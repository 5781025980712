import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { UpdateAdminRolePermissionModel } from '../../../../../../Models/Admin/UpdateAdminRolePermission';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';

export const usePostAddAdminRolePermission = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, UpdateAdminRolePermissionModel> => {
     const postAddAdminRolePermission = (payload: UpdateAdminRolePermissionModel) => {
          return agent.adminRole.postAddAdminRolePermission(payload);
     };

     return useMutation(postAddAdminRolePermission);
};
