import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { BillingModel } from '../../../../../Models/Billing/AddBilling/BillingModel';

export const useGetBill = (
     BillID: number,
     options?: any
): UseQueryResult<BillingModel, any> => {
     const getBill = () => {
          return agent.bills.getBill(BillID)
     };

     return useQuery(['getBill', BillID], getBill, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};