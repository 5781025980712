import { NewMembersActionTypes } from './Types';
import { AppThunkAction } from "../../../App";
import agent from '../../../Axios/Agent';
import { MembersResponseModel, MembersRequestParam } from '../../../Models/Members/ViewMembers'; 

export const newMembersActionCreators = {
    /**
     * function to get new members added in last 15 days
     * @param  {MembersRequestParam} params
     */
    getNewMembers: (params: MembersRequestParam): AppThunkAction<NewMembersActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "REQUEST_NEW_MEMBERS" });
            agent.members
                .getMembers(params)
                .then(response => response as MembersResponseModel)
                .then(data => {
                    dispatch({ type: "RECEIVE_NEW_MEMBERS", payload: data })
                }).catch(error => {
                    dispatch({ type: "RECEIVE_NEW_MEMBERS_ERROR", error })
                })
        }
    },
}