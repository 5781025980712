import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, useAlert } from '../../../../Components';
import { PriceGroupGridKeys } from '../../../../Models/PriceGroup';
import { CommonRequestParam, Pager } from '../../../../Types';
import { getSorterFromSortModel, Page } from '../../../../Utils';
import ViewPriceGroupGridView from './Grid';
import { useGetPriceGroupList } from './Hooks';

const ViewPriceGroup: React.FC = (): JSX.Element => {

     /** useStates */
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [pager, setPager] = useState<Pager>({ CurrentPage: Page.CurrentPage, ItemsPerPage: Page.PageSize });
     const [sortModel, setSortModel] = useState<GridSortModel>([
          {
               field: PriceGroupGridKeys.ID,
               sort: 'asc',
          },
     ]);

     /** Functions */
     const getParams = () => {
          const params: CommonRequestParam = {
               Pager: pager,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };

     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
     };

     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
     };

     const onSort = (sortModel: GridSortModel) => {
          setSortModel(sortModel);
          setPager({ ...pager, CurrentPage: 1 });
     };

     const onUpdateGridHandler = () => {
          refetchPriceGroupList();
     };

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hook */
     const {
          data: getPriceGroupList,
          isLoading: isGetPriceGroupListLoading,
          refetch : refetchPriceGroupList,
          error: getPriceGroupListError,
     } = useGetPriceGroupList(getParams());

     const isDataLoaded = getPriceGroupList !== null;

     /** useEffects */
     useEffect(() => {
          refetchPriceGroupList();
     }, [pager, sortModel]);

     useEffect(() => {
          setIsLoading(isGetPriceGroupListLoading);
     }, [isGetPriceGroupListLoading]);

     useEffect(() => {
     if (getPriceGroupListError) {
          setAlert({
          ...alert,
          show: true,
          header: getPriceGroupListError?.Subject,
          body: getPriceGroupListError?.Description,
          });
     }
     }, [getPriceGroupListError]);
        
     return (
          <div>
               {renderHeader('View Price Groups')}
               <div className='container-fluid'>
                    <Loading loading={isLoading} />
                    <Alert {...alert} />
                    {isDataLoaded && (
                         <ViewPriceGroupGridView
                              data={getPriceGroupList}
                              onSort={onSort}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onUpdateGrid={onUpdateGridHandler}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewPriceGroup;
