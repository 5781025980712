import { useQuery, UseQueryResult } from 'react-query';
import { CommonRequestParam } from '../../../../../../Types';
import agent from '../../../../../../Axios/Agent';

export const useGetAdministratorList = (payload: CommonRequestParam,
  options?: any
): UseQueryResult<any, any> => {

  const getAdministratorList = () => {
    return agent.admin.getAdministrators(payload);
  };

  return useQuery(['GetAdministratorList', payload], getAdministratorList, {
    ...options,
  });
};
