import { Form,FormikProvider,useFormik } from "formik";
import { useEffect,useState } from "react";
import { Col,Row } from "react-bootstrap";
import { Alert,ConfirmDialog,ConfirmDialogProps,useAlert } from "../../../../Components";
import useFileUploader from "../../../../Components/FileUpload/useUploadFileToTempBlob";
import { FormControlKeys } from "../../../../Components/FormControls";
import {
FieldSetContainer,
FieldSetHeader,
LineBreak
} from "../../../../Components/Formik";
import FormikControl from "../../../../Components/Formik/FormikControl";
import { TasksDocumentTypes,TasksFormModel } from "../../../../Models/System/Tasks";
import { PostTasksFile } from "../../../../Models/System/Tasks/Request";
import { FileModelRequest } from "../../../../Models/Tasks";
import { ConfirmDialogContent } from "../../../../Utils";
import { usePostSendSmartDebitFile,usePostUploadMarkRequestingMemberInvoicesAsPaidFile,usePostUploadMemberCodaReferencesFile } from "./Hooks";
import { Line } from "./Line";

const SectionFileUpload = () => {
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };
  /** useStates */
  const [formikInitialValues, setFormikInitialValues] = useState<TasksFormModel>({});

  const [documentInvoicesInfo, setDocumentInvoicesInfo] = useState<FileModelRequest>();
  const [fileInvoicesToUpload, setFileInvoicesToUpload] = useState<File[]>([]);
  const [documentCodaRefsInfo, setDocumentCodaRefsInfo] = useState<FileModelRequest>();
  const [fileCodaRefs, setFileCodaRefs] = useState<File[]>([]);
  const [documentSmartDebitInfo, setDocumentSmartDebitInfo] = useState<FileModelRequest>();
  const [fileSmartDebit, setFileSmartDebit] = useState<File[]>([]);

  //todo: get permissions from API
  const [
    isCodaReferenceUploaderVisible,
    setUploadCodaReferenceUploaderVisible,
  ] = useState(false);
  const [
    isRequestingMemberInvoiceUploaderVisible,
    setRequestingMemberInvoiceUploaderFile,
  ] = useState(false);
  const [isSendSmartDebitUploaderVisible, setSendSmartDebitUploaderVisible] = useState(false);

  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: "",
      body: "",
      cancelCallback: onConfirmDialogClose,
    });

    /** Alert and dialog Hooks */
    const { alert, handleDisplayAlert } = useAlert();

  /** Query Hooks */
  const { uploadFiles, isUploadSuccessful } = useFileUploader();
  
  const {
    mutate: uploadMemberCodaReferenceFile,
    isLoading: uploadMemberCodaReferenceFileIsLoading,
    data: uploadMemberCodaReferenceFileData,
    error: uploadMemberCodaReferenceFileError,
  } = usePostUploadMemberCodaReferencesFile();
  const {
    mutate: postUploadMarkRequestingMemberInvoicesAsPaidFile,
    isLoading: postUploadMarkRequestingMemberInvoicesAsPaidFileIsLoading,
    data: postUploadMarkRequestingMemberInvoicesAsPaidFileData,
    error: postUploadMarkRequestingMemberInvoicesAsPaidFileError,
  } = usePostUploadMarkRequestingMemberInvoicesAsPaidFile();
  const {
    mutate: postSendSmartDebitFile,
    isLoading: postSendSmartDebitFileIsLoading,
    data: postSendSmartDebitFileData,
    error: postSendSmartDebitFileError,
  } = usePostSendSmartDebitFile();

  useEffect(() => {
    if (isUploadSuccessful) {
      setFileCodaRefs([]);
      setFileSmartDebit([]);
      setFileInvoicesToUpload([]);
    }
  }
  , [isUploadSuccessful]);

  useEffect(() => {
    if (uploadMemberCodaReferenceFileData) {
      setFileCodaRefs([]);
    }
  }
  , [uploadMemberCodaReferenceFileData]);

  useEffect(() => {
    if (postUploadMarkRequestingMemberInvoicesAsPaidFileData) {
      setFileInvoicesToUpload([]);
    }
  }
  , [postUploadMarkRequestingMemberInvoicesAsPaidFileData]);

  useEffect(() => {
    if (postSendSmartDebitFileData) {
      setFileSmartDebit([]);
    }
  }
  , [postSendSmartDebitFileData]);

  useEffect(() => {
    handleDisplayAlert(
      uploadMemberCodaReferenceFileError, 
      uploadMemberCodaReferenceFileError?.Error + ': PostUploadMemberCodaReferencesFile', 
      uploadMemberCodaReferenceFileError?.Description + ': PostUploadMemberCodaReferencesFile');
    
    handleDisplayAlert(
      postUploadMarkRequestingMemberInvoicesAsPaidFileError, 
      postUploadMarkRequestingMemberInvoicesAsPaidFileError?.Error + 'PostUploadMarkRequestingMemberInvoicesAsPaidFile', 
      postUploadMarkRequestingMemberInvoicesAsPaidFileError?.Description);

    handleDisplayAlert(
      postSendSmartDebitFileError, 
      postSendSmartDebitFileError?.Error + ': PostSendSmartDebitFile', 
      postSendSmartDebitFileError?.Description + ': PostSendSmartDebitFile');
}, [
  uploadMemberCodaReferenceFileError, 
  postUploadMarkRequestingMemberInvoicesAsPaidFileError,
  postSendSmartDebitFileError,
]);

useEffect(() => {
  if(postUploadMarkRequestingMemberInvoicesAsPaidFileIsLoading) {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.UploadMarkInvicesFile.Title,
      body: <div>Uploading Mark Requesting Member Invoices As Paid File</div>,
      show: true,
      confirmCallback: () => {
        onConfirmDialogClose();
      },
    });
  }
  else if(postSendSmartDebitFileIsLoading) {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.SendSmartDebitFile.Title,
      body: <div>Uploading file..</div>,
      show: true,
      confirmCallback: () => {
        onConfirmDialogClose();
      }
    });
  }
  else if(uploadMemberCodaReferenceFileIsLoading) {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.UpdateCodaRefsFile.Title,
      body: <div>Uploading File..</div>,
      show: true,
      confirmCallback: () => {
        onConfirmDialogClose();
      }
    });
  }
}, [postUploadMarkRequestingMemberInvoicesAsPaidFileIsLoading,
  postSendSmartDebitFileIsLoading,
  uploadMemberCodaReferenceFileIsLoading]);


  useEffect(() => {
    function downloadData(data: any, filename: string) {
      // Decode data and create blob

      let base64 = data.File;
      let binaryString = atob(base64);
      let len = binaryString.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }

      let blob = new Blob([bytes], {type: 'text/csv'}); // Adjust the Blob type as necessary
      let url = window.URL.createObjectURL(blob);
  
      // Create a hidden anchor element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;   
      a.download = filename;
  
      // Append anchor to body, click to start download and then remove it
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  
    if(postUploadMarkRequestingMemberInvoicesAsPaidFileData) {
      setConfirmDialogContent({
        ...confirmDialogContent,
        header: ConfirmDialogContent.UploadMarkInvicesFile.Title,
        body: <p>The file {postUploadMarkRequestingMemberInvoicesAsPaidFileData.FileName} should download automatically!</p>,
        show: true,
        confirmCallback: () => {
          onConfirmDialogClose();
        },
      });
      downloadData(postUploadMarkRequestingMemberInvoicesAsPaidFileData, postUploadMarkRequestingMemberInvoicesAsPaidFileData.FileName ? postUploadMarkRequestingMemberInvoicesAsPaidFileData.FileName : 'file.csv');
    }
    else if(postSendSmartDebitFileData) {
      setConfirmDialogContent({
        ...confirmDialogContent,
        header: ConfirmDialogContent.SendSmartDebitFile.Title,
        body: <p>{postSendSmartDebitFileData}</p>,
        show: true,
        confirmCallback: () => {
          onConfirmDialogClose();
        }
      });
    }
    else if(uploadMemberCodaReferenceFileData) {
      setConfirmDialogContent({
        ...confirmDialogContent,
        header: ConfirmDialogContent.UpdateCodaRefsFile.Title,
        body: <p>The file {uploadMemberCodaReferenceFileData.FileName} should download automatically!</p>,
        show: true,
        confirmCallback: () => {
          onConfirmDialogClose();
        }
      });
      downloadData(uploadMemberCodaReferenceFileData, uploadMemberCodaReferenceFileData.FileName ? uploadMemberCodaReferenceFileData.FileName : 'file.csv');
    }
  }, [postUploadMarkRequestingMemberInvoicesAsPaidFileData,
    postSendSmartDebitFileData,
    uploadMemberCodaReferenceFileData]);
  


  /** Methods */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>, fileInputName: string) => {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    const fileArray = Array.from(files);
    if (fileInputName === TasksDocumentTypes.MemberCodaReferences) setFileCodaRefs(fileArray);
    else if (fileInputName === TasksDocumentTypes.SmartDebitFile) setFileSmartDebit(fileArray);
    else if (fileInputName === TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid) setFileInvoicesToUpload(fileArray);
};
const onUpload = async (fileInputName: string) => {
     if (fileInputName === TasksDocumentTypes.MemberCodaReferences) {
          setConfirmDialogContent({
               ...confirmDialogContent,
               header: ConfirmDialogContent.UpdateCodaRefsFile.Title,
               body: ConfirmDialogContent.UpdateCodaRefsFile.Content,
               show: true,
               confirmCallback: async () => {
                    await uploadFiles(fileCodaRefs);
                    if (documentCodaRefsInfo) {
                         uploadMemberCodaReferenceFile(documentCodaRefsInfo);
                    }
                    onConfirmDialogClose();
               },
          });
     } else if (fileInputName === TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid) {
          setConfirmDialogContent({
               ...confirmDialogContent,
               header: ConfirmDialogContent.UploadMarkInvicesFile.Title,
               body: ConfirmDialogContent.UploadMarkInvicesFile.Content,
               show: true,
               confirmCallback: async () => {
                    await uploadFiles(fileInvoicesToUpload);
                    if (documentInvoicesInfo) {
                        postUploadMarkRequestingMemberInvoicesAsPaidFile(documentInvoicesInfo);
                    }
                    onConfirmDialogClose();
               },
          });
     } else if (fileInputName === TasksDocumentTypes.SmartDebitFile) {
          setConfirmDialogContent({
               ...confirmDialogContent,
               header: ConfirmDialogContent.SendSmartDebitFile.Title,
               body: ConfirmDialogContent.SendSmartDebitFile.Content,
               show: true,
               confirmCallback: async () => {
                    await uploadFiles(fileSmartDebit);
                    if (documentSmartDebitInfo) {
                         postSendSmartDebitFile(documentSmartDebitInfo);
                    }
                    onConfirmDialogClose();
               },
          });
     }
};

const getRemoveAndUploadDocumentInnerContent = (fileInputName: string) => {
     return (
          <>
               <FormikControl
                    control={FormControlKeys.Button}
                    label='Remove'
                    variant='danger'
                    onClick={() => {
                         setFieldValue(fileInputName, '');
                         if (fileInputName === TasksDocumentTypes.MemberCodaReferences) setDocumentCodaRefsInfo(undefined);
                         else if (fileInputName === TasksDocumentTypes.SmartDebitFile) setDocumentSmartDebitInfo(undefined);
                         else if (fileInputName === TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid)
                              setDocumentInvoicesInfo(undefined);
                    }}
               ></FormikControl>
               <FormikControl
                    control={FormControlKeys.Button}
                    label='Upload'
                    variant='success'
                    onClick={() => {
                         onUpload(fileInputName);
                    }}
               ></FormikControl>
          </>
     );
};

  const getFileUploadContent = (fileInputName: string): JSX.Element => {
    
    let docToUpload = {} as PostTasksFile;
    if(TasksDocumentTypes.MemberCodaReferences === fileInputName) docToUpload = documentCodaRefsInfo as PostTasksFile;
    else if(TasksDocumentTypes.SmartDebitFile === fileInputName) docToUpload = documentSmartDebitInfo as PostTasksFile;
    else if(TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid === fileInputName) docToUpload = documentInvoicesInfo as PostTasksFile;
    
    let content: JSX.Element = (
      <div>
        {"  "}
        {docToUpload?.Name}
        <br />
      </div>
    );

    return (
      <div className="file-uploader">
        <div className="files">
          {docToUpload && (
            <>
              {content}
              <br />
              {getRemoveAndUploadDocumentInnerContent(fileInputName)}
            </>
          )}
        </div>
      </div>
    );
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, fileInputType : string) => {
    onFileChange( e, fileInputType );
    setFieldValue(
      fileInputType,
      e.target.value
    );

    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    const newDocument: FileModelRequest = {
      Name: files[0]?.name,
      IsUploaded :true,
    };
    if(TasksDocumentTypes.MemberCodaReferences === fileInputType) setDocumentCodaRefsInfo(newDocument);
    else if(TasksDocumentTypes.SmartDebitFile === fileInputType) setDocumentSmartDebitInfo(newDocument);
    else if(TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid === fileInputType)  setDocumentInvoicesInfo(newDocument);
  }

  return (
    <>
      <FormikProvider value={formik}>
      <Alert {...alert} />
        <Form>
          <ConfirmDialog {...confirmDialogContent} />
          <FieldSetContainer>
            <LineBreak />
            <FieldSetHeader>File Upload Related Tasks</FieldSetHeader>

            <Row className="justify-content-md-center">
              <Col xs={6}>Mark Requesting Member Invoices As Paid</Col>
              <Col xs={3}>
                {!isRequestingMemberInvoiceUploaderVisible && (
                  <FormikControl
                    control={FormControlKeys.Button}
                    label="Add"
                    variant="primary"
                    onClick={() => {
                      setRequestingMemberInvoiceUploaderFile(true);
                    }}
                  ></FormikControl>
                )}
                {isRequestingMemberInvoiceUploaderVisible && (
                  <>
                    <label>
                      <FormikControl
                        style={fileInputStyle}
                        name={
                          TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid
                        }
                        label="Mark Requesting Member Invoices As Paid"
                        type="file"
                        value={formik.values.MarkRequestingMemberInvoicesAsPaid || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(
                            e,
                            TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid
                          );
                        }}
                        disabled={false}
                        title={
                          TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid
                        }
                      />
                    </label>
                    {getFileUploadContent(
                      TasksDocumentTypes.MarkRequestingMemberInvoicesAsPaid
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Line />

            <Row className="justify-content-md-center">
              <Col xs={6}>Update Member Coda References</Col>
              <Col xs={3}>
                {!isCodaReferenceUploaderVisible && (
                  <FormikControl
                    control={FormControlKeys.Button}
                    label="Add"
                    variant="primary"
                    onClick={() => {
                      setUploadCodaReferenceUploaderVisible(true);
                    }}
                  ></FormikControl>
                )}
                {isCodaReferenceUploaderVisible && (
                  <>
                    <label>
                      <FormikControl
                        style={fileInputStyle}
                        name={TasksDocumentTypes.MemberCodaReferences}
                        label="Upload Coda References"
                        type="file"
                        value={formik.values.MemberCodaReferences || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e, TasksDocumentTypes.MemberCodaReferences);
                        }}
                        disabled={false}
                        title={TasksDocumentTypes.MemberCodaReferences}
                      />
                    </label>
                    {getFileUploadContent(
                      TasksDocumentTypes.MemberCodaReferences
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Line />
            <Row className="justify-content-md-center">
              <Col xs={6}>Send Smart Debit File</Col>
              <Col xs={3}>
                {!isSendSmartDebitUploaderVisible && (
                  <FormikControl
                    control={FormControlKeys.Button}
                    label="Add"
                    variant="primary"
                    onClick={() => {
                      setSendSmartDebitUploaderVisible(true);
                    }}
                  ></FormikControl>
                )}
                {isSendSmartDebitUploaderVisible && (
                  <>
                    <label>
                      <FormikControl
                        name={TasksDocumentTypes.SmartDebitFile}
                        label="Send Smart Debit File"
                        type="file"
                        value={formik.values.SmartDebitFile || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e, TasksDocumentTypes.SmartDebitFile);
                        }}
                        disabled={false}
                        title={TasksDocumentTypes.SmartDebitFile}
                      />
                    </label>
                    {getFileUploadContent(TasksDocumentTypes.SmartDebitFile)}
                  </>
                )}
              </Col>
            </Row>
            <Line />
          </FieldSetContainer>
        </Form>
      </FormikProvider>
    </>
  );
};

const fileInputStyle = { color: "#006dcc" };

export default SectionFileUpload;