import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FileModelRequest } from '../../../../../Models/Tasks';

export const usePostSendSmartDebitFile = (
    options?: any
): UseMutationResult<string, any, FileModelRequest> => {

    const usePostSendSmartDebitFile = (payload: FileModelRequest) => {
        return agent.tasks.postSendSmartDebitFile(payload)
    }

    return useMutation(usePostSendSmartDebitFile);
}
