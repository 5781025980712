import React from "react";
import "chartjs-adapter-luxon";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import StreamingPlugin from "chartjs-plugin-streaming";
Chart.register(...registerables);
Chart.register(StreamingPlugin);
const Retries: React.FC = (): JSX.Element => {
  return (
    <div className="row">
      <div className="col-md-12" style={{paddingInline:60}}>
        <h1 className="page-header">Retries</h1>
        <hr
          style={{
            color: "grey",
            height: 1,
          }}
        />
        <div className="alert alert-success">
          All is OK - you have no retries.
        </div>
      </div>
    </div>
  );
};
export default Retries;
