import styled from "styled-components";

export const CellWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
`;

export const CellContent = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1px;
`;

export const CellTextWrapper = styled.div`
  display: 'flex';
  align-items: 'center';
  min-width: 0;
`;

export const TextSpan = styled.span`
  white-space: nowrap;
  margin-left: 0;
`;

export const TextSpan8 = styled.span`
  color: green;
  white-space: nowrap;
  margin-left: 8px;
`;

export const ArchiveButtonWrapper = styled.div`
  margin-left: 'auto';
`;
