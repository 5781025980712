import { Col } from "react-bootstrap";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { InlineBlockColMd6Section } from "../../../../../../Components/Formik";
import { TimeAmPmDropdown } from "../../../../../../Utils";
import { FormControlKeys } from "../../../../../../Components/FormControls";
import FormikControl from "../../../../../../Components/Formik/FormikControl";
import { ReviveJobModel } from "../../../../../../Models/Jobs/EditJob";

const ReviveModule: React.FC<any> = ({ onValuesChange }): JSX.Element => {
  const [showSpecificTimeCollection, setShowSpecificTimeCollection] =
    useState<boolean>(false);
  const [showSpecificTimeDelivery, setShowSpecificTimeDelivery] =
    useState<boolean>(false);

  const formikInitialValues: ReviveJobModel = {
    JobId: 0,
    UseExactCollectionDateAndTime: false,
    CollectionDate: undefined,
    CollectionTime: 0,
    UseExactDeliveryDateAndTime: false,
    DeliveryDate: undefined,
    DeliveryTime: 0,
  };

  /** Methods */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  
  const { values, setFieldValue } = formik;

  useEffect(() => {
      onValuesChange(values);
  }, [values, onValuesChange]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <fieldset>
            <InlineBlockColMd6Section>
                <Col>
                  <label className="fw-600">Earliest Collection</label>
                </Col>
                <Col>
                  <FormikControl
                    name={"CollectionDate"}
                    type="date"
                    placeholder="From"
                  />
                </Col>
                {showSpecificTimeCollection && (
                  <Col>
                    <FormikControl
                      name={"CollectionTime"}
                      control={FormControlKeys.Select}
                      options={TimeAmPmDropdown}
                    />
                  </Col>
                )}
                <Col>
                  <label className="fw-600">Latest Delivery</label>
                </Col>
                <Col>
                  <FormikControl
                    name={"DeliveryDate"}
                    type="date"
                    placeholder="To"
                  />
                </Col>
                {showSpecificTimeDelivery && (
                  <Col>
                    <FormikControl
                      name={"DeliveryTime"}
                      control={FormControlKeys.Select}
                      options={TimeAmPmDropdown}
                    />
                  </Col>
                )}
                <Col>
                  <FormikControl
                    name="UseExactCollectionDateAndTime"
                    label="This specific date ONLY"
                    control={FormControlKeys.Checkbox}
                    onClick={() => {
                      setShowSpecificTimeCollection(
                        !showSpecificTimeCollection
                      );
                    }}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name="UseExactDeliveryDateAndTime"
                    label="This specific date ONLY"
                    control={FormControlKeys.Checkbox}
                    onClick={() => {
                      setShowSpecificTimeDelivery(!showSpecificTimeDelivery);
                    }}
                  />
                </Col>
            </InlineBlockColMd6Section>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ReviveModule;
