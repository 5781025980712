import React from 'react';

interface PlaceholderProps {
    message?: string;
}

const SearchResultPlaceholder: React.FC<PlaceholderProps> = ({ message = "No matching results found." }) => {
    return (
        <div className='please-search-placeholder'>
            <p className='please-search-text'>{message}</p>
        </div>
    );
};

export default SearchResultPlaceholder;
