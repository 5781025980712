import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../Components';
import { RouteConstants } from '../../../Routes';
import { authActionCreators } from '../../../Store/Session/Action';
import { ApplicationState } from '../../../Store/Types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useAppDispatch } from '../../../Store/hooks';

const Logout = (props: any): JSX.Element | null => {
  const { history } = props;
  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch()
  const [loading, setLoading] = useState(true);
  const sessionState = useSelector(
    (state: ApplicationState) => state.sessionState!
  );

  const doLogout = () => {
    dispatch(authActionCreators.logout());
    setLoading(true);
  };

  useEffect(() => {
    doLogout();
  }, []);

  useEffect(() => {
    if (!sessionState.session) {
      history.push(`${RouteConstants.Login}`);
      setLoading(false);
    }
  }, [sessionState]);

  return <Loading loading={loading} />;
};

export default Logout;
