import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { MarkPaymentsAsPaidModel } from "../../../../../Models/Billing/AddBilling/MarkPaymentsAsPaidModelRequest";
import { EntityCreatedOrUpdatedViewModel } from "../../../../../Types";

export const usePutMarkPaymentsAndMembershipAsPaid = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  MarkPaymentsAsPaidModel
> => {
  const putMarkPaymentsAndMembershipAsPaid = (payload: MarkPaymentsAsPaidModel) => {
    return agent.bills.putMarkPaymentsAndMembershipAsPaidModel(payload);
  };

  return useMutation(putMarkPaymentsAndMembershipAsPaid);
};
