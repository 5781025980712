import React from 'react';
import { Modal } from 'react-bootstrap';
import './index.css';

export const ModalDialog: React.FC<ModalDialogProps> = (props): JSX.Element => {
     const { header, footer, body, show = false, closeCallback, size } = props;

     return (
          <Modal
               className='modal'
               animation={false}
               style={{ opacity: 1 }}
               show={show}
               onHide={closeCallback}
               size={size}
          >
               {header && (
                    <Modal.Header closeButton>
                         <Modal.Title style={{ whiteSpace: 'pre-wrap' }}>{header}</Modal.Title>
                    </Modal.Header>
               )}
               {body && <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>{body}</Modal.Body>}
               {footer && <Modal.Footer>{footer}</Modal.Footer>}
          </Modal>
     );
};

export type ModalDialogProps = {
     header?: any;
     body?: any;
     footer?: any;
     show: boolean;
     closeCallback?: () => void;
     size?: any;
};
