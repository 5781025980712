import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetPriceGroupResponse } from '../../../../../Models/PriceGroup';

export const useGetPriceGroup = (
     priceGroupID: number,
     options?: any
): UseQueryResult<GetPriceGroupResponse, any> => {
     const getPriceGroup = () => {
          if(priceGroupID) return agent.priceGroup.getPriceGroup(priceGroupID);
     };

     return useQuery(['GetPriceGroup', priceGroupID], getPriceGroup, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
