/** Inbuilt libraries */
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
/** Custom Components */
import {
  FieldSetHeader, HoverableLink,
} from "../../../../../Components/Formik";
import { ActivityHistory } from "../../../../../Models/Jobs/EditJob";
import { getFormattedDateWithYearAndTime, lookupEnumByValForLabel } from "../../../../../Utils";
import { JobActivityType, MemberType } from "../../../../../Utils/Enums";
import { useGetMembersForDropdown } from "../../../Members/EditMember/Tabstrip/MemberBlocks/Hooks";
import { getUsernameById } from "./helper";
import { RouteConstants } from "../../../../../Routes";

const Section9ActivityHistory: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { activityHistoryList } = props;

  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

  const sortedActivityHistory = [...activityHistoryList].sort((a, b) => {
    return new Date(b.ActivityDate).getTime() - new Date(a.ActivityDate).getTime();
  });
  
  /** Methods */
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: () => {
    },
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <FieldSetHeader>Activity History</FieldSetHeader>
              <table id='id_table_activity_history' className="table table-bordered table-condensed table-striped">
                <thead>
                  <tr>
                    <td>Admin</td>
                    <td>Member</td>
                    <td>Job ID</td>
                    <td>Date / Time</td>
                    <td>Type</td>
                  </tr>
                </thead>
                <tbody>
                  {isWaitingData && <tr>Loading...</tr>}
                  {!isWaitingData &&
                    sortedActivityHistory?.map(
                      (data: ActivityHistory, index: number) => {
                        const {
                          AdminUserID,
                          AdminUserName,
                          MemberID,
                          MemberUserName,
                          JobID,
                          ActivityDate,
                          ActivityType
                        } = data;
                        return (
                          <tr key={index}>
                            <td>   
                              <HoverableLink to={`${RouteConstants.EditAdministrator}/${AdminUserID}`}>
                                {AdminUserName}
                              </HoverableLink>
                            </td>
                            <td>
                              <HoverableLink to={`${RouteConstants.EditMember}/${MemberID}`}>
                                {MemberUserName}
                              </HoverableLink>
                            </td>
                            <td>                              
                              <HoverableLink to={`${RouteConstants.ViewJobById}/${JobID}`}>
                                {JobID}
                              </HoverableLink>
                            </td>
                            <td>{getFormattedDateWithYearAndTime(ActivityDate)}</td>
                            <td>{lookupEnumByValForLabel(JobActivityType, ActivityType)}</td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  jobID: number;
  activityHistoryList:  ActivityHistory[]
};

export default Section9ActivityHistory;
