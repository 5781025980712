export const AddGroupKeys = {
    Active: 'Active',
    ConfirmPassword: 'ConfirmPassword',
    Email: 'Email',
    ID: "ID",
    Notes: 'Notes',
    ParentId: 'ParentId',
    Password: 'Password',
    UserName: 'UserName',
    SelectedFavouriteMember: 'FavouriteMemberID',
    AddFavouriteMember: 'AddFavouriteMember',
    RemoveFavouriteMember: 'RemoveFavouriteMember',
    SelectedFavouriteBlock: 'BlockedMemberID',
    AddFavouriteBlock: 'AddFavouriteBlock',
    RemoveFavouriteBlock: 'RemoveFavouriteBlock',
}

export const AddInvoiceKeys = {
    MemberID: 'Invoice.MemberID',
    MemberUserName: 'Invoice.MemberUserName',
    MemberCodaCustomerReference: 'Invoice.MemberCodaCustomerReference',
    MemberCompany: 'Invoice.MemberCompany',
    MemberContactName: 'Invoice.MemberContactName',
    MemberAddress2: 'Invoice.MemberAddress2',
    MemberAddress1: 'Invoice.MemberAddress1',
    MemberAddress3: 'Invoice.MemberAddress3',
    MemberTown: 'Invoice.MemberTown',
    MemberCounty: 'Invoice.MemberCounty',
    MemberPostCode: 'Invoice.MemberPostCode',
    MemberLandline: 'Invoice.MemberLandline',
    MemberVATRegistered: 'Invoice.MemberVATRegistered',
    MemberVATNo: 'Invoice.MemberVATNo',
    SendInvoiceEmailToUser: 'Invoice.SendInvoiceEmailToUser',

    PaymentReference: 'Invoice.PaymentReference',
    InvoiceDate: 'Invoice.InvoiceDate',
    InvoiceNo: 'Invoice.InvoiceNo',
    AlreadyPaid: 'Invoice.AlreadyPaid',
    DueDate: 'Invoice.DueDate',

    MovexCompany: 'Invoice.MovexCompany',
    MovexAddress1: 'Invoice.MovexAddress1',
    MovexAddress2: 'Invoice.MovexAddress2',
    MovexAddress3: 'Invoice.MovexAddress3',
    MovexTown: 'Invoice.MovexTown',
    MovexCounty: 'Invoice.MovexCounty',
    MovexWebsite: 'Invoice.MovexWebsite',
    MovexEmail: 'Invoice.MovexEmail',
    MovexLandline: 'Invoice.MovexLandline',
    MovexVATNo: 'Invoice.MovexVATNo',
    MovexPostCode: 'Invoice.MovexPostCode',

    UseQuantitiesAndRates: 'Invoice.UseQuantitiesAndRates',
    Quantity: 'Invoice.Quantity',
    Rate: 'Invoice.Rate',
    Discount: 'Invoice.Discount',
    NetAmount: 'Invoice.NetAmount',
    VatAmount: 'Invoice.VatAmount',
    GrossAmount: 'Invoice.GrossAmount',
    VatRate: 'Invoice.VatRate',
    Description: 'Invoice.Description',

    TotalNetAmount: 'Invoice.TotalNetAmount',
    TotalVatAmount: 'Invoice.TotalVatAmount',
    TotalGrossAmount: 'Invoice.TotalGrossAmount',
}

export type InvoiceIDParamType = {
    invoiceID: string;
}
