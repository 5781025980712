import React from 'react';
import { ControlledPagination as Pagination } from '../../../../../Components';
import Table from '../../../../../Components/Table/index';
import { MembersResponseModel } from '../../../../../Models/Members';
import { Sorter } from '../../../../../Types';
import { columns } from './Columns';

const NewMembersGridView: React.FC<NewMembersGridProps> = (props) => {
  const { data: members, onPageChange, onPageSizeChange, onSort } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sorter: Sorter) => {
    onSort(sorter);
  };

  const { Page, TableSorter } = members;
  if (Page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = Page;
    const paginationElement = (
      <Pagination
        totalPages={TotalPages}
        itemsCount={TotalItems}
        pageSize={ItemsPerPage}
        currentPage={CurrentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    );
    return (
      <div id="divNewMembersGrid">
        {paginationElement}
        <Table 
          columns={columns}
          data={Items}
          sorter={TableSorter as Sorter}
          onSort={handleSort}
        />
        {paginationElement}
      </div>
    );
  }
  return <div>No new members!</div>;
};

export default NewMembersGridView;
interface NewMembersGridProps {
  data: MembersResponseModel;
  onPageChange: (currentPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSort: (sorter: Sorter) => void;
}
