export const FeedbackGridKeys = {
    Comments: 'Comments',
    Communication: 'Communication',
    FeedbackDate: 'FeedbackDate',
    FromMemberID: 'FromMemberID',
    FromMemberUserName: 'FromMemberUserName',
    ID: 'ID',
    JobID: 'JobID',
    Overall: 'Overall',
    Payment: 'Payment',
    Professionalism: 'Professionalism',
    Promptness: 'Promptness',
    ServiceType: 'ServiceType',
    SmartDrivers: 'SmartDrivers',
    ToMemberID: 'ToMemberID',
    ToMemberUserName: 'ToMemberUserName',
    ValueForMoney: 'ValueForMoney',
}