import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { IGetQuoteFeeDetailsRequest } from "../../../../../Models/Jobs/EditJob/GetQuoteFeeDetailsRequest";
import { QuoteDetailsResponseModel } from "../../../../../Models/Jobs/EditJob";

export const useGetQuoteFeeDetails = (
  getQuoteFeeDetailsModel: IGetQuoteFeeDetailsRequest,
  options?: any
): UseQueryResult<QuoteDetailsResponseModel, any> => {
  const getQuoteFeeDetails = () => {
    if(Number(getQuoteFeeDetailsModel.quoteAmount) !== 0)
    return agent.jobs.getQuoteFeeDetails(getQuoteFeeDetailsModel);
  };

  return useQuery(["GetQuoteFeeDetails", getQuoteFeeDetailsModel.quoteAmount], getQuoteFeeDetails, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
