import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeleteSavedSearch = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number> => {
     const deleteSavedSearch = (savedSearchID: number) => {
          return agent.savedSearches.deleteSavedSearch(savedSearchID);
     };

     return useMutation(deleteSavedSearch);
};
