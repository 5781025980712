export enum FreeTextSearchByOptions {
    ID = 'ID',
    JobID = 'JobID',
    Username = 'Username',
    Email = 'Email',
    Company = 'Company',
    CodaCustomerReference = 'CodaCustomerReference',
    CodaSupplierReference = 'CodaSupplierReference',
    Dealer = 'Dealer',
    Supplier = 'Supplier',
    FromUsername = 'From Username',
    ToUsername = 'To Username',
    FromEmail = 'From Email',
    DealerUsername = 'DealerUsername',
    DealerEmail = 'DealerEmail',
    SupplierUsername = 'SupplierUsername',
    SupplierEmail = 'SupplierEmail',
    ToEmail = 'To Email',
}

