import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, ShowPleaseWait, useAlert } from '../../../../Components';
import {
  MemberModelKeys,
  MembersRequestParam,
  MembersFilter,
  MembersResponseModel,
} from '../../../../Models/Members';
import { Pager } from '../../../../Types';
import {
  downloadFile,
  getSorterFromSortModel,
  getYesNoOrBothRadioValue,
  Page,
} from '../../../../Utils';
import FilterBox from './FilterBox';
import MembersDataGridView from './Grid';
import { useGetMembers } from './Hooks';
import './index.css';
import useHandleGridFilterCache, { GridFilterKeys } from '../../../../Components/savedFilterHook/useHandleGridFilterCache';
import { useLocation } from 'react-router-dom';
import { FreeTextSearchByOptions } from '../../../../Utils/Enums/FreeTextSearchBy';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../Store/Types';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';
import { isEqual } from 'lodash';
import agent from '../../../../Axios/Agent';

const ViewMembers: React.FC = (): JSX.Element => {
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupID = queryParams.get('groupID');

  /** useStates */
  const [triggerFetching, setTriggerFetching] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isResultEmpty, setIsResultEmpty] = useState<boolean>(true);

  const { saveFilterInCache } = useHandleGridFilterCache({
    key: GridFilterKeys.MemberFilterKey,
    defaultFilter: { GroupID : groupID?.toString(), FreeTextSearchBy: FreeTextSearchByOptions.Username },
  }); 

  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: MemberModelKeys.SignupDate,
      sort: 'desc',
    },
  ]);
  
  const lastUpdatedGridState = useSelector((state: ApplicationState) => state.gridsFilterState.savedFilters!);

  /** Methods */
  const getParams = () => {
    const filter = {...lastUpdatedGridState.filterData} as MembersFilter;
    if (groupID) filter.GroupID = groupID;

    const params: MembersRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };


  const handleSearch = (filter: MembersFilter) => {
    saveFilterInCache( { key: GridFilterKeys.MemberFilterKey, filterData: {...filter, IsKeyAccount: getYesNoOrBothRadioValue(filter.IsKeyAccount)} });
    setTriggerFetching(true);
  };

  const handleClearSearch = (triggerFetch? : boolean) => {
    if (!isEqual(lastUpdatedGridState.filterData, { IsKeyAccount: undefined, FreeTextSearchBy: FreeTextSearchByOptions.Username })) {
      saveFilterInCache( { 
        key: GridFilterKeys.MemberFilterKey, 
        filterData: {
          FreeText: '',
          FreeTextSearchBy: FreeTextSearchByOptions.Username,
          MemberType: '',
          MemberStatus: '',
          IsKeyAccount: undefined, 
        } });
      setTriggerFetching(triggerFetch == undefined ? true : triggerFetch);
    }
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
    setTriggerFetching(true);
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
    setTriggerFetching(true);
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
    setTriggerFetching(true);
  };

  /** Hooks */
  const { alert, setAlert } = useAlert();
  
  const rqOption = { enabled: false };
  const {
    isLoading: isGetMembersLoading,
    data: membersResponse,
    refetch: fetchMembers,
    error: getMembersError,
  } = useGetMembers(getParams(), rqOption);
  const handleExportMembersCsv = () => {
    const params = getParams();
    agent.members
      .exportMembersToCSV(params)
      .then((response: any) => {
        const { FileDownloadName, FileContents } = response;
        downloadFile(FileContents, FileDownloadName);
      });
  }; 

  /** useEffects */
  useEffect(() => {
    const dataLoaded = membersResponse !== null;
    setIsDataLoaded(dataLoaded);

    const resultEmpty = dataLoaded ? membersResponse?.Page == null : true;
    setIsResultEmpty(resultEmpty);
  }, [membersResponse]);

  useEffect(() => {
    if(lastUpdatedGridState.key !== GridFilterKeys.MemberFilterKey){
      const triggerFetching = false;
      handleClearSearch(triggerFetching);
    }
  }, []);

  useEffect(() => {
    if (triggerFetching) {
      fetchMembers();
      const triggerFetching = false;
      setTriggerFetching(triggerFetching);
    }
  }, [triggerFetching]);

  useEffect(() => {
    if (getMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getMembersError?.Subject,
        body: getMembersError?.Description,
      });
    } 
  }, [getMembersError]);

  return (
    <div>
      <Alert {...alert} />
      {renderHeader('Members')}
      <div className="container-fluid">
        <FilterBox
          filter={lastUpdatedGridState.filterData as MembersFilter}
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onExportCSV={handleExportMembersCsv}
          showExport={!isResultEmpty}
        />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        <Loading loading={isGetMembersLoading} />
        {isDataLoaded && (
          <MembersDataGridView
            data={membersResponse ?? {} as MembersResponseModel}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewMembers;
