export interface NewOrUpdatedMembersFilter {
    UserName?: string;
    Email?: string;
    FromDate?: string;
    ToDate?: string;
    IsDriver?: boolean;
}

export const NewOrUpdatedMembersFilterKeys = {
    UserName: 'UserName',
    Email: 'Email',
    FromDate: 'FromDate',
    ToDate: 'ToDate',
    IsDriver: 'IsDriver',
}