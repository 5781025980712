import { Action, Reducer } from 'redux';
import { CurrentSavedFilters, GridFiltersState, GridsActionTypes } from './Types';

const unloadedState: GridFiltersState = {
     savedFilters: {} as CurrentSavedFilters,
};

export const reducer: Reducer<GridFiltersState> = (
     state: GridFiltersState | undefined,
     incomingAction: Action
): GridFiltersState => {
     if (state === undefined) {
          return unloadedState;
     }
     const action = incomingAction as GridsActionTypes;
     switch (action.type) {
          case 'GET_GRID_FILTERING':
          case 'SET_GRID_FILTERING':
               if (JSON.stringify(state.savedFilters) !== JSON.stringify(action.payload)) {
                    return {
                         ...state,
                         savedFilters: action.payload,
                    };
               }
               return state; 
          default:
               return state;
     }
};
