import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddGroupFavouriteModel, GroupFavouriteResponseModel } from '../../../../../Models/Groups';

export const useDeleteGroupFavourite = (
  options?: any
): UseMutationResult<
  GroupFavouriteResponseModel,
  any,
  AddGroupFavouriteModel
> => {

  const deleteGroupFavourite = (payload: AddGroupFavouriteModel) => {
    return agent.groups.deleteGroupFavourite(payload);
  };

  return useMutation(deleteGroupFavourite);
};