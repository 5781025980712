import { OptionType } from '../../../../Components/FormControls';
import { InvoiceType } from '../../../../Utils/Enums';

export const InvoiceTypeOptions: OptionType[] = [
    {
         value: null,
         label: 'All Types',
    },
    {
         value: InvoiceType.AdHocInvoice,
         label: 'Adhoc Invoice',
    },
    {
         value: InvoiceType.AdHocCreditNote,
         label: 'Adhoc Credit Note',
    },
    {
         value: InvoiceType.DeferredFeesInvoice,
         label: 'Deferred Fees Invoice',
    },
    {
         value: InvoiceType.rm_PrePaidInvoice,
         label: 'Requesting Member Prepaid Invoice',
    },
    {
         value: InvoiceType.rm_MonthlyInvoice,
         label: 'Requesting Member Monthly Invoice',
    },
    {
         value: InvoiceType.rm_WeeklyInvoice,
         label: 'Requesting Member Weekly Invoice',
    },
    {
         value: InvoiceType.rm_FortnightlyInvoice,
         label: 'Requesting Member Fortnightly Invoice',
    },
    {
         value: InvoiceType.rm_PayNowInvoice,
         label: 'Requesting Member Pay Now Invoice',
    },
    {
         value: InvoiceType.sm_JobInvoice,
         label: 'Supplying Member Job Invoice',
    },
    {
         value: InvoiceType.sm_MonthlyInvoice,
         label: 'Supplying Member Monthly Invoice',
    },
];