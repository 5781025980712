import React from 'react';

export const Info24HoursDealerPrePay = () => {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>
        <strong>
          <span style={{ fontSize: 16 }}>
            <span style={{ color: '#BD321C' }}>
              IMPORTANT MESSAGE PLEASE READ
            </span>
          </span>
        </strong>
      </p>

      <p>
        <span style={{ color: '#004966' }}>
          <span style={{ fontSize: 16 }}>
            I am very pleased to inform you that we are now live with our new
            payment system whereby Movex pays the transport companies for your
            jobs 3 working days after a delivery.
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: 16 }}>
          At this current time you will be required to pre pay for your jobs
          until you have been allocated a credit limit.
        </span>
      </p>

      <p>
        <span style={{ fontSize: 16 }}></span>
      </p>

      <p>
        <span style={{ fontSize: 16 }}>
          If you are allocated credit you will need to set up a direct debit.
          Then at month end Movex will invoice you for all your completed jobs
          for the month.
        </span>
      </p>

      <p>
        <span style={{ fontSize: 16 }}>
          As we will be paying transport companies on your behalf you will
          receive a job completed email with an attached proof of delivery
          (POD). If for any reason you wish not to pay for the job simply reply
          &ldquo;do not pay&rdquo; and we will pause the payment and contact
          you.{' '}
          <span style={{ color: '#BD321C', fontWeight: 'bold' }}>
            Please note: this must be done within 3 working days.
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: 16 }}>
          <strong>Features of the new system</strong>
        </span>
      </p>

      <ul>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>
              Free iOS APP is available now to download from the Apple APP store
              (search for <span style={{ color: '#004966' }}>Movex</span>)
            </strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>
              Free Android APP is available now to download from Google store
              (search for <span style={{ color: '#004966' }}>Movex</span>)
            </strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>
              Proof of collection (POC) emailed to you with photos of the front,
              back, left and right of every vehicle, full appraisal, electronic
              signature date and time stamped.
            </strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>Proof of delivery (POD) as above POC.</strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>
              Live online tracking with ETA for you and your customers.
            </strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>
              One monthly invoice and payment for all your jobs, instead of
              multiple invoices and payments.
            </strong>
          </span>
        </li>
        <li>
          <span style={{ fontSize: 16 }}>
            <strong>Improved cash flow with up to 60 days credit.</strong>
          </span>
        </li>
      </ul>

      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p style={{ textAlign: 'center' }}>
        <span style={{ color: '#BD321C' }}>
          <span style={{ fontSize: 16 }}>
            <strong>
              If you have any queries regarding this please feel free to call us
              on{' '}
            </strong>
          </span>
        </span>
      </p>

      <p style={{ textAlign: 'center' }}>
        <span style={{ color: '#BD321C' }}>
          <span style={{ fontSize: 16 }}>
            <strong>0208 551 1060</strong>
          </span>
        </span>
      </p>
    </div>
  );
};