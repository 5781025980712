import { useMutation, UseMutationResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { AddTaskAttachmentsRequest } from '../../../../../Models/Tasks';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddTaskAttachments = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddTaskAttachmentsRequest> => {
     const postAddTaskAttachments = (payload: AddTaskAttachmentsRequest) => {
          return adminTasks.postAddTaskAttachments(payload);
     };
     return useMutation(postAddTaskAttachments);
};
