import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeletePartner = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number> => {

  const deletePartner = (partnerID: number) => {
    return agent.partners.deletePartner(partnerID);
  };

  return useMutation(deletePartner);
};
