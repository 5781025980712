import { TaskDetailItem } from "../../../../Components/UI";

interface TaskDetailProps {
    label: string;
    value: string | number;
  }
  
  function TaskDetail({ label, value }: TaskDetailProps) {
    return (
      <TaskDetailItem>
        <strong>{label}: </strong>
        <span>{value}</span>
      </TaskDetailItem>
    );
  }

export default TaskDetail;
