export enum JobActivityType {

    JobCreated = 0,
    JobUpdated = 1,
    JobMarkedAsNotForMe = 2,
    JobDeleted = 3,
    JobCancelled = 4,
    JobRevived = 5,
    JobCompleted = 6,
    QuotesRequested = 7,
    FixedPriceJobActive = 8,
    QuoteCreated = 9,
    QuoteUpdated = 10,
    QuoteAccepted = 11,
    QuoteMarkedAsDeleted = 12,
    QuoteDeleted = 13,
    QuotedJobActive = 14,
    QuoteJobDeclined = 15,
    JobAborted = 16,
    QuoteMarginUpdated = 17,
    QuotesAutomaticallyCreated = 18,
    QuoteAcceptedAndJobActive = 19,
    JobsVehicleAborted = 20,
    JobLocked = 21,
    JobUnlocked = 22,
    JobReOffered = 23,
    AdminRemovedFailed = 24,
    DraftJob = 25,
    JobBooked = 26,
    JobExpired = 27,
    JobStatusUnknown = 255,
}