import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../Formik/TextError';
import PropTypes from 'prop-types';
import { OptionType } from './Types';

export const CheckboxGroup = ({ ...props }) => {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control">
      <label>{label}</label>
      <Field name={name}>
        {({ field }: { field: any }) => {
          return options.map((option: OptionType) => {
            const { value, label } = option;
            const id = `${name}_${value}`;
            return (
              <React.Fragment key={value}>
                <input
                  type="checkbox"
                  id={id}
                  {...field}
                  {...rest}
                  value={value}
                  checked={field.value.includes(value)}
                />
                <label htmlFor={id}>{label}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default CheckboxGroup;

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
