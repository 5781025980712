
export const DealerConfigKeys = {
    SelectedFavouriteForDrivenId: "SelectedFavouriteForDrivenId",
    SelectedFavouriteForTransportedId: "SelectedFavouriteForTransportedId",
    MaskContactInfoForJobDetails: "MaskContactInfoForJobDetails",
    DrivenMaxCostPerVehiclePerMile: "DrivenMaxCostPerVehiclePerMile",
    DrivenGuaranteedPriceMinimumRate: 'DrivenGuaranteedPriceMinimumRate',
    DrivenGuaranteedPriceMaximumRate: 'DrivenGuaranteedPriceMaximumRate',
    TransportedMaxCostPerVehiclePerMile: "TransportedMaxCostPerVehiclePerMile",
    TransportedGuaranteedPriceMinimumRate: "TransportedGuaranteedPriceMinimumRate",
    TransportedGuaranteedPriceMaximumRate: 'TransportedGuaranteedPriceMaximumRate'
}

export const FavouritesForDrivenJobsKeys = {
    FavouriteMemberUsername: "FavouriteMemberUsername",
    FavouriteMemberCompany: "FavouriteMemberCompany",
    FavouriteType: 'FavouriteType',
    ForMovement_Internal: 'ForMovement_Internal',
    ForMovement_Dealer: 'ForMovement_Dealer',
    ForMovement_Fleet: 'ForMovement_Fleet',
    ForMovement_Retail: 'ForMovement_Retail',
    ForMovement_PremiumFleet: 'ForMovement_PremiumFleet',
    ForMovement_PremiumRetail: 'ForMovement_PremiumRetail',
    FavouriteMemberId: 'FavouriteMemberId',
    MemberId: 'MemberId',
    id: 'Id'
}