import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, ConfirmDialog, Loading, useAlert } from '../../../../Components';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import { renderHeader } from '../../../../Components/PageHeader';
import { PriceGridKeys } from '../../../../Models/Prices/ViewPrices';
import { PricesRequestParam } from '../../../../Models/Prices/ViewPrices/Request';
import { Pager } from '../../../../Types';
import { ConfirmDialogContent, getSorterFromSortModel, Page } from '../../../../Utils';
import GridView from './Grid';
import { useDeletePrice, useGetPrices } from './Hooks';

const ViewPrices: React.FC = (): JSX.Element => {
     
     /** useStates */
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [pager, setPager] = useState<Pager>({ CurrentPage: Page.CurrentPage, ItemsPerPage: Page.PageSize });
     const [sortModel, setSortModel] = useState<GridSortModel>([{
               field: PriceGridKeys.Id,
               sort: 'asc',
          }
     ]);

     /** Functions */
     const getParams = () => {
          const params: PricesRequestParam = {
               Pager: pager,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };
     
     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
     };

     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
     };

     const onSort = (sortModel: GridSortModel) => {
          setPager({ ...pager, CurrentPage: 1 });
          setSortModel(sortModel);
     };

     const onDelete = (priceId: number) => {
          openConfirmDialog(
               ConfirmDialogContent.DeletePrice.Title,
               ConfirmDialogContent.DeletePrice.Content,
               () => {
                    deletePrice(priceId);
                    closeConfirmDialog();
               }
          );
     };

     /** Custom Hooks */
     const { 
          alert, 
          setAlert 
     } = useAlert();
     
     const { 
          confirmDialogProps, 
          openConfirmDialog, 
          closeConfirmDialog 
     } = useConfirmDialog();

     /** Query Hooks */
     const {
          data: getPricesResponse,
          isLoading: isGetPricesLoading,
          refetch: refetchGetPrices,
          error: getPricesError,
     } = useGetPrices(getParams());
     
     const isDataLoaded = getPricesResponse !== null;

     const {
          mutate: deletePrice,
          data: deletePriceData,
          error: deletePriceError,
     } = useDeletePrice();

     /** useEffects */
     useEffect(() => {
          refetchGetPrices();
     }, [pager, sortModel]);

     useEffect(() => {
          refetchGetPrices();
     }, [deletePriceData]);

     useEffect(() => {
          setIsLoading(isGetPricesLoading);
     }, [isGetPricesLoading]);

     useEffect(() => {
          if (deletePriceError) {
               setAlert({
               ...alert,
               show: true,
               header: deletePriceError?.Subject ?? 'Price not deleted',
               body: deletePriceError?.Description ?? 'Price failed to be deleted.',
               });
          }
     }, [deletePriceError]);
        
     useEffect(() => {
          if (deletePriceData) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Price Deleted Successfully',
                    body: 'Price Deleted Successfully.',
               });
          }
     }, [deletePriceData]);

     useEffect(() => {
          if (getPricesError) {
            setAlert({
              ...alert,
              show: true,
              header: getPricesError?.Subject,
              body: getPricesError?.Description,
            });
          }
        }, [getPricesError]);
        

     return (
          <div>
               {renderHeader('Prices')}
               <div>
                    <Loading loading={isLoading} />
                    <ConfirmDialog {...confirmDialogProps}></ConfirmDialog>
                    <Alert {...alert} />
                    {isDataLoaded && (
                         <GridView
                              data={getPricesResponse}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onDelete={onDelete}
                              onSort={onSort}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewPrices;
