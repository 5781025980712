import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { FailIndicatorIcon } from '../../../../../Assets/Images';
import { CellRenderer } from '../../../../../Components/Formik';
import { PaymentItemModelKeys } from '../../../../../Models/Jobs/Payments';
import { getDayAndMonthDate } from '../../../../../Utils';
import { PaymentStatus } from '../../../../../Utils/Enums';
import { cellImageStyle } from '../../../Billing/AddBill';
import { ArchiveCell } from './ArchiveCell';
import {
     getInvoicedColumn,
     getMovexPaidDateColumn,
     getPaymentNoOfVehiclesColumn,
     getSupplierPaidDateColumn,
     renderDateStatus,
} from './PaymentColumnsHelper';
import PaymentsGridActionMenu from './PaymentsGridActionMenu';

const {
     renderViewJobByJobIdCell,
     renderCellWithTooltip,
     renderEditMemberByMemberIdCell,
     renderDateCell,
     renderServiceTypeCell,
     renderCellRoundedTooltip,
     renderAdminJobStatusCell,
     renderColoredCurrencyCell,
     renderTotalPayableColoredCurrencyCell,
     renderVatColoredCurrencyCell,
} = CellRenderer;

export const paymentsColumns = (
     onShowDialog: Function,
     showPaymentNotesModule: Function,
     showPaymentDueDateModal: Function,
     showPayNowModal: Function,
     showRefundPrePaymentModal: Function,
     showMarkPaymentAsNotPayableModal: Function,
     archivePOC: Function,
     archivePOD: Function,
     showAlert: Function,
     handleAlertClose: Function,
     showCheckPaymentModal: Function,
     showUncheckPaymentModal: Function
): GridColumns => {
     const width = 61;

     function getPaymentStatusColumn(
          params: GridRenderCellParams,
          showCheckPaymentModal: Function,
          showUncheckPaymentModal: Function
     ) {
          const status = PaymentStatus[params.row.PaymentStatus];

          const handleClick = () => {
               if (checkedDate == null) {
                    showCheckPaymentModal(params.row.PaymentID);
               } else {
                    showUncheckPaymentModal(params.row.PaymentID);
               }
          };

          const checkedDate = getDayAndMonthDate(params.row.AdminCheckedDate);
          return (
               <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                    {status} {checkedDate ? renderDateStatus(checkedDate) : <img alt='' style={cellImageStyle} src={FailIndicatorIcon} />}
               </span>
          );
     }

     return [
          {
               field: PaymentItemModelKeys.ID,
               headerName: 'Job ID',
               description: 'Job ID',
               renderCell: (params: GridRenderCellParams) => renderViewJobByJobIdCell(params, PaymentItemModelKeys.ID, params.row.Id),
               flex: 1,
               maxWidth: width,
               minWidth: width,
          },
          {
               field: PaymentItemModelKeys.RequestDate,
               description: 'Request Date',
               renderCell: (params: GridRenderCellParams) => renderDateCell(params),
               flex: 1,
               minWidth: width,
               renderHeader: () => <div className='grid-custom-header'>Request Date</div>,
          },
          {
               field: PaymentItemModelKeys.RequestingMemberUserName,
               headerName: 'User',
               renderCell: (params: GridRenderCellParams) => {
                    const id = (params.row.RequestingMemberID as number) || 0;
                    return renderEditMemberByMemberIdCell(PaymentItemModelKeys.RequestingMemberUserName, id, params.value);
               },
               flex: 1,
               minWidth: width,
          },
          {
               field: PaymentItemModelKeys.SupplyingMemberUserName,
               renderCell: (params: GridRenderCellParams) => {
                    const id = (params.row.SupplyingMemberID as number) || 0;
                    return renderEditMemberByMemberIdCell(PaymentItemModelKeys.SupplyingMemberUserName, id, params.value);
               },
               renderHeader: () => <div className='grid-custom-header'>Supplier</div>,
               flex: 1,
               minWidth: width,
          },
          {
               field: PaymentItemModelKeys.ActualServiceType,
               renderHeader: () => <div className='grid-custom-header'>Service Type</div>,
               renderCell: (params: GridRenderCellParams) => renderServiceTypeCell(params),
               flex: 1,
               minWidth: 80,
               sortable: false,
          },
          {
               field: PaymentItemModelKeys.NoOfVehicles,
               headerName: 'No Of Vehicles',
               valueGetter: getPaymentNoOfVehiclesColumn,
               renderHeader: () => <div className='grid-custom-header'>No Of Vehicles</div>,
               flex: 1,
               maxWidth: width,
               minWidth: width,
               sortable: false,
          },
          {
               field: PaymentItemModelKeys.DistanceMiles,
               headerName: 'Miles',
               renderCell: (params: GridRenderCellParams) => renderCellRoundedTooltip(params),
               renderHeader: () => <div className='grid-custom-header'>Miles</div>,
               flex: 1,
               minWidth: width,
          },
          {
               field: PaymentItemModelKeys.JobStatus,
               headerName: 'Job Status',
               minWidth: 100,
               renderCell: (params: GridRenderCellParams) => renderAdminJobStatusCell(params),
               flex: 1,
               renderHeader: () => <div className='grid-custom-header'>Job Status</div>,
          },
          {
               field: PaymentItemModelKeys.POC_ID,
               headerName: 'Reports',
               minWidth: 200,
               renderCell: (params: GridRenderCellParams) => <ArchiveCell params={params} archivePOC={archivePOC} archivePOD={archivePOD}/>,
               flex: 1,
               renderHeader: () => <div className='grid-custom-header'>Reports</div>,
          },
          {
               field: PaymentItemModelKeys.PaymentID,
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
               minWidth: width,
               flex: 1,
               renderHeader: () => <div className='grid-custom-header'>Payment ID</div>,
          },
          {
               field: PaymentItemModelKeys.QuoteAmount,
               headerName: 'Job Cost',
               renderCell: (params: GridRenderCellParams) => renderColoredCurrencyCell(params),
               flex: 1,
               sortable: false,
          },
          {
               field: PaymentItemModelKeys.SupplyingMemberFeesVat,
               headerName: 'VAT',
               renderCell: (params: GridRenderCellParams) => 'N/A',
               minWidth: width,
               flex: 1,
               sortable: false,
          },
          {
               field: PaymentItemModelKeys.SupplyingMemberFees,
               headerName: 'Our Fees',
               renderCell: (params: GridRenderCellParams) => renderColoredCurrencyCell(params),
               minWidth: width,
               flex: 1,
               sortable: false,
          },
          {
               field: 'VAT',
               headerName: 'VAT',
               renderCell: (params: GridRenderCellParams) => renderVatColoredCurrencyCell(params),
               minWidth: width,
               flex: 1,
               sortable: false,
          },
          {
               field: PaymentItemModelKeys.SupplyingMemberTotal,
               headerName: 'Total Payable',
               renderCell: (params: GridRenderCellParams) => renderTotalPayableColoredCurrencyCell(params),
               minWidth: width,
               renderHeader: () => <div className='grid-custom-header'>Total Payable</div>,
               flex: 1,
          },
          {
               field: PaymentItemModelKeys.SupplyingMemberInvoiceSentDate,
               headerName: 'Invoiced',
               renderCell: (params: GridRenderCellParams) => getInvoicedColumn(params),
               flex: 1,
          },
          {
               field: PaymentItemModelKeys.SupplierPaidDate,
               headerName: 'S/Paid',
               renderCell: (params: GridRenderCellParams) => getSupplierPaidDateColumn(params),
               flex: 1,
          },
          {
               field: PaymentItemModelKeys.MovexPaidDate,
               headerName: 'M/Paid',
               renderCell: (params: GridRenderCellParams) => getMovexPaidDateColumn(params),
               flex: 1,
          },
          {
               field: PaymentItemModelKeys.DueDate,
               headerName: 'Due',
               renderCell: (params: GridRenderCellParams) => renderDateCell(params),
               flex: 1,
          },
          {
               field: 'PaymentStatus',
               renderCell: (params: GridRenderCellParams) => {
                    return getPaymentStatusColumn(params, showCheckPaymentModal, showUncheckPaymentModal);
               },
               renderHeader: () => <div className='grid-custom-header'>Payment Status</div>,
               minWidth: width,
               flex: 1,
          },
          {
               align: 'center',
               description: 'Action',
               field: 'Action',
               flex: 1,
               headerName: 'Action',
               sortable: false,
               renderCell: (params: GridRenderCellParams) => <PaymentsGridActionMenu
                    params={params}
                    onShowDialog={onShowDialog}
                    showPaymentNotesModule={showPaymentNotesModule}
                    showPaymentDueDateModal={showPaymentDueDateModal}
                    showPayNowModal={showPayNowModal}
                    showRefundPrePaymentModal={showRefundPrePaymentModal}
                    showMarkPaymentAsNotPayableModal={showMarkPaymentAsNotPayableModal}
                    showAlert={showAlert}
                    handleAlertClose={handleAlertClose}
               />
          },
     ];
};