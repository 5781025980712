import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Loading } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { UpdatePaymentAdminNotesModel } from '../../../../../Models/Jobs';
import { usePutUpdatePaymentAdminNotes } from '../Hooks';
import { PaymentNoteKey } from '../Types';
import { ColWrapper, ErrorContainer, RowWrapper } from './style/PaymentModuleStyledComponents';

export const PaymentNotesModalBody: React.FC<GeneralFormProps> = ({
     paymentId,
     paymentNote,
     onClose,
     onRefresh,
}): JSX.Element => {
     /** States */
     const [paymentNoteUpdated, setPaymentNoteUpdated] = React.useState(false);

     /** Query Hooks */
     const { mutate: putPaymentNotes, isLoading, data, error, isSuccess } = usePutUpdatePaymentAdminNotes();

     useEffect(() => {
          if (data) {
               setPaymentNoteUpdated(true);
               onRefresh();
          }
          onRefresh();
     }, [data]);

     /** Formik */
     const formik = useFormik({
          initialValues: { AdminNotes: paymentNote },
          onSubmit: () => {
               const addPaymentNotesModel: UpdatePaymentAdminNotesModel = {
                    PaymentId: paymentId,
                    AdminNotes: values.AdminNotes,
               };
               putPaymentNotes(addPaymentNotesModel);
          },
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     return (
          <>
               {' '}
               <Loading loading={isLoading} />
               {paymentNoteUpdated && isSuccess && <div>Payment Note updated!</div>}
               {error && !isSuccess && <ErrorContainer>{error.Subject}: {error.Description}</ErrorContainer>}
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         {!paymentNoteUpdated && (
                              <Row>
                                   <FormikControl control={FormControlKeys.Textarea} name={PaymentNoteKey.AdminNotes} />
                              </Row>
                         )}
                         <RowWrapper>
                              {!paymentNoteUpdated && (
                                   <ColWrapper>
                                        <FormikControl
                                             name='Submit'
                                             label='Submit'
                                             type='submit'
                                             control={FormControlKeys.Button}
                                             defaultValue={paymentNote}
                                        />
                                   </ColWrapper>
                              )}
                              <ColWrapper>
                                   <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                              </ColWrapper>
                         </RowWrapper>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     paymentId: number;
     paymentNote: string;
     onClose: Function;
     onRefresh: Function;
};
