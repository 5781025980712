import { GridRenderCellParams } from "@mui/x-data-grid";

export default function NextExpiringQuote({ value }: GridRenderCellParams) {
     let quoteExpiry = new Date(value).getTime();
     let differenceInMilliseconds = quoteExpiry - Date.now();

     if(differenceInMilliseconds <= 0) {
          return null;
     }

     let differenceInMinutes = differenceInMilliseconds / 1000 / 60;

     let displayHours = Math.floor(differenceInMinutes / 60);
     let displayMinutes = Math.floor(differenceInMinutes % 60);

     let hourText = "";
     let minuteText = "";

     if (displayHours >= 1)
          hourText += `${displayHours} hr${displayHours > 1 ? "s" : ""} `;

     if (displayMinutes >= 1)
          minuteText += `${displayMinutes} min${displayMinutes > 1 ? "s" : ""}`;

     const additionalStyle = displayHours < 1 ? 'text-danger' : '';
     return (
          <strong className={`${additionalStyle}`}>
               {displayHours >= 1 ? hourText : ""}
               {displayHours >= 1 && displayMinutes >= 1 ? <br /> : ""}
               {displayMinutes >= 1 ? minuteText : ""}
          </strong>
     )
};