import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UpdatePaymentAdminNotesModel } from '../../../../../Models/Jobs/Payments';

export const usePutUpdatePaymentAdminNotes = (
  options?: any
): UseMutationResult<
  any,
  any,
  UpdatePaymentAdminNotesModel
> => {

  const putUpdatePaymentAdminNotes = (payload: UpdatePaymentAdminNotesModel) => {
    return agent.payments.putUpdatePaymentAdminNotes(payload);
  };

  return useMutation(putUpdatePaymentAdminNotes);
};