import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetPricesByTypeAndName = (Type: number, Name: string, options?: any): UseQueryResult<any, any> => {
     const getPricesByTypeAndName = () => {
          return agent.priceGroup.getPricesByTypeAndName({ Type, Name });
     };

     return useQuery(['GetPricesByTypeAndName', { Type, Name }], getPricesByTypeAndName, {
          enabled: !!Name && Name.length >= 3,
          ...options,
     });
};
