import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { JobResponseModel } from "../../../../../Models/Jobs/EditJob";

export const useGetJob = (
  jobID: number,
  options?: any
): UseQueryResult<JobResponseModel, any> => {
  const getJob = () => {
    if (jobID) {
      return agent.jobs.getJob(jobID);
    }
  };

  return useQuery(["GetJob", jobID], getJob, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
