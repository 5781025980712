import axios from 'axios';
import { responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetCustomerSetForDropdown_URL,
    GetLanguages_URL,
} = ApiURLConstants;

export const options = {
    getCustomerSetForDropdown: (): Promise<any> => {
        return axios
            .get(`${GetCustomerSetForDropdown_URL}`)
            .then(responseBody)
    },

    getLanguagesForDropdown: (): Promise<any> => {
        return axios
            .get(`${GetLanguages_URL}`)
            .then(responseBody)
    },
};