import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import { CellRenderer, HoverableLink } from '../../../../../Components/Formik';
import { ControlledPagination as Pagination } from '../../../../../Components/Pagination';
import { PartnersResponseModel } from '../../../../../Models/Partners';
import { RouteConstants } from '../../../../../Routes/Constants';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter, ActionColumnPath } from '../../../../../Utils';
import { PartnerGridKeys } from '../Types';

const { renderCellWithTooltip, renderPartnerNameCell } = CellRenderer;

const ViewPartnersGridView: React.FC<PartnerGridViewProps> = (props) => {
  const {
    data: partnerList,
    onPageChange,
    onPageSizeChange,
    onDelete,
    onSort,
  } = props;

  const columns: GridColDef[] = [
    {
      field: PartnerGridKeys.ID,
      headerName: 'ID',
      description: 'ID',
      renderCell: (params: GridRenderCellParams) =>
        <HoverableLink
          to={`${RouteConstants.EditPartner}/${params.id}`}
          style={{ paddingLeft: '10px' }}>
          {params.id}
        </HoverableLink>,
      flex: 2,
    },
    {
      field: PartnerGridKeys.Name,
      headerName: 'Name',
      description: 'Name',
      flex: 6,
      renderCell: (params: GridRenderCellParams) => renderPartnerNameCell(params),
      sortable: false,
    },
    {
      field: PartnerGridKeys.Email,
      headerName: 'Email',
      description: 'Email',
      flex: 6,
      renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
      sortable: false,
    },
    {
      field: PartnerGridKeys.IsActive,
      description: 'Is Active',
      headerName: 'Is Active',
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? 'Yes' : 'No';
      },
      flex: 4,
    },
    {
      field: ActionColumnPath,
      description: 'Action',
      headerName: 'Action',
      type: 'actions',
      flex: 6,
      renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    },
  ];

  const renderActionCell = (params: GridRenderCellParams) => {
    const partnerId = parseInt(params.id.toString());
    return (
      <React.Fragment>
        <Tooltip title="Edit Partner">
          <HoverableLink
            to={`${RouteConstants.EditPartner}/${params.id}`}
            style={{ paddingLeft: '10px', alignContent: 'left', boxSizing: 'border-box'}}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </HoverableLink>
        </Tooltip>
        <Tooltip title="Delete Partner">
          <a
            href="#"
            style={{ paddingLeft: '10px', width: '100%', alignContent: 'left' }}
            onClick={() => {
              onDelete(partnerId);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </Tooltip>
      </React.Fragment>
    );
  };

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (partnerList) {
    const { Page: page, TableSorter } = partnerList;
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = page;

    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            currentPage={CurrentPage}
            pageSize={ItemsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    return (
      <div>
        {page && (
          <div className="partners-container">
            <div id='id_grid_partners' style={{ display: 'flex', height: '100%', width: '100%' }}>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                autoHeight
                getRowId={(row: any) => row.Id}
                rows={Items}
                rowHeight={41}
                disableColumnMenu={true}
                columns={columns}
                columnBuffer={columns.length + 1}
                components={{
                  Toolbar: CustomGridFooter,
                  Footer: CustomGridFooter,
                }}
                disableExtendRowFullWidth={true}
                sortingMode="server"
                sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                onSortModelChange={handleSort}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ViewPartnersGridView;

interface PartnerGridViewProps extends GridProps<PartnersResponseModel> {
  onDelete: (partnerID: number) => void;
}