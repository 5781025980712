export interface JobsByUserItemModel {
  ActualJobType: string;
  ActualServiceType: string;
  CancellationReason: string;
  CollectionDate: string;
  CollectionPostCode: string;
  Company: string;
  Cost: number;
  CostExclusive: number;
  CostExclusivePerMile: number;
  CostExclusivePerMilePerVehicle: number;
  CostPerMile: number;
  CostPerMilePerVehicle: number;
  DeliveryDate: string;
  DeliveryPostCode: string;
  DistanceMiles: number;
  Email: string;
  Id: number;
  IncludingFuel: string;
  JobStatus: string;
  JobType: number;
  MemberType: number;
  NoOfQuotes: number;
  NoOfVehicles: number;
  RequestDate: string;
  ServiceType: number;
  SignupDate: number;
  SignupDate2: number;
  TotalRecords: number;
  TransportCompanyName: string;
  UserName: string;
  UserName2: string;
  Vat: number;
}

export const JobsByUserItemModelKeys = {
  ActualJobType: "ActualJobType",
  ActualServiceType: "ActualServiceType",
  CancellationReason: "CancellationReason",
  CollectionDate: "CollectionDate",
  CollectionPostCode: "CollectionPostCode",
  Company: "Company",
  Cost: "Cost",
  CostExclusive: "CostExclusive",
  CostExclusivePerMile: "CostExclusivePerMile",
  CostExclusivePerMilePerVehicle: "CostExclusivePerMilePerVehicle",
  CostPerMile: "CostPerMile",
  CostPerMilePerVehicle: "CostPerMilePerVehicle",
  DeliveryDate: "2DeliveryDate",
  DeliveryPostCode: "DeliveryPostCode",
  DistanceMiles: "DistanceMiles",
  Email: "Email",
  Id: "Id",
  IncludingFuel: "IncludingFuel",
  JobStatus: "JobStatus",
  JobType: "JobType",
  MemberType: "MemberType",
  NoOfQuotes: "NoOfQuotes",
  NoOfVehicles: "NoOfVehicles",
  RequestDate: "RequestDate",
  ServiceType: "ServiceType",
  SignupDate: "SignupDate",
  SignupDate2: "SignupDate2",
  TotalRecords: "TotalRecords",
  TransportCompanyName: "TransportCompanyName",
  UserName: "UserName",
  UserName2: "UserName2",
  Vat: "Vat",
};
