import { AppThunkAction } from "../../../App";
import { MembersActionTypes } from './Types';

export const membersActionCreators = {
    showScrollbar: (show: boolean): AppThunkAction<MembersActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "SHOW_GENERAL_SCROLLBAR", payload: show })
        }
    }
}

















