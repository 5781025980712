/** Import inbuilt libraries*/
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
/** Import custom components*/
import { ControlledPagination as Pagination } from '../../../../../../Components';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { PageGridKeys, PageModel } from '../../../../../../Models/System/Pages';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { GridProps } from '../../../../../../Types';
import { ActionColumnPath } from '../../../../../../Utils';

const ViewPagesGrid: React.FC<ViewPagesGridProps> = (props) => {
  const { data, onPageChange, onPageSizeChange, onSort } = props;

  const renderPageNameCell = (params: GridRenderCellParams) => {
    const editPageByNameLink = RouteConstants.EditPageByName;
    const row = params.row as PageModel;
    const { IsReserved, Name } = row;
    return IsReserved ? (
      <>
        {Name}
        <i>(reserved)</i>
      </>
    ) : (
      <a href={`${editPageByNameLink}=${Name}`} title={`Edit Page : ${Name}`}>
        {Name}
      </a>
    );
  };

  const renderActionCell = (params: GridRenderCellParams) => {
    const editPageByNameLink = RouteConstants.EditPageByName;
    const deletePageByNameLink = RouteConstants.DeletePageByName;
    const row = params.row as PageModel;
    const { IsReserved, Name } = row;
    return IsReserved ? (
      <>
        <Tooltip title={`View Page: ${Name}`}>
          <HoverableLink to={`/${Name}`} style={{ paddingLeft: '10px',  alignContent: 'left', width: '100%' }}>
            View
          </HoverableLink>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip title={`View Page: ${Name}`}>
          <HoverableLink to={`/${Name}`} style={{ paddingLeft: '10px', alignContent: 'left', boxSizing: 'border-box' }}>
            View
          </HoverableLink>
        </Tooltip>
        <Tooltip title={`Edit Page: ${Name}`}>
          <HoverableLink
            to={`/${editPageByNameLink}=${Name}`}
            style={{ paddingLeft: '10px',  boxSizing: 'border-box', alignContent: 'left' }}
          >
            Edit
          </HoverableLink>
        </Tooltip>
        <Tooltip title={`Delete Page: ${Name}`}>
          <HoverableLink
            to={`/${deletePageByNameLink}=${Name}`}
            style={{ paddingLeft: '10px', width: '100%', alignContent: 'left' }}
          >
            Delete
          </HoverableLink>
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      field: PageGridKeys.Name,
      headerName: 'Name',
      description: 'Name',
      renderCell: (params: GridRenderCellParams) => renderPageNameCell(params),
    },
    {
      field: ActionColumnPath,
      headerName: 'Action',
      flex: 2,
      description: 'Action',
      type: 'actions',
      renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    },
  ];

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (data) {
    const { Page, TableSorter } = data;
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = Page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      );
    };

    return (
      <div>
        {Page && (
          <div id='id_grid_pages' className="view-pages-grid-container">
            <DataGrid
              sortingOrder={['desc', 'asc']}
              autoHeight
              getRowId={(row: any) => row.Id}
              disableColumnMenu={true}
              rows={Items}
              rowHeight={30}
              columns={columns}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

interface ViewPagesGridProps extends GridProps<any> {}

export default ViewPagesGrid;
