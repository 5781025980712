import { MemberType, PaymentMethod, PaymentStatus } from "../../Utils/Enums";

export type BillViewModel = {
    AbsoluteTotal:number,
    BillType: string;
    Id: number;
    InvoiceFileName: boolean;
    MemberType: MemberType;
    MemberPaymentMethod: PaymentMethod;
    MemberPaymentReference: string;
    PaymentStatus: PaymentStatus;
    CorrectedTotal: number;
    MemberUserName: string;
    Version: number;
    InvoiceDate: string;
    BilledMemberID: number;
}

export const BillModelKeys = {
    AbsoluteTotal:'AbsoluteTotal',
    BillType: "BillType",
    InvoiceDate: "InvoiceDate",
    Id: "Id",
    InvoiceFileName: "InvoiceFileName",
    MemberType: "MemberType",
    MemberPaymentMethod: "MemberPaymentMethod",
    MemberPaymentReference: "MemberPaymentReference",
    PaymentStatus: "PaymentStatus",
    CorrectedTotal: "CorrectedTotal",
    MemberUserName: "MemberUserName",
    BilledMemberID: "BilledMemberID",
    Version: "Version"
}