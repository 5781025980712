export interface BlockedMemberModel {
    BlockedDate?: Date;
    BlockedMemberCompany?: string;
    BlockedMemberID?: number;
    BlockedMemberUserName?: string;
    BlockedReason?: string;
    BlockingMemberCompany?: string;
    BlockingMemberID?: number;
    BlockingMemberUserName?: string;
    Id: number;
}

export const BlockMemberModelKeys = {
    BlockedDate: 'BlockedDate',
    BlockedMemberCompany: 'BlockedMemberCompany',
    BlockedMemberID: 'BlockedMemberID',
    BlockedMemberUserName: "BlockedMemberUserName",
    BlockedReason: 'BlockedReason',
    BlockingMemberCompany: 'BlockingMemberCompany',
    BlockingMemberID: 'BlockingMemberID',
    BlockingMemberUserName: "BlockingMemberUserName",
    ID: "ID"
}