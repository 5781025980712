import { GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  Alert,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  useAlert,
} from "../../../../Components";
import { renderHeader } from "../../../../Components/PageHeader";
import { TaskRequestParam } from "../../../../Models/Tasks/Request";
import { TasksGridFilter } from "../../../../Models/Tasks/TasksGridFilter";
import { TaskGridKeys } from "../../../../Models/Tasks/TasksViewModel";
import { Pager } from "../../../../Types";
import { getSorterFromSortModel, Page } from "../../../../Utils";
import FilterBox from "./FilterBox";
import GridView from "./Grid";
import { useGetTask } from "./Hooks";
import FormikControl from "../../../../Components/Formik/FormikControl";
import { FormControlKeys } from "../../../../Components/FormControls";
import { NavbarMenusRoutes } from "../../../../Components/Navbar/Constants";
import { useHistory } from "react-router-dom";
import { MarginBottomDiv } from "../../../../Components/UI/SharedComponents.styles";

const ViewTasks: React.FC = (): JSX.Element => {
  let history = useHistory();

  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  /** useStates */
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: "",
      body: "",
      cancelCallback: onConfirmDialogClose,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<TasksGridFilter>({});
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: TaskGridKeys.ID,
      sort: "desc",
    },
  ]);
  /** Methods */
  const getParams = () => {
    const params: TaskRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const handleSearch = (filter: TasksGridFilter) => {
    setPageLoaded(true);
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setPageLoaded(true);
    setFilter({});
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
  };

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const { 
    refetch: fetchAdminTasks, 
    data: getTasksResponse, 
    isLoading: isGetTaskLoading,
    error: getTasksError
   } = useGetTask(getParams());

  const isDataLoaded = getTasksResponse !== null;

  useEffect(() => {
    if (pageLoaded) {
      fetchAdminTasks();
    }
  }, [pageLoaded, pager, filter, sortModel]);

  useEffect(() => {
    setIsLoading(isGetTaskLoading);
  }, [isGetTaskLoading]);

  useEffect(() => {
      if (getTasksError) {
          setAlert({
              ...alert,
              show: true,
              header: getTasksError?.Subject ?? 'Error when trying to fetch tasks',
              body: getTasksError?.Description ?? 'Error when trying to fetch tasks.'
          });
      }
  }, [getTasksError]);

  return (
    <div>
      {renderHeader("Tasks")}
      <div className="container-fluid">
        <Alert {...alert} />
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
        {!isDataLoaded && (
          <div className="please-search-placeholder">
            <p className="please-search-text">
              Please perform a search to return some results.
            </p>
          </div>
        )}

        <MarginBottomDiv>
          <FormikControl
            name='AddTask'
            control={FormControlKeys.Button}
            label='+ Create a new Task'
            onClick={() => {
              history.push(`/${NavbarMenusRoutes.AddTaskRoute}`);
            }}
          />
        </MarginBottomDiv>

        <Loading loading={isLoading} />
        <ConfirmDialog {...confirmDialogContent}></ConfirmDialog>
        {(isDataLoaded && getTasksResponse) && (
          <GridView
            data={getTasksResponse}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onSort={onSort}
          />
        )}
      </div>
    </div>
  );
};

export default ViewTasks;
