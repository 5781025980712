import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { FormikProvider, useFormik } from 'formik';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../Components/Formik/StyledComponents';
import { DealerMarginKeys } from './Types';

export const MarginGrid = ({
     data,
     marginGridType,
     editRowIdWithTypeMap,
     onSubmit,
     onCancelEdit,
     onEditSavedData,
     onDeleteSavedData,
     parentKeyId,
     isCustomerSet,
     editingItem
}: MarginGridProps) => {
     const formik = useFormik({
          initialValues: {
               PriceTo: editingItem ? editingItem.AmountTo : undefined,
               Margin: editingItem ? editingItem.MemberFee : undefined,
             },
          enableReinitialize: true,
          onSubmit: () => {},
     });

     return (
          <FormikProvider value={formik} key={'key_'+marginGridType}>
               <FormContainer>
                    <table id='id_table_margin_grid' className='table table-bordered table-condensed table-striped'>
                         <thead>
                              <tr>
                                   <td>#</td>
                                   <td>Price To</td>
                                   <td>Margin</td>
                                   <td>Action</td>
                              </tr>
                         </thead>
                         <tbody>
                              {(parentKeyId !== 0 || !isCustomerSet) && data?.map((item: any, index: number) => {
                                   const {
                                        RequestingMemberFeeFixedRangeID,
                                        RequestingMemberFeeQuoteRangeID,
                                        MemberFee,
                                        AmountTo,
                                   } = item;
                                   const Id =
                                        RequestingMemberFeeFixedRangeID ??
                                        RequestingMemberFeeQuoteRangeID;

                                   const rowId = index + 1;
                                   const isEditRow =
                                        editRowIdWithTypeMap.get(marginGridType) === Id;

                                   return (
                                        <tr key={'key_' + rowId + ' ' + marginGridType} className={isEditRow ? 'edit-row' : 'display-row'}>
                                             <td>{rowId}</td>
                                             <td>
                                                  {!isEditRow && <span>£ {AmountTo}</span>}
                                                  {isEditRow && (
                                                       <FormikControl
                                                            name={DealerMarginKeys.PriceTo}
                                                            marginGridType='number'
                                                            onChange={(e: any) => {
                                                                 formik.setFieldValue(
                                                                      DealerMarginKeys.PriceTo,
                                                                      e.target.value
                                                                 );
                                                            }}
                                                       />
                                                  )}
                                             </td>
                                             <td>
                                                  {!isEditRow && <span>£ {MemberFee}</span>}
                                                  {isEditRow && (
                                                       <FormikControl
                                                            name={DealerMarginKeys.Margin}
                                                            marginGridType='number'
                                                            onChange={(e: any) => {
                                                                 formik.setFieldValue(
                                                                      DealerMarginKeys.Margin,
                                                                      e.target.value
                                                                 );
                                                            }}
                                                       />
                                                  )}
                                             </td>
                                             {isEditRow && (
                                                  <td>
                                                       <span title='Save'>
                                                            <DoneIcon
                                                                 className='cursor-pointer'
                                                                 onClick={() => {
                                                                      const payload = {
                                                                           RequestingMemberFeeFixedRangeID: item.RequestingMemberFeeFixedRangeID,
                                                                           RequestingMemberFeeQuoteRangeID: item.RequestingMemberFeeQuoteRangeID,
                                                                           RequestingMemberFeeType: isCustomerSet === false ? 0 : marginGridType,
                                                                           ParentKeyId: parentKeyId,
                                                                           AmountTo: formik.values.PriceTo ?? 0,
                                                                           MemberFee: formik.values.Margin ?? 0
                                                                      } as AddOrUpdateRequestingMemberFeeFixedRanges;
                                                                      onSubmit({ ...payload }, marginGridType);
                                                                 }}
                                                            />
                                                       </span>
                                                       |
                                                       <span title='Cancel'>
                                                            <CloseIcon
                                                                 className='cursor-pointer'
                                                                 onClick={() => {
                                                                      onCancelEdit(
                                                                           marginGridType,
                                                                           Id
                                                                      );
                                                                 }}
                                                            ></CloseIcon>
                                                       </span>
                                                  </td>
                                             )}
                                             {!isEditRow && (
                                                  <td>
                                                       <span title='Edit Requesting Member Fee'>
                                                            <EditIcon
                                                                 className='cursor-pointer'
                                                                 onClick={() => {
                                                                      onEditSavedData(
                                                                           marginGridType,
                                                                           Id,
                                                                           AmountTo,
                                                                           MemberFee
                                                                      );
                                                                 }}
                                                            />
                                                       </span>
                                                       |
                                                       <span title='Delete Requesting Member Fee'>
                                                            <DeleteIcon
                                                                 className='cursor-pointer'
                                                                 onClick={() => {
                                                                      onDeleteSavedData(
                                                                           marginGridType,
                                                                           Id
                                                                      );
                                                                 }}
                                                            />
                                                       </span>
                                                  </td>
                                             )}
                                        </tr>
                                   );
                              })}
                         </tbody>
                         {((data?.length === 0 || parentKeyId === 0) && isCustomerSet) && (
                              <tbody>
                                   <tr>
                                        <td colSpan={12}>No details found!</td>
                                   </tr>
                              </tbody>
                         )}
                    </table>
               </FormContainer>
          </FormikProvider>
     );
};

interface MarginGridProps {
     isCustomerSet: boolean,
     data: any;
     marginGridType: any;
     editRowIdWithTypeMap: any;
     onSubmit: Function;
     onCancelEdit: Function;
     onEditSavedData: Function;
     onDeleteSavedData: Function;
     parentKeyId: number;
     editingItem: {AmountTo: number, MemberFee: number};
}

interface AddOrUpdateRequestingMemberFeeFixedRanges {
     RequestingMemberFeeFixedRangeID?: number;
     RequestingMemberFeeQuoteRangeID?: number;
     AmountTo: number;
     MemberFee: number;
     RequestingMemberFeeType: number;
     ParentKeyId?: number;
     isFixedPrice: boolean;
}
