import { GridSortModel } from "@mui/x-data-grid";
import React,{ useEffect,useState } from "react";
import { Alert,Loading,renderHeader,useAlert } from "../../../../Components";
import useHandleGridFilterCache,{ GridFilterKeys } from "../../../../Components/savedFilterHook/useHandleGridFilterCache";
import { GroupModelKeys,GroupsRequestParam } from "../../../../Models/Groups";
import { FreeTextFilter,Pager } from "../../../../Types";
import { Page } from "../../../../Utils/Constants";
import { SqlStringMatchType } from "../../../../Utils/Enums/SqlStringMatchType";
import { getSorterFromSortModel } from "../../../../Utils/Helper";
import FilterBox from "./FilterBox";
import GroupsGridView from "./Grid";
import { useGetGroups } from "./Hooks";
import { FreeTextSearchByOptions } from "../../../../Utils/Enums/FreeTextSearchBy";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../Store/Types";

const ViewGroups: React.FC<any> = (): JSX.Element => {
  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { savedFilter, saveFilterInCache } = useHandleGridFilterCache({
    key: GridFilterKeys.GroupFilterKey,
    defaultFilter: {
      SqlStringMatchType: SqlStringMatchType.Contains,
      FreeTextSearchBy: FreeTextSearchByOptions.Username,
    },
  });

  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: GroupModelKeys.Id,
      sort: "asc",
    },
  ]);

  const lastUpdatedGridState = useSelector(
    (state: ApplicationState) => state.gridsFilterState.savedFilters!
  );


  /** Methods */
  const getParams = () => {
    const params: GroupsRequestParam = {
      Pager: pager,
      Filter: {...savedFilter},
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: FreeTextFilter) => {
       saveFilterInCache({ key: GridFilterKeys.GroupFilterKey, filterData: { ...filter } });
  };

  const handleClearSearch = () => {
       saveFilterInCache({
            key: GridFilterKeys.GroupFilterKey,
            filterData: {
                 SqlStringMatchType: SqlStringMatchType.Contains,
                 FreeTextSearchBy: FreeTextSearchByOptions.Username,
            },
       });
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setPager({ ...pager, CurrentPage: 1 });
    setSortModel(sortModel);
  };

  /** Hooks */
  const { alert, setAlert } = useAlert();
  const {
    isLoading: isGetBillingsLoading,
    data: groups,
    refetch: fetchGroups,
    error: getBillingsError,
  } = useGetGroups(getParams());

  const dataLoaded = groups !== null;

  /** useEffects */
  useEffect(() => {
    if(lastUpdatedGridState.key !== GridFilterKeys.GroupFilterKey){
      handleClearSearch();
    }
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [pager, savedFilter, sortModel]);

  useEffect(() => {
    setIsLoading(isGetBillingsLoading);
  }, [isGetBillingsLoading]);

  useEffect(() => {
    if (getBillingsError) {
      setAlert({
        ...alert,
        show: true,
        header: 'Error when getting feedback',
        body: getBillingsError?.Description,
      });
    }
  }, [getBillingsError]);

  return (
    <div>
      {renderHeader("Groups")}
      <Alert {...alert} />
      <div className="container-fluid">
        <FilterBox filter={lastUpdatedGridState.filterData as FreeTextFilter} onSearch={handleSearch} onClearSearch={handleClearSearch} />
        {!dataLoaded && (
          <div className="please-search-placeholder">
            <p className="please-search-text">
              Please perform a search to return some results.
            </p>
          </div>
        )}
        {!groups?.Page && (
          <div className="please-search-placeholder">
            <p className="please-search-text">No matching results found.</p>
          </div>
        )}
        <Loading loading={isLoading} />
        {(dataLoaded && groups) && (
          <GroupsGridView
            data={groups}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewGroups;
