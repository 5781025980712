import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { MakePaymentNowModel } from '../../../../../Models/Jobs';

export const usePutMakePaymentNow = (options?: any): UseMutationResult<any, any, any> => {
     const putMakePaymentNow = (payload: MakePaymentNowModel) => {
          return agent.payments.putPayNow(payload);
     };

     return useMutation(putMakePaymentNow);
};
