import { GridSortModel } from '@mui/x-data-grid';
import React,{ useEffect,useState } from 'react';
import { Alert,ConfirmDialog,ConfirmDialogProps,Loading,renderHeader, useAlert } from '../../../../Components';
import { SavedSearchesFilter } from '../../../../Models/Searches';
import { CommonRequestParam,Pager } from '../../../../Types';
import { getSorterFromSortModel } from '../../../../Utils';
import { ConfirmDialogContent, Page } from '../../../../Utils/Constants';
import FilterBox from './FilterBox';
import ViewSearchesGridView from './Grid';
import { useGetSavedSearches } from './Hooks';
import { useDeleteSavedSearch } from './Hooks/useDeleteSavedSearch';
import './index.css';
import { SearchesGridKeys } from './Types';
import { FreeTextSearchByOptions } from '../../../../Utils/Enums/FreeTextSearchBy';

const ViewSaveSearches: React.FC = (): JSX.Element => {
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [filter, setFilter] = useState<SavedSearchesFilter>({ FreeTextSearchBy: FreeTextSearchByOptions.Username});
     const [pager, setPager] = useState<Pager>({
          CurrentPage: Page.CurrentPage,
          ItemsPerPage: Page.PageSize,
     });
     const [sortModel, setSortModel] = useState<GridSortModel>([
          {
               field: SearchesGridKeys.Id,
               sort: 'asc',
          },
     ]);

     const getParams = () => {
          const params: CommonRequestParam = {
               Pager: pager,
               Filter: filter,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hooks */
     const {
          data: getSavedSearchesResponse,
          isLoading: isGetSavedSearchesLoading,
          refetch: refetchSavedSearchesList,
          error: getSavedSearchesError,
     } = useGetSavedSearches(getParams());

     const { 
          mutate: deleteSavedSearch, 
          isLoading: isDeleteSavedSearchLoading, 
          isSuccess: deleteSavedSearchIsSuccess,
          error : deleteSavedSearchError
     } = useDeleteSavedSearch();

     const isDataLoaded = getSavedSearchesResponse !== null;

     /** useEffects */
     useEffect(() => {
          refetchSavedSearchesList();
     }, [pager, filter, sortModel]);

     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     const [confirmDialogContent, setConfirmDialogContent] =
     useState<ConfirmDialogProps>({
       header: '',
       body: '',
       cancelCallback: onConfirmDialogClose,
     });

     useEffect(() => {
          if (deleteSavedSearchIsSuccess) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Saved search deleted',
                    body: 'Saved search deleted successfully',
               });
               refetchSavedSearchesList();
          }
     }, [deleteSavedSearchIsSuccess]);

     useEffect(() => {
          if (deleteSavedSearchError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: deleteSavedSearchError.Subject,
                    body: deleteSavedSearchError.Description,
               });
          }
     }, [deleteSavedSearchError]);

     useEffect(() => {
          setIsLoading(isGetSavedSearchesLoading || isDeleteSavedSearchLoading);
     }, [isGetSavedSearchesLoading, isDeleteSavedSearchLoading]);

     useEffect(() => {
          if (getSavedSearchesError) {
               setAlert({
               ...alert,
               show: true,
               header: getSavedSearchesError?.Subject ?? 'Unexpected Error',
               body: getSavedSearchesError?.Description ?? 'An unexpected error occurred while fetching the saved searches.',
               });
          }
     }, [getSavedSearchesError]);
        

     const handleSearch = (filter: SavedSearchesFilter) => {
          setFilter(filter);
     };

     const handleClearSearch = () => {
          setFilter({
               FreeTextSearchBy: FreeTextSearchByOptions.Username,
          });
     };

     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
     };

     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
     };

     const onSort = (sortModel: GridSortModel) => {
          setSortModel(sortModel);
          setPager({ ...pager, CurrentPage: 1 });
     };

     const onDelete = (searchId: number) => {
          setConfirmDialogContent({
            ...confirmDialogContent,
            header: ConfirmDialogContent.DeleteSavedSearch.Title,
            body: ConfirmDialogContent.DeleteSavedSearch.Content,
            show: true,
            confirmCallback: () => {
               deleteSavedSearch(searchId);
              onConfirmDialogClose();
            },
          });
        };
      

     return (
          <div>
               {renderHeader('Searches')}
               <div className='container-fluid'>
                    <ConfirmDialog {...confirmDialogContent} />
                    <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
                    <Loading loading={isLoading} />
                    <Alert {...alert} />
                    {isDataLoaded && getSavedSearchesResponse && (
                         <ViewSearchesGridView
                              data={getSavedSearchesResponse}
                              onSort={onSort}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onDelete={onDelete}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewSaveSearches;
