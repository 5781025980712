import axios from 'axios';
import { AddSavedSearchModel, GetSavedSearchesRequestParam } from '../../../Models/Searches';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetSavedSearches_URL,
    GetSavedSearch_URL,
    PutUpdateSavedSearch_URL,
    PostAddSavedSearch_URL,
    DeleteSavedSearch_URL
} = ApiURLConstants;

export const savedSearches = {
    getSavedSearches: (params: GetSavedSearchesRequestParam): Promise<any> => {
        return axios.post(GetSavedSearches_URL, params)
            .then(responseBody)
    },

    getSavedSearch: (savedSearchID: number): Promise<any> => {
        return axios.get(`${GetSavedSearch_URL}?savedSearchID=${savedSearchID}`).then(responseBody);
    },

    postAddSavedSearch: (params: AddSavedSearchModel): Promise<any> => {
        return axios
            .post(`${PostAddSavedSearch_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    putUpdateSavedSearch: (params: AddSavedSearchModel): Promise<any> => {
        return axios
            .put(`${PutUpdateSavedSearch_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    deleteSavedSearch: (savedSearchID: number): Promise<any> => {
        return axios.delete(`${DeleteSavedSearch_URL}?savedSearchID=${savedSearchID}`)
            .then(responseBody)
    },
}