import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EditPriceDetails } from '../../../../../Models/PriceGroup/EditPriceDetails';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddPrice = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, EditPriceDetails> => {
     const addPrice = (payload: EditPriceDetails) => {
          return agent.priceGroup.postAddPrice(payload);
     };

     return useMutation(addPrice);
};
