import { TaskSearchType } from "../../Utils/Enums/TaskSearchType";

export interface TasksGridFilter {
    TaskType? : number,
    AssignedTo? : number,
    CreatedBetweenStart? : Date,
    CreatedBetweenEnd? : Date,
    DueBetweenStart? :  Date,
    DueBetweenEnd? : Date,
    Status? : number,
    LastStatusChangedStart? : Date,
    LastStatusChangedEnd? : Date,
    InProgress? : boolean,
    FiltersChanged? : boolean,
    SearchType? : TaskSearchType,
    SearchTerm? : string,
}

export const TasksGridFilterKeys = {
    SearchType: "SearchType",
    SearchTerm: "SearchTerm",
    TaskType : "TaskType",
    AssignedTo : "AssignedTo",
    CreatedBetweenStart : "CreatedBetweenStart",
    CreatedBetweenEnd : "CreatedBetweenEnd",
    DueBetweenStart :  "DueBetweenStart",
    DueBetweenEnd : "DueBetweenEnd",
    Status : "Status",
    LastStatusChangedStart : "LastStatusChangedStart",
    LastStatusChangedEnd : "LastStatusChangedEnd",
    InProgress : "InProgress",
    FiltersChanged : "FiltersChanged",
}