import { ProofType } from "../../../../../../Utils/Enums";

export const NoteForUploadButton: React.FC<NoteForUploadProofProps> = ({ proofType }) => {
     if (proofType === ProofType.POD) {
          return (
               <label>
                    Note: Uploading a new POD document will archive any existing active POD
                    document.
               </label>
          );
     } else {
          return (
               <label>
                    Note: Uploading a new POC document will archive any existing active POC
                    document.
               </label>
          );
     }
};

type NoteForUploadProofProps = {
     proofType: ProofType;
};
