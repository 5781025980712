import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FeedbackResponseModel } from '../../../../../Models/Feedback';

export const useGetFeedback = (feedbackID: number,
  options?: any
): UseQueryResult<FeedbackResponseModel, any> => {

  const getFeedback = () => {
    if (feedbackID) {
        return agent.feedback.getFeedback(feedbackID)
    }
  };

  return useQuery(['GetFeedback', feedbackID], getFeedback, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
