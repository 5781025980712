import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { GetAdminRoleModelResponse } from '../../../../../../Models/Admin';

export const useGetAdminRole = (adminRoleID: number,
  options?: any
): UseQueryResult<GetAdminRoleModelResponse, any> => {

  const getAdminRole = () => {
    if(!adminRoleID) return;
    return agent.adminRole.getAdminRole(adminRoleID);
  }

  return useQuery(['GetAdminRole', adminRoleID], getAdminRole, {
    ...options,
  });
};
