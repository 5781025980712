import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, useAlert } from '../../../../../Components';
import {
  CommonRequestParam,
  FreeTextFilter,
  Pager,
} from '../../../../../Types';
import { getSorterFromSortModel, Page } from '../../../../../Utils';
import { SqlStringMatchType } from '../../../../../Utils/Enums';
import AddNewPage from './AddNewPage';
import ViewPagesGrid from './Grid';
import { useGetPages } from './Hooks';

const ViewPages: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FreeTextFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const getParams = () => {
    const params: CommonRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: FreeTextFilter) => {
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setFilter({});
  };

  const onAddNewPage = (pageName: string) => {
    setAlert({
      ...alert,
      show: true,
      header: "New Page Added: ",
      body: "Page Name: " + pageName + " added successfully"
    });
  };

  const pageChangeHandler = (currentPage: number) => {};
  const pageSizeChangeHandler = (itemsPerPage: number) => {};
  const onSort = (sortModel: GridSortModel) => {};

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** Query Hook */
  const { 
    data: pagesResponse, 
    isLoading: isGetPagesLoading,
    error: getPagesError,
  } = useGetPages( getParams() );

  const isDataLoaded = pagesResponse !== null;

  /** useEffects */
  useEffect(() => {
    setIsLoading(isGetPagesLoading);
  }, [isGetPagesLoading]);

  useEffect(() => {
    if (getPagesError) {
      setAlert({
        ...alert,
        show: true,
        header: getPagesError?.Subject ? getPagesError?.Subject : "Error",
        body: getPagesError?.Description ? getPagesError?.Description : "Error while fetching the pages."
      });
    }
  }, [getPagesError]);

  return (
    <div>
      {renderHeader('Pages')}
      <AddNewPage onAdd={onAddNewPage} />
      <div className="container-fluid">
        <Loading loading={isLoading} />
        <Alert {...alert} />
        {isDataLoaded && (
          <ViewPagesGrid
            data={pagesResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPages;
