import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { DeleteTradePlateRequest } from '../../../../../../../Models/Members/EditMember/TradePlates/DeleteTradePlates';
import { MovexErrorResponse } from '../../../../../../../Types';

export const useDeleteTradePlate = (options?: any): UseMutationResult<MovexErrorResponse, any, DeleteTradePlateRequest> => {
     const deleteTradePlate = (payload: DeleteTradePlateRequest) => {
          return agent.members.deleteTradePlate(payload);
     };

     return useMutation(deleteTradePlate);
};