import SectionFileUpload from "./SectionFileUpload";
import SectionMember from "./SectionMember";
import SectionPaymentAndInvoice from "./SectionPaymentAndInvoice";

const TasksPage = () => {
  return (
    <>
          <SectionFileUpload/>
          {/* <SectionMember/>
          <SectionPaymentAndInvoice/> */}
    </>
  );
};

export default TasksPage;