import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AdminUserDataModel } from '../../../../../Models/Admin';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutUpdateAdminUserData = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AdminUserDataModel> => {
     const updateAdminUser = (payload: AdminUserDataModel) => {
          return agent.jobs.putUpdateAdminUserData(payload);
     };

     return useMutation(updateAdminUser);
};
