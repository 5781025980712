import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { DeleteMemberPartnersRequestModel, DeleteMemberPartnersResponseModel } from '../../../../../../../Models/Members/EditMember';

export const useDeleteSelectedPartnersFromMember = (
  options?: any
): UseMutationResult<DeleteMemberPartnersResponseModel, any, DeleteMemberPartnersRequestModel> => {

  const deleteSelectedPartnersFromMember = (payload: DeleteMemberPartnersRequestModel) => {
    return agent.members.deleteSelectedPartnersFromMember(payload);
  };

  return useMutation(deleteSelectedPartnersFromMember);
};
