import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetInvoiceResponse } from '../../../../../Models/Billing';

export const useGetInvoice = (invoiceID: number,
  options?: any
): UseQueryResult<any, any> => {

  const getInvoice = () => {
    if(invoiceID === 0) return;
    return agent.invoices.getInvoice(invoiceID)
   
  };

  return useQuery(['GetInvoice', invoiceID], getInvoice, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
