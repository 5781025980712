import styled from "styled-components";

export const ActivityColumnRightWrapper = styled.div`
  display: inline-block;
  width: 28%;
  margin-left: 10px;
  margin-bottom: 40px;
  vertical-align: top;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
`;

export const JobDetailsDisplayWrapper = styled.div`
  border-radius: 5px;
  padding: 30px;
`;

export const JobActivitiesDisplayWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
  display: inline-block;
  width: 60%;
  margin-right: 10px;
  padding: 20px;
  vertical-align: top;
  margin-bottom: 100px;
`;

export const TaskAndActivities = styled.div`
  padding-bottom: 50px;
`;

export const TaskDetailItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  strong {
    margin-right: 10px;
  }
`;

export const ActivityWrapper = styled.div`
  margin: 30px;
`

export const ActivityPointWrapper = styled.span`
  color: unset;
  font-style: normal;
`;

export const ActivityNoteWrapper = styled.div`
  background-color: #99ccdf;
  border-radius: 15px;
  padding: 10px 15px;
  color: black;
  max-width: 100%;
  width: fit-content;
  margin-top: 5px;
`;


export const RedX = styled.b`
  color: red;
`;