/** Import Inbuilt libraries */
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
/** Custom Components */
import {
  FieldSetHeader,
  HoverableLink,
} from "../../../../../Components/Formik/StyledComponents";
import { ModalDialog, ModalDialogProps } from "../../../../../Components";
import { LabelItem, SpanItem } from "../../../../../Components/UI";
import { BillingModel } from "../../../../../Models/Billing/AddBilling/BillingModel";
import { getPaymentMethodImage, renderImage } from "../../../../../Components/SharedComponents/PaymentMethod";
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from "@mui/material";
import { RouteConstants } from "../../../../../Routes/Constants";

const MemberInformation: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { bill } = props;
  
  const handleModalClose = () => {
    setModalContent({ ...modalContent, show: false });
  };
  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<BillingModel>(bill);

  /** Methods */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const [modalContent, setModalContent] = useState<ModalDialogProps>({
    header: "",
    body: "",
    show: false,
    closeCallback: handleModalClose,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <fieldset>
            <br />
            <FieldSetHeader>Member Information</FieldSetHeader>
            <ModalDialog {...modalContent}></ModalDialog>
            <div>
              <LabelItem>Member:</LabelItem>
              <HoverableLink to={`${RouteConstants.EditMemberByMemberId}${bill.BilledMemberID}`} target="_blank">
                <SpanItem>{bill.UserName}</SpanItem>
              </HoverableLink>
            </div>
            <div>
              <LabelItem>Member Type:</LabelItem>
              <SpanItem>{renderImage(bill.MemberType)}</SpanItem>
            </div>
            <div>
              <LabelItem>Payment Method:</LabelItem>

              {bill.MemberPaymentMethod === 0 && <SpanItem><Tooltip title="Payment method not set up!"><CloseIcon/></Tooltip></SpanItem> }
              {bill.MemberPaymentMethod !== 0 && <SpanItem>{getPaymentMethodImage(bill.MemberPaymentMethod)}</SpanItem>}
            </div>
            <div>
              <LabelItem>Payment Reference:</LabelItem>
              <SpanItem>{bill.MemberPaymentReference}</SpanItem>
            </div>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  bill: BillingModel;
};

export default MemberInformation;
