import { ErrorMessage, Field } from 'formik';
import TextError from '../Formik/TextError';
import React from 'react';

export const PlainInput = ({ ...props }): JSX.Element => {
  const { name, label, ...rest } = props;
  const className = `form-control`;
  return (
    <div className="form-group">
      <Field name={name} className={className} placeholder={label} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default PlainInput;
