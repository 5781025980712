import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import TextError from '../Formik/TextError';

export const InputGroupElement = ({ ...props }) => {
  const { name, label, text, ...rest } = props;
  return (
    <div className="mb-3 form-group">
      {label && (
        <label htmlFor={name} className="form-label fw-600">
          {label}
        </label>
      )}

      <Field name={name} {...rest}>
        {({
          field: { name },
          meta: { touched, error },
        }: {
          field: { name: string };
          meta: { touched: boolean; error: any };
        }) => {
          const className =
            touched && error ? 'is-invalid' : touched ? 'is-valid' : '';
          return (
            <InputGroup>
              <InputGroup.Text>{text}</InputGroup.Text>
              <Field
                name={name}
                id={name}
                className={`form-control ${className}`}
                {...rest}
              />
            </InputGroup>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default InputGroupElement;
