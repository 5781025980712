import { VehicleType } from "../../../Utils/Enums/VehicleType"

export interface VehicleItemModel {
    Id: number
    Make: string
    MaxWeight: number
    Model: string
    TotalRecords: number
    VehicleType: VehicleType
}

export const VehicleItemModelKeys = {
    Id: "Id",
    Make: "Make",
    MaxWeight: "MaxWeight",
    Model: "Model",
    TotalRecords: "TotalRecords",
    VehicleType: "VehicleType",
}