import { Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';

/// Plate List styles
export const PlateListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-row-gap: 0px;
  grid-column-gap: 60px;
`;

// TradePlates styles

interface StyledTradePlateDivProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
     isEditing?: boolean;
     ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
}

export const StyledTradePlateDiv = styled.div<StyledTradePlateDivProps>`
     border: ${(props) => (props.isEditing ? 'none' : '1px solid #ced4da')};
     border-radius: 5px;
     padding: 5px;
     margin: 5px;
     width: 130px;
     flex-basis: 250px;
`;

export const CustomCol = styled(Col)`
     margin: 0px !important;
     padding: 0px;
`;

export const FormWrapper = styled(Form)`
    margin-top: 15px;
`;

export const RowWrapper = styled(Row)`
    margin: 10px;
`;

export const FormError = styled.p`
    color: red;
`;

export const MinWidthDiv = styled.div`
  min-width: 190px;
`;

export const TradePlateStyledCol = styled(Col).attrs(() => ({sm: '6'}))`
  min-width: 130px;
`;


/// Delete Plate styles

export const StyledCol = styled(Col)`
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
`;

export const DeleteButton = styled.div`
     position: absolute;
     cursor: pointer;
`;
