import { DataGrid,GridColDef,GridRenderCellParams } from '@mui/x-data-grid';
import { FormikProvider,useFormik } from 'formik';
import React,{ useEffect,useState } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import { object,ref,string } from 'yup';
import { Alert,ConfirmDialog,Loading,ShowPleaseWait,useAlert } from '../../../../../Components';
import { useConfirmDialog } from '../../../../../Components/ConfirmDialog/useConfirmDialog';
import { Button } from '../../../../../Components/FormControls';
import { FormControlKeys,OptionType } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
ColMd6SectionInner,
DivContainerWithMargin,
FieldSetContainer,
FieldSetHeader,
FlexContainer,
FormContainer,
FormFieldSetContainer,
HoverableLink,
InlineBlockColMd6Section,
QuickLinkItem
} from '../../../../../Components/Formik/StyledComponents';
import { AddAdminUserModel,AdministratorModel,AdminRoleModel,DeleteAdminUserRolesModel } from '../../../../../Models/System';
import { RouteConstants } from '../../../../../Routes/Constants';
import withAddForm from '../../../Common/AddForm/withAddForm';
import {
useDeleteSelectedAdminRolesFromAdminUser,
useGetAdminRolesForDropdown,
usePostAddSelectedAdminRoleToAdminUser
} from '../ViewAdminRoles/Hooks';
import { useGetAdministrator,usePostAddAdministrator,usePutUpdateAdministrator } from './Hooks';
import { AddAdministratorKeys,AdminIDParamsType } from './Types';

const validationSchema = object({
     [AddAdministratorKeys.UserName]: string().required(),
     [AddAdministratorKeys.Email]: string().required(),
     [AddAdministratorKeys.ConfirmEmail]: string().oneOf([ref('Email')],'Email & confirmation email do not match!'),
     [AddAdministratorKeys.Password]: string(),
     [AddAdministratorKeys.ConfirmPassword]: string().oneOf([ref('Password')],'Passwords must match')
});

/** Column definition for the mini grid/tab `Add Admin Role to the Admin User`. */
const columnsForAdministratorAdminRoles: GridColDef[] = [
     {
          field: AddAdministratorKeys.RoleName,
          headerName: 'Role Name',
          flex: 6,
          renderCell: (params: GridRenderCellParams) => {
               return <div>{params.row.RoleName}</div>}
     },
];

const AddAdministrator = () => {
     const { administratorID: paramAdminID } = useParams<AdminIDParamsType>();
     const adminID = paramAdminID ? parseInt(paramAdminID) : 0;
     const isEditMode = adminID > 0;
     const history = useHistory();
     const jobsActivityHistoryLink = `${RouteConstants.JobActivityHistoryByAdminID}=${adminID}`;

     /** States Hooks*/
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [administratorAdminRoles, setAdministratorAdminRoles] = useState<any[]>([]);
     const [selectedAdminRoleIds, setSelectedAdminRoleIds] = React.useState<number[]>([]);
     const [formikInitialValues, setFormikInitialValues] = useState<AdministratorModel>({
          Active: false,
          ConfirmEmail: '',
          ConfirmPassword: '',
          DisplayName: '',
          Email: '',
          ID: 0,
          IsAllocatedJobs: false,
          IsNew: false,
          Password: '',
          UserName: '',
          AdminRole: 0,
     });

     /** Functions */
     const onSubmit = async (payload: AddAdminUserModel) => {
          if (isEditMode) updateAdmin(payload);
          else addAdmin(payload);
     };

     const getAdminRolesForDropdown = () => {
          const options: OptionType[] = [];
          adminRoles?.AdminRoles.forEach((role: AdminRoleModel) => {
               options.push({
                    value: role.Id,
                    label: role.RoleName,
               });
          });
          return options;
     };

     const CustomGridFooter = (props: any) => {
          return (
               <DivContainerWithMargin>
                    <Button
                         className='btn btn-danger btn-sm'
                         label='Delete'
                         disabled={!selectedAdminRoleIds.length}
                         onClick={() => {
                              const payload = { AdminUserID: adminID, RoleIds: selectedAdminRoleIds } as DeleteAdminUserRolesModel;
                              deleteAdminRolesFromAdmin(payload);
                         }}
                    />
               </DivContainerWithMargin>
          );
     };

     /** Formik Hook */
     const formik = useFormik({
          initialValues: formikInitialValues,
          validationSchema: validationSchema,
          onSubmit: onSubmit,
          enableReinitialize: true,
     });

     const { handleSubmit } = formik;

     /** Alert and dialog Hooks */
     const { alert, handleDisplayAlert, setAlert } = useAlert();
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();

     /** Queries Hooks */
     const { data: adminRoles } = useGetAdminRolesForDropdown();
     const {
          data: getAdminResponse,
          isLoading: isGetAdminLoading,
          refetch: refetchAdministrator,
          error: getAdminError,
     } = useGetAdministrator(adminID, { enabled: false });
     const {
          mutate: addAdmin,
          isLoading: isAddAdminLoading,
          data: postAddAdminResponse,
          error: addAdminError,
     } = usePostAddAdministrator();
     const {
          mutate: updateAdmin,
          isLoading: isUpdateAdminLoading,
          data: putUpdateAdminResponse,
          error: updateAdminError,
     } = usePutUpdateAdministrator();

     const {
          mutate: deleteAdminRolesFromAdmin,
          isLoading: isDeleteAdminRolesFromAdminLoading,
          data: deleteAdminRolesFromAdminResponse,
          error: deleteAdminRolesFromAdminError,
     } = useDeleteSelectedAdminRolesFromAdminUser();
     const {
          mutate: postAddSelectedAdminRoleToAdmin,
          isLoading: isPostAddSelectedAdminRoleToAdminLoading,
          data: postAddSelectedAdminRoleToAdminResponse,
          error: postAddSelectedAdminRoleToAdminError,
     } = usePostAddSelectedAdminRoleToAdminUser();

     /** Side effects hooks */
     useEffect(() => {
          if (isEditMode) refetchAdministrator();
     }, [isEditMode, postAddSelectedAdminRoleToAdminResponse]);

     useEffect(() => {
          if (getAdminResponse) {
               const admin = getAdminResponse;
               setFormikInitialValues({
                    Active: admin.Active,
                    ConfirmEmail: admin.Email,
                    ConfirmPassword: '',
                    DisplayName: admin.DisplayName,
                    Email: admin.Email,
                    ID: admin.Id,
                    IsAllocatedJobs: admin.IsAllocatedJobs,
                    IsNew: false,
                    Password: '',
                    UserName: admin.UserName,
                    AdminRole: 0,
               });
               setAdministratorAdminRoles(admin.AdminRoles);
          }
     }, [getAdminResponse]);

     useEffect(() => {
          /** Success Responses handling */
          handleDisplayAlert(postAddAdminResponse, 'Success', 'Administrator added successfully.');
          handleDisplayAlert(putUpdateAdminResponse, 'Success', 'Administrator updated successfully.');
          handleDisplayAlert(deleteAdminRolesFromAdminResponse, 'Success', 'Administrator deleted successfully.');
          handleDisplayAlert(postAddSelectedAdminRoleToAdminResponse, 'Success', 'Administrator updated successfully.');

          /** Error Responses handling*/
          handleDisplayAlert(
               addAdminError,
               addAdminError?.Subject ?? 'Error when trying to add Administrator.',
               addAdminError?.Description
          );
          handleDisplayAlert(
               updateAdminError,
               updateAdminError?.Subject ?? 'Error when trying to update Administrator.',
               updateAdminError?.Description
          );
          handleDisplayAlert(
               deleteAdminRolesFromAdminError,
               deleteAdminRolesFromAdminError?.Subject ?? 'Error when trying to delete Administrator.',
               deleteAdminRolesFromAdminError?.Description
          );
          handleDisplayAlert(
               postAddSelectedAdminRoleToAdminError,
               postAddSelectedAdminRoleToAdminError?.Subject ?? 'Error when trying to update Admin Role.',
               postAddSelectedAdminRoleToAdminError?.Description
          );

          if (postAddAdminResponse || putUpdateAdminResponse) {
               history.push(RouteConstants.ViewAdministrators);
          }
     }, [
          getAdminResponse,
          postAddAdminResponse,
          putUpdateAdminResponse,
          deleteAdminRolesFromAdminResponse,
          postAddSelectedAdminRoleToAdminResponse,
          addAdminError,
          updateAdminError,
          deleteAdminRolesFromAdminError,
          postAddSelectedAdminRoleToAdminError,
     ]);

     useEffect(() => {
          if (getAdminError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getAdminError?.Subject ?? 'Unexpected Error',
                    body: getAdminError?.Description ?? 'An unexpected error occurred while fetching admin data.',
               });
          }
     }, [getAdminError]);

     useEffect(() => {
          setIsLoading(isGetAdminLoading);
     }, [isGetAdminLoading]);

     useEffect(() => {
          setShowPleaseWait(
               isDeleteAdminRolesFromAdminLoading ||
                    isPostAddSelectedAdminRoleToAdminLoading ||
                    isAddAdminLoading ||
                    isUpdateAdminLoading
          );
     }, [isDeleteAdminRolesFromAdminLoading, isPostAddSelectedAdminRoleToAdminLoading, isAddAdminLoading, isUpdateAdminLoading]);

     const component = (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <ShowPleaseWait show={showPleaseWait} />
               <Loading loading={isLoading} />
               <ConfirmDialog {...confirmDialogProps} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         handleSubmit(e);
                    }}
               >
                    <FormFieldSetContainer>
                         {isEditMode && (
                              <FieldSetContainer>
                                   <FieldSetHeader>Quick Links</FieldSetHeader>
                                   <QuickLinkItem>
                                        <HoverableLink to={jobsActivityHistoryLink} target='_blank'>
                                             View Jobs Activity History
                                        </HoverableLink>
                                   </QuickLinkItem>
                              </FieldSetContainer>
                         )}
                         <FieldSetContainer>
                              <FlexContainer>
                                   <InlineBlockColMd6Section>
                                        <ColMd6SectionInner>
                                             <FieldSetHeader>Administrator Details</FieldSetHeader>
                                             <FormikControl
                                                  name={AddAdministratorKeys.UserName}
                                                  label='UserName'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.ClickToUpdateField}
                                                  name={AddAdministratorKeys.Password}
                                                  label='Password'
                                                  type='password'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.ClickToUpdateField}
                                                  name={AddAdministratorKeys.ConfirmPassword}
                                                  label='Confirm Password'
                                                  type='password'
                                             />
                                             <FormikControl
                                                  name={AddAdministratorKeys.DisplayName}
                                                  label='Display Name'
                                             />
                                             <FormikControl
                                                  name={AddAdministratorKeys.Email}
                                                  label='Email'
                                             />
                                             <FormikControl
                                                  name={AddAdministratorKeys.ConfirmEmail}
                                                  label='Confirm Email'
                                             />
                                             <FormikControl
                                                  name={AddAdministratorKeys.IsAllocatedJobs}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Is Allocated Jobs'
                                             />
                                             <FormikControl
                                                  name={AddAdministratorKeys.Active}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Active'
                                             />
                                             <DivContainerWithMargin>
                                                  <FormikControl
                                                       name='AddAdminButton'
                                                       control={FormControlKeys.Button}
                                                       type='submit'
                                                       label={
                                                            isEditMode
                                                                 ? 'Update Administrator'
                                                                 : 'Add Administrator'
                                                       }
                                                  ></FormikControl>
                                             </DivContainerWithMargin>
                                        </ColMd6SectionInner>
                                   </InlineBlockColMd6Section>
                                   <InlineBlockColMd6Section>
                                        <ColMd6SectionInner>
                                             <FieldSetHeader>Administrator Roles</FieldSetHeader>
                                             <FormikControl
                                                  control={FormControlKeys.Select}
                                                  name={AddAdministratorKeys.AdminRole}
                                                  label='Add an Admin Role to the Admin User:'
                                                  placeholder='Select'
                                                  options={getAdminRolesForDropdown()}
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Button}
                                                  name={AddAdministratorKeys.AdminRole}
                                                  label='Add Role'
                                                  onClick={() => {
                                                       if(getAdminResponse === undefined){
                                                            handleDisplayAlert(
                                                                 true,
                                                                 'Error',
                                                                 'Please save the Administrator first before adding a role.'
                                                            );
                                                            return;
                                                       }
                                                       const payload = {
                                                            AdminUserID: adminID,
                                                            AdminRoleID: formik.values.AdminRole,
                                                       };
                                                       postAddSelectedAdminRoleToAdmin(payload);
                                                  }}
                                             />
                                             <div id='id_grid_administrator_roles' style={{ width: '100%' }}>
                                                  <DataGrid
                                                       sortingOrder={['desc', 'asc']}
                                                       getRowId={(row: any) => {
                                                            return row.AdminRoleId;
                                                       }}
                                                       autoHeight
                                                       rows={administratorAdminRoles}
                                                       columnBuffer={
                                                            columnsForAdministratorAdminRoles.length +
                                                            1
                                                       }
                                                       columns={columnsForAdministratorAdminRoles}
                                                       hideFooterPagination={true}
                                                       checkboxSelection
                                                       disableSelectionOnClick
                                                       disableColumnMenu={true}
                                                       selectionModel={selectedAdminRoleIds}
                                                       onSelectionModelChange={(selected) => {
                                                            setSelectedAdminRoleIds(
                                                                 selected as number[]
                                                            );
                                                       }}
                                                       components={{
                                                            Footer: CustomGridFooter,
                                                       }}
                                                  />
                                             </div>
                                        </ColMd6SectionInner>
                                   </InlineBlockColMd6Section>
                              </FlexContainer>
                         </FieldSetContainer>
                    </FormFieldSetContainer>
               </FormContainer>
          </FormikProvider>
     );

     return withAddForm(component, isEditMode ? 'Edit Administrator' : 'Add Administrator');
};

export default AddAdministrator;
