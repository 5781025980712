
export const CheckBox = (props: any): JSX.Element => {
  return (
      <input
        key={props.Id}
        onClick={props.handleCheckChieldElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.Id}
      />
  );
};

export default CheckBox;
