import React from 'react';

export const Privacy = () => {
  return (
    <div className="privacy-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>PRIVACY</h1>
            <h3>
              HELPLINE{' '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>Movex Privacy Policy</h1>
            <h3>1. Who we are</h3>
            <p>
              Movex Ltd (Movex) is the data controller in respect of any
              personal information to which this privacy policy applies.
              <br />
              You can contact us at:
            </p>
            <p>
              <b>Trading Address</b>
              <br />
              Crate
              <br />
              15 Oakwood Hill Industrial Estate
              <br />
              Loughton
              <br />
              IG10 3TZ
            </p>
            <p>
              <b>Registered Address</b>
              <br />
              Central House
              <br />
              Leeds Road
              <br />
              Rothwell
              <br />
              Leeds
              <br />
              LS26 0JE
            </p>
            <p>
              Movex is part of the Cox Automotive UK group of companies. The Cox
              Automotive UK Group consists of the companies listed below.
              References in this privacy policy to the ‘Cox Automotive Group’
              should be taken to include all of these companies:
            </p>
            <ul>
              <li>Cox Automotive UK Ltd</li>
              <li>Cox Automotive Retail Solutions Ltd</li>
              <li>Manheim UK Ltd</li>
              <li>NextGear Capital Ltd</li>
              <li>Motors.co.uk Ltd</li>
              <li>Dealer Auction Ltd</li>
              <li>Money4yourmotors.com Ltd</li>
              <li>Movex Logistics Ltd</li>
              <li>Kingfisher Systems Ltd</li>
              <li>RMS Automotive</li>
              <li>Modix UK Ltd</li>
            </ul>
            <p></p>
            If you wish to contact our Head of Privacy & Data Protection, you
            can do so by emailing{' '}
            <a href="mailto:dataprotection@manheim.co.uk">
              dataprotection@manheim.co.uk
            </a>
            .<h3>2. What we do with your personal information</h3>
            <p>
              We may collect personal information from you (such as name,
              username, address, telephone number, email address, date of birth,
              proof of ID) when you complete registration or enquiry forms or
              send emails to us as well as via job information generated on the
              site such as collection or delivery addresses. Furthermore, we may
              record any telephone calls made to telephone numbers included on
              the Site. If you are a transport company we will also hold your
              bank details. Further information on the use of data is set out
              below.
            </p>
            <ul>
              <li>
                We process personal data for the purposes of administering your
                account with us, to provide you with the products or services
                you have requested and to enable us to contact you about changes
                to those products and services.
              </li>
              <li>
                Given the nature of our service, we collect certain personal
                data such as proof of insurance to reduce business risk.
              </li>
              <li>
                If you apply for credit on your Movex account, we will undertake
                necessary checks with credit reference agencies to asses lending
                risk.
              </li>
              <li>
                We collect personal data generated as a result of your use of
                our service, such as customer comments, records of emails or
                telephone calls. This information is used for audit purposes and
                to help us improve our service.
              </li>
              <li>
                We also use your personal data to send you by electronic means
                including email, telephone and text message.
              </li>
              <li>
                We also collect details of your insurance cover and may collect
                proof of ID and proof of address.
              </li>
              <li>
                When your insurance details are due for renewal, we will pass
                those details to an insurance broker, Finch Commercial Insurance
                Brokers Ltd, who may contact you about your renewal
                requirements.
              </li>
              <li>
                We also use personal data to analyse use of our website for
                trends.
              </li>
            </ul>
            <p>
              Movex processes your personal information on the following
              grounds:
            </p>
            <ul>
              <li>
                We process personal data on the grounds that it is necessary for
                the performance of a contract between you and Movex for the use
                of the Movex platform.
              </li>
              <li>
                Where we process your personal data for direct marketing
                purposes using electronic methods (telephone, email, fax and
                text message) we do so on the basis of having obtained your
                opt-in consent. You can withdraw consent at any time by
                contacting us and letting us know.
              </li>
              <li>
                Where we process your personal data to send email marketing, it
                is done on the basis of us pursuing legitimate interests.
                Marketing is a legitimate interest of commercial organisations.
                Your privacy rights are not unduly affected by this because you
                still have the right to opt out of this (see ‘Your Rights’
                below).
              </li>
              <li>
                Where we process your personal data for audit purposes, or for
                the purposes of analysing use of our site or service
                improvements, we do this on the basis of legitimate interests.
                It is in our legitimate interests to retain information such as
                customer comments to enable us to identify issues and concerns
                people have with the service they have been provided. It is also
                generally in our legitimate interest to retain emails and
                telephone recordings as an audit log of our interactions with
                you.
              </li>
              <li>
                Where we share your personal data with insurance brokers, we do
                this on the basis of you have provided opt in consent to us
                passing your details on for this purpose.
              </li>
            </ul>
            <h3>3. Disclosures and Transfers</h3>
            <p>
              Movex will pass your personal information on to the following
              recipients:
            </p>
            <ul>
              <li>Staff working for Movex</li>
              <li>Finch Commercial Insurance Brokers Ltd</li>
              <li>
                Your personal information may also be shared with auditors or
                contractors or other advisers auditing or advising us on our
                business operations.
              </li>
              <li>
                Regardless of your relationship to us, we co-operate with law
                enforcement agencies, as well as other third parties, to assist
                in the enforcement of laws. We may disclose any personal
                information about you to such law enforcement or other
                government officials as we believe necessary or appropriate in
                connection with any investigation by such law enforcement
                agencies or other government officials.
              </li>
              <li>
                In the event of a dispute between users of the Movex platform
                with regards to a job will provide support. Where this cannot
                resolve the issue and either side wishes to take the matter up
                directly with the other, we will provide the necessary contact
                details to the relevant parties.
              </li>
              <li>
                We may transfer, sell or assign any of the personal information
                described in this Policy to third parties as a result of a sale,
                merger, consolidation, change of control, transfer of assets or
                reorganisation of our business (or any part of our group).
              </li>
              <li>
                We also use Amazon AWS to host our platform. As such, personal
                data is passed to Amazon in order for it to be stored on their
                cloud servers. These servers are located in the EU.
              </li>
              <li>
                We may inform other members of the Cox Automotive UK Group that
                you are a Movex customer.
              </li>
              <li>
                We use Pulse Smartdebit, a third party company, to handle direct
                debit.
              </li>
            </ul>
            <h3>4. Your rights in relation to your personal information</h3>
            <p>
              You have the following rights in relation to the personal
              information Movex processes relating to you. Where you exercise
              these rights, Movex will provide information to you as to what
              action has been taken, if any, within 30 calendar days of receipt
              of your request. Where no action has been taken, Movex will
              explain our reasons why.
            </p>
            <p>
              Taking into account the complexity and number of requests, it may
              take Movex up to 90 calendar days to respond to your request.
              Where Movex intend to extend the deadline for responding, you will
              be informed within 30 calendar days of receipt of your request.
            </p>
            <h3>4.1 Right of Access</h3>
            <p>
              You have the right to request access to the personal information
              that Movex hold about you.
            </p>
            <h3>4.2 Rights in relation to inaccurate personal information</h3>
            <p>
              Where personal information that Movex holds about you is
              inaccurate, you have the right to request that those inaccuracies
              are rectified. Taking into account the purposes for which the
              personal information is processed, you also have the right to have
              incomplete personal information about you completed.
            </p>
            <h3>4.3 Right to erasure</h3>
            <p>
              In certain limited circumstances, you have the right to request
              that Movex erase personal information relating to you that Movex
              hold. This right only applies if any one of the following
              circumstances applies:
            </p>
            <ul>
              <li>
                Where Movex no longer require the personal information for the
                purpose(s) for which it was obtained or otherwise used.
              </li>
              <li>
                Where the processing is based on your consent, that consent has
                been withdrawn and there is no other legal ground for
                processing.
              </li>
              <li>
                Where you have exercised your right to object to the processing
                and there is no overriding legitimate grounds for the
                processing.
              </li>
              <li>
                Where you have exercised your right to object to the use of your
                personal information for direct marketing purposes.
              </li>
              <li>
                Where the personal information have been unlawfully processed.
              </li>
              <li>
                Where Movex are required to erase the personal information in
                order to comply with a legal obligation.
              </li>
            </ul>
            <p>
              The right does not apply where the processing of your personal
              information is necessary:
            </p>
            <ul>
              <li>
                For compliance with a legal obligation requiring Movex to
                process your personal information or where the processing is
                necessary for the performance of a task carried out in the
                public interest
              </li>
              <li>
                For the establishment, exercise or defence of legal claims.
              </li>
            </ul>
            <h3>4.4 Right to restriction of processing</h3>
            <p>
              You have the right to request that Movex restrict the processing
              of your personal information in the following circumstances:
            </p>
            <ul>
              <li>
                Where you have contested the accuracy of the personal
                information. In this case the processing of the personal
                information will be restricted for a period which enables Movex
                to verify the accuracy of the personal information in question.
              </li>
              <li>
                Where the processing of the personal information is unlawful and
                you have objected to the information being erased and have
                instead requested the restriction of its use.
              </li>
              <li>
                Where Movex no longer needs the personal information for the
                purposes set out in part 2 above but you require them for
                establishing, exercising or defending legal claims.
              </li>
              <li>
                Where you have objected to the processing of your personal
                information as set out in section 3.6.1 below. In this case,
                processing of your personal information will be restricted
                pending verification of whether our legitimate grounds for
                continuing to process your personal information override your
                grounds for objecting.
              </li>
            </ul>
            <p>
              Where processing of your personal information is restricted for
              any of the reasons above Movex will not process it, with the
              exception of storage, unless:
            </p>
            <ul>
              <li>You have provided your consent,</li>
              <li>
                For the establishment, exercise or defence of legal claims,
              </li>
              <li>
                For the protection of the rights of another natural or legal
                person, or
              </li>
              <li>For reasons of important public interest.</li>
            </ul>
            <p>
              Where Movex have agreed to restrict the processing of your
              personal information, we will write to you to inform you that we
              will be lifting that restriction prior to doing so.
            </p>
            <h3>4.5 Right to data portability</h3>
            <p>This right only applies in the following circumstances:</p>
            <ul>
              <li>
                Where the personal information in question consists of
                information you have provided to Movex
              </li>
              <li>
                Where the grounds for processing the personal information stated
                in section 2 above are consent, explicit consent or for the
                performance of a contract or to take steps at your request prior
                to entering into a contract, and
              </li>
              <li>
                The processing of the personal information is carried via
                automated means.
              </li>
            </ul>
            <p>
              Where this right applies, you have the right to receive your
              personal information in a structured, commonly used and machine
              readable format. You also have the right to transmit that
              information to another data controller or, where possible,
              directly from Movex to another controller.
            </p>
            <h3>4.6 Right to object to processing</h3>
            <p>
              You have the following rights in relation to objecting to the
              processing of your personal information by Movex:
            </p>
            <ul>
              <li>
                You have the right to object at any time, on grounds relating to
                your particular situation, to the processing, and
              </li>
              <li>
                The right to object specifically to the processing of your
                personal information for direct marketing purposes.
              </li>
            </ul>
            <h3>4.6.1 Right to object to processing</h3>
            <p>
              You have the right to object to the processing of your personal
              information at any time, on grounds relating to your particular
              situation. This only applies where the grounds for processing are:
            </p>
            <ul>
              <li>
                The processing is necessary for the purposes of legitimate
                interests pursued by the data controller or by a third party, or
              </li>
              <li>
                Processing is necessary for the performance of a task carried
                out in the public interest
              </li>
            </ul>
            <h3>
              4.6.2 Right to object to processing for the purposes of direct
              marketing
            </h3>
            <p>
              You have the right to object specifically to the processing of
              your personal information for the purposes of direct marketing. If
              you object to the processing of your personal information in this
              way, then Movex will no longer process it for that purpose.
            </p>
            <h3>
              4.7 Rights in relation to automated decision making & profiling
            </h3>
            <p>
              You have the right not to be subjected to a decision based solely
              on automated processing, including profiling, which would legally
              affect you or significantly affect you. However, this right will
              not apply if the decision:
            </p>
            <ul>
              <li>
                Is necessary for the entering into, or performance of, a
                contract for use of the Movex Platform between you and Movex
              </li>
              <li>
                Is authorised by or under any law to which Movex is subject, or
              </li>
              <li>Is based on your explicit consent.</li>
            </ul>
            <h3>
              4.8 Right to complain to the Information Commissioner’s Office
            </h3>
            <p>
              Where you believe that Movex has failed to properly comply with
              its obligations in respect of your personal information, including
              your rights as set out in section 3.1-3.7 above, you can complain
              to the Information Commissioner.
            </p>
            <p>The Information Commissioner’s Office’s address is:</p>
            <p>
              <b>The Information Commissioner’s Office</b>
              <br />
              Wycliffe House
              <br />
              Water Lane
              <br />
              Wilmslow
              <br />
              Cheshire
              <br />
              SK9 5AF
            </p>
            <p>
              You can find more information about their role and how to submit a
              complaint on their website:{' '}
              <a target="_blank" href="http://www.ico.org.uk">
                www.ico.org.uk
              </a>
              .
            </p>
            <h3>5. Cookies</h3>
            <p>How we use cookies</p>
            <p>
              A cookie is a small file which asks permission to be placed on
              your computer's hard drive. Once you agree, the file is added and
              the cookie helps analyse web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes and dislikes by gathering and
              remembering information about your preferences.
            </p>
            <p>
              We use traffic log cookies to identify which pages are being used.
              This helps us analyse data about web page traffic and improve our
              website in order to tailor it to customer needs. We only use this
              information for statistical analysis purposes and then the data is
              removed from the system.
            </p>
            <p>
              Overall, cookies help us provide you with a better website, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
            </p>
            <p>
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of the website
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
