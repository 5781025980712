import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Sorter } from '../../../Types';

class TableHeader extends Component<TableHeaderProps, TableHeaderState> {
  raiseSort = (path: string) => {
    const sorter = { ...this.props.sorter };
    if (sorter && sorter.Column === path) sorter.Ascending = !sorter.Ascending;
    else {
      sorter.Column = path;
      sorter.Ascending = true;
    }
    this.props.onSort(sorter);
  };

  renderSortIcon = (column: TableHeaderColumn) => {
    const { sorter } = this.props;
    if (sorter) {
      if (column.path !== sorter.Column) {
        return null;
      } else if (sorter.Ascending) {
        return <FontAwesomeIcon icon={faSortUp} />;
      }
    }
    return <FontAwesomeIcon icon={faSortDown} />;
  };

  render() {
    return (
      <thead>
        <tr>
          {this.props.columns.map((column) => {
            let isSortable = true;
            if (column.isSortable === false) isSortable = false;
            return (
              <th
                className={isSortable ? 'clickable underline' : ''}
                key={column.path || column.key}
                onClick={() => {
                  if (isSortable) this.raiseSort(column.path);
                }}
              >
                {column.label} {this.renderSortIcon(column)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;

interface TableHeaderState {}

export interface TableHeaderProps {
  sorter: Sorter;
  onSort: (sorter: Sorter) => void;
  columns: TableHeaderColumn[];
}

export interface TableHeaderColumn {
  _id?: string;
  path: string;
  key?: string;
  label: string;
  content?: Function;
  isSortable?: boolean;
}

export interface MaterialTableHeaderColumn {
  _id?: string;
  field: string;
  key?: string;
  title: string;
  defaultSort?: 'asc' | 'desc' | undefined;
  render?: any;
}

export interface DataGridHeaderColumn {
  _id?: string;
  field: string;
  key?: string;
  title: string;
  defaultSort?: 'asc' | 'desc' | undefined;
  render?: any;
}
