import React from 'react';
import { CellRenderer, HoverableLink } from '../../../../../Components/Formik';
import { TableHeaderColumn } from '../../../../../Components/Table/TableHeader';
import {
  MemberItemModel,
  MemberModelKeys,
} from '../../../../../Models/Members';
import {
  ActionColumnPath,
  getFormattedDateWithYear,
} from '../../../../../Utils';

const { renderMemberNotesCell, renderMemberActionCell, renderMemberTypeCell } =
  CellRenderer;

export const columns: TableHeaderColumn[] = [
  {
    path: MemberModelKeys.MemberType,
    label: 'Type',
    content: (member: MemberItemModel) => renderMemberTypeCell(member.MemberType),
    isSortable: false,
  },
  {
    path: MemberModelKeys.Username,
    label: 'UserName',
    content: (member: MemberItemModel) => (
      <HoverableLink to={`edit-member/${member.Id}`}>
        {member.Username}
      </HoverableLink>
    ),
  },
  { path: MemberModelKeys.Email, label: 'Email' },
  { path: MemberModelKeys.Company, label: 'Company' },
  {
    path: MemberModelKeys.Notes,
    label: 'Notes',
    content: (member: MemberItemModel) =>
      renderMemberNotesCell(member.Notes, member.Id),
  },
  {
    path: MemberModelKeys.SignupDate,
    label: 'Signup',
    content: (member: MemberItemModel) =>
      getFormattedDateWithYear(member.SignupDate),
  },
  {
    path: MemberModelKeys.Disabled,
    label: 'Account',
    content: (member: MemberItemModel) => (
      <span>{member.Disabled ? 'Disabled' : 'Enabled'}</span>
    ),
    isSortable: false,
  },
  {
    path: MemberModelKeys.Active,
    label: 'Status',
    content: (member: MemberItemModel) => (
      <span>{member.Active ? 'Active' : 'Inactive'}</span>
    ),
    isSortable: false,
  },
  {
    path: ActionColumnPath,
    label: 'Action',
    content: (member: MemberItemModel) => renderMemberActionCell(member.Id),
    isSortable: false,
  },
];
