import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobFixedPricesModel, UpdateJobFixedPricesResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobFixedPrices = (
  options?: any
): UseMutationResult<
  UpdateJobFixedPricesResponseModel,
  any,
  UpdateJobFixedPricesModel
> => {
  const usePutUpdateJobFixedPrices = (
    payload: UpdateJobFixedPricesModel
  ) => {
    return agent.jobs.putUpdateJobFixedPrices(payload);
  };

  return useMutation(usePutUpdateJobFixedPrices);
};