import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';
import TextError from '../Formik/TextError';

export const Checkbox = ({ ...props }): JSX.Element => {
  const { name, label, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  return (
    <>
    <Field name={name}>
      {({ field }: { field: any }) => {
        return (
          <div
            style={{ marginRight: 19 }}
            className="form-check form-check-inline mb-3 form-group"
          >
             <label className="form-check-label" htmlFor={name}>
              {label}
            </label>
            
            <input style={{float: "right", marginRight: "-1.5em"}}
              className="form-check-input"
              type="checkbox"
              id={name}
              name={name}
              {...rest}
              value={field.value}
              checked={field.value === true || field.value === 'true'}
              onChange={(e) => {
                setValue(e.currentTarget.checked);
              }}
            />

            
          </div>
        );
      }}
    </Field>
    <br/>
    <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default Checkbox;
