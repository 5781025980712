import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateBillPaymentInformationModel } from "../../../../../Models/Billing/AddBilling/UpdateBillPaymentInformationModel";
import { EntityCreatedOrUpdatedViewModel } from "../../../../../Types";

export const usePutUpdateBill = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  UpdateBillPaymentInformationModel
> => {
  const updateBill = (payload: UpdateBillPaymentInformationModel) => {
    return agent.bills.putUpdateBill(payload);
  };

  return useMutation(updateBill);
};
