import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetPriceGroupList = (payload: any, options?: any): UseQueryResult<any, any> => {
     const getPriceGroupList = ({ queryKey }: any) => {
          return agent.priceGroup.getPriceGroupList(payload);
     };
     return useQuery(['GetPriceGroupList'], getPriceGroupList, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
