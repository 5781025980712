import React from 'react';

export const Info24HoursDealer = () => {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p style={{ textAlign: 'center' }}>
        <span style={{ fontSize: 18 }}>
          <strong>
            <p style={{ color: '#BD321C' }}>IMPORTANT MESSAGE PLEASE READ</p>
          </strong>
        </span>
      </p>

      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p style={{ textAlign: 'center' }}>
        <strong>
          <span style={{ color: '#004966' }}>
            All driven jobs will now include fuel if required.
            <br />
            <br />
            When a driven job is requested you will be asked if fuel is
            required, if so you will be shown an estimated fuel amount to be
            added to the fixed price.
          </span>
        </strong>
      </p>

      <p style={{ textAlign: 'center' }}>
        <strong>
          <span style={{ color: '#004966' }}>
            If placing a quote request and fuel is required the transport
            company will quote for the job including fuel.
          </span>
        </strong>
      </p>

      <p style={{ textAlign: 'center' }}>
        <img
          alt=""
          src="/Images/Messages/FuelInclusive_RequestQuote.png"
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: ' Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            textAlign: 'center',
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p style={{ textAlign: 'center' }}>
        T
        <span style={{ color: '#004966' }}>
          <strong>
            he transport company will see the below when they view the
            job.&nbsp;
          </strong>
        </span>
      </p>

      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p style={{ textAlign: 'center' }}>
        <img
          alt=""
          src="/Images/Messages/FuelInclusive_QuoteFuel.png"
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: ' Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            textAlign: 'center',
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p style={{ textAlign: 'center' }}>
        <span style={{ color: '#004966' }}>
          <strong>
            If fuel is not required the transport company will see it as below.
          </strong>
        </span>
      </p>

      <p style={{ textAlign: 'center' }}>
        <img
          alt=""
          src="/Images/Messages/FuelInclusive_QuoteNoFuel.png"
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: ' Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            textAlign: 'center',
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p style={{ textAlign: 'center' }}>&nbsp;</p>

      <p>&nbsp;</p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: ' rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: 'rgb(255, 0, 0)' }}>
          <span style={{ fontSize: 16 }}>
            <strong>
              If you have any queries regarding this please feel free to call us
              on
            </strong>
          </span>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: ' rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: 'rgb(255, 0, 0)' }}>
          <span style={{ fontSize: 16 }}>
            <strong>0208 551 1060</strong>
          </span>
        </span>
      </p>
    </div>
  );
};

