import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetInvoiceDatesForDropdown = (
  options?: any
  ): UseQueryResult<string[], any> => {

  const getInvoiceDates = () => {
    return agent.bills.getInvoiceDatesForDropdown()
  };

  return useQuery(['GetInvoiceDates'], getInvoiceDates, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};