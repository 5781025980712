import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export interface AddPriceToPriceGroupRequest {
     PriceGroupID: number;
     PriceID: number;
}

export const usePostAddPriceToPriceGroup = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddPriceToPriceGroupRequest> => {
     const addPrice = async (payload: AddPriceToPriceGroupRequest) => {
          if (payload.PriceGroupID && payload.PriceID) return await agent.priceGroup.postAddPriceToPriceGroup(payload);
     };

     return useMutation(addPrice, options);
};