import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddMemberFavouriteModel } from '../../../../../../../Models/Members/EditMember/DealerConfig';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const usePostAddMemberFavourite = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddMemberFavouriteModel
> => {
  const addMemberFavourite = (payload: AddMemberFavouriteModel) => {
    return agent.members.postAddMemberFavourite(payload);
  };

  return useMutation(addMemberFavourite);
};
