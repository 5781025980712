/** Import inbuilt libraries*/
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
/** Import custom components*/
import {
  Alert,
  AlertProps,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  ShowPleaseWait,
  useAlert,
} from '../../../../../Components';
import { Button } from '../../../../../Components/FormControls';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';

import {
  DivContainer,
  FieldSetContainer,
  FormContainer,
  FormFieldSetContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import {
  AddPageModel,
  AddPageModelKeys,
} from '../../../../../Models/System/Pages/AddPageModel';
import { RouteConstants } from '../../../../../Routes/Constants';
import withAddForm from '../../../Common/AddForm/withAddForm';
import CkEditorTemplate from '../../../Common/CkEditor/CkEditorTemplate';
import { AboutUs } from '../Layout';
import {
  useGetPage,
  usePostAddAdministrator,
  usePutUpdateAdministrator,
} from './Hooks';
import { AdminIDParamsType } from './Types';

const validationSchema = object({});

const EditPage = () => {
  const { administratorID: paramAdminID } = useParams<AdminIDParamsType>();
  const adminID = paramAdminID ? parseInt(paramAdminID) : 0;
  const isEditMode = adminID > 0;
  const history = useHistory();
  const jobsActivityHistoryLink = `${RouteConstants.JobActivityHistoryByAdminID}=${adminID}`;

  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });

  const [formikInitialValues, setFormikInitialValues] = useState<any>({});
  const [administratorAdminRoles, setAdministratorAdminRoles] = useState<any[]>(
    []
  );
  const [selectedAdminRoleIds, setSelectedAdminRoleIds] = React.useState<
    number[]
  >([]);

  /** Custom Hook */
  const { alert, setAlert, handleAlertClose } = useAlert();

  /** Function */
  const onSubmit = async (payload: AddPageModel) => {
    if (isEditMode) {
      //TODO: integrate apis once ready
      // updateAdmin(payload);
    } else {
      // payload.ID = null;
      // addAdmin(payload);
    }
  };

  const CustomGridFooter = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedAdminRoleIds.length}
        style={{ margin: 10 }}
        onClick={() => {}}
      ></Button>
    );
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  /** Query Hooks */
  const { data: pageResponse, isLoading: isGetPageLoading } =
    useGetPage(adminID);

  const {
    mutate: addAdmin,
    isLoading: isAddAdminLoading,
    data: postAddAdminResponse,
  } = usePostAddAdministrator();

  const { mutate: updateAdmin, isLoading: isUpdateAdminLoading } =
    usePutUpdateAdministrator();

  /** useEffects */
  useEffect(() => {
    setIsLoading(isGetPageLoading);
  }, [isGetPageLoading]);

  useEffect(() => {
    setShowPleaseWait(isAddAdminLoading || isUpdateAdminLoading);
  }, [isAddAdminLoading, isUpdateAdminLoading]);

  const { handleSubmit, values, errors, validateForm, isValid, setFieldValue } =
    formik;
  const component = (
    <FormikProvider value={formik}>
      <Alert {...alert} />
      <ShowPleaseWait show={showPleaseWait} />
      <Loading loading={isLoading} />
      <ConfirmDialog {...confirmDialogContent} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <FormFieldSetContainer>
          <FieldSetContainer>
            <RowFluidContainer>
              <Row>
                <Col>
                  <FormikControl
                    name={AddPageModelKeys.Name}
                    label="Name"
                    readOnly="readOnly"
                  />
                </Col>
                <Col>
                  <FormikControl name={AddPageModelKeys.Title} label="Title" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={AddPageModelKeys.MetaKeywords}
                    label="MetaKeywords"
                    control={FormControlKeys.Textarea}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={AddPageModelKeys.MetaDescription}
                    label="Meta Description"
                    control={FormControlKeys.Textarea}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={AddPageModelKeys.Styles}
                    label="Styles"
                    control={FormControlKeys.Textarea}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={AddPageModelKeys.Scripts}
                    label="Scripts"
                    control={FormControlKeys.Textarea}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <FormikControl name={AddPageModelKeys.Layout} label="Body" /> */}
                  <CkEditorTemplate content={<AboutUs />} />
                </Col>
              </Row>

              <DivContainer>
                <FormikControl
                  name="UpdatePageBtn"
                  control={FormControlKeys.Button}
                  type="submit"
                  label={'Update Page'}
                  style={{ margin: '10px 0' }}
                ></FormikControl>
              </DivContainer>
            </RowFluidContainer>
          </FieldSetContainer>
        </FormFieldSetContainer>
      </FormContainer>
    </FormikProvider>
  );

  return withAddForm(component, 'Edit Page');
};

export default EditPage;
