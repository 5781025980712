import { useQuery, UseMutationResult, UseQueryResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { MemberUserNameDetailsResponseModel } from '../../../../../../../Models/Members/EditMember/ConfigObjects';
import { ServiceType } from '../../../../../../../Utils/Enums';

export const useGetSearchSuppliersByUserNameAndServiceType = (
  supplier: {
    userName: string;
    serviceType?: ServiceType;
  },
  options?: any
): UseQueryResult<MemberUserNameDetailsResponseModel, any> => {
  const { userName, serviceType } = supplier;
  const searchSuppliers = () => {
    return agent.members.getSupplyingMembersByUserNameAndServiceType(
      userName,
      serviceType
    );
  };

  return useQuery(
    ['GetSupplyingMembersByUserNameAndServiceType', userName, serviceType],
    searchSuppliers,
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
