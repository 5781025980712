import React from 'react';

export const Disclaimer = () => {
  return (
    <div className="disclaimer-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>DISCLAIMER</h1>
            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>Disclaimer</h1>

            <p>
              The information contained in this website is for general
              information purposes only. The information is provided by
              MOVEX.co.uk and while we endeavour to keep the information up to
              date and correct, we make no representations or warranties of any
              kind, express or implied, about the completeness, accuracy,
              reliability, suitability or availability with respect to the
              website or the information, products, services, or related
              graphics contained on the website for any purpose. Any reliance
              you place on such information is therefore strictly at your own
              risk.
            </p>

            <p style={{ marginTop: '0.49cm', marginBottom: '0.49cm' }}>
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use of this website.
            </p>

            <p style={{ marginTop: '0.49cm', marginBottom: '0.49cm' }}>
              Every effort is made to keep the website up and running smoothly.
              However, MOVEX.co.uk takes no responsibility for, and will not be
              liable for, the website being temporarily unavailable due to
              technical issues beyond our control.
            </p>

            <p style={{ marginTop: '0.49cm', marginBottom: '0.49cm' }}>
              Through this website you are able to link to other websites which
              are not under the control of MOVEX.co.uk We have no control over
              the nature, content and availability of those sites. The inclusion
              of any links does not necessarily imply a recommendation or
              endorse the views expressed within them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
