import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { UpdateAdminUserRequest } from '../../../../../../Models/System';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';

export const usePutUpdateAdministrator = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  UpdateAdminUserRequest
> => {

  const putUpdateAdministrator = (payload: UpdateAdminUserRequest) => {
    return agent.admin.putUpdateAdministrator(payload);
  };

  return useMutation(putUpdateAdministrator);
};