import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetDealerMarginQuotedPrices = (
     requestingMemberFeeType: number,
     parentKeyID: number,
     options?: any
): UseQueryResult<any, any> => {
     const getDealerMarginQuotedPrices = () => {
          return agent.dealerMargin.getDealerMarginQuotedPrices(
               requestingMemberFeeType,
               parentKeyID
          );
     };

     return useQuery(['GetDealerMarginQuotedPrices'], getDealerMarginQuotedPrices, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
