import styled from "styled-components";

export const LabelItem = styled.label`
  display: inline-block;
  font-weight: bold;
  width: 200px;
  line-height: 25px;
`;

export const SpanItem = styled.span`
  display: inline-block;
  line-height: 25px;
`;

export const SpanItemNewLine = styled.span`
  display: block;
  line-height: 25px;
`;

export const SpanWidth200Item = styled.span`
  display: inline-block;
  width: 130px;
`;

export const SpanBoldItem = styled.span`
  display: inline-block;
  font-weight: bold;
`;

export const DangerSpanItem = styled.span`
  font-weight: bold;
  margin-right: 5px;
  color: #BD321C; 
  display: inline-block; 
  width: 220px;
`;

export const DivInputWidth = styled.div`
width: 220px;
`;

export const DivUploadProof = styled.div`
padding-bottom: 5px;
`;

export const SearchBox = styled.div`
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const SupplierLabelInfo = styled.label`
  background-color: #2d6987 !important;
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
  cursor: pointer;
`;

export const UnlockJobHeader = styled.b`
  color: #0181AF;
  font-weight: bold;
`;