import { useMutation, UseMutationResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { AddActivityNoteRequest } from '../../../../../Models/Tasks/AddActivityNoteRequest';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddActivityNote = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddActivityNoteRequest> => {
     const postAddActivityNote = (payload: AddActivityNoteRequest) => {
          return adminTasks.postAddActivityNote(payload);
     };

     return useMutation(postAddActivityNote);
};
