import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, ShowPleaseWait, useAlert } from '../../../../Components';
import { FormControlKeys } from '../../../../Components/FormControls';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
     ActivityNoteWrapper,
     ActivityPointWrapper,
     ActivityWrapper,
     JobActivitiesDisplayWrapper,
} from '../../../../Components/UI';
import { ActivityModel } from '../../../../Models/Tasks';
import { AddActivityNoteRequest } from '../../../../Models/Tasks/AddActivityNoteRequest';
import { formatDateTime } from '../../../../Utils';
import { NoteActivityType } from '../../../../Utils/Enums/NoteActivityType';
import { usePostAddActivityNote } from './Hooks/usePostAddActivityNote';

interface Props {
     activities: ActivityModel[];
     taskId: number;
     refetchTask: () => void;
}

const JobActivitiesDisplay: React.FC<Props> = ({ activities, taskId, refetchTask }) => {
     const [showNewNoteArea, setShowNewNoteArea] = useState<boolean>(false);

     /** Formik Hook */
     const formik = useFormik({
          initialValues: { Note: '' },
          onSubmit: () => {},
          enableReinitialize: true,
     });

     /** Functions */
     const handleAddActivityNote = () => {
          const payload = {
               NoteText: formik.values.Note,
               TaskId: taskId,
          } as AddActivityNoteRequest;
          postAddActivityNote(payload);

          formik.setValues({ Note: '' });
          setShowNewNoteArea(false);
     };

     const handleShowHideNewNoteArea = () => {
          setShowNewNoteArea(!showNewNoteArea);
     };

     /** Other Hooks */
     const { alert, setAlert } = useAlert();
     const {
          mutate: postAddActivityNote,
          isLoading: isPostAddActivityNoteLoading,
          data: postAddActivityNoteData,
          error: postAddActivityNoteError,
     } = usePostAddActivityNote();

     /** Effects Hooks */
     useEffect(() => {
          if (postAddActivityNoteData) {
               refetchTask();
          }
     }, [postAddActivityNoteData]);

     useEffect(() => {
          if (postAddActivityNoteError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: postAddActivityNoteError.Subject,
                    body:  postAddActivityNoteError.Description,
               });
          }
     }, [postAddActivityNoteError]);

     return (
          <JobActivitiesDisplayWrapper>
               <Alert {...alert} />
               <h2>Activities</h2>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         {!showNewNoteArea && (
                              <FormikControl
                                   name={'+ New Note'}
                                   label='+ New Note'
                                   control={FormControlKeys.Button}
                                   onClick={() => {
                                        handleShowHideNewNoteArea()
                                   }}
                              />
                         )}
                         {showNewNoteArea && (
                              <>
                                   <FormikControl name={'Note'} control={FormControlKeys.Textarea} />
                                   <FormikControl
                                        name={'Save'}
                                        label='Save'
                                        control={FormControlKeys.Button}
                                        type='submit'
                                        onClick={() => handleAddActivityNote()}
                                   />
                                   <FormikControl
                                        name={'Cancel'}
                                        label='Cancel'
                                        control={FormControlKeys.Button}
                                        onClick={() => handleShowHideNewNoteArea()}
                                   />
                              </>
                         )}
                    </Form>
               </FormikProvider>
               {activities.map((activity, index) => (
                    <React.Fragment key={index}>
                         <ShowPleaseWait show={isPostAddActivityNoteLoading} />
                         <ActivityWrapper>
                              {(activity.ActivityType === NoteActivityType.AttachmentDeleted ||
                              activity.ActivityType === NoteActivityType.AttachmentAdded) && (
                                   <ActivityPointWrapper>
                                        <span>📁</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span className='activity-text'>
                                             <b>{' ' + activity.ActivityText}</b>
                                        </span>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.UserAssigned && (
                                   <ActivityPointWrapper>
                                        <span>👤</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span className='activity-text'>
                                             <b>{' ' + activity.ActivityText}</b>
                                        </span>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.Created && (
                                   <ActivityPointWrapper>
                                        <span>🆕</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span className='activity-text'> {' Task created by '} {activity.PerformingUser}</span>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.NoteAdded && (
                                   <ActivityPointWrapper>
                                        <span>📝</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span className='activity-text'>{' Note added by '} {activity.PerformingUser}</span>
                                        <ActivityNoteWrapper>
                                             <span>{activity.ActivityText}</span>
                                        </ActivityNoteWrapper>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.StatusUpdated && (
                                   <ActivityPointWrapper>
                                        <span>🔄</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span className='activity-text'>
                                             <b>{' ' + activity.ActivityText}</b>
                                        </span>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.Correction && (
                                   <ActivityPointWrapper>
                                        <span>🛠️</span>
                                        <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                        <span>
                                             <b>{' ' + activity.ActivityText}</b>
                                        </span>
                                   </ActivityPointWrapper>
                              )}
                              {activity.ActivityType === NoteActivityType.Unknown && (
                                        <ActivityPointWrapper>
                                             <span>➡️</span>
                                             <span className='timestamp'>[ {formatDateTime(activity.Timestamp)} ]</span>
                                             {' by '}
                                             <span>{activity.PerformingUser}</span>
                                             <ActivityNoteWrapper>
                                             <span className='activity-text'>
                                                  {activity.ActivityText}</span>
                                             </ActivityNoteWrapper>
                                        </ActivityPointWrapper>
                                   )}
                         </ActivityWrapper>
                    </React.Fragment>
               ))}
          </JobActivitiesDisplayWrapper>
     );
};

export default JobActivitiesDisplay;
