import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { ExportToCsvResponseModel } from '../../../../../Models/Common';
import { JobActivityHistoryRequestParam } from '../../../../../Models/Jobs/JobActivityHistory';

export const useExportJobsActivityHistoryToCsv = (payload: JobActivityHistoryRequestParam,
    options?: any
): UseQueryResult<ExportToCsvResponseModel, any> => {

    const getJobsActivityHistoryList = ({ queryKey }: any) => {
        return agent.jobActivityHistory.exportJobActivityHistoryToCSV(payload)
    }

    return useQuery(['ExportJobsActivityHistoryToCsv', payload], getJobsActivityHistoryList, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}
