import { useQuery, UseQueryResult } from "react-query";
import { TaskRequestParam } from "../../../../../Models/Tasks/Request";
import { adminTasks } from "../../../../../Axios/Screens/Task";
import { TaskResponseModel } from "../../../../../Models/Tasks/Response";

export const useGetTask = (
  payload: TaskRequestParam,
  options?: any
): UseQueryResult<TaskResponseModel, any> => {
  const getTasks = () => {
    return adminTasks.getTasks(payload);
  };

  return useQuery(["GetTask", payload], getTasks, {
    ...options,
  });
};
