import axios from 'axios';
import { JobActivityHistoryRequestParam } from '../../../Models/Jobs';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetJobsActivityHistoryList_URL,
    ExportJobActivityHistoryToCSV_URL,
} = ApiURLConstants;

export const jobActivityHistory = {
    getJobsActivityHistoryList: (params: JobActivityHistoryRequestParam): Promise<any> => {
        return axios.post(`${GetJobsActivityHistoryList_URL}`, params)
            .then(responseBody)
    },

    exportJobActivityHistoryToCSV: (params: JobActivityHistoryRequestParam): Promise<any> => {
        return axios.post(`${ExportJobActivityHistoryToCSV_URL}`, params, jsonConfig)
            .then(responseBody)
    },
}
