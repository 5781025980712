import { AppThunkAction } from '../../App';
import * as Storage from '../../Utils/Storage';
import { ApiUrlActionTypes } from './Types';

export const SET_API_URL = 'SET_API_URL';
export const GET_API_URL = 'GET_API_URL';

export const apiUrlActionCreators = {
     setApiUrl:
          (url: string): AppThunkAction<ApiUrlActionTypes> =>
          (dispatch, getState) => {
               Storage.setApiUrl(url);
               dispatch({ type: SET_API_URL, payload: url });
          },
     getApiUrl: (): AppThunkAction<ApiUrlActionTypes> => (dispatch, getState) => {
          const url = Storage.getApiUrl();
          dispatch({ type: GET_API_URL, payload: url });
     },
};
