import { useQuery, UseQueryResult } from 'react-query';
import { reports } from '../../../../../Axios/Screens';
import {
     JobStatisticsFilter,
     JobStatisticsResponseModel,
} from '../../../../../Models/Reports/Statistics';

export const useGetStatistics = (
     payload: JobStatisticsFilter,
     options?: any
): UseQueryResult<JobStatisticsResponseModel, any> => {
     const getStatistics = () => {
          return reports.GetReportStatistics(payload);
     };

     return useQuery(['GetStatistics', payload], getStatistics, {
          ...options,
     });
};
