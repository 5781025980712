import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';

export const useDeleteAdministrator = (
  options?: any
): UseMutationResult<any, any, number> => {

  const deleteAdministrator = (administratorID: number) => {
    return agent.admin.deleteAdministrator(administratorID);
  };

  return useMutation(deleteAdministrator);
};
