import React from 'react';
import { FormControlKeys } from '../FormControls/Types';
import {
  PlainInput,
  LabelledInput,
  InputGroupElement,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Button,
  TypeaheadSingleInput,
  TypeaheadMultiInput,
  Textarea,
  DatePickerInput,
  ClickToUpdateField,
  ButtonGroup,
  RatingInput,
} from '../FormControls';
import CheckboxReversed from '../FormControls/CheckboxReversed';
import { SimpleDatePickerInput } from '../FormControls/DatePicker';
import DateInputNotClearable from '../FormControls/DateInputNotClearable';
import DateInput from '../FormControls/DateInput';

function FormikControl({ ...props }) {
  const { control, ...rest } = props;

  switch (control) {
    case FormControlKeys.PlainInput:
      return <PlainInput {...rest} />;

    case FormControlKeys.LabelledInput:
      return <LabelledInput {...rest} />;

    case FormControlKeys.InputGroup:
      return <InputGroupElement {...rest} />;

    case FormControlKeys.Select:
      return <Select {...rest} />;

    case FormControlKeys.Radio:
      return <Radio {...rest} />;

    case FormControlKeys.RadioGroup:
      return <RadioGroup {...rest} />;

    case FormControlKeys.Checkbox:
      return <Checkbox {...rest} />;

    case FormControlKeys.CheckboxReversed:
        return <CheckboxReversed {...rest} />;

    case FormControlKeys.CheckboxGroup:
      return <CheckboxGroup {...rest} />;

    case FormControlKeys.Button:
      return <Button {...rest} />;

    case FormControlKeys.ButtonGroup:
      return <ButtonGroup {...rest} />;

    case FormControlKeys.TypeaheadSingle:
      return <TypeaheadSingleInput {...rest} />;

    case FormControlKeys.TypeaheadMulti:
      return <TypeaheadMultiInput {...rest} />;

    case FormControlKeys.Textarea:
      return <Textarea {...rest} />;

    case FormControlKeys.DatePicker:
      return <DatePickerInput {...rest} />;

    case FormControlKeys.SimpleDatePickerInput:
      return <SimpleDatePickerInput {...rest} />;

    case FormControlKeys.ClickToUpdateField:
      return <ClickToUpdateField {...rest} />;

    case FormControlKeys.Rating:
      return <RatingInput {...rest} />;

    case FormControlKeys.DateInputNotClearable:
      return <DateInputNotClearable {...rest} />;

    case FormControlKeys.DateInput:
      return <DateInput {...rest} />;

    default:
      return <LabelledInput {...rest} />;
  }
}

export default FormikControl;
