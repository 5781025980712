import { FormikProvider,useFormik } from 'formik';
import React,{ useEffect,useState } from 'react';
import { Form } from 'react-bootstrap';
import { ConfirmDialog,Loading } from '../../../../Components';
import { Alert,useAlert } from '../../../../Components/Alert';
import useFileUploader from '../../../../Components/FileUpload/useUploadFileToTempBlob';
import { FormControlKeys } from '../../../../Components/FormControls';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { ActivityColumnRightWrapper,JobDetailsDisplayWrapper,LabelItem } from '../../../../Components/UI';
import { DropdownAdminUsers } from '../../../../Models/Jobs/EditJob';
import { UploadedDocument } from '../../../../Models/Shared/Document';
import { AddTaskAttachmentsRequest,TaskDetailResponseModel,UpdateTaskStatusRequest } from '../../../../Models/Tasks';
import {
getDropdownAdminTaskStatusOptions,
getDropdownAdminUsersForTaskAssignementOptions,
getFormattedDate,
getTaskDataFieldValue,
getTaskTypeFromValue
} from '../../../../Utils';
import {
UpdateTaskDetails,
useDeleteTaskAttachment,
useGetAdminUsersForTaskListDropdown,
useGetAttachmentsFile,
usePostAddTaskAttachments,
usePutAssignTask,
usePutUpdateTaskStatus
} from './Hooks';

import { FailIndicatorIcon } from '../../../../Assets/Images';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import { cellImageStyle } from '../../Billing/AddBill';
import { RemoveLink,StyledDiv,StyledLi,StyledUl } from '../TasksStyledComponents';
import TaskDetail from './TaskDetail';

interface Props {
     adminUserList: DropdownAdminUsers[];
     taskDetails: TaskDetailResponseModel;
     refetchTask: () => void;
}

const ActivityColumnRight: React.FC<Props> = ({ adminUserList, taskDetails, refetchTask }) => {
     const documentTitle = 'You can add a document';
     const [documentsToUploadToStorage, setDocumentsToUploadToStorage] = useState<UploadedDocument[]>([]);
     const [showUploader, setShowUploader] = useState<boolean>(false);
     const [showAssignButtons, setShowAssignButtons] = useState<boolean>(false);
     const [showChangeStatusButtons, setShowChangeStatusButtons] = useState<boolean>(false);
     const [synchronizeDocuments, setSynchronizeDocuments] = useState<boolean>(false);
     const [requestAttachment, setRequestTaskAttachment] = useState<UploadedDocument>({} as UploadedDocument);
     const [TaskAttachments, setTaskAttachments] = useState<UploadedDocument[]>([]);

     /** Custom Hooks */
     const { alert, handleDisplayAlert } = useAlert();
     const {
          openConfirmDialog,
          closeConfirmDialog: onConfirmDialogClose,
          confirmDialogProps: confirmDialogContent,
     } = useConfirmDialog();
     const { uploadFiles } = useFileUploader();
     const {
          mutate: postAssignTask,
          isLoading: isPutAssignTaskLoading,
          data: postAssignTaskData,
          error: postAssignTaskError,
     } = usePutAssignTask({
          enabled: false,
     });
     const {
          mutate: putUpdateTaskStatus,
          isLoading: isPutUpdateTaskStatusLoading,
          data: putUpdateTaskStatusData,
          error: putUpdateTaskStatusError,
     } = usePutUpdateTaskStatus();
     const { isLoading: isGetAdminUsersForTaskListDropdownLoading, error: getAdminUsersForTaskListDropdownError } =
          useGetAdminUsersForTaskListDropdown(true);
     const {
          mutate: postAddTaskAttachments,
          isLoading: isPostAddTaskAttachmentsLoading,
          data: postAddTaskAttachmentsData,
          error: postAddTaskAttachmentsError,
     } = usePostAddTaskAttachments();
     const {
          mutate: deleteTaskAttachment,
          error: deleteTaskAttachmentError,
          data: deleteTaskAttachmentData,
     } = useDeleteTaskAttachment();

     const shouldBeRequestedOnMount = false;
     const {
          isLoading: isGetAttachmentsFileLoading,
          data: getAttachmentsFileData,
          error: getAttachmentsFileError,
     } = useGetAttachmentsFile(requestAttachment.Id || 0, shouldBeRequestedOnMount);

     const formik = useFormik({
          initialValues: {
               AdminUsers: taskDetails.AssignedToId,
               TaskStatus: taskDetails.CurrentStatus,
          } as any,
          onSubmit: () => {
               updateTaskDetails();
          },
          enableReinitialize: true,
     });

     /** Functions */
     const updateTaskDetails = () => {
          let payload = {
               NewAssignedUserId: formik.values.AdminUsers,
               TaskId: taskDetails.TaskId,
          } as UpdateTaskDetails;
          postAssignTask(payload);
     };

     const handleRemoveUploadedFile = (index: number, attachmentId: number) => {
          if (attachmentId > 0) {
               const attachmentName = TaskAttachments.find((attachment) => attachment.Id === attachmentId)?.Name;
               openConfirmDialog(
                    'Delete Attachment',
                    `Are you sure you want to delete the attachment ${attachmentName ?? attachmentId}?`,
                    () => {
                         deleteTaskAttachment(attachmentId);
                         onConfirmDialogClose();
                    }
               );
          } else {
               setDocumentsToUploadToStorage(
                    documentsToUploadToStorage.filter((attachment, attachmentIndex) => attachmentIndex !== index)
               );
               refetchTask();
               setSynchronizeDocuments(true);
          }
     };

     const onFileUpload = (event: Event) => {
          const target = event.target as HTMLInputElement;
          const files = target.files as FileList;
          const file = files?.[0];
          const newDocument: UploadedDocument = {
               Name: files[0].name,
               Size: files[0].size,
               IsUploaded: true,
               File: file,
          };
          setDocumentsToUploadToStorage([...documentsToUploadToStorage, newDocument]);
     };

     const handleSaveNewAttachments = async () => {
          /* save new files in the storage**/
          const files = documentsToUploadToStorage?.filter((doc) => doc.File)?.map((doc) => doc.File);
          await uploadFiles(files as File[]);

          const payload: AddTaskAttachmentsRequest = {
               TaskId: taskDetails.TaskId,
               Attachments: documentsToUploadToStorage?.map((file) => ({
                    IsNew: true,
                    Name: file.Name,
                    TemporaryFile: file.Name,
                    IsUploaded: true,
                    JobId: taskDetails.JobId,
               })),
          };
          postAddTaskAttachments(payload);
          setDocumentsToUploadToStorage([]);
     };

     const handleDropdownChange = (event: any, handleChange: any) => {
          setShowAssignButtons(true);
          handleChange(event);
     };

     const handleAttachmentClick = (doc: UploadedDocument) => {
          if (doc.Id != null) {
               setRequestTaskAttachment(doc);
          }
     };

     const openTaskAttachment = (documentName: string) => {
          if (getAttachmentsFileData) {
               const blob: Blob = new Blob([getAttachmentsFileData.data], { type: getAttachmentsFileData.contentType });
               const url = URL.createObjectURL(blob);
               const link = document.createElement('a');
               link.href = url;
               link.download = documentName;
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
               URL.revokeObjectURL(url);
          }
     };

     const renderTaskAttachment = (taskAttachments: UploadedDocument[]) => {
          return (
               <>
                    {taskAttachments?.map((doc, index) => (
                         <StyledLi key={index}>
                              <span
                                   onClick={() => {
                                        if (doc.Id) handleAttachmentClick(doc);
                                   }}
                              >
                                   {doc.Name}
                              </span>

                              <RemoveLink
                                   className='remove-attachment'
                                   href='#remove-attachment'
                                   title='Remove Attachment'
                                   onClick={() => {
                                        handleRemoveUploadedFile(index, doc.Id ?? 0);
                                   }}
                              >
                                   <img alt='' style={cellImageStyle} src={FailIndicatorIcon} />
                              </RemoveLink>
                         </StyledLi>
                    ))}
               </>
          );
     };

     /** useEffect */
     useEffect(() => {
          const existingTaskAttachments = taskDetails.TaskAttachments?.map((attachment) => {
               return { Name: attachment.FilePath, Id: attachment.TaskAttachmentId } as UploadedDocument;
          });
          if (existingTaskAttachments !== null && existingTaskAttachments !== undefined)
               setTaskAttachments(existingTaskAttachments);
          else setTaskAttachments([]);
     }, [taskDetails, synchronizeDocuments]);

     useEffect(() => {
          if (documentsToUploadToStorage.length === 0) setShowUploader(false);
     }, [documentsToUploadToStorage]);

     useEffect(() => {
          if (getAttachmentsFileData) {
               openTaskAttachment(requestAttachment.Name?.toString() ?? 'downoladedFile');
               setRequestTaskAttachment({} as UploadedDocument);
          }
     }, [getAttachmentsFileData]);

     useEffect(() => {
          if (postAddTaskAttachmentsData) {
               handleDisplayAlert(
                    postAddTaskAttachmentsData,
                    'Task attachments updated successfully',
                    'Task attachments updated successfully.'
               );
               refetchTask();
          }
     }, [postAddTaskAttachmentsData]);

     useEffect(() => {
          if (postAssignTaskData) {
               handleDisplayAlert(postAssignTaskData, postAssignTaskData.ResponseMessage, postAssignTaskData.ResponseMessage);
               setShowAssignButtons(false);
               refetchTask();
          }
     }, [postAssignTaskData]);

     useEffect(() => {
          if (putUpdateTaskStatusData) {
               handleDisplayAlert(
                    putUpdateTaskStatusData,
                    'Task status updated successfully',
                    'Task status updated successfully.'
               );
               setShowChangeStatusButtons(false);
               refetchTask();
          }
     }, [putUpdateTaskStatusData]);

     useEffect(() => {
          if (deleteTaskAttachmentData) {
               handleDisplayAlert(
                    deleteTaskAttachmentData,
                    'Task attachment deleted successfully',
                    'Task attachment deleted successfully.'
               );
               refetchTask();
          }
     }, [deleteTaskAttachmentData]);

     useEffect(() => {
          if (getAttachmentsFileError) {
               handleDisplayAlert(
                    getAttachmentsFileError,
                    getAttachmentsFileError?.Subject ?? 'Error when trying to fetch attachment files',
                    getAttachmentsFileError?.Description ?? 'Error when trying to fetch attachment files.'
               );
          }
          if (deleteTaskAttachmentError) {
               handleDisplayAlert(
                    deleteTaskAttachmentError,
                    deleteTaskAttachmentError?.Subject ?? 'Error when trying to delete task attachment',
                    deleteTaskAttachmentError?.Description ?? 'Error when trying to delete task attachment.'
               );
          }
          if (getAdminUsersForTaskListDropdownError) {
               handleDisplayAlert(
                    getAdminUsersForTaskListDropdownError,
                    getAdminUsersForTaskListDropdownError?.Subject ??
                         'Error when trying to fetch admin users for task list dropdown',
                    getAdminUsersForTaskListDropdownError?.Description ??
                         'Error when trying to fetch admin users for task list dropdown.'
               );
          }
          if (putUpdateTaskStatusError) {
               handleDisplayAlert(
                    putUpdateTaskStatusError,
                    putUpdateTaskStatusError?.Subject ?? 'Error when trying to update task status',
                    putUpdateTaskStatusError?.Description ?? 'Error when trying to update task status.'
               );
          }
          if (postAssignTaskError) {
               handleDisplayAlert(
                    postAssignTaskError,
                    postAssignTaskError?.Subject ?? 'Error when trying to assign task',
                    postAssignTaskError?.Description ?? 'Error when trying to assign task.'
               );
          }
          if (postAddTaskAttachmentsError) {
               handleDisplayAlert(
                    postAddTaskAttachmentsError,
                    postAddTaskAttachmentsError?.Subject ?? 'Error when trying to add task attachments',
                    postAddTaskAttachmentsError?.Description ?? 'Error when trying to add task attachments.'
               );
               setShowUploader(false);
               setDocumentsToUploadToStorage([]);
          }
     }, [
          getAttachmentsFileError,
          deleteTaskAttachmentError,
          getAdminUsersForTaskListDropdownError,
          putUpdateTaskStatusError,
          postAssignTaskError,
          postAddTaskAttachmentsError,
     ]);

     return (
          <ActivityColumnRightWrapper>
               <ConfirmDialog {...confirmDialogContent} />
               <JobDetailsDisplayWrapper>
                    <Loading
                         loading={
                              isPutAssignTaskLoading ||
                              isPostAddTaskAttachmentsLoading ||
                              isGetAdminUsersForTaskListDropdownLoading ||
                              isPutUpdateTaskStatusLoading ||
                              isGetAttachmentsFileLoading
                         }
                    />
                    <Alert {...alert} />
                    <h2>Task Details</h2>
                    <FormikProvider value={formik}>
                         <Form onSubmit={formik.handleSubmit}>
                              <LabelItem>Assigned to:</LabelItem>
                              {adminUserList && (
                                   <FormikControl
                                        control={FormControlKeys.Select}
                                        name={'AdminUsers'}
                                        options={getDropdownAdminUsersForTaskAssignementOptions(adminUserList, false)}
                                        onChange={(event: any) => handleDropdownChange(event, formik.handleChange)}
                                   />
                              )}
                              {showAssignButtons && (
                                   <StyledDiv>
                                        <FormikControl
                                             name='Submit'
                                             label='Submit'
                                             type='submit'
                                             control={FormControlKeys.Button}
                                        ></FormikControl>
                                   </StyledDiv>
                              )}

                              <FormikControl
                                   control={FormControlKeys.Select}
                                   name={'TaskStatus'}
                                   options={getDropdownAdminTaskStatusOptions(
                                        taskDetails.NextWorkflowStatuses,
                                        taskDetails.CurrentStatus
                                   )}
                                   onClick={() => {
                                        setShowChangeStatusButtons(true);
                                   }}
                              />

                              {showChangeStatusButtons && (
                                   <StyledDiv>
                                        <FormikControl
                                             name='Change'
                                             label='Change'
                                             control={FormControlKeys.Button}
                                             onClick={() => {
                                                  const payload = {
                                                       TaskId: taskDetails.TaskId,
                                                       NextStatus: formik.values.TaskStatus,
                                                  } as UpdateTaskStatusRequest;
                                                  putUpdateTaskStatus(payload);
                                             }}
                                        ></FormikControl>
                                   </StyledDiv>
                              )}

                              <TaskDetail label='Created By' value={taskDetails.CreatedBy} />
                              <TaskDetail label='Task ID' value={taskDetails.TaskId} />
                              <TaskDetail label='Task Type' value={getTaskTypeFromValue(taskDetails.TaskType) ?? 'N/A'} />
                              {(taskDetails.TaskDataFieldValues != null && taskDetails.TaskDataFieldValues.length > 0) && (
                                   <TaskDetail label='Additional Cost Amount' value={getTaskDataFieldValue(taskDetails.TaskType, taskDetails.TaskDataFieldValues)}/>
                              )}
                              <TaskDetail label='Job ID' value={taskDetails.JobId} />
                              <TaskDetail label='Member ID' value={taskDetails.MemberId} />
                              <TaskDetail label='Created On' value={getFormattedDate(taskDetails.CreatedOn)} />
                              <TaskDetail label='Due On' value={getFormattedDate(taskDetails.DueOn)} />

                              {!showUploader && (
                                   <FormikControl
                                        name='AddAttachment'
                                        label='Add Attachment'
                                        control={FormControlKeys.Button}
                                        onClick={() => {
                                             setShowUploader(true);
                                        }}
                                   ></FormikControl>
                              )}

                              {showUploader && (
                                   <FormikControl
                                        name={'TaskAttachment'}
                                        label='Attachment'
                                        type='file'
                                        title={documentTitle}
                                        onChange={(e: any) => {
                                             onFileUpload(e);
                                        }}
                                   />
                              )}
                              <StyledUl>
                                   {renderTaskAttachment(TaskAttachments)}
                                   {renderTaskAttachment(documentsToUploadToStorage)}
                              </StyledUl>

                              {documentsToUploadToStorage.length > 0 && (
                                   <FormikControl
                                        name='SaveNewAttachments'
                                        label='Save New Attachments'
                                        control={FormControlKeys.Button}
                                        onClick={() => {
                                             handleSaveNewAttachments();
                                        }}
                                   ></FormikControl>
                              )}
                         </Form>
                    </FormikProvider>
               </JobDetailsDisplayWrapper>
          </ActivityColumnRightWrapper>
     );
};

export default ActivityColumnRight;
