import { Tooltip } from "@mui/material";
import React from "react";
import { DirectDebitIcon, GeneralPublicIcon, InvoiceIcon, SuccessIndicatorIcon, SupplierIcon, UserIcon } from "../../Assets/Images";
import { cellImageStyle } from "../../Screens/Admin/Billing/AddBill";
import { MemberType, PaymentMethod } from "../../Utils/Enums";

/** get Images */
export const getPaymentMethodImage = (params?: number | null) => {
    switch (params) {
      case PaymentMethod.Invoice:
        return (
          <Tooltip title="Invoice">
            <img alt="Invoice" style={cellImageStyle} src={InvoiceIcon} />
          </Tooltip>
        );
      case PaymentMethod.PrePay:
        return (
          <Tooltip title="Pre pay">
            <img alt="Pre pay" style={cellImageStyle} src={SuccessIndicatorIcon} />
          </Tooltip>
        );
      case PaymentMethod.SmartDebit:
        return (
          <Tooltip title="Direct Debit">
            <img alt="Direct Debit" style={cellImageStyle} src={DirectDebitIcon} />
          </Tooltip>
        );
      case PaymentMethod.Pending:
        return <React.Fragment />;
    }
}

export const renderImage = (memberType?: MemberType) => {
    switch (memberType) {
      case MemberType.MotorDealer:
        return (
          <Tooltip title="User">
            <img alt="User" style={cellImageStyle} src={UserIcon} />
          </Tooltip>
        );
      case MemberType.TransportCompany:
        return (
          <Tooltip title="Supplier">
            <img alt="Supplier" style={cellImageStyle} src={SupplierIcon} />
          </Tooltip>
        );
      case MemberType.GeneralPublic:
        return (
          <Tooltip title="General Public">
            <img
              alt="General Public"
              style={cellImageStyle}
              src={GeneralPublicIcon}
            />
          </Tooltip>
        );
      default:
        return <React.Fragment />;
    }
  };