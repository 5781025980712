import { FormikProvider, useFormik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormContainer,
  Margin20BottomContainer,
} from '../../../../Components/Formik/StyledComponents';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { FeedbackGridKeys } from '../ViewFeedback/Types';
import withAddForm from '../../Common/AddForm/withAddForm';
import {
  ShowPleaseWait,
  Alert,
  Loading,
  useAlert,
} from '../../../../Components';
import {
  FormControlKeys,
  OptionType,
} from '../../../../Components/FormControls/Types';
import { useGetFeedback, usePostAddFeedback, usePutFeedback } from './Hooks';
import { useGetMembersForDropdown } from '../../Members/EditMember/Tabstrip/MemberBlocks/Hooks';
import { FeedbackItemModel } from '../../../../Models/Feedback';
import './index.css';
import { convertNullsToEmptyStrings,
  isMovexErrorResponse, 
  isSuccessEntityCreatedOrUpdatedResponse} from '../../../../Utils';
import { FeedbackIDParamType } from './Types';
import { FeedbackViewModel } from '../../../../Models/Feedback/FeedbackViewModel';
import MovexResponseError from '../../../../Axios/MovexResponseError';
import { RouteConstants } from '../../../../Routes/Constants';
import { number, object, string } from 'yup';

const validationSchema = object({
  [FeedbackGridKeys.JobID]: number().nullable().required(),
  [FeedbackGridKeys.FeedbackDate]: string().nullable().required(),
  [FeedbackGridKeys.FromMemberID]: number().notOneOf([0], FeedbackGridKeys.FromMemberID + ' is a required field.'),
  [FeedbackGridKeys.ToMemberID]: number().notOneOf([0], FeedbackGridKeys.ToMemberID + ' is a required field.'),
  [FeedbackGridKeys.Communication]: number().nullable().notOneOf([0], FeedbackGridKeys.Communication + ' is a required field.'),
  [FeedbackGridKeys.Payment]: number().nullable().notOneOf([0], FeedbackGridKeys.Payment + ' is a required field.'),
  [FeedbackGridKeys.Promptness]: number().nullable().notOneOf([0], FeedbackGridKeys.Promptness + ' is a required field.'),
  [FeedbackGridKeys.Professionalism]: number().notOneOf([0], FeedbackGridKeys.Professionalism + ' is a required field.'),
  [FeedbackGridKeys.Overall]: number().nullable().notOneOf([0], FeedbackGridKeys.Overall + ' is a required field.'),
  [FeedbackGridKeys.SmartDrivers]: number().nullable().notOneOf([0], FeedbackGridKeys.SmartDrivers + ' is a required field.'),
  [FeedbackGridKeys.Communication]: number().nullable().notOneOf([0], FeedbackGridKeys.Communication + ' is a required field.'),
  [FeedbackGridKeys.ValueForMoney]: number().nullable().notOneOf([0], FeedbackGridKeys.ValueForMoney + ' is a required field.')
});

const AddFeedback: React.FC = (): JSX.Element => {
  const { feedbackID } = useParams<FeedbackIDParamType>();
  const feedbackId = feedbackID ? parseInt(feedbackID) : 0;
  const isEditMode = feedbackId > 0;

  const history = useHistory();

  const addFeedbackItemFormInitialValues  :  FeedbackViewModel = {
    Comments: '',
    Communication: 0,
    FeedbackDate: null,
    FromMemberID: 0,
    ToMemberID: 0,
    JobID: 0,
    Id: 0,
    Overall: 0,
    Payment: 0,
    Professionalism: 0,
    Promptness: 0,
    SmartDrivers: 0,
    ValueForMoney: 0
  };

  const [formikInitialValues, setFormikInitialValues] = useState<FeedbackViewModel>(addFeedbackItemFormInitialValues);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  const onSubmit = (payload: any): void => {
    const request: FeedbackItemModel = { ...payload };
    if (isEditMode) {
      putUpdateFeedback(request);
    } else {
      postAddFeedback(request);
    }
  };

  const showAlert = (header: string, body: string): void => {
    setAlert({
      ...alert,
      show: true,
      header: header,
      body: body,
    });
  };

  const getMemberListOptions = () => {
    const options: OptionType[] = [];
    memberList.forEach((member) => {
      let className = '';
      if(member.HasAcceptedCurrentTsAndCs !== true)
        className = className + 'dropdown-tsandcs-invalid';
      if(member.PaySuccSelected !== true)
        className = className + 'dropdown-payment-not-setup';
      if(member.Active !== true)
        className = className + 'dropdown-inactive';
      if(member.Disabled === true)
        className = className + 'dropdown-disabled';
      options.push({
        value: member.Id,
        label: member.UserName,
        className: className,
      });
    });
    return options;
  };

  /** Query Hooks */
  const {
    mutate: putUpdateFeedback,
    isLoading: isPutUpdateFeedbackLoading,
    data: updateFeedbackResponse,
    error: updateFeedbackError,
  } = usePutFeedback();

  const {
    mutate: postAddFeedback,
    isLoading: isPostAddFeedbackLoading,
    data: addFeedbackResponse,
    error: addFeedbackError,
  } = usePostAddFeedback();

  const { data: memberList = [] } = useGetMembersForDropdown();
  const { 
    isLoading: isGetFeedbackListLoading, 
    data: getFeedbackResponse, 
    refetch: refetchGetFeedback,
    error: getFeedbackError
   } = useGetFeedback(feedbackId);

  const onFeedbackSaveSuccess = () => {
      history.push(RouteConstants.ViewFeedbacks);
  };

  /** useEffects */
  useEffect(() => {
    if (getFeedbackResponse) {
      const Feedback = getFeedbackResponse;
      convertNullsToEmptyStrings(Feedback);
      setFormikInitialValues({
        ...formikInitialValues,
        ...Feedback,
      });
    }
  }, [getFeedbackResponse]);
  
  useEffect(() => {
    if (feedbackID) {
      refetchGetFeedback();
    } else {
      setFormikInitialValues(addFeedbackItemFormInitialValues);
    }
  }, [feedbackID]);
  useEffect(() => {
    setIsLoading(isGetFeedbackListLoading);
  }, [isGetFeedbackListLoading]);

  useEffect(() => {
    setShowPleaseWait(isPostAddFeedbackLoading || isPutUpdateFeedbackLoading);
  }, [isPostAddFeedbackLoading, isPutUpdateFeedbackLoading]);

  useEffect(() => {
    if (addFeedbackError) {
        const errorContent = MovexResponseError.getMovexErrorContent(addFeedbackError);
        setAlert({
          ...alert,
          show: true,
          header: errorContent.header,
          body: errorContent.body,
        });
    }
  }, [addFeedbackError]);

  useEffect(() => {
    if (addFeedbackResponse) {
      console.log(addFeedbackResponse);
      showAlert('Add Feedback ', "Feedback added successfully");
    }
  }, [addFeedbackResponse]);

  useEffect(() => {
    if (updateFeedbackError) {
      if (isMovexErrorResponse(updateFeedbackError)) {
        setAlert({
          ...alert,
          show: true,
          header: "Error when updating a feedback",
          body: MovexResponseError.getMovexErrorContent(updateFeedbackError).body,
        });
      }
    }
  }, [updateFeedbackError]);

  useEffect(() => {
    if (updateFeedbackResponse) {
      console.log(updateFeedbackResponse);
      showAlert('Update Feedback ', "Feedback updated successfully.");
    }
  }, [updateFeedbackResponse]);

  useEffect(() => {
    if (addFeedbackResponse) {
      setAlert({
        ...alert,
        show: true,
        body: 'Save Successful!',
        closeCallback: onFeedbackSaveSuccess,
      });
    }
  }, [addFeedbackResponse]);

  useEffect(() => {
    if (updateFeedbackResponse) {
      setAlert({
        ...alert,
        show: true,
        body: 'Update Successful!',
        closeCallback: onFeedbackSaveSuccess,
      });
    }
  }, [updateFeedbackResponse]);

  useEffect(() => {
    if (getFeedbackError) {
      setAlert({
        ...alert,
        show: true,
        header: getFeedbackError.Subject ?? 'Error when getting feedback',
        body: getFeedbackError?.Description,
        closeCallback: getFeedbackError,
      });
    }
  }, [getFeedbackError]);

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit } = formik;
  const component = (
    <FormikProvider value={formik}>
      <Alert {...alert} />
      <ShowPleaseWait show={showPleaseWait} />
      <Loading loading={isLoading} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Row>
          <Col>
            <FormikControl
              type="date"
              name={FeedbackGridKeys.FeedbackDate}
              label="Feedback Date"
            />
          </Col>
          <Col>
            <FormikControl
              name={FeedbackGridKeys.JobID}
              label="Job ID"
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Select}
              name={FeedbackGridKeys.FromMemberID}
              label="From Member"
              placeholder="Select"
              options={getMemberListOptions()}
            />
          </Col>
          <Col>
            <FormikControl
              control={FormControlKeys.Select}
              name={FeedbackGridKeys.ToMemberID}
              label="To Member"
              placeholder="Select"
              options={getMemberListOptions()}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.Payment}
              label="Payment?"
            />
          </Col>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.SmartDrivers}
              label="Smart Drivers?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.Promptness}
              label="Promptness?"
            />
          </Col>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.Communication}
              label="Communication?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.Professionalism}
              label="Professionalism?"
            />
          </Col>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.ValueForMoney}
              label="Value for Money?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Rating}
              name={FeedbackGridKeys.Overall}
              label="How would you rate them overall?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              control={FormControlKeys.Textarea}
              name={FeedbackGridKeys.Comments}
              label="Comments"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Margin20BottomContainer style={{ height: '3em' }}>
              <FormikControl
                control={FormControlKeys.Button}
                name="AddFeedback"
                className="btn btn-primary f-r"
                type="submit"
                label={isEditMode ? 'Update Feedback' : 'Add Feedback'}
              />
            </Margin20BottomContainer>
          </Col>
        </Row>
      </FormContainer>
    </FormikProvider>
  );
  return withAddForm(component, isEditMode ? 'Update Feedback' : 'Add Feedback');
};

export default AddFeedback;