export interface PriceGroupItemModel {
    Id: number,
    Name: string,
    Type: String
}

export const PriceGroupGridKeys = {
    ID: 'Id',
    Type: 'Type',
    Name: 'Name',
}