import axios from 'axios';
import { AddVehicleModel, GetVehiclesRequest } from '../../../Models/System/Vehicles';
import { UpdateVehicleModel } from '../../../Models/System/Vehicles/UpdateVehicleModel';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetVehicle_URL,
    GetVehicles_URL,
    PostAddVehicle_URL,
    PutUpdateVehicle_URL,
    DeleteVehicle_URL,
} = ApiURLConstants;

export const vehicles = {

    getVehicles: (params: GetVehiclesRequest): Promise<any> => {
        return axios.post(GetVehicles_URL, params, jsonConfig)
            .then(responseBody)
    },

    getVehicle: (vehicleId:any):Promise<any> => {
        return axios.get(`${GetVehicle_URL}?vehicleId=${vehicleId}`)
            .then(responseBody)
    },


    postAddVehicle: (params: AddVehicleModel): Promise<any> => {
        return axios
            .post(PostAddVehicle_URL, params, jsonConfig)
            .then(responseBody);
    },

    putUpdateVehicle: (params: UpdateVehicleModel): Promise<any> => {
        return axios
            .put(PutUpdateVehicle_URL, params, jsonConfig)
            .then(responseBody);
    },

    deleteVehicle: (id: number): Promise<any> => {
        return axios.delete(`${DeleteVehicle_URL}?vehicleId=${id}`)
            .then(responseBody)
    },

}