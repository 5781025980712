import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddSavedSearchModel } from '../../../../../Models/Searches';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddSavedSearch = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddSavedSearchModel> => {
     const addSavedSearch = (payload: AddSavedSearchModel) => {
          return agent.savedSearches.postAddSavedSearch(payload);
     };

     return useMutation(addSavedSearch);
};
