import React from 'react';
import styled from 'styled-components';
import { IGetJobSummaryDetailsResponseModel } from '../../../../Models/Tasks/GetJobSummaryDetailsResponseModel';

interface JobDetailsProps {
     jobSummaryDetails: IGetJobSummaryDetailsResponseModel;
     jobId: number;
}

const JobDetailsContainer = styled.div`
     margin-left: 30px;
     margin-bottom: 20px;
     border-bottom: 1px solid darkgray;
     width: fit-content;
     padding-bottom: 10px;
`;

const JobDetailsHeader = styled.div`
     font-weight: bolder;
     font-size: large;
     margin-bottom: 10px;
`;

const JobDetailsBody = styled.div`
     border-top: 1px solid darkgray;
     padding-top: 10px;
`;

const JobSummaryField = styled.span`
     font-weight: bold;
`;

const JobDetails: React.FC<JobDetailsProps> = ({ jobSummaryDetails, jobId }) => {
     const { JobFound, RequestingMember, SupplyingMember, CollectionAddress, DeliveryAddress, NoOfVehicles, JobStatus } =
          jobSummaryDetails;

     if (!JobFound) {
          return <div>No Job found for this id: {jobId}</div>;
     }

     return (
          <JobDetailsContainer>
               <JobDetailsHeader>Job Details</JobDetailsHeader>
               <JobDetailsBody>
                    <div>
                         <JobSummaryField>Requesting Member: </JobSummaryField>
                         {RequestingMember}
                    </div>
                    <div>
                         <JobSummaryField>Supplying Member: </JobSummaryField>
                         {SupplyingMember}
                    </div>
                    <div>
                         <JobSummaryField>Collection Address: </JobSummaryField>
                         {CollectionAddress}
                    </div>
                    <div>
                         <JobSummaryField>Delivery Address: </JobSummaryField>
                         {DeliveryAddress}
                    </div>
                    <div>
                         <JobSummaryField>Number Of Vehicles: </JobSummaryField>
                         {NoOfVehicles}
                    </div>
                    <div>
                         <JobSummaryField>Job Status: </JobSummaryField>
                         {JobStatus}
                    </div>
               </JobDetailsBody>
          </JobDetailsContainer>
     );
};

export default JobDetails;
