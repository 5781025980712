export const ViewStatisticsGridKeys = {
    Label: 'Label',
    Value: 'Value',
}

export const ViewStatisticsFilterKeys = {
    FromDate: 'FromDate',
    ToDate: 'ToDate',
    AdminUserID: 'AdminUserID',
    GroupID: 'GroupID',
    PartnerID: 'PartnerID',
    StatisticsType: 'StatisticsType',
}