import { ErrorMessage,Field,useField } from 'formik';
import { getDateForInput,isNonNull } from '../../Utils';
import TextError from '../Formik/TextError';

export const LabelledInput = ({ ...props }) => {
  const { name, label, type, showRequired, placeholder, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  let { value, touched, error } = meta;
  const { setValue } = helpers;

  /** Extract 'date' part from 'datetime' value to bind date type input field */
  if (value && type === 'date') {
    value = getDateForInput(value);
  } else if (value && type === 'number' && rest?.min === 0) {
    value = Math.abs(value);
  }

  const className =
    touched && error
      ? 'is-invalid'
      : touched && isNonNull(value)
      ? 'is-valid'
      : '';

  return (
       <div className='mb-3 form-group'>
            {label && (
                 <label htmlFor={name} className='form-label fw-600'>
                      {label}
                 </label>
            )}
            {showRequired && (
                 <span className='asterisk' title='Required'>
                      *
                 </span>
            )}
            {type === 'currency' ? (
                 <div className='input-group'>
                      <div className='input-group-prepend'>
                           <span className='input-custom-currency-styling'>
                                £
                           </span>
                      </div>
                      <Field
                           name={name}
                           id={name}
                           type='number'
                           className={`form-control ${className}`}
                           style={{zIndex: 0}}
                           value={value}
                           onChange={(e: any) => setValue(parseFloat(e.target.value))}
                           placeholder={placeholder}
                           {...rest}
                      />
                 </div>
            ) : (
                 <Field
                      name={name}
                      id={name}
                      type={type}
                      className={`form-control ${className}`}
                      value={value}
                      placeholder={placeholder}
                      onChange={(e: any) => {
                           if (e.target.type === 'date') {
                                setValue(e.target.value);
                           } else if (e.target.type === 'number') {
                                setValue(parseInt(e.target.value));
                           } else {
                                setValue(e.target.value);
                           }
                      }}
                      {...rest}
                 />
            )}
            <ErrorMessage component={TextError} name={name} />
       </div>
  );
};

export default LabelledInput;
