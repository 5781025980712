import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll';
import { boolean } from 'yup';
import { ApplicationState } from '../../../../Store/Types';

const ScrollToNavbar = () => {
  const [show, setShow] = useState<boolean>(true);
  const membersState = useSelector(
    (state: ApplicationState) => state.membersState!
  );
  
  useEffect(() => {
    if (membersState?.isScrollbarVisible !== null && membersState?.isScrollbarVisible !== undefined) {
      setShow(membersState.isScrollbarVisible);
    }
  }, [membersState.isScrollbarVisible]);

  return (
    <div
      className="col-md-2 scroll-to-general-tab-menus"
      style={{ display: show ? 'block' : 'none' }}
    >
      <Nav
        id="addMemberLeftNav"
        className="flex-column"
        style={{ position: 'fixed', marginTop: 30 }}
      >
        <Navbar.Brand style={{ padding: '10px 7px' }}>
          On this page
        </Navbar.Brand>
        <Link spy={true} activeClass="active" to="creditReportSection">
          Credit Report
        </Link>
        <Link spy={true} to="codaInfoSection">
          Coda Information
        </Link>

        <Link spy={true} to="loginInfoSection">
          Login Information
        </Link>

        <Link spy={true} to="membershipInfoSection">
          Membership Information
        </Link>

        <Link spy={true} to="mainContactSection">
          Main Contact
        </Link>

        <Link spy={true} to="partnerContactSection">
          Partner Contact
        </Link>

        <Link spy={true} to="companyInfoSection">
          Company Information
        </Link>

        <Link spy={true} to="insuranceInfoSection">
          Insurance Information
        </Link>
        
        <Link spy={true} to="tradePlates">
          Trade Plates
        </Link>

        <Link spy={true} to="serviceInfoSection">
          Service Information
        </Link>

        <Link spy={true} to="paymentInfoSection">
          Payment Information
        </Link>

        <Link spy={true} to="membershipFeesSection">
          Membership Fees
        </Link>

        <Link spy={true} to="emailPreferencesSection">
          Email Preferences
        </Link>

        <Link spy={true} to="notesSection">
          Notes For Admin Use
        </Link>
      </Nav>
    </div>
  );
};

export default ScrollToNavbar;
