import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetPricePriceGroups = (payload: number, options?: any): UseQueryResult<any, any> => {
     const getPricePriceGroups = ({ queryKey }: any) => {
          return agent.priceGroup.getPricePriceGroups(payload);
     };

     const isPayloadValid = Boolean(payload && payload !== 0);

     return useQuery(['getPricePriceGroups'], getPricePriceGroups, {
          enabled: isPayloadValid,
          ...options,
     });
};
