export interface JobItemModel {
    Active: boolean;
    AvgDistance: number;
    Email: string;
    Id: number;
    JobsAdded: number;
    JobsDone: number;
    LastLoginDate: string;
    NumMembers: number;
    ParentID: number;
    TotalDistance: number;
    TotalRecords: number;
    UserName: string;
}

export const JobGridKeys = {
    AdditionalNotes: "AdditionalNotes",
    AdminNotes: 'AdminNotes',
    AdminUserID: 'AdminUserID',
    CollectionDate: "CollectionDate",
    CollectionPostCode: "CollectionPostCode",
    DeliveryDate: "DeliveryDate",
    DeliveryPostCode: "DeliveryPostCode",
    DistanceMiles: "DistanceMiles",
    HasBeenDeclinedBySupplier: "HasBeenDeclinedBySupplier",
    HasQuoteWithinFixedPriceRange: "HasQuoteWithinFixedPriceRange",
    ID: "Id",
    JobKind: "JobKind",
    JobMovementType: "JobMovementType",
    JobOfferedTo: "JobOfferedTo",
    JobStatus: "JobStatus",
    JobType: "JobType",
    MaximumMargin: "MaximumMargin",
    NextExpiringQuote: "NextExpiringQuote",
    NoOfHGVs: "NoOfHGVs",
    NoOfReturnVehicles: "NoOfReturnVehicles",
    NoOfVehicles: "NoOfVehicles",
    NumQuotes: "NumQuotes",
    PartnerCID: "PartnerCID",
    PartnerID: "PartnerID",
    PartnerJID: "PartnerJID",
    PartnerName: "PartnerName",
    PaymentMethod: "PaymentMethod",
    PromoCode: "PromoCode",
    Reference: "Reference",
    RequestDate: "RequestDate",
    RequestingMemberID: "RequestingMemberID",
    RequestingMemberMemberType: "RequestingMemberMemberType",
    RequestingMemberUserName: "RequestingMemberUserName",
    SalesPerson: "SalesPerson",
    ServiceType: "ServiceType",
    SupplyingMemberID: "SupplyingMemberID",
    SupplyingMemberMemberType: "SupplyingMemberMemberType",
    SupplyingMemberUserName: "SupplyingMemberUserName",

    ExpiresInMilliseconds: "ExpiresInMilliseconds",
}