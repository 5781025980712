/** Inbuilt libraries */
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { FormControlKeys } from '../../../../../Components/FormControls';
import CheckBox from '../../../../../Components/FormControls/SimpleCheckbox';
/** Custom Components */
import { FieldSetHeader, HoverableLink } from '../../../../../Components/Formik';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { BillingModel } from '../../../../../Models/Billing/AddBilling/BillingModel';
import { Charge, ChargeV1 } from '../../../../../Models/Billing/AddBilling/ChargeModel';
import { PaymentModel } from '../../../../../Models/Billing/AddBilling/PaymentModel';
import { RouteConstants } from '../../../../../Routes/Constants';
import { getFormattedDateWithYear } from '../../../../../Utils';
import { StyledTd, StyledTdNoCharge, Styledtfoot } from '../../BillsStyledComponents';
import { V1_FeeTotal, V1_FeeVAT, V1_GrandTotal, V1_HandlingFeeTotal } from '../billingFunctions';

const BillVersion1: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { bill, payments, charges } = props;
     

     /** useState */
     const [chargesCheckable, setChargesCheckable] = useState<PaymentModel[]>(payments);
     const [additionalCheckbox, setAdditionalCheckbox] = useState<boolean>(false);
     const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

     /** Query Hooks */

     /** Methods */
     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {},
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     const handleCheckChieldElement = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          let chargesCheckable2 = chargesCheckable;
          chargesCheckable2.forEach((charge) => {
               if (charge.Id === Number(event.currentTarget.value)) {
                    charge.isChecked = event.currentTarget.checked;
               }
          });
          setChargesCheckable([...chargesCheckable2]);
     };

     const handleShowWarningModule = () => {
          alert('The ability to delete charges is not currently available.');
     };

     return (
          <>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>Charges</FieldSetHeader>
                              <table id='id_table_bill_version1' className='table table-bordered table-condensed table-striped'>
                                   <thead>
                                        <tr>
                                             <td></td>
                                             <td>Client</td>
                                             <td>Job Cost</td>
                                             <td>Job ID / Invoiced</td>
                                             <td>Charge Date</td>
                                             <td>Fee</td>
                                             <td>Handling Fee</td>
                                             <td></td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {isWaitingData && <tr>Loading...</tr>}
                                        {!isWaitingData &&
                                             charges?.map((data: ChargeV1, index: number) => {
                                                  const {
                                                       ID,
                                                       JobId,
                                                       ChargeDate,
                                                       QuoteAmount,
                                                       QuoteFee,
                                                       QuoteHandlingFee,
                                                       InvoiceSent,
                                                       RequestingMemberId,
                                                       RequestingMemberUserName
                                                  } = data;
                                                  return (
                                                       <tr key={ID}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                            <HoverableLink to={`${RouteConstants.EditMember}/${RequestingMemberId}`}>
                                                                 {RequestingMemberUserName}
                                                            </HoverableLink>
                                                            </td>
                                                            <td>{'£' + QuoteAmount!.toFixed(2)}</td>
                                                            <td>
                                                                 <HoverableLink to={`${RouteConstants.ViewJobById}/${JobId}`}>
                                                                      {JobId}
                                                                 </HoverableLink>
                                                                 <span>{InvoiceSent && " - Invoiced"}</span>
                                                            </td>
                                                            <td>
                                                                 {getFormattedDateWithYear(
                                                                      ChargeDate!
                                                                 )}
                                                            </td>
                                                            <td>
                                                                 {QuoteFee
                                                                      ? '£' + QuoteFee?.toFixed(2)
                                                                      : ' - '}
                                                            </td>
                                                            <td>
                                                                 {!QuoteHandlingFee
                                                                      ? '-'
                                                                      : '£' + QuoteHandlingFee?.toFixed(2)}
                                                            </td>
                                                            <td>
                                                                 <CheckBox
                                                                      handleCheckChieldElement={handleCheckChieldElement}
                                                                      {...data}
                                                                 />
                                                            </td>
                                                       </tr>
                                                  );
                                             })}
                                   </tbody>
                                   {charges.length === 0 && (
                                        <tbody>
                                             <tr>
                                                  <StyledTdNoCharge colSpan={8}>
                                                       No charges found!
                                                  </StyledTdNoCharge>
                                             </tr>
                                        </tbody>
                                   )}
                                   {charges.length > 0 && (
                                        <Styledtfoot                                          
                                        >
                                             <tr>
                                                  <StyledTd colSpan={5}>
                                                       Total
                                                  </StyledTd>
                                                  <td>£{V1_FeeTotal(charges)}</td>
                                                  <td></td>
                                             </tr>

                                             <tr>
                                                  <StyledTd colSpan={5}>
                                                       Handling Fee
                                                  </StyledTd>
                                                  <td>£{V1_HandlingFeeTotal(charges)}</td>
                                                  <td></td>
                                             </tr>

                                             <tr>
                                                  <StyledTd colSpan={5}>
                                                       VAT
                                                  </StyledTd>
                                                  <td>£{V1_FeeVAT(charges)}</td>
                                                  <td></td>
                                             </tr>

                                             <tr>
                                                  <StyledTd colSpan={5}>
                                                       Grand Total
                                                  </StyledTd>
                                                  <td>£{V1_GrandTotal(charges)}</td>
                                                  <td></td>
                                             </tr>

                                             <tr>
                                                  <StyledTd
                                                       colSpan={7}
                                                  ></StyledTd>
                                                  <td>
                                                       <FormikControl
                                                            name='Delete'
                                                            label='Delete'
                                                            type='submit'
                                                            onClick={handleShowWarningModule}
                                                            control={FormControlKeys.Button}
                                                            size='sm'
                                                            variant='danger'
                                                       />
                                                  </td>
                                             </tr>
                                        </Styledtfoot>
                                   )}
                              </table>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     payments: PaymentModel[];
     bill: BillingModel;
     charges: Charge[];
};

export default BillVersion1;
