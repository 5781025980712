import { faEdit,faEye,faPencilAlt,faTrash,faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem,GridRenderCellParams,GridRowParams } from '@mui/x-data-grid';
import CSS from 'csstype';
import React from 'react';
import { OverlayTrigger,Popover } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { BillPaymentStatus } from '../../Utils/Enums/BillPaymentStatus';
/** Import custom components */
import { HoverableLink,SpanCommentItem } from '.';
/** Import images/icons */
import {
     DirectDebitIcon,
     DrivenServiceIcon,
     FailIndicatorIcon,
     GeneralPublicIcon,
     InvoiceIcon,
     SuccessIndicatorIcon,
     SupplierIcon,
     TransportedServiceIcon,
     UserIcon
} from '../../Assets/Images';
import agent from '../../Axios/Agent';
import { InvoiceItemModel } from '../../Models/Billing/AddInvoice';
import { PaymentItemModel } from '../../Models/Jobs';
import { RouteConstants } from '../../Routes/Constants';
import {
     AdminJobStatusOptions,
     JobKindOptions,
     JobMovementTypeOptions,
     JobOfferedToOptions
} from '../../Screens/Admin/Members/Constants';
import {
     getDayAndMonthDate,
     getFormattedDate,
     lookupEnumByVal,
     millisecondsToString,
     reFormatUkDate,
     reFormatUkDateTimeExpiry
} from '../../Utils';
import {
     InvoiceType,
     JobKind,
     JobOfferedTo,
     JobType,
     MemberType,
     PaymentMethod,
     PriceType,
     ServiceType
} from '../../Utils/Enums';
import { FormControlKeys } from '../FormControls';
import FormikControl from './FormikControl';
import { ArchiveButtonWrapper, CellContent, CellTextWrapper, CellWrapper, TextSpan, TextSpan8 } from '../UI/Payments.styles';

const cellImageStyle: CSS.Properties = { height: '25px', width: '25px' };
const cellWrapStyle: CSS.Properties = { whiteSpace: 'normal', wordWrap: 'break-word'}

const renderCellWithTooltip = (params: GridRenderCellParams) => {
     return (
          <Tooltip title={params.value || ''}>
               <span>{params.value} </span>
          </Tooltip>
     );
};

const renderCellRoundedTooltip = (params: GridRenderCellParams) => {
     return (
          <Tooltip title={params.value || ''}>
               <span>{Math.ceil(params.value)} </span>
          </Tooltip>
     );
};

const renderDateCell = (params: GridRenderCellParams) => {
     const date = params.value === null ? null : getFormattedDate(params.value);
     return (
          <Tooltip title={date || ''}>
               <div style={cellWrapStyle}>{date} </div>
          </Tooltip>
     );
};

const renderDateWithTimeCell = (params: GridRenderCellParams) => {
     if (params.value) {
          const date = reFormatUkDateTimeExpiry(params.value);
          return (
               <Tooltip title={date || ''}>
                    <span>{date} </span>
               </Tooltip>
          );
     } else {
          return (
               <Tooltip title={''}>
                    <span></span>
               </Tooltip>
          );
     }
};

const renderDate = (params: GridRenderCellParams) => {
     if (params.value) {
          const date = reFormatUkDate(params.value);
          return (
               <Tooltip title={date || ''}>
                    <span>{date} </span>
               </Tooltip>
          );
     } else {
          return (
               <Tooltip title={''}>
                    <span></span>
               </Tooltip>
          );
     }
};

const renderCurrencyCell = (params: GridRenderCellParams) => {
     const price = (
          <NumberFormat
               value={params.value}
               displayType={'text'}
               thousandSeparator={true}
               prefix={'£'}
               decimalScale={2}
               fixedDecimalScale={true}
          />
     );
     return (
          <Tooltip title={price}>
               <span>{price}</span>
          </Tooltip>
     );
};

const formatCurrency = (value: number) => (
     <NumberFormat value={value} displayType='text' thousandSeparator prefix='£' decimalScale={2} fixedDecimalScale />
);

const renderColoredCurrencyCell = (params: GridRenderCellParams) => {
     const priceColor = params.value >= 0 ? 'green' : 'red';
     const price = formatCurrency(params.value);

     return (
          <Tooltip title={price} style={{ color: priceColor }}>
               <strong>{price}</strong>
          </Tooltip>
     );
};

const getTooltipWithColor = (title: any, color: string) => (
     <Tooltip title={title} style={{ color }}>
          <strong>{title}</strong>
     </Tooltip>
);

const renderSimpleCurrencyTooltip = (value: number, originalValue: number) => {
     const price = formatCurrency(value);
     const originalPrice = formatCurrency(originalValue);
     return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
               {getTooltipWithColor(price, 'black')}
               {getTooltipWithColor(originalPrice, 'black')}
          </div>
     );
};

const renderCurrencyTooltip = (value: number, originalValue: number) => {
     const isValueSameAsOriginal = value === originalValue;

     const valueColor = value >= 0 ? 'green' : 'red';
     const originalValueColor = originalValue >= 0 ? 'green' : 'red';

     const price = formatCurrency(value);
     const originalPrice = formatCurrency(originalValue);

     if (isValueSameAsOriginal) {
          return getTooltipWithColor(price, valueColor);
     }

     return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
               {getTooltipWithColor(price, valueColor)}
               {getTooltipWithColor(originalPrice, originalValueColor)}
          </div>
     );
};

const renderVatColoredCurrencyCell = (params: GridRenderCellParams) => {
     const value = params.row.SupplyingMemberFeesVat;
     const originalValue = params.row.SupplyingMemberFeesVatOriginal;

     return renderCurrencyTooltip(value, originalValue);
};

const renderTotalPayableColoredCurrencyCell = (params: GridRenderCellParams) => {
     const value = params.row.SupplyingMemberTotal;
     const originalValue = params.row.SupplyingMemberTotalOriginal;

     return renderCurrencyTooltip(value, originalValue);
};

const renderPartnerNameCell = (params: GridRenderCellParams) => {
     return (
          <HoverableLink to={`${RouteConstants.EditPartner}/${params.row.Id}`} style={{ paddingLeft: '10px' }}>
               {params.value}
          </HoverableLink>
     );
};

/** Members Grid CellRenderers */
const renderMemberActionCell = (memberId: number) => {
     return (
          <React.Fragment>
               <HoverableLink title='Edit Member' to={`${RouteConstants.EditMember}/${memberId}`}>
                    <FontAwesomeIcon icon={faPencilAlt} />
               </HoverableLink>
               <a style={{ paddingLeft: '10px' }} 
               title='Impersonate Member' 
               href="https://portal.movex.co.uk/movex-admin/"
               target="_blank"
               rel="noopener noreferrer"
               >
                    <FontAwesomeIcon icon={faUser} />
               </a>
          </React.Fragment>
     );
};

const renderMemberTypeCell = (memberType?: MemberType) => {
     switch (memberType) {
          case MemberType.MotorDealer:
               return (
                    <Tooltip title='User'>
                         <img alt='User' style={cellImageStyle} src={UserIcon} />
                    </Tooltip>
               );
          case MemberType.TransportCompany:
               return (
                    <Tooltip title='Supplier'>
                         <img alt='Supplier' style={cellImageStyle} src={SupplierIcon} />
                    </Tooltip>
               );
          case MemberType.GeneralPublic:
               return (
                    <Tooltip title='General Public'>
                         <img alt='General Public' style={cellImageStyle} src={GeneralPublicIcon} />
                    </Tooltip>
               );
          default:
               return <React.Fragment />;
     }
};

const renderMemberPayCell = (params: GridRenderCellParams) => {
     switch (params.value) {
          case PaymentMethod.Invoice:
               return (
                    <Tooltip title='Invoice'>
                         <img alt='Invoice' style={cellImageStyle} src={InvoiceIcon} />
                    </Tooltip>
               );
          case PaymentMethod.PrePay:
               return (
                    <Tooltip title='Pre pay'>
                         <img alt='Pre pay' style={cellImageStyle} src={SuccessIndicatorIcon} />
                    </Tooltip>
               );
          case PaymentMethod.SmartDebit:
               return (
                    <Tooltip title='Direct Debit'>
                         <img alt='Direct Debit' style={cellImageStyle} src={DirectDebitIcon} />
                    </Tooltip>
               );
          case PaymentMethod.Pending:
               return <React.Fragment />;
     }
};

const renderMemberNotesCell = (notes: string, id: number) => {
     const popover = (
          <Popover id='popover-basic'>
               <Popover.Header as='h3'>Notes</Popover.Header>
               <Popover.Body>{notes}</Popover.Body>
          </Popover>
     );
     return (
          <OverlayTrigger trigger={['hover', 'focus']} placement='left' overlay={popover}>
               <HoverableLink className='hoverable-tag clickable' to={`${RouteConstants.EditMember}/${id}`}>
                    View
               </HoverableLink>
          </OverlayTrigger>
     );
};

/** Group Grid CellRenderers */
const renderEditGroupByGroupIdCell = (params: GridRenderCellParams) => {
     return <HoverableLink to={`${RouteConstants.EditGroup}/${params.id}`}>{params.value}</HoverableLink>;
};

const renderGroupActionCell = (params: GridRowParams) => {
     const actionCellDropdown = [
          <GridActionsCellItem
               label='Edit'
               showInMenu
               icon={<EditIcon />}
               onClick={() => {
                    window.open(`${RouteConstants.EditGroup}/${params.id}`);
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
          <GridActionsCellItem
               label='Impersonate'
               showInMenu
               icon={<PersonIcon />}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
          <GridActionsCellItem
               label='View Members'
               showInMenu
               onClick={() => {
                    window.open(`${RouteConstants.MembersByGroup}=${params.id}`);
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
          <GridActionsCellItem
               label='View Jobs'
               showInMenu
               onClick={() => {
                    window.open(`${RouteConstants.JobsByGroup}=${params.id}`);
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
     ];
     return actionCellDropdown;
};

/** Jobs Grid CellRenderers */
const renderJobsActionCell = (notes: string, id: number) => {
     const popover = (
          <Popover id='popover-basic'>
               <Popover.Header as='h3'>Notes</Popover.Header>
               <Popover.Body className='pre-line'>
                    {notes?.length > 0 ? (
                         <>
                              <b>Admin Notes</b>
                              <br />
                              {notes}
                         </>
                    ) : (
                         'No notes found.'
                    )}
               </Popover.Body>
          </Popover>
     );
     return (
          <OverlayTrigger trigger={['hover', 'focus']} placement='left' overlay={popover}>
               <HoverableLink className='hoverable-tag clickable' to={`${RouteConstants.ViewJobById}/${id}`}>
                    View
               </HoverableLink>
          </OverlayTrigger>
     );
};

export const renderServiceTypeCell = (params: GridRenderCellParams | number) => {
     let value;

     if (params.hasOwnProperty('value') && typeof params === 'object') {
          value = params.value;
     } else {
          value = params;
     }
     const drivenIcon = (
          <Tooltip title='Driven'>
               <img alt='Driven' style={cellImageStyle} src={DrivenServiceIcon} />
          </Tooltip>
     );
     const transportedIcon = (
          <Tooltip title='Transported'>
               <img alt='Transported' style={cellImageStyle} src={TransportedServiceIcon} />
          </Tooltip>
     );
     switch (value) {
          case ServiceType.Driven:
               return drivenIcon;
          case ServiceType.Transported:
               return transportedIcon;
          case ServiceType.Both:
               return (
                    <>
                         {drivenIcon}
                         <span style={{ marginRight: 5 }} />
                         {transportedIcon}
                    </>
               );
          case ServiceType.Unknown:
               return <></>;
     }
};

const renderJobUserOrSupplierCell = (params: GridRenderCellParams, isUser: boolean) => {
     const memberType = isUser
          ? params.row.RequestingMemberMemberType
          : params.row.SupplyingMemberMemberType;
     const memberId = isUser
          ? params.row.RequestingMemberID
          : params.row.SupplyingMemberID;
     return (
          <div>
               {renderMemberTypeCell(memberType as MemberType)}
               <HoverableLink to={`${RouteConstants.EditMember}/${memberId}`}>
                    <span>{params.value}</span>
               </HoverableLink>
          </div>
     );
};

const renderPaymentCell = (params: GridRenderCellParams) => {
     switch (params.value) {
          case PaymentMethod.PrePay:
               return (
                    <Tooltip title='Pre Pay'>
                         <img alt='Pre Pay' style={cellImageStyle} src={SuccessIndicatorIcon} />
                    </Tooltip>
               );
          case PaymentMethod.SmartDebit:
               return (
                    <Tooltip title='Direct Debit'>
                         <img alt='Direct Debit' style={cellImageStyle} src={DirectDebitIcon} />
                    </Tooltip>
               );
          case PaymentMethod.Invoice:
               return (
                    <Tooltip title='Invoice'>
                         <img alt='Invoice' style={cellImageStyle} src={InvoiceIcon} />
                    </Tooltip>
               );
          default:
               return <React.Fragment />;
     }
};

const renderMemberDisabledCell = (params: GridRenderCellParams) => {
     const isEnabledText = params.value ? 'Disabled' : 'Enabled';
     return (
          <Tooltip title={isEnabledText}>
               <span>{isEnabledText}</span>
          </Tooltip>
     );
};

const renderMemberStatusCell = (params: GridRenderCellParams) => {
     const isActiveText = params.value ? 'Active' : 'Inactive';
     return (
          <Tooltip title={isActiveText}>
               <span>{isActiveText}</span>
          </Tooltip>
     );
};

const renderMemberKeyAccountCell = (params: GridRenderCellParams) => {
     if (params.value)
          return (
               <Tooltip title='Key Account'>
                    <img alt='Yes' style={cellImageStyle} src={SuccessIndicatorIcon}></img>
               </Tooltip>
          );
     else
          return (
               <Tooltip title='Not Key Account'>
                    <img alt='No' style={cellImageStyle} src={FailIndicatorIcon}></img>
               </Tooltip>
          );
};

const renderMemberUserNameCell = (params: GridRenderCellParams) => {
     return (
          <Tooltip title={params.value || ''}>
               <HoverableLink to={`${RouteConstants.EditMember}/${params.id}`}>{params.value}</HoverableLink>
          </Tooltip>
     );
};

const renderJobNoOfVehiclesCell = (params: GridRenderCellParams) => {
     const jobKind = params.row.JobKind;
     const noOfReturnVehicles = params.row.NoOfReturnVehicles;
     const noOfHGVs = params.row.NoOfHGVs;
     let jobKindContent = null;
     let hgvContent = null;

     if (jobKind === JobKind.Return) {
          jobKindContent = (
               <>
                    <b className='text-danger' title='Return Vehicles'>
                         O
                    </b>
                    <span>{noOfReturnVehicles}</span>
                    <b className='text-danger' title='Return Vehicles'>
                         R
                    </b>
               </>
          );
     }
     if (noOfHGVs && noOfHGVs > 0) {
          hgvContent = (
               <b className='text-danger' title='HGV Vehicles'>
                    HGV
               </b>
          );
     }
     return (
          <Tooltip title={params.value || ''}>
               <>
                    <span>{params.value}</span>
                    {jobKindContent}
                    {hgvContent}
               </>
          </Tooltip>
     );
};

const renderJobTypeCell = (params: GridRenderCellParams) => {
     switch (params.value) {
          case JobType.RequestQuote:
               return (
                    <Tooltip title='Quotes'>
                         <strong>Q</strong>
                    </Tooltip>
               );
          case JobType.FixedPrice:
               return (
                    <Tooltip title='Fixed Price'>
                         <strong>F</strong>
                    </Tooltip>
               );
          case JobType.Both:
               return (
                    <Tooltip title='Quotes and Fixed Price'>
                         <strong>Q F</strong>
                    </Tooltip>
               );
          case JobType.GuaranteedPrice:
               return (
                    <Tooltip title='Guaranteed Price'>
                         <strong>G</strong>
                    </Tooltip>
               );
     }
};

const renderPriceTypeCell = (params: GridRenderCellParams) => {
     switch (params.value) {
          case PriceType.DealerPrices:
               return (
                    <Tooltip title='DealerPrices'>
                         <p>Dealer Prices</p>
                    </Tooltip>
               );
          case PriceType.TransporterCosts:
               return (
                    <Tooltip title='transporterCosts'>
                         <p>Transporter Costs</p>
                    </Tooltip>
               );
     }
};

const renderJobOfferedToCell = (params: GridRenderCellParams) => {
     if (params.value === JobOfferedTo.FiveStarCompanies) {
          return (
               <Tooltip title='Quotes'>
                    <span className='five-stars'>
                         5<i className='fa fa-star'></i>
                    </span>
               </Tooltip>
          );
     } else {
          const label = lookupEnumByVal(JobOfferedToOptions, params.value.toString()) || '';
          return (
               <Tooltip title={label}>
                    <strong>{label.replace(/[^0-9A-Z]/g, '')}</strong>
               </Tooltip>
          );
     }
};

const renderAdminJobStatusCell = (params: GridRenderCellParams) => {
     const label = lookupEnumByVal(AdminJobStatusOptions, params.value);
     const status = label?.replace(/[^0-9A-Za-z]/g, '_');
     return (
          <Tooltip title={label || ''}>
               <strong>{label || ''}</strong>
          </Tooltip>
     );
};

const renderJobExpiredInCell = (params: GridRenderCellParams) => {
     const label = params.value <= 0 ? 'Expired' : millisecondsToString(params.value, true, true, false, false, true);
     const additionalStyle = params.value < 3600000 ? 'text-danger' : '';
     if (params.value > 0) {
          return <strong className={`text-nowrap ${additionalStyle}`}>{label}</strong>;
     }
};

const renderJobMovementTypeCell = (params: GridRenderCellParams) => {
     const label = lookupEnumByVal(JobMovementTypeOptions, params.value) || '';
     return (
          <Tooltip title={label}>
               <strong>{label.replace(/[^0-9A-Z]/g, '')}</strong>
          </Tooltip>
     );
};

const renderJobKindCell = (params: GridRenderCellParams) => {
     const label = lookupEnumByVal(JobKindOptions, params.value.toString()) || '';
     return (
          <Tooltip title={label}>
               <strong>{label.charAt(0)}</strong>
          </Tooltip>
     );
};

const renderJobSalesCell = (params: GridRenderCellParams) => {
     const adminId = params.row.AdminUserID;
     const maxLength = 20;
     let displayValue = params.value;
     if (displayValue?.length >= maxLength) 
          displayValue = displayValue.substring(0, maxLength) + '...';
     return (
          <HoverableLink to={`${RouteConstants.EditAdministrator}/${adminId}`}>
               <span>{displayValue}</span>
          </HoverableLink>
     );
};

const renderJobMaxMarginCell = (params: GridRenderCellParams) => {
     if (params.value > 0) {
          const label = '£' + params.value.toFixed(2) || '';
          return (
               <Tooltip title={label}>
                    <span>{label}</span>
               </Tooltip>
          );
     }
};

/** Payments */
const renderViewJobByJobIdCell = (params: GridRenderCellParams, key: string, jobId: number) => {
     return (
          <Tooltip title={params.value || ''}>
               <HoverableLink to={`${RouteConstants.ViewJobById}/${jobId}`}>{params.value}</HoverableLink>
          </Tooltip>
     );
};

const renderEditMemberByMemberIdCell = (key: string, id: number, label: string) => {
     return (
          <Tooltip title={label || ''}>
               <HoverableLink to={`${RouteConstants.EditMember}/${id}`}>{label}</HoverableLink>
          </Tooltip>
     );
};

/** Price Group */
const renderEditPriceGroupCell = (params: GridRenderCellParams) => {
     return (
          <Tooltip title={params.value || ''}>
               <HoverableLink to={`${RouteConstants.EditPriceGroupById}/${params.value}`}>{params.value}</HoverableLink>
          </Tooltip>
     );
};

/** Invoices */
const renderInvoicesActionCell = (
     params: GridRowParams, 
     onEmailInvoicePdf : Function, 
     showAlert: Function,
     handleAlertClose : Function) => {
     const row = params.row as InvoiceItemModel;
     const getInvoiceFile = async (Id: number) => {
          try {
               const invoiceResponse = await agent.invoices.getInvoiceFile(Id)
               .catch((error: any) => {
                    showAlert({
                         show: true,
                         header: error.Subject ?? 'Error fetching invoice file',
                         body: error.Description ?? 'An error occurred fetching the invoice file: ' + error.Subject,
                         closeCallback: handleAlertClose,
                    });
               })

               if (invoiceResponse instanceof Blob) {
                    const url = URL.createObjectURL(invoiceResponse);
                    window.open(url, '_blank');
                    window.addEventListener('focus', () => URL.revokeObjectURL(url));
               }
          } catch (error : any) {
               showAlert({
                    show: true,
                    header: "Error fetching invoice file",
                    body: "An error occurred fetching the invoice file: " + error.Subject,
                    closeCallback: handleAlertClose,
               });
          }
     };

     const getCodaFile = async (Id: number) => {
          try {
               const codaResponse = await agent.invoices.getCodaFile(Id)
               .catch((error: any) => {
                    showAlert({
                         show: true,
                         header: 'Error fetching coda file',
                         body: 'An error occurred fetching the coda file: ' + error.Description,
                         closeCallback: handleAlertClose,
                    });})
               if (codaResponse instanceof Blob) {
                    const url = URL.createObjectURL(codaResponse);
                    window.open(url, '_blank');
                    window.addEventListener('focus', () => URL.revokeObjectURL(url));
               }
          } catch (error : any) {
               showAlert({
                    show: true,
                    header: "Error fetching coda file",
                    body: "An error occurred fetching the coda file",
                    closeCallback: handleAlertClose,
               });
          }
     }
     const actionCellDropdown = [
          <GridActionsCellItem
               label='View Invoice Pdf'
               showInMenu
               onClick={() => {
                    getInvoiceFile(row.Id)
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
          <GridActionsCellItem
               label='Email Invoice Pdf'
               showInMenu
               onClick={() => {
                    onEmailInvoicePdf(row.Id);
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
          <GridActionsCellItem
               label='Download Coda File'
               showInMenu
               onClick={() => {
                    getCodaFile(row.Id)
               }}
               onResize={() => {}}
               onResizeCapture={() => {}}
          />,
     ];
     return actionCellDropdown;
};

const renderInvoiceIDCell = (params: GridRenderCellParams) => {
     return (
          <HoverableLink to={`${RouteConstants.ViewInvoiceById}/${params.id}`}>
               <span>{params.value}</span>
          </HoverableLink>
     );
};

const renderInvoiceTypeCell = (params: GridRenderCellParams) => {
     const row = params.row as InvoiceItemModel;
     const type = InvoiceType[row.Type];
     return <span>{type}</span>;
};

/** Prices */
const renderPriceIncludesFuelTypeCell = (params: GridRenderCellParams) => {
     if (params.value) {
          return (
               <Tooltip title='includes fuel'>
                    <img alt='includes fuel' style={cellImageStyle} src={SuccessIndicatorIcon} />
               </Tooltip>
          );
     } else if (params.value === false) {
          return (
               <Tooltip title='does not include fuel'>
                    <img alt='does not include fuel' style={cellImageStyle} src={FailIndicatorIcon} />
               </Tooltip>
          );
     }
};

const renderCommentsCellWithTooltip = (params: GridRenderCellParams) => {
     if (params) {
          return (
               <Tooltip title={params.value || ''}>
                    <SpanCommentItem>{params.value}</SpanCommentItem>
               </Tooltip>
          );
     }
     return <></>;
};

/** Billing Grid CellRenderers */
const renderBillingActionCell = (memberId: number) => {
     return (
          <React.Fragment>
               <HoverableLink title='View Bill' to={`${RouteConstants.ViewBillById}/${memberId}`}>
                    <FontAwesomeIcon icon={faEye} />
               </HoverableLink>
               <a style={{ paddingLeft: '10px' }} title='Delete Bill'>
                    <FontAwesomeIcon icon={faTrash} />
               </a>
          </React.Fragment>
     );
};

const renderPaymentStatusCell = (params: GridRenderCellParams) => {
     if (params.value === BillPaymentStatus.NoCharge)
          return (
               <Tooltip title=''>
                    <p style={{ color: 'green', fontWeight: 'bold' }}>No Charge</p>
               </Tooltip>
          );
     else if (params.value === BillPaymentStatus.Unpaid)
          return (
               <Tooltip title=''>
                    <p style={{ color: 'red', fontWeight: 'bold' }}>Unpaid</p>
               </Tooltip>
          );
     else if (params.value === BillPaymentStatus.Paid)
          return (
               <Tooltip title=''>
                    <p style={{ color: 'green', fontWeight: 'bold' }}>Paid</p>
               </Tooltip>
          );
     else
          return (
               <Tooltip title=''>
                    <p style={{ color: 'green', fontWeight: 'bold' }}>Missed Bill</p>
               </Tooltip>
          );
};

const renderPaidStatusCell = (params: GridRenderCellParams<PaymentItemModel>) => {
     return (
          <Tooltip title=''>
               <p>{params.row}</p>
          </Tooltip>
     );
};

const renderEditBillingByBillingIdCell = (params: GridRenderCellParams) => {
     return <HoverableLink to={`${RouteConstants.ViewBillById}/${params.id}`}>{params.value}</HoverableLink>;
};

const renderBillingMemberUserNameCell = (params: GridRenderCellParams, memberId: number) => {
     return (
          <Tooltip title={params.value || ''}>
               <HoverableLink to={`${RouteConstants.EditMember}/${memberId}`}>{params.value}</HoverableLink>
          </Tooltip>
     );
};

const renderPaymentMethodCell = (value: number | undefined) => {
     const type = PaymentMethod[value ?? 0];
     return <span>{(value ?? 0) === PaymentMethod.Pending ? <img alt='' style={cellImageStyle} src={FailIndicatorIcon} /> : type }</span>;
};

const renderDeleteActionCell = (params: GridRenderCellParams, onDelete: (priceId: number) => void, title: string) => {
     const priceId = parseInt(params.id.toString());
     return (
          <React.Fragment>
               <Tooltip title={title}>
                    <a
                         href='#'
                         style={{ paddingLeft: '10px' }}
                         onClick={() => {
                              onDelete(priceId);
                         }}
                    >
                         <FontAwesomeIcon icon={faTrash} />
                    </a>
               </Tooltip>
          </React.Fragment>
     );
};
  
const renderAdministratorActionCell = (params: GridRenderCellParams, onDelete: (id: number) => void) => {
     return (
          <React.Fragment>
               <Tooltip title='Edit Administrator'>
                    <HoverableLink to={`${RouteConstants.EditAdministrator}/${params.id}`} style={{ paddingLeft: '10px' }}>
                         <FontAwesomeIcon icon={faEdit} />
                    </HoverableLink>
               </Tooltip>
               <Tooltip title='Delete Administrator'>
                    <a
                         style={{ paddingLeft: '10px' }}
                         onClick={() => {
                              onDelete(parseInt(params.id.toString()));
                         }}
                    >
                         <FontAwesomeIcon icon={faTrash} />
                    </a>
               </Tooltip>
          </React.Fragment>
     );
};

const renderBillInvoiceCell = (params: GridRenderCellParams) => {
     if (params.value)
          return (
               <Tooltip title='Invoiced'>
                    <img alt='Yes' style={cellImageStyle} src={SuccessIndicatorIcon}></img>
               </Tooltip>
          );
     else
          return (
               <Tooltip title='Not invoiced'>
                    <img alt='No' style={cellImageStyle} src={FailIndicatorIcon}></img>
               </Tooltip>
          );
};

const renderGroupStatusCell = (params: GridRenderCellParams) => {
     if (params.value)
          return (
               <Tooltip title='Active'>
                    <img alt='Yes' style={cellImageStyle} src={SuccessIndicatorIcon}></img>
               </Tooltip>
          );
     else
          return (
               <Tooltip title='Inactive'>
                    <img alt='No' style={cellImageStyle} src={FailIndicatorIcon}></img>
               </Tooltip>
          );
};

export const CellRenderer = {
     /** Members */
     renderCellWithTooltip,
     renderDateCell,
     renderPartnerNameCell,
     renderMemberActionCell,
     renderMemberTypeCell,
     renderMemberPayCell,
     renderMemberNotesCell,
     /** Groups */
     renderEditGroupByGroupIdCell,
     renderGroupActionCell,
     /** Jobs */
     renderServiceTypeCell,
     renderJobUserOrSupplierCell,
     renderPaymentCell,
     renderMemberDisabledCell,
     renderMemberStatusCell,
     renderMemberKeyAccountCell,
     renderMemberUserNameCell,
     renderJobNoOfVehiclesCell,
     renderJobTypeCell,
     renderJobOfferedToCell,
     renderAdminJobStatusCell,
     renderJobExpiredInCell,
     renderJobMovementTypeCell,
     renderJobKindCell,
     renderJobSalesCell,
     renderJobMaxMarginCell,
     renderJobsActionCell,
     renderDateWithTimeCell,
     renderDate,
     renderCellRoundedTooltip,
     /** Payments */
     renderViewJobByJobIdCell,
     renderEditMemberByMemberIdCell,
     renderPaidStatusCell,
     /** Price Group */
     renderEditPriceGroupCell,
     /** Invoices */
     renderInvoicesActionCell,
     renderInvoiceIDCell,
     renderInvoiceTypeCell,
     /** Prices */
     renderPriceIncludesFuelTypeCell,
     renderCurrencyCell,
     renderPriceTypeCell,
     renderDeleteActionCell,
     /** Feedbacks */
     renderCommentsCellWithTooltip,
     /** Billing */
     renderPaymentStatusCell,
     renderBillingActionCell,
     renderBillingMemberUserNameCell,
     renderEditBillingByBillingIdCell,
     renderPaymentMethodCell,
     /** Administrators */
     renderAdministratorActionCell,
     /** Payments */
     renderColoredCurrencyCell,
     renderVatColoredCurrencyCell,
     renderTotalPayableColoredCurrencyCell,
     renderBillInvoiceCell,
     renderGroupStatusCell
};
