export const AddAdministratorKeys = {
    Id: "Id",
    UserName: "UserName",
    Email: "Email",
    ConfirmEmail: "ConfirmEmail",
    DisplayName: "DisplayName",
    IsAllocatedJobs: "IsAllocatedJobs",
    Active: "Active",
    Password: 'Password',
    ConfirmPassword: 'ConfirmPassword',
    AdminRole: 'AdminRole',
    RoleName: ' RoleName',
}

export type AdminIDParamsType = {
    administratorID: string;
}
