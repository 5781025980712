import { PriceGroupSourceTargetType } from '../../../../../Utils/Enums/PriceGroupSourceTargetType';
import { Label, LabelGroup, LabelMember, LabelPartner } from '../../../../../Components/UI';

export const ApplyEntityTypeStyle = (type?: number) => {
     switch (type) {
          case PriceGroupSourceTargetType.Member:
               return <LabelMember>Member</LabelMember>;
          case PriceGroupSourceTargetType.Group:
               return <LabelGroup>Group</LabelGroup>;
          case PriceGroupSourceTargetType.Partner:
               return <LabelPartner>Partner</LabelPartner>;
          case PriceGroupSourceTargetType.Any:
          default:
               return <Label>...</Label>;
     }
};
