export enum QuoteStatus {
    Negotiated = 100,
    Accepted = 2,
    Unknown = 255,

    // Still In Use...

    Placed = 0,
    Declined = 1,
    Deleted = 3,
    Active = 4,
    ConfirmDeclined = 5,

    Completed = 6,
    Removed = 7
}