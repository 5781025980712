import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddGroupModel } from '../../../../../Models/Groups';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutUpdateGroup = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddGroupModel
> => {

  const putUpdateGroup = (payload: AddGroupModel) => {
    return agent.groups.putUpdateGroup(payload);
  };

  return useMutation(putUpdateGroup);
};