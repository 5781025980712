export async function loadConfig() {
     try {
          const response = await fetch('/config.json');
          if (!response.ok) {
               throw new Error(`Failed to fetch config: ${response.status}`);
          }
          const config = await response.json();
          return config;
     } catch (error) {
          console.error('Error loading config:', error);
          throw error;
     }
}
