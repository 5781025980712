import React, { FC, useCallback, useEffect, useState } from 'react';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { AdminMembersDocumentViewModel } from '../../../../../../Models/Members';
import { RouteConstants } from '../../../../../../Routes';
import { DocumentType } from '../../../../../../Utils/Enums';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../Store/Types';

type Props = {
     documentType: number;
     inputName: string;
     showImagePreview?: boolean;
     allowRemoveAll?: boolean;
     memberID?: number;
     memberDocuments: AdminMembersDocumentViewModel[];
     tempImageFiles: any;
     handleRemoveDocument: (inputName: string, documentName: string, docType: string) => void;
     handleRemoveAllDocumentsByType: (inputName: string, docType: number) => void;
};

const FileUploadContent: FC<Props> = ({
     documentType,
     inputName,
     showImagePreview,
     allowRemoveAll,
     memberID,
     memberDocuments,
     tempImageFiles,
     handleRemoveDocument,
     handleRemoveAllDocumentsByType,
}) => {
     const apiUrl = useSelector((state: ApplicationState) => state.apiUrlState.apiUrl); 
     const [documentsNotFoundTypes, setDocumentsNotFoundTypes] = useState<number[]>([]);

     // List of document URLs
     const [documentUrls, setDocumentUrls] = useState({
          creditReport: '',
          insuranceDocument: '',
          licenseDocument: '',
          photoIdentification: '',
          proofOfBusinessAddress: '',
          proofOfPartnerAddress: '',
          other: ''
     });

     const getDocumentLink = (document: AdminMembersDocumentViewModel, type: number) => {
          if (document.MemberID) {
               return `${apiUrl}/v1/Files/GetMembersDocument?memberID=${document.MemberID}&documentID=${document.Id}&name=${encodeURIComponent(document.Name)}`;
          }
          const matchingFiles = tempImageFiles.filter((file: any) => file.type === type);
          return matchingFiles.length > 0 ? URL.createObjectURL(matchingFiles[0][0]) : '';
     };

     // Clean up blob URLs when the component unmounts.
     useEffect(() => {
          return () => {
              Object.values(documentUrls).forEach(url => {
                  if (url) URL.revokeObjectURL(url);
              });
          };
      }, [documentUrls]);

     useEffect(() => {
          setDocumentsNotFoundTypes([]);
     }, [memberDocuments]);

     const handleDocumentClick = async (e: React.MouseEvent, document: AdminMembersDocumentViewModel, type: number) => {
          e.preventDefault();
          const documentLink = getDocumentLink(document, type);

          try {
              const response = await axios.get(documentLink, { responseType: 'blob' });
              const blobData = response.data;

              if (blobData.type === "unknown/unknown") {
                  setDocumentsNotFoundTypes(prevState => [...prevState, type]);
                  return;
              }

              const blobUrl = URL.createObjectURL(blobData);
              window.open(blobUrl, '_blank');

              const updatedUrls = { ...documentUrls };
              switch (type) {
               case DocumentType.CreditReport:
                   updatedUrls.creditReport = blobUrl;
                   break;
               case DocumentType.InsuranceDocument:
                   updatedUrls.insuranceDocument = blobUrl;
                   break;
               case DocumentType.LicenseDocument:
                   updatedUrls.licenseDocument = blobUrl;
                   break;
               case DocumentType.PhotoIdentification:
                   updatedUrls.photoIdentification = blobUrl;
                   break;
               case DocumentType.ProofOfBusinessAddress:
                   updatedUrls.proofOfBusinessAddress = blobUrl;
                   break;
               case DocumentType.ProofOfPartnerAddress:
                   updatedUrls.proofOfPartnerAddress = blobUrl;
                   break;
               default:
                   console.warn(`Unhandled document type: ${type}`);
           }
           
              setDocumentUrls(updatedUrls);

          } catch (error) {
              console.error('Error fetching document:', error);
          }
     };

     const renderDocumentContent = (document: AdminMembersDocumentViewModel, type: number) => {
          if (documentsNotFoundTypes.includes(type)) {
               return <span>Document Not Found</span>;
          }
          const documentUrl = getDocumentLink(document, type);
          if (showImagePreview) {
              return <img style={{ maxWidth: 200 }} alt={document.Name} src={documentUrl} />;
          }
          if (document.MemberID) {
              return (
                  <a href="#" onClick={(e) => handleDocumentClick(e, document, type)} target='_blank' rel='noreferrer'>
                      {document.Name}
                  </a>
              );
          }
          return <label>{document.Name}</label>;
     };

     const renderRemoveDocumentButton = (document: AdminMembersDocumentViewModel) => (
          <FormikControl
               control='Button'
               label='Remove'
               variant='danger'
               onClick={() => {
                    handleRemoveDocument(inputName, document.Name, document.Type)}}
          />
     );

     const renderRemoveAllDocumentsButton = () => (
          <FormikControl
               control='Button'
               label='Remove All'
               variant='danger'
               onClick={() => handleRemoveAllDocumentsByType(inputName, documentType)}
          />
     );

     const filterDocumentsByType = useCallback(
          (type: number) => memberDocuments.filter((doc) => doc.Type == type.toString()),
          [memberDocuments]
     );

     const renderContent = () => {
          const filteredDocuments = filterDocumentsByType(documentType);
          return filteredDocuments.map((doc) => (
               <div key={doc.Id} className={showImagePreview ? 'image' : 'file'}>
                    {renderDocumentContent(doc, documentType)}
                    {' '}
                    {renderRemoveDocumentButton(doc)}
                    <br />
               </div>
          ));
     };

     return (
          <div className='file-uploader'>
               <div className='document-list'>
                    {renderContent()}
                    <br />
                    {allowRemoveAll && renderContent().length > 0 && renderRemoveAllDocumentsButton()}
               </div>
          </div>
     );
};

export default FileUploadContent;
