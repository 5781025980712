import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UpdateProofStatusModel } from '../../../../../Models/Jobs/Payments';

export const useUpdateProofStatus = (
  options?: any
): UseMutationResult<
  any,
  any,
  UpdateProofStatusModel
> => {

  const updateProofStatus = (payload: UpdateProofStatusModel) => {
    return agent.payments.updateProofStatus(payload);
  };

  return useMutation(updateProofStatus);
};