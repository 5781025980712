import { useMutation, UseMutationResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { UpdateTaskStatusRequest } from '../../../../../Models/Tasks';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutUpdateTaskStatus = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, UpdateTaskStatusRequest> => {
     const updateTaskStatus = (payload: UpdateTaskStatusRequest) => {
          return adminTasks.putUpdateTaskStatus(payload);
     };

     return useMutation(updateTaskStatus);
};
