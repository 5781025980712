import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { MemberIDParamType } from '../../..';
import agent from '../../../../../../../Axios/Agent';
import { GetMemberResponse } from '../../../../../../../Models/Members/AddMember';

const onGetMemberError = (error: any) => {
  console.log('Peform side effect after encountering error', error);
};

const getMember = ({ queryKey }: any) => {
  const [_, memberID] = queryKey;
  if (memberID) {
    return agent.members.getMember(memberID)
  }
};

export const useGetMember = (
  options?: any
): UseQueryResult<GetMemberResponse, any> => {
  const { memberID } = useParams<MemberIDParamType>();

  return useQuery(['GetMember', memberID], getMember, {
    onError: onGetMemberError,
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options,
  });
};
