export const getRowColor = (paymentStatus: number) => {
     switch (paymentStatus) {
          case 3:
               return 'grid-color--success';
          case 1:
               return 'grid-color--info';
          case 1:
               return 'grid-color--orange';
          case 2:
               return 'grid-color--warning';
          case 4:
               return 'grid-color--error';
          default:
               return 'grid-color';
     }
};
