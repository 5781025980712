import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FeedbackItemModel } from '../../../../../Models/Feedback';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutFeedback = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  FeedbackItemModel
> => {

  const updateFeedback = (payload: FeedbackItemModel) => {
    return agent.feedback.putFeedback(payload);
  };

  return useMutation(updateFeedback);
};