import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import React from 'react';
import { ConfirmDialog, ControlledPagination as Pagination } from '../../../../../../Components';
import { useConfirmDialog } from '../../../../../../Components/ConfirmDialog/useConfirmDialog';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { AdminRoleModelKeys, AdminRoleResponseModel } from '../../../../../../Models/System';
import { RouteConstants } from '../../../../../../Routes';
import { GridProps, Sorter } from '../../../../../../Types';
import { ActionColumnPath, getSortModelFromSorter } from '../../../../../../Utils';
import { useDeleteAdminRole } from '../Hooks';

const AdminRolesGridView: React.FC<AdminRolesGridProps> = (props) => {
     const { data: adminRoles, onPageChange, onPageSizeChange, onSort, onRefresh } = props;

     /**Custom Hook */
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();

     const { mutate: deleteAdminRole, isLoading: isDeletingAdminRole, error: deleteAdminRoleError } = useDeleteAdminRole();

     const handleDelete = (id: number) => {
          openConfirmDialog('Delete Admin Role', 'Are you sure you want to delete this admin role?', () => {
               deleteAdminRole(id);
               onRefresh();
               closeConfirmDialog();
          });
     };

     const adminRoleColumns = [
          {
               field: AdminRoleModelKeys.Id,
               headerName: 'ID',
               description: 'ID',
               renderCell: (params: GridRenderCellParams) => (
                    <HoverableLink to={`${RouteConstants.EditAdminRole}/${params.id}`}>{params.value}</HoverableLink>
               ),
          },
          {
               field: AdminRoleModelKeys.RoleName,
               headerName: 'RoleName',
               description: 'RoleName',
               renderCell: (params: GridRenderCellParams) => (
                    <HoverableLink to={`${RouteConstants.EditAdminRole}/${params.id}`}>{params.value}</HoverableLink>
               ),
               flex: 2,
          },
          {
               field: ActionColumnPath,
               headerName: 'Action',
               description: 'Action',
               type: 'actions',
               renderCell: (params: GridRenderCellParams) => renderActionCell(params, handleDelete),
          },
     ];

     const renderActionCell = (params: GridRenderCellParams, handleDelete: Function) => {
          const { Id } = params.row;
          return (
               <React.Fragment>
                    <Tooltip title='Edit Admin Role'>
                         <HoverableLink to={`${RouteConstants.EditAdminRole}/${params.id}`}>
                              <FontAwesomeIcon icon={faEdit} />
                         </HoverableLink>
                    </Tooltip>
                    <Tooltip title='Delete Admin Role'>
                         <HoverableLink to='#' style={{ paddingLeft: '10px', paddingRight: '30px' }}>
                              <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(Id)} />
                         </HoverableLink>
                    </Tooltip>
               </React.Fragment>
          );
     };

     const handlePageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentPage: number) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     if (adminRoles?.Page) {
          const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = adminRoles?.Page;
          const CustomGridToolbar = () => {
               return (
                    <Pagination
                         itemsCount={TotalItems}
                         currentPage={CurrentPage}
                         totalPages={TotalPages}
                         pageSize={ItemsPerPage}
                         onPageChange={handlePageChange}
                         onRowsPerPageChange={handleRowsPerPageChange}
                    />
               );
          };

          return (
               <div>
                    {adminRoles?.Page && (
                         <div className='view-adminroles-container'>
                              <ConfirmDialog {...confirmDialogProps} />
                              <div id='id_grid_adminroles'>
                              <DataGrid
                                   sortingOrder={['desc', 'asc']}
                                   autoHeight
                                   getRowId={(row: any) => row.Id}
                                   disableColumnMenu={true}
                                   rows={Items}
                                   rowHeight={30}
                                   columns={adminRoleColumns}
                                   components={{
                                        Toolbar: CustomGridToolbar,
                                        Footer: CustomGridToolbar,
                                   }}
                                   sortingMode='server'
                                   sortModel={getSortModelFromSorter(adminRoles?.TableSorter as Sorter)}
                                   onSortModelChange={handleSort}
                                   style={{ marginTop: 10 }}
                              />
                              </div>
                         </div>
                    )}
               </div>
          );
     }
     return null;
};

interface AdminRolesGridProps extends GridProps<AdminRoleResponseModel> {
  onRefresh: Function;
}

export default AdminRolesGridView;
