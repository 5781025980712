import React from 'react';
import { RequestingMemberFeeType } from '../../../../Utils/Enums';
import withAddForm from '../../Common/AddForm/withAddForm';
import MarginMaintenance from '../../Common/MarginMaintenance';

const DefaultMargin = () => {
     const component = (
          <MarginMaintenance requestingMemberFeeType={RequestingMemberFeeType.Default} />
     );
     return withAddForm(component, 'Default Margin');
};

export default DefaultMargin;
