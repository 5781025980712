export interface LoginForm {
  formData: LoginData;
}

export type LoginData = {
  Username: string;
  Password: string;
  RememberMe?: boolean;
};

export const LoginFormKeys = {
  Username: "Username",
  Password: "Password",
  RememberMe: "RememberMe"
};