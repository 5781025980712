import { JobActivityType, SqlStringMatchType } from "../../../Utils/Enums"

export interface JobActivityHistoryFilter {
    AdminUserID?: number,
    MemberID?: number,
    JobID?: number,
    SqlStringMatchType?: SqlStringMatchType,
    ActivityFromDate?: Date,
    ActivityToDate?: Date,
    ActivityType?: JobActivityType
}

export const JobsActivityHistoryFilterKeys = {
    JobID: "JobID",
    ActivityFromDate: "ActivityFromDate",
    ActivityToDate: "ActivityToDate",
    ActivityType: "ActivityType",
}