import { useEffect, useState } from 'react';
import { useGetQuoteFeeDetails } from '../../Hooks';
import { JobViewModel } from '../../../../../../Models/Jobs/EditJob';

export function useQuoteFeeDetailDetailManagement(job: JobViewModel) {
     const [quoteDrivenAmountEnteredByUser, setQuoteDrivenAmountEnteredByUser] = useState<number>(
          job.DFixedPriceMin
     );
     const [quoteTransportedAmountEnteredByUser, setQuoteTransportedAmountEnteredByUser] =
          useState<number>(job.TFixedPriceMin);

     const [isFirstRender, setIsFirstRender] = useState(true);

     const { refetch: getDrivenQuoteFeeDetails, data: getQuoteFeeDetailsResponse } =
          useGetQuoteFeeDetails({
               memberId: job.RequestingMemberID,
               quoteAmount: quoteDrivenAmountEnteredByUser,
          }, {
               enabled: false
           });

     const { refetch: getTransportedQuoteFeeDetails, data: getTransportedQuoteFeeDetailsResponse } =
          useGetQuoteFeeDetails({
               memberId: job.RequestingMemberID,
               quoteAmount: quoteTransportedAmountEnteredByUser,
          }, {
               enabled: false
           });

     useEffect(() => {
          if (!isFirstRender && quoteDrivenAmountEnteredByUser) {
               getDrivenQuoteFeeDetails();
          } else {
               setIsFirstRender(false);
          }
     }, [quoteDrivenAmountEnteredByUser]);

     useEffect(() => {
          if (!isFirstRender && quoteTransportedAmountEnteredByUser) {
               getTransportedQuoteFeeDetails();
          } else {
               setIsFirstRender(false);
          }
     }, [quoteTransportedAmountEnteredByUser]);

     return {
          quoteDrivenAmountEnteredByUser,
          setQuoteDrivenAmountEnteredByUser,
          quoteTransportedAmountEnteredByUser,
          setQuoteTransportedAmountEnteredByUser,
          getQuoteFeeDetailsResponse,
          getTransportedQuoteFeeDetailsResponse,
          getDrivenQuoteFeeDetails,
          getTransportedQuoteFeeDetails,
     };
}

