import { useQuery, UseQueryResult } from 'react-query';
import { CommonRequestParam } from '../../../../../../Types';

export const useGetAuthServicesList = (payload: CommonRequestParam,
  options?: any
): UseQueryResult<any, any> => {

  const getAdministratorList = () => {
    // ********** TODO: code to be replaced with api call
    const data = {
      Page: {
        CurrentPage: 1, TotalPages: 1, TotalItems: 4, ItemsPerPage: 50,
        Items: [
          { TotalRecords: 4, Id: 1, Created: "20/05/2022 03:08:52", AuthToken : "B6g23wb18cqIdjz8NOreCelKy9G//dNi1juNyIAnOu8=", ClientVersion: "Web|Admin|11816512091", ClientIP:  "103.167.164.10", UserID : 1, UserName: "admin" },
        ],
        Context: null
      },
      TableSorter: { Column: "ID", Ascending: true }
    }
    //***********
    return data;
  };

  return useQuery(['GetAdministratorList', payload], getAdministratorList, {
    ...options,
  });
};
