/** Import inbuilt libraries*/
import React from "react";
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
/** Import custom components*/
import { ControlledPagination as Pagination } from "../../../../../../Components";
import {
  EmailServiceModelKeys,
  EmailServiceResponseModel,
} from "../../../../../../Models/System";
import { GridProps, Sorter } from "../../../../../../Types";
import {
  ActionColumnPath,
  getSortModelFromSorter,
} from "../../../../../../Utils";
import Checkbox from "@mui/material/Checkbox";

const EmailServiceGridView: React.FC<EmailServiceGridProps> = (props) => {
  const {
    data: emailService,
    onPageChange,
    onPageSizeChange,
    onSort,
  } = props;
  const { Page, TableSorter } = emailService;

  const emailServiceColumns = [
    {
      field: EmailServiceModelKeys.Id,
      headerName: "#",
      description: "ID",
      flex: 0.2,
    },
    {
      field: EmailServiceModelKeys.From,
      headerName: "From",
      description: "From",
      flex: 2,
    },
    {
      field: EmailServiceModelKeys.ReplyTo,
      headerName: "Reply To",
      flex: 4,
      description: "Reply To",
    },
    {
      field: EmailServiceModelKeys.To,
      headerName: "To",
      flex: 3,
      description: "To",
    },
    {
      field: EmailServiceModelKeys.Subject,
      headerName: "Subject",
      flex: 1,
      description: "Subject",
    },
    {
      field: ActionColumnPath,
      headerName: "",
      flex: 0.2,
      description: "Action",
      type: "actions",
      renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    },
  ];

  const renderActionCell = (params: GridRenderCellParams) => {
    return (
      <React.Fragment>
       <Checkbox />
      </React.Fragment>
    );
  };

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (Page) {
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = Page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      );
    };

    return (
      <div>
        {Page && (
          <div id='id_grid_email_service' className="view-administrators-container">
            <DataGrid
              sortingOrder={['desc', 'asc']}
              autoHeight
              getRowId={(row: any) => row.Id}
              disableColumnMenu={true}
              rows={Items}
              rowHeight={30}
              columns={emailServiceColumns}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
              sortModel={getSortModelFromSorter(TableSorter as Sorter)}
              onSortModelChange={handleSort}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

interface EmailServiceGridProps
  extends GridProps<EmailServiceResponseModel> {}

export default EmailServiceGridView;
