import { ResultsViewModel } from "../../../Types"

export interface AuthServicesResponseModel extends ResultsViewModel<AuthServicesModel> { }

export type AuthServicesModel = {
    Id: number;
    Created: string;
    AuthToken: string;
    ClientVersion: string;
    ClientIP: string;
    UserID: number;
    UserName: string;
}

export const AuthServicesModelKeys = {
    Id: "Id",
    Created: "Created",
    AuthToken: "AuthToken",
    ClientVersion: "ClientVersion",
    ClientIP: "ClientIP",
    UserID: "UserID",
    UserName: "UserName",
}