import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import agent from '../../../../../../Axios/Agent';

export const useGetVehicle = (vehicleId: number,
  options?: any
): UseQueryResult<
  any,
  any
> => {
  const getVehicle = () => {
    return agent.vehicles.getVehicle(vehicleId)
  };

  return useQuery(['GetVehicle', vehicleId], getVehicle, {
    ...options,
    refetchOnWindowFocus: false,
  });
};