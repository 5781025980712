import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import { UpdateJobAdminUserModel } from '../../../../../Models/Jobs/ViewJobs/AssignJobModels';

export const SaveAssignJobFooter: React.FC<any> = ({
     user,
     jobsIds,
     assignJobsToUser,
     onClose,
}): JSX.Element => {
     const payload = {
          AdminUserID: user.Id,
          JobId: jobsIds[0],
     } as UpdateJobAdminUserModel;

     return (
          <>
               <Row>
                    <Col style={{marginRight: '-15px'}}>
                         <Button
                              label='Assign'
                              control={FormControlKeys.Button}
                              onClick={() => {
                                   assignJobsToUser(payload);
                              }}
                         />
                    </Col>
                    <Col>
                         <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                    </Col>
               </Row>
          </>
     );
};
