import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddPartnerMemberModel, AddPartnerMemberResponseViewModel } from '../../../../../../../Models/Members/EditMember';

export const usePostAddSelectedMemberToPartner = (
  options?: any
): UseMutationResult<any, any, AddPartnerMemberModel> => {
  const addMemberPartner = (payload: any) => {
    return agent.members.postAddSelectedMemberToPartner(payload)
  };

  return useMutation(addMemberPartner);
};
