import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { DeleteTransporterPlateRequest } from '../../../../../../../Models/Members/EditMember/TradePlates/DeleteTradePlates';
import { MovexErrorResponse } from '../../../../../../../Types';

export const useDeleteTransporterPlate = (options?: any): UseMutationResult<MovexErrorResponse, any, DeleteTransporterPlateRequest> => {
     const deleteTransporterPlate = (payload: DeleteTransporterPlateRequest) => {
          return agent.members.deleteTransporterPlate(payload);
     };

     return useMutation(deleteTransporterPlate);
};