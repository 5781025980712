import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddSourceToPriceGroupRequest } from '../../../../../Models/PriceGroup';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddSourceToPriceGroup = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddSourceToPriceGroupRequest> => {
     const addTargetToPriceGroup = (payload: AddSourceToPriceGroupRequest) => {
          return agent.priceGroup.PostAddSourceToPriceGroup(payload);
     };

     return useMutation(addTargetToPriceGroup);
};
