/** Inbuilt libraries */
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
/** Custom Components */
import {
  FieldSetHeader,
} from "../../../../../Components/Formik";
import { JobsVehicle } from "../../../../../Models/Jobs/EditJob";
import { AdminVehicleType } from "../../../../../Utils/Enums/AdminVehicleType";

const SectionVehicles: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { jobID, vehicles } = props;

  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

  /** Query Hooks */

  /** Methods */
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: () => {
    },
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <FieldSetHeader>Vehicles Information</FieldSetHeader>
              <table id='id_table_job_vehicles' className="table table-bordered table-condensed table-striped">
                <thead>
                  <tr>
                    <td></td>
                    <td>VRM or VIN</td>
                    <td>Type</td>
                    <td>Make</td>
                    <td>Model</td>
                    <td>Colour</td>
                    <td>Year</td>
                    <td>Length</td>
                    <td>Width</td>
                    <td>Height</td>
                    <td>Weight</td>
                    <td>Cond.</td>
                    <td>Value</td>
                  </tr>
                </thead>
                <tbody>
                  {isWaitingData && <tr>Loading...</tr>}
                  {!isWaitingData &&
                    vehicles?.map(
                      (data: JobsVehicle, index: number) => {
                        const {
                          Vrm,
                          Vin,
                          FuelTypeId, 
                          VehicleType,
                          VehicleMake, 
                          VehicleModel,
                          VehicleColour,
                          VehicleYear,
                          VehicleLength,
                          VehicleHeight,
                          VehicleWeight,
                          VehicleWidth,
                          IsReturnVehicle,
                          VehicleValue,
                        } = data;
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{Vrm ? Vrm : Vin}</td>
                            <td>{getVehicleTypeByID(VehicleType)}</td>
                            <td>{VehicleMake}</td>
                            <td>{VehicleModel}</td>
                            <td>{VehicleColour}</td>
                            <td>{VehicleYear}</td>
                            <td>{VehicleLength}</td>
                            <td>{VehicleWidth}</td>
                            <td>{VehicleHeight}</td>
                            <td>{VehicleWeight}</td>
                            <td>{IsReturnVehicle ? "" : "new"}</td>
                            <td>£{VehicleValue}</td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  jobID: number;
  vehicles:  JobsVehicle[]
};

export default SectionVehicles;


const getVehicleTypeByID = (id: number): string | undefined => {
  const keys = Object.keys(AdminVehicleType).filter((key) => isNaN(Number(key)));
  const vehicleType = keys.find((key) => AdminVehicleType[key as keyof typeof AdminVehicleType] === id);
  return vehicleType ? vehicleType.toString() : undefined;
};