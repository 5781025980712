import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobGuaranteedPricesModel, UpdateJobGuaranteedResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobGuaranteedPrices = (
  options?: any
): UseMutationResult<
  UpdateJobGuaranteedResponseModel,
  any,
  UpdateJobGuaranteedPricesModel
> => {
  const usePutUpdateJobGuaranteedPrices = (
    payload: UpdateJobGuaranteedPricesModel
  ) => {
    return agent.jobs.putUpdateJobGuaranteedPrices(payload);
  };

  return useMutation(usePutUpdateJobGuaranteedPrices);
};
