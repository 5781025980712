import { GridRenderCellParams } from '@mui/x-data-grid';
import Dropdown from 'react-bootstrap/Dropdown';
import agent from '../../../../../Axios/Agent';
import { RouteConstants } from '../../../../../Routes/Constants';
import { JobStatus, PaymentMethod, PaymentStatus } from '../../../../../Utils/Enums';
import { useCallback } from 'react';

interface Props {
    params: GridRenderCellParams;
    onShowDialog: Function,
    showPaymentNotesModule: Function,
    showPaymentDueDateModal: Function,
    showPayNowModal: Function,
    showRefundPrePaymentModal: Function,
    showMarkPaymentAsNotPayableModal: Function,
    showAlert: Function,
    handleAlertClose: Function
}

export default function PaymentsGridActionMenu({
    params,
    onShowDialog,
    showPaymentNotesModule,
    showPaymentDueDateModal,
    showPayNowModal,
    showRefundPrePaymentModal,
    showMarkPaymentAsNotPayableModal,
    showAlert,
    handleAlertClose
}: Props) {
    const { id, row } = params;
    const {
        AdminNotes: adminNotes,
        JobStatus: jobStatus,
        MovexPaidDate: movexPaidDate,
        MovexRefundDate: movexRefundDate,
        PaymentID: paymentID,
        PaymentMethod: paymentMethod,
        PaymentStatus: paymentStatus,
        RequestingMemberInvoiceFileName: requestingMemberInvoiceFileName,
        RmInvoiceIDs: rmInvoiceIDs,
        SmInvoiceIDs: smInvoiceIDs,
        SupplyingMemberInvoiceFileName: supplyingMemberInvoiceFileName,
    } = row;

    const getInvoiceFile = useCallback(async (Id: number) => {
        try {
            const invoiceResponse = await agent.invoices.getInvoiceFile(Id)
                .catch((error: any) => {
                    showAlert({
                        show: true,
                        header: error.Subject,
                        body: error.Description,
                        closeCallback: handleAlertClose,
                    });
                })

            if (invoiceResponse instanceof Blob) {
                const url = URL.createObjectURL(invoiceResponse);
                window.open(url, '_blank');

                // release the object URL after usage
                window.addEventListener('focus', () => URL.revokeObjectURL(url));
            } else {
                if (invoiceResponse == undefined) {
                    showAlert({
                        show: true,
                        header: 'Error getting invoice.',
                        closeCallback: handleAlertClose,
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
        }
    }, [agent, handleAlertClose])

    return <Dropdown key={id}>
        <Dropdown.Toggle variant={adminNotes ? 'info' : 'secondary'} />
        <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed', }}>
            <Dropdown.Item onClick={() => window.open(`${RouteConstants.ViewJobById}/${params.id}`)}>
                View Job
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showPaymentNotesModule(paymentID, adminNotes)}>
                Add / Update Notes
            </Dropdown.Item>
            {requestingMemberInvoiceFileName &&
                <Dropdown.Item onClick={() => getInvoiceFile(rmInvoiceIDs[0].InvoiceID)}>
                    View User Invoice
                </Dropdown.Item>
            }
            {paymentMethod === PaymentMethod.PrePay && movexPaidDate && !movexRefundDate &&
                <Dropdown.Item onClick={() => showRefundPrePaymentModal(paymentID)}>
                    Refund Pre Payment
                </Dropdown.Item>
            }
            {supplyingMemberInvoiceFileName &&
                <Dropdown.Item onClick={() => getInvoiceFile(smInvoiceIDs[0].InvoiceID)}>
                    View Supplier Invoice
                </Dropdown.Item>
            }
            {(paymentStatus === PaymentStatus.Pending ||
                paymentStatus === PaymentStatus.Approved ||
                paymentStatus === PaymentStatus.NotPayable) &&
                <Dropdown.Item onClick={() => onShowDialog(paymentID)}>
                    Pause Payment
                </Dropdown.Item>
            }
            {paymentStatus === PaymentStatus.Approved &&
                <Dropdown.Item onClick={() => showPaymentDueDateModal(paymentID)}>
                    Change Due Date
                </Dropdown.Item>
            }
            {(paymentStatus === PaymentStatus.Paused || paymentStatus === PaymentStatus.Failed) &&
                <Dropdown.Item onClick={() => showPayNowModal(paymentID, row)}>
                    Pay Now
                </Dropdown.Item>
            }
            {(jobStatus === JobStatus.AdminRemoved_Or_Failed ||
                paymentStatus === PaymentStatus.Paused ||
                paymentStatus === PaymentStatus.Failed) &&
                <Dropdown.Item onClick={() => showMarkPaymentAsNotPayableModal(paymentID)}>
                    Do Not Pay
                </Dropdown.Item>
            }
        </Dropdown.Menu>
    </Dropdown>
}