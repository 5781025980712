import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useDeleteDealerMarginFeeQuotesPrice = (options?: any): UseMutationResult<any, any, number> => {
     const deleteFeeQuotePrice = (id: number) => {
          return agent.dealerMargin.deleteDealerMarginQuotePrices(id);
     };

     return useMutation(deleteFeeQuotePrice);
};
