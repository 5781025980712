import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AdminUpdateJobFieldModelResponse, UpdateJobAdminUserModel } from '../../../../../Models/Jobs/ViewJobs/AssignJobModels';
import { MovexErrorResponse } from '../../../../../Types';

export const usePutUpdateJobAdminUser = (
     options?: any
): UseMutationResult<AdminUpdateJobFieldModelResponse, any, UpdateJobAdminUserModel> => {
     const updateJobAdminUser = (payload: UpdateJobAdminUserModel) => {
          return agent.jobs.putUpdateJobAdminUser(payload);
     };

     return useMutation(updateJobAdminUser);
};