import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert, ConfirmDialog, ShowPleaseWait, useAlert } from '../../../../Components';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import useFileUploader from '../../../../Components/FileUpload/useUploadFileToTempBlob';
import { FormControlKeys } from '../../../../Components/FormControls';
import {
     ColMd6SectionInner,
     FieldSetContainer,
     FlexContainer,
     FormContainer,
     InlineBlockColMd4Section,
} from '../../../../Components/Formik';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { UploadedDocument } from '../../../../Models/Shared/Document';
import { CreateAdditionalCostsTaskRequest } from '../../../../Models/Tasks/CreateAdditionalCostTaskRequest';
import { RouteConstants } from '../../../../Routes';
import { getTaskOpenReasonEnumToDropdownOptions } from '../../../../Utils';
import { TaskOpenReason } from '../../../../Utils/Enums/TaskOpenReason';
import { useGetSummaryDetails } from './Hooks';
import { usePostAddAdditionalCostsTask } from './Hooks/usePostAddTask';
import JobDetails from './JobSummaryDetails';
import { FailIndicatorIcon } from '../../../../Assets/Images';
import { cellImageStyle } from '../../Billing/AddBill';

interface FormValues {
     TaskOpenReason: TaskOpenReason;
     SupportingInformation: string;
     AdditionalCostAmount: number | undefined;
     JobId: number | undefined;
     DueDate: string;
}

const initialValues: FormValues = {
     TaskOpenReason: TaskOpenReason.NA,
     SupportingInformation: '',
     AdditionalCostAmount: undefined,
     JobId: undefined,
     DueDate: '',
};

const today = new Date().toISOString().slice(0, 10);

const validationSchema = Yup.object({
     TaskOpenReason: Yup.mixed().required('Additional cost reason is required'),
     AdditionalCostAmount: Yup.number()
          .typeError('Please enter an amount greater than zero')
          .required('Please enter an amount greater than zero')
          .moreThan(0, 'Please enter an amount greater than zero'),
     JobId: Yup.number().required('Invalid job ID'),
     DueDate: Yup.date()
          .required('Due date cannot be before today\'s date')
          .min(today, 'Due date cannot be before today\'s date')
});

const AddTaskForm: React.FC = () => {
     const [showUploader, setShowUploader] = useState<boolean>(false);
     const history = useHistory();

     const redirectToViewTasksPage = () => {
          history.push(RouteConstants.ViewTasks);
     };

     const documentTitle = 'You can add a document';

     /** States Hooks */
     const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocument[]>([]);
     const [documentsToUploadToStorage, setDocumentsToUploadToStorage] = useState<UploadedDocument[]>([]);

     /** Functions */
     const onFileUpload = (event: Event) => {
          const target = event.target as HTMLInputElement;
          const files = target.files as FileList;
          const file = files?.[0];
          const newDocument: UploadedDocument = {
               Name: files[0].name,
               Size: files[0].size,
               IsUploaded: true,
               File: file,
          };
          setUploadedDocuments([...uploadedDocuments, newDocument]);
          setDocumentsToUploadToStorage([...documentsToUploadToStorage, newDocument]);
     };

     const handleSubmit = async () => {
          if (
               formik.values.DueDate === undefined ||
               formik.values.JobId === undefined ||
               formik.values.AdditionalCostAmount === undefined ||
               formik.errors.TaskOpenReason ||
               formik.errors.AdditionalCostAmount ||
               formik.errors.JobId ||
               formik.errors.DueDate
          ) {
               //need to call submitForm to trigger validation
               formik.submitForm();
               return;
          }

          //Upload the files to the temp storage location
          const files = uploadedDocuments?.filter((doc) => doc.File).map((doc) => doc.File);
          await uploadFiles(files as File[]);

          const payload: CreateAdditionalCostsTaskRequest = {
               OpenReason: formik.values.TaskOpenReason,
               SupportingInformation: formik.values.SupportingInformation,
               DueDate: new Date(formik.values.DueDate),
               JobId: formik.values.JobId!,
               AdditionalCostAmount: formik.values.AdditionalCostAmount!,
               Attachments: null,
          };
          payload.Attachments = uploadedDocuments?.map((file) => ({
               IsNew: true,
               Name: file.Name,
               TemporaryFile: file.Name,
               IsUploaded: true,
               JobId: formik.values.JobId!,
          }));

          PostAddAdditionalCostsTask(payload);
     };

     const handleRemove = (index: number) => {
          const newDocuments = [...uploadedDocuments];
          newDocuments.splice(index, 1);
          setUploadedDocuments(newDocuments);
     };
     /** Custom Hooks */
     const { alert, setAlert } = useAlert();
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();

     /** Queries Hooks */
     const { uploadFiles, isUploadSuccessful } = useFileUploader();
     const {
          mutate: PostAddAdditionalCostsTask,
          isLoading: isPostAddAdditionalCostsTaskLoading,
          data: postAddAdditionalCostsTaskData,
          error: postAddAdditionalCostsTaskError,
     } = usePostAddAdditionalCostsTask();

     /** Formik Hook */
     const formik = useFormik({
          initialValues: initialValues,
          validationSchema: validationSchema,
          onSubmit: handleSubmit,
     });

     const {
          data: getSummaryDetailsData,
          error: getSummaryDetailsError,
          refetch: refetchGetSummaryDetails,
     } = useGetSummaryDetails(formik.values.JobId ?? 0);

     /** Effect Hooks */
     useEffect(() => {
          if (postAddAdditionalCostsTaskData) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Task added successfully',
                    body: 'Task added successfully.',
                    closeCallback: redirectToViewTasksPage
               });
          }
     }, [postAddAdditionalCostsTaskData]);

     useEffect(() => {
          if (postAddAdditionalCostsTaskError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: postAddAdditionalCostsTaskError?.Subject,
                    body: postAddAdditionalCostsTaskError?.Description,
               });
          }
     }, [postAddAdditionalCostsTaskError]);

     useEffect(() => {
          if (getSummaryDetailsError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getSummaryDetailsError?.Subject,
                    body: getSummaryDetailsError?.Description,
               });
          }
     }, [getSummaryDetailsError]);

     return (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <ConfirmDialog {...confirmDialogProps} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         handleSubmit();
                    }}
               >
                    <FieldSetContainer>
                         <FlexContainer>
                              <InlineBlockColMd4Section>
                                   <ColMd6SectionInner>
                                        {isPostAddAdditionalCostsTaskLoading && <ShowPleaseWait show={true} />}
                                        <legend>New Additional Job Costs Task</legend>
                                        <FormikControl
                                             name={'TaskOpenReason'}
                                             control={FormControlKeys.Select}
                                             label='Additional Cost Reason'
                                             options={getTaskOpenReasonEnumToDropdownOptions()}
                                        />
                                        <FormikControl
                                             name={'SupportingInformation'}
                                             label='Supporting Information'
                                             control={FormControlKeys.Textarea}
                                             placeholder={'Enter the reason for the additional cost.'}
                                        />
                                        <FormikControl
                                             name={'AdditionalCostAmount'}
                                             label='Additional Cost Amount'
                                             control={FormControlKeys.LabelledInput}
                                             type='currency'
                                             placeholder={'Enter the additional cost amount.'}
                                        />
                                        <FormikControl
                                             name={'JobId'}
                                             label='Job ID'
                                             type='number'
                                             value={formik.values.JobId !== null ? formik.values.JobId : ''}
                                             control={FormControlKeys.LabelledInput}
                                             onBlur={() => {
                                                  refetchGetSummaryDetails();
                                             }}
                                        />

                                        {getSummaryDetailsData && formik.values.JobId && (
                                             <JobDetails jobSummaryDetails={getSummaryDetailsData!} jobId={formik.values.JobId} />
                                        )}

                                        <FormikControl name={'DueDate'} label='Due Date' type='date' min={today} />

                                        {!showUploader && (
                                             <FormikControl
                                                  name='AddAttachment'
                                                  label='Add Attachment'
                                                  control={FormControlKeys.Button}
                                                  onClick={() => {
                                                       setShowUploader(true);
                                                  }}
                                             ></FormikControl>
                                        )}

                                        {showUploader && (
                                             <FormikControl
                                                  name={'TaskAttachment'}
                                                  label='Attachment'
                                                  type='file'
                                                  title={documentTitle}
                                                  onChange={(e: any) => {
                                                       onFileUpload(e);
                                                  }}
                                             />
                                        )}

                                        <ul>
                                             {uploadedDocuments?.map((doc, index) => (
                                                  <li key={index}>
                                                       <span>{doc.Name}</span>
                                                       <a
                                                            className='remove-attachment'
                                                            href='#remove-attachment'
                                                            title='Remove Attachment'
                                                            onClick={() => handleRemove(index)}
                                                       >
                                                            {' '}
                                                            <img alt='' style={cellImageStyle} src={FailIndicatorIcon} />
                                                       </a>
                                                  </li>
                                             ))}
                                        </ul>

                                        <FormikControl name='Submit' label='Add' type='submit' control={FormControlKeys.Button} />
                                   </ColMd6SectionInner>
                              </InlineBlockColMd4Section>
                         </FlexContainer>
                    </FieldSetContainer>
               </FormContainer>
          </FormikProvider>
     );
};

export default AddTaskForm;
