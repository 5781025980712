import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UpdatePartnerRequestModel } from '../../../../../Models/Partners';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutPartner = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  UpdatePartnerRequestModel
> => {

  const updateFeedback = (payload: UpdatePartnerRequestModel) => {
    return agent.partners.putPartner(payload);
  };

  return useMutation(updateFeedback);
};