export class AddPartnerModel {
  public PartnerID = 0;
  public MemberID = 0;
  public PartnerCID: string = "";
  public Email: string = "";
  public EncryptionKey: string = "";
  public EncryptionVector: string = "";
  public Id: number = 0;
  public IsActive: boolean = false;
  public Name: string = "";
  public SupportEmail: string = "";
  public ExampleUrl: string = "";
  public ExampleForm: string = "";
}

export class AddPartnerRequestModel {
  public Email: string = "";
  public EncryptionKey: string = "";
  public EncryptionVector: string = "";
  public Name: string = "";
  public SupportEmail: string = "";
  public SuccessCallBackUrl: string = "";
  public ErrorCallBackUrl: string = "";
}

export class UpdatePartnerRequestModel {
  public Id: number = 0;
  public Name: string = "";
  public Email: string = "";
  public EncryptionKey: string = "";
  public EncryptionVector: string = "";
  public SuccessCallBackUrl: string = "";
  public ErrorCallBackUrl: string = "";
  public SupportEmail: string = "";
  public IsActive: boolean = false;
}

export const addPartnerExamplesUrL = (): string => {
  return `https://test.movex.co.uk/members/add-job/?pid=&pjid=###&pcid=###&regno=###&ccont=###&cphon=###&ccomp=###&caddr=###&ctown=###&cpost=###&dpost=###`;
};

export const addPartnerExampleForm = (): string => {
  return `<form action=""https://test.movex.co.uk/members/add-job"" method=""POST"">
    <input type=""hidden"" name=""pid"" value="" />
    <div>
        <label>Partner Job ID</label>
        <input type=""text"" name=""pjid"" value="""" />
        <span><i>This would actually be a hidden field that takes a string.</i></span>
    </div>
    <div>
        <label>Partner Customer ID</label>
        <input type=""text"" name=""pcid"" value="""" />
        <span><i>This would actually be a hidden field that takes a string.</i></span>
    </div>
    <div>
        <label>VRM / Registration Number</label>
        <input type=""text"" name=""regno"" value="""" />
    </div>
    <div>
        <label>Collection Contact</label>
        <input type=""text"" name=""ccont"" value="""" />
    </div>
    <div>
        <label>Collection Phone</label>
        <input type=""text"" name=""cphon"" value="""" />
    </div>
    <div>
        <label>Collection Company</label>
        <input type=""text"" name=""ccomp"" value="""" />
    </div>
    <div>
        <label>Collection Address</label>
        <input type=""text"" name=""caddr"" value="""" />
    </div>
    <div>
        <label>Collection Town</label>
        <input type=""text"" name=""ctown"" value="""" />
    </div>
    <div>
        <label>Collection Postcode</label>
        <input type=""text"" name=""cpost"" value="""" />
    </div>
    <div>
        <label>Delivery Postcode</label>
        <input type=""text"" name=""dpost"" value="""" />
    </div>
    <div>
        <input type=""submit"" name=""submit"" value=""Submit"" />
    </div>
</form>`;
};
