import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { FilterKeys, FreeTextFilter } from '../../../../../Types';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch } = props;
  const initialValues: FreeTextFilter = {
    FreeText: '',
  };


  const onSubmit = (values: FreeTextFilter) => {
    onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const { handleSubmit, resetForm } = formik;
  let { values } = formik;

  const handleClearSearch = () => {
    resetForm();
    values = initialValues;
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={FilterKeys.Freetext}
                    label="Freetext"
                    title="Searches by ID, UserName or Email"
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      type="reset"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;
export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
}
