import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AcceptQuoteModel, AcceptQuoteResponseModel } from '../../../../../Models/Jobs/EditJob';
import { UpdateJobAdminNoteModel } from '../../../../../Models/Jobs/EditJob/GeneralJobRequest/UpdateJobAdminNoteModel';
import { UpdateJobAdminNoteResponseModel } from '../../../../../Models/Jobs/EditJob/UpdateJobAdminNoteResponseModel';

export const usePutUpdateJobAdminNotes = (
  options?: any
): UseMutationResult<
UpdateJobAdminNoteResponseModel,
  any,
  UpdateJobAdminNoteModel
> => {
  const updateNoteAdmin = (payload: UpdateJobAdminNoteModel) => {
    return agent.jobs.putUpdateJobAdminNotes(payload);
  };

  return useMutation(updateNoteAdmin);
};
