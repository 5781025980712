import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormControlKeys } from "../../../../../../Components/FormControls";
import FormikControl from "../../../../../../Components/Formik/FormikControl";
import { JobsVehicle } from "../../../../../../Models/Jobs/EditJob";
import { SpanItem } from "../../../../../../Components/UI";

const VehiclesModule: React.FC<GeneralFormProps> = (props): JSX.Element => {
  const {func,  vehicles } = props;

  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

  /** Methods */
  const formik = useFormik({
    initialValues: {vehicles},
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  useEffect(()=> {
    func(values);
  }, [values])

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <SpanItem>Please ensure you have provided a VRM or a VIN for each vehicle.</SpanItem>
            <table id='id_table_job_vehicles_module' className="table table-bordered table-condensed table-striped">
              <thead>
                <tr>
                  <td>Make</td>
                  <td>Model</td>
                  <td>VRM</td>
                  <td>VIN</td>
                </tr>
              </thead>
              <tbody>
                {isWaitingData && <tr>Loading...</tr>}
                {!isWaitingData &&
                  vehicles?.map((data: JobsVehicle, index: number) => {
                    const { ID, Vrm, Vin, VehicleModel, VehicleMake } = data;
                    return (
                      <tr>
                        <td>{VehicleMake}</td>
                        <td>{VehicleModel}</td>
                        <td>
                          <FormikControl
                            control={FormControlKeys.LabelledInput}
                            name={`vehicles[${index}].Vrm`}
                          />
                        </td>
                        <td>
                          <FormikControl
                            control={FormControlKeys.LabelledInput}
                            name={`vehicles[${index}].Vin`}
                          />
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  vehicles: JobsVehicle[];
  func: Function
};

export default VehiclesModule;
