export interface PriceItemModel {
    Id: number,
    Type: string,
    Name: string,
    ServiceType?: number,
    IncludesFuel?: number,
    MinVehicles: number,
    MaxVehicles: number,
    MinWeight: number,
    MaxWeight: number,
    MinLeadTime: Date,
    MaxLeadTime: Date,
    MinDistance: number,
    MaxDistance: number,
    InitialDistance: number,
    InitialPrice: number,
    PricePerVehiclePerMile: number,
    MinPriceRate: number,
    MaxPriceRate: number,
    MinRate: number,
    MaxRate: number
}

export const PriceGridKeys = {
    Id: "Id",
    Type: "Type",
    Name: "Name",
    ServiceType: "ServiceType",
    IncludesFuel: "IncludesFuel",
    MinVehicles: "MinVehicles",
    MaxVehicles: "MaxVehicles",
    MinWeight: "MinWeight",
    MaxWeight: "MaxWeight",
    MinLeadTime: "MinLeadTime",
    MaxLeadTime: "MaxLeadTime",
    MinDistance: "MinDistance",
    MaxDistance: "MaxDistance",
    InitialDistance: "InitialDistance",
    InitialPrice: "InitialPrice",
    PricePerVehiclePerMile: "PricePerVehiclePerMile",
    MinPriceRate: "MinPriceRate",
    MaxPriceRate: "MaxPriceRate",
    MinRate: "MinPriceRate",
    MaxRate: "MaxPriceRate"
}