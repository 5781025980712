import { UseMutationResult, useMutation } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { ConfigObjectModel } from '../../../../../../../Models/Members/EditMember/ConfigObjects/AddConfigObjectModel';

export const usePutConfigObject = (options?: any): UseMutationResult<any, any, ConfigObjectModel> => {
  const putConfigObject = (payload: ConfigObjectModel) => {
    return agent.members.putUpdateConfigObject(payload);
  };

  return useMutation(putConfigObject);
};
