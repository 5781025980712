import CloseIcon from '@mui/icons-material/Close';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { object } from 'yup';
import { Alert, ConfirmDialog, Loading, ShowPleaseWait, useAlert } from '../../../../Components';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import { FormControlKeys } from '../../../../Components/FormControls/Types';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
     DivContainer,
     FieldSetColMd4Section,
     FieldSetColMd4SectionInner,
     FieldSetContainer,
     FieldSetHeader,
     FlexContainer,
     FormContainer,
     FormFieldSetContainer,
} from '../../../../Components/Formik/StyledComponents';
import { NavbarMenusRoutes } from "../../../../Components/Navbar/Constants";
import { AddInvoiceDetailsModel, AddInvoiceLineItemModel, AddInvoiceModel } from '../../../../Models/Billing/AddInvoice';
import { InvoiceLineItemType, InvoiceType } from '../../../../Utils/Enums';
import withAddForm from '../../Common/AddForm/withAddForm';
import { RoundToPlaces } from '../../Jobs/EditJob/Sections/CalcFunctions';
import { getInvoiceLineItemTypes } from '../Constants';
import { itemMatchesInitialData, updateFormFields } from './addInvoiceHelper';
import { useGetInvoice, usePostAddInvoice } from './Hooks';
import { FilterTypes, GetMembersByRequestFilter, useGetMembersBy } from './Hooks/useGetMembersListBy';
import './index.css';
import { AddInvoiceKeys, InvoiceIDParamType } from './Types';

const validationSchema = object({});

const AddInvoice: React.FC = () => {
     /** get params : checking if edit mode */
     const { invoiceID: paramInvoiceID } = useParams<InvoiceIDParamType>();
     const invoiceID = paramInvoiceID ? parseInt(paramInvoiceID) : 0;
     const isEditMode = invoiceID > 0;
     const location = useLocation();

     /** Initial Data */
     let dueDate = new Date();
     dueDate.setMonth(dueDate.getMonth() + 1);

     const addInvoiceFormikInitialData: AddInvoiceModel = {
          Id: null,
          Type: location.pathname.includes(NavbarMenusRoutes.AddCreditNoteRoute) ? InvoiceType.AdHocCreditNote : InvoiceType.AdHocInvoice,
          UseQuantitiesAndRates: false,
          Title: '',
          MemberID: null,
          MemberUserName: '',
          MemberCodaCustomerReference: '',
          MemberCodaSupplierReference: '',
          MemberCompany: '',
          MemberContactName: '',
          MemberAddress1: '',
          MemberAddress2: '',
          MemberAddress3: '',
          MemberTown: '',
          MemberCounty: '',
          MemberPostCode: '',
          MemberLandline: '',
          MemberVATRegistered: false,
          MemberVATNo: '',
          BillID: null,
          PaymentReference: '',
          InvoiceDate: new Date().toISOString().split('T')[0],
          InvoiceNo: '',
          DueDate: dueDate.toISOString().split('T')[0],
          DeductionDate: undefined,
          AlreadyPaid: false,
          MovexCompany: 'Movex Logistics Ltd',
          MovexAddress1: 'Crate',
          MovexAddress2: '15 Oakwood Hill Industrial Estate',
          MovexAddress3: undefined,
          MovexTown: 'Loughton',
          MovexCounty: 'Essex',
          MovexPostCode: 'IG103TZ',
          MovexWebsite: 'https://portal.movex.co.uk/',
          MovexEmail: 'admin@movex.co.uk',
          MovexLandline: '0208 551 1060',
          MovexVATNo: '140695708',
          TotalNetAmount: 0,
          TotalVatAmount: 0,
          TotalGrossAmount: 0,
     };

     const invoiceLineItemInitialData: AddInvoiceLineItemModel = {
          InvoiceNo: '',
          Type: InvoiceLineItemType.AdHocLineItem,
          VatRate: 0.2,
          Description: '',
          Quantity: 1,
          Rate: 0,
          Discount: 0,
          NetAmount: 0,
          VatAmount: 0,
          ItemNo: 0,
          GrossAmount: 0,
          JobID: 0,
     };

     const invoiceToAdd: AddInvoiceDetailsModel = {
          SendInvoiceEmailToUser: true,
          Invoice: addInvoiceFormikInitialData,
          InvoiceLineItems: [invoiceLineItemInitialData],
     };

     /** useStates */
     const [formikData, setFormikData] = useState<AddInvoiceDetailsModel>(invoiceToAdd);
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [useQuantitiesAndRates, setUseQuantitiesAndRates] = useState<boolean>(false);
     const [isCreditNote, setIsCreditNote] = useState<boolean>(location.pathname.includes(NavbarMenusRoutes.AddCreditNoteRoute));

     /** useStates - Payload for getMembersBy endpoint */
     const [getMembersByPayload, setGetMembersByPayload] = useState<GetMembersByRequestFilter>({
          filterType: FilterTypes.UserName,
          filterValue: '',
     });

     /** useStates - MembersList will contain the result of the GetMembersByEndpoint */
     const [memberList, setMemberList] = useState<MemberInformation[]>([]);

     /** useStates - show/hide the Dropdowns */
     const [showMemberUsernameDropdown, setShowMemberUsernameDropdown] = useState<boolean>(false);
     const [showCodaCustomerRefDropdown, setShowCodaCustomerRefDropdown] = useState<boolean>(false);
     const [showCompanyDropdown, setShowCompanyDropdown] = useState<boolean>(false);
     const [showContactDropdown, setShowContactDropdown] = useState<boolean>(false);

     /** Custom Hook - show alert and dialog messages*/
     const { alert, setAlert } = useAlert();
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();

     /** Query Hooks */
     const {
          isLoading: isGetInvoiceLoading,
          data: getInvoiceResponse,
          error: getInvoiceError,
     } = useGetInvoice(invoiceID);

     const {
          mutate: postAddInvoice,
          isLoading: isPostAddInvoiceLoading,
          data: postAddInvoiceResponse,
          error: postAddInvoiceError,
     } = usePostAddInvoice();

     const {
          refetch: getMembersBy,
          data: getMembersData,
     } = useGetMembersBy(getMembersByPayload, { retry: 1 });

     /** Methods */
     const handleAddNewInvoiceLineItem = () => {
          formik.setFieldValue('InvoiceLineItems', [...(formik.values.InvoiceLineItems ?? []), invoiceLineItemInitialData]);
     };

     const onCancelAddInvoiceLineItem = (id: number) => {
          if (id === 0) {
               const data = [...(formik.values.InvoiceLineItems ?? [])];
               data.pop();
               formik.setFieldValue('InvoiceLineItems', [...data]);
          } 
     };

     const onSubmit = async (payload: any) => {
          payload.InvoiceLineItems = payload.InvoiceLineItems.filter(
              (item: AddInvoiceLineItemModel) => !itemMatchesInitialData(item, invoiceLineItemInitialData)
          );
          postAddInvoice(payload);
      };

     /** Automatically recalculate when inputs are updated by the admin user. */
     const handleBlur = (e: any, index: any) => {
          const { name, value } = e.target;
          formik.handleBlur(e);

          let totalNetAmount = 0;
          let totalVatAmount = 0;
          let totalGrossAmount = 0;

          const quantity = isNaN(formik.values.InvoiceLineItems[index].Quantity)
               ? 0
               : Number(formik.values.InvoiceLineItems[index].Quantity);
          const rate = isNaN(formik.values.InvoiceLineItems[index].Rate) ? 0 : Number(formik.values.InvoiceLineItems[index].Rate);
          const discount = isNaN(formik.values.InvoiceLineItems[index].Discount)
               ? 0
               : Number(formik.values.InvoiceLineItems[index].Discount);
          const vatRate = isNaN(formik.values.InvoiceLineItems[index].VatRate ?? 0)
               ? 0
               : Number(formik.values.InvoiceLineItems[index].VatRate);
          const netAmount = RoundToPlaces(quantity * rate - discount, 2);
          const vatAmount = RoundToPlaces(netAmount * vatRate, 2);
          const grossAmount = RoundToPlaces(netAmount + vatAmount, 2);

          formik.setFieldValue(`InvoiceLineItems[${index}].NetAmount`, netAmount);
          formik.setFieldValue(`InvoiceLineItems[${index}].VatAmount`, vatAmount);
          formik.setFieldValue(`InvoiceLineItems[${index}].GrossAmount`, grossAmount);

          totalNetAmount += netAmount;
          totalVatAmount += vatAmount;
          totalGrossAmount += grossAmount;

          formik.values.InvoiceLineItems.forEach((item: AddInvoiceLineItemModel, idx: number) => {
               if (idx !== index) {
                    totalNetAmount += item.NetAmount;
                    totalVatAmount += item.VatAmount;
                    totalGrossAmount += item.GrossAmount;
               }
          });

          formik.setFieldValue(AddInvoiceKeys.TotalNetAmount, totalNetAmount);
          formik.setFieldValue(AddInvoiceKeys.TotalGrossAmount, totalGrossAmount);
          formik.setFieldValue(AddInvoiceKeys.TotalVatAmount, totalVatAmount);
     };

     const handleMemberUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value;
          if (inputValue.trim() === '') {
               setShowMemberUsernameDropdown(false);
             } else {
               setShowMemberUsernameDropdown(true);
             }
          setShowCodaCustomerRefDropdown(false);
          setShowCompanyDropdown(false);
          setShowContactDropdown(false);
          setGetMembersByPayload({
               filterType: FilterTypes.UserName,
               filterValue: inputValue,
          });
          getMembersBy();
          formik.handleChange(event);
     };

     const handleCodaCustomerRefChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value;
          setShowMemberUsernameDropdown(false);
          if (inputValue.trim() === '') {
               setShowCodaCustomerRefDropdown(false);
             } else {
               setShowCodaCustomerRefDropdown(true);
             }
          setShowCompanyDropdown(false);
          setShowContactDropdown(false);
          setGetMembersByPayload({
               filterType: FilterTypes.CodaCustomerReference,
               filterValue: inputValue,
          });
          getMembersBy();
          formik.handleChange(event);
     };

     const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value;
          setShowMemberUsernameDropdown(false);
          setShowCodaCustomerRefDropdown(false);
          if (inputValue.trim() === '') {
               setShowCompanyDropdown(false);
             } else {
               setShowCompanyDropdown(true);
             }
          setShowContactDropdown(false);
          setGetMembersByPayload({
               filterType: FilterTypes.Company,
               filterValue: inputValue,
          });
          getMembersBy();
          formik.handleChange(event);
     };

     const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value;
          setShowMemberUsernameDropdown(false);
          setShowCodaCustomerRefDropdown(false);
          setShowCompanyDropdown(false);
          if (inputValue.trim() === '') {
               setShowContactDropdown(false);
             } else {
               setShowContactDropdown(true);
             }
          setGetMembersByPayload({
               filterType: FilterTypes.Contact,
               filterValue: inputValue,
          });
          getMembersBy();
          formik.handleChange(event);
     };

     const handleMemberSelect = (member: MemberInformation) => {
          setMemberList([]);
          setShowMemberUsernameDropdown(false);
          updateFormFields(member, formik);
     };

     const handleCodaCustomerRefSelect = (member: MemberInformation) => {
          setShowCodaCustomerRefDropdown(false);
          setMemberList([]);
          updateFormFields(member, formik);
     };

     const handleCompanySelect = (member: MemberInformation) => {
          setShowCompanyDropdown(false);
          setMemberList([]);
          updateFormFields(member, formik);
     };

     const handleContactSelect = (member: MemberInformation) => {
          setShowContactDropdown(false);
          setMemberList([]);
          updateFormFields(member, formik);
     };

     let showJobCostFields = (index: number) => {
          return formik.values.InvoiceLineItems[index].Type == InvoiceLineItemType.rm_JobLineItem
               || formik.values.InvoiceLineItems[index].Type == InvoiceLineItemType.sm_JobLineItem;
     }

     /** Formik */
     const formik = useFormik({
          initialValues: formikData,
          validationSchema: validationSchema,
          onSubmit: onSubmit,
          enableReinitialize: true,
     });

     const { handleSubmit, values, resetForm } = formik;

     /** useEffects */

     /** handle EditMode (fill-in the form automatically) */
     useEffect(() => {
          if (getInvoiceResponse && isEditMode) {
               const { Type } = getInvoiceResponse;
               setIsCreditNote(Type === InvoiceType.AdHocCreditNote);
               if (getInvoiceResponse?.InvoiceLineItems) {
                    const formikNestedArrayValues = getInvoiceResponse.InvoiceLineItems?.map((item: AddInvoiceLineItemModel) => {
                         return item;
                    });
                    setFormikData({
                         ...getInvoiceResponse,
                         InvoiceLineItems: formikNestedArrayValues,
                    });
               } else {
                    setFormikData({ ...getInvoiceResponse });
               }
          }
          /** when Editing an invoice, get the data from getInvoiceResponse */
          if (getInvoiceResponse) {
               const invoiceData = getInvoiceResponse;
               formik.setValues({
                    ...formik.values,
                    Invoice: {
                         ...formik.values.Invoice,
                         Id: invoiceData?.ID ?? formik.values.Invoice.Id,
                         Type: invoiceData?.Type ?? formik.values.Invoice.Type,
                         MemberUserName: invoiceData?.MemberContactName ?? formik.values.Invoice.MemberUserName,
                         MemberCompany: invoiceData?.MemberCompany ?? formik.values.Invoice.MemberCompany,
                         MemberContactName: invoiceData?.MemberContactName ?? formik.values.Invoice.MemberContactName,
                         MemberAddress1: invoiceData?.MemberAddress1 ?? formik.values.Invoice.MemberAddress1,
                         MemberAddress2: invoiceData?.MemberAddress2 ?? formik.values.Invoice.MemberAddress2,
                         MemberAddress3: invoiceData?.MemberAddress3 ?? formik.values.Invoice.MemberAddress3,
                         MemberTown: invoiceData?.MemberTown ?? formik.values.Invoice.MemberTown,
                         MemberCounty: invoiceData?.MemberCounty ?? formik.values.Invoice.MemberCounty,
                         MemberPostCode: invoiceData?.MemberPostCode ?? formik.values.Invoice.MemberPostCode,
                         MemberLandline: invoiceData?.MemberLandline ?? formik.values.Invoice.MemberLandline,
                         MemberVATRegistered: invoiceData?.MemberVATRegistered ?? formik.values.Invoice.MemberVATRegistered,
                         MemberVATNo: invoiceData?.MemberVATNo ?? formik.values.Invoice.MemberVATNo,
                         BillID: invoiceData?.BillID ?? formik.values.Invoice.BillID,
                         PaymentReference: invoiceData?.PaymentReference ?? formik.values.Invoice.PaymentReference,
                         InvoiceDate: invoiceData?.InvoiceDate ?? formik.values.Invoice.InvoiceDate,
                         InvoiceNo: invoiceData?.InvoiceNo ?? formik.values.Invoice.InvoiceNo,
                         DueDate: invoiceData?.DueDate ?? formik.values.Invoice.DueDate,
                         DeductionDate: invoiceData?.DeductionDate ?? formik.values.Invoice.DeductionDate,
                         AlreadyPaid: invoiceData?.AlreadyPaid ?? formik.values.Invoice.AlreadyPaid,
                         MovexCompany: invoiceData?.MovexCompany ?? formik.values.Invoice.MovexCompany,
                         MovexAddress1: invoiceData?.MovexAddress1 ?? formik.values.Invoice.MovexAddress1,
                         MovexAddress2: invoiceData?.MovexAddress2 ?? formik.values.Invoice.MovexAddress2,
                         MovexAddress3: invoiceData?.MovexAddress3 ?? formik.values.Invoice.MovexAddress3,
                         MovexTown: invoiceData?.MovexTown ?? formik.values.Invoice.MovexTown,
                         MovexCounty: invoiceData?.MovexCounty ?? formik.values.Invoice.MovexCounty,
                         MovexPostCode: invoiceData?.MovexPostCode ?? formik.values.Invoice.MovexPostCode,
                         MovexWebsite: invoiceData?.MovexWebsite ?? formik.values.Invoice.MovexWebsite,
                         MovexEmail: invoiceData?.MovexEmail ?? formik.values.Invoice.MovexEmail,
                         MovexLandline: invoiceData?.MovexLandline ?? formik.values.Invoice.MovexLandline,
                         MovexVATNo: invoiceData?.MovexVATNo ?? formik.values.Invoice.MovexVATNo,
                         TotalNetAmount: invoiceData?.TotalNetAmount ?? formik.values.Invoice.TotalNetAmount,
                         TotalVatAmount: invoiceData?.TotalVatAmount ?? formik.values.Invoice.TotalVatAmount,
                         TotalGrossAmount: invoiceData?.TotalGrossAmount ?? formik.values.Invoice.TotalGrossAmount,
                    },
                    InvoiceLineItems: invoiceData.LineItems
                         ? invoiceData.LineItems?.map((item: AddInvoiceLineItemModel, index: number) => ({
                                InvoiceNo: item?.InvoiceNo ?? formik.values.InvoiceLineItems[index]?.InvoiceNo,
                                Type: item?.Type ?? formik.values.InvoiceLineItems[index]?.Type,
                                VatRate: item?.VatRate ?? formik.values.InvoiceLineItems[index]?.VatRate,
                                Description: item?.Description ?? formik.values.InvoiceLineItems[index]?.Description,
                                Quantity: item?.Quantity ?? formik.values.InvoiceLineItems[index]?.Quantity,
                                Rate: item?.Rate ?? formik.values.InvoiceLineItems[index]?.Rate,
                                Discount: item?.Discount ?? formik.values.InvoiceLineItems[index]?.Discount,
                                NetAmount: item?.NetAmount ?? formik.values.InvoiceLineItems[index]?.NetAmount,
                                VatAmount: item?.VatAmount ?? formik.values.InvoiceLineItems[index]?.VatAmount,
                                ItemNo: item?.ItemNo ?? formik.values.InvoiceLineItems[index]?.ItemNo,
                                GrossAmount: item?.GrossAmount ?? formik.values.InvoiceLineItems[index]?.GrossAmount,
                                JobID: item?.JobID ?? formik.values.InvoiceLineItems[index]?.JobID,
                           }))
                         : [],
               });
          }
          if (!isEditMode) {
               setFormikData(invoiceToAdd);
          }
     }, [getInvoiceResponse, isEditMode]);

     useEffect(() => {
          if (postAddInvoiceResponse) {
               const { Id } = postAddInvoiceResponse;
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Success',
                    body: `Invoice ${Id} has been added successfully.`,
               });
               resetForm();
          }
     }, [postAddInvoiceResponse]);

     useEffect(() => {
          if (postAddInvoiceError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: `Save Invoice Error: ${postAddInvoiceError?.Subject}`,
                    body: `${postAddInvoiceError?.Description}`,
               });
          }
     }, [postAddInvoiceError]);

     useEffect(() => {
          if (getInvoiceError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getInvoiceError?.Subject,
                    body: getInvoiceError?.Description,
               });
          }
     }, [getInvoiceError]);

     useEffect(() => {
          if (getMembersData?.Members) {
               setMemberList(getMembersData?.Members);
          } else {
               setMemberList([]);
          }
     }, [getMembersData]);

     useEffect(() => {
          setIsCreditNote(location.pathname.includes(NavbarMenusRoutes.AddCreditNoteRoute));
          resetForm();
          formik.setFieldValue('Invoice.Type', location.pathname.includes(NavbarMenusRoutes.AddCreditNoteRoute) ? InvoiceType.AdHocCreditNote : InvoiceType.AdHocInvoice);
     }, [location]);

     /** End of Additional functions */
     const renderInvoicesLineItemsTable = () => {
          const tableData = formik.values?.InvoiceLineItems;
          if (tableData === undefined) return null;
          return (
               <table id='id_table_invoice_line_items' className='view-invoices-line-items table' style={{ maxWidth: '1000px' }}>
                    <thead>
                         <tr>
                              <td style={{ width: '10px' }}>
                                   <button
                                        className='btn btn-mini btn-primary'
                                        onClick={(e: any) => {
                                             e.preventDefault();
                                             handleAddNewInvoiceLineItem();
                                        }}
                                   >
                                        +
                                   </button>
                              </td>
                              <td>Type &amp; Description</td>
                              
                              {!useQuantitiesAndRates && (
                                   <td>Amount</td>
                              )}

                              {useQuantitiesAndRates && (
                                   <>
                                        <td>Quantity</td>
                                        <td>Rate</td>
                                   </>
                              )}
                              <td>Discount</td>
                              <td>Net</td>
                              <td>Vat</td>
                              <td>Gross</td>
                              <td>Vat Rate</td>
                              <td>&nbsp;</td>
                         </tr>
                    </thead>
                    <tbody>
                         {Object.entries(tableData)?.map(([key, data]: [string, AddInvoiceLineItemModel], index: number) => {
                              const { Id, Type } = data;
                              const rowID = index + 1;

                              return (
                                   <>
                                        <tr key={Id + '_' + index} className={isEditMode ? 'edit-row' : 'display-row'}>
                                             <td style={{ maxWidth: '10px' }}>{rowID}</td>
                                             <td style={{ width: '200px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].Type`}
                                                       control={FormControlKeys.Select}
                                                       options={getInvoiceLineItemTypes(!isEditMode)}
                                                  />
                                                  {showJobCostFields(index) && (
                                                       <div>
                                                            <div className='job-cost-field'></div>
                                                            <div className='job-cost-field'>How much is surcharge?</div>
                                                            <div className='job-cost-field'>How much is PAYG?</div>
                                                       </div>
                                                  )}
                                             </td>
                                             
                                             {useQuantitiesAndRates && (
                                                  <td style={{ width: '80px' }}>
                                                       <FormikControl
                                                            name={`InvoiceLineItems[${index}].Quantity`}
                                                            type='number'
                                                            onBlur={(e: any) => handleBlur(e, index)}
                                                       />
                                                  </td>
                                             )}

                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].Rate`}
                                                       control={FormControlKeys.InputGroup}
                                                       type='currency'
                                                       text='£'
                                                       onBlur={(e: any) => handleBlur(e, index)}
                                                  />

                                                  {showJobCostFields(index) && (
                                                       <div>
                                                            <div>
                                                                 <FormikControl
                                                                      name={`InvoiceLineItems[${index}].JobId`}
                                                                      placeholder='JobID'
                                                                 />
                                                            </div>
                                                            <div>
                                                                 <FormikControl
                                                                      name={`InvoiceLineItems[${index}].RequestingMemberFee`}
                                                                      type='currency'
                                                                      text='£'
                                                                      control={FormControlKeys.InputGroup}
                                                                 />
                                                            </div>
                                                            <div>
                                                                 <FormikControl
                                                                      name={`InvoiceLineItems[${index}].RequestingMemberPayAsYouGoFee`}
                                                                      type='currency'
                                                                      text='£'
                                                                      control={FormControlKeys.InputGroup}
                                                                 />
                                                            </div>
                                                       </div>
                                                  )}
                                             </td>

                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].Discount`}
                                                       control={FormControlKeys.InputGroup}
                                                       type='currency'
                                                       text='£'
                                                       onBlur={(e: any) => handleBlur(e, index)}
                                                  />
                                             </td>

                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].NetAmount`}
                                                       control={FormControlKeys.InputGroup}
                                                       readOnly={true}
                                                       type='currency'
                                                       text='£'
                                                       disabled={true}
                                                  />
                                             </td>

                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].VatAmount`}
                                                       readOnly={true}
                                                       type='currency'
                                                       control={FormControlKeys.InputGroup}
                                                       text='£'
                                                       disabled={true}
                                                  />
                                             </td>
                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].GrossAmount`}
                                                       readOnly={true}
                                                       type='currency'
                                                       text='£'
                                                       control={FormControlKeys.InputGroup}
                                                       disabled={true}
                                                  />
                                             </td>
                                             <td style={{ width: '110px' }}>
                                                  <FormikControl
                                                       name={`InvoiceLineItems[${index}].VatRate`}
                                                       type='currency'
                                                       text='£'
                                                       control={FormControlKeys.InputGroup}
                                                       onBlur={(e: any) => handleBlur(e, index)}
                                                  />
                                             </td>
                                             <td>
                                                  <span title='Cancel'>
                                                       <CloseIcon
                                                            style={{ color: 'red' }}
                                                            className='cursor-pointer'
                                                            onClick={(e: any) => {
                                                                 e.preventDefault();
                                                                 onCancelAddInvoiceLineItem(data.Id || 0);
                                                            }}
                                                       ></CloseIcon>
                                                  </span>
                                             </td>

                                        </tr>

                                        {Type === InvoiceLineItemType.rm_JobLineItem && (
                                             <tr className='form-inline'>
                                                  <td colSpan={4} style={{ borderTop: 'none' }}>
                                                       <div>
                                                            <div
                                                                 style={{
                                                                      display: 'flex',
                                                                      justifyContent: 'flex-end',
                                                                      alignItems: 'center',
                                                                 }}
                                                            >
                                                                 <div
                                                                      style={{
                                                                           textAlign: 'right',
                                                                           paddingRight: '10px',
                                                                      }}
                                                                 >
                                                                      How Much Is Surcharge?
                                                                 </div>
                                                                 <FormikControl
                                                                      name={`InvoiceLineItems[${index}].RequestingMemberFee`}
                                                                      type='currency'
                                                                      text='£'
                                                                      control={FormControlKeys.InputGroup}
                                                                      style={{ width: '70px' }}
                                                                 />
                                                            </div>
                                                            <div
                                                                 style={{
                                                                      display: 'flex',
                                                                      justifyContent: 'flex-end',
                                                                      alignItems: 'center',
                                                                 }}
                                                            >
                                                                 <div
                                                                      style={{
                                                                           textAlign: 'right',
                                                                           paddingRight: '10px',
                                                                      }}
                                                                 >
                                                                      How Much Is PAYG?
                                                                 </div>
                                                                 <FormikControl
                                                                      name={`InvoiceLineItems[${index}].RequestingMemberPayAsYouGoFee`}
                                                                      type='currency'
                                                                      text='£'
                                                                      control={FormControlKeys.InputGroup}
                                                                      style={{ width: '70px' }}
                                                                 />
                                                            </div>
                                                       </div>
                                                  </td>
                                             </tr>
                                        )}
                                        <tr className='form-inline'>
                                             <td colSpan={useQuantitiesAndRates ? 10 : 9}>
                                                  <FormikControl
                                                       control={FormControlKeys.Textarea}
                                                       label={'Description'}
                                                       name={`InvoiceLineItems[${index}].Description`}
                                                  />
                                             </td>
                                        </tr>
                                   </>
                              );
                         })}
                    </tbody>
                    <tfoot>
                         <tr>
                              <td>
                                   <button
                                        className='btn btn-mini btn-primary'
                                        onClick={(e: any) => {
                                             e.preventDefault();
                                             handleAddNewInvoiceLineItem();
                                        }}
                                   >
                                        +
                                   </button>
                              </td>
                              <td className='ta-r' colSpan={2}>
                                   Total
                              </td>
                              <td colSpan={2}>
                                   <FormikControl
                                        style={{ width: '100px' }}
                                        name={AddInvoiceKeys.TotalNetAmount}
                                        readOnly={true}
                                        type='currency'
                                        text='£'
                                        control={FormControlKeys.InputGroup}
                                        disabled={true}
                                   />
                              </td>
                              <td colSpan={2}>
                                   <FormikControl
                                        name={AddInvoiceKeys.TotalVatAmount}
                                        readOnly={true}
                                        type='currency'
                                        text='£'
                                        control={FormControlKeys.InputGroup}
                                        style={{ width: '100px' }}
                                        disabled={true}
                                   />
                              </td>
                              <td colSpan={2}>
                                   <FormikControl
                                        name={AddInvoiceKeys.TotalGrossAmount}
                                        readOnly={true}
                                        type='currency'
                                        text='£'
                                        control={FormControlKeys.InputGroup}
                                        style={{ width: '100px' }}
                                        disabled={true}
                                   />
                              </td>
                         </tr>
                    </tfoot>
               </table>
          );
     };

     /** useEffects */
     useEffect(() => {
          setShowPleaseWait(isPostAddInvoiceLoading);
     }, [isPostAddInvoiceLoading]);

     const dropdownContainerRef = useRef<HTMLDivElement | null>(null);

     const handleClickOutside = (event: MouseEvent) => {
          if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target as Node)) {
               setShowMemberUsernameDropdown(false);
               setShowCodaCustomerRefDropdown(false);
               setShowCompanyDropdown(false);
               setShowContactDropdown(false);
          }
     };

     useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
               document.removeEventListener('mousedown', handleClickOutside);
          };
     }, []);
     const component = (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <ShowPleaseWait show={showPleaseWait} />
               <Loading loading={isGetInvoiceLoading} />
               <ConfirmDialog {...confirmDialogProps} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         handleSubmit(e);
                    }}
               >
                    <FormFieldSetContainer>
                         <FieldSetContainer>
                              <FlexContainer>
                                   <FieldSetColMd4Section>
                                        <FieldSetColMd4SectionInner>
                                             <FieldSetHeader>Member Information</FieldSetHeader>

                                             <FormikControl
                                                  name={AddInvoiceKeys.MemberUserName}
                                                  label='Member'
                                                  placeholder='Search by username'
                                                  onChange={handleMemberUserNameChange}
                                             />
                                             {showMemberUsernameDropdown && (memberList.length !== 0) &&(
                                                  <div ref={dropdownContainerRef} className='member-dropdown-container'>
                                                       <ul className='member-dropdown'>
                                                            {memberList?.map((member, index) => (
                                                                 <li
                                                                      key={member.UserName + index}
                                                                      onClick={() => handleMemberSelect(member)}
                                                                 >
                                                                      {member.UserName}
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </div>
                                             )}

                                             <FormikControl
                                                  label='Coda Customer Ref.'
                                                  name={AddInvoiceKeys.MemberCodaCustomerReference}
                                                  placeholder='Search by CODA Customer Ref.'
                                                  onChange={handleCodaCustomerRefChange}
                                             />

                                             {showCodaCustomerRefDropdown && (memberList.length !== 0) && (
                                                  <div ref={dropdownContainerRef} className='member-dropdown-container'>
                                                       <ul className='member-dropdown'>
                                                            {memberList?.map((member, index) => (
                                                                 <li
                                                                      key={member.CodaCustomerReference + index}
                                                                      onClick={() => handleCodaCustomerRefSelect(member)}
                                                                 >
                                                                      {member.CodaCustomerReference}
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </div>
                                             )}

                                             <FormikControl
                                                  label='Company'
                                                  name={AddInvoiceKeys.MemberCompany}
                                                  placeholder='Search by company'
                                                  onChange={handleCompanyChange}
                                             />

                                             {showCompanyDropdown && (memberList.length !== 0) && (
                                                  <div ref={dropdownContainerRef} className='member-dropdown-container'>
                                                       <ul className='member-dropdown'>
                                                            {memberList?.map((member, index) => (
                                                                 <li
                                                                      key={member.Company + index}
                                                                      onClick={() => handleCompanySelect(member)}
                                                                 >
                                                                      {member.Company}
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </div>
                                             )}
                                             <FormikControl
                                                  label='Contact'
                                                  name={AddInvoiceKeys.MemberContactName}
                                                  placeholder='Search by contact'
                                                  onChange={handleContactChange}
                                             />

                                             {showContactDropdown && (memberList.length !== 0) && (
                                                  <div ref={dropdownContainerRef} className='member-dropdown-container'>
                                                       <ul className='member-dropdown'>
                                                            {memberList?.map((member, index) => (
                                                                 <li
                                                                      key={member.ContactName1 + index}
                                                                      onClick={() => handleContactSelect(member)}
                                                                 >
                                                                      {member.ContactName1}
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </div>
                                             )}

                                             <DivContainer>
                                                  <label className='form-label fw-600'>Address</label>
                                                  <FormikControl name={AddInvoiceKeys.MemberAddress1} placeholder='Address 1' />
                                                  <FormikControl name={AddInvoiceKeys.MemberAddress2} placeholder='Address 2' />
                                                  <FormikControl name={AddInvoiceKeys.MemberAddress3} placeholder='Address 3' />
                                                  <FormikControl name={AddInvoiceKeys.MemberTown} placeholder='Town' />
                                                  <FormikControl name={AddInvoiceKeys.MemberCounty} placeholder='County' />
                                                  <FormikControl name={AddInvoiceKeys.MemberPostCode} placeholder='PostCode' />
                                             </DivContainer>
                                             <FormikControl name={AddInvoiceKeys.MemberLandline} label='Landline' />
                                             <FormikControl
                                                  name={AddInvoiceKeys.MemberVATRegistered}
                                                  label='VAT Registered?'
                                                  control={FormControlKeys.Checkbox}
                                             />
                                             <FormikControl name={AddInvoiceKeys.MemberVATNo} label='VAT No' />
                                             <FormikControl
                                                  name={AddInvoiceKeys.SendInvoiceEmailToUser}
                                                  label='Send Email to Member?'
                                                  control={FormControlKeys.Checkbox}
                                             />
                                        </FieldSetColMd4SectionInner>
                                   </FieldSetColMd4Section>
                                   <FieldSetColMd4Section>
                                        <FieldSetColMd4SectionInner>
                                             <FieldSetHeader>Header Information</FieldSetHeader>
                                             <FormikControl name={AddInvoiceKeys.PaymentReference} label='Payment Reference' />
                                             <FormikControl name={AddInvoiceKeys.InvoiceDate} label='Invoice Date' type='date' />
                                             <FormikControl name={AddInvoiceKeys.InvoiceNo} label='Invoice No' />
                                             <FormikControl
                                                  name={AddInvoiceKeys.AlreadyPaid}
                                                  label='Already Paid?'
                                                  control={FormControlKeys.Checkbox}
                                             />
                                             <FormikControl name={AddInvoiceKeys.DueDate} label='Due Date' type='date' />
                                        </FieldSetColMd4SectionInner>
                                   </FieldSetColMd4Section>
                                   <FieldSetColMd4Section>
                                        <FieldSetColMd4SectionInner>
                                             <FieldSetHeader>Movex Information</FieldSetHeader>
                                             <FormikControl name={AddInvoiceKeys.MovexCompany} label='Company' />
                                             <DivContainer>
                                                  <label className='form-label fw-600'>Address</label>
                                                  <FormikControl name={AddInvoiceKeys.MovexAddress1} placeholder='Address 1' />
                                                  <FormikControl name={AddInvoiceKeys.MovexAddress2} placeholder='Address 2' />
                                                  <FormikControl name={AddInvoiceKeys.MovexAddress3} placeholder='Address 3' />
                                                  <FormikControl name={AddInvoiceKeys.MovexCounty} placeholder='County' />
                                                  <FormikControl name={AddInvoiceKeys.MovexPostCode} placeholder='PostCode' />
                                             </DivContainer>
                                             <FormikControl name={AddInvoiceKeys.MovexWebsite} label='Website' />
                                             <FormikControl name={AddInvoiceKeys.MovexEmail} label='Email' />
                                             <FormikControl name={AddInvoiceKeys.MovexLandline} label='Landline' />
                                             <FormikControl name={AddInvoiceKeys.MovexVATNo} label='VAT No' />
                                        </FieldSetColMd4SectionInner>
                                   </FieldSetColMd4Section>
                              </FlexContainer>
                         </FieldSetContainer>
                         <FieldSetContainer>
                              <FieldSetHeader>Line Items</FieldSetHeader>
                              <FormikControl
                                   name={AddInvoiceKeys.UseQuantitiesAndRates}
                                   label='Use quantities and rates?'
                                   control={FormControlKeys.Checkbox}
                                   onClick={(e: any) => {
                                        setUseQuantitiesAndRates(e.target.checked);
                                   }}
                              />
                              <DivContainer>{formik.values.InvoiceLineItems && renderInvoicesLineItemsTable()}</DivContainer>
                              {!isEditMode && (
                                   <>
                                        <FormikControl
                                             name='AddInvoiceButton'
                                             label={ `Add ${isCreditNote ? 'Credit Note' : 'Invoice'}` }
                                             control={FormControlKeys.Button}
                                             type='submit'
                                        />
                                   </>
                              )}
                         </FieldSetContainer>
                    </FormFieldSetContainer>
               </FormContainer>
          </FormikProvider>
     );

     const pageType = isEditMode ? 'View' : 'Add';
     return withAddForm(component, isCreditNote ? `${pageType} Credit Note` : `${pageType} Invoice`);
};

export interface MemberInformation {
     Id: number;
     UserName: string;
     CodaCustomerReference: string;
     CodaSupplierReference: string;
     Company: string;
     ContactName1: string;
     Address1: string;
     Address2: string;
     Address3: string;
     Town: string;
     County: string;
     PostCode: string;
     Landline: string;
     Vatregistered: boolean;
     Vatno: string;
}

export default AddInvoice;
