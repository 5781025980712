import { GridColDef,GridSortItem,GridSortModel } from "@mui/x-data-grid";
import _ from "lodash";
import {
DeliveryPoint,
StreetAddressResponse
} from "../Models/GoogleAPI/DistanceMatrix";
import { DropdownAdminUsers } from "../Models/Jobs/EditJob";
import { DropdownMember } from "../Models/Members/EditMember";
import {
EntityCreatedOrUpdatedViewModel,
ListResponse,
Sorter,
TypeaheadOption
} from "../Types";
import { OptionType } from "./../Components/FormControls/Types";
import {
ApiHost,
documentDownloadURL,
EnvironmentAPIURL,
EnvironmentImageDownloadURL
} from "./Constants";
import { EnvironmentEnum } from "./Enums";
import { TaskOpenReason } from "./Enums/TaskOpenReason";
import { TaskStatus } from "./Enums/TaskStatus";
import { TaskType } from "./Enums/TaskType";
import { StatusTypeOption } from "../Screens/Admin/Tasks/ViewTasks/Constants";
import { TaskDataFieldValue } from "../Models/Tasks/TaskDataFieldValues";

export const getAPIHostURL = (selectedEnvironment: string): string => {
  var apiURL = ApiHost;
  if (selectedEnvironment !== undefined && selectedEnvironment !== "") {
    var env = parseInt(selectedEnvironment);
    if (env === EnvironmentEnum.DEV) {
      apiURL = EnvironmentAPIURL.DEV;
    } else if (env === EnvironmentEnum.TEST) {
      apiURL = EnvironmentAPIURL.TEST;
    } else if (env === EnvironmentEnum.STAGE) {
      apiURL = EnvironmentAPIURL.STAGE;
    } else if (env === EnvironmentEnum.PROD) {
      apiURL = EnvironmentAPIURL.PROD;
    }
  }
  return apiURL;
};

export const getImageDownloadURL = (selectedEnvironment: string): string => {
  var apiURL = documentDownloadURL;
  if (selectedEnvironment !== undefined && selectedEnvironment !== "") {
    var env = parseInt(selectedEnvironment);
    if (env === EnvironmentEnum.DEV) {
      apiURL = EnvironmentImageDownloadURL.DEV;
    } else if (env === EnvironmentEnum.TEST) {
      apiURL = EnvironmentImageDownloadURL.TEST;
    } else if (env === EnvironmentEnum.STAGE) {
      apiURL = EnvironmentImageDownloadURL.STAGE;
    } else if (env === EnvironmentEnum.PROD) {
      apiURL = EnvironmentImageDownloadURL.PROD;
    }
  }
  return apiURL;
};

export const emailValidator = (email: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const passwordValidator = (password: string): boolean => {
  const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return regex.test(password);
};

export const convertEnumToSelectListWithIntegerValue = (
  enumList: any,
  withEmptyFirst: boolean = false
): OptionType[] => {
  let selectList: OptionType[] = [];

  Object.entries(enumList).forEach((item) => {
    if (typeof item[1] === "string") {
      let formattedLabel = item[1]
        .toString()
        // Replace underscores with spaces
        .replace(/_+/g, " ")
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function(str){ return str.toUpperCase(); })
        // Remove any white space left around the word
        .trim();

      selectList.push({ value: item[0], label: formattedLabel });
    }
  });

  return selectList;
};

export const roundToPlaces = (value: number, places: number): number => {
  var divisor = Math.pow(10.0, Number(places));
  return Math.round(value * divisor) / divisor;
};

export const scrollToFirstError = (errors: any) => {
  for (const [name, errorMessage] of Object.entries(errors)) {
    if (errorMessage) {
      const input = document.querySelector(`input[name=${name}]`);
      if (input) {
        input.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
        input.setAttribute("autoFocus", "");
      }
    }
    return;
  }
};

export const getYesNoOrBothRadioValue = (value?: number | boolean): any => {
  if (value == 1) {
    return true;
  } else if (value == 0) {
    return false;
  }
  return undefined;
};

export const getSorterFromSortModel = (sortModel: GridSortModel): Sorter => {
  let sorter: Sorter = { Column: "", Ascending: true };
  if (sortModel.length) {
    sorter = {
      Column: sortModel[0].field,
      Ascending: sortModel[0].sort === "asc",
    };
  }
  return sorter;
};

export const getSortModelFromSorter = (sorter: Sorter): GridSortModel => {
  const sortModel: GridSortItem[] = [];
  sortModel.push({
    field: sorter.Column,
    sort: sorter.Ascending ? "asc" : "desc",
  });
  return sortModel;
};

const INDENTATION = '-';

export interface ResultType {
     Id: number;
     ParentId: number | null;
     Username: string;
}

export const mapResultsToOptionTreeTypeList = (
     results: ResultType[],
     mappingKeys: { labelKeyString: keyof ResultType; valueKeyString: keyof ResultType }
): { value: string; label: string }[] => {
     // Step 1: Sort by Username
     const sortedResults = [...results].sort((a, b) => a.Username.localeCompare(b.Username));

     // Step 2: Recursive function to build tree
     const buildTree = (parentId: number | null, level: number = 0): { value: string; label: string }[] => {
          let items: { value: string; label: string }[] = [];

          // Using a standard for loop for easier control over the array iteration
          for (let i = 0; i < sortedResults.length; i++) {
               const result = sortedResults[i];

               if (result.ParentId === parentId) {
                    const indentation = INDENTATION.repeat(level);

                    items.push({
                         value: result[mappingKeys.valueKeyString]?.toString() ?? '',
                         label: indentation + result[mappingKeys.labelKeyString],
                    });

                    // Remove the current item from sortedResults since it's processed
                    sortedResults.splice(i, 1);
                    i--; // Adjust the index after removal

                    // Recursively find and append children
                    const children = buildTree(result.Id, level + 1);
                    items = items.concat(children);
               }
          }
          return items;
     };

     return buildTree(null);
};


export const mapResultsToOptionTypeList = (
  results: any[],
  optionModel: OptionModel
): OptionType[] => {
  let selectList: OptionType[] = [];
  for (const item of results) {
    const subSelectList: OptionType[] = [];
    const { valueKeyString, labelKeyString } = optionModel;
    selectList.push({
      value: item[valueKeyString],
      label: item[labelKeyString],
      children: subSelectList,
    });
  }

  return selectList;
};

export type OptionModel = {
  valueKeyString: string;
  labelKeyString: string;
};

export const compareLookupAddress = (a: any, b: any) => {
  let A = a?.sub_building_name?.split(" ");
  let B = b?.sub_building_name?.split(" ");
  let comparison = 0;
  if (A?.length && B?.length) {
    let addressNumberA = parseInt(A[A.length - 1]);
    let addressNumberB = parseInt(B[B.length - 1]);

    if (addressNumberA > addressNumberB) {
      comparison = 1;
    } else if (addressNumberA < addressNumberB) {
      comparison = -1;
    }
  }
  return comparison;
};

export const compareLookupAddressBuildingNumber = (a: any, b: any) => {
  let addressNumberA = parseInt(a.building_number);
  let addressNumberB = parseInt(b.building_number);

  let comparison = 0;
  if (addressNumberA > addressNumberB) {
    comparison = 1;
  } else if (addressNumberA < addressNumberB) {
    comparison = -1;
  }
  return comparison;
};
export const getPostCodeLookupAddress = (
  res: StreetAddressResponse
): OptionType[] => {
  res &&
    res.Thoroughfares[0] &&
    res.Thoroughfares[0].Delivery_points.sort(
      compareLookupAddressBuildingNumber
    ) &&
    res.Thoroughfares[0].Delivery_points.sort(compareLookupAddress);
  const finalAddress =
    res &&
    res.Thoroughfares[0] &&
    res.Thoroughfares[0].Delivery_points.map(
      (Delivery_points: DeliveryPoint, Index: number) => {
        return {
          label: `${
            Delivery_points.Organisation_name
              ? Delivery_points.Organisation_name + ","
              : ""
          } ${
            Delivery_points.Sub_building_name
              ? Delivery_points.Sub_building_name + ","
              : ""
          } ${
            Delivery_points.Building_name
              ? Delivery_points.Building_name + ","
              : ""
          } ${Delivery_points.Building_number} ${
            res.Thoroughfares[0].Thoroughfare_name
          } ${
            res.Thoroughfares[0].Thoroughfare_descriptor
              ? res.Thoroughfares[0].Thoroughfare_descriptor + ","
              : ""
          } ${res.Dependent_locality ? res.Dependent_locality + "," : ""} ${
            res.Town
          }`,
          value: Index.toString(),
        };
      }
    );

  return finalAddress;
};

export const getGridColumnsWithDescription = (
  columns: GridColDef[]
): GridColDef[] => {
  const columnsWithDesc = [];
  for (const column of columns) {
    if (column.description === null) {
      column.description = column.headerName;
    }
    columnsWithDesc.push(column);
  }
  return columnsWithDesc;
};

export const IsJsonString = (data: string): boolean => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatRadioPayloadToBooleanValues = (payload: any) => {
  const formattedData = _.cloneDeep(payload);
  if (formattedData) {
    for (let [key, value] of Object.entries(formattedData)) {
      if (
        value &&
        typeof value === "string" &&
        (value.toLowerCase() === "true" || value.toLowerCase() === "false")
      ) {
        formattedData[key] = value === "true" ? true : false;
      }
    }
  }
  return formattedData;
};

export const formatBooleanValuesToString = (payload: any) => {
  const formattedData = _.cloneDeep(payload);
  if (formattedData) {
    for (let [key, value] of Object.entries(formattedData)) {
      if (typeof value === "boolean") {
        formattedData[key] = value ? "true" : "false";
      }
    }
  }
  return formattedData;
};

export const isNonNull = (value: any): boolean => value && value !== "";

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const convertNullsToEmptyStrings = (obj: any): void => {
  if (!obj) return;
  for (var [key, value] of Object.entries(obj)) {
    if (obj[key] && typeof obj[key] === "object") {
      convertNullsToEmptyStrings(obj[key]);
    } else if (Object.prototype.toString.call(obj[key]) === "[object Array]") {
      for (const { k, v } of obj[key]) {
        convertNullsToEmptyStrings(v);
      }
    } else if (value === null) {
      obj[key] = "";
    }
  }
};

export const camelize = (obj: any) =>
  _.transform(obj, (acc: any, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.upperFirst(key as string);
    acc[camelKey] = _.isObject(value) ? camelize(value) : value;
  });

export const getTypeaheadOptions = (
  labelKey: string,
  idKey: string,
  data?: any[]
): TypeaheadOption[] => {
  let options: TypeaheadOption[] = [];
  if (data) {
    data.forEach((o) => {
      options.push({ Label: o[labelKey], Id: o[idKey] });
    });
  }
  return options;
};

export const isIterable = (obj: any) => {
  if (obj === null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
};

export const getDropdownMemberOptions = (members: DropdownMember[]) => {
  const options: OptionType[] = [];
  members.forEach((member) => {
    let className = "";
    if (!member.HasAcceptedCurrentTsAndCs) className += "tsandcs-invalid ";
    if (!member.PaySuccSelected) className += " payment-not-setup";
    if (!member.Active) className += " inactive";
    if (member.Disabled) className += " disabled";
    options.push({
      value: member.Id,
      label: member.UserName,
      className: className,
    });
  });
  return options;
};

export const getDropdownTypeOptions = () => {
  const options: OptionType[] = [];
  options.push({ value: 0, label: "Dealer Prices" });
  options.push({ value: 1, label: "Transporter Costs" });
  return options;
};

export const getDropdownSalesPersonOptions = (
  salesPerson: DropdownAdminUsers[]
) => {
  const options: OptionType[] = [];
  if (salesPerson) {
    options.push({ label: "All People", value: "" });
    salesPerson.forEach((salesPerson) => {
      let className = "";
      options.push({
        value: salesPerson.Id,
        label: salesPerson.DisplayName,
        className: className,
      });
    });
  }
  return options;
};

export const getDropdownAdminUsersOptions = (
  adminUsers: DropdownAdminUsers[]
) => {
  const options: OptionType[] = [];
  if (adminUsers) {
    options.push({ label: "All People", value: 0 });
    adminUsers.forEach((adminUser) => {
      options.push({
        value: adminUser?.Id,
        label: adminUser.DisplayName,
      });
    });
  }
  return options;
};

export const getDropdownAdminUsersForTaskAssignementOptions = (
  adminUsers: DropdownAdminUsers[],
  viewTasks: boolean
) => {
  const options: OptionType[] = [];
  if (adminUsers) {
    if (viewTasks)
      options.push({ label: "Select Admin User", value: '' });
    options.push({ label: "Unassigned", value: viewTasks ? -1 : '' });
    adminUsers.forEach((adminUser) => {
      options.push({
        value: adminUser?.Id,
        label: adminUser.DisplayName,
      });
    });
  }
  return options;
};

export const getDropdownInvoiceDateOptions = (dates: string[]) => {
  const options: OptionType[] = [];
  options.push({ value: "", label: "All" });
  dates.forEach((date) => {
    const inputString = date;
    const dates = new Date(inputString);
    const formattedDate = dates.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    let className = "";
    options.push({
      value: formattedDate,
      label: formattedDate,
      className: className,
    });
  });
  return options;
};

export const lookupEnumByVal = (options: OptionType[], value: string): string => {
  const option = options.find((opt) => opt.value === parseInt(value));
  return option ? option.label : '';
};

export const lookupEnumByValForLabel = (enumType: any, value: number): string => {
  var option = enumType[value]
  return option ? option.toString().replace(/_/g, '').replace(/([A-Z])/g, ' $1').trim() : '';
};

export const checkIsPhoneAtleastEightDigits = (phoneNumber: string) =>
  /\d\d\d\d\d\d\d\d+$/.test(phoneNumber);

export const replaceLineBreaksWithBR = (str: any) => {
  return typeof str === "string"
    ? str?.trim().replace(/(?:\r\n|\r|\n)/g, "<br />")
    : str;
};

/** API Response functions */
export const isSuccessEntityCreatedOrUpdatedResponse = (
  response?: EntityCreatedOrUpdatedViewModel
) => response?.Error === null;

export const isSuccessListResponse = (response?: ListResponse<any>) =>
  response?.ErrorResponse === null;

export const isMovexErrorResponse = (error: any): boolean =>
  error && !!error.ErrorCode && !!error.DefaultHttpResponse;

export const isApiError = (error?: any): boolean => error && !!error.TraceId;

export const isValidNumberAndNotZero = (value: any) => {
  const isValidNumber = !Number.isNaN(value);
  const isNotZero = value !== 0;
  return isValidNumber && isNotZero;
}

export function getTaskStatusFromValue(value: number): string | undefined {
  for (const key in TaskStatus) {
    if (key === value.toString()) {
      return TaskStatus[key];
    }
  }
  return undefined;
}

export function getTaskTypeFromValue(value: number): string | undefined {
  for (const key in TaskType) {
    if (key === value.toString()) {
      return TaskType[key];
    }
  }
  return undefined;
}

export const getTaskDataFieldValue = (taskType: number, taskDataFieldValues: TaskDataFieldValue[] | null): string => {
  if (taskType == TaskType.JobAdditionalCosts && taskDataFieldValues != null && taskDataFieldValues.length > 0) {
      const costs = taskDataFieldValues
          .filter((item: TaskDataFieldValue) => item.ValueStoreField === "NumericValue")
          .map((item: TaskDataFieldValue) => `£${item.NumericValue}`);
      return costs.join(", ");
  }
  return "";
};

export const getDropdownAdminTaskStatusOptions = (nextWorkflowStatuses: number[], currentStatus: number) => {
  const options: OptionType[] = [];
  for (const key in TaskStatus) {
    if (isNaN(Number(key))) {
      const value = TaskStatus[key];
      const parsedValue = parseInt(value);
      const label = (StatusTypeOption.find(option => option.value === parsedValue.toString()))?.label;
      const isSelectable = nextWorkflowStatuses?.includes(parsedValue);
      const className = isSelectable ? "selectable-option" : "non-selectable-option";
      if (isSelectable || parsedValue === currentStatus) {
        options.push({
          value: value,
          label: label ?? key,
          className: className,
          disabled: !isSelectable,
        });
      }
    }
  }
  return options;
};

export const getTaskOpenReasonEnumToDropdownOptions = () => {
     const options: OptionType[] = [];
     for (const key in TaskOpenReason) {
          if (isNaN(Number(key))) {
               const value = TaskOpenReason[key];
               let label = key;
               if (parseInt(value) === 0) {
                    label = 'Select Reason';
               } else {
                    label = key.replace(/([a-z])([A-Z])/g, '$1 $2');
               }
               options.push({
                    value: value,
                    label: label,
               });
          }
     }
     return options;
};

export const validateEmail = (email: string) => {
  const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return re.test(String(email).toLowerCase());
};

export const parsePlateInput = (plateInput: string) => {
  return plateInput.includes(',') ? plateInput.split(',') : [plateInput];
};

export const verifyPlateInput = (plateInput: string) => {
  const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharsRegex.test(plateInput)) {
       // Special characters detected
       return false;
  }
  return true;
};

export function shallowEqual(object1: any, object2: any) {

  if (!object1 || !object2) {
    return false;
  }

  const keys1 = Object?.keys(object1);
  const keys2 = Object?.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}