import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';
import { UpdateAdminRolePermissionModel } from '../../../../../../Models/Admin/UpdateAdminRolePermission';

export const usePutUpdateAdminRolePermission = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  UpdateAdminRolePermissionModel
> => {

  const putUpdateAdminRolePermission = (payload: UpdateAdminRolePermissionModel) => {
    return agent.adminRole.putUpdateAdminRolePermission(payload);
  };

  return useMutation(putUpdateAdminRolePermission);
};