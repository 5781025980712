/** Import inbuilt libraries*/
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
/** Import custom components*/
import { ControlledPagination as Pagination } from '../../../../../../Components';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { VehicleItemModelKeys, VehiclesResponseModel } from '../../../../../../Models/System/Vehicles';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { GridProps, Sorter } from '../../../../../../Types';
import {
  ActionColumnPath, getSortModelFromSorter,
} from '../../../../../../Utils';
import { useDeleteVehicle } from '../Hooks';

const VehiclesGridView: React.FC<VehiclesGridViewProps> = (props) => {
  const { data, onPageChange, onPageSizeChange, onSort } = props;
  const { Page } = data;

  const {
    mutate: deleteVehicle,
    isLoading: isDeleteVehicleLoading,
    error: deleteVehicleError,
  } = useDeleteVehicle();

  const columns = [
    {
      field: VehicleItemModelKeys.Id,
      headerName: 'ID',
      description: 'ID',
      renderCell: (params: GridRenderCellParams) => {
        const link = `${RouteConstants.EditVehicle}/${params.value}`;
        return <HoverableLink to={link}>{params.value}</HoverableLink>;
      },
    },
    {
      field: VehicleItemModelKeys.Make,
      headerName: 'Make',
      description: 'Make',
      renderCell: (params: GridRenderCellParams) => renderCell(params),
      flex: 2,
    },
    {
      field: VehicleItemModelKeys.Model,
      headerName: 'Model',
      description: 'Model',
      renderCell: (params: GridRenderCellParams) => renderCell(params),
      flex: 2,
    },
    {
      field: VehicleItemModelKeys.VehicleType,
      headerName: 'Vehicle Type',
      description: 'Vehicle Type',
      flex: 2,
    },
    {
      field: VehicleItemModelKeys.MaxWeight,
      headerName: 'Max Weights',
      flex: 2,
      description: 'Max Weights',
    },
    {
      field: ActionColumnPath,
      headerName: 'Action',
      flex: 2,
      description: 'Action',
      type: 'actions',
      renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    },
  ];

  const renderCell = (params: GridRenderCellParams) => {
    return (
      <Tooltip title={params.value || ''}>
        <span>{params.value}</span>
      </Tooltip>
    );
  };

  const renderActionCell = (params: GridRenderCellParams) => {
      const link = `${RouteConstants.EditVehicle}/${params.id}`;
    return (
      <React.Fragment>
        <Tooltip title="Edit Vehicle">
          <HoverableLink to={link} style={{ paddingLeft: '10px', alignContent: 'left', boxSizing: 'border-box' }}>
            <FontAwesomeIcon icon={faEdit} />
          </HoverableLink>
        </Tooltip>
        <Tooltip title="Delete Vehicle">
          <HoverableLink to="#" onClick={ () => {
            const vehicleId = params.id as string;
            deleteVehicle(parseInt(vehicleId))
          }
            } style={{ paddingLeft: '10px', width: '100%', alignContent: 'left' }}>
            <FontAwesomeIcon icon={faTrash} />
          </HoverableLink>
        </Tooltip>
      </React.Fragment>
    );
  };

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (Page) {
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = Page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      );
    };

    return (
      <div>
        {Page && (
          <div id='id_grid_vehicles' className="view-vehicles-grid-container">
            <DataGrid
              sortingOrder={['desc', 'asc']}
              autoHeight
              getRowId={(row: any) => row.Id}
              disableColumnMenu={true}
              rows={Items}
              rowHeight={30}
              columns={columns}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
              sortModel={getSortModelFromSorter(data?.TableSorter as Sorter)}
              onSortModelChange={handleSort}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

interface VehiclesGridViewProps extends GridProps<VehiclesResponseModel> {}

export default VehiclesGridView;
