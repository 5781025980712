import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import { InlineBlockColMd10Section } from '../../../../../../Components/Formik';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { IUnlockReason } from '../../../../../../Models/Jobs/EditJob/IUnlockReason';

const UnlockJobModal: React.FC<{
     jobId: number;
     putUnlockJob: Function;
     closeModal: Function;
}> = ({jobId, putUnlockJob, closeModal}): JSX.Element => {
     const initialValues = {
          UnlockReason: '',
     };
     
     const formik = useFormik({
          initialValues: initialValues,
          onSubmit: (values) => {
               const payload = {
                    JobId: jobId,
                    UnlockedReason: values.UnlockReason,
               } as IUnlockReason;

               putUnlockJob(payload);
               closeModal();
          },
          enableReinitialize: true,
     });

     return (
          <>
               <FormikProvider value={formik}>
                    <Form>
                         <fieldset>
                              <InlineBlockColMd10Section>
                                   <RowContainer>
                                        <b>Are you sure you want to unlock job {jobId}? </b>
                                   </RowContainer>

                                   <FormikControl label='Unlock Reason' name='UnlockReason' control={FormControlKeys.Textarea} />
                              </InlineBlockColMd10Section>
                         </fieldset>
                         <br />
                         <StyledCol>
                              <FormikControl label='Unlock Job' variant='danger' control={FormControlKeys.Button} type='submit' />
                              <FormikControl label='Cancel' onClick={closeModal} control={FormControlKeys.Button} />
                         </StyledCol>
                    </Form>
               </FormikProvider>
          </>
     );
};

export default UnlockJobModal;

const RowContainer = styled.div`
     display: flex;
     align-items: center;
     gap: 10px;
`;

const StyledCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;