import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddGroupModel } from '../../../../../Models/Groups';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddGroup = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddGroupModel
> => {

  const postAddGroup = (payload: AddGroupModel) => {
    return agent.groups.postAddGroup(payload);
  };

  return useMutation(postAddGroup);
};
