import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { MembersRequestParam, MembersResponseModel } from '../../../../../Models/Members';

export const useGetMembers = (payload: MembersRequestParam,
    options?: any
): UseQueryResult<MembersResponseModel, any> => {

    const getMembers = () => {
        return agent.members.getMembers(payload)
    }
    const uniqueId = JSON.stringify(payload);
    return useQuery(['GetMembers', payload, uniqueId], getMembers, {
        ...options,
        retry: 1,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });
}
