/** Import inbuilt libraries*/
import { GridSortModel } from '@mui/x-data-grid';
import React,{ useEffect,useState } from 'react';
/** Import custom components*/
import { Alert, Loading,renderHeader,useAlert } from '../../../../../Components';
import { AdminRoleModelKeys } from '../../../../../Models/System/AdminRoles';
import { CommonRequestParam,Pager } from '../../../../../Types';
import { getSorterFromSortModel,Page } from '../../../../../Utils';
import AdminRolesGridView from './Grid';
import { useGetAdminRoles } from './Hooks';

const ViewAdminRoles: React.FC = (): JSX.Element => {
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [pager, setPager] = useState<Pager>({
          CurrentPage: Page.CurrentPage,
          ItemsPerPage: Page.PageSize,
     });
     const [sortModel, setSortModel] = useState<GridSortModel>([
          {
               field: AdminRoleModelKeys.Id,
               sort: 'asc',
          },
     ]);

     const getParams = () => {
          const params: CommonRequestParam = {
               Pager: pager,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };

     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
     };
     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
     };
     const onSort = (sortModel: GridSortModel) => {
          setPager({ ...pager, CurrentPage: 1 });
          setSortModel(sortModel);
     };

     /** Custom Hooks */
     const { alert, setAlert } = useAlert();

     /** Query Hooks */
     const {
          data: adminRoleListResponse,
          isLoading: isAdminRoleListLoading,
          refetch: refetchAdminRoleList,
          error: getAdminRoleListError,
     } = useGetAdminRoles(getParams());

     const isDataLoaded = adminRoleListResponse !== null;

     /** UseEffects */
     useEffect(() => {
          refetchAdminRoleList();
     }, [pager, sortModel]);

     useEffect(() => {
          setIsLoading(isAdminRoleListLoading);
     }, [isAdminRoleListLoading]);

     useEffect(() => {
          if (getAdminRoleListError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getAdminRoleListError?.Subject ? getAdminRoleListError?.Subject : 'Error',
                    body: getAdminRoleListError?.Description
                         ? getAdminRoleListError?.Description
                         : 'Error while fetching the list of admin roles.',
               });
          }
     }, [getAdminRoleListError]);

     return (
          <div>
               {renderHeader('Admin Roles')}
               <div className='container-fluid'>
                    <Loading loading={isLoading} />
                    <Alert {...alert} />
                    {isDataLoaded && adminRoleListResponse && (
                         <AdminRolesGridView
                              data={adminRoleListResponse}
                              onSort={onSort}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onRefresh={refetchAdminRoleList}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewAdminRoles;
