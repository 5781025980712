import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetInvoicesRequest } from '../../../../../Models/Billing';

export const useGetInvoices = (payload: GetInvoicesRequest,
  options?: any
): UseQueryResult<any, any> => {

  const getInvoices = () => {
    
    return agent.invoices.getInvoices(payload)
    
  };

  return useQuery(['GetInvoices', payload], getInvoices, {
    ...options,
  });
};
