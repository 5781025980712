import { Field, ErrorMessage } from 'formik';
import TextError from '../Formik/TextError';

export function Textarea({ ...props }) {
  const { label, name, showRequired, showLabel = true, placeholder, ...rest } = props;
  return (
    <div className="mb-3 form-group ">
      {showLabel && (
        <label className="fw-600">{label}</label>
      )}

      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      {showLabel && (
        <div style={{ marginTop: 10 }}></div>
      )}
      <Field
        as="textarea"
        id={name}
        className="form-control"
        name={name}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Textarea;
