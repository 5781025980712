import React from 'react';

export const InfoHowToGetPaid = () => {
  return (
    <div>
      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <strong style={{ fontSize: 16 }}>
          <span style={{ color: '#BD321C' }}>
            IMPORTANT MESSAGE PLEASE READ
          </span>
        </strong>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <u>
          <span style={{ fontSize: 16 }}>
            <strong>
              <span style={{ color: '#BD321C' }}>
                Although most POD&#39;S are ok, we are still seeing some
                incorrect ones
              </span>
            </strong>
          </span>
        </u>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <strong>
          In order for you to get your job paid through the new 24 hour system
          you must carry out the below:
        </strong>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        Upload a proof of delivery (POD) with every job either via the APP or a
        paper one on the website&nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        If you use a paper POD it&nbsp;<u>must</u>&nbsp;be a vehicle inspection
        form, with the below filled in....
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        1. Vehicle registration number(s)
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        2. Collection &amp; delivery address
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        3. Appraisal completed
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        4. Customer name, signature &amp; date
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        Without the correct forms we will no longer be able to process your
        paments in 24 hours
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        If you are unsure where to get the correct forms, click&nbsp;
        <a
          href="http://oceanprintdirect.co.uk/vehicle-inspection-form-printing"
          style={{ color: 'rgb(0, 136, 204)', textDecoration: 'none' }}
        >
          here
        </a>
        &nbsp;to order them.&nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        If you are unsure of what to do ref the above please call{' '}
        <strong style={{ fontSize: 16 }}>0208 551 1060</strong>.
      </p>
    </div>
  );
};
