import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetCanRelistJob = (
    payload: number,
    options?: any
): UseQueryResult<boolean, any> => {

    const getCanRelistJob = () => {
        if(payload === 0) return [];
        return agent.jobs.GetCanRelistJob(payload)
    }

    return useQuery(['GetCanRelistJob', payload], getCanRelistJob, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}
