import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddTradePlateRequest } from '../../../../../../../Models/Members/EditMember/AddTradePlatesRequest';
import { AddTradePlateResponse } from '../../../../../../../Models/Members/EditMember/AddTradePlatesResponse';
import { MovexErrorResponse } from '../../../../../../../Types';

export const usePostAddTradePlates = (
     options?: UseMutationOptions<AddTradePlateResponse, MovexErrorResponse, AddTradePlateRequest>
): UseMutationResult<AddTradePlateResponse, MovexErrorResponse, AddTradePlateRequest> => {
     const addTradePlates = (payload: AddTradePlateRequest) => {
          return agent.members.postAddTradePlates(payload);
     };

     return useMutation(addTradePlates, {
          ...options,
          onError: (error: MovexErrorResponse, variables: AddTradePlateRequest, context: unknown) => {
               options?.onError?.(error, variables, context);
          },
     });
};
