import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { BillsRequestParam } from "../../../../../Models/Billing/Request";
import { BillsResponseModel } from "../../../../../Models/Billing/Response";

export const useGetBills = (
  payload: BillsRequestParam,
  options?: any
): UseQueryResult<BillsResponseModel, any> => {
  const getBills = () => {
    return agent.bills.getBills(payload);
  };

  return useQuery(["GetBills", payload], getBills, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
