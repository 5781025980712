import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobRequestMoreQuoteModel, UpdateJobRequestMoreQuotesResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobRequestMoreQuotes = (
  options?: any
): UseMutationResult<
UpdateJobRequestMoreQuotesResponseModel,
  any,
  UpdateJobRequestMoreQuoteModel
> => {
  const usePutUpdateJobRequestMoreQuotes = (
    payload: UpdateJobRequestMoreQuoteModel
  ) => {
    return agent.jobs.putUpdateJobRequestMoreQuotes(payload);
  };

  return useMutation(usePutUpdateJobRequestMoreQuotes);
};
