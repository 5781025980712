import { ErrorMessage, Field, useField } from 'formik';
import { getDateForInput, isNonNull } from '../../Utils';
import TextError from '../Formik/TextError';

export const DateInputNotClearable = ({ ...props }) => {
     const { name, label, type, showRequired, placeholder, ...rest } = props;
     const [field, meta, helpers] = useField(props.name);
     let { value, touched, error } = meta;
     const { setValue } = helpers;

     value = getDateForInput(value);

     const className = touched && error ? 'is-invalid' : touched && isNonNull(value) ? 'is-valid' : '';

     return (
          <div className='mb-3 form-group'>
               {label && (
                    <label htmlFor={name} className='form-label fw-600'>
                         {label}
                    </label>
               )}
               {showRequired && (
                    <span className='asterisk' title='Required'>
                         *
                    </span>
               )}

               <Field
                    name={name}
                    id={name}
                    type={type}
                    className={`form-control ${className}`}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e: any) => {
                         if (e.target.value !== '') {
                              setValue(e.target.value);
                         }else{
                              alert('Please select a date!');
                         }
                    }}
                    {...rest}
               />

               <ErrorMessage component={TextError} name={name} />
          </div>
     );
};

export default DateInputNotClearable;
