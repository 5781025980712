import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GroupSavedAddressDetails } from '../../../../../Models/Groups';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useAddOrUpdateGroupSavedDetails = (
  options?: any
): UseMutationResult<
  string,
  any,
  GroupSavedAddressDetails
> => {

  const addOrUpdateGroupSavedDetails = (payload: GroupSavedAddressDetails) => {
    return agent.groups.addOrUpdateGroupSavedDetails(payload);
  };

  return useMutation(addOrUpdateGroupSavedDetails);
};