import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FieldSetContainer = styled.fieldset`
  padding: 10px;
  width: 100%;
`;

export const FieldSetHeader = styled.legend`
  top: 10px;
`;
export const FormContainer = styled.form``;

export const MainContentOuterContainer = styled.div``;

export const MainContentInnerContainer = styled.div.attrs((props) => ({
  className: 'container-fluid',
}))``;

export const RowFluidContainer = styled.div.attrs((props) => ({
  className: 'row-fluid',
}))``;

export const DivDisplayRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Span8Offset2Container = styled.div.attrs((props) => ({
  className: 'span8 offset2',
}))``;

export const FormFieldSetContainer = styled.div`
  display: grid;
`;

export const DivContainer = styled.div``;

export const DivContainerWithMargin = styled.div`
  margin: 10px 0;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexContainerWithMargin = styled.div`
  margin-bottom: 300px;
`;

export const Width50DivContainer = styled.div`
  width: 50%;
`;

export const Width20DivContainer = styled.div`
  width: 20%;
`;

export const FieldAddButtonContainer = styled.div`
  margin: 30px 10px;
`;

export const FixedBottomContainer = styled.div.attrs((props) => ({
  className: 'span8',
}))`
  position: fixed;
  bottom: 5px;
  margin-left: 0px !important;
`;

export const FileNotUploadedContainer = styled.div`
  color: #bd321c;
`;

export const Margin20RightSpan = styled.span`
  margin-right: 20px;
`;
export const Margin20TopSpan = styled.span`
  margin-top: 20px;
`;
export const Margin20LeftSpan = styled.span`
  margin-left: 20px;
`;
export const Margin20BottomSpan = styled.span`
  margin-bottom: 20px;
`;

export const InlineBlockColMd5Section = styled.div.attrs(() => ({
  className: 'col-md-5',
}))`
    display: inline-block;
    position: absolute;
    padding: 15px;
`;

export const InlineBlockColMd6Section = styled.div.attrs(() => ({
  className: 'col-md-6',
}))`
  display: inline-block;
  padding: 0px;
`;

export const InlineBlockColMd7Section = styled.div.attrs(() => ({
  className: 'col-md-7',
}))`
  display: inline-block;
  padding: 0px;
`;

export const InlineBlockColMd10Section = styled.div.attrs(() => ({
  className: 'col-md-10',
}))`
  display: inline-block;
  padding: 0px;
`;


export const InlineBlockColMd4Section = styled.div.attrs(() => ({
  className: 'col-md-4',
}))`
  display: inline-block;
  padding: 0px;
`;

export const InlineBlockColMd6AndAlignTopSection = styled.div.attrs(() => ({
  className: 'col-md-6 align-top'
}))`
  display: inline-block;
  padding: 0px;
`;

export const Margin5LeftSDiv = styled.div`
  margin-left: 5px;
`;

export const ColMd6SectionInner = styled.div`
  margin-right: 15px;
`;

export const FieldSetColMd4Section = styled.div.attrs(() => ({
  className: 'col-md-4',
}))`
  display: inline-block;
  padding: 0px;
`;

export const FieldSetColSpan5Section = styled.div.attrs(() => ({
  className: 'span5',
}))``;

export const FieldSetColSpan10Section = styled.div.attrs(() => ({
  className: 'span10',
}))``;

export const FieldSetColSpan5AlignedSection = styled.div.attrs(() => ({
  className: 'span5',
}))`text-align: right;
  margin-left: 15px;
`;

export const FieldSetColMd4SectionInner = styled.div`
  margin-right: 15px;
`;

export const LineBreak = styled.br``;

export const BoxContainer = styled.div`
  height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
`;

export const FieldError = styled.span`
  color: #b32e2e;
  font-size: 14px;
`;

export const HoverableLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Margin20BottomContainer = styled.div`
  margin-bottom: 20px;
  height: 3em;
`;

export const InlineBlockDiv = styled.div`
  display: inline-block;
`;

export const MovexColor = styled.div`
  display: inline-block;
`;

export const QuickLinkItem = styled.div``;

export const FieldAddContainer = styled.div`
  display: flex;
`;

export const SpanCommentItem = styled.span`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;