export enum NoteActivityType {
     UserAssigned = 0,
     StatusUpdated = 1,
     NoteAdded = 2,
     Created = 3,
     AttachmentAdded = 4,
     AttachmentDeleted = 5,
     Correction = 6,
     Unknown = 7
}
