const getBadRequestErrorContent = (errors: any[]) => {
  let content: JSX.Element[] = [];
  for (const [errorHeader, errorList] of Object.entries(errors)) {
    const errorDetailContent = [];
    for (const errorDetail of errorList) {
      errorDetailContent.push(<li>{errorDetail} </li>);
    }
    content.push(
      <div>
        <ul>
          {errorHeader}
          {errorDetailContent}
        </ul>
      </div>
    );
  }
  return content;
};

const ApiResponseError = { getBadRequestErrorContent };

export default ApiResponseError;
