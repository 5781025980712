/** Inbuilt libraries */
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
/** Custom Components */
import styled from 'styled-components';
import { MemberIDParamType } from '../..';
import {
  Alert,
  AlertProps,
  ConfirmDialog,
  ConfirmDialogProps,
  ShowPleaseWait,
  useAlert,
} from '../../../../../../Components';
import { Button } from '../../../../../../Components/FormControls';
import { FormControlKeys } from '../../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import {
  DivContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormFieldSetContainer,
  HoverableLink,
  Width50DivContainer,
} from '../../../../../../Components/Formik/StyledComponents';
import {
  BlockedMemberModel,
  BlockMemberModelKeys,
} from '../../../../../../Models/Members';
import { MovexErrorResponse } from '../../../../../../Types';
import {
  ConfirmDialogContent,
  getDropdownMemberOptions,
  getFormattedDateWithYear,
  isMovexErrorResponse,
  isSuccessEntityCreatedOrUpdatedResponse,
} from '../../../../../../Utils';
import { MemberType } from '../../../../../../Utils/Enums';
import { useGetMember } from '../General/Hooks';
import { useDeleteMemberBlocks, usePutMemberBlock } from './Hooks';
import { useGetMembersForDropdown } from './Hooks/useGetMembersForDropdown';
import { MemberBlockKeys } from './Types';
import './index.css';
import { RouteConstants } from '../../../../../../Routes/Constants';

const getBlockMemberUserNameCellContent = (
  params: GridRenderCellParams,
  isBlockedMember: boolean
) => {
  const row = params.row as BlockedMemberModel;
  const memberID = isBlockedMember ? row.BlockedMemberID : row.BlockingMemberID;
  return (
    <Tooltip title={params.value || ''}>
      <HoverableLink
        target="_blank"
        to={`${RouteConstants.EditMember}/${memberID}`}
      >
        {params.value}
      </HoverableLink>
    </Tooltip>
  );
};
const columnsForMembersBlockedGrid: GridColDef[] = [
  {
    field: BlockMemberModelKeys.BlockedMemberUserName,
    headerName: 'UserName',
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      getBlockMemberUserNameCellContent(params, true),
  },
  {
    field: BlockMemberModelKeys.BlockedMemberCompany,
    flex: 1,
    headerName: 'Company',
  },
  {
    field: BlockMemberModelKeys.BlockedDate,
    flex: 1,
    headerName: 'Blocked Date',
    renderCell: (params: GridRenderCellParams) =>
      getFormattedDateWithYear(params.value),
  },
  {
    field: BlockMemberModelKeys.BlockedReason,
    flex: 3,
    headerName: 'Blocked Reason',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ''}>
        <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>
    ),
  },
];

const columnsForMembersBlockingGrid: GridColDef[] = [
  {
    field: BlockMemberModelKeys.BlockingMemberUserName,
    headerName: 'UserName',
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      getBlockMemberUserNameCellContent(params, false),
  },
  {
    field: BlockMemberModelKeys.BlockingMemberCompany,
    flex: 1,
    headerName: 'Company',
  },
  {
    field: BlockMemberModelKeys.BlockedDate,
    flex: 1,
    headerName: 'Blocked Date',
    renderCell: (params: GridRenderCellParams) =>
      getFormattedDateWithYear(params.value),
  },
  {
    field: BlockMemberModelKeys.BlockedReason,
    flex: 3,
    headerName: 'Blocked Reason',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ''}>
        <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>
    ),
  },
];

const MemberBlocks: React.FC = () => {
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };
  const { memberID } = useParams<MemberIDParamType>();
  const initialValues = { SelectedMemberBlockedId: 0 };

  /** useStates */
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [movexErrorResponse, setMovexErrorResponse] =
    useState<MovexErrorResponse>({ ErrorCode: 0, DefaultHttpResponse: 0 });
  const [blockedMembers, setBlockedMembers] = useState<BlockedMemberModel[]>(
    []
  );
  const [blockingMembers, setBlockingMembers] = useState<BlockedMemberModel[]>(
    []
  );
  const [selectedMembersBlockingIds, setSelectedMembersBlockingIds] =
    React.useState<number[]>([]);
  const [selectedMembersBlockedIds, setSelectedMembersBlockedIds] =
    React.useState<number[]>([]);
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    data: getMemberResponse,
    isRefetching: isRefetchGetMemberLoading,
    refetch: refetchGetMember,
    error: getMemberError,
  } = useGetMember();

  const { data: transportCompanies = [] } = useGetMembersForDropdown(
    MemberType.TransportCompany
  );

  const {
    mutate: deleteMemberBlocks,
    isLoading: isDeleteMemberBlocksLoading,
    data: deleteMemberBlocksResponse,
    error: deleteMemberBlocksError,
  } = useDeleteMemberBlocks();

  const {
    mutate: putMembersBlock,
    isLoading: isPutMemberBlockLoading,
    data: putMembersBlockResponse,
    error: putMembersBlockError,
  } = usePutMemberBlock();

  const CustomFooterForMembersBlocked = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedMembersBlockedIds.length}
        style={{ margin: 10 }}
        onClick={removeMembersBlocked}
      ></Button>
    );
  };

  /** Methods */
  const CustomFooterForMembersBlocking = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedMembersBlockingIds.length}
        style={{ margin: 10 }}
        onClick={removeMembersBlocking}
      ></Button>
    );
  };

  const removeMembersBlocked = (e: ChangeEvent) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeleteMemberBlocked.Title,
      body: ConfirmDialogContent.DeleteMemberBlocked.Body,
      show: true,
      confirmCallback: handleConfirmRemoveMembersBlocked,
    });
  };

  const handleConfirmRemoveMembersBlocked = () => {
    onConfirmDialogClose();
    deleteMemberBlocks(selectedMembersBlockedIds);
  };

  const removeMembersBlocking = (e: ChangeEvent) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeleteMemberBlocking.Title,
      body: ConfirmDialogContent.DeleteMemberBlocking.Body,
      show: true,
      confirmCallback: handleConfirmRemoveMembersBlocking,
    });
  };

  const handleConfirmRemoveMembersBlocking = () => {
    onConfirmDialogClose();
    deleteMemberBlocks(selectedMembersBlockingIds);
  };

  const addMembersBlock = (blockMemberID: number) => {
    putMembersBlock({
      MemberID: parseInt(memberID),
      BlockMemberID: blockMemberID,
    });
  };

  /** useEffects */
  useEffect(() => {
    setShowPleaseWait(
      isDeleteMemberBlocksLoading ||
        isPutMemberBlockLoading ||
        isRefetchGetMemberLoading
    );
  }, [
    isDeleteMemberBlocksLoading,
    isPutMemberBlockLoading,
    isRefetchGetMemberLoading,
  ]);

  useEffect(() => {
    if (putMembersBlockResponse) {
      setAlert({
        ...alert,
        show: true,
        header: "Member blocked successfully, Id: " + putMembersBlockResponse?.Id,
      });
      refetchGetMember();
    }
  }, [putMembersBlockResponse]);

  useEffect(() => {
    if (putMembersBlockError) {
      setAlert({
        ...alert,
        show: true,
        header: putMembersBlockError.Description,
      });
    }
    if (deleteMemberBlocksError) {
      setAlert({
        ...alert,
        show: true,
        header: deleteMemberBlocksError.Description,
      });
    }
  }, [putMembersBlockError, deleteMemberBlocksError]);

  useEffect(() => {
    const blockedMemberData = blockedMembers.filter(
      (x) => !selectedMembersBlockedIds.includes(x.Id)
    );
    const blockingMemberData = blockingMembers.filter(
      (x) => !selectedMembersBlockingIds.includes(x.Id)
    );
    setBlockedMembers(blockedMemberData);
    setBlockingMembers(blockingMemberData);
  }, [deleteMemberBlocksResponse]);

  useEffect(() => {
    if (getMemberResponse) {
      const {
        BlockedMembers: blockedMembers,
        BlockingMembers: blockingMembers,
      } = getMemberResponse;
      setBlockedMembers(blockedMembers);
      setBlockingMembers(blockingMembers);
    }
  }, [getMemberResponse]);

  useEffect(() => {
    if (getMemberError) {
      setAlert({
        ...alert,
        show: true,
        header: getMemberError?.Subjectsss,
        body: getMemberError?.Description,
      });
    }
  }, [getMemberError]);

  if (getMemberResponse) {
    return (
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => (
          <Form>
            <ConfirmDialog {...confirmDialogContent}></ConfirmDialog>
            <ShowPleaseWait show={showPleaseWait} />
            <Alert {...alert} />
            <FormFieldSetContainer>
                <div>
                  <FieldSetContainer>
                    <FieldSetHeader>
                      Members blocked by this member
                    </FieldSetHeader>
                    <DivContainer>
                      <FlexContainer>
                        <div style={{ flexGrow: 1 }}>
                          <FormikControl
                            name={MemberBlockKeys.SelectedMemberBlockedId}
                            control={FormControlKeys.Select}
                            placeholder="Select"
                            className="member-select form-select"
                            options={getDropdownMemberOptions(
                              transportCompanies
                            )}
                          />
                        </div>
                        <FieldAddButtonContainer>
                          <Button
                            label="Block Member"
                            onClick={() => {
                              addMembersBlock(values?.SelectedMemberBlockedId);
                            }}
                            disabled={!values.SelectedMemberBlockedId}
                            variat="sm"
                          />
                        </FieldAddButtonContainer>
                      </FlexContainer>
                    </DivContainer>
                    <DivContainer>
                      <div id='id_grid_members_blocked_by_this_member' style={{ width: '100%' }}>
                        <DataGrid
                          sortingOrder={['desc', 'asc']}
                          getRowId={(row: any) => row.Id}
                          autoHeight
                          rows={blockedMembers}
                          disableColumnMenu={true}
                          columns={columnsForMembersBlockedGrid}
                          columnBuffer={columnsForMembersBlockedGrid.length + 1}
                          hideFooterPagination={true}
                          checkboxSelection
                          disableSelectionOnClick
                          selectionModel={selectedMembersBlockedIds}
                          onSelectionModelChange={(selected) => {
                            setSelectedMembersBlockedIds(selected as number[]);
                          }}
                          components={{
                            Footer: blockedMembers.length
                              ? CustomFooterForMembersBlocked
                              : undefined,
                            NoRowsOverlay: () => {
                              return (
                                <GridOverlay>
                                  <div>No members blocked by this member!</div>
                                </GridOverlay>
                              );
                            },
                          }}
                        />
                      </div>
                    </DivContainer>
                  </FieldSetContainer>
                </div>
                <div>
                  <FieldSetContainer>
                    <FieldSetHeader>
                      Members blocking this member
                    </FieldSetHeader>
                    <DivContainer>
                      <div id='id_grid_members_blocking_this_member' style={{ width: '100%' }}>
                        <DataGrid
                          sortingOrder={['desc', 'asc']}
                          getRowId={(row: any) => row.Id}
                          autoHeight
                          disableColumnMenu={true}
                          rows={blockingMembers}
                          columns={columnsForMembersBlockingGrid}
                          columnBuffer={
                            columnsForMembersBlockingGrid.length + 1
                          }
                          hideFooterPagination={true}
                          checkboxSelection
                          disableSelectionOnClick
                          selectionModel={selectedMembersBlockingIds}
                          onSelectionModelChange={(selected) => {
                            setSelectedMembersBlockingIds(selected as number[]);
                          }}
                          components={{
                            Footer: blockingMembers.length
                              ? CustomFooterForMembersBlocking
                              : undefined,
                            NoRowsOverlay: () => {
                              return (
                                <GridOverlay>
                                  <div>No members blocking this member!</div>
                                </GridOverlay>
                              );
                            },
                          }}
                        />
                      </div>
                    </DivContainer>
                  </FieldSetContainer>
                </div>
            </FormFieldSetContainer>
          </Form>
        )}
      </Formik>
    );
  }
  return null;
};

export default MemberBlocks;

export const FieldAddButtonContainer = styled.div`
  margin: 0px 10px;
`;
