import { AppThunkAction } from "../../../App";
import agent from "../../../Axios/Agent";
import { AddMemberRequestModel } from "../../../Models/Members/AddMember";
import { EntityCreatedOrUpdatedViewModel } from "../../../Types";
import { AddMemberActionTypes } from './Types';

export const addMemberActionCreators = {
    /**
     * Function to add new member
     * @param  {AddMemberRequestModel} params
     */
    addMember: (params: AddMemberRequestModel): AppThunkAction<AddMemberActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "ADD_MEMBER", payload: params });
            agent.members
                .addMember(params)
                .then(response => response as EntityCreatedOrUpdatedViewModel)
                .then(data => {
                    dispatch({ type: "RECEIVE_ADDED_MEMBER", response: data })
                }).catch(error => {
                    dispatch({ type: "RECEIVE_ADD_MEMBER_ERROR", error })
                })
        }
    },

    /**
    * Function to update member
    * @param  {AddMemberRequestModel} params
    */
    updateMember: (params: AddMemberRequestModel): AppThunkAction<AddMemberActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "UPDATE_MEMBER", payload: params });
            agent.members
                .updateMember(params)
                .then(response => response as EntityCreatedOrUpdatedViewModel)
                .then(data => {
                    dispatch({ type: "RECEIVE_UPDATED_MEMBER", response: data })
                }).catch(error => {
                    dispatch({ type: "RECEIVE_UPDATE_MEMBER_ERROR", error })
                })
        }
    },

    getMemberFormValues: (payload: AddMemberRequestModel): AppThunkAction<AddMemberActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "GET_MEMBER_FORM_VALUES", payload: payload })
        }
    }

}