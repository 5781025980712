import { useQuery, UseQueryResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { GetAdminUsersForTaskListDropdownResponse } from '../../../../../Models/Tasks/GetAdminUsersForTaskListDropdownResponse';

export const useGetAdminUsersForTaskListDropdown = (
     activeAccountsOnly: boolean,
     options?: any
): UseQueryResult<GetAdminUsersForTaskListDropdownResponse, any> => {
     const getAdminUsersForTaskListDropdown = () => {
          if (activeAccountsOnly) {
               return adminTasks.getAdminUsersForTaskListDropdown(activeAccountsOnly);
          }
     };

     return useQuery(['GetAdminUsersForTaskListDropdown', activeAccountsOnly], getAdminUsersForTaskListDropdown, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
