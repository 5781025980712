import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { DeleteAdminUserRolesModel } from '../../../../../../Models/System';

export const useDeleteSelectedAdminRolesFromAdminUser = (
  options?: any
): UseMutationResult<any, any, DeleteAdminUserRolesModel> => {

  const deleteSelectedAdminRolesFromAdminUser = (payload: DeleteAdminUserRolesModel) => {
    return agent.adminRole.deleteSelectedAdminRolesFromAdminUser(payload);
  };

  return useMutation(deleteSelectedAdminRolesFromAdminUser);
};
