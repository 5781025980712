import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddMemberRequestModel } from '../../../../../../../Models/Members/AddMember';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const usePutUpdateMember = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddMemberRequestModel
> => {
  const updateMember = (payload: AddMemberRequestModel) => {
    return agent.members.updateMember(payload);
  };

  return useMutation(updateMember);
};
