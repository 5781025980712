import { Field } from 'formik';
import styled from 'styled-components';

export const Styledtfoot = styled.tfoot`
     background-color: rgb(241, 241, 241);
     font-size: 12px;
     font-weight: bold;
`;

export const StyledDivContainer = styled.div`
     max-width: 200px;
`;

export const StyledTd = styled.td`
     text-align: right;
`;
export const StyledTdNoCharge = styled.td`
     text-align: center;
`;
export const FlexContainerPayment = styled.div`
     display: flex;
     margin-bottom: 10px;
`;

export const FormikControlInput = styled(Field)`

     min-width: 200px;
`;
