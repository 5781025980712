import { FormikProvider } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer, RowFluidContainer } from '../../../../../Components/Formik/StyledComponents';
import { FreeTextFilter } from '../../../../../Types';
import { FilterKeys } from '../Types';
import { useFormik } from "formik";
import { GroupFreeTextSearchBy } from '../../../Members/Constants';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';

const FilterBox: React.FC<FilterBoxProps> = ({ filter, onSearch, onClearSearch }) => {
  const initialValues: FreeTextFilter = { 
    FreeText: filter?.FreeText ?? "",
    FreeTextSearchBy: filter?.FreeTextSearchBy ?? FreeTextSearchByOptions.Username
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSearch(values);
    }
  });

  return (
    <FormContainer
      onSubmit={formik.handleSubmit}
    >
      <FormikProvider value={formik} >
        <Collapsible open={true} trigger={collasibleElement}>
          <Container>
            <Row>
              <Col>
                <FormikControl
                  name={FilterKeys.FreeText}
                  label="Freetext"
                  title="Searches by ID, UserName or Email"
                />
              </Col>
              <Col>
                  <FormikControl
                    name={FilterKeys.FreeTextSearchBy}
                    label="Freetext Type"
                    control={FormControlKeys.Select}
                    options={GroupFreeTextSearchBy}
                  />
              </Col>
            </Row>
            <Row>
              <br />
            </Row>
            <RowFluidContainer>
              <Col>
                <FormikControl
                  name="Search"
                  label="Search"
                  type="submit"
                  className="btn btn-light span2"
                  control={FormControlKeys.Button}
                />
                <span style={{ paddingRight: 10 }}></span>
                <FormikControl
                  name="Clear"
                  label="Clear"
                  onClick={() => {
                    formik.setValues(initialValues);
                    onClearSearch();
                  }}
                  className="btn btn-light span2"
                  control={FormControlKeys.Button}
                />
              </Col>
            </RowFluidContainer>
          </Container>
        </Collapsible>
      </FormikProvider>
    </FormContainer>
  );
};

export default FilterBox;

export const collasibleElement: React.ReactElement = (
  <div>
    <span style={{ marginLeft: "10px" }}>Filter by</span>
    <BsChevronDown style={{ float: "right", margin: "0 12px" }} />
  </div>
);

export interface FilterBoxProps {
  filter?: FreeTextFilter;
  onSearch: Function;
  onClearSearch: Function;
}

