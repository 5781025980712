import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { AbortJobRequest } from '../../../../../../Models/Jobs/EditJob/AbortJobRequest';
import { InlineBlockColMd10Section } from '../../../../../../Components/Formik';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import styled from 'styled-components';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import { JobViewModel } from "../../../../../../Models/Jobs/EditJob";
import { Col } from 'react-bootstrap';
import { MotabilityAbortReason } from "../../../../../../Utils/Enums";
import { convertEnumToSelectListWithIntegerValue  } from "../../../../../../Utils";

const validationSchema = Yup.object().shape({
    ContactNameOnSite: Yup.string().required('Please enter the name of the person the supplier spoke to on site'),
    ContactTelephoneNumberOnSite: Yup.string().required('Please enter the contact phone number the person the supplier spoke to on site'),
    ArrivalDateOnSite: Yup.date()
        .required('Please enter the date the supplier arrived on site')
        .max(new Date(), "The Arrival date on site must not be greater than today"),
    ArrivalTimeOnSite: Yup.string().required('Please enter the time the supplier arrived on site'),
    Address1: Yup.string().required('Please enter line 1 of the address the supplier arrived at'),
    City: Yup.string().required('Please enter the city of the address the supplier arrived at'),
    PostCode: Yup.string().required('Please enter the post code of the address the supplier arrived at'),
    AbortReason: Yup.string().required('Please enter an abort reason')
});

type FormValues = {
    ContactNameOnSite: string | null;
    ContactTelephoneNumberOnSite: string | null;
    ArrivalDateOnSite: Date | null;
    ArrivalTimeOnSite: string | null;
    Address1: string | null;
    Address2: string | null;
    City: string | null;
    County: string | null;
    PostCode: string | null;
    TyreManufacturer: string | null;
    TyreModel: string | null;
    TyreSpecification: string | null;
    AbortReason: MotabilityAbortReason | null;
    AdditionalComments: string | null;
};

const today = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate(), 23, 59, 59);

const AbortJobModal: React.FC<{ 
    job: JobViewModel;
    closeModal: Function;
    postAbortJob: Function;
}> = (props) : JSX.Element => {

    const job = props.job;
    const closeModal = props.closeModal;
    const postAbortJob = props.postAbortJob;

    const addressParts = job.CollectionAddress?.split('\n') ?? [];
    const address1 = addressParts[0] ?? '';
    const address2 = addressParts.length > 1 ? addressParts.slice(1).join(' ') : '';
    
    const formik = useFormik<FormValues>({
        initialValues: {
            ContactNameOnSite: '',
            ContactTelephoneNumberOnSite: '',
            ArrivalDateOnSite: today,
            ArrivalTimeOnSite: '',
            Address1: address1,
            Address2: address2,
            City: job.CollectionTown ?? '',
            County: '',
            PostCode: job.CollectionPostCode ?? '',
            TyreManufacturer: '',
            TyreModel: '',
            TyreSpecification: '',
            AbortReason: null,
            AdditionalComments: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            if (!values.ContactNameOnSite) {
                setErrors({ ContactNameOnSite: 'Contact Name On Site is required' });
            }

            if (!values.ContactTelephoneNumberOnSite) {
                setErrors({ ContactTelephoneNumberOnSite: 'Contact Telephone Number On Site is required' });
            }

            if (!values.ArrivalDateOnSite) {
                setErrors({ ArrivalDateOnSite: 'Arrival Date On Site is required' });
            }

            if (!values.ArrivalTimeOnSite) {
                setErrors({ ArrivalTimeOnSite: 'Arrival Time On Site is required' });
            }

            if (!values.Address1) {
                setErrors({ Address1: 'Address 1 is required' });
            }

            if (!values.City) {
                setErrors({ City: 'City is required' });
            }

            if (!values.PostCode) {
                setErrors({ PostCode: 'Post Code is required' });
            }

            if (!values.AbortReason) {
                setErrors({ AbortReason: 'Please select an abort reason' });
            }

            if (values.AbortReason === MotabilityAbortReason.AbortReasonUnknown && !values.AdditionalComments) {
                setErrors({ AdditionalComments: 'More information about the abort needs to be provided in the additional comments as the abort reason is unknown' });
            }

            setSubmitting(false);

            let payload: AbortJobRequest = {
                JobId: job.Id,
                ContactNameOnSite: values.ContactNameOnSite ?? '',
                ContactTelephoneNumberOnSite: values.ContactTelephoneNumberOnSite ?? '',
                ArrivalDateOnSite: values.ArrivalDateOnSite ?? new Date(),
                ArrivalTimeOnSite: values.ArrivalTimeOnSite ?? '',
                Address1: values.Address1 ?? '',
                Address2: values.Address2 ?? '',
                City: values.City ?? '',
                County: values.County ?? '',
                PostCode: values.PostCode ?? '',
                AbortReason: values.AbortReason ?? MotabilityAbortReason.AbortReasonUnknown,
                TyreManufacturer: values.TyreManufacturer,
                TyreModel: values.TyreModel,
                TyreSpecification: values.TyreSpecification,
                AdditionalComments: values.AdditionalComments
             };

            postAbortJob(payload);
            closeModal();
        },
        enableReinitialize: true,
   });

    return(
        <>
            <FormikProvider value={formik}>
                <Form>
                    <fieldset>
                        <InlineBlockColMd10Section>
                            <RowContainer>
                                <b>Contact Name On Site</b>
                                <FormikControl name='ContactNameOnSite' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>Contact Telephone Number On Site</b>
                                <FormikControl name='ContactTelephoneNumberOnSite' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>Arrival Date And Time On Site (HH:mm)</b>
                                <div>
                                    <FlexFieldContainer>
                                        <FormikControl name='ArrivalDateOnSite' type='date' />
                                    </FlexFieldContainer>
                                    <FlexFieldContainer>
                                        <FormikControl name='ArrivalTimeOnSite' type='time' />
                                    </FlexFieldContainer>
                                </div>
                            </RowContainer>

                            <RowContainer>
                                <b>Address 1</b>
                                <FormikControl name='Address1' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>Address 2</b>
                                <FormikControl name='Address2' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>City</b>
                                <FormikControl name='City' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>County</b>
                                <FormikControl name='County' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>Post Code</b>
                                <FormikControl name='PostCode' type='text' />
                            </RowContainer>

                            <RowContainer>
                                <b>Abort Reason</b>
                                <FormikControl
                                    control={FormControlKeys.Select}
                                    name='AbortReason'
                                    options={[
                                        { label: 'Please Select',  value: null },
                                        ...convertEnumToSelectListWithIntegerValue(MotabilityAbortReason)
                                    ]}
                                />
                            </RowContainer>

                            <RowContainer>
                                <b>Tyre Manufacturer (Optional)</b>
                                <FormikControl name='TyreManufacturer' type='text' placeholder='e.g: Michelin' />
                            </RowContainer>

                            <RowContainer>
                                <b>Tyre Model (Optional)</b>
                                <FormikControl name='TyreModel' type='text' placeholder='e.g: GoodGrip' />
                            </RowContainer>

                            <RowContainer>
                                <b>Tyre Specification (Optional)</b>
                                <FormikControl name='TyreSpecification' type='text' placeholder='e.g: 205 55 R16 91V' />
                            </RowContainer>

                            <RowContainer>
                                <b>Additional Comments</b>
                                <FormikControl name='AdditionalComments' type='textarea' placeholder='Additional information relating to the abort' />
                            </RowContainer>

                        </InlineBlockColMd10Section>
                    </fieldset>
                    <Col style={{ display: 'flex' }}>
                        <FormikControl label='Abort Job' control={FormControlKeys.Button} type='submit' />
                        <FormikControl label='Cancel' onClick={closeModal} control={FormControlKeys.Button} />
                    </Col>
                </Form>
            </FormikProvider>
        </>
    )
}

export default AbortJobModal;

const RowContainer = styled.div`
     display: grid;
     align-items: center;
     gap: 10px;
`;

const FlexFieldContainer = styled.span`
     display: inline-flex;
     align-items: center;
     margin-right: 10px;
     gap: 10px;
`;