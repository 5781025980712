import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { GetPreviousSupplierOfRouteResponseModel, PreviousSupplierSearchFilter } from "../../../../../Models/Jobs/EditJob";

export const useGetPreviousSupplyingMembersByRange = (
   searchFilter: PreviousSupplierSearchFilter,
  options?: any
): UseQueryResult<GetPreviousSupplierOfRouteResponseModel, any> => {
  
  const getPreviousSupplingMembersByRange = () => {
    if (searchFilter) {
      return agent.jobs.getGetPreviousSupplyingMembersByRange(searchFilter);
    }
  };

  return useQuery(["GetPreviousSupplyingMembers"], getPreviousSupplingMembersByRange, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
    ...options,
  });
};