import React from 'react';

export const Terms = () => {
  return (
    <>
      <div className="terms-page standard-page container-fluid">
        <div className="row hero-row">
          <div className="container">
            <div className="col-lg-12">
              <h1>TERMS</h1>

              <h2>
                HELPLINE{' '}
                <span className="helpline-phone-number">0208 551 1060</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row content-row">
          <div className="col-lg-12">
            <div className="container">
              <h1>TERMS</h1>

              <p>
                <strong>
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
                  THIS SITE
                </strong>
              </p>

              <p>
                <strong>Who we are and how to contact us</strong>
              </p>
              <p>
                Movex.co.uk is a site operated by Movex Logistics Limited. We
                are a limited company registered in England and Wales under
                company number 8113821 and have our registered office at Central
                House, Leeds Road, Rothwell, Leeds, LS26 0JE. Our main trading
                address is Crate 15 Oakwood Hill Industrial Estate, Loughton,
                IG10 3TZ. Our VAT number is 140695708.
              </p>
              <p>
                To contact us, please email{' '}
                <a href="mailto:info@movex.co.uk">info@movex.co.uk</a> or
                telephone 0208 551 1060.
              </p>

              <p>
                <strong>Our site is for businesses only</strong>
              </p>
              <p>
                By using our site, you warrant that you are acting as a business
                and not a consumer.
              </p>

              <p>
                <strong>By using our site you accept our terms</strong>
              </p>
              <p>
                By using our site, you confirm that you accept our terms and
                agree to comply with them.
              </p>
              <p>
                You are also responsible for ensuring that all persons who
                access our site through your internet connection are aware of
                our terms, and that they comply with them.
              </p>
              <p>
                If you do not agree to our terms, you must not use our site.
              </p>
              <p>
                We recommend that you print a copy of our terms for future
                reference.
              </p>

              <p>
                <strong>Which terms apply?</strong>
              </p>
              <p>
                The following terms apply to your use of the site (each and
                together “our terms”):
              </p>
              <ul>
                <li>
                  Our <a href="#platform-terms-of-use">Platform Terms of Use</a>
                  , which sets out the rules for using our site.
                </li>
                <li>
                  Our <a href="#acceptable-use-policy">Acceptable Use Policy</a>
                  , which sets out the permitted uses and prohibited uses of our
                  site.
                </li>
                <li>
                  Our <a href="/privacy">Privacy Policy</a>, which sets out the
                  terms on which we process any personal data we collect from
                  you or that you provide to us. By using our site, you consent
                  to such processing and you warrant that you are legally
                  entitled to provide that data.
                </li>
                <li>
                  Our <a href="/privacy/#cookies">Cookie Policy</a>, which sets
                  out information about the cookies on our site.
                </li>
              </ul>
              <p>
                If you arrange the provision and/or receipt of transport
                services via our site, our{' '}
                <a href="/terms-standard/">
                  Standard Terms and Conditions of Business
                </a>{' '}
                will apply to the arrangement, which set out the contractual
                terms between transport supplier and user and explain each
                party’s rights and obligations. They also set out Movex’s rights
                and its duties to transport suppliers and users.
              </p>

              <p>
                <strong>We may make changes to these terms</strong>
              </p>
              <p>
                We amend our terms from time to time. Every time you wish to use
                our site, please check our terms to ensure you understand the
                terms that apply at that time.
              </p>

              <p>
                <strong>Which country's laws apply to any disputes?</strong>
              </p>
              <p>
                All our terms, their subject matter and their formation (and any
                non-contractual disputes or claims) are governed by English law.
                We both agree to the exclusive jurisdiction of the courts of
                England and Wales.
              </p>

              <hr className="dash" />

              <p id="platform-terms-of-use">
                <strong>PLATFORM TERMS OF USE</strong>
              </p>
              <p>
                <strong>We may make changes to our site</strong>
              </p>
              <p>
                We may update and change our site from time to time to reflect
                changes to our services, our users' needs, applicable
                regulations and our business priorities.
              </p>

              <p>
                <strong>Availability</strong>
              </p>
              <p>
                We do not guarantee that our site, or any content on it, will
                always be available, uninterrupted or error free. We may suspend
                or withdraw or restrict the availability of all or any part of
                our site for business and operational reasons. We will try to
                give you reasonable notice of any suspension or withdrawal.
              </p>

              <p>
                <strong>Our site is only for users in the UK</strong>
              </p>
              <p>
                Our site is directed to users in the United Kingdom. We do not
                represent that content available on or through our site is
                appropriate for use or available in other locations.
              </p>

              <p>
                <strong>You must keep your account details safe</strong>
              </p>
              <p>
                If you choose, or you are provided with, a username, password or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential. You
                must not disclose it to any third party.
              </p>
              <p>
                We have the right to disable any username or password, whether
                chosen by you or allocated by us, at any time, if in our
                reasonable opinion you have failed to comply with any of the
                provisions of our terms.
              </p>
              <p>
                If you know or suspect that anyone other than you knows your
                username or password, you must promptly notify us by email to{' '}
                <a href="mailto:info@movex.co.uk">info@movex.co.uk</a>.
              </p>

              <p>
                <strong>How you may use material on our site</strong>
              </p>
              <p>
                We are the owner or the licensee of all intellectual property
                rights in our site, and in the material published on it. Those
                works are protected by copyright laws and treaties around the
                world. All such rights are reserved.
              </p>
              <p>
                You may print off one copy, and may download extracts, of any
                page(s) from our site for your internal business use only.
              </p>
              <p>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded in any way, and you must not
                use any illustrations, photographs, video or audio sequences or
                any graphics separately from any accompanying text.
              </p>
              <p>
                Our status as the authors of content on our site must always be
                acknowledged.
              </p>
              <p>
                You must not use any part of the content on our site for
                commercial purposes without obtaining a licence to do so from us
                or our licensors.
              </p>

              <p>
                <strong>Do not rely on information on this site</strong>
              </p>
              <p>
                The content on our site is provided for general information
                only. It is not intended to amount to advice on which you should
                rely. You must obtain professional or specialist advice before
                taking, or refraining from, any action on the basis of the
                content on our site.
              </p>
              <p>
                Although we make reasonable efforts to update the information on
                our site, we make no representations, warranties or guarantees,
                whether express or implied, that the content on our site is
                accurate, complete or up to date.
              </p>

              <p>
                <strong>We are not responsible for websites we link to</strong>
              </p>
              <p>
                Where our site contains links to other sites and resources
                provided by third parties, these links are provided for your
                information only. Such links should not be interpreted as
                approval by us of those linked websites or information you may
                obtain from them.
              </p>
              <p>
                We have no control over the contents of those sites or
                resources.
              </p>

              <p>
                <strong>User-generated content is not approved by us</strong>
              </p>
              <p>
                This website may include information and materials uploaded by
                other users of the site, including in the description of a job.
                This information and these materials have not been verified or
                approved by us. The views expressed by other users on our site
                do not represent our views or values.
              </p>
              <p>
                If you wish to complain about information and materials uploaded
                by other users please email us at{' '}
                <a href="mailto:info@movex.co.uk">info@movex.co.uk</a>.
              </p>

              <p>
                <strong>
                  Our responsibility for loss or damage suffered by you
                </strong>
              </p>
              <ul>
                <li>
                  We do not exclude or limit in any way our liability to you
                  where it would be unlawful to do so. This includes liability
                  for death or personal injury caused by our negligence or the
                  negligence of our employees, agents or subcontractors and for
                  fraud or fraudulent misrepresentation.
                </li>
                <li>
                  Different limitations and exclusions of liability will apply
                  to liability arising as a result of the:
                  <ul>
                    <li>
                      provision of subscription services via our site; and/or
                    </li>
                    <li>
                      provision and/or receipt of transport services arranged
                      via our site,
                    </li>
                  </ul>
                  which are set out in our{' '}
                  <a href="/terms-standard/">
                    Standard Terms and Conditions of Business
                  </a>
                  .
                </li>

                <li>
                  We exclude all implied conditions, warranties, representations
                  or other terms that may apply to our site or any content on
                  it.
                </li>
                <li>
                  We will not be liable to you for any loss or damage, whether
                  in contract, tort (including negligence), breach of statutory
                  duty, or otherwise, even if foreseeable, arising under or in
                  connection with: use of, or inability to use, our site; or use
                  of or reliance on any content displayed on our site.
                </li>
                <li>
                  In particular, we will not be liable for: loss of profits,
                  sales, business, or revenue; business interruption; loss of
                  anticipated savings; loss of business opportunity, goodwill or
                  reputation; or any indirect or consequential loss or damage.
                </li>
              </ul>

              <p>
                <strong>You give us a licence to use your content</strong>
              </p>
              <p>
                You retain all of your ownership rights in your content, but you
                are required to grant us and other users of our site a limited
                licence to use, store and copy that content and to distribute
                and make it available to third parties.
              </p>
              <p>
                We also have the right to disclose your identity to any third
                party who is claiming that any content posted or uploaded by you
                to our site constitutes a violation of their intellectual
                property rights, or of their right to privacy.
              </p>
              <p>
                We have the right to remove any content you upload to, or
                correspondence you send via, our site if in our opinion it does
                not comply with the content standards set out in our{' '}
                <a href="#acceptable-use-policy">Acceptable Use Policy</a>.
              </p>
              <p>
                You are solely responsible for securing and backing up your
                content and correspondence.
              </p>

              <p>
                <strong>We are not responsible for viruses</strong>
              </p>
              <p>
                We do not guarantee that our site will be secure or free from
                bugs or viruses.
              </p>
              <p>
                You are responsible for configuring your information technology,
                computer programmes and platform to access our site. You should
                use your own virus protection software.
              </p>

              <p>
                <strong>Rules about linking to our site</strong>
              </p>
              <p>
                You may link to our home page, provided you do so in a way that
                is fair and legal and does not damage our reputation or take
                advantage of it.
              </p>
              <p>
                You must not establish a link in such a way as to suggest any
                form of association, approval or endorsement on our part where
                none exists.
              </p>
              <p>
                You must not establish a link to our site in any website that is
                not owned by you.
              </p>
              <p>
                Our site must not be framed on any other site, nor may you
                create a link to any part of our site other than the home page.
              </p>
              <p>
                We reserve the right to withdraw linking permission without
                notice.
              </p>
              <p>
                The website in which you are linking must comply in all respects
                with the content standards set out in our{' '}
                <a href="#acceptable-use-policy">Acceptable Use Policy</a>.
              </p>

              <hr className="dash" />

              <p id="acceptable-use-policy">
                <strong>ACCEPTABLE USE POLICY</strong>
              </p>
              <p>
                <strong>Prohibited uses</strong>
              </p>
              <p>
                You may use our site only for lawful purposes. You may not use
                our site:
              </p>
              <ul>
                <li>
                  in any way that breaches any applicable local, national or
                  international law or regulation;
                </li>
                <li>
                  in any way that is unlawful or fraudulent, or has any unlawful
                  or fraudulent purpose or effect;
                </li>
                <li>
                  to send, knowingly receive, upload, download, use or re-use
                  any material which does not comply with our content standards,
                  outlined below;
                </li>
                <li>
                  to transmit, or procure the sending of, any unsolicited or
                  unauthorised advertising or promotional material or any other
                  form of similar solicitation (spam); or
                </li>
                <li>
                  to knowingly transmit any data, send or upload any material
                  that contains viruses, Trojan horses, worms, time-bombs,
                  keystroke loggers, spyware, adware or any other harmful
                  programs or similar computer code designed to adversely affect
                  the operation of any computer software or hardware.
                </li>
              </ul>
              <p>You also agree:</p>
              <ul>
                <li>
                  not to reproduce, duplicate, copy or re-sell any part of our
                  site in contravention of the provisions of our{' '}
                  <a href="#platform-terms-of-use">Platform Terms of Use</a>;
                  and
                </li>
                <li>
                  not to access without authority, interfere with, damage or
                  disrupt: any part of our site; any equipment or network on
                  which our site is stored; any software used in the provision
                  of our site; or any equipment or network or software owned or
                  used by any third party.
                </li>
              </ul>

              <p>
                <strong>Content standards</strong>
              </p>
              <p>
                These content standards apply to any and all material which you
                upload to and transmit via our site (“Contribution”).
              </p>
              <p>
                The Content Standards must be complied with in spirit as well as
                to the letter. The standards apply to each part of any
                Contribution as well as to its whole.
              </p>
              <p>
                Movex will determine, in its discretion, whether a Contribution
                breaches the Content Standards.
              </p>
              <p>
                A Contribution must be accurate (where it states facts),
                genuinely held (where it states opinions) and comply with the
                law applicable in England and Wales and in any country from
                which it is posted.
              </p>
              <p>A Contribution must not:</p>
              <ul>
                <li>
                  be defamatory of any person, obscene, offensive, hateful or
                  inflammatory;
                </li>
                <li>
                  promote sexually explicit material, violence or illegal
                  activity;
                </li>
                <li>
                  promote discrimination based on race, sex, religion,
                  nationality, disability, sexual orientation or age;
                </li>
                <li>
                  infringe any copyright, trade mark or other intellectual
                  property rights of any third party;
                </li>
                <li>be likely to deceive any person;</li>
                <li>
                  breach any legal duty owed to a third party, such as a
                  contractual duty or a duty of confidence;
                </li>
                <li>
                  be threatening, abuse or invade another's privacy, or cause
                  annoyance, inconvenience or needless anxiety;
                </li>
                <li>
                  be likely to harass, upset, embarrass, alarm or annoy any
                  other person;
                </li>
                <li>
                  impersonate any person, or misrepresent your identity or
                  affiliation with any person;
                </li>
                <li>
                  give the impression that the Contribution emanates from Movex,
                  if this is not the case; or
                </li>
                <li>
                  advocate, promote or incite any party to commit, or assist any
                  unlawful or criminal act.
                </li>
              </ul>

              <p>
                <strong>Breach of this policy</strong>
              </p>
              <p>
                When we consider that a breach of this acceptable use policy has
                occurred, we may take such action as we deem appropriate,
                including immediate, temporary or permanent withdrawal of your
                right to use our site and closure of your account.
              </p>
              <p>
                We may also bring legal proceedings against you for
                reimbursement of all our losses resulting from the breach as
                well as disclosure of information in respect of the breach to
                law enforcement authorities as we reasonably feel is necessary
                or as required by law.
              </p>
              <p>
                We exclude our liability for all action we may take in response
                to breaches of this acceptable use policy. The actions we may
                take are not limited to those described above, and we may take
                any other action we reasonably deem appropriate.
              </p>

              <hr className="dash" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
