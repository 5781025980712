import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { PartnerResponseModel } from '../../../../../Models/Partners/PartnerResponseModel';

export const useGetPartner = (partnerID: number,
  options?: any
): UseQueryResult<PartnerResponseModel, any> => {

  const getPartner = () => {
    if (partnerID) {
      return agent.partners.getPartner(partnerID)
    }
  };

  return useQuery(['GetPartner', partnerID], getPartner, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
