import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddMembersBlockModel } from '../../../../../../../Models/Members/EditMember/MemberBlocks';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const usePutMemberBlock = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddMembersBlockModel> => {
  const putAddMembersBlock = (payload: AddMembersBlockModel) => {
    return agent.members.putAddMembersBlock(payload);
  };

  return useMutation(putAddMembersBlock);
};
