import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
     Alert,
     ConfirmDialog,
     ConfirmDialogProps,
     Loading,
     ShowPleaseWait,
     useAlert,
} from '../../../../Components';
import {
     MainContentInnerContainer,
     MainContentOuterContainer,
     RowFluidContainer,
     Span8Offset2Container,
} from '../../../../Components/Formik';
import { ActivityHistory, JobViewModel } from '../../../../Models/Jobs/EditJob';
import { convertNullsToEmptyStrings } from '../../../../Utils';
import { JobStatus, JobType, ProofType } from '../../../../Utils/Enums';
import { useGetJob } from './Hooks/useGetJob';
import { usePutUpdateJobNewProof } from './Hooks/usePutUpdateJobNewProof';
import Section9ActivityHistory from './Sections/ActivityHistory';
import { emptyValues } from './Sections/emptyData';
import Section3JobDetails from './Sections/JobDetails';
import SectionNoteForAdmin from './Sections/NoteForAdmin';
import SectionPlacedQuotesAcceptedFixedPrices from './Sections/PlacedQuotesAcceptedFixedPrices';
import SectionPlaceQuoteOrAcceptFixedPrices from './Sections/PlaceQuoteOrAcceptFixedPrices';
import Section4PreviousSupplierOfRoute from './Sections/PreviousSupplierOfRoute';
import Section1QuickLinks from './Sections/QuickLinks';
import Section6RequestHistory from './Sections/RequestHistory';
import Section2StatusInformation from './Sections/StatusInformation';
import SupplierInformation from './Sections/SupplierInformation';
import Section5SupplyingMembersNearby from './Sections/SupplyingMembersNearby';
import PocSectionUploadProof from './Sections/UploadProof/PocUploadProof';
import PodSectionUploadProof from './Sections/UploadProof/PodUploadProof';
import SectionVehicles from './Sections/Vehicles';

import { JobIDParamType } from './Types';

const ViewJob = () => {
     const { jobID: paramJObID } = useParams<JobIDParamType>();
     const jobID = paramJObID ? parseInt(paramJObID) : 0;
     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     convertNullsToEmptyStrings(emptyValues);

     /** useState */
     const [jobData, setJobData] = useState<JobViewModel>(emptyValues);
     const [needToBeRefetched, setNeedToBeRefetched] = useState<Boolean>(false);
     const [isLoading, setIsLoading] = useState<Boolean>(false);

     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });

     const showAlert = (header: string, body: string): void => {
          setAlert({
               ...alert,
               show: true,
               header: header,
               body: body,
          });
     };

     const showLoading = (visible: Boolean): void => {
          setIsLoading(visible);
     };

     const onRefreshProofList = () => {
          refetchGetJob();
     }

     /** Hook */
     const { alert, setAlert } = useAlert();

     const {
          isLoading: isGetJobLoading,
          data: getJobData,
          refetch: refetchGetJob,
          error: getJobError,
     } = useGetJob(jobID);

     const {
          mutate: uploadJobNewProof,
          data: updateJobNewProofData,
          error: updateJobNewError,
     } = usePutUpdateJobNewProof();

     /** useEffects */
     useEffect(() => {
          if (getJobData) {
               convertNullsToEmptyStrings(getJobData);
               setJobData(getJobData.Job);
          }
     }, [getJobData, jobID]);

     useEffect(() => {
          refetchGetJob();
          setNeedToBeRefetched(false);
     }, [needToBeRefetched]);

     useEffect(() => {
          if (updateJobNewProofData) showAlert('Proof Updated', 'Proof Updated Successfully');
          showLoading(false);
          onRefreshProofList();
     }, [updateJobNewProofData]);

     useEffect(() => {
          if (updateJobNewError){
               showAlert( 
                    updateJobNewError?.Subject ?? 'Update Proof Error', 
                    updateJobNewError?.Description ?? 'Error when updating proof');
               }
          showLoading(false);
     }, [updateJobNewError]);

     useEffect(() => {
          if (getJobError){
               showAlert( 
                    updateJobNewError?.Subject, 
                    getJobError?.Description);
               }
     }, [getJobError]);

     /** Methods */
     const handleConfirmDialogContent = (dialogProps: ConfirmDialogProps) => {
          setConfirmDialogContent(dialogProps);
     };

     return (
          <>
               <MainContentOuterContainer>
                    <MainContentInnerContainer>
                         <RowFluidContainer>
                              <Span8Offset2Container>
                                   {isGetJobLoading && <Loading loading={true} />}
                                   {isLoading && <ShowPleaseWait show={true} />}
                                   <Alert {...alert} />
                                   <ConfirmDialog {...confirmDialogContent}></ConfirmDialog>
                                   <Section1QuickLinks />
                                   <Section2StatusInformation 
                                        job={jobData} 
                                        showAlert={showAlert}
                                        refetchJob={refetchGetJob}
                                        vehicles={getJobData?.Vehicles ?? []}
                                   />
                                   <Section3JobDetails
                                        job={jobData}
                                        suppliers={getJobData?.SelectedSuppliers ?? []}
                                        onConfirmDialogClose={onConfirmDialogClose}
                                        onHandleDialogProps={handleConfirmDialogContent}
                                        showAlert={showAlert}
                                   />
                                   <Section4PreviousSupplierOfRoute 
                                        jobID={jobData.Id}
                                        job={jobData} 
                                   />
                                   <Section5SupplyingMembersNearby job={jobData} />
                                   {getJobData?.RequestHistory && (
                                        <Section6RequestHistory
                                             jobID={jobData.Id}
                                             job={jobData}
                                             requestHistory={getJobData?.RequestHistory}
                                             showAlert={showAlert}
                                        />
                                   )}
                                   <PocSectionUploadProof
                                        proofType={ProofType.POC}
                                        uploadNewProof={uploadJobNewProof}
                                        pocList={getJobData?.Poc}
                                        job={jobData}
                                        showLoading={showLoading}
                                        refreshProofList={onRefreshProofList}
                                   />
                                   <PodSectionUploadProof
                                        proofType={ProofType.POD}
                                        uploadNewProof={uploadJobNewProof}
                                        podList={getJobData?.Pod}
                                        job={jobData}
                                        showLoading={showLoading}
                                        refreshProofList={onRefreshProofList}
                                   />
                                   {getJobData && (
                                        <Section9ActivityHistory
                                             jobID={jobData?.ID}
                                             activityHistoryList={
                                                  getJobData.JobsActivityHistory
                                                       ? getJobData.JobsActivityHistory
                                                       : ([] as ActivityHistory[])
                                             }
                                        />
                                   )}

                                   {getJobData?.Vehicles && (
                                        <SupplierInformation
                                             job={jobData}
                                             vehicles={getJobData?.Vehicles}
                                        />
                                   )}
                                   {getJobData?.Vehicles && (
                                        <SectionVehicles
                                             jobID={jobData?.ID}
                                             vehicles={getJobData?.Vehicles}
                                        />
                                   )}

                                   {getJobData &&
                                        (((jobData?.JobType === JobType.RequestQuote ||
                                             jobData?.JobType === JobType.FixedPrice ||
                                             jobData?.JobType === JobType.Both) &&
                                             jobData.JobStatus === JobStatus.PendingQuotes) ||
                                             (jobData?.JobType === JobType.GuaranteedPrice &&
                                                  jobData?.JobStatus === JobStatus.BookedJob)) && (
                                             <SectionPlaceQuoteOrAcceptFixedPrices
                                                  job={jobData}
                                                  showAlert={showAlert}
                                                  refetchJob={refetchGetJob}
                                             />
                                        )}
                                   {getJobData?.Quotes && (
                                        <SectionPlacedQuotesAcceptedFixedPrices
                                             job={jobData}
                                             quotes={getJobData?.Quotes}
                                             vehicles={getJobData?.Vehicles}
                                             showAlert={showAlert}
                                             refetchJob={refetchGetJob}
                                        />
                                   )}
                                   <SectionNoteForAdmin job={jobData} showAlert={showAlert} />
                              </Span8Offset2Container>
                         </RowFluidContainer>
                    </MainContentInnerContainer>
               </MainContentOuterContainer>
          </>
     );
};

export default ViewJob;
