import agent from "../../../../../../Axios/Agent";
import { downloadProofFile } from "../../../../../../Utils/BlobStorageServiceUpload";

export function getExtensionFromMimeType(mimeType: string, fileName: string): string {
    switch (mimeType) {
        case 'image/jpeg': return 'jpg';
        case 'image/png': return 'png';
        case 'application/pdf': return 'pdf';
        default: 
            const fileExtension = fileName.split('.').pop()?.toLowerCase() || 'png';
            return fileExtension;
    }
}

export const inspectionReportDownloadPOC = (Id: number, Name: string) => {
    agent.inspectionReport.getInspectionReportDownloadPOC(Id).then((pocResponse) => {
         downloadProofFile(Name, pocResponse.Base64FileString, pocResponse.MimeType);
    });
};

export const inspectionReportDownloadArchivedPOC = (Id: number, Name: string) => {
    agent.inspectionReport.getInspectionReportDownloadArchivedPOC(Id).then((pocResponse) => {
         downloadProofFile(Name, pocResponse.Base64FileString, pocResponse.MimeType);
    });
};

export const inspectionReportDownloadPOD = (Id: number, Name: string) => {
    agent.inspectionReport.getInspectionReportDownloadPOD(Id).then((pocResponse) => {
         downloadProofFile(Name, pocResponse.Base64FileString, pocResponse.MimeType);
    });
};

export const inspectionReportDownloadArchivedPOD = (Id: number, Name: string) => {
    agent.inspectionReport.getInspectionReportDownloadArchivedPOD(Id).then((pocResponse) => {
         downloadProofFile(Name, pocResponse.Base64FileString, pocResponse.MimeType);
    });
};