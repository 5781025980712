import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormControlKeys } from '../../../../Components/FormControls';
import FormikControl from '../../../../Components/Formik/FormikControl';
import AddTaskForm from './AddTaskForm';

const taskTypeOptions = [
     { label: 'Select Task Type', value: '0' },
     { label: 'Additional Job Costs', value: '1' },
];

const CreateNewAdminTask: React.FC = () => {
     const formik = useFormik({
          initialValues: { TaskType: '0' },
          validationSchema: Yup.object({
               taskType: Yup.string().required('Task Type is required'),
          }),
          onSubmit: (values: any) => {
               console.log(values);
          },
     });

     return (
          <>
               <FormikProvider value={formik}>
                    <Form>
                         <div className='control-group'>
                              <label htmlFor='taskType'>Task Type</label>
                              <FormikControl
                                   control={FormControlKeys.Select}
                                   name={'TaskType'}
                                   options={taskTypeOptions}
                              />
                         </div>
                    </Form>
               </FormikProvider>
               {formik.values.TaskType !== '0' && <AddTaskForm />}
          </>
     );
};

export default CreateNewAdminTask;
