import React from 'react';

export const TermsInstantPrices = () => {
  return (
    <div className="terms-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>TERMS</h1>

            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h2>
          </div>
        </div>
      </div>

      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>TERMS</h1>

            <p>
              <strong>
                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
                THIS SITE
              </strong>
            </p>

            <p>
              <strong>Who we are and how to contact us</strong>
            </p>
            <p>
              Movex.co.uk is a site operated by Movex Logistics Limited. We are
              a limited company registered in England and Wales under company
              number 8113821 and have our registered office at Central House,
              Leeds Road, Rothwell, Leeds, LS26 0JE. Our main trading address is
              Crate 15 Oakwood Hill Industrial Estate, Loughton, IG10 3TZ. Our
              VAT number is 140695708.
            </p>
            <p>
              To contact us, please email{' '}
              <a href="mailto:info@movex.co.uk">info@movex.co.uk</a> or
              telephone 0208 551 1060.
            </p>

            <p>
              <strong>By using our site you accept our terms</strong>
            </p>
            <p>
              By using our site, you confirm that you accept our terms and agree
              to comply with them.
            </p>
            <p>
              You are also responsible for ensuring that all persons who access
              our site through your internet connection are aware of our terms,
              and that they comply with them.
            </p>
            <p>If you do not agree to our terms, you must not use our site.</p>
            <p>
              We recommend that you print a copy of our terms for future
              reference.
            </p>

            <p>
              <strong>We may make changes to these terms</strong>
            </p>
            <p>
              We amend our terms from time to time. Every time you wish to use
              our site, please check our terms to ensure you understand the
              terms that apply at that time.
            </p>

            <p>
              <strong>Which country's laws apply to any disputes?</strong>
            </p>
            <p>
              All our terms, their subject matter and their formation (and any
              non-contractual disputes or claims) are governed by English law.
              We both agree to the exclusive jurisdiction of the courts of
              England and Wales.
            </p>

            <hr className="dash" />

            <p id="private-customers">
              <strong>1. Private Customers</strong>
            </p>
            <ol type="a">
              <li>
                If We have confirmed that you are a Private Customer
                (non-Business Customer) when you enter into the Contract with
                Movex in accordance with these Terms, it will be on the basis
                that Movex are acting as a disclosed agent for Our Partners.
                Just to explain some the of the legal jargon, a “disclosed
                agent” means that We are not the principal and that by entering
                into the Contract with Us, you do so on the basis that you are
                aware that We are acting as agent for one of Our Partners who
                will be carrying out the Services for you. Movex are entitled
                and authorised by Our Partners to enter into this Contract with
                you on their behalf and to arrange the Services as an agent.
                This Contract governs your relationship with Movex and also with
                Our Partners.
              </li>
              <li>
                We will introduce you to Our Partners to try and enable you get
                the best Price and Services possible. By entering into these
                Terms you are acknowledging that you will enter into and be
                bound by the Terms
              </li>
              <li>
                By accepting that Movex is acting as agent for Our Partners and
                by entering into the Contract with Movex this will create a
                contract between you and Movex’s Partner (Partner Contract). The
                Partner Contract referred to in this clause 2.4(c) is for the
                provision of Services as set out in these Terms and you
                acknowledge and accept that the Partner Contract will be between
                you and the Partner and that Movex is not a party to the Partner
                Contract other than as expressly provided in these Terms. You
                also accept that Movex are simply accepting the Booking on your
                behalf and undertaking the Services, which they are expressly
                bound to provide under these Terms. Movex will ensure that Our
                Partners are also bound by the Terms that relate to them in the
                Contract.
              </li>
              <li>
                You also acknowledge and accept that Movex does not provide
                transportation (moving) services to Private Customers and that
                We are simply acting as agent (and intermediary) between you and
                Our Partner.
              </li>
            </ol>

            <p id="the-movex-quote">
              <strong>2. The Movex Quote.</strong>
            </p>
            <p>
              <strong>2.1</strong>
            </p>
            <ol type="a">
              <li>
                Movex will supply you with a number of Price Options which shall
                reflect the information provided by you when completing the
                Booking Request Form. The Price Options will vary depending upon
                when they are requested and the desired completion date.
              </li>
              <li>
                Movex will accept amendments up to 48 hours prior to the date
                that Services are required. However, you may incur additional
                charges in relation to such changes. If on arrival at the
                address provided by you, the Partner cannot gain access to the
                premises and you are uncontactable by telephone (using the
                number provided by you on the Booking Request Form), the Partner
                shall be entitled at its discretion to cease provision of the
                Services and/or cancel the Services if you cannot be reached and
                access gained. In this case, you shall not be entitled to
                receive a refund.
              </li>
              <li>
                For any amendments made less than 48 hours prior to the date
                that Services are required where We are able to accommodate
                these changes, you will incur additional charges.
              </li>
              <li>
                Additional charges may apply if the Services required and
                subsequently booked differ upon the arrival of the Partner.
              </li>
              <li>
                If the Services required are materially different to those in
                the Booking Request Form, the Partners shall have the right to
                terminate the Contract.
              </li>
              <li>
                Fees or taxes payable to any government bodies (if moving goods
                abroad), customs duties or port storage charges are not included
                in your quote. Movex is not responsible for such costs.
              </li>
              <li>
                Any additional charges incurred by you under these Terms if not
                already paid for by You shall be due and payable 5 days from the
                date that the Services were provided.
              </li>
              <li>
                If you have any questions or wish to discuss any of the
                additional charges referred to in this clause, please contact
                our Customer Care Team.
              </li>
            </ol>

            <p>
              <strong>2.2</strong> If Movex Does Not Accept Your Booking
              Request.
            </p>
            <p>
              If Movex is unable to find a Partner who will undertake the
              Services, or such Partner decides not to accept your Booking
              Request, you will be notified as soon as possible via email or by
              telephone. Movex will use its reasonable endeavours to try and
              find another Partner to undertake the Services on your behalf.
              Accepting your Booking Request is at Movex’s and / or its
              Partner’s discretion.
            </p>

            <p>
              <strong>2.3</strong> Your Job Number.
            </p>
            <p>
              Upon confirming your Booking Request, Movex will assign and notify
              you of the Job Number allocated to your Booking. Please reference
              this number when contacting Movex.
            </p>

            <p id="services-and-our-contract-with-you">
              <strong>3. Services and Our Contract With You</strong>
            </p>
            <p>
              <strong>3.1</strong> Your Booking.
            </p>
            <p>
              As set out above, Movex will supply you with Price Options
              dependent on your requirements notified in the Booking Request
              Form. The information you provide on the Booking Request Form is
              used to produce the Price Options, any alteration to the
              information supplied on the Booking Request Form may subsequently
              alter the Price. Accepting a particular Price Option that best
              suits your needs and supplying Movex with your payment details
              creates a contract between you and Movex and Our Partner
              (Contract) and grants Us authority to confirm to the relevant
              Partner that you have accepted the Price Option and that they will
              undertake the Services for you at the agreed Price.
            </p>
            <p>
              <strong>3.2</strong> Services.
            </p>
            <ol type="a">
              <li>
                The Partner will arrive at the address provided by you and on
                the date agreed in your Booking or any subsequent date and time
                notified by you to Movex and Movex confirm their acceptance of
                such change. Please note that any proposed change to the date
                and time that the Services are to be provided may result in a
                change to the Price, which will be notified to you in advance of
                the Services being undertaken.
              </li>
              <li>
                The Partner will load and deliver only the vehicle listed.
                Neither Movex nor the Partner is liable for goods loaded that
                were not included in your job listed or for any miscalculations
                in relation to Volume Size or Van Size on your Booking Request
                Form.
              </li>
              <li>
                Please note that if you wish to make any changes to the Booking,
                including on the day that the Services are undertaken, all
                payments must be made to Movex, Our Partners are not authorised
                to accept any payments directly.
              </li>
              <li>
                The Partner will deliver your vehicle in the same condition they
                were in when collected or made ready for transportation. If the
                Partner fails under this clause 3.2(d) please see clause 7 for
                information on liability for loss or damage. If you (acting
                reasonably) believe that damage to your vehicle was caused by
                the Partner, written notification including genuine evidence of
                the damage and loss of value is required within 24 hours of the
                Services being completed. Outside of this time frame, neither
                Movex nor the Partner is liable. Our liability and that of Our
                Partner is determined in accordance with clause 7.
              </li>
              <li>
                The Price Options shall include moving costs, staff costs and
                fuel unless otherwise stated.
              </li>
            </ol>
            <p>
              <strong>3.3</strong> Services Movex Do Not Provide.
            </p>
            <ol type="a">
              <li>
                Movex will not do anything to put Our Partners in danger. For
                example, Movex Partners will not enter any premises unless
                properly lit, floored and where safe access is provided.
              </li>
              <li>
                In order to adhere to permits and insurance, the Partners will
                not transport anything apart from vehicles.
              </li>
              <li>
                The Partners will not agree to do anything where they do not
                have the requisite qualifications.
              </li>
              <li>
                The Partners will not transport illegal, stolen or dangerous
                goods.
              </li>
              <li>
                Neither Movex nor its Partners will store any vehicles other
                than in accordance with clause 7.8 and clause 9 unless
                previously agreed in writing prior to or at the time of Booking
                unless subsequently agreed by Movex in writing. Additional
                charges apply for storage of vehicles and vary depending on
                duration.
              </li>
            </ol>
            <p>
              <strong>3.4</strong> The Service Provider.
            </p>
            <p>
              The Services will be provided by one of the Partners. The Partners
              are approved by Our dedicated Partner management team and are
              assessed by the Movex team for suitability and eligibility.
            </p>

            <p id="services outside of the united kingdom">
              <strong>4. Services Outside Of The United Kingdom</strong>
            </p>
            <p>
              <strong>4.1</strong> In addition to all other applicable terms,
              you must comply with the provisions of this clause 4.
            </p>
            <p>
              <strong>4.2</strong> You must, at your own cost, obtain all
              documents, permits, permissions, licences and customs documents
              necessary for Us to be able to undertake the Services required.
            </p>
            <p>
              <strong>4.3</strong> Movex’s Partners will not transport any
              vehicles that require a special licence or government permissions
              for export and/or import. Please see clause 7 and in particular
              clause 7.4 for limitation of liability.
            </p>
            <p>
              <strong>4.4</strong> The Movex Quote will include a time estimate
              for transit. Times can vary when vehicles are being transported.
              Movex will keep you updated with any material changes but will not
              be liable for any loss or damage which may occur as a result of
              delays in transit times, unless they are as a result of Movex or
              the Partner’s negligence.
            </p>

            <p id="customer obligations">
              <strong>5. Customer Obligations</strong>
            </p>
            <p>
              <strong>5.1</strong> By entering into the Contract you must comply
              with the following obligations. If you fail to comply with these
              obligations, neither Movex nor the Partner is liable to you for
              any losses incurred.
            </p>
            <ol type="a">
              <li>
                You must guarantee that all vehicles transported belong to you
                and that if anyone has legal interest in the vehicle, you have
                their permission to transport it.
              </li>
              <li>Payments are to be made paid in advance upon booking.</li>
              <li>
                You must provide Movex with your current telephone number and
                email address. If these contact details change, you must let
                Movex know immediately. Correct and precise addresses for pickup
                and delivery locations are compulsory.
              </li>
              <li>
                Suitable parking arrangements for the Partner’s vehicles will be
                made by you, at your own expense. These include but are not
                limited to, suspended bays, parking permits, width restrictions
                and parking distances from your premises. You will fully
                indemnify the Partner if fines or penalties are incurred as a
                result of your failure to make said arrangements.
              </li>
              <li>
                You will be present or represented upon collection, and delivery
                of your vehicle.
              </li>
              <li>
                Upon collection the Partner will present you with a Proof of
                Collection (POC), upon delivery the Partner will complete a
                Proof of Delivery (POD)
              </li>
              <li>
                A signature will be required for both POC and POD as
                confirmation that the Services were provided to a satisfactory
                standard.
              </li>
              <li>
                All payments must be made in accordance with this agreement and
                all overdue payments will incur interest on a daily basis that
                is calculated at 3% per annum above the base rate of Barclays
                Bank plc.
              </li>
            </ol>

            <p id="cancellation-and-postponement">
              <strong>6. Cancellation & Postponement</strong>
            </p>
            <p>
              <strong>6.1</strong> Consumers Statutory Cancellation Right.
            </p>
            <p>
              The Consumer Contracts (Information, Cancellation and Additional
              Charges) Regulations 2013 are not applicable to service contracts
              which provide for the transport of vehicle’s on a specific date or
              period for performance.
            </p>
            <p>
              <strong>6.2</strong> Postponement & Cancellation By The Customer.
            </p>
            <p>
              If you wish to postpone or cancel the Contract please notify Us,
              by email or telephone as soon as possible. If you cancel by
              telephone We will email to you confirming cancellation. All
              postponement and cancellation charges are charged as follows:
            </p>
            <ol type="a">
              <li>
                Domestic
                <ol type="i">
                  <li>
                    If a Domestic Service is booked and Movex receives notice
                    via email or by telephone no less than 48 hours before the
                    date that the Services are due to be provided, there will be
                    no charge. If you paid in full upon booking the Services,
                    you are entitled to a full refund.
                  </li>
                  <li>
                    If a Domestic Service is booked and Movex receives notice
                    via email or by telephone with less than 48 hours but not
                    less than 24 hours before the date that the Services are due
                    to be provided, you will incur a charge equal to 50% of the
                    Price. If you paid in full upon Booking the Services, you
                    are entitled to a 50% refund of the Price.
                  </li>
                </ol>
              </li>
              <li>
                International
                <ol type="i">
                  <li>
                    If International Services or Additional Domestic Services
                    are booked and Movex receive notice of cancellation via
                    email or by telephone no less than five business days before
                    the date that the Services are due to be provided, there
                    will be no charge. If you paid in full upon booking the
                    Services, you are entitled to a full refund.
                  </li>
                  <li>
                    If International Services or Additional Domestic Services
                    are booked and Movex receive notice of cancellation via
                    email or by telephone with less than five business days
                    before the date that the Services are due, you will not be
                    eligible for any refund. If you paid in full upon Booking
                    the Services, you are not entitled to a refund of the Price.
                  </li>
                </ol>
              </li>
              <li>
                If you postpone or cancel the Services (international and
                domestic) within 24 hours of the date that the Services are due
                to be provided, you will be charged the full Price. If you paid
                in full upon Booking the Services, you will not be entitled to a
                refund.
              </li>
              <li>
                If you postpone the Services and immediately re-book, it is at
                the discretion of Movex whether We reduce or waive some or all
                of the charges referred to in this clause 6.2.
              </li>
              <li>
                Urgent Bookings are non-refundable if cancelled or postponed.
              </li>
              <li>
                If you postpone or cancel the Services and as a result of this
                clause 6, additional amounts are owed to Movex, these will be
                due immediately.
              </li>
              <li>
                If you postpone or cancel the Services and have a dispute
                regarding a payment made to Movex, a charge pending or an issue
                relating to the Service, you must notify Us as soon as possible,
                and no later than seven days after the Services have been
                provided, by contacting our Customer Care Team.
              </li>
            </ol>
            <p>
              <strong>6.3</strong> Cancellation By Movex and its Partners.
            </p>
            <ol type="a">
              <li>
                If you breach any term of this Contract then Movex and its
                Partner are entitled to terminate the Contract.
              </li>
              <li>
                If the Partner is unable to perform the Services agreed upon
                Booking (or if We are unable to find an appropriate Partner to
                undertake the Services), whether through its own fault or as a
                result of something outside of Our control then Movex will
                notify you as soon as possible via email or by telephone.
              </li>
              <li>
                If you make a Booking, Movex shall use its reasonable endeavours
                to find an available Partner as soon as possible. If Movex is
                unable to do so, We are not in breach of the Contract but you
                will be entitled to a full refund.
              </li>
            </ol>
            <p>
              <strong>6.4</strong>
            </p>
            <ol type="a">
              <li>
                If Movex or its Partner cancel the Contract in accordance with
                clause 6.3(a) you will not be entitled to a refund. If Movex or
                its Partner cancel the Contract in accordance with clause
                6.3(b)-(c) then you are entitled to a full refund.
              </li>
              <li>
                If you cancel the Contract in accordance with clauses 6.2(a),
                6.2(b) or 6.2(d) any money that is due to be refunded to you
                will be transferred within five working days of our Customer
                Care Team receiving an email request for a Refund. Movex require
                confirmation of your bank account details within five days of
                submitting the request to us by email. The request must be
                submitted no later than seven days after the date when
                notification that you are cancelling the Contract was sent.
              </li>
            </ol>

            <p id="liability-for-loss-or-damage">
              <strong>7. Liability For Loss Or Damage</strong>
            </p>
            <p>
              <strong>7.1</strong> Movex shall have no liability to you for any
              loss or damage to your vehicle or premises or any other loss
              suffered by you arising out of the Contract.
            </p>
            <p>
              <strong>7.2</strong> The Partner is liable for damage to your
              vehicle and premises subject to you producing satisfactory
              evidence that the damage was caused by the Partner and not by you
              or a third party. Their liability to you is limited as follows:
            </p>
            <ol type="a">
              <li>
                If the Partner damages premises or property other than the
                vehicle they are contracted to transport as a result of their
                negligence or breach of contract, they are only liable for
                repairing the damaged area. This liability is in accordance with
                the limitations of clause 7.3.
              </li>
              <li>
                The Partner is not liable for any damage caused to any premises
                or vehicles if you ignore Movex or the Partner’s advice in
                relation to the Services.
              </li>
              <li>
                You must advise Movex by email or telephone, no later than seven
                days after completion of the Services, if any damage is caused
                to the premises or your vehicle upon collection, transportation
                or delivery. Movex nor the Partners are liable outside of this
                time unless permitted by law.
              </li>
            </ol>
            <p>
              <strong>7.3</strong> Neither Movex nor its Partners are liable
              for:
            </p>
            <ol type="a">
              <li>
                Damage caused as a result of your actions and/or your breach of
                these Terms;
              </li>
              <li>
                Perishable items, including but not limited to food and drink;
              </li>
              <li>Damage caused by fire;</li>
              <li>Normal wear and tear;</li>
              <li>
                Goods loaded and/or unloaded against Our or the Partners advice;
              </li>
              <li>
                Damage to any goods or premises not caused by Movex or the
                Partners;
              </li>
              <li>
                Damage to goods or premises caused by you or a third party when
                packing, loading or unloading them on and/or off Our vehicles or
                otherwise;
              </li>
              <li>Movex’s Partners do not transport live animals or plants.</li>
              <li>
                Loss incurred if any of your goods were already damaged or had
                an inherent defect;
              </li>
              <li>
                Damage if any of your goods are susceptible to damage including
                but not limited to breakage, internal spoilage, leakage,
                malfunctions;
              </li>
              <li>Damage to any collections or documentation.</li>
              <li>
                Personal items including but not limited to jewellery, handbags,
                clothes and hats.
              </li>
              <li>Business loss whatsoever if you are a Private Customer;</li>
              <li>
                Loss or damage which occurs after the vehicle has been delivered
                to you or your representative;
              </li>
              <li>
                Loss or damage not caused by Movex or Our Partners, employees,
                subcontractors or agents;
              </li>
              <li>Loss which is not reasonably foreseeable;</li>
              <li>
                Damage caused to your vehicle which are held by Our Partners in
                accordance with clause 7.9 and clause 9.
              </li>
            </ol>
            <p>
              <strong>7.4</strong> Transit Outside Of The United kingdom.
            </p>
            <p>
              If you require Services outside of the United Kingdom, in addition
              to the liability provisions in clause 7.1 to 7.4 (inclusive),
              neither Movex nor its Partners are liable for any goods
              confiscated, seized, removed or damaged by any customs authorities
              or any other government agency unless such damage, confiscation,
              seizure or removal was as a result of Our Partner’s negligence or
              breach of contract.
            </p>
            <p>
              <strong>7.5</strong> Events Outside Of Our Control.
            </p>
            <p>
              Neither Movex nor its Partners are liable for any damage or loss
              if any of the below occur:
            </p>
            <ol type="a">
              <li>
                Acts of God, including but not limited to flood, drought,
                earthquake or other natural disaster;
              </li>
              <li>Epidemic or pandemic;</li>
              <li>
                Acts of war, threat or preparation for war, riot, nuclear or
                chemical containment, change in the law or action taken by a
                government or public authority, collapse of buildings, fire,
                explosion or accident and any labour or trade dispute, strikes
                industrial action or lockouts;
              </li>
              <li>Delay in transit;</li>
              <li>
                Any events which can reasonably be considered outside of Our
                control.
              </li>
            </ol>
            <p>
              <strong>7.6</strong> Neither We nor Our Partners will exclude our
              liability for death or personal injury caused by Our or the
              Partner’s negligence, fraudulent misrepresentation or liability
              which under the laws of England and Wales may not be limited or
              excluded.
            </p>
            <p>
              <strong>7.7</strong> None of Our employees or subcontractors other
              than the Partner who undertook the Services, are independently
              liable for any loss, damage, mis-delivery, errors or omissions
              under the Contract.
            </p>
            <p>
              <strong>7.8</strong> Delays In Transit:
            </p>
            <ol type="a">
              <li>
                If there is a delay in transit or Movex’s Partner are unable to
                deliver your vehicle for reasons not caused by Our negligence or
                breach of Contract, it may store them. You may at Movex’s
                discretion be liable for any storage redelivery costs.
              </li>
              <li>
                Movex will provide you with an estimated time for collection and
                delivery based on information in Our possession. Times may vary
                due to events outside of Our control. Neither We nor Our Partner
                will be liable for any loss suffered by you as a result of this.
              </li>
            </ol>

            <p id="insurance-and-basic-compensation-cover">
              <strong>8. Insurance and Basic Compensation Cover</strong>
            </p>
            <p>
              <strong>8.1</strong> Insurance is covered by Our Partner, and is
              not liable by Movex.
            </p>
            <ol type="a">
              <li>
                cover up to a limit of £35,000 including fire and & full theft
                (full theft is the theft of your vehicle that Our Partner’s are
                transporting), any value of the vehicle over £35,000, please
                call Movex to increase the value of the cover.
              </li>
            </ol>
            <p>
              <strong>8.2</strong> You shall not take or fail to take any action
              or permit anything to occur that would entitle the Insurer not to
              pay any claim.
            </p>

            <p id="our-rights-to-hold-the-goods">
              <strong>9. Our Rights To Hold The Goods</strong>
            </p>
            <p>
              <strong>9.1</strong> The Partners have the right to hold your
              vehicle (lien) until all money owed under the Contract. This
              includes costs We have incurred for storage and legal costs.
            </p>
            <p>
              <strong>9.2</strong> If full payment of any monies owed by you to
              Movex is not received within 90 days We shall be entitled to sell
              the vehicle or deal with them as We think reasonable and apply any
              proceeds towards Our costs incurred and the money you owe to us.
            </p>

            <p id="complaints">
              <strong>10. Complaints</strong>
            </p>
            <p>
              <strong>10.1</strong> Complaints About Our Services.
            </p>
            <ol type="a">
              <li>
                If you have any complaints about Our Services, please contact
                our Customer Care Team who will endeavor to review your
                complaint and make any necessary actions within 7 days of the
                complaint being received.
              </li>
              <li>
                You can visit the citizens advice website on
                www.adviceguide.org.uk or call them on 0345 04 05 06 for a
                summary of your key legal rights.
              </li>
            </ol>

            <p id="how-we-may-use-your-personal-information">
              <strong>11. How We May Use Your Personal Information</strong>
            </p>
            <p>
              <strong>11.1</strong> Your Personal Information.
            </p>
            <p>We will use the personal information you provide to us:</p>
            <ol type="a">
              <li>
                to introduce Partners to supply the Services to you and to
                provide the Services expressly stated as being obligations of
                Movex in these Terms;
              </li>
              <li>to process your payment for the Services;</li>
              <li>
                if you agreed upon booking Our Services, to give you information
                about similar services that We provide.
              </li>
              <li>
                Please read our privacy policy to fully understand how we use
                your data.
              </li>
            </ol>
            <p>
              <strong>
                11.2 We will only give your personal information to other third
                parties where the law either requires or allows Us to do so.
              </strong>
            </p>

            <p id="other-important-terms">
              <strong>12. Other Important Terms</strong>
            </p>
            <p>
              <strong>12.1</strong> Transferring Your Rights.
            </p>
            <p>
              You may only transfer your rights or obligations under these Terms
              to another person if Movex agree to this in advance in writing.
            </p>
            <p>
              <strong>12.2</strong> Rights Under The Contract.
            </p>
            <p>
              The Contract is between you, Movex and Our Partner. No other
              person has rights to enforce any of its terms unless expressly
              provided for in these Terms.
            </p>
            <p>
              <strong>12.3</strong> The Law And The Contract.
            </p>
            <p>
              Each clause of these Terms operates separately. If any court or
              relevant authority decides that any of them are unlawful, the
              remaining clauses remain in full effect.
            </p>
            <p>
              <strong>12.4</strong> Enforcing The Contract.
            </p>
            <p>
              If We do not enforce these Terms immediately, or if you break the
              Contract and We delay taking steps against you, that will not
              prevent Us taking steps against you at a later date.
            </p>
            <p>
              <strong>12.5</strong> Applicable Laws To The Contract.
            </p>
            <p>
              These Terms are governed by English law and you can only bring
              legal proceedings in the English courts.
            </p>

            <hr className="dash" />
          </div>
        </div>
      </div>
    </div>
  );
};
