export type JobActivityHistoryItemModel = {
    TotalRecords: number,
    ID: number,
    AdminUserID: number,
    MemberID: number,
    ActivityDate: Date,
    ActivityType: number,
    ActivityNotes: string
    MemberUserName: string,
    AdminUserName: string
}

export const JobActivityHistoryItemKeys = {
    TotalRecords: "TotalRecords",
    Id: "Id",
    AdminUserID: "AdminUserID",
    MemberID: "MemberID",
    JobID: "JobID",
    Notes: "Notes",
    ActivityDate: "ActivityDate",
    ActivityType: "ActivityType",
    ActivityNotes: 'ActivityNotes',
    MemberUserName: "MemberUserName",
    AdminUserName: "AdminUserName",
}