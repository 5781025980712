import { GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Alert, Loading, useAlert } from "../../../../Components";
import { renderHeader } from "../../../../Components/PageHeader";
import { BillsFilter } from "../../../../Models/Billing/BillsFilter";
import { BillModelKeys } from "../../../../Models/Billing/BillViewModel";
import { BillsRequestParam } from "../../../../Models/Billing/Request";
import { Pager } from "../../../../Types";
import { getSorterFromSortModel, Page } from "../../../../Utils";
import { SqlStringMatchType } from "../../../../Utils/Enums";
import FilterBox from "./FilterBox";
import BillsGridView from "./Grid";
import { useGetBills } from "./Hooks";
import { useParams } from "react-router-dom";
import SearchResultPlaceholder from "../../../../Components/SharedComponents/girdComponents/SearchResultMessage";

const ViewBills: React.FC<any> = (): JSX.Element => {
  const { MemberId } = useParams<{ MemberId?: string }>();
  /** useStates */
  const [triggerDataFetch, setTriggerDataFetch] = useState<boolean>(false);
  const [filter, setFilter] = useState<BillsFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
    MemberId: MemberId ?? undefined,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: BillModelKeys.Id,
      sort: "desc",
    },
  ]);

  /** Methods */
  const getParams = () => {
    const params: BillsRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    if (filter.Freetext === '')
      filter.FreeTextSearchBy = undefined;
    return params;
  };

  const handleSearch = (filter: BillsFilter) => {
    setFilter(filter);
    setTriggerDataFetch(true);
  };

  const handleClearSearch = () => {
    setFilter({});
    setTriggerDataFetch(true);
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
    setTriggerDataFetch(true);
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
    setTriggerDataFetch(true);
  };

  const onSort = (sortModel: GridSortModel) => {
    setPager({ ...pager, CurrentPage: 1 });
    setSortModel(sortModel);
    setTriggerDataFetch(true);
  };

  /** Hooks */
  const {alert, setAlert} = useAlert();

  /** useEffects */
  const rqOption = { enabled: false };
  const {
    isLoading: isGetBillsLoading,
    data: billingRecords,
    refetch: fetchBills,
    error : getBillsError
  } = useGetBills(getParams(), rqOption);
  const isDataLoaded = billingRecords != null;
  const isResultEmpty = billingRecords?.Page == null;

  useEffect(() => {
    if(triggerDataFetch){
      fetchBills();
    }
  }, [triggerDataFetch, pager, filter, sortModel]);

  useEffect(() => {
    if (MemberId) {
      setFilter({ ...filter, MemberId: MemberId });
    }
    else {
      setFilter({ ...filter, MemberId: undefined });
    }
  }, [MemberId]);

  useEffect(() => {
    if (getBillsError) {
         setAlert({
              ...alert,
              show: true,
              header: getBillsError?.Subject,
              body: getBillsError?.Description,
         });
    }
  }, [getBillsError]);

  const amount = Number(billingRecords?.Totals.toFixed(2));

  return (
    <div>
      <Alert {...alert} />
      {renderHeader("Billing", amount)}
      <div className="container-fluid">
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} filter={filter} />
        <Loading loading={isGetBillsLoading} />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        {isDataLoaded && billingRecords && (
          <BillsGridView
            data={billingRecords}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewBills;
