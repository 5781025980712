import { useEffect, useState } from 'react';
import { UseFileUploaderResult } from '../../Models/Files';
import { useGetStorageServiceSasToken } from '../../Screens/Admin/Jobs/EditJob/Hooks';
import { uploadFileToBlob } from '../../Utils/BlobStorageServiceUpload';

const useFileUploader = (): UseFileUploaderResult => {
     const [isUploadSuccessful, setIsUploadSuccessful] = useState<boolean>(true);

     /** Hooks */
     const {
          data: sasToken,
          error: sasTokenError,
          refetch: getStorageServiceSasToken,
     } = useGetStorageServiceSasToken();

     /** Effects */
     useEffect(() => {
          getStorageServiceSasToken();
     }, []);

     /**Function */
     const uploadFiles = async (files: File[]) => {
          try {
               files.map((file) =>
                    file ? uploadFileToBlob(file, sasToken ?? '') : Promise.resolve()
               );
               setIsUploadSuccessful(true);
          } catch (error) {
               setIsUploadSuccessful(false);
               throw error;
          }
     };

     return { sasToken, sasTokenError, uploadFiles, isUploadSuccessful };
};

export default useFileUploader;
