import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Loading } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { usePutMarkPaymentAsNotPayable } from '../Hooks';
import { ColWrapper, ErrorContainer, RowWrapper } from './style/PaymentModuleStyledComponents';

export const MarkPaymentAsNotPayableModal: React.FC<GeneralFormProps> = ({ paymentId, onClose, onRefresh }): JSX.Element => {
     /** States */
     const [paymentUpdatedToNotPayable, setUpdatePaymentToNotPayable] = React.useState(false);

     /** Query Hooks */
     const { mutate: putMarkPaymentAsNotPayable, data, error, isLoading, isSuccess } = usePutMarkPaymentAsNotPayable();

     useEffect(() => {
          if (data) {
               setUpdatePaymentToNotPayable(true);
               onRefresh();
          }
          onRefresh();
     }, [error, data]);

     /** Formik */
     const formik = useFormik({
          initialValues: { AdminNotes: '' },
          onSubmit: () => {
               putMarkPaymentAsNotPayable(paymentId);
          },
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     return (
          <>
               {' '}
               <Loading loading={isLoading} />
               {paymentUpdatedToNotPayable && isSuccess && <div>Payment Note updated!</div>}
               {error && <ErrorContainer>{error?.Subject}: {error?.Description}</ErrorContainer>}
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         {(!paymentUpdatedToNotPayable && !error) && <p>Mark Payment As Not Payable?</p>}
                         <RowWrapper>
                              {!paymentUpdatedToNotPayable && (
                                   <ColWrapper>
                                        <FormikControl
                                             name='Submit'
                                             label='Submit'
                                             type='submit'
                                             control={FormControlKeys.Button}
                                        />
                                   </ColWrapper>
                              )}
                              <ColWrapper>
                                   <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                              </ColWrapper>
                         </RowWrapper>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     paymentId: number;
     onClose: Function;
     onRefresh: Function;
};
