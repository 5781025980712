import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const usePutUncheckPayment = (
  options?: any
): UseMutationResult<
  any,
  any,
  number
> => {

  const putUncheckPayment = (paymentID: number) => {
    return agent.payments.putUncheckPayment(paymentID);
  };

  return useMutation(putUncheckPayment);
};