import axios from "axios";
import { AddAdminUserModel, UpdateAdminUserRequest } from "../../../Models/System";
import { jsonConfig, responseBody } from "../../Agent";
import { ApiURLConstants } from "../../ApiUrlConstants";
import { CommonRequestParam } from "../../../Types";
import { GetAdminUIPermissionsResponse } from "../../../Models/Admin/GetAdminUIPermissionsResponse";

const {
  GetAdminRolesForDropdown_URL,
  PostAddAdministrator_URL,
  PutUpdateAdministrator_URL,
  DeleteAdministrator_URL,
  GetAdministrators_URL,
  GetAdministrator_URL,
  GetStorageServiceSasToken_URL,
  GetAdminUIPermissions_URL,
} = ApiURLConstants;

export const admin = {
  getAdminRolesForDropdown: (): Promise<any> => {
    return axios.get(GetAdminRolesForDropdown_URL).then(responseBody);
  },

  getAdministrators: (params: CommonRequestParam): Promise<any> => {
    return axios.post(`${GetAdministrators_URL}`, params).then(responseBody);
  },

  getAdministrator: (administratorID: number): Promise<any> => {
    return axios.get(`${GetAdministrator_URL}?adminUserId=${administratorID}`).then(responseBody);
  },

  postAddAdministrator: (params: AddAdminUserModel): Promise<any> => {
    return axios.post(`${PostAddAdministrator_URL}`, params, jsonConfig).then(responseBody);
  },

  putUpdateAdministrator: (params: UpdateAdminUserRequest): Promise<any> => {
    return axios.put(`${PutUpdateAdministrator_URL}`, params, jsonConfig).then(responseBody);
  },

  deleteAdministrator: (administratorID: number): Promise<any> => {
    return axios.delete(`${DeleteAdministrator_URL}?adminUserId=${administratorID}`).then(responseBody);
  },

  getStorageServiceSasToken: (): Promise<any> => {
    return axios.get(GetStorageServiceSasToken_URL).then(responseBody);
  },

  getAdminUIPermissions: (): Promise<GetAdminUIPermissionsResponse> => {
    return axios.get(GetAdminUIPermissions_URL).then(responseBody);
  },
};
