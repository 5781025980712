import { GridRenderCellParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { PriceGroupGridKeys } from '../../../../../Models/PriceGroup';
import { ActionColumnPath } from '../../../../../Utils/Constants';

const {
     renderCellWithTooltip,
     renderEditPriceGroupCell,
     renderPriceTypeCell,
     renderDeleteActionCell,
} = CellRenderer;

export const priceGroupColumns = (onPriceGroupDelete: (id: number) => void) => {
     return [
          {
               field: PriceGroupGridKeys.ID,
               headerName: 'ID',
               description: 'ID',
               renderCell: (params: GridRenderCellParams) => renderEditPriceGroupCell(params),
               flex: 2,
          },
          {
               field: PriceGroupGridKeys.Type,
               headerName: 'Type',
               description: 'Type',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderPriceTypeCell(params),
          },
          {
               field: PriceGroupGridKeys.Name,
               description: 'Name',
               headerName: 'Name',
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
               flex: 6,
          },
          {
               field: ActionColumnPath,
               description: 'Action',
               headerName: 'Action',
               renderCell: (params: GridRenderCellParams) =>
                    renderDeleteActionCell(params, onPriceGroupDelete, 'Delete PriceGroup'),
               flex: 1,
          },
     ];
};
