import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { DeleteQuoteModel } from '../../../../../Models/Jobs/EditJob';

export const useDeleteQuote = (
  options?: any
): UseMutationResult<any, any, DeleteQuoteModel> => {

  const deleteQuote = (payload: DeleteQuoteModel) => {
    return agent.jobs.deleteQuote(payload);
  };

  return useMutation(deleteQuote);
};
