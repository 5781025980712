import { ResultsViewModel } from "../../../Types"

export interface EmailServiceResponseModel extends ResultsViewModel<EmailServiceModel> { }

export type EmailServiceModel = {
    Id: number;
    From: string;
    ReplyTo: string;
    To: string;
    Subject: string;
}

export const EmailServiceModelKeys = {
    Id: "Id",
    From: "From",
    ReplyTo: "ReplyTo",
    To: "To",
    Subject: "Subject",
}