import { GridRenderCellParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../../Components/Formik';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { AdministratorModelKeys } from '../../../../../../Models/System';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { ActionColumnPath } from '../../../../../../Utils';

interface Props {
  onDelete : (id : number) => void;
}
export const AdmonistratorGridColumns = (props : Props) => {
  const {onDelete } = props;
     const { renderAdministratorActionCell } = CellRenderer;

     const administratorColumns = [
          {
               field: AdministratorModelKeys.Id,
               headerName: 'ID',
               description: 'ID',
               renderCell: (params: GridRenderCellParams) => {
                    const link = `${RouteConstants.EditAdministrator}/${params.value}`;
                    return <HoverableLink to={link}>{params.value}</HoverableLink>;
               },
          },
          {
               field: AdministratorModelKeys.UserName,
               headerName: 'UserName',
               description: 'UserName',
               renderCell: (params: GridRenderCellParams) => {
                    const link = `${RouteConstants.EditAdministrator}/${params.id}`;
                    return <HoverableLink to={link}>{params.value}</HoverableLink>;
               },
               flex: 2,
          },
          {
               field: AdministratorModelKeys.Email,
               headerName: 'Email',
               description: 'Email',
               flex: 4,
          },
          {
               field: AdministratorModelKeys.DisplayName,
               headerName: 'Display Name',
               flex: 2,
               description: 'Display Name',
          },
          {
               field: AdministratorModelKeys.IsAllocatedJobs,
               headerName: 'Is Allocated Jobs',
               flex: 2,
               description: 'Is Allocated Jobs',
          },
          {
               field: AdministratorModelKeys.Active,
               headerName: 'Active',
               flex: 2,
               description: 'Active',
          },
          {
               field: ActionColumnPath,
               headerName: 'Action',
               flex: 2,
               description: 'Action',
               type: 'actions',
               renderCell: (params: GridRenderCellParams) => renderAdministratorActionCell(params, onDelete),
          },
     ];

     return administratorColumns;
};
