import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { DropdownAdminUsersResponse } from "../../../../../Models/Jobs/EditJob/DropdownAdminUsersResponse";

export const useGetAdminUsersForDropdown = (
  options?: any
): UseQueryResult<DropdownAdminUsersResponse, any> => {
  const getMAdminUsersForDropdown = () => {
    return agent.jobs.getAdminUsersForDropdown().then((response) => response);
  };

  return useQuery(["GetAdminUsersForDropdown"], getMAdminUsersForDropdown, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
