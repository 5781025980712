import React from "react";
import PropTypes from "prop-types";

export const InputButton = ({ ...props }): JSX.Element => {
  const { label, variant, size, ...rest } = props;
  return (
    <input
      type="button"
      value={label}
      className={`btn btn-${variant} ${size}`}
      {...rest}
    ></input>
  );
};

InputButton.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

InputButton.defaultProps = {
  variant: "primary",
  onClick: undefined,
  size: "btn-sm",
};

export default InputButton;
