import axios from 'axios';
import { FileModelRequest, FileModelRequestForSystemTasksCSV } from '../../../Models/Tasks';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
     GetAdministrationInformation_URL,
     PostUploadFile_URL,
     PostUploadMemberCodaReferencesFile_URL,
     PostSendSmartDebitFile_URL,
     PostUploadMarkRequestingMemberInvoicesAsPaidFile_URL,
     PostAutoRemoveOldExpiredJobs_URL,
     PostPerformDailyPaymentRun_URL,
     PostPerformInvoiceRunForDeferredFees_URL,
     PerformFortnightlyInvoiceRun_URL,
     PostPerformWeeklyInvoiceRun_URL,
     PostGenerateDailyStatsReport_URL,
     PostGeneratePrePaymentFile_URL,
     PostAutoInsuranceReminders_URL,
     PostSendEmailsToInactiveMembers_URL,
     PostAutoReRequestQuotes_URL,
} = ApiURLConstants;

export const tasks = {
     getAdministrationInformation: (): Promise<any> => {
          return axios.get(GetAdministrationInformation_URL).then(responseBody);
     },
     postUploadFiles: (params: any): Promise<any> => {
          return axios.post(PostUploadFile_URL, params).then(responseBody);
     },
     uploadUpdateMemberCodaReferenceFile: (params: FileModelRequest): Promise<FileModelRequestForSystemTasksCSV> => {
          return axios.post(PostUploadMemberCodaReferencesFile_URL, params, jsonConfig).then(responseBody);
     },
     postSendSmartDebitFile: (params: FileModelRequest): Promise<string> => {
          return axios.post(PostSendSmartDebitFile_URL, params, jsonConfig).then(responseBody);
     },
     postPostUploadMarkRequestingMemberInvoicesAsPaidFile: (
          params: FileModelRequest
     ): Promise<FileModelRequestForSystemTasksCSV> => {
          return axios.post(PostUploadMarkRequestingMemberInvoicesAsPaidFile_URL, params, jsonConfig).then(responseBody);
     },
     postAutoRemoveOldExpiredJobs: (): Promise<any> => {
          return axios.post(PostAutoRemoveOldExpiredJobs_URL, jsonConfig).then(responseBody);
     },
     postPerformDailyPaymentRun: (params: any): Promise<any> => {
          return axios.post(PostPerformDailyPaymentRun_URL, params, jsonConfig).then(responseBody);
     },
     postPerformInvoiceRunForDeferredFees: (params: any): Promise<any> => {
          return axios.post(PostPerformInvoiceRunForDeferredFees_URL, params, jsonConfig).then(responseBody);
     },
     postPerformFortnightlyInvoiceRun: (params: any): Promise<any> => {
          return axios.post(PerformFortnightlyInvoiceRun_URL, params, jsonConfig).then(responseBody);
     },
     postPerformWeeklyInvoiceRun: (params: any): Promise<any> => {
          return axios.post(PostPerformWeeklyInvoiceRun_URL, params, jsonConfig).then(responseBody);
     },
     postGenerateDailyStatsReport: (params: any): Promise<any> => {
          return axios.post(PostGenerateDailyStatsReport_URL, params, jsonConfig).then(responseBody);
     },
     postGeneratePrePaymentFile: (params: any): Promise<any> => {
          return axios.post(PostGeneratePrePaymentFile_URL, params, jsonConfig).then(responseBody);
     },
     postAutoInsuranceReminders: (): Promise<any> => {
          return axios.post(PostAutoInsuranceReminders_URL, jsonConfig).then(responseBody);
     },
     postSendEmailsToInactiveMembers: (params: any): Promise<any> => {
          return axios.post(PostSendEmailsToInactiveMembers_URL, params, jsonConfig).then(responseBody);
     },
     postAutoReRequestQuotes: (): Promise<any> => {
          return axios.post(PostAutoReRequestQuotes_URL, jsonConfig).then(responseBody);
     },
};
