import { useState } from 'react';
import { ConfirmDialogProps } from './ConfirmDialog';

// Define the return type of the hook
interface UseConfirmDialogResult {
     confirmDialogProps: ConfirmDialogProps;
     openConfirmDialog: (
          header: string,
          body: any,
          confirmCallback?: () => void,
          cancelCallback?: () => void
     ) => void;
     closeConfirmDialog: () => void;
}

export function useConfirmDialog(): UseConfirmDialogResult {
     const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          show: false,
          cancelCallback: () => setConfirmDialogProps({ ...confirmDialogProps, show: false }),
     });

     // Function to open the confirmation dialog
     const openConfirmDialog = (
          header: string,
          body: any,
          confirmCallback?: () => void,
          cancelCallback?: () => void
     ) => {
          setConfirmDialogProps({
               header,
               body,
               show: true,
               confirmCallback,
               cancelCallback: cancelCallback || confirmDialogProps.cancelCallback,
          });
     };

     // Function to close the confirmation dialog
     const closeConfirmDialog = () => {
          setConfirmDialogProps({ ...confirmDialogProps, show: false });
     };

     return {
          confirmDialogProps,
          openConfirmDialog,
          closeConfirmDialog,
     };
}
