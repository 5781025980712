import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GroupResponseModel } from '../../../../../Models/Groups';

export const useGetGroup = (groupID: number,
  options?: any
): UseQueryResult<GroupResponseModel, any> => {

  const getGroup = () => {
    if (groupID) {
      return agent.groups.getGroup(groupID)
    }
  };

  return useQuery(['GetGroup', groupID], getGroup, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
