import { OptionType } from '../../../../Components/FormControls/Types';

export const TaskTypeOptions: OptionType[] = [
     { value: '', label: 'All Members' },
     { value: 1, label: 'Additional Job Costs' },
];

export const AssignedToTypeOptions: OptionType[] = [
     { value: '', label: 'Select Admin User' },
     { value: 1, label: 'Unassigned' },
];

export const StatusTypeOption: OptionType[] = [
     { value: '', label: 'Select Task Status' },
     { value: '1', label: 'New' },
     { value: '2', label: 'In Progress' },
     { value: '3', label: 'Awaiting Dealer' },
     { value: '4', label: 'Awaiting Supplier' },
     { value: '5', label: 'Awaiting Other' },
     { value: '6', label: 'Payment Approved' },
     { value: '7', label: 'Payment Disputed' },
     { value: '8', label: 'On hold' },
     { value: '9', label: 'Confirmed' },
     { value: '254', label: 'Closed' },
     { value: '255', label: 'Completed' },
];

export const InProgressTypeOption: OptionType[] = [
     { value: '', label: 'Both' },
     { value: true, label: 'Yes' },
     { value: false, label: 'No' },
];

export const SearchTypeOptions: OptionType[] = [
     { value: '', label: 'Search Type' },
     { value: '1', label: 'Job ID' },
     { value: '2', label: 'Member ID' },
     { value: '100', label: 'VRM' },
];
