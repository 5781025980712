import { Action } from 'redux';
export interface ApiUrlState {
     apiUrl: string;
}
export const SET_API_URL = 'SET_API_URL';
export const GET_API_URL = 'GET_API_URL';

interface SetApiUrlAction extends Action<typeof SET_API_URL> {
     payload: string;
}
interface GetApiUrlAction extends Action<typeof GET_API_URL> {
     payload: string;
}
export type ApiUrlActionTypes = SetApiUrlAction | GetApiUrlAction;
