import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';

export const useDeleteAdminRole = (
  options?: any
): UseMutationResult<any, any, number> => {

  const deleteAdminRole = (adminRoleID: number) => {
    return agent.adminRole.deleteAdminRole(adminRoleID);
  };

  return useMutation(deleteAdminRole);
};
