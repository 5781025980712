import { Formik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer, RowFluidContainer } from '../../../../../Components/Formik/StyledComponents';
import { BillsFilter } from '../../../../../Models/Billing/BillsFilter';
import { getDropdownInvoiceDateOptions } from '../../../../../Utils';
import { BillTypeOptions, PaymentMethodTypeOptions, StatusOptions } from '../../../Members/Constants';
import { useGetInvoiceDatesForDropdown } from '../Hooks/useGetInvoiceDatesForDropdown';
import { FilterKeys } from '../Types';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
     const { onSearch, onClearSearch, filter } = props;
     const initialValues: BillsFilter = { 
          MemberId: filter.MemberId || undefined, 
          FreeTextSearchBy: FreeTextSearchByOptions.SupplierUsername,
          Freetext: '',
          InvoiceStatus: '',
          PaymentType: '',
          InvoiceDate: ''
     };
     const collasibleElement: React.ReactElement = (
          <div>
               <span style={{ marginLeft: '10px' }}>Filter by</span>
               <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
          </div>
     );

     const { data } = useGetInvoiceDatesForDropdown();

     return (
          <Formik
               initialValues={initialValues}
               onSubmit={(values) => {
                    if(values.MemberId === "") values.MemberId = undefined;
                    onSearch(values);
               }}
          >
               {({ handleSubmit, resetForm, values }) => {
                    const handleClearSearch = () => {
                         resetForm();
                         values = initialValues;
                         onClearSearch();
                    };
                    return (
                         <FormContainer
                              onSubmit={(e) => {
                                   e.preventDefault();
                                   handleSubmit(e);
                              }}
                         >
                              <div className='well'>
                                   <Collapsible open={true} trigger={collasibleElement}>
                                        <Container>
                                             <Row>
                                                  <Col style={{ display: 'none' }}>
                                                       <FormikControl
                                                            name={FilterKeys.MemberId}
                                                            label='Member ID'
                                                            title='Member ID'
                                                       />
                                                  </Col>
                                                  <Col>
                                                       <FormikControl
                                                            name={FilterKeys.FreeText}
                                                            label='Freetext'
                                                            title='Searches by ID, UserName or Email'
                                                       />
                                                  </Col>
                                                  <Col>
                                                       <FormikControl
                                                            name={FilterKeys.FreeTextSearchBy}
                                                            label="Freetext Type"
                                                            control={FormControlKeys.Select}
                                                            options={BillTypeOptions}
                                                       />
                                                  </Col>
                                                  <Col>
                                                       <FormikControl
                                                            name={FilterKeys.InvoiceStatus}
                                                            label='Status'
                                                            control={FormControlKeys.Select}
                                                            options={StatusOptions}
                                                       />
                                                  </Col>
                                                  <Col>
                                                       <FormikControl
                                                            name={FilterKeys.PaymentType}
                                                            label='Payment Type'
                                                            control={FormControlKeys.Select}
                                                            options={PaymentMethodTypeOptions}
                                                       />
                                                  </Col>
                                                  <Col>
                                                       <FormikControl
                                                            name={FilterKeys.InvoiceDate}
                                                            label='Invoice Date'
                                                            control={FormControlKeys.Select}
                                                            options={getDropdownInvoiceDateOptions(data ?? [])}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row>
                                                  <br />
                                             </Row>
                                             <RowFluidContainer>
                                                  <Col>
                                                       <FormikControl
                                                            name='Search'
                                                            label='Search'
                                                            type='submit'
                                                            className='btn btn-light span2'
                                                            control={FormControlKeys.Button}
                                                       />
                                                       <span style={{ paddingRight: 10 }}></span>
                                                       <FormikControl
                                                            name='Clear'
                                                            label='Clear'
                                                            type="reset"
                                                            onClick={handleClearSearch}
                                                            className='btn btn-light span2'
                                                            control={FormControlKeys.Button}
                                                       />
                                                  </Col>
                                             </RowFluidContainer>
                                        </Container>
                                   </Collapsible>
                              </div>
                         </FormContainer>
                    );
               }}
          </Formik>
     );
};

export default FilterBox;
export interface FilterBoxProps {
     onSearch: Function;
     onClearSearch: Function;
     filter: BillsFilter;
}
