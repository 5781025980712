import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
/** custom imports */
import { Col, Row } from 'react-bootstrap';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { MarkPaymentsAsPaidModel } from '../../../../../Models/Billing/AddBilling/MarkPaymentsAsPaidModelRequest';
import { StyledDivContainer } from '../../BillsStyledComponents';
import { usePutMarkPaymentsAndMembershipAsPaid } from '../Hooks/usePutMarkPaymentsAndMembershipAsPaid';

export const MarkAsPaidBody: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { billID, jobIDs, requestingMemberMonthlyFeePaid, supplyingMemberMonthlyFeePaid } = props;

     /** Query Hooks */
     const {
          mutate: putMarkPaymentsAndMembershipAsPaid,
          isLoading: isPutMarkPaymentsAndMembershipAsPaidLoading,
          data: putMarkPaymentsAndMembershipAsPaidResponse,
          error: putMarkPaymentsAndMembershipAsPaidError,
     } = usePutMarkPaymentsAndMembershipAsPaid();

     /** Methods */
     const formik = useFormik({
          initialValues: { MarkAsPaidModule: undefined },
          onSubmit: () => {},
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     const handleMarkPaymentsAsPaid = () => {
          const markPaymentsAsPaidModel: MarkPaymentsAsPaidModel = {
               BillID: billID,
               JobIDs: jobIDs,
               RequestingMemberMonthlyFeePaid: requestingMemberMonthlyFeePaid,
               SupplyingMemberMonthlyFeePaid: supplyingMemberMonthlyFeePaid,
               PaidDate: values.MarkAsPaidModule!,
          };

          putMarkPaymentsAndMembershipAsPaid(markPaymentsAsPaidModel);
     };

     return (
          <FormikProvider value={formik}>
               <Form onSubmit={formik.handleSubmit}>
                    <Col>
                         <Row>
                              {isPutMarkPaymentsAndMembershipAsPaidLoading && <p>Loading...</p>}

                              {putMarkPaymentsAndMembershipAsPaidError && (
                                   <p>{putMarkPaymentsAndMembershipAsPaidError ?? 'Error'}</p>
                              )}

                              {putMarkPaymentsAndMembershipAsPaidResponse && <>{putMarkPaymentsAndMembershipAsPaidResponse}</>}

                              {!isPutMarkPaymentsAndMembershipAsPaidLoading &&
                                   !putMarkPaymentsAndMembershipAsPaidError &&
                                   !putMarkPaymentsAndMembershipAsPaidResponse && (
                                        <StyledDivContainer>
                                             <FormikControl name='MarkAsPaidModule' type='date' />

                                             <FormikControl
                                                  name='Submit'
                                                  label='Submit'
                                                  type='submit'
                                                  onClick={handleMarkPaymentsAsPaid}
                                                  control={FormControlKeys.Button}
                                             />
                                        </StyledDivContainer>
                                   )}
                         </Row>
                    </Col>
               </Form>
          </FormikProvider>
     );
};

export const MarkAsPaidFooter: React.FC<any> = ({ onClose }): JSX.Element => {
     return (
          <>
               <Row>
                    <Col>
                         <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                    </Col>
               </Row>
          </>
     );
};

type GeneralFormProps = {
     billID: number;
     jobIDs: number[];
     requestingMemberMonthlyFeePaid: boolean;
     supplyingMemberMonthlyFeePaid: boolean;
};
