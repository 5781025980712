import axios from "axios";
import { responseBody } from "../../Agent";
import { ApiURLConstants } from "../../ApiUrlConstants";
import { PocResponseModel, PodResponseModel } from "../../../Models/Inspection";

const {
  GetInspectionReportDownloadPOC_URL,
  GetInspectionReportDownloadPOD_URL,
  GetInspectionReportDownloadArchivedPOC_URL,
  GetInspectionReportDownloadArchivedPOD_URL,
} = ApiURLConstants;

export const inspectionReport = {
  getInspectionReportDownloadPOC: (pocId: number): Promise<PocResponseModel> => {
    return axios
      .get(`${GetInspectionReportDownloadPOC_URL}?pocId=${pocId}`)
      .then(responseBody);
  },
  getInspectionReportDownloadPOD: (podId: number): Promise<PodResponseModel> => {
    return axios
      .get(`${GetInspectionReportDownloadPOD_URL}?podId=${podId}`)
      .then(responseBody);
  },
  getInspectionReportDownloadArchivedPOC: (pocId: number): Promise<PocResponseModel> => {
    return axios
      .get(`${GetInspectionReportDownloadArchivedPOC_URL}?pocId=${pocId}`)
      .then(responseBody);
  },
  getInspectionReportDownloadArchivedPOD: (podId: number): Promise<PodResponseModel> => {
    return axios
      .get(`${GetInspectionReportDownloadArchivedPOD_URL}?podId=${podId}`)
      .then(responseBody);
  },
};
