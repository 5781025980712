import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import * as Authorization from "./Session/Reducer";
import * as NewMembers from "./Members/NewMembers/Reducer";
import * as Members from "./Members/ViewMembers/Reducer";
import * as AddMember from "./Members/AddMember/Reducer";
import * as GridFilterCache from "./Grids/GridFilterCache/Reducer";
import * as getApiUrl from "./ApiUrl/Reducer";

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["sessionState"],
    blacklist: ["addMemberState"]
};

export const reducers = {
    sessionState: Authorization.reducer,
    membersState: Members.reducer,
    newMembersState: NewMembers.reducer,
    addMemberState: AddMember.reducer,
    gridsFilterState: GridFilterCache.reducer,
    apiUrlState: getApiUrl.reducer
}

// create the root reducer
export const rootReducer = combineReducers({
    ...reducers,
});

export const PersistedReducer = persistReducer(persistConfig, rootReducer);
