export const PartnerGridKeys = {
    ID: 'ID',
    IsActive: 'IsActive',
    Name: 'Name',
    Email: 'Email',
}

export const AddPartnerKeys = {
    ID: 'ID',
    IsActive: 'IsActive',
    Name: 'Name',
    Email: 'Email',
    EncryptionKey: 'EncryptionKey',
    EncryptionVector: 'EncryptionVector',
    SuccessCallBackURL: 'SuccessCallBackURL',
    ErrorCallBackURL: 'ErrorCallBackURL',
    ExampleForm: 'ExampleForm',
    ExampleUrl: 'ExampleUrl',
    SupportEmail: 'SupportEmail',
}

export const AddPartnerMemberKeys = {
    ID: 'ID',
    MemberID: 'MemberID',
    Company: 'Company',
    PartnerCID: 'PartnerCID',
    PartnerID: 'PartnerID',
    PartnerName: 'PartnerName',
    UserName: 'UserName'
}

export type PartnerIDParamType = {
    partnerID: string;
}