import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import 'reactjs-popup/dist/index.css';
import RootNavigator from './RootNavigator';
import { ApplicationState } from './Store/Types';
import { PersistedReducer } from './Store/RootReducers';
import './App.css';

// Setup Redux Store
export const store = createStore(
  PersistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);

export default function App(): JSX.Element {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);

  /** block the retry when the ErrorCode is 403! */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error: any) => {
          if (error.ErrorCode == 403) {
            return false;
          }
          return failureCount < 3;
        },
      },
    },
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <RootNavigator isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

/**This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are  correctly typed to match your store. */
export interface AppThunkAction<TAction> {
  (dispatch: AppDispatch, getState: () => ApplicationState): void;
}
