import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { MemberSearchFilter, SupplyingMembersNearbyResponseModel } from "../../../../../Models/Jobs/EditJob";

export const useGetSupplyingMembersNearby = (
  searchFilter: MemberSearchFilter,
  options?: any
): UseQueryResult<SupplyingMembersNearbyResponseModel, any> => {
  const getSupplyingMembers = () => {
    if (searchFilter) {
      return agent.jobs.getSupplyingMembersNearby(searchFilter);
    }
  };

  return useQuery(["GetSuppliyingMembers"], getSupplyingMembers, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
    ...options,
  });
};
