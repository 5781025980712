export const JobFilterDialogKeys = {
     FreeText: 'Freetext',
     'FreeTextSearchBy': 'FreeTextSearchBy',
     VRMorVIN: 'VRMorVIN',
     PartnerID: 'PartnerID',
     GroupID: 'GroupID',
     AdminUserID: 'AdminUserID',
     RequestDateFrom: 'RequestDateFrom',
     RequestDateTo: 'RequestDateTo',
     Standard: 'Standard',
     Return: 'Return',
     Onward: 'Onward',
     MultipleDropOff: 'MultipleDropOff',
     InternalTransfer: 'InternalTransfer',
     FleetDelivery: 'FleetDelivery',
     RetailDelivery: 'RetailDelivery',
     PremiumFleet: 'PremiumFleet',
     PremiumRetail: 'PremiumRetail',
     Driven: 'Driven',
     Transported: 'Transported',
     Both: 'Both',
     VehiclesMin: 'VehiclesMin',
     VehiclesMax: 'VehiclesMax',
     FromPostCode: 'FromPostCode',
     ToPostCode: 'ToPostCode',
     DistanceMilesMin: 'DistanceMilesMin',
     DistanceMilesMax: 'DistanceMilesMax',
     CollectionDateFrom: 'CollectionDateFrom',
     CollectionDateTo: 'CollectionDateTo',
     DeliveryDateFrom: 'DeliveryDateFrom',
     DeliveryDateTo: 'DeliveryDateTo',
     RequestQuote: 'RequestQuote',
     FixedPrice: 'FixedPrice',
     GuaranteedPrice: 'GuaranteedPrice',
     WholeNetwork: 'WholeNetwork',
     Aborted: 'Aborted',
     AcceptedQuote: 'AcceptedQuote',
     ActiveJob: 'ActiveJob',
     AdminRemoved_OrFailed: 'AdminRemoved_OrFailed',
     BookedJob: 'BookedJob',
     Cancelled: 'Cancelled',
     CompletedJob: 'CompletedJob',
     Draft: 'Draft',
     Expired: 'Expired',
     PendingQuotes: 'PendingQuotes',
     Quotes: 'Quotes',
     MovementTypeFleetDelivery: 'MovementTypeFleetDelivery',
     MovementTypeDealerTransfer: 'MovementTypeDealerTransfer',
     MovementTypeInternalTransfer: 'MovementTypeInternalTransfer',
     MovementTypePremiumFleet: 'MovementTypePremiumFleet',
     MovementTypePremiumRetail: 'MovementTypePremiumRetail',
     MovementTypeRetailDelivery: 'MovementTypeRetailDelivery',
     MovementTypeUnknown: 'MovementTypeUnknown',
     JobTypeQuotes: 'JobTypeQuotes',
     JobTypeFixedPrice: 'JobTypeFixedPrice',
     JobTypeBoth: 'JobTypeBoth',
     JobTypeGuaranteedPrice: 'JobTypeGuaranteedPrice',
     OfferedToWholeNetwork: 'OfferedToWholeNetwork',
     FiveStarCompanies: 'FiveStarCompanies',
     SelectedSuppliers: 'SelectedSuppliers',
     Pending: 'Pending',
     SmartDebit: 'SmartDebit',
     Invoice: 'Invoice',
     PrePay: 'PrePay',
};
