import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import CSS from 'csstype';
import { ControlledPagination as Pagination } from '../../../../../Components';
import { BillsResponseModel } from '../../../../../Models/Billing/Response';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter } from '../../../../../Utils';
import { columns } from './Columns';

const BillsGridView: React.FC<BillsGridProps> = (props) => {
     const { data: bills, onPageChange, onPageSizeChange, onSort } = props;

     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const { Page: page, TableSorter } = bills;

     if (page) {
          const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = page;
          
          const CustomGridToolbar = () => {
               return (
                    <Pagination
                         itemsCount={TotalItems}
                         currentPage={CurrentPage}
                         totalPages={TotalPages}
                         pageSize={ItemsPerPage}
                         onPageChange={handlePageChange}
                         onRowsPerPageChange={handleRowsPerPageChange}
                    ></Pagination>
               );
          };
          return (
               <div>
                    {page && (
                         <div id='id_grid_bills'>
                              <DataGrid
                                   sortingOrder={['desc', 'asc']}
                                   getRowId={(row: any) => row.Id}
                                   autoHeight
                                   disableColumnMenu={true}
                                   rows={Items}
                                   rowHeight={41}
                                   columns={columns}
                                   components={{
                                        Toolbar: CustomGridToolbar,
                                        Footer: CustomGridToolbar,
                                   }}
                                   sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                                   onSortModelChange={handleSort}
                              />
                         </div>
                    )}
               </div>
          );
     }
     return null;
};

export default BillsGridView;

interface BillsGridProps extends GridProps<BillsResponseModel> {}

const imageStyle: CSS.Properties = { height: '25px', width: '25px' };
