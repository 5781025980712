export const AddGroupKeys = {
    Active: 'Active',
    ConfirmPassword: 'ConfirmPassword',
    Email: 'Email',
    ID: "ID",
    Notes: 'Notes',
    ParentId: 'ParentId',
    Password: 'Password',
    UserName: 'UserName',
    SelectedFavouriteMember: 'FavouriteMemberID',
    AddFavouriteMember: 'AddFavouriteMember',
    RemoveFavouriteMember: 'RemoveFavouriteMember',
    SelectedFavouriteBlock: 'BlockedMemberID',
    AddFavouriteBlock: 'AddFavouriteBlock',
    RemoveFavouriteBlock: 'RemoveFavouriteBlock',
}

export const GroupSavedDetailKeys = {
    Address: 'Address',
    Company: 'Company',
    Contact: 'Contact',
    GroupID: 'GroupID',
    GroupsSavedDetailsID: 'GroupsSavedDetailsID',
    Latitude: 'Latitude',
    Longitude: 'Longitude',
    Phone: 'Phone',
    PostCode: 'PostCode',
    Town: 'Town',
}

export type GroupIDParamType = {
    groupID: string;
}
