import { useQuery, UseQueryResult } from 'react-query';
import { CommonRequestParam } from '../../../../../../Types';

export const useGetEmailServiceList = (payload: CommonRequestParam,
  options?: any
): UseQueryResult<any, any> => {

  const getAdministratorList = () => {
    // ********** TODO: code to be replaced with api call
    const data = {
      Page: {
        CurrentPage: 1, TotalPages: 1, TotalItems: 4, ItemsPerPage: 50,
        Items: [
          { TotalRecords: 4, Id: "", From: "", ReplyTo: "", To: "", Subject: ""},
        ],
        Context: null
      },
      TableSorter: { Column: "ID", Ascending: true }
    }
    //***********
    return data;
  };

  return useQuery(['GetAdministratorList', payload], getAdministratorList, {
    ...options,
  });
};
