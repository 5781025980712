import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { IDeleteTargetFormPriceGroup } from '../../../../../Models/PriceGroup';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeleteTargetFromPriceGroup = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, IDeleteTargetFormPriceGroup> => {
     const deleteTargetFromPriceGroup = (params: IDeleteTargetFormPriceGroup) => {
          return agent.priceGroup.deleteTargetFromPriceGroup(params);
     };

     return useMutation(deleteTargetFromPriceGroup);
};
