import { useQuery, UseQueryResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { TaskAttachmentResponse } from '../../../../../Models/Tasks';

export const useGetAttachmentsFile = (taskAttachmentId: number, options?: any): UseQueryResult<TaskAttachmentResponse, any> => {
     const getTaskAttachmentsFile = () => {
          if (taskAttachmentId) {
               return adminTasks.getTaskAttachmentsFile(taskAttachmentId);
          }
     };

     return useQuery(['GetTaskAttachments', taskAttachmentId], getTaskAttachmentsFile, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
