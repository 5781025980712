import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';

export const useDeleteVehicle = (
  options?: any
): UseMutationResult<any, any, number> => {

  const deleteVehicle = (vehicleId: number) => {
    return agent.vehicles.deleteVehicle(vehicleId);
  };

  return useMutation(deleteVehicle);
};
