import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { ReviveJobModel, ReviveJobResponseModel } from '../../../../../Models/Jobs/EditJob';

export const usePutReviveJob = (
  options?: any
): UseMutationResult<
  ReviveJobResponseModel,
  any,
  ReviveJobModel
> => {

  const usePutReviveJob = (payload: ReviveJobModel) => {
    return agent.jobs.putReviveJob(payload)
  };

  return useMutation(usePutReviveJob);
};