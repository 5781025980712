import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { AddAdminUserRoleModel } from '../../../../../../Models/System';
import { camelize } from '../../../../../../Utils';

export const usePostAddSelectedAdminRoleToAdminUser = (
  options?: any
): UseMutationResult<any, any, AddAdminUserRoleModel> => {
  const addSelectedAdminRoleToAdminUser = (payload: AddAdminUserRoleModel) => {
    return agent.adminRole.postAddSelectedAdminRoleToAdminUser(payload).then((response => camelize(response)));
  };

  return useMutation(addSelectedAdminRoleToAdminUser);
};
