import React, { Component } from 'react';
import _ from 'lodash';
import { TableHeaderColumn } from '../TableHeader';

class TableBody extends Component<TableBodyProps, ITableBodyState> {
  renderCell = (item: any, column: TableHeaderColumn) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  createKey = (item: any, column: TableHeaderColumn) => {
    return item._id + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data &&
          data.map((item) => {
            let className = item.Active ? 'active' : 'inactive';
            className += item.Disabled ? ' disabled' : ' enabled';
            return (
              <tr key={item.Id} className={className}>
                {columns.map((column) => (
                  <td key={this.createKey(item, column)}>
                    {this.renderCell(item, column)}
                  </td>
                ))}
              </tr>
            );
          })}
        {!data && 'No new members!'}
      </tbody>
    );
  }
}

export default TableBody;

interface ITableBodyState {}
interface TableBodyProps {
  data: any[];
  columns: TableHeaderColumn[];
}
