import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { PriceGridKeys } from '../../../../../Models/Prices/ViewPrices/PriceItemModel';
import { ActionColumnPath } from '../../../../../Utils';

const {
     renderServiceTypeCell,
     renderPriceIncludesFuelTypeCell,
     renderCurrencyCell,
     renderDeleteActionCell,
     renderCellWithTooltip
} = CellRenderer;

interface Props {
     onDelete: (id: number) => void;
}

export function getPriceGridColumns({ onDelete: deletePrice }: Props): GridColDef[] {
     return [
          {
               field: PriceGridKeys.Id,
               headerName: 'ID',
               description: 'ID',
               renderCell: (params: GridRenderCellParams) => {
                    return <span>{params.id}</span>;
               },
               flex: 2,
               sortable: false,
          },
          {
               field: PriceGridKeys.Type,
               description: 'Type',
               headerName: 'Type',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value === 0 ? 'Dealer Prices' : 'Transporter Costs'} </div>;
               },
               flex: 6,
          },
          {
               field: PriceGridKeys.Name,
               description: 'Name',
               headerName: 'Name',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 12,
          },
          {
               field: PriceGridKeys.ServiceType,
               description: 'Service Type',
               headerName: 'Service Type',
               renderCell: (params: GridRenderCellParams) => renderServiceTypeCell(params),
               flex: 4,
          },
          {
               field: PriceGridKeys.IncludesFuel,
               description: 'Includes Fuel',
               headerName: 'Includes Fuel',
               renderCell: (params: GridRenderCellParams) =>
                    renderPriceIncludesFuelTypeCell(params),
               flex: 4,
          },
          {
               field: PriceGridKeys.MinVehicles,
               description: 'Min Vehicles',
               headerName: 'Min Vehicles',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MaxVehicles,
               description: 'Max Vehicles',
               headerName: 'Max Vehicles',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MinWeight,
               description: 'Min Weight',
               headerName: 'Min Weight',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MaxWeight,
               description: 'Max Weight',
               headerName: 'Max Weight',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
          },
          {
               field: PriceGridKeys.MinLeadTime,
               description: 'Min Lead Time',
               headerName: 'Min Lead Time',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MaxLeadTime,
               description: 'Max Lead Time',
               headerName: 'Max Lead Time',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MinDistance,
               description: 'Min Distance',
               headerName: 'Min Distance',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MaxDistance,
               description: 'Max Distance',
               headerName: 'Max Distance',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.InitialDistance,
               description: 'Initial Distance',
               headerName: 'Initial Distance',
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.InitialPrice,
               description: 'Initial Price',
               headerName: 'Initial Price',
               renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.PricePerVehiclePerMile,
               description: 'Price',
               headerName: 'Price',
               renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MinRate,
               description: 'Min Rate',
               headerName: 'Min Rate',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: PriceGridKeys.MaxRate,
               description: 'Max Rate',
               headerName: 'Max Rate',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 4,
               sortable: false,
          },
          {
               field: ActionColumnPath,
               description: 'Action',
               headerName: 'Action',
               type: 'actions',
               flex: 4,
               renderCell: (params: GridRenderCellParams) =>
                    renderDeleteActionCell(params, deletePrice, 'Delete Price'),
               sortable: false,
          }
     ];
}
