import { MovexErrorResponse } from '../Types';

const getMovexErrorContent = (
  error: MovexErrorResponse,
  useAdditionalInfo?: boolean
) => {
  const { Subject, Description, AdditionalInformation } = error;

  if (useAdditionalInfo && AdditionalInformation) {
    return {
      header: <div>{Subject}</div>,
      body: <div>{Description + " " + AdditionalInformation}</div>
    };
  }

  return {
    header: Subject,
    body: Description,
  };
};

const MovexResponseError = { getMovexErrorContent };

export default MovexResponseError;
