import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetReportsJobsByUserRequest, ReportsJobsByUserResponse } from '../../../../../Models/Reports/JobsByUser';

export const useGetJobsByUserList = (payload: GetReportsJobsByUserRequest,
    options?: any
): UseQueryResult<ReportsJobsByUserResponse, any> => {

    const getJobsByUserList = ({ queryKey }: any) => {
        return agent.reports.getReportJobsByUsers(payload)
        
    }

    return useQuery(['GetReportsNewOrUpdatedMembers', payload], getJobsByUserList, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}
