import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { CommonRequestParam } from '../../../../../../Types';
import { AdminRoleResponseModel } from '../../../../../../Models/System';
export const useGetAdminRoles = (payload: CommonRequestParam,
  options?: any
): UseQueryResult<AdminRoleResponseModel, any> => {

  const getAdminRoleList = () => {
    return agent.adminRole.getAdminRoles(payload);
  };

  return useQuery(['GetAdminRoleList', payload], getAdminRoleList, {
    ...options,
  });
};
