import { Action, Reducer } from "redux";
import { MembersActionTypes, MembersState } from "./Types";

const unloadedState: MembersState = {
    selectedMember: undefined,
};

export const reducer: Reducer<MembersState> = (
    state: MembersState | undefined,
    incomingAction: Action
): MembersState => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as MembersActionTypes;
    switch (action.type) {

        case 'SHOW_GENERAL_SCROLLBAR':
            return {
                ...state, isScrollbarVisible: action.payload
        }
        default:
            return state;
    }
};
