export enum HearAboutMovex {
    Telesales = 0,
    Referral = 1,
    Google = 6,
    Autotrade_Mail = 5,
    Auction4Cars = 7,
    Partner = 8,
    AMMagazine = 9,
    AMOnline = 10,
    MotorTraderMagazine = 11,
    MotorTraderOnline = 12,
    InstantPrices = 13,
    Other = 255
}