import { AppThunkAction } from '../../../App';
import * as Storage from '../../../Utils/Storage';
import { CurrentSavedFilters, GridsActionTypes } from './Types';

export const gridsActionCreators = {
     setGridFiltering:
          (filter: CurrentSavedFilters): AppThunkAction<GridsActionTypes> =>
          (dispatch, getState) => {
               const appState = getState();
               if (appState) {
                    Storage.setFiltering(filter);
                    dispatch({ type: 'SET_GRID_FILTERING', payload: filter });
               }
          },
};
