import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddTargetToPriceGroupRequest } from '../../../../../Models/PriceGroup';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddTargetToPriceGroup = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddTargetToPriceGroupRequest> => {
     const addTargetToPriceGroup = (payload: AddTargetToPriceGroupRequest) => {
          return agent.priceGroup.postAddTargetToPriceGroup(payload);
     };

     return useMutation(addTargetToPriceGroup);
};
