/** Import inbuilt libraries */
import { Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import CSS from "csstype";
import React from "react";
/** Import icons */
import {
  GeneralPublicIcon,
  SupplierIcon,
  UserIcon,
} from "../../../../../Assets/Images";
import { ControlledPagination as Pagination } from "../../../../../Components";
import { CellRenderer } from "../../../../../Components/Formik";
/** Import Custom Components */
import { HoverableLink } from "../../../../../Components/Formik/StyledComponents";
import {
  NewOrUpdatedMemberItemModelKeys,
  ReportsNewOrUpdatedMembersResponse,
} from "../../../../../Models/NewOrUpdatedMembers";
import { ReportsJobsByUserResponse } from "../../../../../Models/Reports/JobsByUser";
import { JobsByUserItemModelKeys } from "../../../../../Models/Reports/JobsByUser/JobsByUserItemModel";
import { GridProps, Sorter } from "../../../../../Types";
import { getSortModelFromSorter } from "../../../../../Utils";
import { MemberType } from "../../../../../Utils/Enums";

const {
  renderDateWithTimeCell,
  renderDate,
  renderCellWithTooltip,
  renderServiceTypeCell,
  renderJobTypeCell,
  renderCurrencyCell
} = CellRenderer;

const columns: GridColDef[] = [
  {
    field: JobsByUserItemModelKeys.Id,
    headerName: "Job ID",
    flex: 16,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ""}>
        <HoverableLink to={`edit-job/${params.id}`}>
          {params.value}
        </HoverableLink>
      </Tooltip>
    ),
  },
  {
    field: JobsByUserItemModelKeys.UserName,
    headerName: "UserName",
    flex: 16,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: JobsByUserItemModelKeys.Email,
    headerName: "Email",
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    flex: 4,
    minWidth: 250,
  },

  {
    field: JobsByUserItemModelKeys.RequestDate,
    headerName: "Request Date",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderDate(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Request Date
      </div>
    )
  },
  {
    field: "JobsByUserItemModelKeys.ActiveDate",
    headerName: "Active Date",
    description: "Active Date",
    renderCell: (params: GridRenderCellParams) => renderDate(params),
    flex: 2,
    minWidth: 80,
    headerClassName: "headerStyleTest"
  },
  {
    field: JobsByUserItemModelKeys.CollectionDate,
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderDate(params),
    minWidth: 80,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Collection Date
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.DeliveryDate,
    headerName: "Delivery Date",
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderDate(params),
    minWidth: 80,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Delivery Date
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.CollectionPostCode,
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    minWidth: 80,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Collection PostCode
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.DeliveryPostCode,
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    minWidth: 80,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Delivery Post Code
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.DistanceMiles,
    headerName: "Miles",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => {
      params.value = Math.round(params.value);
      return renderCellWithTooltip(params);
    },
  },
  {
    field: JobsByUserItemModelKeys.TransportCompanyName,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    flex: 4,
    minWidth: 80,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Transport Company
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.ServiceType,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderServiceTypeCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Service Type
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.ActualServiceType,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Actual Service Type
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.IncludingFuel,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Including Fuel
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.JobType,
    headerName: "Job Type",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderJobTypeCell(params),
  },
  {
    field: JobsByUserItemModelKeys.ActualJobType,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Actual Job Type
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.NoOfVehicles,
    headerName: "No. vehicles",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: JobsByUserItemModelKeys.JobStatus,
    headerName: "Job Status",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: JobsByUserItemModelKeys.CancellationReason,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Cancellation Reason
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.NoOfQuotes,
    headerName: "No. Quotes",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: JobsByUserItemModelKeys.Cost,
    headerName: "Cost",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
  },
  {
    field: JobsByUserItemModelKeys.Vat,
    headerName: "Vat",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),  },
  {
    field: JobsByUserItemModelKeys.CostPerMile,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Cost per Mile
      </div>
    )
    },
  {
    field: JobsByUserItemModelKeys.CostPerMilePerVehicle,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Cost Per Mile Per Vehicle
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.CostExclusive,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
       Cost Exclusive
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.CostExclusivePerMile,
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Cost Exclusive Per Mile
      </div>
    )
  },
  {
    field: JobsByUserItemModelKeys.CostExclusivePerMilePerVehicle,
    headerName: "Cost Exclusive Per Mile Per Vehicle",
    flex: 4,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Cost Exclusive Per Mile Per Vehicle
      </div>
    )
  }
];

const renderCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value || ""}>
      <span>{params.value}</span>
    </Tooltip>
  );
};

const JobsByUserGridView: React.FC<JobsByUserGridViewProps> = (
  props
) => {
  const { data, onPageChange, onPageSizeChange, onSort } = props;
  

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  const { Page: page, TableSorter } = data;

  if (page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = page;
    
   
    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            pageSize={ItemsPerPage}
            currentPage={CurrentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    return (
      <div>
        {page && (
          <div className="new-or-updated-users-grid-container">
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <div id='id_grid_jobs_by_user' style={{ flexGrow: 1 }}>
                <DataGrid
                  sortingOrder={['desc', 'asc']}
                  getRowId={(row: any) => row.Id}
                  autoHeight
                  rows={Items}
                  rowHeight={41}
                  disableColumnMenu={true}
                  columns={columns}
                  //columnBuffer={columns.length + 1}
                  components={{
                    Toolbar: CustomGridFooter,
                    Footer: CustomGridFooter,
                  }}
                  disableExtendRowFullWidth={true}
                  sortingMode="server"
                  sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                  onSortModelChange={handleSort}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default JobsByUserGridView;

interface JobsByUserGridViewProps
  extends GridProps<ReportsJobsByUserResponse> {}

const imageStyle: CSS.Properties = { height: "25px", width: "25px" };
