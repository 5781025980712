import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddTransporterPlateRequest } from '../../../../../../../Models/Members/EditMember/AddTradePlatesRequest';
import { AddTradePlateResponse } from '../../../../../../../Models/Members/EditMember/AddTradePlatesResponse';
import { MovexErrorResponse } from '../../../../../../../Types';

export const usePostAddTransporterPlates = (
     options?: UseMutationOptions<AddTradePlateResponse, MovexErrorResponse, AddTransporterPlateRequest>
): UseMutationResult<AddTradePlateResponse, MovexErrorResponse, AddTransporterPlateRequest> => {
     const addTradePlates = (payload: AddTransporterPlateRequest) => {
          return agent.members.postAddTransporterPlates(payload);
     };

     return useMutation(addTradePlates, {
          ...options,
          onError: (error: MovexErrorResponse, variables: AddTransporterPlateRequest, context: unknown) => {
               options?.onError?.(error, variables, context);
          },
     });
};
