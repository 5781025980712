import { useFormik } from 'formik';
import React from 'react';
import {
  JobKind,
  JobStatus,
  QuoteType,
  ServiceType,
} from '../../../../../Utils/Enums';

export const JobDetails = (model: any) => {
  const {
    DeliveryContact,
    DeliveryAddress,
    DeliveryCompany,
    DeliveryTown,
    DeliveryPostCode,
    DeliveryPhone,
    CollectionContact,
    CollectionAddress,
    CollectionCompany,
    CollectionTown,
    CollectionPostCode,
    CollectionPhone,
  } = model;

  const ShowSupplier = true;
  const ShowLeaveFeedback = true;
  const HasFeedback = true;
  const ShowBlockMember = true;
  const supplierBlocked = true;
  const ShowPOCs = true;
  const ShowPODs = true;

  let jobKind = JobKind.Onward;
  let quoteType = QuoteType.Driven;
  let jobStatus = JobStatus.BookedJob;
  let serviceType = ServiceType.Both;

  const ID = 0;
  const ShowTrackMyVehicles = true;

  const proofsOfDelivery: any[] = [];
  const proofsOfCollection: any[] = [];
  const shippingNotes: any[] = [];
  const AdditionalNotes: any[] = [];
  const segmentsInChunks: any[] = [];
  const formikInitialValues = {};

  const onSubmit = (values: any) => {};

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });

  return (
    <div className="job-details-page">
      <div className="container-fluid">
        <div
          className="row details-row"
          style={{ display: 'none' }}
          data-bind="visible: job().ID"
        >
          <div className="container">
            <div className="row row-section">
              <div className="col-lg-6 col-print-6">
                {ShowSupplier && (
                  <div className="details-box">
                    <header>
                      <div className="heading">Supplier Details</div>
                      {ShowLeaveFeedback && (
                        <>
                          {HasFeedback && (
                            <span className="msg-feedback-left-already">
                              Feedback left already.
                            </span>
                          )}
                          {!HasFeedback && (
                            <a
                              className="btn btn--feedback movex-btn-primary"
                              data-bind="attr: { href: '/motor-dealers/leave-feedback/?jobID=' +  $root.job().ID }"
                            >
                              Leave Feedback
                            </a>
                          )}
                        </>
                      )}
                    </header>
                    <div className="content">
                      <div>
                        <table id='id_table_job_detail_company' className="details">
                          <tr>
                            <th>COMPANY:</th>
                            <td>
                              {ID > 0 && (
                                <span data-bind="text: UserName"></span>
                              )}
                              {ID <= 0 && (
                                <span data-bind="text: Company"></span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>CONTACT(S):</th>
                            <td>
                              <span data-bind="text: Contact_Name1"></span>
                              <br />
                              <span data-bind="text: Address1"></span>
                              <span data-bind="text: Address2"></span>
                              <span data-bind="text: Address3"></span>
                              <span data-bind="text: Town"></span>
                              <span data-bind="text: County"></span>
                            </td>
                          </tr>
                          <tr>
                            <th>TEL:</th>
                            <td>
                              <span data-bind="text: Landline"></span>
                            </td>
                          </tr>
                          <tr>
                            <th>MOB:</th>
                            <td>
                              <span data-bind="text: Mobile"></span>
                            </td>
                          </tr>
                          {ShowBlockMember && (
                            <tr>
                              <td colSpan={2}>
                                {!supplierBlocked && (
                                  <a
                                    href="#block-company"
                                    className="btn--block-company"
                                    data-bind="click: $root.BlockMember"
                                  >
                                    <i className="fa fa-ban"></i> BLOCK THIS
                                    COMPANY
                                  </a>
                                )}
                                {supplierBlocked && (
                                  <a
                                    href="#unblock-company"
                                    className="btn--unblock-company"
                                    data-bind="click: $root.UnblockMember"
                                  >
                                    <i className="fa fa-ban"></i> UNBLOCK THIS
                                    COMPANY
                                  </a>
                                )}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-print-6">
                {jobStatus === JobStatus.BookedJob && (
                  <div className="details-box">
                    <header>
                      <div className="heading">Booked Details</div>
                    </header>
                    <div className="content">
                      <table id='id_table_job_booked_detailed' className="details">
                        <tr>
                          <th>TYPE:</th>
                          <td className="service-type-cell">
                            {ServiceType.Driven === ServiceType.Driven && (
                              <i
                                className="icon icon-servicetype-driven"
                                title="Driven"
                              ></i>
                            )}
                            {ServiceType.Transported ===
                              ServiceType.Transported && (
                              <i
                                className="icon icon-servicetype-transported"
                                title="Transported"
                              ></i>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>PRICE:</th>
                          <td>
                            {ServiceType.Driven === ServiceType.Driven && (
                              <span data-bind="text: '£' + Number(DGuaranteedPrice).toFixed(2) + ' +VAT (inc. fuel)'"></span>
                            )}
                            {ServiceType.Transported ===
                              ServiceType.Transported && (
                              <span data-bind="text: '£' + Number(TGuaranteedPrice).toFixed(2) + ' +VAT'"></span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>COLLECTION DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(CollectionDate)"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>DELIVERY DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(DeliveryDate)"></span>
                          </td>
                        </tr>
                        {shippingNotes.length > 0 && (
                          <tr>
                            <th>SHIPPING NOTES:</th>
                            <td>
                              {shippingNotes.map((notes) => {
                                return (
                                  <>
                                    <a
                                      target="_blank"
                                      data-bind="text: Name, attr: { href: '/files/jobs-shipping-note?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }"
                                    ></a>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                )}
                {JobStatus.ActiveJob === JobStatus.ActiveJob && (
                  <div className="details-box">
                    <header>
                      <div className="heading">Active Details</div>
                      {ShowTrackMyVehicles && (
                        <>
                          <a
                            className="btn btn--track-my-vehicles movex-btn-primary"
                            data-bind="attr: { href: '/motor-dealers/track-my-vehicles/?jobID=' + $root.job().ID  }"
                          >
                            Track My Vehicles
                          </a>
                          <br />
                        </>
                      )}
                    </header>
                    <div className="content">
                      <table id='id_table_quote_type' className="details">
                        <tr>
                          <th>TYPE:</th>
                          <td>
                            {QuoteType.Driven === QuoteType.Driven && (
                              <i
                                className="icon icon-servicetype-driven"
                                title="Driven"
                              ></i>
                            )}
                            {QuoteType.Transported ===
                              QuoteType.Transported && (
                              <i
                                className="icon icon-servicetype-transported"
                                title="Transported"
                              ></i>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>PRICE:</th>
                          <td>
                            <span data-bind="text: '£' + Number(DiscountedAmount).toFixed(2) + PriceSuffix()"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>COLLECTION DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(CollectionDate)"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>DELIVERY DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(DeliveryDate)"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>NOTES:</th>
                          <td>
                            <div data-bind="html: ReplaceLineBreaksWithBR(AdditionalNotes && AdditionalNotes.length > 100 ? AdditionalNotes.substring(0, 99) + '...' : AdditionalNotes)"></div>
                            {AdditionalNotes && AdditionalNotes.length > 100 && (
                              <span
                                className="link"
                                data-bind="popover: { title: 'Notes', html: true, content: ReplaceLineBreaksWithBR(AdditionalNotes) }"
                              >
                                view
                              </span>
                            )}
                          </td>
                        </tr>
                        {shippingNotes.length > 0 && (
                          <tr>
                            <th>SHIPPING NOTES:</th>
                            <td>
                              {shippingNotes.map((notes) => {
                                return (
                                  <>
                                    <a
                                      href="#"
                                      target="_blank"
                                      data-bind="text: Name, attr: { href: '/files/jobs-shipping-note?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }"
                                    ></a>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                )}
                {JobStatus.CompletedJob === JobStatus.CompletedJob && (
                  <div className="details-box">
                    <header>
                      <div className="heading">Completed Details</div>
                    </header>
                    <div className="content">
                      <table id='id_table_job_completed_details' className="details">
                        <tr>
                          <th>TYPE:</th>
                          <td>
                            {quoteType === QuoteType.Driven && (
                              <i
                                className="icon icon-servicetype-driven"
                                title="Driven"
                              ></i>
                            )}
                            {QuoteType.Transported ===
                              QuoteType.Transported && (
                              <i
                                className="icon icon-servicetype-transported"
                                title="Transported"
                              ></i>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>PRICE:</th>
                          <td>
                            <span data-bind="text: '£' + Number(DiscountedAmount).toFixed(2) + PriceSuffix()"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>COLLECTION DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(CollectionDate)"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>DELIVERY DATE:</th>
                          <td>
                            <span data-bind="text: ReFormatUkDate(DeliveryDate)"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>NOTES:</th>
                          <td>
                            <div data-bind="html: ReplaceLineBreaksWithBR(AdditionalNotes && AdditionalNotes.length > 100 ? AdditionalNotes.substring(0, 99) + '...' : AdditionalNotes)"></div>
                            {AdditionalNotes && AdditionalNotes.length > 100 && (
                              <span
                                className="link"
                                data-bind="popover: { title: 'Notes', html: true, content: ReplaceLineBreaksWithBR(AdditionalNotes) }"
                              >
                                view
                              </span>
                            )}
                          </td>
                        </tr>
                        {shippingNotes.length > 0 && (
                          <tr>
                            <th>SHIPPING NOTES:</th>
                            <td>
                              {shippingNotes.map((notes) => {
                                return (
                                  <>
                                    <a
                                      href="#"
                                      target="_blank"
                                      data-bind="text: Name, attr: { href: '/files/jobs-shipping-note?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }"
                                    ></a>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row row-section">
              <div className="col-lg-6 col-print-6">
                <div className="details-box">
                  <header>
                    <div className="heading">
                      Collection Details
                      {(jobKind === JobKind.Onward ||
                        jobKind === JobKind.MultipleDropOff) && <span>(A)</span>}
                    </div>
                    {ShowPOCs && (
                      <>
                        {proofsOfCollection.map((proof) => {
                          return (
                            <>
                              <a
                                href="#"
                                target="_blank"
                                className="btn btn--proof-of-collection movex-btn-primary"
                                data-bind="attr: { href: '/files/jobs-proof-of-collection?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }"
                              >
                                Collection Report
                              </a>
                              <br />
                            </>
                          );
                        })}
                      </>
                    )}
                  </header>
                  <div className="content">
                    <div className="row-field">
                      {CollectionContact && (
                        <span data-bind="text: CollectionContact"></span>
                      )}
                    </div>
                    <div className="row-field">
                      {CollectionContact && (
                        <>
                          <span data-bind="text: CollectionCompany"></span>
                          <br />
                        </>
                      )}
                      {CollectionAddress && (
                        <>
                          <span data-bind="text: CollectionAddress"></span>
                          <br />
                        </>
                      )}
                      {CollectionTown && (
                        <>
                          <span data-bind="text: CollectionTown"></span>
                          <br />
                        </>
                      )}
                      {CollectionPostCode && (
                        <span data-bind="text: CollectionPostCode"></span>
                      )}
                    </div>
                    <div className="row-field">
                      {CollectionPhone && (
                        <>
                          Tel: <span data-bind="text: CollectionPhone"></span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-print-6">
                <div className="details-box">
                  <header>
                    <div className="heading">
                      Delivery Details
                      {jobKind === JobKind.Onward && <span>(B)</span>}
                      {JobKind.MultipleDropOff === JobKind.MultipleDropOff && (
                        <span>(B) 1st Drop</span>
                      )}
                    </div>

                    {ShowPODs && (
                      <>
                        {proofsOfDelivery.map((data) => {
                          {
                            data.JobID > 0 && (
                              <>
                                <div className="clearfix">
                                  <a
                                    href="#"
                                    target="_blank"
                                    className="btn btn--proof-of-delivery movex-btn-primary"
                                    data-bind="attr: { href: '/files/jobs-proof-of-delivery?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }"
                                  >
                                    Delivery Report
                                  </a>
                                </div>
                                {data.FuelReceipt && (
                                  <div className="clearfix">
                                    <a
                                      href="#"
                                      target="_blank"
                                      className="btn btn--fuel-receipt"
                                      data-bind="attr: { href: '/files/jobs-fuel-receipt?jobID=' + JobID + '&name=' + encodeURIComponent(FuelReceipt) }"
                                    >
                                      Fuel Receipt
                                    </a>
                                  </div>
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  </header>
                  <div className="content">
                    <div className="row-field">
                      {DeliveryContact && (
                        <span data-bind="text: DeliveryContact"></span>
                      )}
                    </div>
                    <div className="row-field">
                      {DeliveryCompany && (
                        <>
                          <span data-bind="text: DeliveryCompany"></span>
                          <br />
                        </>
                      )}
                      {DeliveryAddress && (
                        <>
                          <span data-bind="text: DeliveryAddress"></span>
                          <br />
                        </>
                      )}
                      {DeliveryTown && (
                        <>
                          <span data-bind="text: DeliveryTown"></span>
                          <br />
                        </>
                      )}
                      {DeliveryPostCode && (
                        <>
                          <span data-bind="text: DeliveryPostCode"></span>
                        </>
                      )}
                    </div>
                    <div className="row-field">
                      {DeliveryCompany && (
                        <>
                          Tel: <span data-bind="text: DeliveryPhone"></span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {jobKind === JobKind.Onward && (
              <>
                {segmentsInChunks.map((chunk) => {
                  return (
                    <div className="row row-section">
                      {chunk.map((segment: any) => {
                        const x = segment;
                        return (
                          <>
                            <div className="col-lg-6 col-print-6">
                              <div className="details-box">
                                <header>
                                  <div className="heading">
                                    Collection Details
                                    <span data-bind="text: '(' + String.fromCharCode(66 + segment.Index) + ')'"></span>
                                  </div>
                                </header>
                                <div className="content">
                                  <div className="row-field">
                                    {CollectionContact && (
                                      <span data-bind="text: CollectionContact"></span>
                                    )}
                                  </div>
                                  <div className="row-field">
                                    {CollectionCompany && (
                                      <>
                                        <span data-bind="text: CollectionCompany"></span>
                                        <br />
                                      </>
                                    )}
                                    {CollectionAddress && (
                                      <>
                                        <span data-bind="text: CollectionAddress"></span>
                                        <br />
                                      </>
                                    )}
                                    {CollectionTown && (
                                      <>
                                        <span data-bind="text: CollectionTown"></span>
                                        <br />
                                      </>
                                    )}
                                    {CollectionPostCode && (
                                      <span data-bind="text: CollectionPostCode"></span>
                                    )}
                                  </div>
                                  <div className="row-field">
                                    {CollectionPhone && (
                                      <>
                                        Tel:
                                        <span data-bind="text: CollectionPhone"></span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-print-6">
                              <div className="details-box">
                                <header>
                                  <div className="heading">
                                    Delivery Details
                                    <span data-bind="text: '(' + String.fromCharCode(67 + segment.Index) + ')'"></span>
                                  </div>
                                </header>
                                <div className="content">
                                  <div className="row-field">
                                    {DeliveryContact && (
                                      <span data-bind="text: DeliveryContact"></span>
                                    )}
                                  </div>
                                  <div className="row-field">
                                    {DeliveryCompany && (
                                      <>
                                        <span data-bind="text: DeliveryCompany"></span>
                                        <br />
                                      </>
                                    )}
                                    {DeliveryAddress && (
                                      <>
                                        <span data-bind="text: DeliveryAddress"></span>
                                        <br />
                                      </>
                                    )}
                                    {DeliveryTown && (
                                      <>
                                        <span data-bind="text: DeliveryTown"></span>
                                        <br />
                                      </>
                                    )}
                                    {DeliveryPostCode && (
                                      <>
                                        <span data-bind="text: DeliveryPostCode"></span>
                                      </>
                                    )}
                                  </div>
                                  <div className="row-field">
                                    {DeliveryPhone && (
                                      <>
                                        Tel:
                                        <span data-bind="text: DeliveryPhone"></span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
            {JobKind.MultipleDropOff === JobKind.MultipleDropOff && (
              <>
                {segmentsInChunks.map((chunk) => {
                  return (
                    <div className="row row-section">
                      {chunk.map((segment: any) => {
                        const x = segment;
                        return (
                          <div className="col-lg-6 col-print-6">
                            <div className="details-box">
                              <header>
                                <div className="heading">
                                  Delivery Details
                                  <span data-bind="text: '(' + String.fromCharCode(67 + segment.Index) + ') ' + ((segment.Index + 1) === $root.segments().length ? 'Last' : Ordinate(segment.Index + 2)) + ' drop'"></span>
                                </div>
                              </header>
                              <div className="content">
                                <div className="row-field">
                                  {DeliveryContact && (
                                    <span data-bind="text: DeliveryContact"></span>
                                  )}
                                </div>
                                <div className="row-field">
                                  {DeliveryCompany && (
                                    <>
                                      <span data-bind="text: DeliveryCompany"></span>
                                      <br />
                                    </>
                                  )}
                                  {DeliveryAddress && (
                                    <>
                                      <span data-bind="text: DeliveryAddress"></span>
                                      <br />
                                    </>
                                  )}
                                  {DeliveryTown && (
                                    <>
                                      <span data-bind="text: DeliveryTown"></span>
                                      <br />
                                    </>
                                  )}
                                  {DeliveryPostCode && (
                                    <span data-bind="text: DeliveryPostCode"></span>
                                  )}
                                </div>
                                <div className="row-field">
                                  {DeliveryPhone && (
                                    <>
                                      Tel:
                                      <span data-bind="text: DeliveryPhone"></span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
