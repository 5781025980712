import { useQuery, UseQueryResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { TaskDetailResponseModel } from '../../../../../Models/Tasks';

export const useGetTaskDetails = (
     taskID: number,
     options?: any
): UseQueryResult<TaskDetailResponseModel, any> => {
     const getTaskDetail = () => {
          if (taskID) {
               return adminTasks.getTaskDetails(taskID);
          }
     };

     return useQuery(['GetTaskDetail', taskID], getTaskDetail, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
