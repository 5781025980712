import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddPartnerModel } from '../../../../../Models/Partners';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddPartner = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddPartnerModel
> => {

  const addFeedback = (payload: AddPartnerModel) => {
    return agent.partners.postPartner(payload);
  };

  return useMutation(addFeedback);
};
