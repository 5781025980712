import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import {
  PaymentsGridFilterKeys,
  PaymentsGridFilter,
} from '../../../../../Models/Jobs/Payments';
import { PaymentsJobStatusOptions, PaymentStatusOptions } from '../Constants';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch, filter } = props;
  const initialValues: PaymentsGridFilter = filter;

  /** Methods */
  const onSubmit = (values: PaymentsGridFilter) => {
    onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const { handleSubmit, resetForm } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.JobID}
                    label="Job ID"
                    type="number"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.RequestDateFrom}
                    label="Request Date From"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.RequestDateTo}
                    label="Request Date To "
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.User}
                    label="User"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.Supplier}
                    label="Supplier"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.JobStatus}
                    control={FormControlKeys.Select}
                    label="Job Status"
                    options={PaymentsJobStatusOptions}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.PaymentID}
                    label="Payment ID"
                    type="number"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.DueDateFrom}
                    label="Due Date From"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.DueDateTo}
                    label="Due Date To"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={PaymentsGridFilterKeys.PaymentStatus}
                    label="Payment Status"
                    control={FormControlKeys.Select}
                    options={PaymentStatusOptions}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      type="reset"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;

export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
  filter: PaymentsGridFilter;
}
