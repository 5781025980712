/** Inbuilt libraries */
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
/** Custom Components */
import { FormControlKeys } from "../../../../../Components/FormControls";
import { FieldSetHeader } from "../../../../../Components/Formik";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import { JobViewModel } from "../../../../../Models/Jobs/EditJob";
import { UpdateJobAdminNoteModel } from "../../../../../Models/Jobs/EditJob/GeneralJobRequest/UpdateJobAdminNoteModel";
import { usePutUpdateJobAdminNotes } from "../Hooks/usePutUpdateJobAdminNotes";
import { EditJobKeys } from "../Types";

const SectionNoteForAdmin: React.FC<GeneralFormProps> = (props): JSX.Element => {
  
  const { job, showAlert} = props;
  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
  const [jobDetails, setjobDetails] = useState<JobViewModel>(job);

  /** Query Hooks */
  var {data, isLoading, mutate, error } = usePutUpdateJobAdminNotes();

  /** Methods */
  const onSubmit = (payload: any): void => {
    mutate({AdminNotes : values.NotesForAdmin, JobId : jobDetails.Id} as UpdateJobAdminNoteModel);
  };

  useEffect(() => {
    if(data){
      showAlert(
        'Job admin notes updated!',
        ''
      );
    }
  }, [data]);

  useEffect(() => {
    if(jobDetails){
      setjobDetails(job);
    }
  }, [job]);

  useEffect(() => {
    if(error){
      showAlert(
        error.Subject,
        error.Description
      );
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {NotesForAdmin : jobDetails.AdminNotes},
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, values, errors, validateForm, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <fieldset>
            <FieldSetHeader>Notes for admin use</FieldSetHeader>
            <FormikControl
              control={FormControlKeys.Textarea}
              name={EditJobKeys.NotesForAdmin}
            />
            <FormikControl
              control={FormControlKeys.Button}
              label="Update"
              type="submit"
              disabled={isWaitingData}
            />
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  job: JobViewModel;
  showAlert: Function;
};

export default SectionNoteForAdmin;
