import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateAdminUserDataResponseModel, UpdateAdditionalNotesModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateAdditionalNotes = (
  options?: any
): UseMutationResult<
  UpdateAdminUserDataResponseModel,
  any,
  UpdateAdditionalNotesModel
> => {
  const usePutUpdateAdditionalNotes = (payload: UpdateAdditionalNotesModel) => {
    return agent.jobs.putUpdateAdditionalNotes(payload);
  };

  return useMutation(usePutUpdateAdditionalNotes);
};
