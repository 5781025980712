import { object, string } from 'yup';
import { TasksGridFilterKeys } from '../../../../../Models/Tasks/TasksGridFilter';
import { SearchTypeOptions } from '../Constants';

export const createValidationSchema = (currentFreeTextSearchBy: number) => {
     const isJobIDValue = SearchTypeOptions.find((option) => option.label === 'Job ID')?.value == currentFreeTextSearchBy;
     const isMemberIdValue = SearchTypeOptions.find((option) => option.label === 'Member ID')?.value == currentFreeTextSearchBy;
     return object({
          [TasksGridFilterKeys.SearchTerm]:
               isJobIDValue || isMemberIdValue
                    ? string()
                           .matches(/^[0-9]*$/, 'ID must be a number')
                           .nullable()
                    : string(),
     });
};
