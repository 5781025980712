import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import { Badge } from "@mui/material";

// Styled component named StyledButton
const Wrapper = styled.section`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
`;

export function NavBar() {
  return (
    <Navbar bg="light">
      <Nav
        className="container-fluid"
        style={{ width: "100%", paddingInline: "3%" }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <Wrapper>
            <NavLink exact to="/_schedulingservice/">
              Hangfire Dashboard
            </NavLink>
          </Wrapper>
          <Wrapper>
            <NavLink to="/_schedulingservice/jobs">
              Jobs &nbsp; <Badge badgeContent={6} color="secondary"></Badge>
            </NavLink>
          </Wrapper>
          <Wrapper>
            <NavLink to="/_schedulingservice/retries">
              Retries &nbsp; <Badge badgeContent={4} color="secondary"></Badge>
            </NavLink>
          </Wrapper>
          <Wrapper>
            <NavLink to="/_schedulingservice/recurringJobs">
              Recurring Jobs &nbsp;
              <Badge badgeContent={1} color="secondary"></Badge>
            </NavLink>
          </Wrapper>
          <Wrapper>
            <NavLink to="/_schedulingservice/servers">
              Servers &nbsp; <Badge badgeContent={1} color="secondary"></Badge>
            </NavLink>
          </Wrapper>
        </div>
        <div style={{ width: "10%" }}>
          <NavLink to="/_schedulingservice/jobs">
            Back to site
          </NavLink>
        </div>
      </Nav>
    </Navbar>
  );
}
