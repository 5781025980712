import styled from 'styled-components';

export const StyledUl = styled.ul`
    padding-top: 10px;
`;

export const StyledLi = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
`;

export const RemoveLink = styled.a`
    text-decoration: none;
    color: red;
    font-size: 15px;
    font-weight: bold;
`;

export const StyledDiv = styled.div`
    padding-bottom: 15px;
`;