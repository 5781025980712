import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { RefundPrePaymentModel } from '../../../../../Models/Jobs/Payments';

export const usePutRefundPrePayment = (
  options?: any
): UseMutationResult<
  any,
  any,
  RefundPrePaymentModel
> => {

  const putRefundPrePayment = (payload: RefundPrePaymentModel) => {
    return agent.payments.putRefundPrePayment(payload);
  };

  return useMutation(putRefundPrePayment);
};