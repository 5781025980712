/** Inbuilt libraries */
import CSS from 'csstype';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, ModalDialog, ModalDialogProps, useAlert } from '../../../../Components';
/** Custom Components */
import {
     MainContentInnerContainer,
     MainContentOuterContainer,
     RowFluidContainer,
     Span8Offset2Container,
} from '../../../../Components/Formik';
import { Charge, PaymentModel } from '../../../../Models/Billing/AddBilling';
import { BillingModel } from '../../../../Models/Billing/AddBilling/BillingModel';
import { convertNullsToEmptyStrings } from '../../../../Utils';
import { useGetBill } from './Hooks/useGetBill';
import BillInformation from './Sections/BillInformation';
import BillVersion1 from './Sections/BillVersion1';
import BillVersion2 from './Sections/BillVersion2';
import BillVersion3a from './Sections/BillVersion3a';
import BillVersion3b from './Sections/BillVersion3b';
import { MarkAsPaidBody, MarkAsPaidFooter } from './Sections/MarkAsPaidModal';
import MemberInformation from './Sections/MemberInformation';
import PaymentInformation from './Sections/PaymentInformation';
import { BillIDParamType } from './Types';

export const cellImageStyle: CSS.Properties = { height: '25px', width: '25px' };

const ViewBill = () => {
     const { billID: paramBillID } = useParams<BillIDParamType>();
     const billID = paramBillID ? parseInt(paramBillID) : 0;

     const handleModalClose = () => {
          setModalContent({ ...modalContent, show: false });
     };

     const paymentsData = [] as PaymentModel[];
     const chargesData = [] as Charge[];

     const billingDummyData: BillingModel = {
          Amount: 0,
          BillType: '',
          BilledMemberID: 0,
          DeductionDate: null,
          ID: 0,
          InvoiceDate: '',
          InvoiceFileName: '',
          IsIncludedinCSV: false,
          IsInvoiceGenerated: false,
          IsInvoiceSent: false,
          MemberID: null,
          MemberPaymentMethod: 0,
          MemberPaymentReference: null,
          MemberSurcharge: null,
          MemberType: 0,
          MonthlyFee: null,
          PaymentDate: null,
          PaymentStatus: 0,
          RequestingMemberCodaGeneratedDate: null,
          RequestingMemberDiscount: 0,
          RequestingMemberFee: 0,
          RequestingMemberID: 0,
          RequestingMemberMonthlyFee: 0,
          RequestingMemberMonthlyFeePaidDate: null,
          RequestingMemberPayAsYouGoFee: 0,
          RequestingMemberTotal: 0,
          SupplyingMemberAmount: null,
          SupplyingMemberCodaGeneratedDate: null,
          SupplyingMemberFee: null,
          SupplyingMemberID: null,
          SupplyingMemberMonthlyFee: null,
          SupplyingMemberMonthlyFeePaidDate: null,
          SupplyingMemberTotal: null,
          Total: null,
          CorrectedTotal: null,
          UserName: '',
          Version: 0,
     };

     convertNullsToEmptyStrings(billingDummyData);
     /** useState */
     const [jobData, setJobData] = useState<BillingModel>(billingDummyData);
     const [chargesForBill, setchargesForBill] = useState(chargesData);
     const [paymentData, setPaymentData] = useState(paymentsData);

     const [modalContent, setModalContent] = useState<ModalDialogProps>({
          header: '',
          body: '',
          show: false,
          closeCallback: handleModalClose,
     });
         
     /** Custom Hook */
     const { alert, setAlert } = useAlert();
     
     /** Query Hook */
     const {
          data: getBillData,
          refetch: refetchGetBill,
          error : getBillError
     } = useGetBill(billID);

     /** useEffects */
     useEffect(() => {
          if (getBillData) {
               setJobData(getBillData.Bill);
               setchargesForBill(getBillData.ChargesForBillV1 ?? getBillData.ChargesForBillV2);
               setPaymentData(getBillData.ChargesForBillV3Supplier ?? getBillData.ChargesForBillV3User);
          }
     }, [getBillData]);
    
     useEffect(() => {
          if (billID) {
               refetchGetBill();
          }
     }, [billID]);

     useEffect(() => {
          if (getBillError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getBillError?.Subject,
                    body: getBillError?.Description,
               });
          }
     }, [getBillError]);

     /** Methods */

     const showMarkAsPaidModule = (
          billID: number,
          jobIDs: number[],
          requestingMemberMonthlyFeePaid: boolean,
          supplyingMemberMonthlyFeePaid: boolean
     ) => {
          setModalContent({
               ...modalContent,
               show: true,
               header: 'Mark Payment As Paid',
               body: (
                    <MarkAsPaidBody
                         billID={billID}
                         jobIDs={jobIDs}
                         requestingMemberMonthlyFeePaid={requestingMemberMonthlyFeePaid}
                         supplyingMemberMonthlyFeePaid={supplyingMemberMonthlyFeePaid}
                    />
               )});
     };

     return (
          <>
               <MainContentOuterContainer>
                    <MainContentInnerContainer>
                         <RowFluidContainer>
                              <Span8Offset2Container>
                                   <Alert {...alert} />
                                   <ModalDialog {...modalContent}></ModalDialog>

                                   <MemberInformation bill={jobData} />

                                   <BillInformation bill={jobData} />

                                   <PaymentInformation bill={jobData} />

                                   {jobData.Version === 1 && (
                                        <BillVersion1
                                             charges={chargesForBill}
                                             bill={jobData}
                                             payments={paymentData}
                                        />
                                   )}

                                   {jobData.Version === 2 && (
                                        <BillVersion2
                                             charges={chargesForBill}
                                             bill={jobData}
                                             payments={paymentData}
                                        />
                                   )}

                                   {jobData.Version === 3 && (jobData?.RequestingMemberID ?? 0) > 0 && (
                                        <BillVersion3a
                                             bill={jobData}
                                             payments={paymentData}
                                             charges={chargesForBill}
                                             onShowModule={showMarkAsPaidModule}
                                        />
                                   )}

                                   {jobData.Version === 3 && (jobData?.SupplyingMemberID ?? 0) > 0 && (
                                        <BillVersion3b
                                             bill={jobData}
                                             payments={paymentData}
                                             charges={chargesForBill}
                                             onShowModule={showMarkAsPaidModule}
                                        />
                                   )}
                              </Span8Offset2Container>
                         </RowFluidContainer>
                    </MainContentInnerContainer>
               </MainContentOuterContainer>
          </>
     );
};

export default ViewBill;
