export enum CustomConfigDealerAddJob {
    All = 'All',
    JobKindSection = 'JobKindSection',
    JobMovementTypeSection = 'JobMovementTypeSection',
    ServiceTypesSection = 'ServiceTypesSection',
    VehiclesSection = 'VehiclesSection',
    CollectionSection = 'CollectionSection',
    DeliverySection = 'DeliverySection',
    DatesSection = 'DatesSection',
    JobTypeSection = 'JobTypeSection',
    JobOfferedToSection = 'JobOfferedToSection',
    AttachPhotosSection = 'AttachPhotosSection',
    ShippingNotesSection = 'ShippingNotesSection',
    AdditionalNotesSection = 'AdditionalNotesSection',
    YourReferenceSection = 'YourReferenceSection',
    PromoCodeSection = 'PromoCodeSection',
    ModelUserSection = 'ModelUserSection',
}