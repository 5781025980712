export enum RequestingMemberFeeType {
    Default = 0,
    Group = 1,
    CustomerSet = 2,
    Dealer = 3,
}

export enum FeeType {
    FeeFixedPrice = 1,
    FeeQuotesPrice = 2,
}