/** Import Inbuilt libraries */
import { Tooltip } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { cellImageStyle } from "..";
import { InvoiceIcon } from "../../../../../Assets/Images";
import { ModalDialog, ModalDialogProps } from "../../../../../Components";
/** Custom Components */
import {
  FieldSetHeader,
  HoverableLink,
} from "../../../../../Components/Formik/StyledComponents";
import { BillingModel } from "../../../../../Models/Billing/AddBilling/BillingModel";
import { getFormattedDateWithYear } from "../../../../../Utils";
import { MemberType } from "../../../../../Utils/Enums";
import {
  LabelItem,
  SpanItem,
} from "../../../../../Components/UI";

const BillInformation: React.FC<GeneralFormProps> = (props): JSX.Element => {
  const { bill } = props;
  const handleModalClose = () => {
    setModalContent({ ...modalContent, show: false });
  };
  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<BillingModel>(bill);

  /** Methods */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const [modalContent, setModalContent] = useState<ModalDialogProps>({
    header: "",
    body: "",
    show: false,
    closeCallback: handleModalClose,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <fieldset>
            <br />
            <FieldSetHeader>Bill Information</FieldSetHeader>
            <ModalDialog {...modalContent}></ModalDialog>
            <div>
              <LabelItem>Version:</LabelItem>
              <SpanItem>{bill.Version}</SpanItem>
            </div>
            <div>
              <LabelItem>Bill Type:</LabelItem>
              <SpanItem>{bill.BillType}</SpanItem>
            </div>
            <div>
              {bill.Version === 1 && (
                <>
                  <LabelItem>Total Fee Amount:</LabelItem>
                  <SpanItem>£{Number(bill.CorrectedTotal ?? bill.TotalFee).toFixed(2)}</SpanItem>
                </>
              )}
              {bill.Version === 2 && (
                <>
                  <LabelItem>Total Charge &amp; Monthly Fee:</LabelItem>
                  <SpanItem>£{Number(bill.Total).toFixed(2)}</SpanItem>
                </>
              )}
              {bill.Version === 3 && (
                <>
                  {bill.MemberType === MemberType.MotorDealer && (
                    <>
                      <LabelItem>
                        Total Surcharge, PAYG, &amp; Monthly Fee:
                      </LabelItem>
                      <SpanItem>
                        £{Number(bill.RequestingMemberTotal).toFixed(2)}
                      </SpanItem>
                    </>
                  )}
                  {bill.MemberType === MemberType.TransportCompany && (
                    <>
                      <LabelItem>Total Fee &amp; Monthly Fee:</LabelItem>
                      <SpanItem>
                        £{Number(bill.SupplyingMemberTotal).toFixed(2)}
                      </SpanItem>
                    </>
                  )}
                </>
              )}
            </div>
            <div>
              <LabelItem>Invoice Date:</LabelItem>
              <SpanItem>{getFormattedDateWithYear(bill.InvoiceDate!)}</SpanItem>
            </div>
            <div>
              <LabelItem>Invoices:</LabelItem>
              <SpanItem>{bill.InvoiceFileName}</SpanItem>
              <div>
                {bill.InvoiceFileName?.length === 1 && bill.Version === 3 && (
                  <HoverableLink
                    to={
                      "/files/jobs-invoice?invoiceFileName=" +
                      encodeURIComponent(bill.InvoiceFileName[0])
                    }
                    title={bill.InvoiceFileName[0]}
                    target="_blank"
                  >
                    <Tooltip title="Invoice">
                      <img
                        alt="Invoice"
                        style={cellImageStyle}
                        src={InvoiceIcon}
                      />
                    </Tooltip>
                  </HoverableLink>
                )}
                <LabelItem></LabelItem>
                {bill.InvoiceFileName?.length! > 1 && bill.Version === 3 && (
                  <HoverableLink
                    to={
                      "/files/jobs-invoice?invoiceFileName=" +
                      encodeURIComponent(bill.InvoiceFileName)
                    }
                    title={bill.InvoiceFileName}
                    target="_blank"
                    >
                    <Tooltip title="">
                      <img
                        style={cellImageStyle}
                        src={InvoiceIcon}
                        />
                    </Tooltip>
                  </HoverableLink>
                )}
                {bill.InvoiceFileName &&
                  (bill.Version === 1 || bill.Version === 2) && (
                    <HoverableLink
                      to={
                        "/files/jobs-invoice?invoiceFileName=" +
                        encodeURIComponent(bill.InvoiceFileName[0])
                      }
                      title={bill.InvoiceFileName[0]}
                      target="_blank"
                    >
                      <Tooltip title="Invoice">
                        <img
                          alt="Invoice"
                          style={cellImageStyle}
                          src={InvoiceIcon}
                        />
                      </Tooltip>
                    </HoverableLink>
                  )}
              </div>
              <div>
                {bill.DeductionDate && (
                  <>
                    <LabelItem>Deduction Date:</LabelItem>
                    <SpanItem>
                      {getFormattedDateWithYear(bill.DeductionDate)}
                    </SpanItem>
                  </>
                )}
              </div>
            </div>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  bill: BillingModel;
};

export default BillInformation;
