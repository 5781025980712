import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { AdminRoleModel } from '../../../../../../Models/System';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';

export const usePostAddAdminRole = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AdminRoleModel
> => {

  const postAddAdminRole = (payload: AdminRoleModel) => {
    return agent.adminRole.postAddAdminRole(payload);
  };

  return useMutation(postAddAdminRole);
};
