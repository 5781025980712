import { OptionType } from "../../../../Components/FormControls";
import { VehicleType } from "../../../../Utils/Enums/VehicleType";
// import { VehicleType } from "../../../../Utils/Enums";

export const FilterVehicleTypeOptions: OptionType[] = [
    { value: -1, label: "All Types" },
    { value: VehicleType.Cars, label: "Cars" },
    { value: VehicleType.LightCommercial, label: "Light Commercial" },
]

export const AddVehicleTypeOptions: OptionType[] = [
    { value: VehicleType.Cars, label: "Cars" },
    { value: VehicleType.LightCommercial, label: "Light Commercial" },
    { value: VehicleType.HGV, label: "HGV" },
]