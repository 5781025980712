import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
     Alert,
     ConfirmDialog,
     ConfirmDialogProps,
     ShowPleaseWait,
     useAlert,
} from '../../../../Components';
import { FormControlKeys } from '../../../../Components/FormControls';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { GetPriceGroupResponse } from '../../../../Models/PriceGroup';
import { AddOrUpdatePriceGroupRequest } from '../../../../Models/PriceGroup/AddOrUpdatePriceGroupRequest';
import { getDropdownTypeOptions } from '../../../../Utils';
import { usePostAddPriceGroup, usePutUpdatePriceGroup } from './Hooks';

const PriceGroupGeneralForm: React.FC<PriceGroupGeneralFormProps> = ({
     getPriceGroupResponse,
     priceGroupId,
     refetchThePageWithTheNewlyCreatedPriceGroup,
}) => {
     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hooks */
     const {
          mutate: putUpdatePriceGroupMutate,
          isLoading: isPutUpdatePriceGroupLoading,
          data: putUpdatePriceGroupData,
          error: putUpdatePriceGroupError,
     } = usePutUpdatePriceGroup();

     const {
          mutate: postAddPriceGroupMutate,
          isLoading: isPostAddPriceGroupLoading,
          data: postAddPriceGroupData,
          error: postAddPriceGroupError,
     } = usePostAddPriceGroup();

     /** Formik */
     const formik = useFormik<GeneralPriceGroupFormikValues>({
          initialValues: {
               Type: getPriceGroupResponse?.PriceGroup?.Type ?? 0,
               Name: getPriceGroupResponse?.PriceGroup?.Name ?? "",
          },
          enableReinitialize: true,
          onSubmit: (values) => {
               if (priceGroupId) {
                    let payload = {
                         Name: values.Name,
                         Id: priceGroupId,
                         Type: values.Type,
                    } as AddOrUpdatePriceGroupRequest;
                    putUpdatePriceGroupMutate(payload);
               } else {
                    let payload = {
                         Name: values.Name,
                         Type: values.Type,
                    } as AddOrUpdatePriceGroupRequest;
                    postAddPriceGroupMutate(payload);
               }
          },
     });

     /** Reset Form */
     useEffect(() => {
          if (priceGroupId === 0) formik.resetForm({ values: { Name: '', Type: -1 } });
     }, []);

     useEffect(() => {
          if (postAddPriceGroupData?.PriceGroupViewModel?.Id) {
               setShowPleaseWait(false);
               setAlert({
                    ...alert,
                    show: true,
                    header: 'PriceGroup Added Successfully',
                    body:
                         'PriceGroup `' +
                         postAddPriceGroupData?.PriceGroupViewModel.Name +
                         '` was added successfully.',
                    closeCallback: () =>
                         refetchThePageWithTheNewlyCreatedPriceGroup(
                              postAddPriceGroupData?.PriceGroupViewModel?.Id
                         ),
               });
          }
     }, [postAddPriceGroupData]);

     useEffect(() => {
          if (postAddPriceGroupError?.Subject) {
               setShowPleaseWait(false);
               setAlert({
                    ...alert,
                    show: true,
                    header: postAddPriceGroupError?.Subject,
                    body: postAddPriceGroupError?.Description,
               });
          }
     }, [postAddPriceGroupError]);

     useEffect(() => {
          if (putUpdatePriceGroupData?.PriceGroupViewModel?.Id) {
               setShowPleaseWait(false);
               setAlert({
                    ...alert,
                    show: true,
                    header: 'PriceGroup Updated Successfully',
                    body:
                         'PriceGroup `' +
                         putUpdatePriceGroupData?.PriceGroupViewModel.Name +
                         '` was updated successfully.',
               });
          }
     }, [putUpdatePriceGroupData]);

     useEffect(() => {
          if (putUpdatePriceGroupError?.Subject) {
               setShowPleaseWait(false);
               setAlert({
                    ...alert,
                    show: true,
                    header: putUpdatePriceGroupError?.Subject,
                    body: putUpdatePriceGroupError?.Description,
               });
          }
     }, [putUpdatePriceGroupError]);

     useEffect(() => {
          if (isPostAddPriceGroupLoading || isPutUpdatePriceGroupLoading) {
               setShowPleaseWait(true);
          }
     }, [isPostAddPriceGroupLoading, isPutUpdatePriceGroupLoading]);

     return (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <form onSubmit={formik.handleSubmit}>
                    <FormikControl
                         name='Type'
                         control={FormControlKeys.Select}
                         label='Type'
                         placeholder='Select'
                         className='form-select'
                         options={getDropdownTypeOptions()}
                    />
                    <FormikControl name='Name' placeholder='Name' label='Name' />
                    <FormikControl control={FormControlKeys.Button} type='submit' label='Save' />
                    <Alert {...alert} />
                    <ShowPleaseWait show={showPleaseWait} />
                    <ConfirmDialog {...confirmDialogContent} />
               </form>
          </FormikProvider>
     );
};

export default PriceGroupGeneralForm;

export interface GeneralPriceGroupFormikValues {
     Type: number;
     Name: string;
}

type PriceGroupGeneralFormProps = {
     getPriceGroupResponse?: GetPriceGroupResponse;
     priceGroupId?: number;
     refetchThePageWithTheNewlyCreatedPriceGroup: Function;
};
