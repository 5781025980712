import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EditPriceDetails } from '../../../../../Models/PriceGroup';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePutUpdatePrice = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, EditPriceDetails> => {
     const updatePrice = (payload: EditPriceDetails) => {
          return agent.priceGroup.putUpdatePrice(payload);
     };

     return useMutation(updatePrice);
};
