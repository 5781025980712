import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { HoverableLink } from "../../../../../Components/Formik";
import { JobsVehicle, JobViewModel } from "../../../../../Models/Jobs/EditJob";
import { SpanBoldItem } from "../../../../../Components/UI";
import { RouteConstants } from "../../../../../Routes";

const SupplierInformation: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { job, vehicles } = props;

  /** useState */
  const [supplyingMemberSelected, setSupplyingMemberSelected] = useState<boolean>(false);
  const [shippingNotes, setShippingNotes] = useState<string>("");

  /** uesEffect */
  useEffect(() => {
    if(job.SupplyingMemberID && job.SupplyingMemberID > 0){
      setSupplyingMemberSelected(true);
    }
  },[job])


  /** Methods */
  const formik = useFormik({
    initialValues: { DealerPays: -1 },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  return (
    <>
      <fieldset>
        <legend>
          {supplyingMemberSelected
            ? "Selected Supplier Information"
            : "No Supplier Selected Yet!"}
        </legend>
        {supplyingMemberSelected && (
          <>
            <div className="row-fluid">
              <div className="span3">
                <strong>Selected Supplier:</strong>
              </div>
              <div className="span9">
                {(job.SupplyingMemberID && job.SupplyingMemberUserName) && (
                  <>
                  <HoverableLink to={`${RouteConstants.EditMember}/${job.SupplyingMemberID}`} target="_blank">
                    {" "}
                    <SpanBoldItem>{job.SupplyingMemberUserName}</SpanBoldItem>
                  </HoverableLink>
                  </>
                )}
              </div>
            </div>
            <div className="row-fluid">
              <div className="span3">
                <strong>Vehicle VRMs or VINs:</strong>
              </div>
              <div className="span9">
                {vehicles.map((vehicle, index) => (
                  <p>{vehicle.Vin? vehicle.Vin : vehicle.Vrm}</p>
                ))}
              </div>
            </div>
            <div className="row-fluid">
              <div className="span3">
                <strong>Shipping Notes:</strong>
              </div>
              <div className="span9">
                {shippingNotes && (
                  <a
                    target="_blank"
                    data-bind="attr: { href: '/files/jobs-shipping-note?jobID=' + JobID + '&name=' + encodeURIComponent(Name) }, text: Name"
                  ></a>
                )}
              </div>
            </div>
          </>
        )}
      </fieldset>
    </>
  );
};
type GeneralFormProps = {
  job: JobViewModel;
  vehicles: JobsVehicle[]
};

export default SupplierInformation;
