import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UpdatePaymentDueDateModel } from '../../../../../Models/Jobs/Payments';

export const usePutUpdatePaymentDueDate = (
  options?: any
): UseMutationResult<
  any,
  any,
  UpdatePaymentDueDateModel
> => {

  const putUpdatePaymentDueDate = (payload: UpdatePaymentDueDateModel) => {
    return agent.payments.putUpdatePaymentDueDate(payload);
  };

  return useMutation(putUpdatePaymentDueDate);
};