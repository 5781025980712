import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import * as Yup from 'yup';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  DivContainer,
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { InvoicesFilterKeys , InvoicesFilter} from '../../../../../Models/Billing';
import { InvoiceTypeOptions } from '../Constants';

const validationSchema = Yup.object().shape({
  InvoiceID: Yup.string().matches(/^[0-9]+$/, 'Invalid Invoice ID format'),
  JobID: Yup.string().matches(/^[0-9]+$/, 'Invalid Job ID format'),
});

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { filter, onSearch, onClearSearch } = props;
  const initialValues: InvoicesFilter = { 
    InvoiceID: undefined,
    InvoiceNo: '',
    Type: undefined,
    MemberCompany: '',
    InvoiceDateFrom: '',
    InvoiceDateTo: '',
    JobID: undefined,
    MemberCodaCustomerReference: '',
    MemberCodaSupplierReference: ''
  }

  const onSubmit = (values: InvoicesFilter) => {
    onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { handleSubmit, resetForm } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  useEffect(() => {
    if (filter) {
      formik.setValues(filter);
    }
  }, [filter]);

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter By</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <DivContainer>
                <h6>Invoice :</h6>
                <Row>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.InvoiceID}
                      label="ID"
                      title="Searches by ID"
                    />
                  </Col>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.InvoiceNo}
                      label="Invoice No."
                      title="Searches by Invoice No"
                    />
                  </Col>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.Type}
                      control={FormControlKeys.Select}
                      label="Type"
                      title="Searches by Invoice Type"
                      options={InvoiceTypeOptions}
                    />
                  </Col>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.MemberCodaCustomerReferenceID}
                      label="Coda Cust. Ref."
                      title="Searches by Member Coda Customer Reference (aka C-Ref)"
                    />
                  </Col>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.MemberCodaSupplierReferenceID}
                      label="Coda Supp. Ref."
                      title="Searches by Member Coda Supplier Reference (aka S-Ref)"
                    />
                  </Col>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.MemberCompany}
                      label="Company"
                      title="Searches by Member Company"
                    />
                  </Col>
                </Row>
                <Row>
                <Col>
                  <FormikControl
                    name={InvoicesFilterKeys.InvoiceDateFrom}
                    label="Invoice date from"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={InvoicesFilterKeys.InvoiceDateTo}
                    label="Invoice date to"
                    type="date"
                  />
                </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Row>
                  <br />
                </Row>
              </DivContainer>
              <DivContainer>
                <h6>Line item :</h6>
                <Row>
                  <Col>
                    <FormikControl
                      name={InvoicesFilterKeys.JobID}
                      label="Job ID"
                      title="Searches by Job ID"
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <RowFluidContainer>
                  <Col>
                    <div>
                      <FormikControl
                        name="Search"
                        label="Search"
                        type="submit"
                        className="btn btn-light span2"
                        control={FormControlKeys.Button}
                      />
                      <span style={{ marginRight: 15 }}></span>
                      <FormikControl
                        name="Clear"
                        label="Clear"
                        type="reset"
                        className="btn btn-light span2"
                        onClick={handleClearSearch}
                        control={FormControlKeys.Button}
                      />
                    </div>
                  </Col>
                  <Col></Col>
                </RowFluidContainer>
              </DivContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;
export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
  filter: InvoicesFilter;
}
