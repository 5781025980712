import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import { Box, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { FeedbackRating } from '../../Utils/Enums';
import TextError from '../Formik/TextError';

export function RatingInput({ ...props }) {
  const { label, name, showRequired, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const { value, touched, error } = meta;
  const { setValue } = helpers;

  const [hover, setHover] = React.useState(-1);
  const labels: { [index: number]: string } = {
    [FeedbackRating.VeryPoor]: 'Very Poor',
    [FeedbackRating.Poor]: 'Poor',
    [FeedbackRating.Average]: 'Average',
    [FeedbackRating.Good]: 'Good',
    [FeedbackRating.VeryGood]: 'Very Good',
  };
  return (
    <div className="mb-3 form-group ">
      <label className="fw-600">{label}</label>
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <div style={{ marginTop: 10 }}></div>
      <Box
        sx={{
          width: 200,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Field name={name}>
          {({ field }: { field: any }) => {
            return (
              <Rating
                name="hover-feedback"
                size="large"
                value={value}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            );
          }}
        </Field>
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RatingInput;
