import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';

export const useDeleteMemberFavourites = (
  options?: any
): UseMutationResult<any, any, number[]> => {
  const deleteMemberFavourites = (membersFavouritesIDs: number[]) => {
    return agent.members.deleteMemberFavourites(membersFavouritesIDs);
  };

  return useMutation(deleteMemberFavourites);
};
