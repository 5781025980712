import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useDeleteDealerMarginFixedPrice = (options?: any): UseMutationResult<any, any, number> => {
     const deletFeeFixedPrice = (id: number) => {
          return agent.dealerMargin.deleteDealerMarginFixedPrices(id);
     };

     return useMutation(deletFeeFixedPrice);
};
