import React from 'react';
import TableHeader, { TableHeaderProps } from './TableHeader';
import TableBody from './TableBody';
import './index.css';

const Table: React.FC<TableProps> = (props) => {
  const { columns, sorter, onSort, data } = props;
  return (
    <table className="table">
      <TableHeader columns={columns} sorter={sorter} onSort={onSort} />
      <TableBody columns={columns} data={data} />
    </table>
  );
};

export default Table;
interface TableProps extends TableHeaderProps {
  data: any[];
}
