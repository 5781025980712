export enum JobLockedReason {
     PaymentInProgress = 0,
     LockedForFeedback = 1,
     JobClosed = 2,
     NotLocked = 254,
     Unknown = 255,
}

const JobLockedReasonDisplayNames: { [key: number]: string } = {
     [JobLockedReason.PaymentInProgress]: 'Payment in progress',
     [JobLockedReason.LockedForFeedback]: 'Locked for feedback',
     [JobLockedReason.JobClosed]: 'Job closed',
     [JobLockedReason.NotLocked]: 'Unlocked',
     [JobLockedReason.Unknown]: 'Unknown',
};

export function getJobReason(reasonValue: number): string {
     const reason = JobLockedReasonDisplayNames[reasonValue];

     return reason || 'Invalid reason';
}

