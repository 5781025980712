import React from 'react';
import { CKEditor } from 'ckeditor4-react';

const CkEditorTemplate = (props: CkEditorTemplateProps) => {
  const { content } = props;
  return <CKEditor initData={content} />;
};

export default CkEditorTemplate;

type CkEditorTemplateProps = {
  content: any;
};
