import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { RowWrapper, ColWrapper } from '../../ViewPayments/Modals.tsx/style/PaymentModuleStyledComponents';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';

const SaveJobSearchModule: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { func, onClose } = props;

     const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

     /** Methods */
     const formik = useFormik({
          initialValues: { SaveSearchName: '', FreeTextSearchBy: FreeTextSearchByOptions.ID, ShowAsQuickSearch: false, SetAsDefaultValue: false },
          onSubmit: () => {
               func(values.SaveSearchName, values.ShowAsQuickSearch, values.SetAsDefaultValue);
          },
          enableReinitialize: true,
     });
     const { values } = formik;

     return (
          <>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <FormikControl control={FormControlKeys.LabelledInput} name='SaveSearchName' />
                         <FormikControl control={FormControlKeys.Checkbox} name='ShowAsQuickSearch' label='Show As Quick Search' />
                         <FormikControl control={FormControlKeys.Checkbox} name='SetAsDefaultView' label='Set As Default View' />
                         <RowWrapper>
                              <ColWrapper>
                              <FormikControl control={FormControlKeys.Button} name='Save' label='Save' type='submit' />
                              </ColWrapper>
                              <ColWrapper>
                              <FormikControl control={FormControlKeys.Button} name='Close' label='Close' onClick={onClose} />
                              </ColWrapper>
                         </RowWrapper>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     func: Function;
     onClose: Function;
};

export default SaveJobSearchModule;
