export enum AdminJobStatus {
     Cancelled = 0,
     Aborted = 6,
     Draft = 7,
     PendingQuotes = 1,
     AcceptedQuote = 2,
     BookedJob = 25,
     ActiveJob = 3,
     CompletedJob = 4,
     AdminRemoved_Or_Failed = 5,
     Expired = 100,
     Unknown = 255,
     All = 'All',
}
