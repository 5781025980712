import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  renderHeader,
  useAlert,
} from '../../../../Components';
import {
  GetInvoicesRequest,
  InvoiceItemModelKeys,
  InvoicesFilter,
} from '../../../../Models/Billing';
import { Pager } from '../../../../Types';
import {
  adjustToUTC,
  getSorterFromSortModel,
  Page,
} from '../../../../Utils';
import FilterBox from './FilterBox';
import ViewInvoicesGridView from './Grid';
import { useGetInvoices } from './Hooks';
import './index.css';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';

const ViewInvoices: React.FC = (): JSX.Element => {
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  const getInitialFilter = (): InvoicesFilter => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);

    return {
      InvoiceDateFrom: adjustToUTC(lastWeek).toISOString(),
      InvoiceDateTo: adjustToUTC(today).toISOString(),
    };
  };

  /** useStates */
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });
  const [triggerDataFetch, setTriggerDataFetch] = useState<boolean>(false);
  const [filter, setFilter] = useState<InvoicesFilter>(getInitialFilter);
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: InvoiceItemModelKeys.Id,
      sort: 'desc',
    },
  ]);

  /** Methods */
  const getParams = () => {
    const params: GetInvoicesRequest = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: InvoicesFilter) => {
    if (filter.InvoiceDateFrom) {
        const dateFrom = new Date(filter.InvoiceDateFrom);
        // Set the time portion of the date to midnight (start of the day).
        dateFrom.setHours(0, 0, 0, 0);
        // Get the timezone offset in minutes and convert it to milliseconds.
        // This offset is the difference in minutes between the local timezone and UTC.
        const offset = dateFrom.getTimezoneOffset() * 60000;
        // Adjust the date by the timezone offset to get a UTC representation.
        // This ensures that when we convert it to an ISO string, it will represent the same day in UTC.
        filter.InvoiceDateFrom = new Date(dateFrom.getTime() - offset).toISOString();
    }
    if (filter.InvoiceDateTo) {
        const dateTo = new Date(filter.InvoiceDateTo);
        dateTo.setHours(0, 0, 0, 0);
        const offset = dateTo.getTimezoneOffset() * 60000;
        filter.InvoiceDateTo = new Date(dateTo.getTime() - offset).toISOString();
    }
    setFilter(filter);
    setTriggerDataFetch(true);
};


  const handleClearSearch = () => {
    setFilter({
      InvoiceID: undefined,
      InvoiceNo: '',
      Type: undefined,
      MemberCompany: '',
      InvoiceDateFrom: '',
      InvoiceDateTo: '',
      JobID: undefined,
    });
    setTriggerDataFetch(true);
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
    setTriggerDataFetch(true);
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
    setTriggerDataFetch(true);
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
    setTriggerDataFetch(true);
  };

  /** Query Hooks */
  const { alert, setAlert } = useAlert();
  const reqOption = { enabled: false };
  const {
    data: getInvoicesResponse,
    isLoading: isGetInvoicesLoading,
    refetch: fetchInvoices,
    error : getInvoicesError
  } = useGetInvoices(getParams(), reqOption);
  const isDataLoaded = getInvoicesResponse != null;
  const isResultEmpty = getInvoicesResponse?.Page == null;

  /** useEffects */
  useEffect(() => {
    if (triggerDataFetch) {
      fetchInvoices();
    }
  }, [triggerDataFetch, pager, filter, sortModel]);
  
  useEffect(() => {
    if (getInvoicesError) {
         setAlert({
              ...alert,
              show: true,
              header: getInvoicesError?.Subject,
              body: getInvoicesError?.Description,
         });
    }
  }, [getInvoicesError]);

  return (
    <div>
      <Alert {...alert} />
      <ConfirmDialog {...confirmDialogContent} />
      {renderHeader('Invoices & Credit Notes')}
      <div className="container-fluid">
        <FilterBox filter={filter} onSearch={handleSearch} onClearSearch={handleClearSearch} />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        <Loading loading={isGetInvoicesLoading} />
        {isDataLoaded && (
          <ViewInvoicesGridView
            data={getInvoicesResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewInvoices;
