import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { MovexErrorResponse } from '../../../../../../../Types';
import { UpdateTradePlateRequest } from '../../../../../../../Models/Members/AddMember/UpdateTradePlateRequest';

export const usePutUpdateTradePlates = (options?: any): UseMutationResult<MovexErrorResponse, any, UpdateTradePlateRequest> => {
     const updateTradePlates = (payload: UpdateTradePlateRequest) => {
          return agent.members.putUpdateTradePlates(payload);
     };

     return useMutation(updateTradePlates);
};
