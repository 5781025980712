import React from 'react';
import './index.css';

export const Loading = ({
  loading,
}: {
  loading: boolean;
}): JSX.Element | null => {
  if (loading)
    return (
      <div className="loading-spinner">
        <div className="loading-placeholder">
          <i className="fa fa-spinner fa-spin fa-2x"></i>{' '}
          <strong className="loading-text">Loading...</strong>
        </div>
      </div>
    );
  return null;
};
