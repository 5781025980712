import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { ReOfferJobResponseModel } from '../../../../../Models/Jobs/EditJob/ReOfferJobResponseModel';

export const usePutReOfferJob = (options?: any): UseMutationResult<ReOfferJobResponseModel, any, number> => {
     const reofferJob = (payload: number) => {
          return agent.jobs.putReOfferJob(payload);
     };
     return useMutation(reofferJob);
};
