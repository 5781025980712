import { Action, Reducer } from "redux";
import { AddMemberActionTypes, AddMembersState } from "./Types";

const unloadedState: AddMembersState = {
    addUpdateMemberRequest: {
        Member: {
            Email: '',
            UserName: '',
            Password: '',
            PostCode: '',
            OnlyAcceptMovexPocPods: false,
        }
    },
    addUpdateMemberResponse: {},
    isApiLoading: false,
    getMemberFormValues: {
        Member: {
            UserName: '',
            Email: '',
            Password: '',
            ConfirmPassword: '',
            PostCode: '',
            OnlyAcceptMovexPocPods: false,
        }
    }
};

export const reducer: Reducer<AddMembersState> = (
    state: AddMembersState | undefined,
    incomingAction: Action
): AddMembersState => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as AddMemberActionTypes;
    switch (action.type) {
        case "ADD_MEMBER":
            return {
                ...state,
                addUpdateMemberRequest: {
                    ...action.payload
                },
            }
        case "RECEIVE_ADDED_MEMBER":
            return {
                ...state,
                addUpdateMemberResponse: {
                    response: action.response
                },
            }
        case "RECEIVE_ADD_MEMBER_ERROR":
            return {
                ...state,
                addUpdateMemberResponse: {
                    error: action.error
                },
            }
        case "UPDATE_MEMBER":
            return {
                ...state,
                addUpdateMemberRequest: {
                    ...action.payload
                },
            }
        case "RECEIVE_UPDATED_MEMBER":
            return {
                ...state,
                addUpdateMemberResponse: {
                    response: action.response
                },
            }
        case "RECEIVE_UPDATE_MEMBER_ERROR":
            return {
                ...state,
                addUpdateMemberResponse: {
                    error: action.error
                },
            }
        case "GET_MEMBER_FORM_VALUES":
            return {
                ...state,
                getMemberFormValues: action.payload
            }

        default:
            return state;
    }
};
