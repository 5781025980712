import { GridRenderCellParams } from "@mui/x-data-grid";
import { FormControlKeys } from "../../../../../Components/FormControls";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import { ArchiveButtonWrapper, CellContent, CellTextWrapper, CellWrapper, TextSpan, TextSpan8 } from "../../../../../Components/UI/Payments.styles";
import { getDayAndMonthDate } from "../../../../../Utils";
import { inspectionReportDownloadPOC, inspectionReportDownloadPOD } from "../../EditJob/Sections/UploadProof/downoladPOCPODHelper";

interface Props {
    archivePOC: Function,
    archivePOD: Function,
    params: GridRenderCellParams,
}

export function ArchiveCell({ archivePOC, archivePOD, params }: Props) {
    const {
        PoC_CreatedDate,
        PoC_ID,
        PoC_IsGenerated,
        PoC_Name,
        PoC_WaitingTime,

        PoD_CreatedDate,
        PoD_ID,
        PoD_IsGenerated,
        PoD_Name,
        PoD_WaitingTime
    } = params.row;

    const pocTitle = <TextSpan>
        POC{PoC_IsGenerated ? '-X' : ''} {PoC_WaitingTime > 0 ? '(W)' : ''}
    </TextSpan>

    const podTitle = <TextSpan>
        POD{PoD_IsGenerated ? '-X' : ''} {PoD_WaitingTime > 0 ? '(W)' : ''}
    </TextSpan>
    
    return (
        <CellWrapper>
            <CellContent>
                <CellTextWrapper>
                    {(PoC_ID && PoC_Name) ?
                        <a
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                inspectionReportDownloadPOC(PoC_ID, PoC_Name);
                            }}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {pocTitle}
                        </a>
                        : pocTitle}
                    {PoC_ID && <TextSpan8>{getDayAndMonthDate(PoC_CreatedDate)}</TextSpan8>}
                </CellTextWrapper>
                {PoC_ID && (
                    <ArchiveButtonWrapper>
                        <FormikControl
                            style={{
                                height: '20px',
                                paddingTop: '0px',
                            }}
                            control={FormControlKeys.Button}
                            label='Archive'
                            onClick={() => archivePOC(PoC_ID)}
                            size='sm'
                            variant='danger'
                        />
                    </ArchiveButtonWrapper>
                )}
            </CellContent>

            <CellContent>
                <CellTextWrapper>
                    {(PoD_ID && PoD_Name) ?
                        <a
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                inspectionReportDownloadPOD(PoD_ID, PoD_Name);
                            }}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {podTitle}
                        </a>
                        : podTitle}
                    {PoD_ID && <TextSpan8>{getDayAndMonthDate(PoD_CreatedDate)}</TextSpan8>}
                </CellTextWrapper>
                {PoD_ID && (
                    <ArchiveButtonWrapper>
                        <FormikControl
                            style={{
                                height: '20px',
                                paddingTop: '0px',
                            }}
                            control={FormControlKeys.Button}
                            label='Archive'
                            onClick={() => archivePOD(PoD_ID)}
                            size='sm'
                            variant='danger'
                        />
                    </ArchiveButtonWrapper>
                )}
            </CellContent>
        </CellWrapper>
    );
}
