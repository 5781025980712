import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';
import TextError from '../Formik/TextError';
import { OptionType } from './Types';

export const RadioGroup = ({ ...props }) => {
  const { name, options, label, showRequired, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);

  return (
    <div className="mb-3 form-group ">
      <label className="fw-600">{label}</label>{' '}
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <div style={{ marginTop: 10 }}>
        <Field name={name}>
          {({ field }: { field: any }) => {
            return options.map((option: OptionType) => {
              const { value: optVal, label } = option;
              const id = `${name}_${optVal}`;
              return (
                <div key={optVal} className="form-check-inline">
                  <Field
                    className="form-check-input"
                    type="radio"
                    id={id}
                    {...field}
                    {...rest}
                    value={optVal}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      if (value === 'true' || value === 'false') {
                        helpers.setValue(value === 'true');
                      } else if (!isNaN(value)) {
                        helpers.setValue(parseInt(value));
                      } else {
                        helpers.setValue(value);
                      }
                    }}                    
                    checked={field.value === optVal}
                  />
                  <label
                    style={{
                      paddingLeft: '10px',
                    }}
                    className="form-check-label"
                    htmlFor={id}
                  >
                    {label}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default RadioGroup;
export interface RadioGroupProps {
  name: string;
  options: OptionType[];
  label?: string;
  rest?: any;
  isInline?: boolean;
}
