import axios, { AxiosResponse } from 'axios';
import { RefreshTokenResponse } from '../Models/Session';
import { RouteConstants } from '../Routes';
import { refreshSessionApiTrigger } from '../Store/Session/Action';
import { RefreshApiParam } from '../Store/Session/Types';
import { MovexErrorResponse } from '../Types';
import { HTTP_STATUS_CODES } from '../Utils/Enums';
import { camelize, IsJsonString } from '../Utils/Helper';
import * as Storage from '../Utils/Storage';
import {
  admin,
  adminRole,
  bills,
  dealerMargin,
  feedback,
  groups,
  inspectionReport,
  invoices,
  jobActivityHistory,
  jobs,
  members,
  options,
  partners,
  payments,
  priceGroup,
  prices,
  reports,
  savedSearches,
  session,
  tasks,
  vehicles
} from './Screens';
/**
 * Error message constants
 */
const RESOURCE_NOT_FOUND_DEBUG = 'DEBUG: RESOURCE NOT FOUND. ERROR CODE 400';
const SERVER_NOT_RESPONDED = 'Movex Admin is currently unavailable. Please try again later!';

/**
 * Custom error
 */
export enum ApiErrorType {
  noNetwork = 'noNetwork',
  timeout = 'timeout',
  noResponse = 'noResponse',
  unAuthorized = 'unAuthorized',
}
class ApiResponseError extends Error {
  constructor(title: string, message: string, errorCode?: string) {
    super(message);
    this.name = title;
    this.message = message;
    this.stack = errorCode;
  }
}

export const jsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const _URLencodedConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-URLencoded',
  },
};

export const responseBody = (response: AxiosResponse) => {
  if (typeof response.data === 'string') {
    return response.data;
  }
  return camelize(response.data);
}

axios.interceptors.request.use(
  async (config) => {
    const token = await Storage.getAccessToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log('Promise reject from axios.interceptors.request.use', error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return (response);
  },
  async (error) => {
    if (!error) {
      return
    }

    console.log('axios.interceptors.response.use >>> error', error);

    /* Handle error for 403 Forbidden */
    if (error?.response?.status === HTTP_STATUS_CODES.FORBIDDEN) {
      let err: MovexErrorResponse = {
        ErrorCode: error.response.data.status,
        DefaultHttpResponse: HTTP_STATUS_CODES.FORBIDDEN,
        Subject: error.response.data.subject ?? 'Access Denied',
        Description: error.response.data.description ?? 'Your current role does not have the (API) permissions to perform this action. Please contact your administrator for more information.',
        AdditionalInformation: ''
      };
      throw err;
    }

    if (error.response.status === HTTP_STATUS_CODES.NOT_FOUND) {
      let err: MovexErrorResponse = {
        ErrorCode: error.response.data.status ?? error.response.data.errorCode,
        DefaultHttpResponse: HTTP_STATUS_CODES.NOT_FOUND,
        Subject: error.response.data.subject ?? 'Resource Not Found',
        Description: error.response.data.description ?? 'Resource Not Found',
        AdditionalInformation: ''
      };
      throw err;
    }

    /* Handle error for 429 Too Many Requests */
    if (error.response.status === HTTP_STATUS_CODES.TOO_MANY_REQUESTS) {
      let err: MovexErrorResponse = {
        ErrorCode: error.response.status,
        DefaultHttpResponse: HTTP_STATUS_CODES.TOO_MANY_REQUESTS,
        Subject: error.response.data.subject ?? 'Too Many Requests',
        Description: error.response.data.description ?? 'You have exceeded the maximum number of requests allowed. Please try again later.',
        AdditionalInformation: ''
      };
      throw err;
    }

    const status = error.response?.status;
    const originalRequest = error.config;

    /* Handle error for 401 Unauthorized or tooManyRequests for the REFRESH endpoint. */
    if (
      (status === HTTP_STATUS_CODES.UNAUTHORIZED
        || status === HTTP_STATUS_CODES.TOO_MANY_REQUESTS)
      && originalRequest.url === 'v1/Public/Refresh') {
      Storage.clearAllStorage();
      window.location.href = RouteConstants.Login;
    }

    /* Parse request data to json as after retrying refresh-api for 401, any api would use still old stringified-request */
    if (originalRequest && IsJsonString(originalRequest.data)) {
      originalRequest.data = JSON.parse(originalRequest.data);
    }

    /** Handle Session Timeout: (when the current request return 401 UNAUTHORIZED)
     *  The first step is to refresh the session, if it works then retry the current request.
     *  Otherwise (if the Refresh fails), redirect to the login page.
     */
    if (status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = Storage.getAccessToken();
        const refreshToken = Storage.getRefreshToken();
        if (accessToken && refreshToken) {
          const params: RefreshApiParam = {
            Access_Token: accessToken,
            Refresh_Token: refreshToken,
          };

          const refreshTokenResponse: RefreshTokenResponse = await refreshSessionApiTrigger(params).then(
            (success) => {
              return success;
            },
            (error) => {
              return error;
            }
          );

          if (refreshTokenResponse.Access_token) {
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + refreshTokenResponse.Access_token;
            console.log(
              'SESSIONTIMEOUT : retry session is  working .......',
              refreshTokenResponse
            );
            Storage.setAccessToken(refreshTokenResponse.Access_token);
            return axios(originalRequest);
          } else if (refreshTokenResponse.Status === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR
            || refreshTokenResponse.Status === HTTP_STATUS_CODES.TOO_MANY_REQUESTS) {
            Storage.clearAllStorage();
            window.location.href = RouteConstants.Login;
            return Promise.reject(error);
          } else {
            const error = new ApiResponseError(
              RESOURCE_NOT_FOUND_DEBUG,
              SERVER_NOT_RESPONDED,
              ApiErrorType.noResponse
            );
            return Promise.reject(error);
          }
        } else {
          const error = new ApiResponseError(
            RESOURCE_NOT_FOUND_DEBUG,
            SERVER_NOT_RESPONDED,
            ApiErrorType.noResponse
          );
          return Promise.reject(camelize(error));
        }
      }
    }

    if (error.response && error.response.data) {
      return Promise.reject(camelize(error.response.data));
    }
  }
);

const requests = {
  get: (_URL: string): Promise<any> =>
    axios.get(_URL, jsonConfig).then(responseBody),
  post: (_URL: string, body: any, jsonConfig?: any): Promise<any> =>
    axios.post(_URL, body, jsonConfig).then(responseBody),
  put: (_URL: string, body: {}): Promise<any> =>
    axios.put(_URL, body).then(responseBody),
  del: (_URL: string): Promise<any> => axios.delete(_URL).then(responseBody),
};

const agent = {
  requests,
  session,
  members,
  groups,
  options,
  feedback,
  partners,
  jobs,
  savedSearches,
  admin,
  adminRole,
  prices,
  dealerMargin,
  jobActivityHistory,
  payments,
  invoices,
  tasks,
  bills,
  priceGroup,
  vehicles,
  reports,
  inspectionReport
};

export default agent;
