import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { GetVehiclesRequest } from '../../../../../../Models/System/Vehicles';

export const useGetVehicles = (payload: GetVehiclesRequest, options?: any): UseQueryResult<any, any> => {
     const getVehicles = () => {
          return agent.vehicles.getVehicles(payload);
     };

     return useQuery(['GetVehicles', payload], getVehicles, {
          ...options,
     });
};
