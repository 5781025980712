import { FormikProvider,useFormik } from 'formik';
import React,{ useEffect,useState } from 'react';
import { useHistory } from 'react-router-dom';
import MovexResponseError from '../../../../../Axios/MovexResponseError';
import { Alert,ModalDialog,ModalDialogProps,useAlert } from '../../../../../Components';
import { FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
DivContainer,
FieldSetHeader,
FormContainer
} from '../../../../../Components/Formik/StyledComponents';
import { LabelItem } from '../../../../../Components/UI';
import { BillingModel } from '../../../../../Models/Billing/AddBilling/BillingModel';
import { UpdateBillPaymentInformationModel } from '../../../../../Models/Billing/AddBilling/UpdateBillPaymentInformationModel';
import { RouteConstants } from '../../../../../Routes/Constants';
import { isMovexErrorResponse } from '../../../../../Utils';
import { PaymentInformationStatusOptions } from '../../../Jobs/ViewPayments/Constants';
import { FlexContainerPayment } from '../../BillsStyledComponents';
import { BillingFormKeys } from '../../ViewBills/Types';
import { usePutUpdateBill } from '../Hooks/usePutUpdateBill';

const PaymentInformation: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { bill } = props;

     const handleModalClose = () => {
          setModalContent({ ...modalContent, show: false });
     };
     const history = useHistory();

     /** useStates */
     const [formikInitialValues, setFormikInitialValues] = useState<BillingModel>(bill);

     /** Custom Hooks */
     const { alert, setAlert } = useAlert();

     /** Query Hooks */
     const {
          mutate: putUpdateBill,
          data: putUpdateBillResponse,
          error: putUpdateBillError,
     } = usePutUpdateBill();

     /** Methods */
     const onSubmit = (values: any) => {
          const originalDate = new Date(values.PaymentDate);
          const isoDate = originalDate.toISOString();

          const myString = values.PaymentStatus;
          const myNumber = Number(myString);
          const updateBillPaymentInformationModel: UpdateBillPaymentInformationModel = {
               Id: bill.Id,
               PaymentStatus: myNumber,
               PaymentDate: isoDate,
          };

          putUpdateBill(updateBillPaymentInformationModel);
     };

     const onFeedbackSaveSuccess = () => {
          history.push(RouteConstants.ViewBills);
     };

     /** UseEffect */
     useEffect(() => {
          if(putUpdateBillResponse)
          setAlert({
               ...alert,
               show: true,
               body: 'Save Successful!',
               closeCallback: onFeedbackSaveSuccess,
          });
     }, [putUpdateBillResponse]);

     useEffect(() => {
          if (isMovexErrorResponse(putUpdateBillError)) {
               const errorContent = MovexResponseError.getMovexErrorContent(putUpdateBillError);
               setAlert({
                    ...alert,
                    show: true,
                    header: errorContent.header,
                    body: errorContent.body,
               });
          }
     }, [putUpdateBillError]);

     const formik = useFormik({
          initialValues: {
               PaymentStatus: bill?.PaymentStatus,
               PaymentDate: bill?.PaymentDate,
          },
          onSubmit: onSubmit,
          enableReinitialize: true,
     });

     const { handleSubmit, values, setFieldValue } = formik;

     const [modalContent, setModalContent] = useState<ModalDialogProps>({
          header: '',
          body: '',
          show: false,
          closeCallback: handleModalClose,
     });

     return (
          <>
               <Alert {...alert} />
               <FormikProvider value={formik}>
                    <FormContainer
                         onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                         }}
                    >
                         <fieldset>
                              <br />
                              <FieldSetHeader>Payment Information</FieldSetHeader>
                              <ModalDialog {...modalContent}></ModalDialog>

                              <DivContainer>
                                   <FlexContainerPayment>
                                        <LabelItem>Payment Status:</LabelItem>
                                        <FormikControl
                                             style={{ minWidth: '200px' }}
                                             name='PaymentStatus'
                                             control={FormControlKeys.Select}
                                             options={PaymentInformationStatusOptions}
                                        />
                                   </FlexContainerPayment>
                              </DivContainer>

                              <DivContainer>
                                   <FlexContainerPayment >
                                        <LabelItem>Payment Date:</LabelItem>
                                        <FormikControl
                                             name='PaymentDate'
                                             options={PaymentInformationStatusOptions}
                                             type="date"
                                        />
                                   </FlexContainerPayment>
                              </DivContainer>

                              <FormikControl
                                   name={BillingFormKeys.UpdatePaymentInformation}
                                   label='Update Payment Information'
                                   type='submit'
                                   control={FormControlKeys.Button}
                              />
                         </fieldset>
                    </FormContainer>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     bill: BillingModel;
};

export default PaymentInformation;
