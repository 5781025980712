import { useState } from 'react';

interface AlertProps {
     header: undefined |string | JSX.Element;
     body: undefined | string | JSX.Element | JSX.Element[];
     show: boolean;
     closeCallback: () => void | Function;
}

export const useAlert = () => {
     const [alert, setAlert] = useState<AlertProps>({
          header: '',
          body: '',
          show: false,
          closeCallback: () => {
               handleAlertClose();
          },
     });

     const handleDisplayAlert = (condition: any, header: string, body: string) => {
          if (condition) {
            setAlert({
              ...alert,
              show: true,
              header,
              body,
            });
          }
        };

     const handleAlertClose = () => {
          setAlert({ ...alert, show: false });
     };

     return { alert, setAlert, handleDisplayAlert, handleAlertClose };
};
