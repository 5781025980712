export enum JobStatus {
     // Deprecated / Not In Use...
     AcceptedQuote = 2,
     Unknown = 255,
     // Still In Use...
     Cancelled = 0,
     Aborted = 6,
     Draft = 7,
     PendingQuotes = 1,
     BookedJob = 25,
     ActiveJob = 3,
     CompletedJob = 4,
     AdminRemoved_Or_Failed = 5,
     Expired = 100
}
