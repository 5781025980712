import moment from "moment";
import { TimeToStringCOnvertion } from "./Constants";
import { DateFrom } from "./Enums/DateFrom";

export const getDayAndMonthDate = (date: string | null): string | undefined => {
    if (!date) return undefined;
     var dt = new Date(date);
     var day = dt.getDate();
     var month = getMonthName(dt.getMonth());
     var year = dt.getFullYear();
     return `${day} ${month}`;
};
export const getFormattedDate = (date: string): string => {
    const dt = new Date(date);
    const dateSuperscript = getDateSuperScript(dt);
    return dt.getDate() + dateSuperscript + ' ' + getMonthName(dt.getMonth()) + ' ' + dt.getFullYear();
};

export const getFormattedDateWithYearAndTime = (date: string): string => {
    var dt = new Date(date);
    var dateSuperscript = getDateSuperScript(dt);
    var hours = dt.getHours();
    var minutes = dt.getMinutes().toString().padStart(2, '0'); 
    return dt.getDate() + dateSuperscript + ' ' + getMonthName(dt.getMonth()) + ' ' + dt.getFullYear() + ' ' + hours + ':' + minutes;
};

export const getFormattedDateAndTime = (date: string): string => {
    var startOfToday = moment().startOf("day");
    var startOfDate = moment(date).startOf("day");
    var daysDiff = startOfDate.diff(startOfToday, "days");
    var days: any = {
        "0": "today",
        "-1": "yesterday",
        "1": "tomorrow",
    };

    if (Math.abs(daysDiff) <= 1) {
        return days[daysDiff];
    }

    var dt = new Date(date);
    var dateSuperscript = getDateSuperScript(dt);
    return dt.getDate() + ' ' + getMonthName(dt.getMonth()) + ' ' + dt.getHours() +':' + dt.getMinutes();
};

export const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date);

    return `${formattedDate} ${formattedTime}`;
};

export const secondsToTime = (secs: number) => {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const time = `${hours} hours ${minutes} mins`
    return time;
};

export const formatSelectedDates = (fromDate: Date, toDate: Date) => {
    if (fromDate || toDate) {
        const fromDateObj = moment(fromDate);
        const toDateObj = moment(toDate);
        let from = fromDate ? `${fromDateObj.format('D')} ${fromDateObj.format('MMMM')}` : "";
        let to = toDate ? `${toDateObj.format('D')} ${toDateObj.format('MMMM')}` : "";
        return (
            `${from} - ${to}`
        );
    }
    return '';
};

export const getDateFromCalender = (date: Date) => {
    var formattedDate = new Date(date),
        mnth = ("0" + (formattedDate.getMonth() + 1)).slice(-2),
        day = ("0" + formattedDate.getDate()).slice(-2);
    return ([formattedDate.getFullYear(), mnth, day].join("-"));

}

export const getDateSuperScript = (date: Date): string => {
    if (date) {
        var value = date.getDate() > 3 && date.getDate() < 21 ? 'th'
            : date.getDate() % 10 === 1
                ? "st"
                : date.getDate() % 10 === 2
                    ? "nd"
                    : date.getDate() % 10 === 3
                        ? "rd"
                        : "th"
        return value;
    }
    else {
        return '';
    }

}

export const getMonthDiffformToday = (date: Date): number => {
    if (date) {
        var d2 = new Date();
        var months;
        months = (d2.getFullYear() - date.getFullYear()) * 12;
        months -= date.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }
    else {
        return 0;
    }
}

export const getMinDateForCalender = (): string => {
    var formattedDate = new Date();
    var mnth = formattedDate.getMonth() + 1;
    return `1970 ${mnth}`;
}

export const getInitialMonthForCalender = (): string => {
    var formattedDate = new Date();
    var mnth = formattedDate.getMonth() + 1;
    return `${formattedDate.getFullYear()} ${mnth}`;
}

export const getInitialMonthForSingleDateCalender = (): string => {
    var formattedDate = new Date();
    var mnth = formattedDate.getMonth() + 1;
    var year = formattedDate.getFullYear() - 1;
    return `${year} ${mnth}`;
}

export const getFormattedDateWithYear = (date: string): string => {
    if (!date) return '';
    var dt = new Date(date);
    var dateSuperscript = getDateSuperScript(dt);
    return dt.getDate() + dateSuperscript + ' ' + getMonthName(dt.getMonth()) + ' ' + dt.getFullYear();
};

export function ReFormatUkDateTimeExpiry(ukDateStr: string) {
    ukDateStr = ukDateStr.trim();
    if (ukDateStr === "")
        return null;

    var dateParts = ukDateStr.match(/\d+/g);
    var minute = null;
    var hour = null;
    var day = null;
    var month = null;
    var year = null;

    if(dateParts){
            // Dates in the format yyyy/mm/dd
            if (/\d\d\d\d[^\d]\d\d[^\d]\d\dT\d\d:\d\d/.test(ukDateStr)) {
                minute = dateParts[4];
                hour = dateParts[3];
                day = dateParts[2];
                month = parseInt(dateParts[1], 10);
                year = dateParts[0];
            }

            if (minute === null || hour === null || day === null || month === null || year === null)
                return null;

            switch (month) {
                case 1: month = "Jan"; break;
                case 2: month = "Feb"; break;
                case 3: month = "Mar"; break;
                case 4: month = "Apr"; break;
                case 5: month = "May"; break;
                case 6: month = "Jun"; break;
                case 7: month = "Jul"; break;
                case 8: month = "Aug"; break;
                case 9: month = "Sep"; break;
                case 10: month = "Oct"; break;
                case 11: month = "Nov"; break;
                case 12: month = "Dec"; break;
            }
            var rval = ("" + day + " " + month + " " + hour + ":" + minute);
            return rval;
    }
};

export const isExpired = (date: string): boolean => {
    if (!date) return false;
    var dt = new Date(date);
    var currentDate = new Date();
    if (dt <= currentDate) {
        return true;
    }
    return false;
};

export const getMonthName = (date: number): string => {
    const month: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    return month[date];
};

export const reFormatUkDateTimeExpiry = (ukDateStr: string) => {
    ukDateStr = ukDateStr.trim()
    if (ukDateStr === "")
        return null;

    var dateParts = ukDateStr.match(/\d+/g);
    var minute = null;
    var hour = null;
    var day = null;
    var month = null;
    var year = null;

    // Dates in the format yyyy/mm/dd
    if (dateParts && /\d\d\d\d[^\d]\d\d[^\d]\d\dT\d\d:\d\d/.test(ukDateStr)) {
        minute = dateParts[4];
        hour = dateParts[3];
        day = dateParts[2];
        month = parseInt(dateParts[1], 10);
        year = dateParts[0];
    }

    if (minute === null || hour === null || day === null || month === null || year === null)
        return null;

    switch (month) {
        case 1: month = "Jan"; break;
        case 2: month = "Feb"; break;
        case 3: month = "Mar"; break;
        case 4: month = "Apr"; break;
        case 5: month = "May"; break;
        case 6: month = "Jun"; break;
        case 7: month = "Jul"; break;
        case 8: month = "Aug"; break;
        case 9: month = "Sep"; break;
        case 10: month = "Oct"; break;
        case 11: month = "Nov"; break;
        case 12: month = "Dec"; break;
    }
    var rval = ("" + day + " " + month + " " + hour + ":" + minute);
    return rval;
}

export const reFormatUkDate = (ukDateStr: string) => {
    ukDateStr = ukDateStr.trim()
    if (ukDateStr === "")
        return null;

    var dateParts = ukDateStr.match(/\d+/g);
    var minute = null;
    var hour = null;
    var day = null;
    var month = null;
    var year = null;

    // Dates in the format yyyy/mm/dd
    if (dateParts && /\d\d\d\d[^\d]\d\d[^\d]\d\dT\d\d:\d\d/.test(ukDateStr)) {
        minute = dateParts[4];
        hour = dateParts[3];
        day = dateParts[2];
        month = parseInt(dateParts[1], 10);
        year = dateParts[0];
    }

    if (minute === null || hour === null || day === null || month === null || year === null)
        return null;

    switch (month) {
        case 1: month = "Jan"; break;
        case 2: month = "Feb"; break;
        case 3: month = "Mar"; break;
        case 4: month = "Apr"; break;
        case 5: month = "May"; break;
        case 6: month = "Jun"; break;
        case 7: month = "Jul"; break;
        case 8: month = "Aug"; break;
        case 9: month = "Sep"; break;
        case 10: month = "Oct"; break;
        case 11: month = "Nov"; break;
        case 12: month = "Dec"; break;
    }
    var rval = ("" + day + " " + month);
    return rval;
}

export const millisecondsToString = (milliseconds: number, ignoreYears: boolean, ignoreDays: boolean, ignoreHours: boolean, ignoreMinutes: boolean, ignoreSeconds: boolean) => {
    // TIP: to find current time in milliseconds, use:
    // var  current_time_milliseconds = new Date().getTime();

    function numberEnding(number: number) {
        return (number > 1) ? 's' : '';
    }

    var str = "";
    var temp = Math.floor(milliseconds / 1000);

    // Try to build up a string starting with the largest unit first
    if (!ignoreYears) {
        var years = Math.floor(temp / 31536000);
        if (years) {
            str = years + ' year' + numberEnding(years) + ' ';
            temp = temp % 31536000;
        }
    }
    if (!ignoreDays) {
        var days = Math.floor(temp / 86400);
        if (days) {
            str += days + ' day' + numberEnding(days) + ' ';
            temp = temp % 86400;
        }
    }
    if (!ignoreHours) {
        var hours = Math.floor(temp / 3600);
        if (hours) {
            str += hours + ' hr' + numberEnding(hours) + ' ';
            temp = temp % 3600;
        }
    }
    if (!ignoreMinutes) {
        var minutes = Math.floor(temp / 60);
        if (minutes) {
            str += minutes + ' min' + numberEnding(minutes) + ' ';
            temp = temp % 60;
        }
    }
    if (!ignoreSeconds) {
        var seconds = temp % 60;
        if (seconds) {
            str += seconds + ' sec' + numberEnding(seconds) + ' ';
        }
    }

    if (str !== "") return str;

    // Otherwise try to set a default string starting with the smallest unit first
    if (str === "" && !ignoreSeconds && milliseconds < 1000) {
        str = "1 sec";
    }
    if (str === "" && !ignoreMinutes && milliseconds < (60 * 1000)) {
        str = "1 min";
    }
    if (str === "" && !ignoreHours && milliseconds < (60 * 60 * 1000)) {
        str = "1 hr";
    }
    if (str === "" && !ignoreDays && milliseconds < (24 * 60 * 60 * 1000)) {
        str = "1 day";
    }
    if (str === "" && !ignoreYears && milliseconds < (365 * 24 * 60 * 60 * 1000)) {
        str = "1 year";
    }

    if (str !== "") return str;

    // Otherwise just return milliseconds as passed in
    return milliseconds;
}

export const getDateForInput = (date: Date) => {
    return date.toString().substring(0, 10);
}

export const expiresInMilliseconds = (date: string): number => {
    let dt = new Date(date);
    let currentDate = new Date();
    let diff = dt.getTime()- currentDate.getTime();
    return diff;
  };


export const DurationString = (milliseconds: number) : string => 
{
    return MillisecondsToString(milliseconds, true, true, false, false, true);
}

const numberEnding = (decimal: number) : string => {
    let numberEnding = (decimal > 1) ? "s" : "";
    return numberEnding;
}
        
export const MillisecondsToString = (milliseconds : number,ignoreYears: boolean, ignoreDays: boolean, ignoreHours: boolean,ignoreMinutes: boolean, ignoreSeconds: boolean) : string =>
{
    var str = "";
    var temp = Math.floor((milliseconds / 1000));

    if (!ignoreYears)
    {
        var years = Math.floor(temp / TimeToStringCOnvertion.secondsPerYear);
        if (years > 0)
        {
            str = years + " year" + numberEnding(years) + " ";
            temp = temp % TimeToStringCOnvertion.secondsPerYear;
        }
    }
    if (!ignoreDays)
    {
        var days = Math.floor(temp / TimeToStringCOnvertion.secondsPerDay);
        if (days > 0)
        {
            str += days + " day" + numberEnding(days) + " ";
            temp = temp % TimeToStringCOnvertion.secondsPerDay;
        }
    }
    if (!ignoreHours)
    {
        var hours = Math.floor(temp / TimeToStringCOnvertion.secondsPerHour);
        if (hours > 0)
        {
            str += hours + " hour" + numberEnding(hours) + " ";
            temp = temp % TimeToStringCOnvertion.secondsPerHour;
        }
    }
    if (!ignoreMinutes)
    {
        var minutes = Math.floor(temp / TimeToStringCOnvertion.secondsPerMinute);
        if (minutes > 0)
        {
            str += minutes + " min";
            temp = temp % TimeToStringCOnvertion.secondsPerMinute;
        }
    }
    if (!ignoreSeconds)
    {
        var seconds = temp % TimeToStringCOnvertion.secondsPerMinute;
        if (seconds > 0)
        {
            str += seconds + " second" + numberEnding(seconds) + " ";
        }
    }

    if (str !== "") return str;

    return "less than a second";
}

export const formatDateForInput = (date: Date) => {
    return new Date(date).toISOString().split('T')[0];
};

export function getDateFrom(dateFrom: DateFrom) {
    switch(dateFrom) {
        case DateFrom.Week:
            return new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();
        case DateFrom.ThreeMonths:
            return new Date(new Date().setDate(new Date().getDate() - 90)).toISOString();
        default:
            return new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();
    }
}

export const adjustToUTC = (date: Date) => {
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset);
};