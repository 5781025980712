import { config } from "../Config/AdminConfig";
import { CurrentSavedFilters } from "../Store/Grids/GridFilterCache/Types";

export const getAccessToken = () => {
  try {
    const token = localStorage.getItem(config.tokenKey);
    return token;
  } catch (e) {
    console.log("getAccessToken", e);
  }
};

export const setAccessToken = (access_token: string) => {
  try {
    localStorage.setItem(config.tokenKey, access_token);
  } catch (e) {
    console.log("setAccessToken", e);
  }
};

export const removeAccessToken = () => {
  try {
    localStorage.removeItem(config.tokenKey);
  } catch (e) {
    console.log("removeAccessToken ", e);
  }
};

export const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(config.refreshTokenKey);
    return token;
  } catch (e) {
    console.log("getRefreshToken", e);
  }
};

export const setRefreshToken = (refresh_token: string) => {
  try {
    localStorage.setItem(config.refreshTokenKey, refresh_token);
  } catch (e) {
    console.log("setRefreshToken", e);
  }
};

export const removeRefreshToken = () => {
  try {
    localStorage.removeItem(config.refreshTokenKey);
  } catch (e) {
    console.log("removeRefreshToken ", e);
  }
};

export const setGoogleInfo = async (key: string, token: string) => {
  try {
    await localStorage.setItem(key, token);
  } catch (e) {
    console.log("setGoogleInfo ", e);
  }
};

export const getGoogleInfo = async (key: string) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log("getGoogleInfo ", e);
  }
};

//------------------------------------

export const setMemberId = (member_id: string) => {
  try {
    localStorage.setItem(config.memberIdKey, member_id);
  } catch (e) {
    console.log("setMemberId ", e);
  }
};

export const getMemberId = () => {
  try {
    const value = localStorage.getItem(config.memberIdKey);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log("getMemberId ", e);
  }
};

export const removeMemberId = () => {
  try {
    localStorage.removeItem(config.memberIdKey);
  } catch (e) {
    console.log("removeMemberId ", e);
  }
};

//------------------------------------

export const setUsername = (username: string) => {
  try {
    localStorage.setItem('username', username);
  } catch (e) {
    console.log("setUsername", e);
  }
};

export const getAdminUsername = () => {
  try {
    const token = localStorage.getItem('username');
    return token;
  } catch (e) {
    console.log("getAdminUsername", e);
  }
};

export const setDisplayName = (displayName: string) => {
  try {
    localStorage.setItem('displayName', displayName);
  } catch (e) {
    console.log("setDisplayName", e);
  }
};

export const getAdminDisplayName = () => {
  try {
    const token = localStorage.getItem('displayName');
    return token;
  } catch (e) {
    console.log("getAdminDisplayName", e);
  }
};

export const clearAllStorage = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.log("clearAllStorage", e);
  }
}


//------------------------------------

export const setFiltering = (filtering: CurrentSavedFilters) => {
  try {
    localStorage.setItem("gridsFilterState", JSON.stringify(filtering));
  } catch (e) {
    console.error('Failed to set filter for key savedFilter');
  }
};

//------------------------------------

export const setApiUrl = (url: string) => {
  try {
    localStorage.setItem("apiUrl", url);
  } catch (e) {
    console.error('Failed to set filter for key apiUrl');
  }
};

export const getApiUrl = () => {
  try {
    const token = localStorage.getItem("apiUrl");
    return token;
  } catch (e) {
    console.log("getApiUrl", e);
  }
};