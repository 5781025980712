
export const NavbarMenusRoutes = {
    /** Tasks */ 
    ViewTasksRoute:'view-tasks',
    AddTaskRoute: 'add-task',
    EditTaskRoute: 'edit-task',
    /** Members */
    NewMembersRoute: "new-members",
    MembersRoute: "members",
    AddMemberRoute: "add-member",
    /** Groups */
    ViewGroupsRoute: "view-groups",
    AddGroupRoute: "add-group",
    EditGroupRoute: "edit-group",
    /** Customer Set */
    CustomerSetMarginMaintenanceRoute: "customer-set-margin",
    /* Jobs */
    ViewJobsRoute: "view-jobs",
    ViewPaymentsRoute: "view-payments",
    JobActivityHistoryRoute: "jobs-activity-history",
    ViewJobRoute: "view-job",
    /* Feedback */
    ViewFeedbackRoute: "view-feedback",
    AddFeedbackRoute: "add-feedback",
    EditFeedbackRoute: "edit-feedback",
    /* Searches */
    ViewSearchesRoute: "view-searches",
    AddSearchRoute: "add-search",
    EditSearchRoute: "edit-search",
    /** Partners */
    ViewPartnersRoute: "view-partners",
    AddPartnerRoute: "add-partner",
    EditPartnerRoute: "edit-partner",
    /** Prices */
    ViewPricesRoute: "view-prices",
    ViewPriceGroupsRoute: "view-price-groups",
    AddPriceGroupRoute: "add-price-groups",
    DefaultMarginRoute: "default-margin",
    EditPriceGroupRoute: "edit-price-group",
    /** Billing */
    ViewBillsRoute: "view-bills",
    ViewBillRoute: "view-bill",
    ViewInvoicesRoute: "view-invoices",
    AddInvoiceRoute: "add-invoice",
    AddCreditNoteRoute: "add-credit-note",
    /** Reporting */
    JobsByUserRoute: "jobs-by-user",
    NewUpdatedUsersRoute: "new-updated-users",
    StatisticsRoute: "statistics",
    /** System */
    ViewAdministratorsRoute: "administrators",
    ViewAdminRolesRoute: "adminroles",
    AddAdministratorRoute: "add-administrator",
    EditAdministratorRoute: "edit-administrator",
    AddAdminRoleRoute: "add-adminrole",
    EditAdminRoleRoute: "edit-adminrole",
    PagesRoute: "pages",
    AuthServiceRoute: "_authservice",
    CacheServiceRoute: "_cacheservice",
    EmailDeliveryServiceRoute: "_emaildeliveryservice",
    SchedulingServiceRoute: "_schedulingservice",
    TasksRoute: "tasks",
    /** Vehicles */
    AddVehicleRoute: "add-vehicle",
    EditVehicleRoute: "edit-vehicle",
    ViewVehiclesRoute: "view-vehicles",
    /** Others */
    LogoutRoute: "logout",
}

export const NavbarMenus = {
    Tasks:'Tasks',
    Members: "Members",
    Groups: "Groups",
    CustomerSet: "Customer Set",
    Jobs: "Jobs",
    Feedback: "Feedback",
    Searches: "Searches",
    Partners: "Partners",
    Prices: "Prices",
    Billing: "Billing",
    Reporting: "Reporting",
    System: "System",
};

export const SystemNavbarSubMenus = {
    Administrators: "Administrators",
    Pages: "Pages",
    Services: "Services",
    Tasks: "Tasks",
    Vehicles: "Vehicles",
}
