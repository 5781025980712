import React from 'react';
import {
  FuelInclusive_RequestQuote,
  FuelInclusive_QuoteFuel,
  FuelInclusive_QuoteNoFuel,
} from '../../../../../Assets/Images';

export const Info24HoursTransporters = () => {
  return (
    <>
      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ fontSize: 24 }}>
          <strong
            style={{
              fontFamily: 'Roboto-Regular, Helvetica',
              lineHeight: 20,
              textAlign: 'center',
              fontSize: 18,
              color: 'rgb(255, 0, 0)',
              backgroundColor: 'rgb(255, 255, 255)',
            }}
          >
            IMPORTANT MESSAGE PLEASE READ
          </strong>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: '#004966' }}>
          <strong>
            All driven jobs will now include fuel if required.
            <br />
            <br />
            When a driven job is requested the user will be asked if fuel is
            required, if so they will be shown an estimated fuel amount.
          </strong>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <img
          alt=""
          src={FuelInclusive_RequestQuote}
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: 'Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: '#004966' }}>
          <strong>
            When you view the job if fuel is required the&nbsp;estimated fuel
            amount will be shown as below.
          </strong>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <img
          alt=""
          src={FuelInclusive_QuoteFuel}
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: 'Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: '#004966' }}>
          <strong>If fuel is not required it will be shown as below.</strong>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <img
          alt=""
          src={FuelInclusive_QuoteNoFuel}
          style={{
            cursor: 'default',
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0px',
            color: 'rgb(51, 51, 51)',
            fontFamily: 'Roboto-Regular, Helvetica',
            fontSize: 14,
            lineHeight: 20,
            width: 500,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        />
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        &nbsp;
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: 'rgb(255, 0, 0)' }}>
          <span style={{ fontSize: 16 }}>
            <strong>
              If you have any queries regarding this please feel free to call us
              on
            </strong>
          </span>
        </span>
      </p>

      <p
        style={{
          margin: '0px 0px 10px',
          fontFamily: 'Roboto-Regular, Helvetica',
          color: 'rgb(51, 51, 51)',
          fontSize: 14,
          lineHeight: 20,
          textAlign: 'center',
        }}
      >
        <span style={{ color: 'rgb(255, 0, 0)' }}>
          <span style={{ fontSize: 16 }}>
            <strong>0208 551 1060</strong>
          </span>
        </span>
      </p>
    </>
  );
};
