/* Inbuilt libraries */
import { GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
/* Custom Components */
import { Alert, Loading, renderHeader, useAlert } from "../../../../../Components";
import { CacheServiceModelKeys } from "../../../../../Models/System/CacheService";
import {
  CommonRequestParam,
  FreeTextFilter,
  Pager,
} from "../../../../../Types";
import { getSorterFromSortModel, Page } from "../../../../../Utils";
import { SqlStringMatchType } from "../../../../../Utils/Enums";
import CacheServiceGridView from "./Grid";
import { useGetCacheServiceList } from "./Hooks";
import { Button } from "../../../../../Components/FormControls";

const ViewCacheService: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FreeTextFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: CacheServiceModelKeys.Id,
      sort: "asc",
    },
  ]);

  const getParams = () => {
    const params: CommonRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: FreeTextFilter) => {
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setFilter({});
  };
  const pageChangeHandler = (currentPage: number) => {};
  const pageSizeChangeHandler = (itemsPerPage: number) => {};
  const onSort = (sortModel: GridSortModel) => {};

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    data: cacheServiceListResponse,
    isLoading: isCacheServiceListLoading,
    refetch: refetchCacheServiceList,
    error: getCacheServiceListError,
  } = useGetCacheServiceList(getParams());

  const isDataLoaded = cacheServiceListResponse !== null;

  /** useEffects */
  useEffect(() => {
    refetchCacheServiceList();
  }, [filter, pager, sortModel]);

  useEffect(() => {
    setIsLoading(isCacheServiceListLoading);
  }, [isCacheServiceListLoading]);
  
  useEffect(() => {
    if (getCacheServiceListError) {
        setAlert({
            ...alert,
            show: true,
            header: getCacheServiceListError?.Subject ? getCacheServiceListError?.Subject : "Error",
            body: getCacheServiceListError?.Description ? getCacheServiceListError?.Description : "Error while fetching the Cache Service list."
        });
    }
}, [getCacheServiceListError]);


  return (
    <div>
      {renderHeader("Cache Keys")}
      <div className="container-fluid">
        <Loading loading={isLoading} />
        <Alert {...alert} />
        {isDataLoaded && (
          <CacheServiceGridView
            data={cacheServiceListResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
        <Button
          label="Delete Selected Cache Keys"
          style={{ margin: 10 }}
          onClick={() => {}}
        ></Button>
      </div>
    </div>
  );
};

export default ViewCacheService;
