import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { config } from './Config/AdminConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
       <BrowserRouter basename={`/`}>
            <App />
       </BrowserRouter>
  </React.StrictMode>
);

// Uncomment for measuring performance of the app
//reportWebVitals(console.log);
