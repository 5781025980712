import { FeedbackResponseModel, GetFeedbackListRequest } from './../../../../../Models/Feedback';
import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetFeedbackList = (payload: GetFeedbackListRequest,
  options?: any
): UseQueryResult<FeedbackResponseModel, any> => {

  const getFeedbackList = () => {
    return agent.feedback.getFeedbackList(payload);
  };

  return useQuery(['GetFeedbackList', payload], getFeedbackList, {
    ...options,
  });
};
