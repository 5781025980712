import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import React from 'react';
import { ControlledPagination as Pagination } from '../../../../../Components';
import { JobActivityHistoryResponseModel } from '../../../../../Models/Jobs/JobActivityHistory';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter } from '../../../../../Utils';
import { columns } from './Columns';

const GridView: React.FC<GridViewProps> = (props) => {
  const {
    data: jobActivityHistoryList,
    onPageChange,
    onPageSizeChange,
    onSort,
  } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  const { Page, TableSorter } = jobActivityHistoryList;

  if (Page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = Page;

    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            pageSize={ItemsPerPage}
            currentPage={CurrentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    return (
      <div>
        {Page && (
          <div className="jobs-activity-grid-container">
            <div id='id_grid_activity_history' style={{ display: 'flex', height: '100%', width: '100%' }}>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                getRowId={(row: any) => row.Id}
                autoHeight
                rows={Items}
                rowHeight={41}
                disableColumnMenu={true}
                columns={columns}
                columnBuffer={columns.length + 1}
                components={{
                  Toolbar: CustomGridFooter,
                  Footer: CustomGridFooter,
                }}
                disableExtendRowFullWidth={true}
                sortingMode="server"
                sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                onSortModelChange={handleSort}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default GridView;

interface GridViewProps extends GridProps<JobActivityHistoryResponseModel> {}
