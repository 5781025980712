/** Import Inbuilt libraries */
import { FormikProvider, useFormik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
/** Custom components */
import { MemberIDParamType } from '../..';
import { FormControlKeys } from '../../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import {
  FieldSetContainer,
  FieldSetHeader,
  FormContainer,
  FormFieldSetContainer,
} from '../../../../../../Components/Formik/StyledComponents';
import {
  ConfigObjectModel,
  DealerUIAddJobConfigModel,
  MemberUserNameDetailsModelKeys,
} from '../../../../../../Models/Members/EditMember/ConfigObjects';
import {
  ConfigType,
  CustomConfigDealerAddJob,
  JobOfferedTo,
  JobType,
  ServiceType,
} from '../../../../../../Utils/Enums';
import {
  CustomConfigDealerAddJobOptions,
  CustomConfigOptions,
  JobKindOptions,
  JobMovementTypeOptions,
  JobOfferedToOptions,
  JobReOfferPeriodOptions,
  JobTypeOptions,
  ServiceTypeOptions,
  YesNoOptions,
} from '../../../Constants';
import '../../index.css';
import {
  useGetConfigObject,
  useGetSearchSuppliersByUserNameAndServiceType,
  usePostConfigObject,
  usePutConfigObject,
} from './Hooks';
import { CustomConfigKeys, DealerUIAddJobkeys } from './Types';
import { getTypeaheadOptions } from '../../../../../../Utils';
import { TypeaheadOption } from '../../../../../../Types';
import {
  ShowPleaseWait,
  Loading,
  AlertProps,
  Alert,
  useAlert,
} from '../../../../../../Components';
import { SupplyingMemberByUsername } from '../../../../../../Models/Members/EditMember/ConfigObjects/SupplyingMemberByUsername';

const CustomConfig: React.FC = () => {
  const { memberID } = useParams<MemberIDParamType>();
  const rqOption = { enabled: false };

  const handleAlertClose = () => {
    setSaveAlert({ ...saveAlert, show: false });
  };

  /** useStates */
  const [saveAlert, setSaveAlert] = useState<AlertProps>({
    header: '',
    body: 'Updated Successfully',
    show: false,
    closeCallback: handleAlertClose,
  });
  const [formikInitialValues, setFormikInitialValues] =
    useState<DealerUIAddJobConfigModel>({});
  const [configId, setConfigId] = useState<number>(0);
  const [
    searchSupplierByUserNameAndServiceTypeParam,
    setSearchSupplierByUserNameAndServiceTypeParam,
  ] = useState<{
    userName: string;
    serviceType?: number;
  }>({ userName: '', serviceType: ServiceType.Driven });

  const [selectedConfigType, setSelectedConfigType] = useState<number>(0);
  const [selectedDealerUIAddJobSection, setSelectedDealerUIAddJobSection] =
    useState<string>();
  const [preSelectedSuppliers, setPreSelectedSuppliers] = useState<
    TypeaheadOption[]
  >([]);
  const [
    preSelectedAutoQuotesDrivenSuppliers,
    setPreSelectedAutoQuotesDrivenSuppliers,
  ] = useState<TypeaheadOption[]>([]);
  const [
    preSelectedAutoQuotesTransportedSuppliers,
    setPreSelectedAutoQuotesTransportedSuppliers,
  ] = useState<TypeaheadOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);

  /** Custom Hook */
  const { alert, setAlert } = useAlert();
  
  /** React Query Hooks */
  const {
    isLoading: isGetConfigLoading,
    data: getConfigObjectResponse,
    refetch: getConfigObject,
    error: getConfigObjectError,
  } = useGetConfigObject(selectedConfigType, rqOption);

  const {
    refetch: searchSuppliersByUserNameAndServiceType,
    data: autoQuotesSupplierResponse,
    isLoading: isGetSearchSuppliersByUserNameAndServiceTypeLoading,
    error: getSearchSuppliersByUserNameAndServiceTypeError,
  } = useGetSearchSuppliersByUserNameAndServiceType(
    searchSupplierByUserNameAndServiceTypeParam,
    rqOption
  );

  const {
    mutate: putConfigObject,
    isLoading: isPutConfigObjectLoading,
    data: putConfigObjectResponse,
    error: putConfigObjectError,
  } = usePutConfigObject();

  const { 
    mutate: postConfigObject, 
    isLoading: isPostConfigObjectLoading,
    error: postConfigObjectError,
   } = usePostConfigObject();

  /** Methods */
  const showAllDealerUIAddJobSections = () =>
    !selectedDealerUIAddJobSection ||
    selectedDealerUIAddJobSection === CustomConfigDealerAddJob.All;

  const onDealerUIAddJobSectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedDealerUIAddJobSection(e.target.value);
  };

  const hideElement = (value: any) => !!value;

  const mapTypeAheadValuesToAutoQuotesSuppliers = (
    options: TypeaheadOption[]
  ) => {
    const suppliers: SupplyingMemberByUsername[] = [];
    options.map((o: TypeaheadOption) => {
      suppliers.push({ Id: o.Id, UserName: o.Label });
    });
    return suppliers;
  };

  const onCustomConfigChange = async (e: any) => {
    await setSelectedConfigType(parseInt(e.target.value));
    getConfigObject();
    setSelectedDealerUIAddJobSection(CustomConfigDealerAddJob.All);
  };

  const onSearchSuppliersByUserNameInput = async (userName: string) => {
    const supplier = { userName };
    setSearchSupplierByUserNameAndServiceTypeParam(supplier);
    searchSuppliersByUserNameAndServiceType();
  };

  const onSearchSuppliersByUserNameAndServiceTypeInput = async (
    userName: string,
    serviceType: number
  ) => {
    const supplier = { userName, serviceType };
    setSearchSupplierByUserNameAndServiceTypeParam(supplier);
    searchSuppliersByUserNameAndServiceType();
  };

  const getRequestForTypeAheadInputs = (configData: any) => {
    //Set autoquote Driven suppliers
    if (!configData.AutoQuotesForDrivenFixedPrice) {
      configData.AutoQuotesForDrivenFixedPrice = {};
    }
    configData.AutoQuotesForDrivenFixedPrice.Suppliers =
      mapTypeAheadValuesToAutoQuotesSuppliers(
        preSelectedAutoQuotesDrivenSuppliers
      );

    //Set autoquote Transported suppliers
    if (preSelectedAutoQuotesTransportedSuppliers) {
      if (!configData.AutoQuotesForTransportedFixedPrice) {
        configData.AutoQuotesForTransportedFixedPrice = {};
      }
      configData.AutoQuotesForTransportedFixedPrice.Suppliers =
        mapTypeAheadValuesToAutoQuotesSuppliers(
          preSelectedAutoQuotesTransportedSuppliers
        );
    }

    //Set Selectable Suppliers
    if (preSelectedSuppliers) {
      if (!configData.SelectableSuppliers) {
        configData.SelectableSuppliers = {};
      }
      configData.SelectableSuppliers =
        mapTypeAheadValuesToAutoQuotesSuppliers(preSelectedSuppliers);
    }
    return configData;
  };

  const onSubmit = (configData: any) => {
    configData = getRequestForTypeAheadInputs(configData);
    const payload: ConfigObjectModel = {
      ID: configId,
      ConfigType: selectedConfigType,
      MemberID: parseInt(memberID),
      ConfigData: JSON.stringify(configData),
    };
    if (!!configId) {
      putConfigObject(payload);
    } else {
      postConfigObject(payload);
    }
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const getTypeaheadOptionsForSupplierField = (data: any) => {
    if (data) {
      return getTypeaheadOptions(
        MemberUserNameDetailsModelKeys.UserName,
        MemberUserNameDetailsModelKeys.Id,
        data
      );
    }
    return [];
  };

  /** useEffects */
  useEffect(() => {
    if (putConfigObjectResponse?.IsSuccess) {
      setSaveAlert({ ...saveAlert, show: true });
    }

    if (putConfigObjectError) {
      setSaveAlert({
        ...saveAlert,
        show: true,
        header: putConfigObjectError.Subject,
        body: putConfigObjectError.Description ?? 'An error occurred while saving',
      });
    }
  }, [putConfigObjectResponse, putConfigObjectError]);

  useEffect(() => {
    if(postConfigObjectError){
      setSaveAlert({
        ...saveAlert,
        show: true,
        header: postConfigObjectError.Subject,
        body: postConfigObjectError.Description ?? 'An error occurred while saving',
      });
      }
    }, [postConfigObjectError]);

  useEffect(() => {
    if (getConfigObjectResponse) {
      const {
        AutoQuotesForDrivenFixedPrice: autoQuotesFixed,
        AutoQuotesForTransportedFixedPrice: autoQuotesTransported,
        SelectableSuppliers,
      } = getConfigObjectResponse;
      setConfigId(getConfigObjectResponse.Id);
      setFormikInitialValues({
        ...formikInitialValues,
        ...getConfigObjectResponse,
      });
      const autoQuotesDrivenSupplierResponse = autoQuotesFixed?.Suppliers;
      const autoQuotesTransportedSupplierResponse =
        autoQuotesTransported?.Suppliers;
      setPreSelectedAutoQuotesDrivenSuppliers(
        getTypeaheadOptionsForSupplierField(autoQuotesDrivenSupplierResponse)
      );
      setPreSelectedAutoQuotesTransportedSuppliers(
        getTypeaheadOptionsForSupplierField(
          autoQuotesTransportedSupplierResponse
        )
      );
      setPreSelectedSuppliers(
        getTypeaheadOptionsForSupplierField(SelectableSuppliers)
      );
    }
  }, [getConfigObjectResponse]);

  useEffect(() => {
    setShowPleaseWait(
      isPutConfigObjectLoading ||
        isPostConfigObjectLoading ||
        isGetConfigLoading
    );
  }, [isPutConfigObjectLoading, isPostConfigObjectLoading, isGetConfigLoading]);

  useEffect(() => {
    setIsLoading(isGetSearchSuppliersByUserNameAndServiceTypeLoading);
  }, [isGetSearchSuppliersByUserNameAndServiceTypeLoading]);

  useEffect(() => {
    if (getConfigObjectError) {
      setAlert({
        ...alert,
        show: true,
        header: getConfigObjectError?.Subject,
        body: getConfigObjectError?.Description,
      });
    }
  }, [getConfigObjectError]);
  
  useEffect(() => {
    if (getSearchSuppliersByUserNameAndServiceTypeError) {
      setAlert({
        ...alert,
        show: true,
        header: getSearchSuppliersByUserNameAndServiceTypeError?.Subject,
        body: getSearchSuppliersByUserNameAndServiceTypeError?.Description,
      });
    }
  }, [getSearchSuppliersByUserNameAndServiceTypeError]);

  useEffect(() => {
    if (formik.values[DealerUIAddJobkeys.AllowModelJobs] == false) {
      formik.setFieldValue(DealerUIAddJobkeys.ModelVmAccountId, "");
    }
  }, [formik.values[DealerUIAddJobkeys.AllowModelJobs]]);

  const { handleSubmit, values } = formik;
  return (
    <FormikProvider value={formik}>
      <Alert {...alert} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Alert {...saveAlert}></Alert>
        <ShowPleaseWait show={showPleaseWait} />
        <Loading loading={isLoading} />
        <FormFieldSetContainer>
          <FieldSetContainer>
            <FieldSetHeader>Custom Config</FieldSetHeader>
            <FormikControl
              name={CustomConfigKeys.CustomConfigOptions}
              label="Choose type"
              control={FormControlKeys.Select}
              options={CustomConfigOptions}
              placeholder="Select"
              onChange={onCustomConfigChange}
            />
            <ConfigTypeContainer>
              {selectedConfigType === ConfigType.DealerUIMainMenuConfig && (
                <DealerUIMaintMenuContainer>
                  <FormikControl
                    name={CustomConfigKeys.ShowQuotesRequested}
                    label="Show Quotes Requested"
                    control={FormControlKeys.RadioGroup}
                    options={YesNoOptions}
                  />
                  <FormikControl
                    name={CustomConfigKeys.ShowAddJob}
                    label="Show Add Job"
                    control={FormControlKeys.RadioGroup}
                    options={YesNoOptions}
                  />
                  <FormikControl
                    name={CustomConfigKeys.ShowActiveJobs}
                    label="Show Active Jobs"
                    control={FormControlKeys.RadioGroup}
                    options={YesNoOptions}
                  />
                  <FormikControl
                    name={CustomConfigKeys.ShowCompletedJobs}
                    label="Show Completed  Jobs"
                    control={FormControlKeys.RadioGroup}
                    options={YesNoOptions}
                  />
                  <FormikControl
                    name={CustomConfigKeys.ShowTradeInsurance}
                    label="Show Trade Insurance"
                    control={FormControlKeys.RadioGroup}
                    options={YesNoOptions}
                  />
                </DealerUIMaintMenuContainer>
              )}

              {selectedConfigType === ConfigType.DealerUIAddJobConfig && (
                <DealerUIAddJobContainer>
                  <FormikControl
                    name={CustomConfigKeys.ChooseDealerUIAddJobSection}
                    label="Choose section:"
                    control={FormControlKeys.Select}
                    options={CustomConfigDealerAddJobOptions}
                    onChange={onDealerUIAddJobSectionChange}
                  />
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.JobKindSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Job Kind Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowJobKindSection}
                        label="Show Job Kind Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowNonStandardJobs}
                        label="Allow Non-Standard Jobs"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultJobKind}
                        label="Default Job Kind"
                        control={FormControlKeys.RadioGroup}
                        options={JobKindOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.JobMovementTypeSection) && (
                    <JobMovementTypeSection>
                      <CustomConfigSubSectionHeader>
                        Job Movement Type Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowJobMovementTypeSection}
                        label="Show Job Movement Type Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HideInternalTransfer}
                        label="Hide Internal Transfer"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HideDealerTransfer}
                        label="Hide Dealer Transfer"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HideFleetDelivery}
                        label="Hide Fleet Delivery"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HideRetailDelivery}
                        label="Hide Retail Delivery"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HidePremiumFleet}
                        label="Hide Premium Fleet"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.HidePremiumRetail}
                        label="Hide Premium Retail"
                        control={FormControlKeys.Checkbox}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultJobMovementType}
                        label="Default Job Movement Type"
                        control={FormControlKeys.RadioGroup}
                        options={JobMovementTypeOptions}
                      />
                      {!hideElement(values.HideInternalTransfer) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForInternalTransfer}
                            label="Job Type For 'Internal Transfer'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForInternalTransfer
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                      {!hideElement(values.HideDealerTransfer) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForDealerTransfer}
                            label="Job Type For 'Dealer  Transfer'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForDealerTransfer
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                      {!hideElement(values.HideFleetDelivery) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForFleetDelivery}
                            label="Job Type For 'Fleet Delivery'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForFleetDelivery
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                      {!hideElement(values.HideRetailDelivery) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForRetailDelivery}
                            label="Job Type For 'Retail Delivery'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForRetailDelivery
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                      {!hideElement(values.HidePremiumFleet) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForPremiumFleet}
                            label="Job Type For 'Premium Fleet'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForPremiumFleet
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                      {!hideElement(values.HidePremiumRetail) && (
                        <JobMovementTypeForContainer>
                          <FormikControl
                            name={DealerUIAddJobkeys.JobTypeForPremiumRetail}
                            label="Job Type For 'Premium Retail'"
                            control={FormControlKeys.RadioGroup}
                            options={JobTypeOptions}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.IgnoreFavouritesForPremiumRetail
                            }
                            label="Ignore Favourites?"
                            control={FormControlKeys.Checkbox}
                          />
                        </JobMovementTypeForContainer>
                      )}
                    </JobMovementTypeSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.ServiceTypesSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Service Types Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowServiceTypeSection}
                        label="Show Service Type Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowServiceTypeDriven}
                        label="Allow Service Type 'Driven'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <RadioGroupCheckboxContainer>
                        <FormikControl
                          name={DealerUIAddJobkeys.AllowServiceTypeTransported}
                          label="Allow Service Type 'Transported'"
                          control={FormControlKeys.RadioGroup}
                          options={YesNoOptions}
                        />
                        <FormikControl
                          name={DealerUIAddJobkeys.Show_Transported_Enclosed}
                          label="Show Enclosed Transport Option?"
                          control={FormControlKeys.Checkbox}
                        />
                      </RadioGroupCheckboxContainer>
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowServiceTypeBoth}
                        label="Allow Service Type 'Both'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultServiceType}
                        label="Default Service Type"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.Default_Driven_ValidMOT}
                        label="Default for Driven has a valid MOT?"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.Default_Driven_FuelRequired}
                        label="Default for Driven is fuel required?"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.Default_Transported_DriveOnto}
                        label="Default for Transported can drive onto Transporter?"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.VehiclesSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Vehicles Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowVehiclesSection}
                        label="Show Vehicles Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowMultiVehicleJobs}
                        label="Allow Multi-Vehicle Jobs"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.RequireVrmsOnJobsVehicles}
                        label="Require Vrms On Jobs Vehicles"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.RequireConditionOnJobsVehicles}
                        label="Require Condition On Jobs Vehicles"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.RequireValueOnJobsVehicles}
                        label="Require Value On Jobs Vehicles"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.SeeVRMBeforeJobAccepted}
                        label="Can See Vrms Before Job Accepted"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultNoOfVehicles}
                        label="Default No Of Vehicles"
                        type="number"
                        min={0}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.CollectionSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Collection Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowCollectionSection}
                        label="Show Collection Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.DeliverySection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Delivery Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowDeliverySection}
                        label="Show Delivery Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.DatesSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Dates Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowDatesSection}
                        label="Show Dates Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowExactDatesAndTimes}
                        label="Allow Exact Dates and Times"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={
                          DealerUIAddJobkeys.DefaultCollectionDateOffsetFromToday
                        }
                        label="Default Collection Date Offset From Today"
                        type="number"
                        min={0}
                      />
                      <FormikControl
                        name={
                          DealerUIAddJobkeys.DefaultDeliveryDateOffsetFromCollectionDateBeforeMidday
                        }
                        label="Default Delivery Date Offset From Collection Date Before Midday"
                        type="number"
                        min={0}
                      />
                      <FormikControl
                        name={
                          DealerUIAddJobkeys.DefaultDeliveryDateOffsetFromCollectionDateAfterMidday
                        }
                        label="Default Delivery Date Offset From Collection Date After Midday"
                        type="number"
                        min={0}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.JobTypeSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Job Type Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowJobTypeSection}
                        label="Show Job Type Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowQuoteRequests}
                        label="Allow Job Type 'Quote Requests'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowJobTypeBoth}
                        label="Allow Job Type 'Both' aka 'Fixed Price & Quote'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.AllowJobTypeGuaranteedPrice}
                        label="Allow Job Type 'Guaranteed Price'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.FixedPricesSetAutomatically}
                        label="Fixed Prices Set Automatically'"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      {values.FixedPricesSetAutomatically && (
                        <ChekboxesContainer>
                          <label>Allow editing if offered to: </label>
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.FixedPricesEditableForWholeNetwork
                            }
                            label="Whole Network"
                            control={FormControlKeys.Checkbox}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.FixedPricesEditableFiveStarCompanies
                            }
                            label="Five Star Companies"
                            control={FormControlKeys.Checkbox}
                          />
                          <FormikControl
                            name={
                              DealerUIAddJobkeys.FixedPricesEditableSelectableSuppliers
                            }
                            label="Selectable Suppliers"
                            control={FormControlKeys.Checkbox}
                          />
                        </ChekboxesContainer>
                      )}
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultJobType}
                        label="Default Job Type"
                        control={FormControlKeys.RadioGroup}
                        options={JobTypeOptions.filter(
                          (o) =>
                            o.value !== JobType.Unknown.toString() &&
                            o.value !== JobType.FixedPrice.toString()
                        )}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.JobOfferedToSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Job Offered To Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowJobOfferedToSection}
                        label="Show Job Offered To Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowSelectableSuppliers}
                        label="Show Selectable Suppliers"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <FormikControl
                        name={DealerUIAddJobkeys.SelectableSuppliers}
                        control={FormControlKeys.TypeaheadMulti}
                        placeholder="Search..."
                        options={getTypeaheadOptionsForSupplierField(
                          autoQuotesSupplierResponse?.MemberDetails
                        )}
                        onTypeaheadInput={(value: any) => {
                          onSearchSuppliersByUserNameInput(value);
                        }}
                        selected={preSelectedSuppliers}
                        onSelect={(values: any) => {
                          setPreSelectedSuppliers(values);
                        }}
                      />
                      <FormikControl
                        name={
                          DealerUIAddJobkeys.SelectableSuppliersChoosableByUser
                        }
                        label="User can choose selected suppliers?"
                        control={FormControlKeys.Checkbox}
                      />
                      <div className="auto-relist-selected-supplier-jobs-container">
                        <label>Auto-relist selected supplier jobs to: </label>{" "}
                        <FormikControl  style={{width:"110px"}}
                          name={
                            DealerUIAddJobkeys.SelectedSupplierJobAutoReOfferedTo
                          }
                          control={FormControlKeys.Select}
                          options={JobOfferedToOptions.filter(
                            (o) =>
                              o.value === JobOfferedTo.WholeNetwork ||
                              o.value === JobOfferedTo.FiveStarCompanies
                          )}
                          placeholder="Select"
                        />
                        after: {" "}
                        <FormikControl style={{width:"70px"}}
                          name={
                            DealerUIAddJobkeys.SelectedSupplierJobAutoReOfferedAfterValue
                          }
                          control={FormControlKeys.LabelledInput}
                          showClassName={false}
                          min={0}
                          className="form-control"
                        />
                        <FormikControl  style={{width:"90px"}}
                          name={
                            DealerUIAddJobkeys.SelectedSupplierJobAutoReOfferedAfterPeriod
                          }
                          control={FormControlKeys.Select}
                          options={JobReOfferPeriodOptions}
                          placeholder="Select"
                        />
                        <div> ReOffer To: </div>
                        <FormikControl style={{width:"100px"}}
                          name={
                            DealerUIAddJobkeys.SelectedSupplierJobAutoServiceType
                          }
                          control={FormControlKeys.Select}
                          options={ServiceTypeOptions.filter(
                            (o) => o.value !== ''
                          )}
                          placeholder="Select"
                        />
                      </div>
                      <FormikControl
                        name={DealerUIAddJobkeys.DefaultJobOfferedTo}
                        label="Default Job Offered To"
                        control={FormControlKeys.RadioGroup}
                        options={JobOfferedToOptions.filter(
                          (o) =>
                            o.value !== JobOfferedTo.TopRated.toString() &&
                            o.value !== JobOfferedTo.Favourites.toString()
                        )}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.AttachPhotosSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Attach Photos Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowAttachPhotosSection}
                        label="Show Attach Photos Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.ShippingNotesSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Shipping Notes Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowShippingNotesSection}
                        label="Show Shipping Notes Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.AdditionalNotesSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Additional Notes Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowAdditionalNotesSection}
                        label="Show Additional Notes Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                      <div>
                        <label className="fw-600">Default Notes :</label>
                        <FormikControl
                          name={DealerUIAddJobkeys.DefaultNoteForJobs}
                          label="Default"
                          control={FormControlKeys.Textarea}
                        />
                        <FormikControl
                          name={
                            DealerUIAddJobkeys.DefaultNoteForInternalTransferJobs
                          }
                          label="Internal Transfer"
                          control={FormControlKeys.Textarea}
                        />
                        <FormikControl
                          name={
                            DealerUIAddJobkeys.DefaultNoteForFleetDeliveryJobs
                          }
                          label="Fleet Delivery"
                          control={FormControlKeys.Textarea}
                        />
                        <FormikControl
                          name={
                            DealerUIAddJobkeys.DefaultNoteForRetailDeliveryJobs
                          }
                          label="Retail Delivery"
                          control={FormControlKeys.Textarea}
                        />
                        <FormikControl
                          name={
                            DealerUIAddJobkeys.DefaultNoteForPremiumFleetJobs
                          }
                          label="Premium Fleet"
                          control={FormControlKeys.Textarea}
                        />
                        <FormikControl
                          name={
                            DealerUIAddJobkeys.DefaultNoteForPremiumRetailJobs
                          }
                          label="Premium Retail"
                          control={FormControlKeys.Textarea}
                        />
                      </div>
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.YourReferenceSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Your Reference Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowYourReferenceSection}
                        label="Show Your Reference Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection ===
                      CustomConfigDealerAddJob.PromoCodeSection) && (
                    <CustomConfigSubSection>
                      <CustomConfigSubSectionHeader>
                        Promo Code Section
                      </CustomConfigSubSectionHeader>
                      <FormikControl
                        name={DealerUIAddJobkeys.ShowPromoCodeSection}
                        label="Show PromoCode Section"
                        control={FormControlKeys.RadioGroup}
                        options={YesNoOptions}
                      />
                    </CustomConfigSubSection>
                  )}
                  {(showAllDealerUIAddJobSections() ||
                    selectedDealerUIAddJobSection === 
                      CustomConfigDealerAddJob.ModelUserSection) && (
                      <CustomConfigSubSection>
                        <CustomConfigSubSectionHeader>
                          MoDel User
                        </CustomConfigSubSectionHeader>
                        <FormikControl
                          name={DealerUIAddJobkeys.AllowModelJobs}
                          label="Allow user to import MoDel jobs to Movex"
                          control={FormControlKeys.RadioGroup}
                          options={YesNoOptions}
                        />
                        {formik.values[DealerUIAddJobkeys.AllowModelJobs] === true && (
                          <FormikControl
                            name={DealerUIAddJobkeys.ModelVmAccountId}
                            label="MoDel VM Account ID"
                            type="string"
                            min={0}
                          />
                        )}
                      </CustomConfigSubSection>
                    )}
                </DealerUIAddJobContainer>
              )}

              {selectedConfigType === ConfigType.DealerFlowAddJobConfig && (
                <DealerUIAddJobContainer>
                  <DealerUIAddJobEachField>
                    <CustomConfigSubSectionHeader>
                      Auto Quotes For Driven Fixed Price
                    </CustomConfigSubSectionHeader>
                    <FormikControl
                      name={CustomConfigKeys.AutoQuotesForDrivenFixedPrice}
                      label="Suppliers"
                      control={FormControlKeys.TypeaheadMulti}
                      placeholder="Search..."
                      options={getTypeaheadOptionsForSupplierField(
                        autoQuotesSupplierResponse?.MemberDetails
                      )}
                      onTypeaheadInput={(value: any) => {
                        onSearchSuppliersByUserNameAndServiceTypeInput(
                          value,
                          ServiceType.Driven
                        );
                      }}
                      onSelect={(values: any) => {
                        setPreSelectedAutoQuotesDrivenSuppliers(values);
                      }}
                      selected={preSelectedAutoQuotesDrivenSuppliers}
                    />
                  </DealerUIAddJobEachField>
                  <DealerUIAddJobEachField>
                    <CustomConfigSubSectionHeader>
                      Auto Quotes For Transported Fixed Price
                    </CustomConfigSubSectionHeader>
                    <FormikControl
                      name={CustomConfigKeys.AutoQuotesForTransportedFixedPrice}
                      label="Suppliers"
                      control={FormControlKeys.TypeaheadMulti}
                      options={getTypeaheadOptionsForSupplierField(
                        autoQuotesSupplierResponse?.MemberDetails
                      )}
                      placeholder="Search..."
                      onTypeaheadInput={(value: any) => {
                        onSearchSuppliersByUserNameAndServiceTypeInput(
                          value,
                          ServiceType.Transported
                        );
                      }}
                      onSelect={(values: any) => {
                        setPreSelectedAutoQuotesTransportedSuppliers(values);
                      }}
                      selected={preSelectedAutoQuotesTransportedSuppliers}
                    />
                  </DealerUIAddJobEachField>
                </DealerUIAddJobContainer>
              )}

              {!!selectedConfigType && selectedConfigType > 0 && (
                <FormikControl
                  name="SaveConfigButton"
                  label="Save Config"
                  type="submit"
                  control={FormControlKeys.Button}
                />
              )}
            </ConfigTypeContainer>
          </FieldSetContainer>
        </FormFieldSetContainer>
      </FormContainer>
    </FormikProvider>
  );
};

export default CustomConfig;

export const ConfigTypeContainer = styled.div``;
export const DealerUIMaintMenuContainer = styled.div``;
export const DealerUIAddJobContainer = styled.div``;
export const CustomConfigSubSection = styled.div``;
export const JobMovementTypeSection = styled.div``;
export const JobMovementTypeForContainer = styled.div``;
export const RadioGroupCheckboxContainer = styled.div``;
export const ChekboxesContainer = styled.div``;
export const CustomConfigSubSectionHeader = styled.strong``;
export const DealerUIAddJobEachField = styled.div``;
