import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Rating } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import { CellRenderer, HoverableLink } from '../../../../../Components/Formik';
import { ControlledPagination as Pagination } from '../../../../../Components';
import { FeedbackResponseModel } from '../../../../../Models/Feedback';
import { RouteConstants } from '../../../../../Routes/Constants';
import { GridProps, Sorter } from '../../../../../Types';
import {
  ActionColumnPath,
  getSortModelFromSorter,
} from '../../../../../Utils';
import { FeedbackGridKeys } from '../Types';

const { renderCommentsCellWithTooltip, renderDateCell } = CellRenderer;

const renderFromToMemberCell = (
  params: GridRenderCellParams,
  isFrom: boolean
) => {
  const memberId = isFrom
    ? params.row.FromMemberID
    : params.row.ToMemberID;
  return (
    <HoverableLink to={`${RouteConstants.EditMember}/${memberId}`}>
      <span>{params.value}</span>
    </HoverableLink>
  );
};

const renderJobIdCell = (params: GridRenderCellParams) => {
  const jobId = params.row.JobID;
  return (
    <HoverableLink to={`${RouteConstants.ViewJobById}/${jobId}`}>
      <span>{params.value}</span>
    </HoverableLink>
  );
};

const renderRatingCell = (params: GridRenderCellParams) => {
  return <Rating readOnly value={params.value} size="small" />;
};

const columns: GridColDef[] = [
  {
    field: FeedbackGridKeys.ID,
    headerName: 'ID',
    description: 'ID',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <HoverableLink to={`${RouteConstants.EditFeedback}/${params.id}`}>
          <span>{params.id}</span>
        </HoverableLink>
      );
    },
    flex: 2,
  },
  {
    field: FeedbackGridKeys.FeedbackDate,
    headerName: 'Date',
    description: 'Date',
    flex: 6,
    renderCell: (params: GridRenderCellParams) => renderDateCell(params),
  },
  {
    field: FeedbackGridKeys.JobID,
    description: 'Job ID',
    headerName: 'Job ID',
    renderCell: (params: GridRenderCellParams) => renderJobIdCell(params),
    flex: 4,
  },
  {
    field: FeedbackGridKeys.FromMemberUserName,
    headerName: 'From',
    description: 'From',
    flex: 4,
    renderCell: (params: GridRenderCellParams) =>
      renderFromToMemberCell(params, true),
  },
  {
    field: FeedbackGridKeys.ToMemberUserName,
    description: 'To',
    headerName: 'To',
    flex: 4,
    renderCell: (params: GridRenderCellParams) =>
      renderFromToMemberCell(params, false),
  },
  {
    field: FeedbackGridKeys.Comments,
    description: 'Comments',
    headerName: 'Comments',
    flex: 4,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (renderCommentsCellWithTooltip(params)),
  },
  {
    field: FeedbackGridKeys.Payment,
    description: 'Payment',
    headerName: 'Payment',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    sortable:false,
  },
  {
    field: FeedbackGridKeys.SmartDrivers,
    description: 'Smart Drivers',
    headerName: 'Smart',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    sortable:false,
  },
  {
    field: FeedbackGridKeys.Promptness,
    description: 'Promptness',
    headerName: 'Prompt',
    flex: 4,
    sortable:false,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
  },
  {
    field: FeedbackGridKeys.Communication,
    description: 'Communication',
    headerName: 'Comm.',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    sortable:false,
  },
  {
    field: FeedbackGridKeys.Professionalism,
    description: 'Professionalism',
    headerName: 'Prof.',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    sortable:false,
  },
  {
    field: FeedbackGridKeys.ValueForMoney,
    description: 'ValueForMoney',
    headerName: 'Value',
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    flex: 4,
    sortable:false,
  },
  {
    field: FeedbackGridKeys.Overall,
    description: 'Overall',
    headerName: 'Overall',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderRatingCell(params),
    sortable:false,
  },
  {
    field: ActionColumnPath,
    description: 'Action',
    headerName: 'Action',
    type: 'actions',
    flex: 6,
    renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    sortable:false,
  },
];

const renderActionCell = (params: GridRenderCellParams) => {
  const feedbackId = parseInt(params.id.toString());
  return (
    <React.Fragment>
      <Tooltip title="Delete Feedback">
        <a
          href="#"
          style={{ paddingLeft: '10px',  width: '100%', alignContent: 'left' }}
          onClick={() => {
            params.row.onDelete(feedbackId);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </a>
      </Tooltip>
    </React.Fragment>
  );
};

const FeedbacksGridView: React.FC<FeedbacksGridProps> = (props) => {
  const {
    data: feedbacks,
    onPageChange,
    onPageSizeChange,
    onDelete,
    onSort,
  } = props;

  const deleteFeedback = (id : number) => { 
    if( onDelete !== undefined)
      onDelete(id);
  };

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  const { Page: page, TableSorter } = feedbacks;

  if (page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = page;

  const modifiedRows = Items.map(element => {
      return {
        ...element,
        onDelete: deleteFeedback
      };
    });

    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            currentPage={CurrentPage}
            pageSize={ItemsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    
    return (
    <div>
      {page && (
        <div className="feedbacks-container">
          <div id='id_grid_feedbacks' style={{ display: 'flex', height: '100%', width: '100%' }}>
          <DataGrid
            sortingOrder={['desc', 'asc']}
            getRowId={(row: any) => row.Id}
            autoHeight
            rows={modifiedRows}
            rowHeight={41}
            disableColumnMenu={true}
            columns={columns}
            columnBuffer={columns.length + 1}
            components={{
              Toolbar: CustomGridFooter,
              Footer: CustomGridFooter,
            }}
            disableExtendRowFullWidth={true}
            sortingMode="server"
            sortModel={getSortModelFromSorter(TableSorter as Sorter)}
            onSortModelChange={handleSort}
          />
          </div>
        </div>
      )}
      </div>
    );
  }
  return null;
};

export default FeedbacksGridView;

interface FeedbacksGridProps extends GridProps<FeedbackResponseModel> {}