import { OptionType } from "../../../Components/FormControls/Types"
import {
    MemberType,
    AdminMemberCombinedStatus,
    ServiceType,
    MemberDisabledReason,
    MemberMonthlyFeeType,
    CustomConfigDealerAddJob,
    ConfigType,
    JobKind,
    JobMovementType,
    JobType,
    JobOfferedTo,
    JobReOfferPeriod,
    AdminMemberType,
    TradingStatus,
    AdminCountry,
    AvgVehiclesMoved,
    HearAboutMovex,
    InvoiceFrequency,
    PaymentMethod,
    TypeOfBusiness,
    ActiveStatus,
    AdminSavedSearchCombinedStatus,
    SearchRadius,
    AdminJobStatus,
    QuoteStatus
} from "../../../Utils/Enums"
import { AdminBillsPaymentType } from "../../../Utils/Enums/AdminBillsPaymentType"
import { BillsCombinedStatus } from "../../../Utils/Enums/BillsCombinedStatus"
import { FreeTextSearchByOptions } from "../../../Utils/Enums/FreeTextSearchBy"

export const MemberStatusOptions: OptionType[] = [
    { value: "", label: "All Members" },
    {
        value: AdminMemberCombinedStatus.Enabled, label: "Enabled"
    },
    {
        value: AdminMemberCombinedStatus.Disabled, label: "Disabled"
    },
    {
        value: AdminMemberCombinedStatus.Active, label: "Active"
    },
    { value: AdminMemberCombinedStatus.Inactive, label: "Inactive" },
]

export const BillTypeOptions: OptionType[] = [
    { value: FreeTextSearchByOptions.DealerEmail, label: "DealerEmail" },
    { value: FreeTextSearchByOptions.DealerUsername, label: "DealerUsername" },
    { value: FreeTextSearchByOptions.SupplierEmail, label: "SupplierEmail" },
    { value: FreeTextSearchByOptions.SupplierUsername, label: "SupplierUsername" },
]

export const MemberTypeOptions: OptionType[] = [
    { value: "", label: "All Members" },
    { value: MemberType.MotorDealer, label: "Motor Dealers" },
    { value: MemberType.TransportCompany, label: "Transport Companies" },
    { value: MemberType.GeneralPublic, label: "General Public" },
]

export const MemberFreeTextSearchBy: OptionType[] = [
    { value: FreeTextSearchByOptions.CodaCustomerReference, label: "Coda Customer Reference" },
    { value: FreeTextSearchByOptions.CodaSupplierReference, label: "Coda Supplier Reference" },
    { value: FreeTextSearchByOptions.Company, label: "Company" },
    { value: FreeTextSearchByOptions.Email, label: "Email" },
    { value: FreeTextSearchByOptions.ID, label: "ID" },
    { value: FreeTextSearchByOptions.Username, label: "Username" }
];

export const GroupFreeTextSearchBy: OptionType[] = [
    { value: FreeTextSearchByOptions.Email, label: "Email" },
    { value: FreeTextSearchByOptions.Username, label: "Username" }
];

export const JobsFreeTextSearchBy: OptionType[] = [
    { value: null, label: "Any" },
    { value: FreeTextSearchByOptions.Dealer, label: "Dealer" },
    { value: FreeTextSearchByOptions.ID, label: "ID" },
    { value: FreeTextSearchByOptions.Supplier, label: "Supplier" }
];

export const SavedSearchFreeTextSearchBy: OptionType[] = [
    { value: FreeTextSearchByOptions.Email, label: "Email" },
    { value: FreeTextSearchByOptions.Username, label: "Username" }
];

export const FeedbackFreeTextSearchBy: OptionType[] = [
    { value: FreeTextSearchByOptions.FromUsername, label: "From Username" },
    { value: FreeTextSearchByOptions.FromEmail, label: "From Email" },
    { value: FreeTextSearchByOptions.ToUsername, label: "To Username" },
    { value: FreeTextSearchByOptions.JobID, label: "JobID" },
    { value: FreeTextSearchByOptions.ToEmail, label: "To Email" }
];

export const ServiceTypeOptions: OptionType[] = [
    { value: "", label: "All Members" },
    { value: ServiceType.Driven, label: "Driven" },
    { value: ServiceType.Transported, label: "Transported" },
    { value: ServiceType.Both, label: "Both" },
]

export const ServiceTypeOptionsForRelist: OptionType[] = [
    { value: ServiceType.Driven, label: "Driven" },
    { value: ServiceType.Transported, label: "Transported" },
    { value: ServiceType.Both, label: "Both" },
]

export const PriceGroupServiceTypeOption: OptionType[] = [
    { value: ServiceType.Driven, label: "Driven" },
    { value: ServiceType.Transported, label: "Transported" },
]

export const MembershipActiveDisableReasonOptions: OptionType[] = [
    { value: "", label: "Select" },
    { value: MemberDisabledReason.SecurityIssue, label: "Security Issue" },
    { value: MemberDisabledReason.AdminRemoved, label: "Admin Removed" },
    { value: MemberDisabledReason.PaymentProblems, label: "Payment Problems" },
    { value: MemberDisabledReason.EmailProblems, label: "Email Problems" },
    { value: MemberDisabledReason.Unknown, label: "Unknown" }
]

export const KeyAccountOptions: OptionType[] = [
    { value: -1, label: "Both" },
    { value: 1, label: "Yes" },
    { value: 0, label: "No" }
]

export const YesNoOptions: OptionType[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
]

export const MembershipActiveStatusOptions: OptionType[] = [
    { value: true, label: "Active Member" },
    { value: false, label: "Inactive / Account Closed" }
]

export const MembershipActiveDisableOptions: OptionType[] = [
    { value: "true", label: "Yes, Account Disabled!" },
    { value: "false", label: "No, Account Enabled!" }
]

export const MonthlyFeeTypeOptions: OptionType[] = [
    { value: MemberMonthlyFeeType.PayMonthly, label: "Pay Monthly", },
    { value: MemberMonthlyFeeType.PayAsYouGo, label: "Pay As You Go" },
    { value: MemberMonthlyFeeType.NotPaying, label: "Not Paying" },
]

export const CustomConfigDealerAddJobOptions: OptionType[] = [
    { value: CustomConfigDealerAddJob.All, label: "Show All" },
    { value: CustomConfigDealerAddJob.JobKindSection, label: "Job Kind Section" },
    { value: CustomConfigDealerAddJob.JobMovementTypeSection, label: "Job Movement Type Section" },
    { value: CustomConfigDealerAddJob.ServiceTypesSection, label: "Service Types Section" },
    { value: CustomConfigDealerAddJob.VehiclesSection, label: "Vehicles Section" },
    { value: CustomConfigDealerAddJob.CollectionSection, label: "Collection Section" },
    { value: CustomConfigDealerAddJob.DeliverySection, label: "Delivery Section" },
    { value: CustomConfigDealerAddJob.DatesSection, label: "Dates Section" },
    { value: CustomConfigDealerAddJob.JobTypeSection, label: "Job Type Section" },
    { value: CustomConfigDealerAddJob.JobOfferedToSection, label: "Job Offered To Section" },
    { value: CustomConfigDealerAddJob.AttachPhotosSection, label: "Attach Photos Section" },
    { value: CustomConfigDealerAddJob.ShippingNotesSection, label: "Shipping Notes Section" },
    { value: CustomConfigDealerAddJob.AdditionalNotesSection, label: "Additional Notes Section" },
    { value: CustomConfigDealerAddJob.YourReferenceSection, label: "Your Reference Section" },
    { value: CustomConfigDealerAddJob.PromoCodeSection, label: " Promo Code Section" },
    { value: CustomConfigDealerAddJob.ModelUserSection, label: "Model User Section" },
];

export const CustomConfigOptions: OptionType[] = [
    {
        value: "DealerUI", label: "Dealer UI",
        children: [
            { value: ConfigType.DealerUIMainMenuConfig, label: "Dealer UI Main Menu" },
            { value: ConfigType.DealerUIAddJobConfig, label: "Dealer UI Add Job" },],
    },
    {
        value: "DealerFlow", label: "Dealer Flow",
        children: [
            { value: ConfigType.DealerFlowAddJobConfig, label: "Dealer Flow Add Job" },]
    }
];

export const JobKindOptions: OptionType[] = [
    { value: JobKind.Standard, label: "Standard" },
    { value: JobKind.Return, label: "Return" },
    { value: JobKind.Onward, label: "Onward" },
    { value: JobKind.MultipleDropOff, label: "Multiple Drop Off" },
]

export const JobMovementTypeOptions: OptionType[] = [
    { value: JobMovementType.InternalTransfer, label: "Internal Transfer" },
    { value: JobMovementType.DealerTransfer, label: "Dealer Transfer" },
    { value: JobMovementType.FleetDelivery, label: "Fleet Delivery" },
    { value: JobMovementType.RetailDelivery, label: "Retail Delivery" },
    { value: JobMovementType.PremiumFleet, label: "Premium Fleet" },
    { value: JobMovementType.PremiumRetail, label: "Premium Retail" },
    { value: JobMovementType.Unknown, label: "Unknown" },
]

export const JobTypeOptions: OptionType[] = [
    { value: JobType.Unknown, label: "Any" },
    { value: JobType.RequestQuote, label: "Request Quote" },
    { value: JobType.FixedPrice, label: "Fixed Price" },
    { value: JobType.Both, label: "Both" },
    { value: JobType.GuaranteedPrice, label: "Guaranteed Price" }
]

export const JobTypeForRelistOptions: OptionType[] = [
    { value: JobType.RequestQuote, label: "Request Quote" },
    { value: JobType.FixedPrice, label: "Fixed Price" },
    { value: JobType.Both, label: "Both" },
]

export const JobOfferedToOptions: OptionType[] = [
    { value: JobOfferedTo.WholeNetwork, label: "Whole Network" },
    { value: JobOfferedTo.FiveStarCompanies, label: "5 Star Companies" },
    { value: JobOfferedTo.SelectedSuppliers, label: "Selected Suppliers" },
    { value: JobOfferedTo.TopRated, label: "Top Rated" },
    { value: JobOfferedTo.Favourites, label: "Favourites" },
]

export const JobReOfferPeriodOptions: OptionType[] = [
    { value: JobReOfferPeriod.Seconds, label: "Seconds" },
    { value: JobReOfferPeriod.Minutes, label: "Minutes" },
    { value: JobReOfferPeriod.Hours, label: "Hours" },
    { value: JobReOfferPeriod.Days, label: "Days" },
]

export const AdminMemberTypeOptions: OptionType[] = [
    { value: AdminMemberType.MotorDealer, label: "Motor Dealer" },
    { value: AdminMemberType.TransportCompany, label: "Transport Company" },
    { value: AdminMemberType.GeneralPublic, label: "General Public" }
]

export const TradingStatusOptions: OptionType[] = [
    { value: TradingStatus.SoleTrader, label: "Sole Trader" },
    { value: TradingStatus.Partnership, label: "Partnership" },
    { value: TradingStatus.Ltd, label: "Limited Company (Ltd)." },
    { value: TradingStatus.Plc, label: "Public Limited Company (Plc)" },
    { value: TradingStatus.LLP, label: "Limited Liability Partnership (LLP)" },
    { value: TradingStatus.LLC, label: "Limited Liability Company (LLC)" },
    { value: TradingStatus.LimitedByGuarantee, label: "Limited by Guarantee" },
]

export const AdminCountryOptions: OptionType[] = [
    { value: AdminCountry.IE, label: "Ireland" },
    { value: AdminCountry.UK, label: "UK" },
    { value: AdminCountry.EU, label: "Other European" }
]

export const AvgVehiclesMovedOptions: OptionType[] = [
    { value: AvgVehiclesMoved.A_1to10, label: "1 to 10" },
    { value: AvgVehiclesMoved.B_11to50, label: "11 to 50" },
    { value: AvgVehiclesMoved.C_51to100, label: "51 to 100" },
    { value: AvgVehiclesMoved.D_100plus, label: "100" },
    { value: AvgVehiclesMoved.Unknown, label: "Unknown" },
]

export const HearAboutMovexOptions: OptionType[] = [
    { value: HearAboutMovex.Telesales, label: "Telesales" },
    { value: HearAboutMovex.Referral, label: "Referral" },
    { value: HearAboutMovex.Google, label: "Google" },
    { value: HearAboutMovex.Autotrade_Mail, label: "Autotrade Mail" },
    { value: HearAboutMovex.Auction4Cars, label: "Auction4Cars" },
    { value: HearAboutMovex.Partner, label: "Partner" },
    { value: HearAboutMovex.AMMagazine, label: "AM Magazine" },
    { value: HearAboutMovex.AMOnline, label: "AM Online" },
    { value: HearAboutMovex.MotorTraderMagazine, label: "MotorTrader Magazine" },
    { value: HearAboutMovex.MotorTraderOnline, label: "MotorTrader Online" },
    { value: HearAboutMovex.InstantPrices, label: "Instant Prices" },
    { value: HearAboutMovex.Other, label: "Other" },
]

export const InvoiceFrequencyOptions: OptionType[] = [
    { value: InvoiceFrequency.Monthly, label: "Monthly" },
    { value: InvoiceFrequency.Weekly, label: "Weekly" },
    { value: InvoiceFrequency.Fortnightly, label: "Fortnightly" },
    { value: InvoiceFrequency.Never, label: "Never" },
]

export const PaymentMethodOptions: OptionType[] = [
    { value: PaymentMethod.Pending, label: "Pending" },
    { value: PaymentMethod.SmartDebit, label: "Smart Debit" },
    { value: PaymentMethod.Invoice, label: "Invoice" },
    { value: PaymentMethod.PrePay, label: "PrePay" },
]

export const StatusOptions: OptionType[] = [
    { value: "", label: "All" },
    { value: BillsCombinedStatus.Current, label: "Current Bills" },
    { value: BillsCombinedStatus.Sent, label: "Sent Bills" },
    { value: BillsCombinedStatus.Unpaid, label: "Unpaid Bills" },
    { value: BillsCombinedStatus.Paid, label: "Paid Bills" },
    { value: BillsCombinedStatus.Missed, label: "Missed Bills" },
    { value: BillsCombinedStatus.NoCharge, label: "No Charge" },
    { value: BillsCombinedStatus.Temp, label: "Temp" },
]

export const PaymentMethodTypeOptions: OptionType[] = [
    { value: "", label: "All" },
    { value: AdminBillsPaymentType.SmartDebit, label: "Smart Debit" },
    { value: AdminBillsPaymentType.Invoice, label: "Invoice" },
]

export const TypeOfBusinessOptions: OptionType[] = [
    { value: TypeOfBusiness.FranchiseDealership, label: "Franchise Dealership" },
    { value: TypeOfBusiness.IndependentUserCarDealership, label: "Independent User Car Dealership" },
    { value: TypeOfBusiness.CarTrader, label: "Car Trader" },
    { value: TypeOfBusiness.Auction, label: "Auction" },
    { value: TypeOfBusiness.FinanceCompany, label: "Finance Company" },
    { value: TypeOfBusiness.RentalCompany, label: "Rental Company" },
    { value: TypeOfBusiness.RemarketingCompany, label: "Remarketing Company" },
    { value: TypeOfBusiness.ShippingCompany, label: "Shipping Company" },
    { value: TypeOfBusiness.SalvageCompany, label: "Salvage Company" },
    { value: TypeOfBusiness.Other, label: "Other" },
]

export const AdminSavedSearchCombinedStatusOptions: OptionType[] = [
    { value: AdminSavedSearchCombinedStatus.DrivenService, label: "Driven Service" },
    { value: AdminSavedSearchCombinedStatus.TransportedService, label: "Transported Service" },
    { value: AdminSavedSearchCombinedStatus.ActiveSearch, label: "Active Search" },
    { value: AdminSavedSearchCombinedStatus.InactiveSearch, label: "Inactive Search" },
]

export const SearchRadiusOptions: OptionType[] = [
    { value: SearchRadius.A_5_Miles, label: "5 Miles" },
    { value: SearchRadius.B_10_Miles, label: "10 Miles" },
    { value: SearchRadius.C_25_Miles, label: "25 Miles" },
    { value: SearchRadius.D_50_Miles, label: "50 Miles" },
    { value: SearchRadius.E_100_Miles, label: "100 Miles" },
    { value: SearchRadius.F_200_Miles, label: "200 Miles" },
    { value: SearchRadius.G_National, label: "National" },
    { value: SearchRadius.H_European, label: "European" },
]

export const DistanceFromCollectionOrDelivery: OptionType[] = [
    { value: SearchRadius.A_5_Miles, label: "5 Miles" },
    { value: SearchRadius.B_10_Miles, label: "10 Miles" },
    { value: SearchRadius.C_25_Miles, label: "25 Miles" },
]

export const AdminJobStatusOptions: OptionType[] = [
    { value: AdminJobStatus.Cancelled, label: "Cancelled" },
    { value: AdminJobStatus.Aborted, label: "Aborted" },
    { value: AdminJobStatus.PendingQuotes, label: "Pending" },
    { value: AdminJobStatus.AcceptedQuote, label: "Accepted" },
    { value: AdminJobStatus.BookedJob, label: "Booked" },
    { value: AdminJobStatus.ActiveJob, label: "Active" },
    { value: AdminJobStatus.CompletedJob, label: "Completed" },
    { value: AdminJobStatus.AdminRemoved_Or_Failed, label: "Admin Removed / Failed" },
    { value: AdminJobStatus.Expired, label: "Expired" },
    { value: AdminJobStatus.Unknown, label: "Unknown" },
    { value: AdminJobStatus.Draft, label: "Draft" },
]


export const QuoteStatusOptions: OptionType[] = [
    { value: QuoteStatus.Accepted, label: "Accepted" },
    { value: QuoteStatus.Completed, label: "Completed" },
    { value: QuoteStatus.Active, label: "Active" },
    { value: QuoteStatus.ConfirmDeclined, label: "Confirm Declined" },
    { value: QuoteStatus.Declined, label: "Declined" },
    { value: QuoteStatus.Deleted, label: "Deleted" },
    { value: QuoteStatus.Removed, label: "Removed" },
    { value: QuoteStatus.Negotiated, label: "Negotiated" },
    { value: QuoteStatus.Placed, label: "Placed" },
    { value: QuoteStatus.Unknown, label: "Unknown" }
]