import { FormikProps } from "formik";
import { AddInvoiceKeys } from "./Types";
import { MemberInformation } from ".";

export const updateFormFields = (
    member: MemberInformation,
    formik: FormikProps<any>
) => {
    formik.setFieldValue(AddInvoiceKeys.MemberID, member.Id);
    formik.setFieldValue(AddInvoiceKeys.MemberUserName, member.UserName);
    formik.setFieldValue(AddInvoiceKeys.MemberUserName, member.UserName);
    formik.setFieldValue(AddInvoiceKeys.MemberCodaCustomerReference, member.CodaCustomerReference);
    formik.setFieldValue(AddInvoiceKeys.MemberCodaCustomerReference, member.CodaSupplierReference);
    formik.setFieldValue(AddInvoiceKeys.MemberCompany, member.Company);
    formik.setFieldValue(AddInvoiceKeys.MemberContactName, member.ContactName1);
    formik.setFieldValue(AddInvoiceKeys.MemberAddress1, member.Address1);
    formik.setFieldValue(AddInvoiceKeys.MemberAddress2, member.Address2);
    formik.setFieldValue(AddInvoiceKeys.MemberAddress3, member.Address3);
    formik.setFieldValue(AddInvoiceKeys.MemberTown, member.Town);
    formik.setFieldValue(AddInvoiceKeys.MemberCounty, member.County);
    formik.setFieldValue(AddInvoiceKeys.MemberPostCode, member.PostCode);
    formik.setFieldValue(AddInvoiceKeys.MemberLandline, member.Landline);
    formik.setFieldValue(AddInvoiceKeys.MemberVATRegistered, member.Vatregistered ?? false);
    formik.setFieldValue(AddInvoiceKeys.MemberVATNo, member.Vatno);
};

export const itemMatchesInitialData = (
    item: any, 
    initialData: any
): boolean => {
    return Object.keys(initialData).every(
        key => item[key] === initialData[key]
    );
};