export enum PermissionType {
     UI = 'UI',
     API = 'API',
     Task = 'Tasks',
     WebAPI = 'Web-API',
     Operation = 'Operation',
}

export interface PermissionModel {
     Id: number;
     Permission: string;
     PermissionGroup: string;
     PermissionType: PermissionType;
}
