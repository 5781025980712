import { FC } from 'react';
import { HoverableLink } from '../../../../../Components/Formik';
import { PriceGroupSourceOrTarget } from '../../../../../Models/PriceGroup';
import { Close, PriceGroupItem, PriceGroupList } from '../../../../../Components/UI';
import { ApplyEntityTypeStyle } from './ApplyEntityTypeStyle';

const MembersList: FC<propsType> = ({ data, onDeleteItem }) => {
     return (
          <PriceGroupList>
               {data.map((item: PriceGroupSourceOrTarget, index: number) => (
                    <PriceGroupItem key={item.Id + '_' + index}>
                         {ApplyEntityTypeStyle(item?.SourceType ?? item?.TargetType)}{' '}
                         <HoverableLink className='hoverable-tag clickable' to={`xxx`}>
                              {item.Name}
                         </HoverableLink>
                         <Close
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                   onDeleteItem(item.Id);
                              }}
                         >
                              x
                         </Close>
                    </PriceGroupItem>
               ))}
          </PriceGroupList>
     );
};

interface propsType {
     data: any[];
     onDeleteItem: Function;
}

export default MembersList;
