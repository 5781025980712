import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsBoxArrowRight, BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import {
  JobActivityHistoryFilter,
  JobsActivityHistoryFilterKeys,
} from '../../../../../Models/Jobs/JobActivityHistory';
import { JobActivityTypeOptions } from '../Constants';
import * as Yup from 'yup';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch, onExportCSV, showExport } = props;
  const initialValues: JobActivityHistoryFilter = {};

  const validationSchema = Yup.object({
    [JobsActivityHistoryFilterKeys.JobID]: Yup.number()
      .typeError('Job ID must be a number')
      .positive('Job ID must be a positive number')
      .integer('Job ID must be an integer')
      .required('Job ID is required'),
  });

  /** Methods */
  const onSubmit = (values: JobActivityHistoryFilter) => {
    formik.validateForm().then(() => {
      onSearch(values);
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  const { handleSubmit, resetForm } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={JobsActivityHistoryFilterKeys.JobID}
                    label="Job ID"
                    title="Searches by Job ID"
                    type="number"
                    touched={true}
                  />
                  <div>{formik.errors[JobsActivityHistoryFilterKeys.JobID as keyof JobActivityHistoryFilter]}</div>
                </Col>
                <Col>
                  <FormikControl
                    name={JobsActivityHistoryFilterKeys.ActivityFromDate}
                    label="From Date"
                    type="date"
                    max={formik.values.ActivityToDate}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={JobsActivityHistoryFilterKeys.ActivityToDate}
                    label="To Date"
                    type="date"
                    min={formik.values.ActivityFromDate}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={JobsActivityHistoryFilterKeys.ActivityType}
                    label="Activity Type"
                    control={FormControlKeys.Select}
                    placeholder="Select"
                    options={JobActivityTypeOptions}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      type="reset"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    {showExport && (
                      <FormikControl
                        name="ExportCsv"
                        label="Export To CSV"
                        className="btn btn-light span2"
                        onClick={onExportCSV}
                        control={FormControlKeys.ButtonGroup}
                        icon={<BsBoxArrowRight />}
                      />
                    )}
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;

export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
  onExportCSV: Function;
  showExport: boolean;
}
