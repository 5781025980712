import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../Components/Formik/StyledComponents';
import { GoogleLogoIcon } from '../../../../../Assets/Images';

export const ContactUs = (model: any) => {
  const {
    YourName,
    PhoneNumber,
    EmailAddress,
    ConfirmEmailAddress,
    Message,
    SubmitContactUs,
  } = model;

  const formikInitialValues = {};

  const onSubmit = (values: any) => {
    SubmitContactUs(values);
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });

  const form = (
    <FormikProvider value={formik}>
      <FormContainer>
        <div className="row contact-form-row">
          <div className="container">
            <form autoComplete="off">
              <div className="row">
                <div className="col-xs-12 col-lg-12 text-center">
                  Please fill in the form below and we'll be in touch:
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-6">
                  <FormikControl placeholder="Your Name *" name={'YourName'} />
                </div>
                <div className="col-xs-12 col-lg-6">
                  <FormikControl
                    placeholder="Your Phone Number *"
                    name={'PhoneNumber'}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-6">
                  <FormikControl
                    placeholder="Your Email *"
                    name={'EmailAddress'}
                  />
                </div>
                <div className="col-xs-12 col-lg-6">
                  <FormikControl
                    placeholder="Confirm Email *"
                    name={'ConfirmEmailAddress'}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-12">
                  <FormikControl
                    placeholder="Your Message *"
                    name={'Message'}
                  />
                </div>
              </div>
              <div className="row row-last">
                <div className="col-xs-12 col-lg-12">
                  <FormikControl type="submit" label="SEND MESSAGE" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </FormContainer>
    </FormikProvider>
  );

  return (
    <div className="contact-us-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>CONTACT US</h1>
            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="row contact-details-row">
        <img className="google-logo" src={GoogleLogoIcon} alt="Google" />
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-xs-offset-0 col-lg-6 col-lg-offset-6">
              <p className="trading-address">
                <strong>TRADING ADDRESS</strong>
                <br />
                Crate
                <br />
                15 Oakwood Hill Industrial Estate
                <br />
                Loughton
                <br />
                IG10 3TZ
              </p>
              <p className="registered-address">
                <strong>REGISTERED ADDRESS</strong>
                <br />
                Central House
                <br />
                Leeds Road
                <br />
                Rothwell
                <br />
                Leeds
                <br />
                LS26 0JE
              </p>
              <p className="opening-hours">
                <strong>OPENING HOURS</strong>
                <br />
                Mon - Fri
                <br />
                9am - 5pm
                <br />
              </p>
              <p className="telephone-number">
                <strong>TEL</strong>
                <br />
                0208 551 1060
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {form}
    </div>
  );
};
