import { useEffect,useState } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import { Alert,AlertProps,Loading, useAlert } from '../../../../Components';
import {
ColMd6SectionInner,
DivContainer,
FieldSetContainer,
FieldSetHeader,
FlexContainer,
InlineBlockColMd4Section
} from '../../../../Components/Formik';
import { renderHeader } from '../../../../Components/PageHeader';
import {
AddSourceToPriceGroupRequest,
AddTargetToPriceGroupRequest,
IDeleteSourceFormPriceGroup,
IDeleteTargetFormPriceGroup,
ISourceOrTarget
} from '../../../../Models/PriceGroup';
import { RouteConstants } from '../../../../Routes/Constants';
import {
useDeleteSourceFromPriceGroup,
useDeleteTargetFromPriceGroup,
useGetPriceGroup,
useGetPriceGroupPrices,
useGetPriceGroupSources,
useGetPriceGroupTargets,
usePostAddSourceToPriceGroup,
usePostAddTargetToPriceGroup
} from './Hooks';
import PriceGroupGeneralForm from './PriceGroupGeneralForm';
import PricesEditor from './PricesEditor';
import { LiveSearch } from './SourcesAndTargetsDynamicLists/LiveSearch';
import ItemsList from './SourcesAndTargetsDynamicLists/MembersList';
import { PriceGroupIDParamType } from './Types';

const EditPriceGroup = () => {
     const history = useHistory();
     const { priceGroupID: paramPriceGroupID } = useParams<PriceGroupIDParamType>();
     const priceGroupID = paramPriceGroupID ? parseInt(paramPriceGroupID) : 0;

     /** useStates */
     const [dealerList, setDealerList] = useState([] as any[]);
     const [transporterList, setTransporterList] = useState([] as any[]);
     const [isEditPage, setIsEditPage] = useState(false);
     const [isLoading, setIsLoading] = useState(false);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Methods */
     const HandleAddDealerItem = (item: any) => {
          const payload = {
               PriceGroupId: priceGroupID,
               SourceId: item.Id,
               SourceType: item.Type,
          } as AddSourceToPriceGroupRequest;
          postAddSourceToPriceGroup(payload);
          setDealerList((oldArray) => [...oldArray, item]);
     };

     const HandleAddTransporterItem = (item: ISourceOrTarget) => {
          const payload = {
               PriceGroupId: priceGroupID,
               TargetId: item.Id,
               TargetType: item.Type,
          } as AddTargetToPriceGroupRequest;
          postAddTargetToPriceGroup(payload);
          setTransporterList((oldArray) => [...oldArray, item]);
     };

     const [formReset, setFormReset] = useState(false);

     const handleResetForm = () => {
          setFormReset(true);
     };

     const refetchThePageWithTheNewlyCreatedPriceGroup = () => {
          console.log('history', history);
          history.push(RouteConstants.ViewPriceGroups);
     };

     /** React Query Hook */
     const {
          mutate: postAddSourceToPriceGroup,
          isLoading: postAddSourceToPriceGroupLoading,
          data: addSourceToPriceGroupResponse,
          error: addSourceToPriceGroupError,
     } = usePostAddSourceToPriceGroup();

     const {
          refetch: getPriceGroup,
          data: getPriceGroupResponse,
          error: getPriceGroupError,
     } = useGetPriceGroup(priceGroupID);

     const {
          refetch: refetchPriceslistOfPriceGroup,
          data: getPriceGroupPricesResponse,
          error: getPriceGroupPricesError,
     } = useGetPriceGroupPrices(priceGroupID);

     const {
          data: getPriceGroupSourcesResponse,
          error: getPriceGroupSourcesError,
          refetch: refetchPriceGroupSources,
     } = useGetPriceGroupSources(priceGroupID);

     const {
          data: getPriceGroupTargetsResponse,
          error: getPriceGroupTargetsError,
          refetch: refetchPriceGroupTargets,
     } = useGetPriceGroupTargets(priceGroupID);

     const {
          mutate: postAddTargetToPriceGroup,
          isLoading: isPostAddTargetToPriceGroupLoading,
          data: addTargetToPriceGroupResponse,
          error: addTargetToPriceGroupError,
     } = usePostAddTargetToPriceGroup();

     const {
          mutate: deleteSourceFormPriceGroup,
          isLoading: isdeleteSourceFormPriceGroupLoading,
          error: putDeleteSourceFormPriceGroupError,
          isSuccess: putDeleteSourceFormPriceGroupSuccess,
     } = useDeleteSourceFromPriceGroup();

     const {
          mutate: deleteTargetFormPriceGroup,
          isLoading: isdeleteTargetFormPriceGroupLoading,
          error: putDeleteTargetFormPriceGroupError,
          isSuccess: putDeleteTargetFormPriceGroupSuccess,
     } = useDeleteTargetFromPriceGroup();

     const onDeletePriceGroupSource = (sourceId: number) => {
          let payload = {
               priceGroupId: priceGroupID,
               sourceId: sourceId,
          } as IDeleteSourceFormPriceGroup;
          deleteSourceFormPriceGroup(payload);
     };

     const onDeletePriceGroupTarget = (targetId: number) => {
          let payload = {
               priceGroupId: priceGroupID,
               targetId: targetId,
          } as IDeleteTargetFormPriceGroup;
          deleteTargetFormPriceGroup(payload);
     };

     /* useEffect */
     useEffect(() => {
          if (priceGroupID) {
               getPriceGroup();
               setIsEditPage(true);
          } else setIsEditPage(false);
     }, [priceGroupID]);

     useEffect(() => {
          setIsLoading(true);
     }, [
          isdeleteTargetFormPriceGroupLoading,
          isdeleteSourceFormPriceGroupLoading,
          isPostAddTargetToPriceGroupLoading,
          postAddSourceToPriceGroupLoading,
     ]);

     useEffect(() => {
          if (putDeleteSourceFormPriceGroupSuccess) {
               refetchPriceGroupSources();
          }
     }, [putDeleteSourceFormPriceGroupSuccess]);

     useEffect(() => {
          if (putDeleteTargetFormPriceGroupSuccess) {
               refetchPriceGroupTargets();
          }
     }, [putDeleteTargetFormPriceGroupSuccess]);

     useEffect(() => {
          setIsLoading(false);
          if (priceGroupID) {
               if (getPriceGroupSourcesResponse?.PriceGroupSources)
                    setDealerList(getPriceGroupSourcesResponse?.PriceGroupSources);
               if (getPriceGroupTargetsResponse?.PriceGroupTargets)
                    setTransporterList(getPriceGroupTargetsResponse?.PriceGroupTargets);
          }
     }, [getPriceGroupResponse, getPriceGroupPricesResponse?.Prices, getPriceGroupSourcesResponse, getPriceGroupTargetsResponse]);

     /* once the source has been added against the PriceGroup, let's retrieve the new source list */
     useEffect(() => {
          setIsLoading(false);
          if (addSourceToPriceGroupResponse) {
               refetchPriceGroupSources();
          }
          if (addSourceToPriceGroupError) {
               let newArray = dealerList.slice(0, dealerList.length - 1);
               setDealerList(newArray);
          }
     }, [addSourceToPriceGroupResponse, addSourceToPriceGroupError]);

     /* once the targets has been added against the PriceGroup, let's retrieve the new target list */
     useEffect(() => {
          setIsLoading(false);
          if (addTargetToPriceGroupResponse) {
               refetchPriceGroupTargets();
          }
          if (addTargetToPriceGroupError) {
               let newArray = transporterList.slice(0, transporterList.length - 1);
               setTransporterList(newArray);
          }
     }, [addTargetToPriceGroupResponse, addTargetToPriceGroupError]);

     /* Error Handling */
     useEffect(() => {
          if (addSourceToPriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addSourceToPriceGroupError?.Subject,
                    body: addSourceToPriceGroupError?.Description,
               });
          } else if (getPriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getPriceGroupError?.Subject,
                    body: getPriceGroupError?.Description,
               });
          } else if (getPriceGroupPricesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getPriceGroupPricesError?.Subject,
                    body: getPriceGroupPricesError?.Description,
               });
          } else if (getPriceGroupSourcesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getPriceGroupSourcesError?.Subject,
                    body: getPriceGroupSourcesError?.Description,
               });
          } else if (getPriceGroupTargetsError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getPriceGroupTargetsError?.Subject,
                    body: getPriceGroupTargetsError?.Description,
               });
          } else if (addTargetToPriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addTargetToPriceGroupError?.Subject,
                    body: addTargetToPriceGroupError?.Description,
               });
          } else if (putDeleteSourceFormPriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: putDeleteSourceFormPriceGroupError?.Subject,
                    body: putDeleteSourceFormPriceGroupError?.Description,
               });
          } else if (putDeleteTargetFormPriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: putDeleteTargetFormPriceGroupError?.Subject,
                    body: putDeleteTargetFormPriceGroupError?.Description,
               });
          }
          setIsLoading(false);
     }, [
          addSourceToPriceGroupError,
          getPriceGroupError,
          getPriceGroupPricesError,
          getPriceGroupSourcesError,
          getPriceGroupTargetsError,
          addTargetToPriceGroupError,
          putDeleteSourceFormPriceGroupError,
          putDeleteTargetFormPriceGroupError,
     ]);

     return (
          <>
               <Alert {...alert} />
               {renderHeader('Edit Price Group')}
               <FieldSetContainer>
                    <FlexContainer>
                         <InlineBlockColMd4Section>
                              <ColMd6SectionInner>
                                   <FieldSetHeader>General</FieldSetHeader>
                                   <PriceGroupGeneralForm
                                        getPriceGroupResponse={getPriceGroupResponse}
                                        priceGroupId={priceGroupID}
                                        refetchThePageWithTheNewlyCreatedPriceGroup={refetchThePageWithTheNewlyCreatedPriceGroup}
                                   />
                              </ColMd6SectionInner>
                         </InlineBlockColMd4Section>
                         <InlineBlockColMd4Section>
                              <ColMd6SectionInner>
                                   <DivContainer>
                                        {' '}
                                        <DivContainer>
                                             <FieldSetHeader>Dealers (Sources):</FieldSetHeader>
                                             {isEditPage && (
                                                  <>
                                                       <div>
                                                            {' '}
                                                            <Loading loading={isLoading} />
                                                            <LiveSearch
                                                                 data={dealerList as any[]}
                                                                 onAddItem={HandleAddDealerItem}
                                                            />
                                                            <ItemsList
                                                                 data={dealerList as any[]}
                                                                 onDeleteItem={onDeletePriceGroupSource}
                                                            />
                                                       </div>
                                                  </>
                                             )}
                                             {!isEditPage && <p>Please save first!</p>}
                                        </DivContainer>
                                   </DivContainer>
                              </ColMd6SectionInner>
                         </InlineBlockColMd4Section>
                         <InlineBlockColMd4Section>
                              <ColMd6SectionInner>
                                   <DivContainer>
                                        {' '}
                                        <FieldSetHeader>Transporters (Targets):</FieldSetHeader>
                                        <DivContainer>
                                             {' '}
                                             {isEditPage && (
                                                  <>
                                                       {' '}
                                                       <Loading loading={isLoading} />
                                                       <LiveSearch
                                                            data={transporterList as any[]}
                                                            onAddItem={HandleAddTransporterItem}
                                                       />
                                                       <ItemsList
                                                            data={transporterList as any[]}
                                                            onDeleteItem={onDeletePriceGroupTarget}
                                                       />
                                                  </>
                                             )}
                                             {!isEditPage && <p>Please save first!</p>}
                                        </DivContainer>
                                   </DivContainer>
                              </ColMd6SectionInner>
                         </InlineBlockColMd4Section>
                    </FlexContainer>
               </FieldSetContainer>
               <FieldSetContainer>
                    {isEditPage && (
                         <>
                              <PricesEditor
                                   prices={getPriceGroupPricesResponse?.Prices}
                                   refetchPricesList={refetchPriceslistOfPriceGroup}
                                   priceGroupId={priceGroupID}
                                   priceGroupType={getPriceGroupResponse?.PriceGroup.Type ?? 0}
                              />
                         </>
                    )}
                    {!isEditPage && (
                         <>
                              {' '}
                              <FieldSetHeader>Prices</FieldSetHeader>
                              <p>Please save first!</p>
                         </>
                    )}
               </FieldSetContainer>
          </>
     );
};

export default EditPriceGroup;
