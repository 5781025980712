/** Inbuilt libraries */
import { Tooltip } from '@mui/material';
import CSS from 'csstype';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
/** Custom Components */
import { FailIndicatorIcon, SuccessIndicatorIcon } from '../../../../../Assets/Images';
import { FormControlKeys } from '../../../../../Components/FormControls';
import { FieldSetHeader, HoverableLink } from '../../../../../Components/Formik';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { BillingModel, Charge, PaymentModel } from '../../../../../Models/Billing/AddBilling';
import { getFormattedDateWithYear } from '../../../../../Utils';
import { StyledTd, StyledTdNoCharge } from '../../BillsStyledComponents';
import {
     ShowMarkAsPaid,
     V3b_AmountTotal,
     V3b_GrandTotal,
     V3b_SupplyingMemberFeeTotal,
     V3b_SupplyingMemberMonthlyFee,
     V3b_VAT,
} from '../billingFunctions';
import { RouteConstants } from '../../../../../Routes';

const cellImageStyle: CSS.Properties = { height: '25px', width: '25px' };

const BillVersion3b: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { bill, payments, onShowModule } = props;

     /** useState */
     const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
     const [paymentsList, setPaymentsList] = useState<PaymentModel[]>(payments);
     const [monthlyFeeChecked, setMonthlyFeeChecked] = useState<boolean>(false);

     /** Methods */
     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {},
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     const handleAllChecked = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          let paymentsListToUpdate = paymentsList;
          paymentsListToUpdate.forEach((payment) => {
               if (!payment.MovexPaidDate) {
                    payment.isChecked = event.currentTarget.checked;
               }
          });
          setMonthlyFeeChecked(event.currentTarget.checked);
          setPaymentsList([...paymentsListToUpdate]);
     };

     const handleCheckMonthlyFee = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          setMonthlyFeeChecked((prevCheckedValue) => !prevCheckedValue);
     };

     const handleShowModule = () => {
          const jobIDs = paymentsList?.filter((item) => {
                    if (item.isChecked) return true;
                    else return false;
               })
               .map((payment) => {
                    return payment.JobId!;
               });
          onShowModule(bill.ID, jobIDs, false, monthlyFeeChecked);
     };

     return (
          <>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>Payments</FieldSetHeader>
                              <table id='id_table_bill_version3b_payments' className='table table-bordered table-condensed table-striped'>
                                   <thead>
                                        <tr>
                                             <td></td>
                                             <td>Client</td>
                                             <td>Job ID / Invoiced</td>
                                             <td>Created Date</td>
                                             <td>Due Date</td>
                                             <td>Job Cost</td>
                                             <td>Fee</td>
                                             <td>
                                                  Movex Paid?
                                                  <br />
                                                  {ShowMarkAsPaid(bill, payments) ? (
                                                       <input
                                                            type='checkbox'
                                                            onClick={handleAllChecked}
                                                            value='checkedAll'
                                                       />
                                                  ) : (
                                                       'false'
                                                  )}
                                             </td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {isWaitingData && <tr>Loading...</tr>}
                                        {!isWaitingData &&
                                             payments?.map((data: PaymentModel, index: number) => {
                                                  const {
                                                       RequestingMemberID,
                                                       RequestingMemberUserName,
                                                       RequestingMemberInvoiceFileName,
                                                       JobId,
                                                       CreatedDate,
                                                       DueDate,
                                                       QuoteAmount,
                                                       SupplyingMemberFee,
                                                       SupplierPaidDate,
                                                  } = data;
                                                  return (
                                                       <tr>
                                                            <td>{index + 1}</td>
                                                            <td>                              
                                                                 <HoverableLink to={`${RouteConstants.EditMember}/${RequestingMemberID}`}>
                                                                      {RequestingMemberUserName}
                                                                 </HoverableLink>
                                                            </td>
                                                            <td>                              
                                                                 <HoverableLink to={`${RouteConstants.ViewJobById}/${JobId}`}>
                                                                      {JobId}
                                                                 </HoverableLink>
                                                                 {RequestingMemberInvoiceFileName && ' - Invoiced'}
                                                            </td>
                                                            <td>
                                                                 {getFormattedDateWithYear(
                                                                      CreatedDate!
                                                                 )}
                                                            </td>
                                                            <td>
                                                                 {getFormattedDateWithYear(
                                                                      DueDate!
                                                                 )}
                                                            </td>
                                                            <td>£{QuoteAmount}</td>
                                                            <td>£{SupplyingMemberFee}</td>
                                                            <td>
                                                                 {SupplierPaidDate ? (
                                                                      getFormattedDateWithYear(
                                                                           SupplierPaidDate
                                                                      )
                                                                 ) : (
                                                                      <Tooltip title='Not Key Account'>
                                                                           <img
                                                                                alt='No'
                                                                                style={
                                                                                     cellImageStyle
                                                                                }
                                                                                src={
                                                                                     FailIndicatorIcon
                                                                                }
                                                                           ></img>
                                                                      </Tooltip>
                                                                 )}
                                                            </td>
                                                       </tr>
                                                  );
                                             })}
                                   </tbody>

                                   {payments?.length === 0 && (
                                        <tbody>
                                             <tr>
                                                  <StyledTdNoCharge colSpan={8}>
                                                       No charges found!
                                                  </StyledTdNoCharge>
                                             </tr>
                                        </tbody>
                                   )}

                                   <tfoot>
                                        {payments?.length > 0 && (
                                             <tr>
                                                  <StyledTd colSpan={6}>
                                                       Total £{V3b_AmountTotal(payments)}
                                                  </StyledTd>
                                                  <td>
                                                       £
                                                       {V3b_SupplyingMemberFeeTotal(bill, payments)}
                                                  </td>
                                                  <td></td>
                                             </tr>
                                        )}
                                        <tr>
                                             <StyledTd colSpan={6}>
                                                  {' '}
                                                  Monthly Fee{' '}
                                             </StyledTd>
                                             <td> {V3b_SupplyingMemberMonthlyFee(bill)}</td>

                                             <td>
                                                  {bill.SupplyingMemberMonthlyFeePaidDate && (
                                                       <>
                                                            <Tooltip title='Supplier Paid Date'>
                                                                 <img
                                                                      alt='SupplyingMemberMonthlyFeePaidDate'
                                                                      style={cellImageStyle}
                                                                      src={SuccessIndicatorIcon}
                                                                 ></img>
                                                            </Tooltip>
                                                            getFormattedDateWithYear(bill.SupplyingMemberMonthlyFeePaidDate)
                                                       </>
                                                  )}
                                                  {!bill.SupplyingMemberMonthlyFeePaidDate && (
                                                       <>
                                                            {ShowMarkAsPaid(bill, payments) ? (
                                                                 <input
                                                                      key={'monthlyFeeCheckbox'}
                                                                      onClick={
                                                                           handleCheckMonthlyFee
                                                                      }
                                                                      type='checkbox'
                                                                      checked={monthlyFeeChecked}
                                                                 />
                                                            ) : (
                                                                 <Tooltip title='Supplier Paid Date'>
                                                                      <img
                                                                           alt='SupplierPaidDate'
                                                                           style={cellImageStyle}
                                                                           src={FailIndicatorIcon}
                                                                      ></img>
                                                                 </Tooltip>
                                                            )}
                                                       </>
                                                  )}
                                             </td>
                                        </tr>
                                        <tr>
                                             <StyledTd colSpan={6}>
                                                  VAT{' '}
                                             </StyledTd>
                                             <td>£{V3b_VAT(bill, payments)}</td>
                                             <td></td>
                                        </tr>
                                        <tr>
                                             <StyledTd colSpan={6}>
                                                  Grand Total
                                             </StyledTd>
                                             <td colSpan={1}>£{V3b_GrandTotal(bill, payments)}</td>
                                             {
                                                  payments.length !==0 && (
                                                       <td>
                                                       <FormikControl
                                                            name='MarkPaid'
                                                            label='MarkPaid'
                                                            type='submit'
                                                            onClick={handleShowModule}
                                                            control={FormControlKeys.Button}
                                                       />
                                                  </td>
                                                  )
                                             }
                                             
                                        </tr>
                                   </tfoot>
                              </table>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     payments: PaymentModel[];
     bill: BillingModel;
     charges: Charge[];
     onShowModule: Function;
};

export default BillVersion3b;
