export enum MotabilityAbortReason {
    Adaptations = 0,
    Bodywork = 1,
    Engine = 2,
    InteriorCondition = 3,
    Mechanical = 4,
    Mirror = 5,
    NonStarter = 6,
    RAC = 7,
    Vandalised = 8,
    WriteOff = 9,
    RSAM = 10,
    DealerIssuesOrOtherMotabilityError = 11,
    CustomerIssue = 12,
    CancelledTooLate = 13,
    Keys = 14,
    AlreadyCollected = 15,
    AltCollectionAddressOrCompound = 16,
    LEOrSold = 17,
    Weather = 18,
    NoMOT = 19,
    Windscreen = 20,
    Tyres = 21,
    EML = 22,
    InRepairs = 23,
    WorkNotComplete = 24,
    AbortReasonUnknown = 25
}