import axios from 'axios';
import { jsonConfig,responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const { 
     GetReportsJobsByUser_URL, 
     GetReportsNewOrUpdatedMembers_URL, 
     GetReportStatistics_URL,
     GetReportJobsByUsersToCSV_URL,
     GetReportsNewOrUpdatedMembersToCSV_URL
 } = ApiURLConstants;

export const reports = {
     getReportJobsByUsers: (params: any): Promise<any> => {
          return axios.post(GetReportsJobsByUser_URL, params, jsonConfig).then(responseBody);
     },
     GetReportsNewOrUpdatedMembers: (params: any): Promise<any> => {
          return axios.post(GetReportsNewOrUpdatedMembers_URL, params, jsonConfig).then(responseBody);
     },
     GetReportStatistics: async (params: any): Promise<any> => {
          const response = await axios.post(GetReportStatistics_URL, params, jsonConfig);
          return responseBody(response);
     },
     //Missing in API
     GetReportJobsByUsersToCSV: (params: any): Promise<any> => {
          return axios.post(GetReportJobsByUsersToCSV_URL, params, jsonConfig).then(responseBody);
     },
     //Missing in API
     GetReportsNewOrUpdatedMembersToCSV: (params: any): Promise<any> => {
          return axios.post(GetReportsNewOrUpdatedMembersToCSV_URL, params, jsonConfig).then(responseBody);
     },
};
