import { useParams } from 'react-router-dom';
import { MemberIDParamType } from '../..';
import { GetMemberResponse } from '../../../../../../Models/Members';
import { RequestingMemberFeeType } from '../../../../../../Utils/Enums';
import MarginMaintenance from '../../../../Common/MarginMaintenance';

type DealerMarginTypeProps = {
     getMemberResponse?: GetMemberResponse;
};

const DealerMargin: React.FC<DealerMarginTypeProps> = ({ getMemberResponse }) => {
     const { memberID: paramMemberID } = useParams<MemberIDParamType>();
     const memberID = paramMemberID ? parseInt(paramMemberID) : 0;

     const memberType = getMemberResponse?.Member?.MemberType;

     const component = (
          <>
               <MarginMaintenance
                    requestingMemberFeeType={RequestingMemberFeeType.Dealer}
                    memberID={memberID}
                    memberType={memberType}
               />
          </>
     );
     return component;
};

export default DealerMargin;
