import { OptionType } from '../../../../Components/FormControls';
import { AdminJobStatus, PaymentStatus } from '../../../../Utils/Enums';
import { BillPaymentStatus } from '../../../../Utils/Enums/BillPaymentStatus';

export const PaymentsJobStatusOptions: OptionType[] = [
     {
          value: AdminJobStatus.All,
          label: 'All Jobs',
     },
     {
          value: AdminJobStatus.ActiveJob,
          label: 'Active Jobs',
     },
     {
          value: AdminJobStatus.CompletedJob,
          label: 'Completed Jobs',
     },
     {
          value: AdminJobStatus.Aborted,
          label: 'Aborted Jobs',
     },
     {
          value: AdminJobStatus.AdminRemoved_Or_Failed,
          label: 'Admin Removed / Failed Jobs',
     }
];

export const PaymentInformationStatusOptions: OptionType[] = [
     {
          value: BillPaymentStatus.Unpaid,
          label: 'Unpaid Bill',
     },
     {
          value: BillPaymentStatus.Paid,
          label: 'Paid Bill',
     },
     {
          value: BillPaymentStatus.Missed,
          label: 'Missed Bill',
     },
     {
          value: BillPaymentStatus.NoCharge,
          label: 'No Charge',
     },
];

export const PaymentStatusOptions: OptionType[] = [
     {
          value: PaymentStatus.All,
          label: 'All Payments',
     },
     {
          value: PaymentStatus.Pending,
          label: 'Pending',
     },
     {
          value: PaymentStatus.Approved,
          label: 'Approved',
     },
     {
          value: PaymentStatus.Paused,
          label: 'Paused',
     },
     {
          value: PaymentStatus.Paid,
          label: 'Paid',
     },
     {
          value: PaymentStatus.Failed,
          label: 'Failed',
     },
     {
          value: PaymentStatus.NotPayable,
          label: 'Not Payable',
     },
];
