import React from 'react';
import { HomeSingleCarTransporter } from '../../../../../Assets/Images';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../Components/Formik/StyledComponents';
import { FormikProvider, useFormik } from 'formik';

export const InstantPrices = (model: any) => {
  const { ChangePassword, DistanceMiles, SelectedPrice } = model;

  const isDirty = true;
  const isBestPrice = true;
  const hasVRM = true;
  const weeks: any[] = [];
  const days: any[] = [];
  const formikInitialValues = {};

  const onSubmit = (values: any) => {
    ChangePassword(values);
  };
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });

  return (
    <div className="home-page">
      <div className="container-fluid">
        <div className="row get-an-instant-price-row form-row no-pad-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-8">
                <h2>
                  To get your vehicle delivered to your door simply enter your
                  postcodes for an instant price...
                </h2>
                <h2>We guarantee to save you time and money!</h2>
                <br />
              </div>
              <div className="col-xs-12 col-lg-4">
                <img
                  className="single-car-transporter"
                  alt="Single Car Transporter"
                  src={HomeSingleCarTransporter}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <FormikProvider value={formik}>
                  <FormContainer>
                    <form className="form-horizontal">
                      <div className="form-group">
                        <div className="col-xs-12 col-sm-4 col-lg-3">
                          <FormikControl
                            label="PickUp"
                            name={'PickUp'}
                            placeholder="PickUp"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-lg-3">
                          <FormikControl
                            label="DropOff"
                            name={'DropOff'}
                            placeholder="DropOff"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-xs-12 col-sm-4 col-lg-3">
                          {hasVRM && (
                            <FormikControl
                              label="VRM"
                              name={'VRM'}
                              placeholder="VRM"
                            />
                          )}
                          {!hasVRM && (
                            <FormikControl
                              label="VehicleMake"
                              name={'VehicleMake'}
                              placeholder="VehicleMake"
                            />
                          )}

                          <div className="hidden-xs">
                            <a
                              className="toggle-hasvrm"
                              href="#toggle-hasvrm"
                              data-bind="click: ToggleHasVRM"
                              tabIndex={-1}
                            >
                              <span data-bind="text: ToggleHasVRMLabel">
                                I don't have a VRM.
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-lg-3">
                          {!hasVRM && (
                            <FormikControl
                              label="VehicleModel"
                              name={'VehicleModel'}
                              placeholder="VehicleModel"
                            />
                          )}

                          <div className="checkbox hidden-xs">
                            <label>
                              <input
                                type="checkbox"
                                data-bind="checked: IsVehicleOperational"
                                tabIndex={8}
                              />
                              <span></span>
                              Vehicle is operational
                            </label>
                          </div>
                        </div>

                        <div className="col-xs-6 visible-xs-block">
                          <div>
                            <a
                              className="toggle-hasvrm"
                              href="#toggle-hasvrm"
                              data-bind="click: ToggleHasVRM"
                              tabIndex={-1}
                            >
                              <span data-bind="text: ToggleHasVRMLabel">
                                I don't have a VRM.
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-6 visible-xs-block">
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                data-bind="checked: IsVehicleOperational"
                                tabIndex={8}
                              />
                              <span></span>
                              Is operational
                            </label>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-lg-3">
                          <button
                            className="btn btn-lg btn-primary btn--get-instant-price"
                            data-bind="click: GetInstantPrices, disable: IsGettingPrices"
                            tabIndex={9}
                          >
                            <span data-bind="visible: !IsGettingPrices()">
                              <span>Get An Instant Price</span>
                              <span
                                className="glyphicon glyphicon-chevron-right glyphicon-white"
                                aria-hidden="true"
                              ></span>
                            </span>
                            <span
                              data-bind="visible: IsGettingPrices()"
                              style={{ display: 'none' }}
                            >
                              <i className="fa fa-spinner fa-spin"></i>
                              <span className="">Finding Prices...</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    .
                  </FormContainer>
                </FormikProvider>
              </div>
            </div>
            <div
              className="alert alert-danger"
              role="alert"
              data-bind="slideVisible: ShowPaymentError"
              style={{ display: 'none' }}
            >
              <strong data-bind="text: Error"></strong>:{' '}
              <span data-bind="text: Description"></span>
            </div>
          </div>
        </div>

        {!isDirty && (
          <>
            {' '}
            <div
              className="row get-an-instant-price-row header-row no-pad-bottom no-pad-top"
              data-bind="slideVisible: HasPrices, slideOut: false, scrollTo: HasPrices"
              style={{ display: 'none' }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <h2 className="strapline">
                      {isBestPrice && (
                        <>
                          <span>
                            Great news, the lowest price for our transported
                            service is
                          </span>{' '}
                          <span
                            className="price"
                            data-bind="text: BestPrice().PriceStr"
                          ></span>
                          !
                        </>
                      )}
                    </h2>
                    <p
                      className="stage-instruction"
                      data-bind="text: StageInstruction"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row get-an-instant-price-row stages-row no-pad-top"
              data-bind="slideVisible: HasPrices, slideOut: false"
              style={{ display: 'none' }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-lg-8">
                    <form>
                      <div
                        className="carousel slide"
                        id="instant-price-carousel"
                        data-interval="false"
                        data-wrap="false"
                        data-bind="carousel: { index: Stage }"
                      >
                        <div className="carousel-inner" role="listbox">
                          <div className="item active">
                            <div
                              className="price-calendar"
                              data-bind="with: PriceCalendar"
                            >
                              <table id='id_table_instant_prices' className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>
                                      <a
                                        className="month-changer"
                                        href="#prev"
                                        data-bind="click: Prev"
                                      >
                                        <span className="sr-only">Prev</span>
                                        <span
                                          className="glyphicon glyphicon-chevron-left glyphicon-white"
                                          aria-hidden="true"
                                        ></span>
                                      </a>
                                    </th>
                                    <th colSpan={5}>
                                      <div className="month-title">
                                        <span data-bind="text: Month"></span>{' '}
                                        <span data-bind="text: Year"></span>
                                      </div>
                                    </th>
                                    <th>
                                      <a
                                        className="month-changer"
                                        href="#next"
                                        data-bind="click: Next"
                                      >
                                        <span className="sr-only">Next</span>
                                        <span
                                          className="glyphicon glyphicon-chevron-right glyphicon-white"
                                          aria-hidden="true"
                                        ></span>
                                      </a>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Mon</th>
                                    <th>Tue</th>
                                    <th>Wed</th>
                                    <th>Thu</th>
                                    <th>Fri</th>
                                    <th>Sat</th>
                                    <th>Sun</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {weeks.map((week) => {
                                    return (
                                      <tr>
                                        {days.map((day) => {
                                          return (
                                            <td>
                                              <div
                                                className="date-content"
                                                data-bind="click: $parents[1].SelectPrice, css: Style"
                                              >
                                                <div className="date-row">
                                                  <strong data-bind="text: Day"></strong>
                                                  <small data-bind="text: Description"></small>
                                                </div>
                                                <div
                                                  className="price-row"
                                                  data-bind="if: HasPrice"
                                                >
                                                  <span
                                                    className="price"
                                                    data-bind="text: PriceStr"
                                                  ></span>
                                                </div>
                                                <div>
                                                  <small>
                                                    {isBestPrice && (
                                                      <>
                                                        <span
                                                          className="glyphicon glyphicon-star glyphicon-white"
                                                          aria-hidden="true"
                                                        ></span>{' '}
                                                        <span>Best</span>
                                                        <span className="hidden-xs">
                                                          {' '}
                                                          Price!
                                                        </span>
                                                      </>
                                                    )}
                                                    &nbsp;
                                                  </small>
                                                </div>
                                              </div>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              <div
                                className="loading-overlay"
                                data-bind="if: $root.IsGettingPrices, visible: $root.IsGettingPrices"
                                style={{ display: 'none' }}
                              ></div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="details-panel">
                              <div className="row">
                                <div className="col-xs-12">
                                  <h3>Your Details</h3>
                                </div>
                                <FormikProvider value={formik}>
                                  <FormContainer>
                                    <div className="col-xs-12 col-md-4">
                                      <FormikControl
                                        label="First Name"
                                        name={'FirstName'}
                                        placeholder="First Name"
                                      />
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                      <FormikControl
                                        label="Last Name"
                                        name={'LastName'}
                                        placeholder="Last Name"
                                      />
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                      <FormikControl
                                        label="Number"
                                        name={'Number'}
                                        placeholder="Contact Name"
                                      />
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                      <FormikControl
                                        label="Email"
                                        name={'Email'}
                                        placeholder="Email"
                                      />
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                      <FormikControl
                                        label="Confirm Email"
                                        name={'ConfirmEmail'}
                                        placeholder="Confirm Email"
                                      />
                                    </div>
                                    <div className="col-xs-12">
                                      <FormikControl
                                        label="Notes"
                                        name={'Notes'}
                                        placeholder="Notes"
                                      />
                                    </div>
                                  </FormContainer>
                                </FormikProvider>
                              </div>
                            </div>
                            <div className="details-panel">
                              <div className="row">
                                <div className="col-xs-12">
                                  <h3>Pick Up Details</h3>
                                </div>
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <input
                                      className="form-control input-lg"
                                      type="text"
                                      placeholder="Pick Up Address"
                                      readOnly={true}
                                      data-bind="value: PickUp.FullAddress"
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <FormikControl
                                    label="PickUpName"
                                    name={'PickUpName'}
                                    placeholder="Contact Name"
                                  />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <FormikControl
                                    label="PickUp Number"
                                    name={'PickUpNumber'}
                                    placeholder="PickUp Number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="details-panel">
                              <div className="row">
                                <div className="col-xs-12">
                                  <h3>Drop Off Details</h3>
                                </div>
                                <div className="col-xs-12">
                                  <FormikControl
                                    label="FullAddress"
                                    name={'FullAddress'}
                                    placeholder="Contact Address"
                                    readOnly="readOnly"
                                  />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <FormikControl
                                    label="DropOffName"
                                    name={'DropOffName'}
                                    placeholder="Contact Name"
                                    readOnly="readOnly"
                                  />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <FormikControl
                                    label="DropOffNumber"
                                    name={'DropOffNumber'}
                                    placeholder="Contact Number"
                                    readOnly="readOnly"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="instant-price-carousel-cta clearfix">
                        <button
                          className="btn btn-lg btn-default pull-left"
                          data-bind="click: PrevStage, enable: PrevEnabled, visible: PrevVisible"
                        >
                          <span
                            className="glyphicon glyphicon-chevron-left"
                            aria-hidden="true"
                          ></span>
                          <span data-bind="text: PrevText"></span>
                        </button>
                        <button
                          className="btn btn-lg btn-success pull-right"
                          data-bind="click: NextStage, enable: NextEnabled"
                        >
                          <span data-bind="text: NextText"></span>
                          <span
                            className="glyphicon glyphicon-chevron-right glyphicon-white"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-xs-12 col-lg-4">
                    <div className="job-details">
                      <div className="map-canvas">
                        <img
                          alt="GOOGLE MAP"
                          src=""
                          data-bind="attr: { src: MapUrl }"
                        />
                      </div>
                      <div className="job-details-item clearfix">
                        <div className="pull-left">
                          <div>
                            <small>Distance</small>
                          </div>
                          <div>
                            <span data-bind="text: Math.round(DistanceMiles())"></span>{' '}
                            miles
                          </div>
                        </div>
                        <div className="pull-right">
                          <div>
                            <small>Duration</small>
                          </div>
                          <div>
                            <span data-bind="text: DurationString(DurationSeconds())"></span>
                          </div>
                        </div>
                      </div>

                      {DistanceMiles > 200 && (
                        <div className="job-details-item job-details-warning">
                          <small>
                            Please note: as your job is over 100 miles, your
                            delivery may arrive the following day. If you
                            require a same day delivery, please call us.
                          </small>
                        </div>
                      )}

                      <div className="job-details-item">
                        <div>
                          <small>Vehicle</small>
                        </div>
                        <div>
                          <span data-bind="text: Vehicle().VehicleMake"></span>{' '}
                          <span data-bind="text: Vehicle().VehicleModel"></span>
                        </div>
                      </div>
                      <div className="job-details-item clearfix">
                        <div className="pull-left">
                          <div>
                            <small>Collection</small>
                          </div>
                          <div>
                            {SelectedPrice && (
                              <span data-bind="text: SelectedPrice().DateStr"></span>
                            )}
                          </div>
                        </div>
                        <div className="pull-right">
                          <div>
                            <small>Delivery</small>
                          </div>
                          <div>
                            {SelectedPrice && (
                              <span data-bind="text: SelectedPrice().DateStr"></span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="job-details-item clearfix">
                        <div className="pull-left">
                          <div>
                            <small>From</small>
                          </div>
                          <div>
                            <span data-bind="text: PickUp.FullAddress"></span>
                          </div>
                        </div>
                        <div className="pull-right">
                          <div>
                            <small>To</small>
                          </div>
                          <div>
                            <span data-bind="text: DropOff.FullAddress"></span>
                          </div>
                        </div>
                      </div>
                      <div className="job-details-item job-details-price clearfix">
                        <div className="pull-left">
                          <span>Total Price</span>
                        </div>
                        <div className="pull-right">
                          {SelectedPrice && (
                            <span data-bind="text: SelectedPrice().DateStr"></span>
                          )}
                        </div>
                      </div>
                      <div
                        className="loading-overlay"
                        data-bind="if: IsGettingPrices, visible: IsGettingPrices"
                        style={{ display: 'none' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row if-you-need-a-vehicle-collecting-row">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <h2>If you need a vehicle collecting we provide...</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-4">
                <ul>
                  <li>Instant Price</li>
                  <li>Next Day Deliveries</li>
                  <li>Full UK &amp; European Coverage</li>
                  <li>Over 10,000 Deliveries Weekly</li>
                  <li>Full Comprehensive Insurance</li>
                </ul>
              </div>
              <div className="col-xs-12 col-lg-4">
                <ul>
                  <li>Mobile App</li>
                  <li>Real-time Tracking</li>
                  <li>Transported Service</li>
                  <li>Quick &amp; Simple To Use</li>
                  <li>Fast &amp; Reliable Service</li>
                </ul>
              </div>
              <div className="col-xs-12 col-lg-4 iphone-and-macbook">
                <img
                  alt="iPhone and Macbook"
                  src="/Images/Redesign/home-iphone-and-macbook.jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row track-and-manage-row">
          <div className="col-xs-12 col-lg-12 no-mrg no-pad">
            <div className="track-and-manage-container">
              <img
                className="google-logo"
                src="~/Images/Redesign/googlelogo_color_272x92dp.png"
                alt="Google"
              />
              <div className="container">
                <div className="col-xs-12 col-lg-6">
                  <div className="track-and-manage-animation-container">
                    <div className="track-and-manage-animation">
                      <div className="track-and-manage-animation-routeContainer">
                        <div className="track-and-manage-animation-routeDrawer">
                          <div className="track-and-manage-animation-route"></div>
                        </div>
                      </div>
                      <div className="track-and-manage-point track-and-manage-originPoint">
                        <span className="track-and-manage-pin">
                          <img src="/Images/Redesign/home-track-and-manage-marker.png" />
                        </span>
                        <div className="track-and-manage-label">
                          <span>COLLECTED</span>
                        </div>
                      </div>
                      <div className="track-and-manage-point track-and-manage-destinationPoint">
                        <span className="track-and-manage-pin">
                          <img src="/Images/Redesign/home-track-and-manage-marker.png" />
                        </span>
                        <div className="track-and-manage-label">
                          <span>DELIVERED</span>
                        </div>
                      </div>
                      <div className="track-and-manage-transitContainer">
                        <div className="track-and-manage-transitInverseContainer">
                          <div className="track-and-manage-point track-and-manage-transitPoint">
                            <div className="track-and-manage-dot"></div>
                            <div className="track-and-manage-label">
                              <span>ON ROUTE</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-lg-6">
                  <div className="track-and-manage-copy">
                    <h4>TRACK YOUR VEHICLE</h4>
                    <p>
                      Track your vehicle from collection point to delivery, get
                      real-time ETA's
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row why-use-movex-row">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-6">
                <div className="row">
                  <div className="col-xs-6 col-lg-6 text-center">
                    <img
                      alt="Fast and Easy"
                      src="/Images/Redesign/home-why-use-movex-1.png"
                    />
                    <h4>Fast and Easy</h4>
                    <hr />
                    <p>
                      Complete one simple form and quotes come to you via email.
                      Hassle free, no phone calls required
                    </p>
                  </div>
                  <div className="col-xs-6 col-lg-6 text-center">
                    <img
                      alt="Great Prices"
                      src="/Images/Redesign/home-why-use-movex-2.png"
                    />
                    <h4>Great Prices</h4>
                    <hr />
                    <p>
                      Movex is able to ensure low costs due to the effectiveness
                      and efficiency of our technology
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-lg-6">
                <div className="row">
                  <div className="col-xs-6 col-lg-6 text-center">
                    <img
                      alt="Trustworthy"
                      src="/Images/Redesign/home-why-use-movex-3.png"
                    />
                    <h4>Trustworthy</h4>
                    <hr />
                    <p>
                      View delivery company's profiles for feedback, rating and
                      insurance cover for peace of mind
                    </p>
                  </div>
                  <div className="col-xs-6 col-lg-6 text-center">
                    <img
                      alt="Helpful"
                      src="/Images/Redesign/home-why-use-movex-4.png"
                    />
                    <h4>Helpful</h4>
                    <hr />
                    <p>
                      Need assistance?
                      <br />
                      Email or call us. We are here to help
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row testimonials-row">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <div
                  id="testimonials-carousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#testimonials-carousel"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#testimonials-carousel"
                      data-slide-to="1"
                    ></li>
                  </ol>

                  <div className="carousel-inner" role="listbox">
                    <div className="item active">
                      <div className="testimonial-thumb">
                        <img alt="Sytner" src="/Images/sytner.png" />
                      </div>
                      <div className="testimonial-body">
                        <p>
                          <span className="testimonial-speechmark">“</span>
                          Since using Movex we have moved hundreds of vehicles
                          and find the process extremely user-friendly.
                          Financially, we save approximately £40 per driven and
                          £60 per transported request. If we contact the Movex
                          office, the staff are very pleasant and always on hand
                          to help with any enquiries.
                          <br />
                          Great site, highly recommend!
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testimonial-thumb">
                        <img alt="Carology" src="/Images/carology.png" />
                      </div>
                      <div className="testimonial-body">
                        <p>
                          <span className="testimonial-speechmark">“</span>
                          Thank you to the Movex team for a great logistic
                          package, we have now completed over 3,000 jobs. The
                          site is of great importance to us both in
                          profitability and logistics practicality. The ease of
                          use, with its interactive map and backload system, has
                          made the site very easy for our team of logistics
                          managers to quote for jobs and plan routes.
                          <br />
                          We have no hesitation recommending Movex to anyone in
                          vehicle logistics.
                        </p>
                      </div>
                    </div>
                  </div>

                  <a
                    className="left carousel-control"
                    href="#testimonials-carousel"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="glyphicon glyphicon-chevron-left"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control"
                    href="#testimonials-carousel"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="glyphicon glyphicon-chevron-right"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
