import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { ViewPaymentsRequestParam, ViewPaymentsResponseModel } from '../../../../../Models/Jobs/Payments';

export const useGetPayments = (payload: ViewPaymentsRequestParam,
    options?: any
): UseQueryResult<ViewPaymentsResponseModel, any> => {

    const getPayments = () => {
        return agent.payments.getPayments(payload)
    }

    return useQuery(['GetPayments', payload], getPayments, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        ...options,
    });
}
