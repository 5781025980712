import React, { useEffect, useState } from "react";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import { InlineBlockColMd6AndAlignTopSection } from "../../../../../Components/Formik/StyledComponents";
import { JobStatus } from "../../../../../Utils/Enums";
import { FormControlKeys } from "../../../../../Components/FormControls";
import { JobViewModel, JobsVehicle } from "../../../../../Models/Jobs/EditJob";
import { useGetCanRelistJob } from "../Hooks/useGetCanRelistJob";
import { usePutReOfferJob } from "../Hooks/usePutReOfferJob";
import { Alert, ModalDialog, ModalDialogProps, useAlert } from "../../../../../Components";
import { SpanItem } from "../../../../../Components/UI";
import RelistJobModal from "./Modules/RelistJobModal";
import AbortJobModal from "./Modules/AbortJobModal";
import { usePutRelistJob } from "../Hooks/usePutRelistJob";
import { RouteConstants } from "../../../../../Routes";
import {
  useGetAdminUsersForDropdown,
  usePutUnlockJob,
  usePutUpdateAdminUserData,
  usePutUpdateJobStatus,
  usePostAdminAbortMotabilityJob,
} from "../Hooks";

const JobActions: React.FC<GeneralFormProps> = ({ job, showAlert, refetchJob }): JSX.Element => {
  const handleModalClose = () => {
    setModalContent({ ...modalContent, show: false });
  };

  const { alert, setAlert, handleAlertClose } = useAlert();

  const {
    refetch: getCanRelistJob,
    data: getCanRelistJobData,
    error: getCanRelistJobError,
  } = useGetCanRelistJob(job.Id);

  const { 
    mutate: putReOfferJob, 
    isLoading : postReofferJobIsLoading, 
    data : putReOfferJobData, 
    error : putReOfferJobError 
  } = usePutReOfferJob();

  const handleShowRelistJobModal = ( ) => {
    setModalContent({
      ...modalContent,
      show: true,
      header: "Relist Job",
      body: getRelistModalBody(),
    });
  };

  const handleShowAbortJobModal = ( ) => {
    setModalContent({
      ...modalContent,
      show: true,
      header: "Abort Job",
      body: getAbortJobModalBody(),
    });
  };

  const getRelistModalBody = () => {
    return <RelistJobModal 
      jobId={job.Id} 
      dFixedPriceMax_Original={job.DFixedPriceMax_Original}
      dFixedPriceMin_Original={job.DFixedPriceMin_Original}
      tFixedPriceMax_Original={job.TFixedPriceMax_Original}
      tFixedPriceMin_Original={job.TFixedPriceMin_Original}
      putRelistJob={putRelistJob} 
      closeModal={handleModalClose}
    />;
  };

  const {
    mutate: putRelistJob,
    error: relistJobError,
    data: relistJobSuccess,
  } = usePutRelistJob();

  const getAbortJobModalBody = () => {
    return <AbortJobModal 
      job={job}
      closeModal={handleModalClose}
      postAbortJob={postAdminAbortMotabilityJob}
    />;
  };
  
  const {
    mutate: postAdminAbortMotabilityJob,
    error: abortMotabilityJobError,
    data: abortMotabilityJobSuccess,
  } = usePostAdminAbortMotabilityJob();

  const [modalContent, setModalContent] = useState<ModalDialogProps>({
    header: "",
    body: "",
    show: false,
    closeCallback: handleModalClose,
  });

  const {
    mutate: putUpdateJobStatusMutate,
    data: putUpdateJobStatusResponse,
    error: putUpdateJobStatusError,
  } = usePutUpdateJobStatus();

  const {
    mutate: putUpdateAdminUserDataMutate,
    data: putUpdateAdminUserDataResponse,
    error: putUpdateAdminUserDataError,
  } = usePutUpdateAdminUserData();

  const {
    mutate: putUnlockJobMutate,
    data: putUnlockJobDataResponse,
    error: putUnlockJobDataError,
  } = usePutUnlockJob();


  useEffect(() => {
    if(getCanRelistJobError){
      showAlert(
        "Can Relist Job Error",
        getCanRelistJobError.Description
      );
    }
  }, [getCanRelistJobError]);

  useEffect(() => {
    if(abortMotabilityJobError){
      showAlert(
        "Abort Motability Job Error",
        abortMotabilityJobError.Error?.Description
      );
    }
  }, [abortMotabilityJobError]);

  useEffect(() => {
    if(abortMotabilityJobSuccess){
      setAlert({
        ...alert,
        show: true,
        header: "Abort Motability Job",
        body: `Job ${job.Id} has been successfully aborted`,
        closeCallback: () => {
          handleAlertClose();
          refetchJob();
          getCanRelistJob();
        }
      });
    }
  }, [abortMotabilityJobSuccess]);

  useEffect(() => {
    if(relistJobSuccess){
      setAlert({
        ...alert,
        show: true,
        header: "Relist Job",
        body: `Job relisted as job ID ${relistJobSuccess}`,
        closeCallback: () => {
          handleAlertClose();
          window.location.href = `${RouteConstants.ViewJobById}/${relistJobSuccess}`;
        }
      });
    }
  }, [relistJobSuccess]);

  useEffect(() => {
    if (putReOfferJobData) {
      showAlert('Job Reoffered', putReOfferJobData);
      refetchJob();
    } 
  }, [putReOfferJobData]);

  useEffect(() => {
    if(putUpdateJobStatusResponse){
      showAlert(
        'Job Status',
        "Job Status has been updated."
      );
    }
  }, [putUpdateJobStatusResponse]);

  const { 
    data: adminUsersList,
    error: getAdminUserError 
  } = useGetAdminUsersForDropdown();

  useEffect(() => {
    if (putUpdateJobStatusError) {
      setAlert({
        ...alert,
        show: true,
        header: putUpdateJobStatusError?.Subject,
        body: putUpdateJobStatusError?.Description,
      });
    } else if (putUpdateAdminUserDataError) {
      setAlert({
        ...alert,
        show: true,
        header: putUpdateAdminUserDataError?.Subject,
        body: putUpdateAdminUserDataError?.Description,
      });
    } else if (putUnlockJobDataError) {
      setAlert({
        ...alert,
        show: true,
        header: "Error Unlocking Job",
        body: putUnlockJobDataError?.Description,
      });
    } else if (putReOfferJobError) {
      setAlert({
        ...alert,
        show: true,
        header: putReOfferJobError?.Subject,
        body: putReOfferJobError?.Description,
      });
    } else if (relistJobError) {
      setAlert({
        ...alert,
        show: true,
        header: relistJobError?.Subject,
        body: relistJobError?.Description,
      });
    } else if (getAdminUserError) {
      setAlert({
        ...alert,
        show: true,
        header: getAdminUserError?.Subject,
        body: getAdminUserError?.Description,
      });
    }
  
  }, [putUpdateJobStatusError,
      putUpdateAdminUserDataError,
      putUnlockJobDataError,
      putReOfferJobError,
      relistJobError,
      getAdminUserError]);

  return(
    <>
    <ModalDialog {...modalContent}></ModalDialog>
    <Alert {...alert} />
      <InlineBlockColMd6AndAlignTopSection
        style={{
          borderRadius: '10px',
          border: '1px solid #ced4da',
          marginLeft:'100px',
          padding: '20px',
          width: '25%'}}>

        <h2>Actions</h2>

        <div style={{marginBottom: '25px'}}>
          { getCanRelistJobData &&
            <FormikControl
              control={FormControlKeys.Button}
              label="Relist Job"
              variant='outline-secondary'
              onClick={() => {
                handleShowRelistJobModal();
              }}
            />
          }
        </div>
        
        {(job.JobStatus === JobStatus.PendingQuotes || job.JobStatus === JobStatus.BookedJob) && (
          <div style={{marginBottom: '25px'}}>
            {postReofferJobIsLoading && <SpanItem>Reoffering job...</SpanItem>}
            {putReOfferJobData && <SpanItem>Job reoffered!</SpanItem>}
            {!postReofferJobIsLoading && !putReOfferJobData &&
              <FormikControl
                control={FormControlKeys.Button}
                label="Reoffer job"
                onClick={() => {
                  putReOfferJob(job.Id);
                }}
              />
            }
          </div>
        )}

        {(job.JobStatus !== JobStatus.Aborted) && (
          <div style={{marginBottom: '25px'}}>
            <FormikControl
              control={FormControlKeys.Button}
              label="Motability Abort Job"
              variant="danger"
              onClick={() => {
                handleShowAbortJobModal();
              }}
            />
          </div>
        )}
      </InlineBlockColMd6AndAlignTopSection>
    </>
  )
}

type GeneralFormProps = {
  job: JobViewModel;
  showAlert: Function;
  refetchJob: Function;
};

export default JobActions;