import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateAdminUserDataModel, UpdateAdminUserDataResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateAdminUserData = (
  option?: any
): UseMutationResult<
  UpdateAdminUserDataResponseModel,
  any,
  UpdateAdminUserDataModel
> => {
  const usePutUpdateAdminUserData = (payload: UpdateAdminUserDataModel) => {
    return agent.jobs.putUpdateAdminUserData(payload);
  };
  return useMutation(usePutUpdateAdminUserData);
};
