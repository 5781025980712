import { ResultsViewModel } from "../../../Types"

export interface CacheServiceResponseModel extends ResultsViewModel<CacheServiceModel> { }

export type CacheServiceModel = {
    Id: number;
    Key: string;
}

export const CacheServiceModelKeys = {
    Id: "Id",
    Key: "Key",
}