import { useEffect, useMemo, useState } from 'react';
import { JobViewModel } from '../../../../Models/Jobs/EditJob';
import { JobStatus, JobType, ServiceType } from '../../../../Utils/Enums';

export function useJobStatus(values: JobViewModel): {
     jobCompleted: boolean;
     jobBooked: boolean;
     jobCompletedOrBooked: boolean;
     jobNotCompletedOrBooked: boolean;
     jobNotCompleted: boolean;
     isDrivenOrBoth: boolean;
     isGuaranteedPriceAndNotBooked: boolean;
     isGuaranteedPrice: boolean;
     isFixedPriceOrBothOrGuaranteedPrice: boolean;
     isTransportedOrBoth: boolean;
} {
     const [jobCompleted, setJobCompleted] = useState(false);
     const [jobBooked, setJobBooked] = useState(false);
     const [jobCompletedOrBooked, setJobCompletedOrBooked] = useState(false);
     const [jobNotCompletedOrBooked, setJobNotCompletedOrBooked] = useState(false);
     const [jobNotCompleted, setJobNotCompleted] = useState(false);

     useEffect(() => {
          setJobCompleted(values.JobStatus === JobStatus.CompletedJob);
          setJobBooked(values.JobStatus === JobStatus.BookedJob);
          setJobCompletedOrBooked(
               values.JobStatus === JobStatus.CompletedJob ||
                    values.JobStatus === JobStatus.BookedJob
          );
          setJobNotCompletedOrBooked(
               values.JobStatus !== JobStatus.CompletedJob &&
                    values.JobStatus !== JobStatus.BookedJob
          );
          setJobNotCompleted(values.JobStatus !== JobStatus.CompletedJob);
     }, [values.JobStatus]);

     const isDrivenOrBoth = useMemo(
          () =>
               values.ServiceType === ServiceType.Driven || values.ServiceType === ServiceType.Both,
          [values.ServiceType]
     );

     const isGuaranteedPriceAndNotBooked = useMemo(
          () =>
               values.JobType === JobType.GuaranteedPrice &&
               values.JobStatus !== JobStatus.BookedJob,
          [values.JobType, values.JobStatus]
     );

     const isGuaranteedPrice = useMemo(
          () =>
               values.JobType === JobType.GuaranteedPrice,
          [values.JobType]
     );

     const isFixedPriceOrBothOrGuaranteedPrice = useMemo(
          () =>
               values.JobType === JobType.FixedPrice ||
               values.JobType === JobType.Both ||
               values.JobType === JobType.GuaranteedPrice,
          [values.JobType]
     );

     const isTransportedOrBoth = useMemo(
          () =>
               values.ServiceType === ServiceType.Transported ||
               values.ServiceType === ServiceType.Both,
          [values.ServiceType]
     );

     return useMemo(
          () => ({
               jobCompleted,
               jobBooked,
               jobCompletedOrBooked,
               jobNotCompletedOrBooked,
               jobNotCompleted,
               isDrivenOrBoth,
               isGuaranteedPriceAndNotBooked,
               isGuaranteedPrice,
               isFixedPriceOrBothOrGuaranteedPrice,
               isTransportedOrBoth,
          }),
          [
               jobCompleted,
               jobBooked,
               jobCompletedOrBooked,
               jobNotCompletedOrBooked,
               jobNotCompleted,
               isDrivenOrBoth,
               isGuaranteedPriceAndNotBooked,
               isGuaranteedPrice,
               isFixedPriceOrBothOrGuaranteedPrice,
               isTransportedOrBoth,
          ]
     );
}
