import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { JobActivityHistoryItemKeys } from '../../../../../Models/Jobs/JobActivityHistory';
import { JobActivityTypeOptions } from '../Constants';

const {
  renderCellWithTooltip,
  renderDateWithTimeCell,
  renderEditMemberByMemberIdCell,
  renderViewJobByJobIdCell,
} = CellRenderer;

export const columns: GridColDef[] = [
  {
    field: JobActivityHistoryItemKeys.Id,
    headerName: 'Id',
    description: 'Id',
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    flex: 2,
  },
  {
    field: JobActivityHistoryItemKeys.AdminUserName,
    headerName: 'Admin',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => {

      const adminId =
        (params.row.AdminUserName as number) || 0;
      return renderEditMemberByMemberIdCell(
        JobActivityHistoryItemKeys.AdminUserID,
        adminId,
        params.value
      );
    },
  },
  {
    field: JobActivityHistoryItemKeys.MemberUserName,
    headerName: 'Member',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => {
      const memberID =
        (params.row.MemberID as number) || 0;
      return renderEditMemberByMemberIdCell(
        JobActivityHistoryItemKeys.MemberUserName,
        memberID,
        params.value
      );
    },
  },
  {
    field: JobActivityHistoryItemKeys.JobID,
    headerName: 'Job ID',
    renderCell: (params: GridRenderCellParams) =>
      renderViewJobByJobIdCell(params, JobActivityHistoryItemKeys.JobID, params.row.JobID),
    flex: 4,
  },
  {
    field: JobActivityHistoryItemKeys.ActivityDate,
    headerName: 'Date /Time',
    flex: 4,
    renderCell: (params: GridRenderCellParams) =>
      renderDateWithTimeCell(params),
  },
  {
    field: JobActivityHistoryItemKeys.ActivityType,
    headerName: 'Type',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => {
      const jobActivityTypeLabel = JobActivityTypeOptions.filter(
        (x) => x.value === params.value
      )[0]?.label;
      return (
        <Tooltip title={jobActivityTypeLabel || ''}>
          <span>{jobActivityTypeLabel}</span>
        </Tooltip>
      );
    },
  },
  {
    field: JobActivityHistoryItemKeys.ActivityNotes,
    headerName: 'Notes / Additional Info',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    sortable: false,
  },
];
