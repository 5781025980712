import React, { useEffect, useState } from 'react';
import {
  MainContentInnerContainer,
  MainContentOuterContainer,
} from '../../../../Components/Formik/StyledComponents';
import { Loading, renderHeader } from '../../../../Components';
import {
  MemberModelKeys,
  MembersRequestParam,
  MembersFilter,
} from '../../../../Models/Members';
import { newMembersActionCreators } from '../../../../Store/Members/NewMembers/Action';
import { ApplicationState } from '../../../../Store/Types';
import { Pager, Sorter } from '../../../../Types';
import { Page } from '../../../../Utils';
import NewMembersGridView from './Grid';
import './index.css';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';

const NewMembers: React.FC<any> = (): JSX.Element => {
  var date = new Date();
  date.setDate(date.getDate() - 15);

  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter] = useState<MembersFilter>({ SignupFromDate: date });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sorter, setSorter] = useState<Sorter>({
    Column: MemberModelKeys.SignupDate,
    Ascending: false,
  });

  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch()

  const newMembersState = useAppSelector(
    (state: ApplicationState) => state.newMembersState!
  );
  const { newMembers, isApiLoading } = newMembersState;

  /** Methods */
  const fetchNewMembers = () => {
    const params: MembersRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: sorter,
    };
    dispatch(newMembersActionCreators.getNewMembers(params));
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sorter: Sorter) => {
    setPager({ ...pager, CurrentPage: 1 });
    setSorter(sorter);
  };

  /** useEffects */
  useEffect(() => {
      fetchNewMembers();
  }, [pager, filter, sorter]); 

  useEffect(() => {
    setIsLoading(isApiLoading);
  }, [isApiLoading]);

  return (
    <MainContentOuterContainer>
      {renderHeader('New Members Added in Last 15 Days')}
      <Loading loading={isLoading} />
      <MainContentInnerContainer>
        {(!isLoading && pager.ItemsPerPage > 0) && (
          <NewMembersGridView
            data={newMembers}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </MainContentInnerContainer>
    </MainContentOuterContainer>
  );
};

export default NewMembers;
