import { FailIndicatorIcon } from '../../../../../../../Assets/Images';
import { TradePlate, TransporterPlate } from '../../../../../../../Models/Members/AddMember/TradePlates';
import { cellImageStyle } from '../../../../../Billing/AddBill';
import { DeleteButton, StyledCol } from './TradePlates.styles';

interface DeletePlateProps {
     deletePlate: Function;
     plate: TradePlate | TransporterPlate;
}

const DeletePlate: React.FC<DeletePlateProps> = ({ deletePlate, plate }) => {
     
     /** Event Handler */
     const handleDelete = () => {
          deletePlate(plate);
     };

     return (
          <StyledCol sm='1'>
               <DeleteButton onClick={handleDelete}><img alt='' style={cellImageStyle} src={FailIndicatorIcon} /></DeleteButton>
          </StyledCol>
     );
};

export default DeletePlate;
