import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../Components/Formik/StyledComponents';

export const ForgottenPassword = (model: any) => {
  const {
    Username,
    EmailAddress,
    NewPassword,
    ConfirmPassword,
    ChangePassword,
  } = model;

  const formikInitialValues = {};

  const onSubmit = (values: any) => {
    ChangePassword(values);
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });

  // Request.QueryString["token"]
  const validTokenExists = true;

  const userDetailForm = (
    <FormikProvider value={formik}>
      <FormContainer>
        <div className="row">
          <div className="col-xs-12 col-lg-12 text-center">
            Please fill in the form below and we'll send you a new password:
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <FormikControl placeholder="Username" name={'Username'} />
          </div>
          <div className="col-xs-12 col-lg-6">
            <FormikControl placeholder="Email Address" name={'EmailAddress'} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-lg-12">
            <FormikControl type="submit" label="Reset Password" />
          </div>
        </div>
      </FormContainer>
    </FormikProvider>
  );

  const passwordForm = (
    <FormikProvider value={formik}>
      <FormContainer>
        <div className="row">
          <div className="col-xs-12 col-lg-12 text-center">
            Please fill in the form below to reset your password:
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <FormikControl placeholder="New Password" name={'NewPassword'} />
          </div>
          <div className="col-xs-12 col-lg-6">
            <FormikControl
              placeholder="Confirm Password"
              name={'ConfirmPassword'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-lg-12">
            <input
              type="submit"
              value="Reset Password"
              className="btn btn-primary"
              data-bind="click: $root.ChangePassword"
            />
          </div>
        </div>
      </FormContainer>
    </FormikProvider>
  );

  return (
    <div className="forgotten-details-page container-fluid">
      <div className="row">
        <div className="container">
          <div className="row hero-row">
            <div className="col-lg-12">
              <h1>FORGOTTEN PASSWORD</h1>
              <h2>
                HELPLINE{' '}
                <span className="helpline-phone-number">0208 551 1060</span>
              </h2>
            </div>
          </div>
          <div className="row reset-form-row">
            <div className="col-xs-12 col-lg-12">
              {validTokenExists && userDetailForm}
              {!validTokenExists && passwordForm}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
