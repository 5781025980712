import axios from 'axios';
import { RequestingMemberFeeModel } from '../../../Models/Prices/DealerMargin';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
     GetRequestingMemberFeeFixedPrices_URL,
     GetRequestingMemberFeeQuotePrices_URL,
     PostAddOrUpdateRequestingMemberFeeFixedRanges_URL,
     PostAddOrUpdateRequestingMemberFeeQuoteRanges_URL,
     DeleteRequestingMemberFixedRanges_URL,
     DeleteRequestingMemberQuoteRanges_URL,
} = ApiURLConstants;

export const dealerMargin = {
     getDealerMarginFixedPrices: (
          requestingMemberFeeType: number,
          parentKeyID: number
     ): Promise<any> => {
          return axios
               .get(
                    `${GetRequestingMemberFeeFixedPrices_URL}?requestingMemberFeeType=${requestingMemberFeeType}&parentKeyID=${parentKeyID}`
               )
               .then(responseBody);
     },

     getDealerMarginQuotedPrices: (
          requestingMemberFeeType: number,
          parentKeyID: number
     ): Promise<any> => {
          return axios
               .get(
                    `${GetRequestingMemberFeeQuotePrices_URL}?requestingMemberFeeType=${requestingMemberFeeType}&parentKeyID=${parentKeyID}`
               )
               .then(responseBody);
     },

     postDealerMarginFixedPrices: (params: RequestingMemberFeeModel): Promise<any> => {
          return axios
               .post(`${PostAddOrUpdateRequestingMemberFeeFixedRanges_URL}`, params, jsonConfig)
               .then(responseBody);
     },

     postDealerMarginQuotesPrices: (params: RequestingMemberFeeModel): Promise<any> => {
          return axios
               .post(`${PostAddOrUpdateRequestingMemberFeeQuoteRanges_URL}`, params, jsonConfig)
               .then(responseBody);
     },

     deleteDealerMarginFixedPrices: (id: number): Promise<any> => {
          return axios
               .delete(`${DeleteRequestingMemberFixedRanges_URL}?id=${id}`)
               .then(responseBody);
     },

     deleteDealerMarginQuotePrices: (id: number): Promise<any> => {
          return axios
               .delete(`${DeleteRequestingMemberQuoteRanges_URL}?id=${id}`)
               .then(responseBody);
     },
};
