import { Formik, FormikHelpers } from 'formik';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { FormControlKeys } from '../../../../../../../Components/FormControls';
import FormikControl from '../../../../../../../Components/Formik/FormikControl';
import { TradePlate, TransporterPlate } from '../../../../../../../Models/Members/AddMember/TradePlates';
import {
     UpdateTradePlateRequest,
     UpdateTransporterPlateRequest,
} from '../../../../../../../Models/Members/AddMember/UpdateTradePlateRequest';
import {
     DeleteTradePlateRequest,
     DeleteTransporterPlateRequest,
} from '../../../../../../../Models/Members/EditMember/TradePlates/DeleteTradePlates';
import { useDeleteTradePlate } from '../Hooks/useDeleteTradePlate';
import { useDeleteTransporterPlate } from '../Hooks/useDeleteTransporterPlate';
import { usePutUpdateTradePlates } from '../Hooks/usePutUpdateTradePlates';
import { usePutUpdateTransporterPlates } from '../Hooks/usePutUpdateTransporterPlates';
import DeletePlate from './DeletePlate';
import { CustomCol, FormError, FormWrapper, MinWidthDiv, RowWrapper, StyledTradePlateDiv, TradePlateStyledCol } from './TradePlates.styles';
import { Alert, useAlert } from '../../../../../../../Components';

interface PlateProps {
     plate: TradePlate | TransporterPlate;
     isEditing: boolean;
     onClick: (plate: TradePlate | TransporterPlate) => void;
     refreshMember?: Function;
     setIsEditing: Function;
     memberId: number;
}

export const Plate: React.FC<PlateProps> = ({ plate, isEditing, setIsEditing, onClick, refreshMember, memberId }) => {
     
     const { alert, handleDisplayAlert, setAlert } = useAlert();
     
     const {
          mutate: putUpdateTradePlates,
          data: putUpdateTradePlatesData,
          error: putUpdateTradePlatesError,
          isLoading: isPutUpdateTradePlatesLoading,
          isSuccess: isPutUpdateTradePlatesSuccess,
     } = usePutUpdateTradePlates();

     const {
          mutate: putUpdateTransporterPlates,
          data: putUpdateTransporterPlatesData,
          error: putUpdateTransporterPlatesError,
          isSuccess: isPutUpdateTransporterPlatesSuccess,
     } = usePutUpdateTransporterPlates();

     const {
          mutate: deleteTradePlate,
          error: deleteTradePlateError,
          isSuccess: isDeleteTradePlateSuccess,
     } = useDeleteTradePlate();

     const {
          mutate: deleteTransporterPlate,
          data: deleteTransporterPlateData,
          error: deleteTransporterPlateError,
          isLoading: isDeleteTransporterPlateLoading,
          isSuccess: isDeleteTransporterPlateSuccess,
     } = useDeleteTransporterPlate();

     useEffect(() => {
          if (isPutUpdateTradePlatesSuccess || isPutUpdateTransporterPlatesSuccess) {
               setIsEditing(false);
          }
     }, [isPutUpdateTradePlatesSuccess, isPutUpdateTransporterPlatesSuccess]);

     useEffect(() => {
          if(putUpdateTradePlatesError 
               || putUpdateTransporterPlatesError 
               || deleteTradePlateError 
               || deleteTransporterPlateError) {
               handleDisplayAlert(
                         true, 
                    'Error When Updating Plate!', 
                    `${putUpdateTradePlatesError?.Description 
                         || putUpdateTransporterPlatesError?.Description 
                         || deleteTradePlateError?.Description 
                         || deleteTransporterPlateError?.Description}`
               );
          }
     }, [putUpdateTradePlatesError, putUpdateTransporterPlatesError, deleteTradePlateError, deleteTransporterPlateError]);

     useEffect(() => {
          if (isDeleteTradePlateSuccess || isDeleteTransporterPlateSuccess || isPutUpdateTradePlatesSuccess || isPutUpdateTransporterPlatesSuccess) {
               if(refreshMember) refreshMember();
          }
     }, [isDeleteTradePlateSuccess, isDeleteTransporterPlateSuccess, isPutUpdateTradePlatesSuccess, isPutUpdateTransporterPlatesSuccess]);

     const handleDelete = (plate: TradePlate | TransporterPlate) => {
          if ('TradePlate' in plate) {
               const payload = {
                    TradePlateId: plate.Id,
                    MemberId: memberId,
               } as DeleteTradePlateRequest;
               deleteTradePlate(payload);
          } else {
               const payload = {
                    TransporterPlateId: plate.Id,
                    MemberId: memberId,
               } as DeleteTransporterPlateRequest;
               deleteTransporterPlate(payload);
          }
     };

     return (
          <MinWidthDiv>
               <Alert {...alert} />
               {isEditing ? (
                    <Formik
                         initialValues={{ plateNumber: 'TradePlate' in plate ? plate.TradePlate : plate.TransporterPlate }}
                         onSubmit={function (
                              values: { plateNumber: string },
                              formikHelpers: FormikHelpers<{ plateNumber: string }>
                         ): void | Promise<any> {
                              throw new Error('Function not implemented.');
                         }}
                    >
                         {(formik) => (
                              <FormWrapper>
                                   {(putUpdateTransporterPlatesError || putUpdateTransporterPlatesError) && (
                                        <FormError>Error when updating plate!</FormError>
                                   )}
                                   <RowWrapper>
                                        <TradePlateStyledCol>
                                             <FormikControl type='text' name='plateNumber'></FormikControl>
                                        </TradePlateStyledCol>
                                        <Col sm='3'>
                                             <FormikControl
                                                  control={FormControlKeys.Button}
                                                  name='Update'
                                                  label='Update'
                                                  onClick={() => {
                                                       if ('TradePlate' in plate) {
                                                            var payload = {
                                                                 TradePlateId: plate.Id,
                                                                 TradePlate: formik.values.plateNumber,
                                                                 MemberId: plate.MemberId,
                                                            } as UpdateTradePlateRequest;
                                                            putUpdateTradePlates(payload);
                                                       } else {
                                                            var transporterPayload = {
                                                                 TransporterPlateId: plate.Id,
                                                                 TransporterPlate: formik.values.plateNumber,
                                                                 MemberId: plate.MemberId,
                                                            } as UpdateTransporterPlateRequest;
                                                            putUpdateTransporterPlates(transporterPayload);
                                                       }
                                                  }}
                                             ></FormikControl>
                                        </Col>
                                   </RowWrapper>
                              </FormWrapper>
                         )}
                    </Formik>
               ) : 'TradePlate' in plate ? (
                    <RowWrapper>
                         <Col sm='1'>
                              <DeletePlate deletePlate={handleDelete} plate={plate} />
                         </Col>
                         <CustomCol onClick={() => onClick(plate)}>
                              <StyledTradePlateDiv>{plate.TradePlate}</StyledTradePlateDiv>
                         </CustomCol>
                    </RowWrapper>
               ) : (
                    <RowWrapper>
                         <Col sm='1'>
                              <DeletePlate deletePlate={handleDelete} plate={plate} />
                         </Col>
                         <CustomCol onClick={() => onClick(plate)}>
                              <StyledTradePlateDiv>{plate.TransporterPlate}</StyledTradePlateDiv>
                         </CustomCol>
                    </RowWrapper>
               )}
          </MinWidthDiv>
     );
};