// import { setAccessToken } from './../../utils/storage';
import { LoginApiParam, RefreshApiParam, RefreshApiResponse, UserSessionActionTypes } from './Types';
import { AppThunkAction, RootState } from "../../App";
import * as Storage from "../../Utils/Storage";
import agent from '../../Axios/Agent';
import { UserSession } from '../../Models/Session/UserSession';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'react';

type DispatchType = ThunkDispatch<RootState, unknown, UserSessionActionTypes> & 
                    Dispatch<UserSessionActionTypes>


export const authActionCreators = {
    /**
     *  function to refresh current user session
     * @param  {RefreshApiParam} params
     */
    refreshSession: (params: RefreshApiParam): AppThunkAction<UserSessionActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: "REQUEST_REFRESH_SESSION"
            });

            agent.session
                .refreshSession(params)
                .then((response) => response as RefreshApiResponse)
                .then((data) => {
                    if (data.Access_token) {
                        Storage.setAccessToken(data.Access_token)
                    }
                    if (data.Refresh_token) {
                        Storage.setRefreshToken(data.Refresh_token)
                    }
                    dispatch({
                        type: "RECEIVE_REFRESH_SESSION",
                        payload: data,
                    })
                }).catch((error) => {
                    dispatch({
                        type: "RECEIVE_REFRESH_SESSION_ERROR",
                        payload: error,
                    });
                    
                    (dispatch as DispatchType)(authActionCreators.logout());
                    console.log("RECEIVE_REFRESH_SESSION_ERROR", error);
                });
        }
    },

    /**
     * function to login into app and create a sesion
     * @param  {LoginApiParam} params
     */
    login: (params: LoginApiParam): AppThunkAction<UserSessionActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "REQUEST_LOGIN" });;
            agent.session
                .login(params)
                .then(response => {
                    Storage.setDisplayName(response.DisplayName);
                    return response;
                })
                .then(response => response as UserSession)
                .then(data => {
                    Storage.setUsername(params.Username)
                    if (data.Access_token) {
                        Storage.setAccessToken(data.Access_token)
                        Storage.setRefreshToken(data.Refresh_token)
                    }
                    dispatch({ type: "RECEIVE_LOGIN", payload: data })
                }).catch(error => {
                    dispatch({ type: "RECEIVE_LOGIN_ERROR", payload: { description: "Invalid username or password!" } })
                })
        }
    },

    /**
     * function to logout from app
     * remove access token and refresh token from local storage
     * @param  {} getState
     */
    logout: (): AppThunkAction<UserSessionActionTypes> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: "REQUEST_LOGOUT_SESSION",
            });
            agent.session
                .logout()
                .then((response) => response as UserSession)
                .then(async (data) => {
                    await Storage.removeRefreshToken();
                    await Storage.removeAccessToken();
                    dispatch({
                        type: "RECEIVE_LOGOUT_SESSION",
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: "RECEIVE_LOGOUT_ERROR",
                        payload: error,
                    });
                });
        }
    }
}

export const refreshSessionApiTrigger = (params: RefreshApiParam): Promise<any> => {
    return new Promise(async function (resolve, reject) {
        const refreshtoken = await Storage.getRefreshToken();
        if (refreshtoken) {
            agent.session
                .refreshSession(params)
                .then((response) => response as UserSession)
                .then(async (data) => {
                    if (data.Access_token) {
                        await Storage.setAccessToken(data.Access_token);
                    }
                    if (data.Refresh_token) {
                        await Storage.setRefreshToken(data.Refresh_token);
                    }
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        }
    });
};