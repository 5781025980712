import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { ConfigObjectModel } from '../../../../../../../Models/Members/EditMember/ConfigObjects/AddConfigObjectModel';

export const usePostConfigObject = (options?: any): UseMutationResult<any, any, ConfigObjectModel> => {
  const postConfigObject = (payload: ConfigObjectModel) => {
    return agent.members.postAddConfigObject(payload);
  };

  return useMutation(postConfigObject);
};
