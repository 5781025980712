/** Import Inbuilt libraries */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import googleApi, { getLatLngForPostCode } from '../../../../Axios/GoogleApi';
import MovexResponseError from '../../../../Axios/MovexResponseError';
import {
  Alert,
  AlertProps,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  ShowPleaseWait,
  useAlert,
} from '../../../../Components';
import {
  FormControlKeys,
  OptionType,
} from '../../../../Components/FormControls/Types';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
  FieldError,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormContainer,
} from '../../../../Components/Formik/StyledComponents';
import { StreetAddressResponse } from '../../../../Models/GoogleAPI/DistanceMatrix';
import {
  GroupResponseModel,
  GroupSavedAddressDetails,
} from '../../../../Models/Groups';
import {
  checkIsPhoneAtleastEightDigits,
  ConfirmDialogContent,
  getPostCodeLookupAddress,
  isMovexErrorResponse,
} from '../../../../Utils';
import {
  useAddOrUpdateGroupSavedDetails,
  useDeleteGroupSavedDetails,
} from './Hooks';
import './index.css';
import { GroupSavedDetailKeys } from './Types';

const GroupSavedAddress: React.FC<GroupSavedAddressProps> = (props) => {
  const { groupID, groupData } = props;
  const initialGroupSavedDataRow = {
    GroupsSavedDetailsID: 0,
    GroupID: groupID,
    PostCode: '',
  };
  const postCodeErrorMessage =
    'This postcode is not valid, please try again or enter your address manually';

  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  /** useStates */
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [postCodeLookup, setPostCodeLookup] = useState<OptionType[]>([]);
  const [streetAddress, setStreetAddress] = useState<StreetAddressResponse>();
  const [isPostCodeLookupLoading, setIsPostCodeLookupLoading] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
  const [isPostCodeValid, setIsPostCodeValid] = useState<boolean>(false);
  const [showLookup, setShowLookup] = useState<boolean>(false);
  const [groupSavedDataRow, setGroupSavedDataRow] =
    useState<GroupSavedAddressDetails>(initialGroupSavedDataRow);
  const [groupSavedData, setGroupSavedData] = useState<
    GroupSavedAddressDetails[]
  >([]);
  const [editSavedDataID, setEditSavedDataID] = useState<number>(0);
  const [editSavedDetails, setEditSavedDetails] = useState<boolean>(false);
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });

  /** Custom Hooks */
  const { alert, setAlert, handleAlertClose } = useAlert();

  /** Methods */
  const handleAddNewGroupSavedDetails = () => {
    setIsPostCodeValid(true);
    setShowLookup(false);
    setEditSavedDetails(false);
    setGroupSavedDataRow(initialGroupSavedDataRow);
    resetForm();
    setPostCodeLookup([]);
    setGroupSavedData([
      ...groupSavedData,
      {
        GroupID: groupID,
        GroupsSavedDetailsID: 0,
        PostCode: '',
        Address: '',
        Company: '',
        Town: '',
        Contact: '',
      },
    ]);
  };

  const handleEditGroupSavedDetails = (data: GroupSavedAddressDetails) => {
    setGroupSavedDataRow(data);
    setShowLookup(false);
    setIsPostCodeValid(true);
    setPostCodeLookup([]);
    setEditSavedDetails(true);
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.EditGroupSavedAddress.Title,
      body: ConfirmDialogContent.EditGroupSavedAddress.Content,
      show: true,
      confirmCallback: () => {
        setEditSavedDataID(data.GroupsSavedDetailsID);
        onConfirmDialogClose();
      },
    });
  };

  const onCancelGroupSavedDetail = (id: number) => {
    if (id === 0) {
      const data = [...groupSavedData];
      data.pop();
      setGroupSavedData([...data]);
    } else {
      setEditSavedDataID(-1);
    }
  };

  const onDeleteSavedData = (id: number) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeleteGroupSavedAddress.Title,
      body: ConfirmDialogContent.DeleteGroupSavedAddress.Content,
      show: true,
      confirmCallback: () => {
        deleteSavedAddress(id);
        onConfirmDialogClose();
      },
    });
  };

  const onGroupSavedDetailsSubmit = (data: GroupSavedAddressDetails) => {
    if (groupID === 0) {
      setAlert({
        ...alert,
        show: true,
        header: 'Cannot add group saved details without a group added!',
      });
    } else {
      if (formikSavedAddress.PostCode !== '') {
        formikSavedAddress.GroupsSavedDetailsID = data.GroupsSavedDetailsID;
        addSavedAddress({ ...data, ...formikSavedAddress });
        setEditSavedDataID(-1);
        const savedAddressRows = [...groupSavedData];
        savedAddressRows.forEach((row) => {
          if (row.GroupsSavedDetailsID === data.GroupsSavedDetailsID) {
            Object.assign(row, { ...data, ...formikSavedAddress });
          }
        });
        setGroupSavedData(savedAddressRows);
      }
    }
  };

  const addGroupSavedDataFormik = useFormik({
    initialValues: groupSavedDataRow,
    enableReinitialize: true,
    onSubmit: onGroupSavedDetailsSubmit,
  });

  const renderInput = (name: string, value: any, isEdit: boolean) => {
    if (!isEdit) {
      return <span>{value}</span>;
    }

    return (
      <FormikControl
        name={name}
        defaultValue={value || ''}
        onChange={(e: any) => {
          addGroupSavedDataFormik.setFieldValue(name, e.target.value);
        }}
      />
    );
  };

  const { values: formikSavedAddress, resetForm } = addGroupSavedDataFormik;

  const isValidSavedAddress = () => {
    return isPostCodeValid && isPhoneNumberValid;
  };

  const checkIsPhoneNumberValid = (phoneNumber: string) => {
    const isValid =
      phoneNumber === '' || checkIsPhoneAtleastEightDigits(phoneNumber);
    setIsPhoneNumberValid(isValid);
  };

  const doPostCodeLookup = (postCode?: string) => {
    if (postCode === null || postCode === undefined) {
      setIsPostCodeValid(false);
    } else {
      setIsPostCodeLookupLoading(true);
      getAddressByPostCode(postCode);
    }
  };

  const getAddressByPostCode = (postCode: string) => {
    googleApi.streetAddress
      .getFullAddressByPostCode(postCode)
      .then((response) => {
        if (response.Thoroughfares) {
          setStreetAddress(response);
          const finalAddressLookup = getPostCodeLookupAddress(response);
          const lookupData = [
            { label: 'Select', value: '' },
            ...finalAddressLookup,
          ];
          setPostCodeLookup(lookupData);
          setIsPostCodeValid(true);
        } else {
          setPostCodeLookup([]);
          setIsPostCodeValid(false);
        }
        setIsPostCodeLookupLoading(false);
      })
      .catch((e) => {
        setIsPostCodeLookupLoading(false);
        setPostCodeLookup([]);
        setIsPostCodeValid(false);
      });
  };

  const onPostCodeChange = (value: string, data: GroupSavedAddressDetails) => {
    doPostCodeLookup(value);
    if (value !== null) {
      getLatLngForPostCode(value).then((location) => {
        const { Lat, Lng } = location;
        data.Latitude = Lat;
        data.Longitude = Lng;
      });
    }
  };

  const renderLookupContent = () => {
    return (
      <div>
        <Loading loading={isPostCodeLookupLoading} />
        {!isPostCodeValid && !isPostCodeLookupLoading && (
          <FieldError>{postCodeErrorMessage}</FieldError>
        )}
        {postCodeLookup.length > 0 && (
          <FormikControl
            name="PostCodeLookup"
            control={FormControlKeys.Select}
            options={postCodeLookup}
            onChange={(e: any) => {
              onPostCodeLookupDropdownChange(e.target.value);
            }}
          />
        )}
      </div>
    );
  };

  const onPostCodeLookupDropdownChange = (selectedLookupIndex: number) => {
    if (streetAddress) {
      const { Town, Thoroughfares, Dependent_locality } = streetAddress;
      const t = Thoroughfares[0];
      const deliveryPoint = t.Delivery_points[selectedLookupIndex];
      const {
        Sub_building_name,
        Building_number,
        Building_name,
        Organisation_name,
      } = deliveryPoint;

      const address = `${Organisation_name ? Organisation_name + ', ' : ''}${
        Sub_building_name ? Sub_building_name + ', ' : ''
      }${Building_name ? Building_name + ', ' : ''}${Building_number} ${
        t.Thoroughfare_name ? t.Thoroughfare_name + ' ' : ''
      }${t.Thoroughfare_descriptor ? t.Thoroughfare_descriptor + ', ' : ''}${
        Dependent_locality ? Dependent_locality + ', ' : ''
      }${Town}`;

      addGroupSavedDataFormik.setFieldValue(
        GroupSavedDetailKeys.Company,
        Organisation_name
      );
      addGroupSavedDataFormik.setFieldValue(GroupSavedDetailKeys.Town, Town);
      addGroupSavedDataFormik.setFieldValue(
        GroupSavedDetailKeys.Address,
        address
      );
    }
  };

  const navigateToEditGroup = () => {
    handleAlertClose();
    window.location.reload();
  };

  /** Query Hooks */
  const {
    mutate: addSavedAddress,
    isLoading: isAddSavedAddressLoading,
    data: addSavedAddressResponse,
    error: addSavedAddressError,
  } = useAddOrUpdateGroupSavedDetails();

  const {
    mutate: deleteSavedAddress,
    isLoading: isDeleteSavedAddressLoading,
    data: deleteSavedAddressResponse,
    error: deleteSavedAddressError,
  } = useDeleteGroupSavedDetails();

  /** useEffects */
  useEffect(() => {
    if (!groupID) {
      setGroupSavedData([]);
    }
  }, [groupID]);

  useEffect(() => {
    if (groupData) {
      const { GroupSavedAddressDetails } = groupData;
      setGroupSavedData(GroupSavedAddressDetails);
    }
  }, [groupData]);

  useEffect(() => {
    if (deleteSavedAddressError) {
      if (isMovexErrorResponse(deleteSavedAddressError)) {
        const errorContent = MovexResponseError.getMovexErrorContent(deleteSavedAddressError);
        setAlert({
          ...alert,
          show: true,
          header: errorContent.header,
        });
      }
    }
  }, [deleteSavedAddressError]);

  useEffect(() => {
    if (deleteSavedAddressResponse) {
      setAlert({
        ...alert,
        show: true,
        body: deleteSavedAddressResponse.Data,
        closeCallback: navigateToEditGroup,
      });
    }
  }, [deleteSavedAddressResponse]);

  useEffect(() => {
    if (addSavedAddressError) {
      if (isMovexErrorResponse(addSavedAddressError)) {
        const errorContent = MovexResponseError.getMovexErrorContent(addSavedAddressError);
        setAlert({
          ...alert,
          show: true,
          header: errorContent.header,
          body: errorContent.body,
        });
      }
    }
  }, [addSavedAddressError]);

  useEffect(() => {
    if (addSavedAddressResponse) {
      setAlert({
        ...alert,
        show: true,
        body: addSavedAddressResponse,
        closeCallback: navigateToEditGroup,
      });
    }
  }, [addSavedAddressResponse]);

  useEffect(() => {
    setShowPleaseWait(isAddSavedAddressLoading || isDeleteSavedAddressLoading);
  }, [isAddSavedAddressLoading, isDeleteSavedAddressLoading]);

  return (
    <FormikProvider value={addGroupSavedDataFormik}>
      <FlexContainer>
        <FieldSetContainer>
          <FieldSetHeader>
            Group Saved Details
            <span title="Add New">
              <AddCircleOutlineIcon
                className="add-new-icon"
                onClick={handleAddNewGroupSavedDetails}
              />
            </span>
          </FieldSetHeader>
          <FormContainer
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Alert {...alert} />
            <ShowPleaseWait show={showPleaseWait} />
            <ConfirmDialog {...confirmDialogContent} />
            <table id='id_table_group_saved_address' className="group-saved-details table table-bordered table-condensed table-striped">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Groups Saved Details ID</td>
                  <td>Group ID</td>
                  <td>Contact</td>
                  <td>Phone</td>
                  <td>Company</td>
                  <td>Address</td>
                  <td>Town</td>
                  <td>PostCode</td>
                  <td style={{ minWidth: 60 }}>Action</td>
                </tr>
              </thead>
              <tbody>
                {groupSavedData.map(
                  (data: GroupSavedAddressDetails, index: number) => {
                    const {
                      Contact,
                      Phone,
                      Company,
                      Address,
                      Town,
                      PostCode,
                      GroupID,
                      GroupsSavedDetailsID,
                    } = data;
                    const isEdit =
                      GroupsSavedDetailsID === 0 ||
                      (editSavedDetails &&
                        editSavedDataID === GroupsSavedDetailsID);
                    const rowId = index + 1;
                    return (
                      <tr className={isEdit ? 'edit-row' : 'display-row'} key={rowId}>
                        <td>{rowId}</td>
                        <td>{GroupsSavedDetailsID}</td>
                        <td>{GroupID}</td>
                        <td>
                          {renderInput(
                            GroupSavedDetailKeys.Contact,
                            Contact,
                            isEdit
                          )}
                        </td>
                        <td>
                          {isEdit && (
                            <>
                              <FormikControl
                                name={GroupSavedDetailKeys.Phone}
                                defaultValue={Phone || ''}
                                onChange={(e: any) => {
                                  addGroupSavedDataFormik.setFieldValue(
                                    GroupSavedDetailKeys.Phone,
                                    e.target.value
                                  );
                                }}
                                onInput={(e: any) => {
                                  checkIsPhoneNumberValid(e.target.value);
                                }}
                              />
                              {!isPhoneNumberValid && (
                                <FieldError>
                                  Phone number must contain atleast 8 digits
                                </FieldError>
                              )}
                            </>
                          )}
                          {!isEdit && <span>{Phone}</span>}
                        </td>
                        <td>
                          {renderInput(
                            GroupSavedDetailKeys.Company,
                            Company,
                            isEdit
                          )}
                        </td>
                        <td>
                          {renderInput(
                            GroupSavedDetailKeys.Address,
                            Address,
                            isEdit
                          )}
                        </td>
                        <td>
                          {renderInput(GroupSavedDetailKeys.Town, Town, isEdit)}
                        </td>
                        <td>
                          {!isEdit && <span>{PostCode}</span>}
                          {isEdit && (
                            <FormikControl
                              name={GroupSavedDetailKeys.PostCode}
                              defaultValue={PostCode || ''}
                              onInput={(e: any) => {
                                onPostCodeChange(e.target.value, data);
                                addGroupSavedDataFormik.setFieldValue(
                                  GroupSavedDetailKeys.PostCode,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                          {isEdit && (
                            <div>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const postCode =
                                    addGroupSavedDataFormik.values.PostCode;
                                  onPostCodeChange(postCode, data);
                                  setShowLookup(true);
                                }}
                              >
                                LookUp
                              </a>
                              <div id="postcode-results">
                                {showLookup && renderLookupContent()}
                              </div>
                            </div>
                          )}
                        </td>
                        {isEdit && (
                          <td>
                            <span title="Save">
                              <DoneIcon
                                className="cursor-pointer"
                                opacity={isValidSavedAddress() ? 1 : 0.4}
                                style={{
                                  pointerEvents: isValidSavedAddress()
                                    ? 'visible'
                                    : 'none',
                                }}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  onGroupSavedDetailsSubmit(data);
                                }}
                              />
                            </span>
                            |
                            <span title="Cancel">
                              <CloseIcon
                                className="cursor-pointer"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  onCancelGroupSavedDetail(
                                    data.GroupsSavedDetailsID
                                  );
                                }}
                              ></CloseIcon>
                            </span>
                          </td>
                        )}
                        {!isEdit && (
                          <td>
                            <span title="Edit Group Saved Detail">
                              <EditIcon
                                className="cursor-pointer"
                                onClick={() => {
                                  handleEditGroupSavedDetails(data);
                                }}
                              />
                            </span>
                            |
                            <span title="Delete Group Saved Detail">
                              <DeleteIcon
                                className="cursor-pointer"
                                onClick={() => {
                                  onDeleteSavedData(data.GroupsSavedDetailsID);
                                }}
                              />
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
              {groupSavedData.length === 0 && (
                <tbody>
                  <tr>
                    <td colSpan={12}>No saved details!</td>
                  </tr>
                </tbody>
              )}
            </table>
          </FormContainer>
        </FieldSetContainer>
      </FlexContainer>
    </FormikProvider>
  );
};

export default GroupSavedAddress;

type GroupSavedAddressProps = {
  groupID: number;
  groupData?: GroupResponseModel;
};
