import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ApplicationState } from '../../Store/Types';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { CurrentSavedFilters, FilterType } from '../../Store/Grids/GridFilterCache/Types';
import { gridsActionCreators } from '../../Store/Grids/GridFilterCache/Action';
import { useRef } from 'react';
import { shallowEqual } from '../../Utils';

// Enum to define filter keys for various grids
export enum GridFilterKeys {
  GroupFilterKey = "groupsFilterKey",
  MemberFilterKey = "membersFilterKey",
  JobFilterKey = "jobsFilterKey",
  FeedbackFilterKey = "feedbackFilterKey",
}

interface useHandleGridFilterCacheProps {
  key: string;
  defaultFilter?: FilterType;
}

// Custom hook to manage grid filter cache
function useHandleGridFilterCache({ key, defaultFilter = {} }: useHandleGridFilterCacheProps) {

  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch();
  const savedFilters: CurrentSavedFilters = useAppSelector((state: ApplicationState) => state.gridsFilterState.savedFilters);
  
  const findFilterByKey = (filtersObj: CurrentSavedFilters): FilterType => {
    return key === filtersObj?.key ? filtersObj.filterData : undefined;
  };

  const previousSavedFilter = useRef<FilterType>();
  const computedFilter = findFilterByKey(savedFilters) || defaultFilter;

  if (!shallowEqual(previousSavedFilter.current, computedFilter)) {
    previousSavedFilter.current = computedFilter;
  }

  const saveFilterInCache = (filter: CurrentSavedFilters) => {
    dispatch(gridsActionCreators.setGridFiltering({
      key: filter.key,
      filterData: {...filter.filterData},
    }));
  };

  const clearCacheFilter = () => {
    dispatch(gridsActionCreators.setGridFiltering({ key, filterData: {} }));
  };

  return {
    savedFilter: previousSavedFilter.current,
    saveFilterInCache,
    clearCacheFilter,
  };
}

export default useHandleGridFilterCache;
