import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { JobsRequestParam, JobsResponseModel } from '../../../../../Models/Jobs/ViewJobs';

export const useGetJobs = (
     payload: JobsRequestParam,
     options?: any
): UseQueryResult<JobsResponseModel, any> => {
     const getJobs = () => {
          return agent.jobs.getJobs(payload);
     };

     return useQuery(['GetJobs', payload], getJobs, {
          ...options,
     });
};
