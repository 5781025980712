import { useState } from 'react';
import { TradePlate, TransporterPlate } from '../../../../../../../Models/Members/AddMember/TradePlates';
import { Plate } from './TradePlates';
import { PlateListWrapper } from './TradePlates.styles';

interface PlateListProps {
     plates: TradePlate[] | TransporterPlate[];
     refreshMember: Function;
     memberId: number;
}

export const PlatesList: React.FC<PlateListProps> = ({ plates, refreshMember, memberId }) => {
     
     /** State */
     const [editingPlate, setEditingPlate] = useState<number | null>(null);

     /** Event Handler */
     const handlePlateClick = (plate: TradePlate | TransporterPlate) => {
          setEditingPlate(plate.Id);
     };

     return (
          <PlateListWrapper>
               {plates?.map((plate) => (
                    <Plate
                         key={plate.Id}
                         plate={plate}
                         isEditing={plate.Id === editingPlate}
                         onClick={handlePlateClick}
                         refreshMember={refreshMember}
                         setIsEditing={setEditingPlate}
                         memberId={memberId}
                    />
               ))}
          </PlateListWrapper>
     );
};
