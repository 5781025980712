export const SearchesGridKeys = {
    Driven: 'Driven',
    Id: 'Id',
    MaxVehicles: 'MaxVehicles',
     MaxVehiclesList: 'MaxVehiclesList',
    MemberID: 'MemberID',
    MinVehicles: 'MinVehicles',
     MinVehiclesList: 'MinVehiclesList',
    PostCode: 'PostCode',
    PostLatitude: 'PostLatitude',
    PostLongitude: 'PostLongitude',
    SearchActive: 'SearchActive',
    SearchName: 'SearchName',
    Transported: 'Transported',
    WithinMiles: 'WithinMiles',
     UserName: 'UserName',
     Email: 'Email',
     ServiceType: 'ServiceType',
     Status: 'SavedSearchStatus',
}

export const SearchesAddEditFormKeys = {
    Driven: 'Driven',
    Id: 'ID',
    MaxVehicles: 'MaxVehicles',
    MemberID: 'MemberID',
    MinVehicles: 'MinVehicles',
    PostCode: 'PostCode',
    PostLatitude: 'PostLatitude',
    PostLongitude: 'PostLongitude',
    SearchActive: 'SearchActive',
    SearchName: 'SearchName',
    Transported: 'Transported',
    WithinMiles: 'WithinMiles'
}

export type SavedSearchIDParam ={
    searchID: string
}
