import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormControlKeys } from "../../../../../Components/FormControls";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import { DistanceFromCollectionOrDelivery } from "../../../Members/Constants";
import {
  FieldSetColSpan5AlignedSection,
  FieldSetColSpan5Section,
  FieldSetHeader,
  FlexContainer,
  HoverableLink,
} from "../../../../../Components/Formik";
import {
  DivInputWidth,
  SearchBox,
  LabelItem,
} from "../../../../../Components/UI";
import { useGetPreviousSupplyingMembersByRange } from "../Hooks";
import {
  JobViewModel,
  MemberForJobSection,
  PreviousSupplierSearchFilter,
} from "../../../../../Models/Jobs/EditJob";
import { PreviousSupplierOfRoute } from "../Types";
import { getFormattedDateWithYear } from "../../../../../Utils";
import { RouteConstants } from "../../../../../Routes";
import { Alert, useAlert } from "../../../../../Components";
import { SearchRadius } from "../../../../../Utils/Enums";

const Section4PreviousSupplierOfRoute: React.FC<GeneralFormProps> = ( props ): JSX.Element => {
  const { jobID, job } = props;

  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<JobViewModel>(job);
  const [showSupplyingMembersList, setShowSupplyingMembersList] =
    useState<boolean>(false);
  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
  const [previousSupplierSearchFilter, setPreviousSupplierSearchFilter] =
    useState<PreviousSupplierSearchFilter>({} as PreviousSupplierSearchFilter);

  /** React Query Hooks */
  const {
    data: previousSupplierOfRouteList,
    refetch: getPreviousSupplierOfRoute,
    error: previousSupplierOfRouteError,
  } = useGetPreviousSupplyingMembersByRange(previousSupplierSearchFilter);

  const { alert, setAlert } = useAlert();

  /** Methods */
  const formik = useFormik({
    initialValues: {
      ...formikInitialValues,
      DistanceFromCollection: SearchRadius.C_25_Miles,
      DistanceFromDelivery: SearchRadius.C_25_Miles,
      PreviouslyCompletedRoute: true
    },
    onSubmit: () => {
      fetchPreviousSuppliersofRoute();
    },
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const fetchPreviousSuppliersofRoute = () => {
    setIsWaitingData(true);
    const payloadFilter: PreviousSupplierSearchFilter = {
      JobID: jobID,
      CollectionRadius: values.DistanceFromCollection,
      DeliveryRadius: values.DistanceFromDelivery,
      PreviouslyCompletedRoute: values.PreviouslyCompletedRoute,
    };
    setPreviousSupplierSearchFilter(payloadFilter);
  };

  /** useEffect */
  useEffect(() => {
    if (Object.keys(previousSupplierSearchFilter).length > 0) {
      getPreviousSupplierOfRoute();
      setIsWaitingData(false);
      setShowSupplyingMembersList(true);
    }
  }, [previousSupplierSearchFilter]);

  useEffect(() => {
    setFormikInitialValues(job);
  }, [job]);

  useEffect(() => {
    if (previousSupplierOfRouteError) {
      setAlert({
        ...alert,
        show: true,
        header: previousSupplierOfRouteError?.Subject ?? 'Error when getting previous suppliers of route',
        body: previousSupplierOfRouteError?.Description,
      });
    }
  }, [previousSupplierOfRouteError]);

  const getLabel = () => {
    if (isWaitingData)
      return (
        <span>
          <i className="fa fa-spinner fa-spin"></i> Waiting...
        </span>
      );
    return "Find Previous Suppliers";
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <Alert {...alert} />
            <FieldSetHeader>Previous Suppliers of Route</FieldSetHeader>
            <div>
              <SearchBox className="search-box-colors">
                <FlexContainer>
                  <FieldSetColSpan5Section>
                    <LabelItem>Distance from Collection:</LabelItem>
                    <DivInputWidth>
                      <FormikControl
                        control={FormControlKeys.Select}
                        name={PreviousSupplierOfRoute.DistanceFromCollection}
                        options={DistanceFromCollectionOrDelivery}
                        value={values.DistanceFromCollection}
                      />
                    </DivInputWidth>
                  </FieldSetColSpan5Section>
                  <FieldSetColSpan5Section>
                    <LabelItem>Distance from Delivery:</LabelItem>
                    <DivInputWidth>
                      <FormikControl
                        control={FormControlKeys.Select}
                        name={PreviousSupplierOfRoute.DistanceFromDelivery}
                        options={DistanceFromCollectionOrDelivery}
                        value={values.DistanceFromDelivery}
                      />
                    </DivInputWidth>
                  </FieldSetColSpan5Section>
                  <FieldSetColSpan5AlignedSection>
                    <FormikControl
                      control={FormControlKeys.Button}
                      label={getLabel()}
                      type="submit"
                      disabled={isWaitingData}
                    />
                  </FieldSetColSpan5AlignedSection>
                </FlexContainer>
              </SearchBox>
            </div>

            {(showSupplyingMembersList || isWaitingData) && (
              <table id='id_table_previous_supplier_of_route' className="table table-bordered table-condensed table-striped">
                <thead>
                  <tr>
                    <td>UserName</td>
                    <td>Company</td>
                    <td>System Type</td>
                    <td>Last JobID</td>
                    <td>Last Request Date</td>
                    <td>Last Quote</td>
                    <td>Avg. Collection Distance</td>
                    <td>Avg. Delivery Distance</td>
                    <td>Lowest Quote</td>
                    <td>Highest Quote</td>
                    <td>Count</td>
                  </tr>
                </thead>
                <tbody>
                  {isWaitingData && <tr>Loading...</tr>}
                  {!isWaitingData &&
                    previousSupplierOfRouteList?.Suppliers &&
                    previousSupplierOfRouteList?.Suppliers.map(
                      (data: MemberForJobSection, index: number) => {
                        const {
                          ID,
                          UserName,
                          Company,
                          ServiceType,
                          LastJobID,
                          LastRequestDate,
                          LastQuote,
                          AvgCollectionDistance,
                          AvgDeliveryDistance,
                          LowestQuote,
                          HighestQuote,
                          JobCountForRoute,
                          Landline,
                          Contact_Name1,
                          Mobile,
                          NoOfDrivers,
                          NoOfTransporters,
                        } = data;
                        return (
                          <tr>
                            <td>
                              {" "}
                              <HoverableLink
                                to={`${RouteConstants.EditMember}/${ID}`}
                              >
                                {UserName}
                              </HoverableLink>
                            </td>
                            <td>
                              {Company}, {Contact_Name1}, {Landline}, {Mobile}
                            </td>
                            <td>
                              Driven {" (" + NoOfDrivers + ")"}
                              <br />
                              Transported {" (" + NoOfTransporters + ")"}
                            </td>
                            <td>
                              <HoverableLink
                                to={`${RouteConstants.ViewJobById}/${LastJobID}`}
                              >
                                {LastJobID}
                              </HoverableLink>
                            </td>
                            <td>
                              {LastRequestDate &&
                                getFormattedDateWithYear(LastRequestDate)}
                            </td>
                            <td>{LastQuote}</td>
                            <td>
                              {AvgCollectionDistance &&
                                Math.ceil(AvgCollectionDistance)}
                            </td>
                            <td>
                              {AvgDeliveryDistance &&
                                Math.ceil(AvgDeliveryDistance)}
                            </td>
                            <td>{LowestQuote}</td>
                            <td>{HighestQuote}</td>
                            <td>{JobCountForRoute}</td>
                          </tr>
                        );
                      }
                    )}
                  {!isWaitingData && previousSupplierOfRouteList?.Suppliers.length === 0  && "No data"}
                </tbody>
              </table>
            )}
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  jobID: number;
  job: JobViewModel;
};

export default Section4PreviousSupplierOfRoute;
