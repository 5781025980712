import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UnlockJobResponseModel } from "../../../../../Models/Jobs/EditJob";
import { IUnlockReason } from "../../../../../Models/Jobs/EditJob/IUnlockReason";

export const usePutUnlockJob = (
  options?: any
): UseMutationResult<UnlockJobResponseModel, any, IUnlockReason> => {
  const usePutUnlockJob = (payload: IUnlockReason) => {
    return agent.jobs.putUnlockJob(payload);
  };

  return useMutation(usePutUnlockJob);
};
