import React, { useState } from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import TextError from '../Formik/TextError';
import { isNonNull } from '../../Utils/Helper';

export const ClickToUpdateField = ({ ...props }) => {
  const { name, label, type, showRequired, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const { value, touched, error } = meta;
  const { setValue, setTouched } = helpers;
  const [readOnly, setReadOnly] = useState(true);
  const className =
    touched && error
      ? 'is-invalid'
      : touched && isNonNull(value)
      ? 'is-valid'
      : '';

  return (
    <div className="mb-3 form-group">
      <label htmlFor={name} className="form-label fw-600">
        {label}
      </label>
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <Field
        name={name}
        id={name}
        type={type}
        className={`form-control ${className}`}
        value={value}
        readOnly={readOnly}
        placeholder={'Click here to update'}
        onClick={(e: any) => {
          setReadOnly(false);
          setValue(e.target.value);
        }}
        onBlur={(e: any) => {
          setTouched(true);
          if (e.target.value === '') {
            setReadOnly(true);
          }
        }}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default ClickToUpdateField;
