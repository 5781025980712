import { DataGrid,GridSortModel } from '@mui/x-data-grid';
import React,{ useEffect } from 'react';
import {
     Alert,
ConfirmDialog,ControlledPagination as Pagination,
useAlert
} from '../../../../../Components';
import { useConfirmDialog } from '../../../../../Components/ConfirmDialog/useConfirmDialog';
import { GetPriceGroupResponseModel } from '../../../../../Models/PriceGroup';
import { GridProps,Sorter } from '../../../../../Types';
import { ConfirmDialogContent,getSortModelFromSorter } from '../../../../../Utils';
import { useDeletePriceGroup } from '../Hooks/useDeletePriceGroup';
import { priceGroupColumns } from './Columns';
import { GridContainer } from '../../../../../Components/UI';

const ViewPriceGroupGridView: React.FC<ViewPriceGroupGridProps> = ({
     data,
     onPageChange,
     onPageSizeChange,
     onSort,
     onUpdateGrid,
}) => {
     /** Functions */
     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const onPriceGroupDelete = (priceGroupId: number) => {
          openConfirmDialog(
               ConfirmDialogContent.DeletePriceGroup.Title,
               ConfirmDialogContent.DeletePriceGroup.Content,
               () => {
                    deletePriceGroup(priceGroupId);
                    closeConfirmDialog();
               }
          );
     };

     /** get the columns properties for the Grid */
     const columns = priceGroupColumns(onPriceGroupDelete);

     /** Queries */
     const { alert, setAlert } = useAlert();
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();

     const {
          mutate: deletePriceGroup,
          isLoading: isDeletePriceGroupLoading,
          data: deletePriceGroupResponse,
          error: deletePriceGroupError,
     } = useDeletePriceGroup();

     /** useEffects */
     useEffect(() => {
          if (deletePriceGroupError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: deletePriceGroupError.Subject,
                    body: deletePriceGroupError.Description,
               });
          }
     }, [deletePriceGroupError]);

     useEffect(() => {
          if (deletePriceGroupResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'PriceGroup deleted successfully',
                    body: 'PriceGroup deleted successfully.',
               });
          }
     }, [deletePriceGroupResponse]);

     useEffect(() => {
          onUpdateGrid();
     }, [deletePriceGroupResponse]);

     if (data) {
          const { Page, TableSorter } = data;
          const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = Page;

          const CustomGridFooter: React.FC = (): JSX.Element => {
               return (
                    <div>
                         <Pagination
                              itemsCount={TotalItems}
                              totalPages={TotalPages}
                              currentPage={CurrentPage}
                              pageSize={ItemsPerPage}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                         />
                    </div>
               );
          };

          return (
               <div>
                    {Page && (
                         <div className='view-searches-container'>
                              <ConfirmDialog {...confirmDialogProps} />
                              <Alert {...alert} />
                              <GridContainer id='id_grid_price_group'>
                                   <DataGrid
                                        sortingOrder={['desc', 'asc']}
                                        autoHeight
                                        getRowId={(row: any) => row.Id}
                                        rows={Items}
                                        rowHeight={41}
                                        disableColumnMenu={true}
                                        columns={priceGroupColumns(onPriceGroupDelete)}
                                        columnBuffer={columns.length + 1}
                                        components={{
                                             Toolbar: CustomGridFooter,
                                             Footer: CustomGridFooter,
                                        }}
                                        disableExtendRowFullWidth={true}
                                        sortingMode='server'
                                        sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                                        onSortModelChange={handleSort}
                                   />
                              </GridContainer>
                         </div>
                    )}
               </div>
          );
     }
     return null;
};

export default ViewPriceGroupGridView;

interface ViewPriceGroupGridProps extends GridProps<GetPriceGroupResponseModel> {
     onUpdateGrid: Function;
}
