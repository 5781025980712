import { GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Alert, Loading, renderHeader, useAlert } from "../../../../../Components";
import { AuthServicesModelKeys } from "../../../../../Models/System/AuthServices";
import {
  CommonRequestParam,
  FreeTextFilter,
  Pager,
} from "../../../../../Types";
import { getSorterFromSortModel, Page } from "../../../../../Utils";
import { SqlStringMatchType } from "../../../../../Utils/Enums";
import AuthServicesGridView from "./Grid";
import { useGetAuthServicesList } from "./Hooks";
import { Button } from "../../../../../Components/FormControls";

const ViewAuthServices: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FreeTextFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: AuthServicesModelKeys.Id,
      sort: "asc",
    },
  ]);

  const getParams = () => {
    const params: CommonRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: FreeTextFilter) => {
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setFilter({});
  };
  const pageChangeHandler = (currentPage: number) => {};
  const pageSizeChangeHandler = (itemsPerPage: number) => {};
  const onSort = (sortModel: GridSortModel) => {};

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    data: authServicesListResponse,
    isLoading: isAuthServicesListLoading,
    refetch: refetchAuthServicesList,
    error: getAuthServicesListError,
  } = useGetAuthServicesList(getParams());

  const isDataLoaded = authServicesListResponse !== null;

  /** useEffects */
  useEffect(() => {
    refetchAuthServicesList();
  }, [filter, pager, sortModel]);

  useEffect(() => {
    setIsLoading(isAuthServicesListLoading);
  }, [isAuthServicesListLoading]);

  useEffect(() => {
    if (getAuthServicesListError) {
      setAlert({
        ...alert,
        show: true,
        header: getAuthServicesListError?.Subject ? getAuthServicesListError?.Subject : "Error",
        body: getAuthServicesListError?.Description ? getAuthServicesListError?.Description : "Error while fetching the Auth Services list."
      });
    }
  }, [getAuthServicesListError]);
  

  return (
    <div>
      {renderHeader("Authenticated Users")}
      <div className="container-fluid">
        <Loading loading={isLoading} />
        <Alert {...alert} />
        {isDataLoaded && (
          <AuthServicesGridView
            data={authServicesListResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
        <Button
          label="Delete Selected Users From Cache"
          style={{ margin: 10 }}
          onClick={() => {}}
        ></Button>
      </div>
    </div>
  );
};

export default ViewAuthServices;
