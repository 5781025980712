/** Import Inbuilt libraries */
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Form, FormikProvider, useFormik } from 'formik';
import { parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
/** Custom components */
import { MemberIDParamType } from '../..';
import {
  Alert,
  AlertProps,
  ConfirmDialog,
  ConfirmDialogProps,
  ShowPleaseWait,
  useAlert,
} from '../../../../../../Components';
import { Button } from '../../../../../../Components/FormControls';
import { FormControlKeys } from '../../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import {
  FieldAddButtonContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormFieldSetContainer,
} from '../../../../../../Components/Formik/StyledComponents';
import {
  AddMemberModel,
  AddMemberRequestModel,
} from '../../../../../../Models/Members/AddMember';
import {
  AddMemberFavouriteModel,
  MemberFavourite,
} from '../../../../../../Models/Members/EditMember/DealerConfig';
import { ApplicationState } from '../../../../../../Store/Types';
import {
  ConfirmDialogContent,
  getDropdownMemberOptions,
  isSuccessEntityCreatedOrUpdatedResponse,
} from '../../../../../../Utils';
import {
  FavouriteType,
  MemberType,
  ServiceType,
} from '../../../../../../Utils/Enums';
import { YesNoOptions } from '../../../Constants';
import { useGetMember, usePutUpdateMember } from '../General/Hooks';
import { useGetMembersForDropdown } from '../MemberBlocks/Hooks';
import {
  useDeleteMemberFavourites,
  useGetMemberFavourites,
  usePostAddMemberFavourite,
  usePutUpdateMemberFavourite,
} from './Hooks';
import { DealerConfigKeys, FavouritesForDrivenJobsKeys } from './Types';

const DealerConfig: React.FC = () => {
  const { memberID: paramMemberID } = useParams<MemberIDParamType>();
  const memberID = paramMemberID ? parseInt(paramMemberID) : 0;
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  const handleAlertClose = () => {
    setSaveAlert({ ...saveAlert, show: false });
  };

  /**useStates */
  const [drivenFavouriteData, setDrivenFavouriteData] = useState<
    MemberFavourite[]
  >([]);
  const [transportedFavouriteData, setTransportedFavouriteData] = useState<
    MemberFavourite[]
  >([]);
  const [saveAlert, setSaveAlert] = useState<AlertProps>({
    header: '',
    body: 'Updated Successfully',
    show: false,
    closeCallback: handleAlertClose,
  });
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [selectedDrivenFavouriteIds, setSelectedDrivenFavouriteIds] =
    React.useState<number[]>([]);
  const [selectedTransportedFavouriteIds, setSelectedTransportedFavouriteIds] =
    React.useState<number[]>([]);
  const memberState = useSelector(
    (state: ApplicationState) => state.addMemberState!
  );
  const { getMemberFormValues } = memberState;
  const { Member: member, Documents: documents } = getMemberFormValues;
  const [formikInitialValues, setFormikInitialValues] =
    useState<AddMemberModel>(member);

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** React Query Hooks */
  const {
    data: getMemberResponse,
    isRefetching: isRefetchGetMemberLoading,
    refetch: refetchGetMember,
    error: getMemberError,
  } = useGetMember();

  const { 
    data: transportCompanies = [],
    error: getMembersError,
   } = useGetMembersForDropdown(
    MemberType.TransportCompany
  );

  const tcDrivenList = transportCompanies.filter(
    (tc) =>
      tc.ServiceType === ServiceType.Driven || tc.ServiceType === ServiceType.Both
  );
  const tcTransportedList = transportCompanies.filter(
    (tc) =>
      tc.ServiceType === ServiceType.Transported ||
      tc.ServiceType === ServiceType.Both
  );

  const {
    mutate: addMembersFavorite,
    isLoading: isAddMemberFavoriteLoading,
    data: putAddMemberFavouriteResponse,
    error: addMemberFavoriteError,
  } = usePostAddMemberFavourite();

  const {
    mutate: updateMembersFavorite,
    isLoading: isUpdateMemberFavoriteLoading,
    data: updateMembersFavoriteResponse,
    error: updateMembersFavoriteError,
  } = usePutUpdateMemberFavourite();

  const {
    mutate: deleteMemberFavorites,
    isLoading: isDeleteMemberFavoritesLoading,
    data: deleteMemberFavoritesResponse,
    error: deleteMemberFavoritesError,
  } = useDeleteMemberFavourites();

  const {
    mutate: putUpdateMember,
    isLoading: isPutUpdateMemberLoading,
    data: updateMemberResponse,
    error: updateMemberError,
  } = usePutUpdateMember();

  const {
    data: getFavouriteMembersResponse,
    isLoading: isGetFavouriteMembersLoading,
    refetch: getFavouriteMembers,
    error: getFavouriteMembersError,
  } = useGetMemberFavourites();

  /** Methods */
  const renderFavoriteCheckBox = (params: GridRenderCellParams) => {
    return (
      <input
        defaultChecked={params.value}
        type="checkbox"
        onChange={(e) =>
          updateSelectedMembersFavorite(params, e.currentTarget.checked)
        }
      />
    );
  };

  const columnsForDrivenJobs: GridColDef[] = [
    {
      field: FavouritesForDrivenJobsKeys.FavouriteMemberUsername,
      headerName: 'UserName',
      flex: 1,
    },
    {
      field: FavouritesForDrivenJobsKeys.FavouriteMemberCompany,
      flex: 1,
      headerName: 'Company',
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Internal,
      headerName: 'Internal Transfer',
      flex: 1,
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Dealer,
      flex: 1,
      headerName: 'Dealer Transfer',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Fleet,
      flex: 1,
      headerName: 'Fleet Delivery',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Retail,
      flex: 1,
      headerName: 'Retail Delivery',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_PremiumFleet,
      flex: 1,
      headerName: 'Premium Fleet',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_PremiumRetail,
      flex: 1,
      headerName: 'Premium Retail',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
  ];

  const columnsForTransportedJobs: GridColDef[] = [
    {
      field: FavouritesForDrivenJobsKeys.id,
      flex: 1,
      headerName: 'Id',
      hide: true,
    },
    {
      field: FavouritesForDrivenJobsKeys.FavouriteMemberUsername,
      flex: 1,
      headerName: 'UserName',
    },
    {
      field: FavouritesForDrivenJobsKeys.FavouriteMemberCompany,
      flex: 1,
      headerName: 'Company',
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Internal,
      headerName: 'Internal Transfer',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Dealer,
      flex: 1,
      headerName: 'Dealer Transfer',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Fleet,
      flex: 1,
      headerName: 'Fleet Delivery',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
    {
      field: FavouritesForDrivenJobsKeys.ForMovement_Retail,
      flex: 1,
      headerName: 'Retail Delivery',
      renderCell: (params: GridRenderCellParams) =>
        renderFavoriteCheckBox(params),
    },
  ];

  const CustomFooterFavouritesForDriven = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedDrivenFavouriteIds.length}
        style={{ margin: 10 }}
        onClick={() => {
          removeFavourites(ServiceType.Driven);
        }}
      ></Button>
    );
  };

  const CustomFooterFavouritesForTransported = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedTransportedFavouriteIds.length}
        style={{ margin: 10 }}
        onClick={() => {
          removeFavourites(ServiceType.Transported);
        }}
      ></Button>
    );
  };

  const handleConfirmRemoveFavourites = (serviceType: ServiceType) => {
    onConfirmDialogClose();
    if (serviceType === ServiceType.Driven)
      deleteMemberFavorites(selectedDrivenFavouriteIds);
    else if (serviceType === ServiceType.Transported)
      deleteMemberFavorites(selectedTransportedFavouriteIds);
  };

  const removeFavourites = (serviceType: ServiceType) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeleteMemberFavorites.Title,
      body: ConfirmDialogContent.DeleteMemberFavorites.Content,
      show: true,
      confirmCallback: () => {
        handleConfirmRemoveFavourites(serviceType);
      },
    });
  };

  const updateSelectedMembersFavorite = (
    params: GridRenderCellParams,
    value: boolean
  ) => {
    const { field: key, row } = params;
    const payload: AddMemberFavouriteModel = {
      ...row,
    };
    payload[key] = value;
    delete payload['FavouriteMemberUsername'];
    delete payload['FavouriteMemberCompany'];
    updateMembersFavorite(payload);
  };

  const addSelectedFavourite = (
    favouriteMemberID: number,
    favoriteType: FavouriteType
  ) => {
    const payload: AddMemberFavouriteModel = {
      MemberId: memberID,
      FavouriteMemberId: favouriteMemberID,
      FavouriteType: favoriteType,
      ForMovement_Internal: true,
      ForMovement_Dealer: true,
      ForMovement_Fleet: true,
      ForMovement_Retail: true,
      ForMovement_PremiumFleet: false,
      ForMovement_PremiumRetail: false,
    };
    addMembersFavorite(payload);
    refetchGetMember();
  };

  const getFavourites = (serviceType: ServiceType) => {
    const items =
      serviceType === ServiceType.Driven ? tcDrivenList : tcTransportedList;
    return getDropdownMemberOptions(items);
  };

  const onSubmit = (payload: AddMemberModel): void => {
    const request: AddMemberRequestModel = {
      Member: payload,
      Documents: documents,
      CustomerSetID: payload.CustomerSetID,
    };
    putUpdateMember(request);
  };

  /** useEffects */
  useEffect(() => {
    if (
      isSuccessEntityCreatedOrUpdatedResponse(updateMembersFavoriteResponse)
    ) {
      setSaveAlert({ ...saveAlert, show: true });
    }
  }, [updateMembersFavoriteResponse]);

  useEffect(() => {
    if (updateMembersFavoriteError) {
      setSaveAlert({
        ...saveAlert,
        show: true,
        header: updateMembersFavoriteError.Subject,
        body: updateMembersFavoriteError.Description,
      });
    }
  }, [updateMembersFavoriteError]);

  useEffect(() => {
    if (isSuccessEntityCreatedOrUpdatedResponse(updateMemberResponse)) {
      setSaveAlert({ ...saveAlert, show: true });
    }
  }, [updateMemberResponse]);

  useEffect(() => {
    if (updateMemberError) {
      setSaveAlert({
        ...saveAlert,
        show: true,
        body: 'An error occurred while saving',
      });
    }
  }, [updateMemberError]);

  useEffect(() => {
    setShowPleaseWait(
      isDeleteMemberFavoritesLoading ||
        isUpdateMemberFavoriteLoading ||
        isAddMemberFavoriteLoading ||
        isPutUpdateMemberLoading ||
        isRefetchGetMemberLoading ||
        isGetFavouriteMembersLoading
    );
  }, [
    isDeleteMemberFavoritesLoading,
    isUpdateMemberFavoriteLoading,
    isAddMemberFavoriteLoading,
    isPutUpdateMemberLoading,
    isRefetchGetMemberLoading,
    isGetFavouriteMembersLoading,
  ]);

  useEffect(() => {
    if (
      getFavouriteMembersResponse &&
      getFavouriteMembersResponse.MemberFavourites
    ) {
      const { MemberFavourites } = getFavouriteMembersResponse;
      const drivenData = MemberFavourites.filter(
        (x) => x.FavouriteType === FavouriteType.Driven
      );
      const transportedData = MemberFavourites.filter(
        (x) => x.FavouriteType === FavouriteType.Transported
      );
      setDrivenFavouriteData(drivenData);
      setTransportedFavouriteData(transportedData);
    }
  }, [getFavouriteMembersResponse]);

  useEffect(() => {
    setFormikInitialValues(member);
  }, [member]);

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      isSuccessEntityCreatedOrUpdatedResponse(putAddMemberFavouriteResponse)
    ) {
      getFavouriteMembers();
    }
  }, [putAddMemberFavouriteResponse]);

  useEffect(() => {
    const drivenData = drivenFavouriteData.filter(
      (x) => !selectedDrivenFavouriteIds.includes(x.Id)
    );
    const transportedData = transportedFavouriteData.filter(
      (x) => !selectedTransportedFavouriteIds.includes(x.Id)
    );
    setDrivenFavouriteData(drivenData);
    setTransportedFavouriteData(transportedData);
  }, [deleteMemberFavoritesResponse]);

  useEffect(() => {
    if (getMemberResponse) {
      const { Favourites: favourites } = getMemberResponse;
      setDrivenFavouriteData(
        favourites.filter((x) => x.FavouriteType === FavouriteType.Driven)
      );
      setTransportedFavouriteData(
        favourites.filter((x) => x.FavouriteType === FavouriteType.Transported)
      );
    }
  }, [getMemberResponse]);

  useEffect(() => {
    if (getFavouriteMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getFavouriteMembersError?.Subject + ': GetMemberFavourites',
        body: getFavouriteMembersError?.Description,
      });
    } else if (updateMemberError) {
      setAlert({
        ...alert,
        show: true,
        header: updateMemberError?.Subject,
        body: updateMemberError?.Description,
      });
    } else if (deleteMemberFavoritesError) {
      setAlert({
        ...alert,
        show: true,
        header: deleteMemberFavoritesError?.Subject,
        body: deleteMemberFavoritesError?.Description,
      });
    } else if (addMemberFavoriteError) {
      setAlert({
        ...alert,
        show: true,
        header: addMemberFavoriteError?.Subject,
        body: addMemberFavoriteError?.Description,
      });
    } else if (getMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getMembersError?.Subject,
        body: getMembersError?.Description,
      });
    } else if (getMemberError) {
      setAlert({
        ...alert,
        show: true,
        header: getMemberError?.Subject,
        body: getMemberError?.Description,
      });
    }
  }, [
    getFavouriteMembersError,
    updateMemberError,
    deleteMemberFavoritesError,
    addMemberFavoriteError,
    getMembersError,
    getMemberError
  ]);
  

  if (getMemberResponse) {
    const { handleSubmit, values, errors, validateForm, setFieldValue } =
      formik;
    return (
      <FormikProvider value={formik}>
        <Form>
          <Alert {...saveAlert}></Alert>
          <Alert {...alert} />
          <ConfirmDialog {...confirmDialogContent}></ConfirmDialog>
          <ShowPleaseWait show={showPleaseWait} />
          <FormFieldSetContainer>
            <FieldSetContainer>
              <FieldSetHeader>
                Dealer Job Offers Restricted To Favourites?
              </FieldSetHeader>
              <FavoriteItemContainer>
                <FlexContainer>
                  <FormikControl
                    name={DealerConfigKeys.SelectedFavouriteForDrivenId}
                    label="Favourites for Driven Jobs"
                    control={FormControlKeys.Select}
                    placeholder="Select"
                    className="member-select form-select"
                    options={getFavourites(ServiceType.Driven)}
                  />
                  <FieldAddButtonContainer>
                    <FormikControl
                      control={FormControlKeys.Button}
                      name="AddFavoriteButton"
                      label="Add Favourite"
                      size="sm"
                      onClick={() => {
                        addSelectedFavourite(
                          values.SelectedFavouriteForDrivenId,
                          FavouriteType.Driven
                        );
                      }}
                      disabled={!values.SelectedFavouriteForDrivenId}
                    />
                  </FieldAddButtonContainer>
                </FlexContainer>
                <div id='id_grid_favourites_for_driven_jobs' style={{ width: '100%' }}>
                  <DataGrid
                    sortingOrder={['desc', 'asc']}
                    getRowId={(row: any) => row.Id}
                    autoHeight
                    disableColumnMenu={true}
                    rows={drivenFavouriteData}
                    columnBuffer={columnsForDrivenJobs.length + 1}
                    columns={columnsForDrivenJobs}
                    hideFooterPagination={true}
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={selectedDrivenFavouriteIds}
                    onSelectionModelChange={(selected) => {
                      setSelectedDrivenFavouriteIds(selected as number[]);
                    }}
                    components={{
                      Footer: drivenFavouriteData.length
                        ? CustomFooterFavouritesForDriven
                        : undefined,
                    }}
                  />
                </div>
              </FavoriteItemContainer>
              <FavoriteItemContainer>
                <FlexContainer>
                  <FormikControl
                    name={DealerConfigKeys.SelectedFavouriteForTransportedId}
                    label="Favourites for Transported Jobs"
                    control={FormControlKeys.Select}
                    placeholder="Select"
                    className="member-select form-select"
                    options={getFavourites(ServiceType.Transported)}
                  />
                  <FieldAddButtonContainer>
                    <FormikControl
                      control={FormControlKeys.Button}
                      name="AddFavoriteButton"
                      label="Add Favourite"
                      size="sm"
                      onClick={() => {
                        addSelectedFavourite(
                          values.SelectedFavouriteForTransportedId,
                          FavouriteType.Transported
                        );
                      }}
                      disabled={!values.SelectedFavouriteForTransportedId}
                    />
                  </FieldAddButtonContainer>
                </FlexContainer>
                <div id='id_grid_favourites_for_transported_jobs' style={{ width: '100%' }}>
                  <DataGrid
                    sortingOrder={['desc', 'asc']}
                    getRowId={(row: any) => row.Id}
                    autoHeight
                    disableColumnMenu={true}
                    rows={transportedFavouriteData}
                    columns={columnsForTransportedJobs}
                    columnBuffer={columnsForTransportedJobs.length + 1}
                    hideFooterPagination={true}
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={selectedTransportedFavouriteIds}
                    onSelectionModelChange={(selected) => {
                      setSelectedTransportedFavouriteIds(selected as number[]);
                    }}
                    components={{
                      Footer: transportedFavouriteData.length
                        ? CustomFooterFavouritesForTransported
                        : undefined,
                    }}
                  />
                </div>
              </FavoriteItemContainer>
            </FieldSetContainer>
          </FormFieldSetContainer>
          <FieldSetContainer>
            <FieldSetHeader>Dealer Costs</FieldSetHeader>
            <FormikControl
              name={DealerConfigKeys.MaskContactInfoForJobDetails}
              label="Mask Contact Info For JobDetails?"
              control={FormControlKeys.RadioGroup}
              options={YesNoOptions}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikControl
                control={FormControlKeys.InputGroup}
                name={DealerConfigKeys.DrivenMaxCostPerVehiclePerMile}
                label="Driven Max Cost Per Vehicle Per Mile"
                type="number"
                min="0"
                text="£"
              />
              <FormikControl
                control={FormControlKeys.InputGroup}
                name={DealerConfigKeys.TransportedMaxCostPerVehiclePerMile}
                label="Transported Max Cost Per Vehicle Per Mile"
                type="number"
                min="0"
                text="£"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikControl
                name={DealerConfigKeys.DrivenGuaranteedPriceMinimumRate}
                label="Driven Guar. Price Min Rate"
                type="number"
                min="0"
              />
              <FormikControl
                name={DealerConfigKeys.DrivenGuaranteedPriceMaximumRate}
                label="Driven Guar. Price Max Rate"
                type="number"
                min="0"
              />
              <FormikControl
                name={DealerConfigKeys.TransportedGuaranteedPriceMinimumRate}
                label="Transported Guar. Price Min Rate"
                type="number"
                min="0"
              />
              <FormikControl
                name={DealerConfigKeys.TransportedGuaranteedPriceMaximumRate}
                label="Transported Guar. Price Max Rate"
                type="number"
                min="0"
              />
            </div>
            <Button label="Update Member" type="submit" />
          </FieldSetContainer>
        </Form>
      </FormikProvider>
    );
  }
  return null;
};

export default DealerConfig;

export const FavoriteItemContainer = styled.div`
  margin: 15px 0;
`;
