import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeleteFeedback = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number> => {

  const deleteFeedback = (feedbackID: number) => {
    return agent.feedback.deleteFeedback(feedbackID);
  };

  return useMutation(deleteFeedback);
};
