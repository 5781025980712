import { Form, FormikProvider, useFormik } from 'formik';
import react, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { ISourceOrTarget } from '../../../../../Models/PriceGroup';
import { useGetPriceGroupSourcesAndTargetsByName } from '../Hooks';
import {
     AutoCompleteContainer,
     AutoCompleteContainerConditionalRender,
     AutoCompleteItem,
     AutoCompleteItemButton,
     HideAutoComplete,
     Root,
     Search,
} from '../../../../../Components/UI';
import { applyBoldText } from './ApplyBoldText';
import { Alert, useAlert } from '../../../../../Components';

export const LiveSearch: FC<autoCompleteProps> = ({ onAddItem }) => {
     /*States */
     const [search, setSearch] = useState({
          text: '',
          suggestions: [] as any[],
     });
     const [isComponentVisible, setIsComponentVisible] = useState(true);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /**React Query Hook*/
     const {
          refetch: fetchGetPriceGroupSourcesAndTargetsByNameLoading,
          data: getPriceGroupSourcesAndTargetsByNameResponse,
          error: getPriceGroupSourcesAndTargetsByNameError,
     } = useGetPriceGroupSourcesAndTargetsByName(search.text);

     /** Formik */
     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {},
          enableReinitialize: true,
     });

     /** Functions */
     const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          if (value === null || value === undefined) {
               return;
          } else {
               fetchGetPriceGroupSourcesAndTargetsByNameLoading();
               setSearch({ ...search, text: value });
          }
     };

     const suggestionSelected = (value: ISourceOrTarget) => {
          setIsComponentVisible(false);
          setSearch({
               text: '',
               suggestions: [],
          });
     };

     /* update the suggestions component and show it */
     useEffect(() => {
          let suggestions =
               getPriceGroupSourcesAndTargetsByNameResponse?.PriceGroupSourceTarget ?? [];
          setIsComponentVisible(true);
          setSearch({ ...search, suggestions });
     }, [getPriceGroupSourcesAndTargetsByNameResponse]);

     /* hide the suggestion component when clicking outside */
     useEffect(() => {
          const handleClick = (e: MouseEvent) => {
               setIsComponentVisible(false);
          };
          document.addEventListener('click', handleClick);
          return () => {
               document.removeEventListener('click', handleClick);
          };
     }, []);

     useEffect(() => {
     if (getPriceGroupSourcesAndTargetsByNameError) {
          setAlert({
          ...alert,
          show: true,
          header: getPriceGroupSourcesAndTargetsByNameError?.Subject,
          body: getPriceGroupSourcesAndTargetsByNameError?.Description,
          });
     }
     }, [getPriceGroupSourcesAndTargetsByNameError]);

     const { suggestions, text } = search;

     return (
          <Root>
               <Alert {...alert} />
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <HideAutoComplete
                                   onClick={() => setIsComponentVisible(false)}
                                   isComponentVisible={isComponentVisible}
                              >
                                   
                              </HideAutoComplete>

                              <Search>
                                   <FormikControl
                                        control={FormControlKeys.LabelledInput}
                                        name={'SearchInput'}
                                        value={search.text}
                                        onChange={onTextChanged}
                                        type={'text'}
                                        placeholder={'search for members, groups and partners...'}
                                   />
                              </Search>
                              <AutoCompleteContainerConditionalRender
                                   isVisible={isComponentVisible}
                              >
                                   {suggestions.length > 0 && isComponentVisible && (
                                        <AutoCompleteContainer>
                                             {suggestions.map((item: ISourceOrTarget) => (
                                                  <AutoCompleteItem key={item.Id}>
                                                       <AutoCompleteItemButton
                                                            key={item.Id}
                                                            onClick={() => {
                                                                 suggestionSelected(item);
                                                                 onAddItem(item);
                                                            }}
                                                       >
                                                            {applyBoldText(item.Name, text)}
                                                       </AutoCompleteItemButton>
                                                  </AutoCompleteItem>
                                             ))}
                                        </AutoCompleteContainer>
                                   )}
                              </AutoCompleteContainerConditionalRender>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </Root>
     );
};

interface autoCompleteProps {
     iconColor?: string;
     inputStyle?: react.CSSProperties;
     optionsStyle?: react.CSSProperties;
     data: any[];
     onAddItem: Function;
}
