import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { FailIndicatorIcon, SuccessIndicatorIcon } from '../../../../../Assets/Images';
import { getDayAndMonthDate } from '../../../../../Utils';
import { cellImageStyle } from '../../../Billing/AddBill';

export function getSupplierPaidDateColumn(params: GridRenderCellParams) {
     const date = getDayAndMonthDate(params.row.SupplierPaidDate);
     return renderDateStatus(date, true);
}

export function getInvoicedColumn(params: GridRenderCellParams) {
     const date = getDayAndMonthDate(params.row.SupplyingMemberInvoiceSentDate);
     return renderDateStatus(date);
}

export function getMovexPaidDateColumn(params: GridRenderCellParams) {
     const date = getDayAndMonthDate(params.row.MovexPaidDate);
     return renderDateStatus(date, true);
}

export function getPaymentNoOfVehiclesColumn(params: GridRenderCellParams) {
     const noOfVehicle = params.row.NoOfVehicles;
     const showHVG = params.row.NoOfHGVs < 1;
     return `${noOfVehicle} ${showHVG ? '' : 'HGV'} `;
}

export const renderDateStatus = (date: string | undefined, displayDate: boolean = false) => {
     if (date) {
          return (
               <>
                    <img alt='' style={cellImageStyle} src={SuccessIndicatorIcon} />
                    {displayDate ? ` ${date}` : null}
               </>
          );
     } else {
          return <img alt='' style={cellImageStyle} src={FailIndicatorIcon} />;
     }
};
