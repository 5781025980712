import { JobsFilterFormModel, SearchDataFilter } from '../../../../Models/Jobs';
import {
     AdminJobStatus,
     JobKind,
     JobMovementType,
     JobOfferedTo,
     JobType,
     PaymentMethod,
     ServiceType,
} from '../../../../Utils/Enums';

export const getJobFiltersFromSavedSearchModel = (filter: SearchDataFilter): JobsFilterFormModel => {
  let jobFilter: JobsFilterFormModel = {
    AdminUserID: filter.AdminUserID,
    CollectionDateFrom: new Date(filter.CollectionDateFrom ?? ''),
    CollectionDateTo: new Date(filter.CollectionDateTo ?? ''),
    DeliveryDateFrom: new Date(filter.DeliveryDateFrom ?? ''),
    DeliveryDateTo: new Date(filter.DeliveryDateTo ?? ''),
    DistanceMilesMax: filter.DistanceMilesMax,
    DistanceMilesMin: filter.DistanceMilesMin,
    Freetext: filter.Freetext,
    FreeTextSearchBy: filter.FreeTextSearchBy,
    FromPostCode: filter.FromPostCode,
    GroupID: filter.GroupID,
    PartnerID: filter.PartnerID,
    RequestDateFrom: new Date(filter.RequestDateFrom ?? ''),
    RequestDateTo: new Date(filter.RequestDateTo ?? ''),
    MultipleDropOff: filter.JobKind?.includes(JobKind.MultipleDropOff) || filter.JobKind?.includes(`${JobKind.MultipleDropOff}`),
    Onward: filter.JobKind?.includes(JobKind.Onward) || filter.JobKind?.includes(`${JobKind.Onward}`),
    Return: filter.JobKind?.includes(JobKind.Return) || filter.JobKind?.includes(`${JobKind.Return}`),
    Standard: filter.JobKind?.includes(JobKind.Standard) || filter.JobKind?.includes(`${JobKind.Standard}`),
    Draft: filter.JobStatus?.includes(AdminJobStatus.Draft) || filter.JobStatus?.includes(`${AdminJobStatus.Draft}`),
    Aborted: filter.JobStatus?.includes(AdminJobStatus.Aborted) || filter.JobStatus?.includes(`${AdminJobStatus.Aborted}`),
    AcceptedQuote: filter.JobStatus?.includes(AdminJobStatus.AcceptedQuote) || filter.JobStatus?.includes(`${AdminJobStatus.AcceptedQuote}`),
    ActiveJob: filter.JobStatus?.includes(AdminJobStatus.ActiveJob) || filter.JobStatus?.includes(`${AdminJobStatus.ActiveJob}`),
    AdminRemoved_OrFailed: filter.JobStatus?.includes(AdminJobStatus.AdminRemoved_Or_Failed) || filter.JobStatus?.includes(`${AdminJobStatus.AdminRemoved_Or_Failed}`),
    BookedJob: filter.JobStatus?.includes(AdminJobStatus.BookedJob) || filter.JobStatus?.includes(`${AdminJobStatus.BookedJob}`),
    Cancelled: filter.JobStatus?.includes(AdminJobStatus.Cancelled) || filter.JobStatus?.includes(`${AdminJobStatus.Cancelled}`),
    CompletedJob: filter.JobStatus?.includes(AdminJobStatus.CompletedJob) || filter.JobStatus?.includes(`${AdminJobStatus.CompletedJob}`),
    PendingQuotes: filter.JobStatus?.includes(AdminJobStatus.PendingQuotes) || filter.JobStatus?.includes(`${AdminJobStatus.PendingQuotes}`),
    Expired: filter.JobStatus?.includes(AdminJobStatus.Expired) || filter.JobStatus?.includes(`${AdminJobStatus.Expired}`),
    MovementTypeFleetDelivery: filter.MovementType?.includes(JobMovementType.FleetDelivery) || filter.MovementType?.includes(`${JobMovementType.FleetDelivery}`),
    MovementTypeDealerTransfer: filter.MovementType?.includes(JobMovementType.DealerTransfer) || filter.MovementType?.includes(`${JobMovementType.DealerTransfer}`),
    MovementTypeInternalTransfer: filter.MovementType?.includes(JobMovementType.InternalTransfer) || filter.MovementType?.includes(`${JobMovementType.InternalTransfer}`),
    MovementTypePremiumFleet: filter.MovementType?.includes(JobMovementType.PremiumFleet) || filter.MovementType?.includes(`${JobMovementType.PremiumFleet}`),
    MovementTypePremiumRetail: filter.MovementType?.includes(JobMovementType.PremiumRetail) || filter.MovementType?.includes(`${JobMovementType.PremiumRetail}`),
    MovementTypeRetailDelivery: filter.MovementType?.includes(JobMovementType.RetailDelivery) || filter.MovementType?.includes(`${JobMovementType.RetailDelivery}`),
    Driven: filter.ServiceType?.includes(ServiceType.Driven) || filter.ServiceType?.includes(`${ServiceType.Driven}`),
    Transported: filter.ServiceType?.includes(ServiceType.Transported) || filter.ServiceType?.includes(`${ServiceType.Transported}`),
    Both: filter.ServiceType?.includes(ServiceType.Both) || filter.ServiceType?.includes(`${ServiceType.Both}`),
    JobTypeQuotes: filter.JobType?.includes(JobType.RequestQuote) || filter.JobType?.includes(`${JobType.RequestQuote}`),
    JobTypeFixedPrice: filter.JobType?.includes(JobType.FixedPrice) || filter.JobType?.includes(`${JobType.FixedPrice}`),
    JobTypeBoth: filter.JobType?.includes(JobType.Both) || filter.JobType?.includes(`${JobType.Both}`),
    JobTypeGuaranteedPrice: filter.JobType?.includes(JobType.GuaranteedPrice) || filter.JobType?.includes(`${JobType.GuaranteedPrice}`),
    OfferedToWholeNetwork: filter.OfferedTo?.includes(JobOfferedTo.WholeNetwork) || filter.OfferedTo?.includes(`${JobOfferedTo.WholeNetwork}`),
    FiveStarCompanies: filter.OfferedTo?.includes(JobOfferedTo.FiveStarCompanies) || filter.OfferedTo?.includes(`${JobOfferedTo.FiveStarCompanies}`),
    SelectedSuppliers: filter.OfferedTo?.includes(JobOfferedTo.SelectedSuppliers) || filter.OfferedTo?.includes(`${JobOfferedTo.SelectedSuppliers}`),
    Pending: filter.PaymentMethod?.includes(PaymentMethod.Pending) || filter.PaymentMethod?.includes(`${PaymentMethod.Pending}`),
    SmartDebit: filter.PaymentMethod?.includes(PaymentMethod.SmartDebit) || filter.PaymentMethod?.includes(`${PaymentMethod.SmartDebit}`),
    Invoice: filter.PaymentMethod?.includes(PaymentMethod.Invoice) || filter.PaymentMethod?.includes(`${PaymentMethod.Invoice}`),
    PrePay: filter.PaymentMethod?.includes(PaymentMethod.PrePay) || filter.PaymentMethod?.includes(`${PaymentMethod.PrePay}`),
  };

  return jobFilter;
};