import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FileModelRequest, FileModelRequestForSystemTasksCSV } from '../../../../../Models/Tasks';

export const usePostUploadMarkRequestingMemberInvoicesAsPaidFile = (
    options?: any
): UseMutationResult<FileModelRequestForSystemTasksCSV, any, FileModelRequest> => {

    const postUploadMarkRequestingMemberInvoicesAsPaidFile = (payload: FileModelRequest) => {
        return agent.tasks.postPostUploadMarkRequestingMemberInvoicesAsPaidFile(payload)
    }

    return useMutation(postUploadMarkRequestingMemberInvoicesAsPaidFile);
}
