import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import { InlineBlockColMd10Section } from '../../../../../../Components/Formik';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { JobType, ServiceType } from '../../../../../../Utils/Enums';
import { JobTime } from '../../../../../../Utils/Enums/JobTime';
import { JobTypeForRelistOptions, ServiceTypeOptionsForRelist } from '../../../../Members/Constants';
import { RelistJobRequest } from '../../../../../../Models/Jobs/EditJob/RelistJobRequest';

const validationSchema = Yup.object().shape({
     CollectionDate: Yup.string().required('Please enter a collection date'),
     DeliveryDate: Yup.string().required('Please enter a delivery date'),
     CollectionTime: Yup.string().required('Please select a collection time'),
     DeliveryTime: Yup.string().required('Please select a delivery time'),
});

type FormValues = {
     CollectionDate: Date | null;
     CollectionTime: JobTime | null;
     UseExactCollectionDate: boolean;
     DeliveryDate: Date | null;
     DeliveryTime: JobTime | null;
     UseExactDeliveryDate: boolean;
     ServiceType: ServiceType | null;
     JobType: JobType | null;
     MinDrivenPrice: number | null;
     MaxDrivenPrice: number | null;
     Transported_DriveOnto: boolean | null;
     Transported_Enclosed: boolean | null;
     TFixedPriceMin: number | null;
     TFixedPriceMax: number | null;
};

const RelistJobModal: React.FC<{ 
     jobId: number; 
     dFixedPriceMin_Original: number;
     dFixedPriceMax_Original: number;
     tFixedPriceMin_Original: number;
     tFixedPriceMax_Original: number;
     putRelistJob: Function; 
     closeModal: Function }> = (props): JSX.Element => {
     const jobId = props.jobId;
     const putRelistJob = props.putRelistJob;
     const closeModal = props.closeModal;
     const dFixedPriceMin_Original = props.dFixedPriceMin_Original;
     const dFixedPriceMax_Original = props.dFixedPriceMax_Original;
     const tFixedPriceMin_Original = props.tFixedPriceMin_Original;
     const tFixedPriceMax_Original = props.tFixedPriceMax_Original;

     const formik = useFormik<FormValues>({
          initialValues: {
               CollectionDate: null,
               CollectionTime: null,
               UseExactCollectionDate: false,
               DeliveryDate: null,
               DeliveryTime: null,
               UseExactDeliveryDate: false,
               ServiceType: ServiceType.Driven,
               JobType: JobType.RequestQuote,
               MinDrivenPrice: dFixedPriceMin_Original ?? null,
               MaxDrivenPrice: dFixedPriceMax_Original ?? null,
               Transported_DriveOnto: true,
               Transported_Enclosed: false,
               TFixedPriceMin: tFixedPriceMin_Original ?? null,
               TFixedPriceMax: tFixedPriceMax_Original ?? null,
          },
          validationSchema,
          onSubmit: (values, { setSubmitting, setErrors }) => {
               
               if (!values.CollectionDate) {
                    setErrors({ CollectionDate: 'Collection Date is required' });
               }
               if (!values.DeliveryDate) {
                    setErrors({ DeliveryDate: 'Delivery Date is required' });
               }
               setSubmitting(false);

               let payload: RelistJobRequest = {
                    JobId: jobId,
                    CollectionDate: values.CollectionDate,
                    CollectionTime: values.CollectionTime,
                    UseExactCollectionDate: values.UseExactCollectionDate,
                    DeliveryDate: values.DeliveryDate,
                    DeliveryTime: values.DeliveryTime,
                    UseExactDeliveryDate: values.UseExactDeliveryDate,
                    ServiceType: values.ServiceType,
                    Transported_DriveOnto: values.Transported_DriveOnto,
                    Transported_Enclosed: values.Transported_Enclosed,
                    JobType: values.JobType,
                    DFixedPriceMin: values.MinDrivenPrice,
                    DFixedPriceMax: values.MaxDrivenPrice,
                    TFixedPriceMin: values.TFixedPriceMin,
                    TFixedPriceMax: values.TFixedPriceMax,
               };

               putRelistJob(payload);
               closeModal();
          },
          enableReinitialize: true,
     });

     const { values, setFieldValue } = formik;
     return (
          <>
               <FormikProvider value={formik}>
                    <Form>
                         <fieldset>
                              <InlineBlockColMd10Section>
                                   <RelistHeader>Collection</RelistHeader>
                                   <RowContainer>
                                        <b>Collection Date: </b>
                                        <FormikControl name='CollectionDate' type='date' />
                                   </RowContainer>
                                   <FormikControl
                                        label='Collection Time:'
                                        name='CollectionTime'
                                        control={FormControlKeys.RadioGroup}
                                        options={[
                                             { label: 'AnyTime', key: 'AnyTime', value: JobTime.Anytime },
                                             { label: 'AM', key: 'AM', value: JobTime.Am },
                                             { label: 'PM', key: 'PM', value: JobTime.Pm },
                                        ]}
                                   />

                                   <FormikControl
                                        label='Use Exact Collection Date'
                                        name='UseExactCollectionDate'
                                        control={FormControlKeys.Checkbox}
                                   />
                                   <RelistHeader>Delivery</RelistHeader>
                                   <RowContainer>
                                        <b>Delivery Date: </b>
                                        <FormikControl name='DeliveryDate' placeholder='Delivery Date' type='date' />
                                   </RowContainer>
                                   <FormikControl
                                        label='Delivery Time:'
                                        name='DeliveryTime'
                                        control={FormControlKeys.RadioGroup}
                                        options={[
                                             { label: 'AnyTime', key: 'AnyTime', value: JobTime.Anytime },
                                             { label: 'AM', key: 'AM', value: JobTime.Am },
                                             { label: 'PM', key: 'PM', value: JobTime.Pm },
                                        ]}
                                   />
                                   <FormikControl
                                        label='Use Exact Delivery Date'
                                        name='UseExactDeliveryDate'
                                        control={FormControlKeys.Checkbox}
                                   />
                                   <RelistHeader>Service</RelistHeader>
                                   <RowContainer>
                                        <b>Service Type: </b>
                                        <FormikControl
                                             style={{ alignSelf: 'flex-center' }}
                                             name='ServiceType'
                                             control={FormControlKeys.Select}
                                             options={ServiceTypeOptionsForRelist}
                                        />
                                   </RowContainer>

                                   {values.ServiceType == ServiceType.Transported && (
                                        <>
                                             <FormikControl
                                                  label='Drive Onto Transport'
                                                  name='Transported_DriveOnto'
                                                  control={FormControlKeys.Checkbox}
                                             />
                                             <FormikControl
                                                  label='Enclosed Transport'
                                                  name='Transported_Enclosed'
                                                  control={FormControlKeys.Checkbox}
                                             />
                                        </>
                                   )}
                                   <RelistHeader>Job Type</RelistHeader>
                                   <RowContainer>
                                        <b>Job Type: </b>
                                        <FormikControl
                                             name='JobType'
                                             control={FormControlKeys.Select}
                                             options={JobTypeForRelistOptions}
                                        />
                                   </RowContainer>

                                   {values.JobType != JobType.RequestQuote && (
                                        <>
                                             {values.ServiceType != ServiceType.Transported && (
                                                  <>
                                                       <RowContainer>
                                                            <b>Min Driven Fixed Price: </b>
                                                            <FormikControl
                                                                 style={{ width: '100px' }}
                                                                 name='MinDrivenPrice'
                                                                 control={FormControlKeys.InputGroup}
                                                                 type='currency'
                                                                 text='£'
                                                            />
                                                       </RowContainer>
                                                       <RowContainer>
                                                            <b>Max Driven Fixed Price: </b>
                                                            <FormikControl
                                                                 style={{ width: '100px' }}
                                                                 name='MaxDrivenPrice'
                                                                 control={FormControlKeys.InputGroup}
                                                                 type='currency'
                                                                 text='£'
                                                            />
                                                       </RowContainer>
                                                  </>
                                             )}

                                             {values.ServiceType != ServiceType.Driven && (
                                                  <>
                                                       <RowContainer>
                                                            <b>Min Transported Fixed Price: </b>
                                                            <FormikControl
                                                                 style={{ width: '100px' }}
                                                                 name='TFixedPriceMin'
                                                                 control={FormControlKeys.InputGroup}
                                                                 type='currency'
                                                                 text='£'
                                                            />
                                                       </RowContainer>
                                                       <RowContainer>
                                                            <b>Max Transported Fixed Price: </b>
                                                            <FormikControl
                                                                 style={{ width: '100px' }}
                                                                 name='TFixedPriceMax'
                                                                 control={FormControlKeys.InputGroup}
                                                                 type='currency'
                                                                 text='£'
                                                            />
                                                       </RowContainer>
                                                  </>
                                             )}
                                        </>
                                   )}
                              </InlineBlockColMd10Section>
                         </fieldset>
                         <br />
                         <Col style={{ display: 'flex' }}>
                              <FormikControl label='Relist Job' control={FormControlKeys.Button} type='submit' />
                              <FormikControl label='Cancel' onClick={closeModal} control={FormControlKeys.Button} />
                         </Col>
                    </Form>
               </FormikProvider>
          </>
     );
};

export default RelistJobModal;

const RelistHeader = styled.h3`
     color: #0181af;
     font-size: 20px;
     font-weight: bold;
     line-height: 1.2em;
`;

const RowContainer = styled.div`
     display: flex;
     align-items: center;
     gap: 10px;
`;
