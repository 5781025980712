import { useQuery, UseQueryResult } from 'react-query';
import { CommonRequestParam } from '../../../../../../Types';

export const useGetCacheServiceList = (payload: CommonRequestParam,
  options?: any
): UseQueryResult<any, any> => {

  const getAdministratorList = () => {
    // ********** TODO: code to be replaced with api call
    const data = {
      Page: {
        CurrentPage: 1, TotalPages: 1, TotalItems: 4, ItemsPerPage: 50,
        Items: [
          { TotalRecords: 2, Id: 1, Key: "AdminUsersPermissions(1)" },
          { TotalRecords: 2, Id: 1, Key: "AuthToken(1F3+zUlczS6TKQpZqt0G979YYoFjU8VbqmsUjT4aVKQ=)"},
          
        ],
        Context: null
      },
      TableSorter: { Column: "ID", Ascending: true }
    }
    //***********
    return data;
  };

  return useQuery(['GetAdministratorList', payload], getAdministratorList, {
    ...options,
  });
};
