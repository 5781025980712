/** Inbuilt libraries */
import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
/** Custom Components */
import agent from '../../../../Axios/Agent';
import { Alert, Loading, renderHeader, useAlert } from '../../../../Components';
import {
  MemberModelKeys,
} from '../../../../Models/Members';
import { GetReportsNewOrUpdatedMembersRequest, NewOrUpdatedMembersFilter } from '../../../../Models/NewOrUpdatedMembers';

import { Pager } from '../../../../Types';
import {
  downloadFile,
  getSorterFromSortModel,
  MAX_VALUE,
  Page,
} from '../../../../Utils';
import FilterBox from './FilterBox';
import JobsByUserGridView from './Grid';
import { useGetJobsByUserList } from './Hooks';
import './index.css';

const JobsByUser: React.FC = (): JSX.Element => {
  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<NewOrUpdatedMembersFilter>({});
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: MemberModelKeys.SignupDate,
      sort: 'asc',
    },
  ]);

  /** Methods */
  const getParams = () => {
    const params: GetReportsNewOrUpdatedMembersRequest = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    isLoading: isGetMembersLoading,
    data: members,
    refetch: fetchMembers,
    error: getMembersError,
  } = useGetJobsByUserList(getParams());

  const isMembersDataLoaded = members !== null;

  const handleSearch = (filter: NewOrUpdatedMembersFilter) => {
    setPageLoaded(true);
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setPageLoaded(true);
    setFilter({});
  };

  const handleExportJobsByUserCsv = () => {
    const params: GetReportsNewOrUpdatedMembersRequest = {
      Pager: {
        CurrentPage: 1,
        ItemsPerPage: MAX_VALUE,
      },
      Sorter: { Column: MemberModelKeys.Id, Ascending: true },
      Filter: filter,
    };
    agent.reports
      .GetReportJobsByUsersToCSV(params)
      .then((response: any) => {
        const { FileDownloadName, FileContents } = response;
        downloadFile(FileContents, FileDownloadName);
      })
      .catch((error) => {
        console.log('handleExportJobsByUserCsv', error);
      });
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
  };

  /** useEffects */
  useEffect(() => {
    if (pageLoaded) {
      fetchMembers();
    }
  }, [pageLoaded, pager, filter, sortModel]);

  useEffect(() => {
    setIsLoading(isGetMembersLoading);
  }, [isGetMembersLoading]);

  useEffect(() => {
    if (getMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getMembersError?.Subject,
        body: getMembersError?.Description,
      });
    }
  }, [getMembersError]);
  

  return (
    <div>
      <Alert {...alert} />
      {renderHeader('Reports - Jobs by User')}
      <div className="container-fluid">
        <FilterBox
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onExportCSV={handleExportJobsByUserCsv}
          showExport={isMembersDataLoaded && members?.Page !== null}
        />
        {!isMembersDataLoaded && (
          <div className="please-search-placeholder">
            <p className="please-search-text">
              Please perform a search to return some results.
            </p>
          </div>
        )}
        {isMembersDataLoaded && members && !members?.Page && (
          <div className="please-search-placeholder">
            <p className="please-search-text">No matching results found.</p>
          </div>
        )}
        <Loading loading={isLoading} />
        {(isMembersDataLoaded && members) && (
          <JobsByUserGridView
            data={members}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default JobsByUser;