import { useParams } from 'react-router-dom';
import {
     FieldSetContainer,
     FieldSetHeader,
     HoverableLink,
     QuickLinkItem,
} from '../../../../../Components/Formik';
import { RouteConstants } from '../../../../../Routes';
import { JobIDParamType } from '../Types';
import { SpanBoldItem } from '../../../../../Components/UI';

const Section1QuickLinks = (): JSX.Element => {
     const { jobID: paramJObID } = useParams<JobIDParamType>();
     const jobID = paramJObID ? parseInt(paramJObID) : 0;

     const jobsAtivityHistory = `${RouteConstants.JobActivityHistoryByJobID}=${jobID}`;

     return (
          <div>
               <FieldSetContainer>
                    <FieldSetHeader>Quick Links</FieldSetHeader>
                    <QuickLinkItem>
                         <br />
                         <HoverableLink to={jobsAtivityHistory} target='_blank'>
                              <SpanBoldItem>View Jobs Activity History</SpanBoldItem>
                         </HoverableLink>
                         <br />
                         <HoverableLink to={''} target='_blank'>
                              <SpanBoldItem>History</SpanBoldItem>
                         </HoverableLink>
                    </QuickLinkItem>
               </FieldSetContainer>
          </div>
     );
};

export default Section1QuickLinks;
