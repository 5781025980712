import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetPartnersRequest } from '../../../../../Models/Partners';

export const useGetPartners = (payload: GetPartnersRequest,
  options?: any
): UseQueryResult<any, any> => {

  const getPartners = () => {
    return agent.partners.getPartners(payload);
  };

  return useQuery(['GetPartners', payload], getPartners, {
    ...options,
  });
};
