import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobReferenceModel, UpdateJobReferenceResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobReference = (
  options?: any
): UseMutationResult<
    UpdateJobReferenceResponseModel,
  any,
  UpdateJobReferenceModel
> => {
  const usePutUpdateJobReference = (
    payload: UpdateJobReferenceModel
  ) => {
    return agent.jobs.putUpdateJobReference(payload);
  };

  return useMutation(usePutUpdateJobReference);
};
