import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobStatusModel, UpdateJobStatusResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobStatus = (
  options?: any
): UseMutationResult<
  UpdateJobStatusResponseModel,
  any,
  UpdateJobStatusModel
> => {
  const usePutUpdateJobStatus = (payload: UpdateJobStatusModel) => {
    return agent.jobs.putUpdateJobStatus(payload);
  };

  return useMutation(usePutUpdateJobStatus);
};
