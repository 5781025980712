import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AbortJobRequest } from '../../../../../Models/Jobs/EditJob/AbortJobRequest';

export const usePostAdminAbortMotabilityJob = (options?: any): UseMutationResult<any, any, AbortJobRequest> => {

  const postAdminAbortMotabilityJob = (abortJobRequest: AbortJobRequest) => {
    return agent.jobs.postAdminAbortMotabilityJob(abortJobRequest);
  };

  return useMutation(postAdminAbortMotabilityJob);
};
