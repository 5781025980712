import { useQuery, UseQueryResult } from 'react-query';

export const useGetPages = (
  options?: any
): UseQueryResult<any, any> => {

  const getPageList = () => {
    // ********** TODO: code to be replaced with api call
    const data = {
      Page: {
        CurrentPage: 1, TotalPages: 1, TotalItems: 4, ItemsPerPage: 50,
        Items: [
          {
            Id: 1,
            FirstLine: "@{", IsReserved: false,
            Path: "D:\Projects\Movex_Web\Movex.Web\Views\Pages\About-Us.cshtml", Name: "About-Us"
          }, {
            Id: 2,
            FirstLine: "@*RESERVED*@", IsReserved: true,
            Path: "D:\Projects\Movex_Web\Movex.Web\Views\Pages\Barclays-EPDQ-Payment-Template.cshtml", Name: "Barclays-EPDQ-Payment-Template"
          }
        ],
        Context: null
      }
    }
    //***********
    return data;
  };

  return useQuery(['GetPageList'], getPageList, {
    ...options,
  });
};
