import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetPriceGroupSourcesResponse } from '../../../../../Models/PriceGroup';

export const useGetPriceGroupSources = (
     priceGroupID: number,
     options?: any
): UseQueryResult<GetPriceGroupSourcesResponse, any> => {
     const getPriceGroupSources = () => {
          return agent.priceGroup.getPriceGroupSources(priceGroupID)
     };

     return useQuery(['GetPriceGroupSources', priceGroupID], getPriceGroupSources, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
