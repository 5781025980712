import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddMemberFavouriteModel } from '../../../../../../../Models/Members/EditMember/DealerConfig';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const usePutUpdateMemberFavourite = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddMemberFavouriteModel
> => {
  const updateMemberFavourite = (payload: AddMemberFavouriteModel) => {
    return agent.members.putUpdateMemberFavourite(payload);
  };

  return useMutation(updateMemberFavourite);
};
