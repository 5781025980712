import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
     Alert,
     ConfirmDialogProps,
     Loading,
     ShowPleaseWait,
     useAlert,
} from '../../../../Components';
import withAddForm from '../../Common/AddForm/withAddForm';
import ActivityColumnRight from './ActivityColumnRight';
import CreateNewAdminTask from './CreateNewAdminTask';
import { useGetAdminUsersForTaskListDropdown, useGetTaskDetails } from './Hooks';
import JobActivitiesDisplay from './JobActivitiesDisplay';

export type TaskIDParamType = {
     taskID: string;
};

const AddTask: React.FC = (): JSX.Element => {
     const { taskID } = useParams<TaskIDParamType>();
     const taskId = parseInt(taskID) ?? 0;
     const isEditMode = taskId > 0;

     /** Modal Functions */
     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     /** States */
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });

     /** Custom Hooks */
     const { alert, setAlert } = useAlert();

     /** Query Hooks */
     const { data: getAdminUsersForTaskListDropdownData } = useGetAdminUsersForTaskListDropdown(true);
     
     const {
          isLoading: isGetTaskLoading,
          data: getTaskData,
          refetch: refetchGetTask,
          error: errorGetTask,
     } = useGetTaskDetails(taskId);

     useEffect(() => {
          if (errorGetTask) {
               setAlert({
                    ...alert,
                    show: true,
                    header: errorGetTask?.Subject,
                    body: errorGetTask?.Description,
               });
          }
     }, [errorGetTask]);

     const component = (
          <>
               <Alert {...alert} />
               <ShowPleaseWait show={showPleaseWait} />
               <Loading loading={isLoading || isGetTaskLoading} />
               {!isEditMode && <CreateNewAdminTask />}

               {isEditMode && getTaskData && (
                    <div id='task-and-activities'>
                         <JobActivitiesDisplay
                              activities={getTaskData.TaskActivities}
                              taskId={taskId}
                              refetchTask={() => refetchGetTask()}
                         />
                         {getAdminUsersForTaskListDropdownData && (
                              <ActivityColumnRight
                                   adminUserList={getAdminUsersForTaskListDropdownData?.AdminUsersList}
                                   taskDetails={getTaskData}
                                   refetchTask={() => refetchGetTask()}
                              />
                         )}
                    </div>
               )}
          </>
     );
     return withAddForm(component, isEditMode ? 'Activities for Job Additional Costs task ' + taskId.toString() : 'Create New Admin Task');
};

export default AddTask;
