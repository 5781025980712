import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateNewProofResponseModel, uploadNewProof } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobNewProof = (
  options?: any
): UseMutationResult<
UpdateNewProofResponseModel,
  any,
  uploadNewProof
> => {
  const usePutUpdateJobNewProof = (
    payload: uploadNewProof
  ) => {
    return agent.jobs.putUploadNewProof(payload);
  };

  return useMutation(usePutUpdateJobNewProof);
};