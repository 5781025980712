export enum TaskOpenReason {
     NA = 0,
     AdditionalVehicle = 1,
     BridgeRoadToll = 2,
     CarWashValet = 3,
     ChangeOfCollectionDeliveryAddress = 4,
     ChangeOfDateTime = 5,
     ChangeOfServiceType = 6,
     ElectricCharging = 7,
     FerryTicket = 8,
     Fuel = 9,
     Hotel = 10,
     JobListedWrongWayRound = 11,
     Oil = 12,
     OutOfHoursCollectionDelivery = 13,
     Postage = 14,
     QuotedWrongPrice = 15,
     Recovery = 16,
     ReturnVehicle = 17,
     ReturningToCollectionOrVehicleNotReady = 18,
     Taxi = 19,
     TrainTicket = 20,
     TyreReplacementFitting = 21,
     WaitingTime = 22,
     Other = 255,
}
