import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import {
  FormControlKeys,
  OptionType,
} from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { JobStatisticsFilter } from '../../../../../Models/Reports/Statistics';
import { getDropdownSalesPersonOptions, mapResultsToOptionTypeList } from '../../../../../Utils';
import { useGetGroupsForDropdown } from '../../../Groups/AddGroup/Hooks';
import { useGetAdminUsersForDropdown } from '../../../Jobs/EditJob/Hooks';
import { useGetPartnersForDropdown } from '../../../Members/EditMember/Tabstrip/Partners/Hooks';
import { StatisticsTypeOptions } from '../Constants';
import { ViewStatisticsFilterKeys } from '../Types';

const FilterBox: React.FC<FilterBoxProps> = (props) => {

  const { onSearch, onClearSearch } = props;
  const initialValues: JobStatisticsFilter = { StatisticsType: 1, };
  const [partnersForDropdown, setPartnersForDropdown] = useState<OptionType[]>([]);
  const [groupsForDropdown, setGroupsForDropdown] = useState<OptionType[]>([]);

  /** Methods */
  const onSubmit = (values: JobStatisticsFilter) => {
    onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const { handleSubmit, resetForm } = formik;
  let { values } = formik;

  const handleClearSearch = () => {
    resetForm();
    values = initialValues;
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  /** Query Hooks */
  const { data: adminUsersForDropdown } = useGetAdminUsersForDropdown();
  const { data: partnersForDropdownData } = useGetPartnersForDropdown();
  const { data: groupsForDropdownData } = useGetGroupsForDropdown();

  useEffect(() => {
    if (partnersForDropdownData) {
      var optionTypeList = mapResultsToOptionTypeList(partnersForDropdownData.PartnersDetails, {
        labelKeyString: "Name",
        valueKeyString: "Id",
      });
      if (optionTypeList.length) {
        const partners: OptionType[] = [];
        optionTypeList.forEach((x) => {
          partners.push({ label: x.label, value: x.value })
        });
        partners.splice(0, 0, { value: '', label: 'All Partners' });
        partners.splice(1, 0, {
          value: '0',
          label: 'Partner Jobs',
        });
        setPartnersForDropdown(partners);
      }
    }
  }, [partnersForDropdownData]);

  useEffect(() => {
    if (groupsForDropdownData) {
      var optionTypeList = mapResultsToOptionTypeList(groupsForDropdownData, {
        labelKeyString: "label",
        valueKeyString: "value",
      });

      if (optionTypeList.length) {
        const groups: OptionType[] = [];
        optionTypeList.forEach((x) => {
          groups.push({ label: x.label, value: x.value })
        });

        groups.splice(0, 0, { value: '', label: 'All Groups' });
        setGroupsForDropdown(groups);
      }
    }
  }, [groupsForDropdownData]);

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.FromDate}
                    label="From Date"
                    control={FormControlKeys.DateInput}
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.ToDate}
                    label="To Date"
                    control={FormControlKeys.DateInput}
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.AdminUserID}
                    label="Sales Person"
                    control={FormControlKeys.Select}
                    options={getDropdownSalesPersonOptions(
                      adminUsersForDropdown?.Results || []
                    )}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.GroupID}
                    label="Group"
                    control={FormControlKeys.Select}
                    options={groupsForDropdown}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.PartnerID}
                    label="Partner"
                    control={FormControlKeys.Select}
                    options={partnersForDropdown}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={ViewStatisticsFilterKeys.StatisticsType}
                    label="Show Type"
                    control={FormControlKeys.RadioGroup}
                    options={StatisticsTypeOptions}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      type="reset"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;

export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
}
