import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  DivDisplayRight,
  FormContainer,
} from '../../../../../Components/Formik/StyledComponents';
import {
  TasksGridFilter,
  TasksGridFilterKeys
} from '../../../../../Models/Tasks/TasksGridFilter';
import { InProgressTypeOption, SearchTypeOptions, StatusTypeOption, TaskTypeOptions } from '../Constants';
import classes from './index.module.css'
import { getDropdownAdminUsersForTaskAssignementOptions } from '../../../../../Utils';
import { useGetAdminUsersForTaskListDropdown } from '../../AddEditTask/Hooks';
import { createValidationSchema } from './validation';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch } = props;
  const initialValues: TasksGridFilter = {};

  /** Query Hook */
  const {
    data: getAdminUsersForTaskListDropdownData,
  } = useGetAdminUsersForTaskListDropdown(true);

  /** Methods */
  const onSubmit = (values: TasksGridFilter) => {
    formik.validateForm();
    if (formik.isValid) onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: () => createValidationSchema(formik.values.SearchType ?? 0)
  });

  const { handleSubmit, resetForm } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.SearchType}
                    label="Search Type"
                    control={FormControlKeys.Select}
                    options={SearchTypeOptions}
                  />
                </Col>

                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.SearchTerm}
                    label="Search Term"
                    control={FormControlKeys.LabelledInput}
                  />
                </Col>

                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.TaskType}
                    label="Task Type"
                    control={FormControlKeys.Select}
                    options={TaskTypeOptions}
                  />
                </Col>
                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.AssignedTo}
                    label="Assigned To"
                    control={FormControlKeys.Select}
                    options={getDropdownAdminUsersForTaskAssignementOptions(
                      getAdminUsersForTaskListDropdownData?.AdminUsersList || [], true)}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.CreatedBetweenStart}
                    label="Created Between Start"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.CreatedBetweenEnd}
                    label="Created Between End"
                    type="date"
                  />
                </Col>
                
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.DueBetweenStart}
                    label="Due Between Start"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.DueBetweenEnd}
                    label="Due Between End"
                    type="date"
                  />
                </Col>
                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.Status}
                    label="Status"
                    control={FormControlKeys.Select}
                    options={StatusTypeOption}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.LastStatusChangedStart}
                    label="Last Status Changed Start"
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={TasksGridFilterKeys.LastStatusChangedEnd}
                    label="Last Status Changed End"
                    type="date"
                  />
                </Col>
                <Col>
                <FormikControl
                    name={TasksGridFilterKeys.InProgress}
                    label="In Progress"
                    control={FormControlKeys.Select}
                    options={InProgressTypeOption}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <DivDisplayRight>
              <Col></Col>
                <Col sm='auto'>
                  <div>
                    <FormikControl
                      name="Submit"
                      label="Submit"
                      type="submit"
                      className="btn btn-primary"
                      control={FormControlKeys.Button}
                    />
                    <span className={classes.btn_filter}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      type="reset"
                      className="btn btn-light"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                    <span className={classes.btn_filter}></span>
                  </div>
                </Col>
              </DivDisplayRight>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;

export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
}
