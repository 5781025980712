import { Tooltip } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  DrivenServiceIcon,
  TransportedServiceIcon,
} from "../../../../../Assets/Images";
import {
  Alert,
  ConfirmDialog,
  ConfirmDialogProps,
  ModalDialog,
  ModalDialogProps,
  useAlert,
} from "../../../../../Components";
import { FormControlKeys } from "../../../../../Components/FormControls";
import {
  FieldSetHeader,
  FormContainer,
  HoverableLink,
} from "../../../../../Components/Formik";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import {
  JobsVehicle,
  JobViewModel,
  ModifiedQuoteModel,
  QuoteModel,
  DeleteQuoteModel,
  AcceptQuoteModel,
} from "../../../../../Models/Jobs/EditJob";
import { AddJobVehicleModel } from "../../../../../Models/Jobs/EditJob/AddJobVehicleModel";
import {
  ConfirmDialogContent,
  DurationString,
  expiresInMilliseconds,
  getFormattedDateWithYear,
  isExpired,
  lookupEnumByVal,
  ReFormatUkDateTimeExpiry,
} from "../../../../../Utils";
import {
  JobStatus,
  JobType,
  MemberType,
  PaymentMethod,
  QuoteStatus,
  ServiceType,
} from "../../../../../Utils/Enums";
import { QuoteStatusOptions } from "../../../Members/Constants";
import { useDeleteQuote } from "../Hooks";
import { usePutAcceptQuote } from "../Hooks/usePutAcceptQuote";
import { PlacedQuotes } from "../Types";
import {
  calcDealerAmount,
  calcDealerAmountChanged,
  calcDealerFeeOriginal,
  RoundToPlaces,
} from "./CalcFunctions";
import { SpanItem, SupplierLabelInfo } from "../../../../../Components/UI";
import VehiclesModule from "./Modules/VehiclesModule";
import { useGetMembersForDropdown } from "../../../Members/EditMember/Tabstrip/MemberBlocks/Hooks";
import { getUsernameById } from "./helper";
import { RouteConstants } from "../../../../../Routes";
import { QuoteExpiryTab } from "./QuoteExpiryTab";
import QuoteExpirationCell from "./Modules/QuoteExpirationCell";

const SectionPlacedQuotesAcceptedFixedPrices: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { job, quotes, vehicles, showAlert, refetchJob } = props;
  const { alert, setAlert, handleAlertClose } = useAlert();

  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };
  const handleModalClose = () => {
    setModalContent({ ...modalContent, show: false });
  };

  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: "",
      body: "",
      cancelCallback: onConfirmDialogClose,
    });
  const [modalContent, setModalContent] = useState<ModalDialogProps>({
    header: "",
    body: "",
    show: false,
    closeCallback: handleModalClose,
  });

  const [newQuotesList, setNewQuotesList] = useState<ModifiedQuoteModel[]>(
    {} as ModifiedQuoteModel[]
  );
  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
  const [isQuotesReady, setIsQuotesReady] = useState<boolean>(false);
  const [acceptVehiclesModule, setAcceptVehiclesModule] = useState<any>({});
  const [isFuelInclusive, setIsFuelIncusive] = useState<boolean>(false);
  const [isFuelRequired, setIsFuelRequired] = useState<boolean>(false);
  const [jobDetails, setJobDetails] = useState<JobViewModel>(job);
  const [quoteExpiry, setQuoteExpiry] = useState<QuoteExpiryTab[]>([]);
  const [isJobGuaranteedPrice, setJobIsGuaranteedPrice] = useState(false);
  const [isJobCompletedPrice, setJobIsCompletedPrice] = useState(false);
  const [isJobBookedPrice, setJobIsBookedPrice] = useState(false);
  const [isJobFeeDeferred, setJobIsDeferred] = useState(false);
  const [isJobPendingQuote, setJobIsPendingQuote] = useState(false);
  const [isJobPaymentPrePayMethod, setJobPaymentIsPrePayMethod] = useState(false);
  const [acceptedQuoteId, setAcceptedQuoteId] = useState<number>(0);

  /** Query Hooks */
  const {
    mutate: deleteQuote,
    data: responseQuoteDeleted,
    error: errorQuoteDeleted,
  } = useDeleteQuote();

  const {
    mutate: putAcceptQuote,
    data: responseAcceptQuote,
    error: errorAcceptQuote,
    isLoading: isLoadingAcceptQuote,    
  } = usePutAcceptQuote();

  const { 
    data: memberList = [],
    error: errorMemberList,
  } = useGetMembersForDropdown(MemberType.TransportCompany);

  /** uesEffect */
  useEffect(() => {
    if (job) {
      if (jobDetails.Driven_FuelRequired !== null)
        setIsFuelIncusive(true);

      if (jobDetails.Driven_FuelRequired === true)
        setIsFuelRequired(true);

      if (jobDetails.JobType === JobType.GuaranteedPrice)
        setJobIsGuaranteedPrice(true);

      if (jobDetails.JobStatus === JobStatus.CompletedJob)
        setJobIsCompletedPrice(true);

      if (jobDetails.JobStatus === JobStatus.BookedJob)
        setJobIsBookedPrice(true);

      if (jobDetails.JobStatus === JobStatus.PendingQuotes)
        setJobIsPendingQuote(true);

      if (jobDetails.FeesDeferred === true) setJobIsDeferred(true);

      if (jobDetails.PaymentMethod === PaymentMethod.PrePay)
        setJobPaymentIsPrePayMethod(true);
    }
  }, [job, jobDetails]);
  
  useEffect(() => {
    updateQuoteExpiryInfo();
  }, [quotes]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      updateQuoteExpiryInfo();
    }, 5000);
    return () => clearInterval(timer);
  }, [quoteExpiry]);
  
  useEffect(() => {
    setIsQuotesReady(true);
  }, [quotes]);

  useEffect(() => {
    calculateQuotesAdditionalValues();
  }, [isQuotesReady, quotes]);

  useEffect(() => {
    /** updating dealerPays column */
    if (newQuotesList.length > 0) {
      newQuotesList.forEach((quote) => {
        setFieldValue(PlacedQuotes.DealerPays + quote.Id, quote.DealerAmount);
      });
    }
  }, [newQuotesList, quotes]);

  useEffect(() => {
    if (responseQuoteDeleted) {
      setAlert({
        ...alert,
        show: true,
        header: "Quote Deleted",
        body: "Success! The quote has been deleted.",
        closeCallback: () => {
          handleAlertClose();
          refetchJob();
        }
      });
    }
  }, [responseQuoteDeleted]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      footer: getReviveFooter(),
    });
  }, [acceptVehiclesModule]);

  useEffect(() => {
    if (responseAcceptQuote) {
      setAlert({
        ...alert,
        show: true,
        header: "Quote Accepted",
        body: "Success! The quote has been accepted.",
        closeCallback: () => {
          handleAlertClose();
          refetchJob();
        }
      });
      refetchJob();
    }
  }, [responseAcceptQuote]);

  useEffect(() => {
    setJobDetails(job);
  }, [job]);

  useEffect(() => {
    if (errorQuoteDeleted) {
      showAlert(errorQuoteDeleted?.Subject, errorQuoteDeleted?.Description);
    } else if (errorAcceptQuote) {
      showAlert(errorAcceptQuote?.Subject, errorAcceptQuote?.Description);
    } else if (errorMemberList) {
      showAlert(errorMemberList?.Subject, errorMemberList?.Description);
    }
  }, [errorQuoteDeleted, errorAcceptQuote, errorMemberList]);

  /** Methods */
  const formik = useFormik({
    initialValues: { DealerPays: -1 },
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const updateQuoteExpiryInfo = useCallback(() => {
    const updatedQuoteExpiry: QuoteExpiryTab[] = [];
    if (quotes.length > 0) {
      quotes.forEach((quote) => {
        if (expiresInMilliseconds(quote.ExpirationDate) > 0) {
          updatedQuoteExpiry.push({
            quoteId: quote.Id,
            expiryInfo: DurationString(expiresInMilliseconds(quote.ExpirationDate)),
          });
        }
      });
    }
    setQuoteExpiry(updatedQuoteExpiry);    
  }, [quotes]);
  
  const calculateQuotesAdditionalValues = () => {
    let updatedQuotes: ModifiedQuoteModel[] = {} as ModifiedQuoteModel[];
    updatedQuotes = quotes;
    updatedQuotes.forEach((quote) => {
      let quoteAmount = Number(quote.Amount);
      let requestingMemberFee = Number(quote.RequestingMemberFee);
      let requestingMemberFeeOriginal = Number(
        quote.RequestingMemberFeeOriginal
      );
      let requestingMemberFeeDeferred = Number(
        quote.RequestingMemberFeeDeferred
      );
      let requestingMemberFeeDeferredOriginal = Number(
        quote.RequestingMemberFeeDeferredOriginal
      );
      let dealerAmountOriginal = RoundToPlaces(
        quoteAmount + (isJobFeeDeferred ? 0 : requestingMemberFee),
        2
      );
      quote.DealerAmount = dealerAmountOriginal;
      quote.DealerAmountChanged = calcDealerAmountChanged(
        quote.DealerAmount,
        dealerAmountOriginal
      );
      quote.DealerFee = calcDealerAmount(
        isJobFeeDeferred,
        quote.DealerAmount,
        requestingMemberFeeDeferred,
        quoteAmount
      );
      quote.DealerFeeOriginal = calcDealerFeeOriginal(
        isJobFeeDeferred,
        requestingMemberFeeDeferredOriginal,
        requestingMemberFeeOriginal
      );
      quote.QuoteStatus = quote.QuoteStatus;
      //TODO Set expiry countdown
    });
    setNewQuotesList(updatedQuotes);
  };

  const onDeleteSavedData = (quoteId: number) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeleteMarkQuote.Title,
      body: ConfirmDialogContent.DeleteMarkQuote.Content,
      show: true,
      confirmCallback: () => {
        let payload = { quoteID: quoteId } as DeleteQuoteModel;
        deleteQuote(payload);
        onConfirmDialogClose();
      },
    });
  };

  const pullUpdatedVehiclesInfo = (updatedVehiclesFromModule: any) => {
    setAcceptVehiclesModule(updatedVehiclesFromModule);
  };

  const getVehiclesModuleBody = () => (
    <VehiclesModule func={pullUpdatedVehiclesInfo} vehicles={vehicles} />
  );

  const handlePutAcceptQuote = () => {
    let newVehiclesValues: AddJobVehicleModel[] = [];

    (acceptVehiclesModule.vehicles).forEach((vehicle: any) => {
      let newVehicle: AddJobVehicleModel = {
        ID: vehicle.Id,
        JobID: vehicle.JobId,
        VRM: vehicle.Vrm,
        VIN: vehicle.Vin,
        VehicleType: vehicle.VehicleType,
        VehicleMake: vehicle.VehicleMake,
        VehicleModel: vehicle.VehicleModel,
        VehicleYear: vehicle.VehicleYear,
        VehicleColour: vehicle.VehicleColour,
        VehicleLength: vehicle.VehicleLength,
        VehicleWidth: vehicle.VehicleWidth,
        VehicleHeight: vehicle.VehicleHeight,
        VehicleWeight: vehicle.VehicleWeight,
        VehicleIsNew: vehicle.VehicleIsNew,
        VehicleValue: vehicle.VehicleValue,
        HasValidMOT: null,
        IsReturnVehicle: vehicle.IsReturnVehicle,
        IsSegmentVehicle: vehicle.IsSegmentVehicle,
        SegmentIndex: vehicle.SegmentIndex,
        FuelTypeID: vehicle.FuelTypeID,
      }

      newVehiclesValues.push(newVehicle);
    });

    let payload = {
      QuoteID: acceptedQuoteId,
      Vehicles: newVehiclesValues,
    } as AcceptQuoteModel;
    putAcceptQuote(payload);
  };

  const getReviveFooter = () => {
    return (
      <FormikProvider value={formik}>
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col>
              <FormikControl
                label="Submit"
                control={FormControlKeys.Button}
                type="submit"
                onClick={() => {
                  setModalContent({ show: false });
                  handlePutAcceptQuote();
                }}
                size="sm"
              />
            </Col>
            <Col>
              <FormikControl
                label="Close"
                control={FormControlKeys.Button}
                size="sm"
                onClick={() => {
                  setAcceptedQuoteId(0);
                  setModalContent({ show: false });
                }}
              />
            </Col>
          </Row>
        </FormContainer>
      </FormikProvider>
    );
  };

  const showVehiclesModule = () => {
    setModalContent({
      ...modalContent,
      show: true,
      header: "Accept",
      body: getVehiclesModuleBody(),
      footer: getReviveFooter(),
    });
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Alert {...alert} />
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <ModalDialog {...modalContent}></ModalDialog>

            <FieldSetHeader>
              Placed Quotes / Accepted Fixed Prices
            </FieldSetHeader>
            <ConfirmDialog {...confirmDialogContent} />
            <table id='id_table_placed_quotes_accepted_fixed_prices' className="table table-bordered table-condensed table-striped">
              <thead>
                <tr>
                  <td></td>
                  <td>Supplier</td>
                  <td>Type</td>
                  <td>Price</td>
                  <td>Fuel</td>
                  <td>Dealer Pays</td>
                  <td>Margin</td>
                  <td>Collect</td>
                  <td>Deliver</td>
                  {(isJobPendingQuote || isJobBookedPrice) && (
                    <td>Expiry</td>
                  )}
                  <td>Note</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {isWaitingData && <tr>Loading...</tr>}
                {newQuotesList?.length > 0 &&
                  newQuotesList.map(
                    (quote: ModifiedQuoteModel, index: number) => {
                      const {
                        Id,
                        SupplyingMemberId,
                        QuoteType,
                        Amount,
                        RequestingMemberFee,
                        CollectionDate,
                        DeliveryDate,
                        ExpirationDate,
                        AdditionalNotes,
                        VATAdditional,
                        RequestingMemberFeeDeferred,
                        RequestingMemberDiscount,
                        DealerFee,
                        DealerFeeOriginal,
                        QuoteStatus: Status,
                      } = quote;
                      return (
                        <tr>
                          <td>{++index}</td>
                          <td>
                          <HoverableLink to={`${RouteConstants.EditMember}/${SupplyingMemberId}`} target="_blank">
                            <SupplierLabelInfo>
                              {getUsernameById(memberList, SupplyingMemberId.toString())}
                            </SupplierLabelInfo>
                          </HoverableLink>
                          </td>
                          <td>
                            {QuoteType === ServiceType.Driven && (
                              <Tooltip title="Driven">
                                <img alt="Driven" src={DrivenServiceIcon} />
                              </Tooltip>
                            )}
                            {QuoteType === ServiceType.Transported && (
                              <Tooltip title="Transported">
                                <img
                                  alt="Transported"
                                  src={TransportedServiceIcon}
                                />
                              </Tooltip>
                            )}
                          </td>
                          <td>
                            <span title="Transporter Quote">
                              {"£" +
                                Number(Amount).toFixed(2) +
                                (VATAdditional ? " + VAT" : "")}
                            </span>
                            <br />
                            <span title="Dealer Price" style={{ color: "red" }}>
                              {"£" +
                                (
                                  Number(Amount) + Number(RequestingMemberFee)
                                ).toFixed(2) +
                                (VATAdditional ? " + VAT" : "")}
                            </span>

                            {RequestingMemberFeeDeferred !== "" && (
                              <>
                                <br />
                                <span title="Dealer Price (including deferred fee)">
                                  {"£" +
                                    (
                                      Number(Amount) +
                                      Number(RequestingMemberFeeDeferred)
                                    ).toFixed(2) +
                                    (VATAdditional ? " + VAT" : "")}
                                </span>
                              </>
                            )}
                            {RequestingMemberDiscount !== 0 && (
                              <div>
                                <br />
                                <span title="Dealer Discounted Price">
                                  {"£" +
                                    (
                                      Number(Amount) +
                                      Number(RequestingMemberFee) -
                                      Number(RequestingMemberDiscount)
                                    ).toFixed(2) +
                                    (VATAdditional ? " + VAT" : "")}
                                </span>
                              </div>
                            )}
                          </td>
                          <td>
                            {isFuelInclusive && (
                              <span>
                                {isFuelRequired ? "Incl Fuel" : "Not Required"}
                              </span>
                            )}
                            {!isFuelInclusive && (
                              <>
                                <br />
                                <span>
                                  {isFuelRequired ? "Included" : "Excluded"}
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <FormikControl
                              style={{ maxWidth: "100px" }}
                              name={PlacedQuotes.DealerPays + Id}
                              control={FormControlKeys.InputGroup}
                              text="£"
                              disabled={
                                isJobGuaranteedPrice ||
                                isJobCompletedPrice ||
                                isJobBookedPrice
                              }
                              onChange={(e: any) => {
                                formik.setFieldValue(
                                  PlacedQuotes.DealerPays + Id,
                                  e.target.value
                                );
                                let newDealerFee = calcDealerAmount(
                                  jobDetails?.FeesDeferred === true,
                                  e.target.value,
                                  Number(RequestingMemberFeeDeferred),
                                  Amount
                                );
                                quote.DealerFee = newDealerFee;
                              }}
                              min={0}
                            />
                          </td>
                          <td>
                            <span> {"£" + DealerFee?.toFixed(2)} </span>
                            {DealerFee !== DealerFeeOriginal && (
                              <>
                                <br />
                                <span title="Original Margin">
                                  {" "}
                                  {"£" + DealerFeeOriginal?.toFixed(2)}{" "}
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <SpanItem>
                              {getFormattedDateWithYear(CollectionDate)}
                            </SpanItem>
                          </td>
                          <td>
                            <SpanItem>
                              {getFormattedDateWithYear(DeliveryDate)}
                            </SpanItem>
                          </td>
                          {(isJobPendingQuote || isJobBookedPrice) && (
                            <td>
                              <QuoteExpirationCell 
                                expirationDate={ExpirationDate} 
                                quote={quote} 
                                quoteExpiry={quoteExpiry} />
                            </td>
                          )}
                          <td>
                            {AdditionalNotes && AdditionalNotes?.length > 1 && (
                              <>
                                <Tooltip title={AdditionalNotes}>
                                  <label>View Mark</label>
                                </Tooltip>
                                {quote.QuoteStatus === QuoteStatus.Placed && (
                                  <FormikControl
                                    name={PlacedQuotes.MarkRead + index}
                                    control={FormControlKeys.CheckboxReversed}
                                    label={"Mark Read"}
                                    size="sm"
                                  />
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            {lookupEnumByVal(
                              QuoteStatusOptions,
                              Status.toString()
                            )}
                          </td>
                          <td>
                            {quote.QuoteStatus === QuoteStatus.Placed && (
                              <>
                                {!isJobGuaranteedPrice && (
                                  <>
                                    {isJobPaymentPrePayMethod && (
                                      <FormikControl
                                        control={FormControlKeys.Button}
                                        label="Update"
                                        size="sm"
                                        onClick={() => {}}
                                      ></FormikControl>
                                    )}
                                    {!isJobPaymentPrePayMethod && !isExpired(ExpirationDate) && (
                                      <FormikControl
                                        control={FormControlKeys.Button}
                                        label= { isLoadingAcceptQuote ? "Loading..." : "Accept" }
                                        size="sm"
                                        onClick={() => {
                                          setAcceptedQuoteId(Id);
                                          showVehiclesModule();
                                        }}
                                      ></FormikControl>
                                    )}
                                  </>
                                )}
                                {isJobGuaranteedPrice && !isExpired(ExpirationDate) && (
                                  <FormikControl
                                    control={FormControlKeys.Button}
                                    label={ isLoadingAcceptQuote ? "Loading..." : "Accept" }
                                    size="sm"
                                    onClick={() => {
                                      setAcceptedQuoteId(Id);
                                      showVehiclesModule();
                                    }}
                                  ></FormikControl>
                                )}
                              </>
                            )}
                            {quote.QuoteStatus === QuoteStatus.Accepted && (
                              <>
                                <FormikControl
                                  control={FormControlKeys.Button}
                                  label="CONFIRM"
                                  size="sm"
                                  onClick={() => {}}
                                ></FormikControl>
                                <FormikControl
                                  control={FormControlKeys.Button}
                                  label="DECLINE"
                                  size="sm"
                                  onClick={() => {}}
                                ></FormikControl>
                              </>
                            )}
                            {(quote.QuoteStatus === QuoteStatus.Placed ||
                              quote.QuoteStatus === QuoteStatus.Accepted) && (
                              <FormikControl
                                control={FormControlKeys.Button}
                                label="Delete"
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  onDeleteSavedData(Id);
                                }}
                              ></FormikControl>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  job: JobViewModel;
  quotes: QuoteModel[];
  vehicles: JobsVehicle[];
  showAlert: Function;
  refetchJob: Function;
};

export default SectionPlacedQuotesAcceptedFixedPrices;