import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { MemberPartnersResponseModel } from '../../../../../../../Models/Members/EditMember';

export const useGetPartnersForDropdown = (
  options?: any
): UseQueryResult<MemberPartnersResponseModel, any> => {

  const getPartners = () => {
    return agent.members.getPartnersForDropdown()
  };

  return useQuery('GetPartnersForDropdown', getPartners, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
