import axios from 'axios';
import { AddTaskAttachmentsRequest, UpdateTaskStatusRequest } from '../../../Models/Tasks';
import { AddActivityNoteRequest } from '../../../Models/Tasks/AddActivityNoteRequest';
import { CreateAdditionalCostsTaskRequest } from '../../../Models/Tasks/CreateAdditionalCostTaskRequest';
import { TaskRequestParam } from '../../../Models/Tasks/Request';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
     GetTaskList_URL,
     GetTaskDetail_URL,
     PutAssignTask_URL,
     PostAddAdditionalCostsTask_URL,
     PostAddActivityNote_URL,
     PostAddTaskAttachments_URL,
     PutUpdateTaskStatus_URL,
     GetTaskAttachmentFile_URL,
     GetAdminUsersForTaskListDropdown_URL,
     GetJobSummaryDetails_URL,
     DeleteTaskAttachment_URL,
} = ApiURLConstants;

export const adminTasks = {
     getTasks: (params: TaskRequestParam): Promise<any> => {
          return axios.post(GetTaskList_URL, params, jsonConfig).then(responseBody);
     },
     getTaskDetails: (taskID: number): Promise<any> => {
          return axios.get(`${GetTaskDetail_URL}?taskId=${taskID}`).then(responseBody);
     },
     getTaskAttachmentsFile: (taskAttachmentId: number): Promise<{data: ArrayBuffer, contentType: string}> => {
          return axios.get(`${GetTaskAttachmentFile_URL}?taskAttachmentId=${taskAttachmentId}`, { 
              responseType: 'arraybuffer'
          })
          .then(response => {
              const contentType = response.headers['content-type'];
              return { data: response.data, contentType };
          });
      },
     getAdminUsersForTaskListDropdown: (activeAccountsOnly: boolean): Promise<any> => {
          return axios.get(`${GetAdminUsersForTaskListDropdown_URL}?activeAccountsOnly=${activeAccountsOnly}`).then(responseBody);
     },
     putAssignTaskDetails: (params: any): Promise<any> => {
          return axios.put(`${PutAssignTask_URL}`, params, jsonConfig).then(responseBody);
     },
     postAddAdditionalCostsTask: (params: CreateAdditionalCostsTaskRequest): Promise<any> => {
          return axios.post(PostAddAdditionalCostsTask_URL, params, jsonConfig).then(responseBody);
     },
     postAddActivityNote: (params: AddActivityNoteRequest): Promise<any> => {
          return axios.post(PostAddActivityNote_URL, params, jsonConfig).then(responseBody);
     },
     postAddTaskAttachments: (params: AddTaskAttachmentsRequest): Promise<any> => {
          return axios.post(PostAddTaskAttachments_URL, params, jsonConfig).then(responseBody);
     },
     putUpdateTaskStatus: (params: UpdateTaskStatusRequest): Promise<any> => {
          return axios.put(PutUpdateTaskStatus_URL, params, jsonConfig).then(responseBody);
     },
     deleteTaskAttachment: (taskId: number): Promise<any> => {
          return axios.delete(`${DeleteTaskAttachment_URL}?taskAttachmentId=${taskId}`).then(responseBody);
     },
     getSummaryDetails: (jobId: number): Promise<any> => {
          return axios.get(`${GetJobSummaryDetails_URL}?jobId=${jobId}`).then(responseBody);
     },
};
