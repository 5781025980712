export enum InvoiceType {
    AdHocInvoice = 0,
    AdHocCreditNote = 1,
    DeferredFeesInvoice = 10,
    rm_PrePaidInvoice = 50,
    rm_JobCompletionNotification = 51,
    rm_MonthlyInvoice = 52,
    rm_WeeklyInvoice = 53,
    rm_FortnightlyInvoice = 54,
    rm_PayNowInvoice = 55,
    rm_PayNowReceipt = 56,
    sm_JobInvoice = 100,
    sm_MonthlyInvoice = 101
}