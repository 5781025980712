import React, { useEffect } from 'react';
import ScrollToNavbar from './ScrollToNavbar';
import Tabstrip from './Tabstrip';
import UsefulInfoAndQuickLinks from './Tabstrip/UsefulInfoAndQuickLinks';
import './index.css';
import { Alert, Loading, renderHeader, useAlert } from '../../../../Components';
import { useGetMember } from './Tabstrip/General/Hooks';

const EditMember: React.FC<EditMemberType> = (): JSX.Element => {
  
  /** Hooks */
  const {alert, setAlert} = useAlert();
  const { 
    isLoading : getMemberIsLoading, 
    error : getMembersError 
  } = useGetMember();

  /** Effects */
  useEffect(() => {
    if (getMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getMembersError?.Subject,
        body: getMembersError?.Description,
      });
    }
  }, [getMembersError]);
  
  return (
    <div>
      <Alert {...alert} />
      {renderHeader('Edit Member')}
      <div className="container-fluid add-member-outer-container">
        <div className="row-fluid">
          <ScrollToNavbar />
          <div className="span8 offset2">
            <Loading loading={getMemberIsLoading} />
            <UsefulInfoAndQuickLinks />
            <br />
            <Tabstrip />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMember;

type EditMemberType = {};

export type MemberIDParamType = {
  memberID: string;
};
