import { Divider } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { NavDropdown } from 'react-bootstrap';
import { DropdownAdminUsers } from '../../Models/Jobs/EditJob';
import { DropdownAdminUsersResponse } from '../../Models/Jobs/EditJob/DropdownAdminUsersResponse';
import { InlineBlockDiv } from '../Formik/StyledComponents';

interface ControlledPaginationProps {
     currentPage: number;
     filter?: React.ReactNode
     itemsCount?: number;
     pageSize: number;
     totalPages?: number;
     onPageChange: any;
     onRowsPerPageChange: any;
     /* Jobs assign feature */
     assignJobsToUser?: Function;
     adminUsers?: DropdownAdminUsersResponse;
};

export function ControlledPagination({
     assignJobsToUser,
     adminUsers,
     currentPage,
     pageSize,
     itemsCount = 0,
     totalPages = 0,
     onPageChange,
     onRowsPerPageChange,
     filter
}: ControlledPaginationProps) {
     return (
          <div style={{ margin: 10 }}>
               {' '}
               {adminUsers && (
                    <InlineBlockDiv>
                         <NavDropdown className='jobGridButtoForAssigningJobsDiv' title='Assign'>
                              {adminUsers?.Results?.map((user: DropdownAdminUsers) => (
                                   <NavDropdown.Item
                                        key={user.Id}
                                        onClick={
                                             assignJobsToUser
                                                  ? () => assignJobsToUser(user)
                                                  : undefined
                                        }
                                   >
                                        {user.DisplayName}{' '}
                                   </NavDropdown.Item>
                              ))}
                         </NavDropdown>
                    </InlineBlockDiv>
               )}
               <InlineBlockDiv>
                    <label>
                         Page <span className='movex-color'>{currentPage}</span> of
                         <span className='movex-color'> {itemsCount}</span> items
                    </label>
               </InlineBlockDiv>
               <InlineBlockDiv
                    style={{
                         marginLeft: 'auto',
                         float: 'right',
                         marginTop: -5,
                         marginBottom: 6,
                    }}
               >
                    <InlineBlockDiv>
                         <Stack
                              direction='row'
                              spacing={10}
                              divider={<Divider orientation='vertical' flexItem />}
                         >
                              <Pagination
                                   color='primary'
                                   shape='rounded'
                                   variant='outlined'
                                   count={totalPages}
                                   page={currentPage}
                                   onChange={onPageChange}
                                   boundaryCount={1}
                                   showFirstButton
                                   showLastButton
                              />
                         </Stack>
                    </InlineBlockDiv>
                    <span style={{ marginRight: 10 }} />
                    <InlineBlockDiv>
                         <select
                              className='form-select'
                              name='SelectPageSize'
                              onChange={onRowsPerPageChange}
                              value={pageSize}
                         >
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                         </select>
                    </InlineBlockDiv>
               </InlineBlockDiv>
               {filter &&
                    <InlineBlockDiv
                         style={{
                              float: 'right',
                              margin: '-5px 40px 5px 0',
                              paddingTop: 3,
                         }}
                    >
                         {filter}
                    </InlineBlockDiv>}
          </div>
     );
};