import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const useDeleteMemberBlocks = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number[]> => {

  const deleteMemberBlocks = (membersBlockedIDs: number[]) => {
    return agent.members.deleteMemberBlocks(membersBlockedIDs);
  };

  return useMutation(deleteMemberBlocks);
};
