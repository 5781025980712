import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, ShowPleaseWait, useAlert } from '../../../../Components';
import {
  JobActivityHistoryFilter,
  JobActivityHistoryItemKeys,
  JobActivityHistoryRequestParam,
} from '../../../../Models/Jobs/JobActivityHistory';
import { Pager } from '../../../../Types';
import {
  downloadFile,
  getSorterFromSortModel,
  MAX_VALUE,
  Page,
} from '../../../../Utils';
import FilterBox from './FilterBox';
import GridView from './Grid';
import {
  useGetJobsActivityHistoryList,
  useExportJobsActivityHistoryToCsv,
} from './Hooks';
import { useLocation } from 'react-router-dom';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';

const JobActivityHistory: React.FC = (): JSX.Element => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberID = queryParams.get('memberID');

  const rqOption = { enabled: false };
  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<JobActivityHistoryFilter>({});
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize, 
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: JobActivityHistoryItemKeys.Id,
      sort: 'asc',
    },
  ]);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);

  /** Methods */
  const getParams = () => {
    if (memberID) filter.MemberID = parseInt(memberID);
    const params: JobActivityHistoryRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const getExportParams = () => {
    const params: JobActivityHistoryRequestParam = {
      Pager: {
        CurrentPage: 1,
        ItemsPerPage: MAX_VALUE,
      },
      Sorter: { Column: JobActivityHistoryItemKeys.Id, Ascending: true },
      Filter: filter,
    };
    return params;
  };

  const handleSearch = (filter: JobActivityHistoryFilter) => {
    setPageLoaded(true);
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setPageLoaded(true);
    setFilter({});
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
  };

  /** Alerts */
  const { alert, setAlert } = useAlert();

  /** React Query Hooks */
  const {
    isLoading: isGetJobActivityHistoryListLoading,
    data: jobActivityHistoryResponse,
    refetch: fetchActivityHistory,
    error: jobActivityHistoryError,
  } = useGetJobsActivityHistoryList(getParams(), rqOption);
  const isDataLoaded = jobActivityHistoryResponse != null;
  const isResultEmpty = jobActivityHistoryResponse?.Page == null;


  const {
    isLoading: isExportToCsvLoading,
    data: exportJobActivityHistoryResponse,
    refetch: exportToCsv,
    error: exportJobActivityHistoryError,
  } = useExportJobsActivityHistoryToCsv(getExportParams(), rqOption);

  /** useEffects */
  useEffect(() => {
    if (pageLoaded && filter.JobID !== undefined && filter.JobID !== null) {
      fetchActivityHistory();
    }
  }, [pageLoaded, pager, filter, sortModel]);

  useEffect(() => {
    if (memberID) {
      setPageLoaded(true);
      fetchActivityHistory();
    }
  },[memberID])

  useEffect(() => {
    setIsLoading(isGetJobActivityHistoryListLoading);
  }, [isGetJobActivityHistoryListLoading]);

  useEffect(() => {
    setShowPleaseWait(isExportToCsvLoading);
  }, [isExportToCsvLoading]);

  useEffect(() => {
    if (exportJobActivityHistoryResponse) {
      const { FileDownloadName, FileContents } =
        exportJobActivityHistoryResponse;
      downloadFile(FileContents, FileDownloadName);
    }
  }, [exportJobActivityHistoryResponse]);

  useEffect(() => {
    if (jobActivityHistoryError) {
      setAlert({
        ...alert,
        show: true,
        header: jobActivityHistoryError?.Subject,
        body: jobActivityHistoryError?.Description,
      });
    }
  }, [jobActivityHistoryError]);
  
  useEffect(() => {
    if (exportJobActivityHistoryError) {
      setAlert({
        ...alert,
        show: true,
        header: exportJobActivityHistoryError?.Subject,
        body: exportJobActivityHistoryError?.Description,
      });
    }
  }, [exportJobActivityHistoryError]);
  

  return (
    <div>
      <ShowPleaseWait show={showPleaseWait} />
      <Alert {...alert} />
      {renderHeader('Jobs Activity History')}
      <div className="container-fluid">
        <FilterBox
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onExportCSV={exportToCsv}
          showExport={isDataLoaded && jobActivityHistoryResponse?.Page !== null}
        />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        <Loading loading={isLoading} />
        {isDataLoaded && jobActivityHistoryResponse && (
          <GridView
            data={jobActivityHistoryResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default JobActivityHistory;
