import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FormContainer } from '../../../../Components/Formik';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { ErrorDiv } from '../../../../Components/UI/SharedComponents.styles';
import { validateEmail } from '../../../../Utils';

export const EmailForm = ({ udapteEmails }: { udapteEmails: (values: string) => void }) => {
     /** State */
     const [error, setError] = useState(false);
     
     /** Formik */
     const formik = useFormik({
          initialValues: {
               Emails: '',
          },
          onSubmit: (values: any) => {},//not used here, handled by the parent component
     });

     /** Side Effects */
     useEffect(() => {
          const emails = formik.values.Emails.split(',')
              .map((email : string) => email.trim())
              .filter(Boolean); // remove empty strings caused by leading or trailing commas

          if (emails.length === 0) {
              setError(false);
              return;
          }

          const allEmailsValid = emails.every(validateEmail);
          setError(!allEmailsValid);
      
          if (allEmailsValid) {
               //update the parent component with the emails
              udapteEmails(emails.join());
          }
      
      }, [formik.values.Emails]);
      

     return (
          <FormikProvider value={formik}>
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                    }}
               >
                    {error && <ErrorDiv>Please provide valid email addresses only.</ErrorDiv>}
                    <FormikControl
                         name='Emails'
                         label='Enter recipients separated by commas'
                         placeholder='Emails separated by commas.'
                    />
               </FormContainer>
          </FormikProvider>
     );
};
