import React from "react";

export function renderHeader(text: string, amount?: number): JSX.Element {
  return (
    <div className="page-title">
      <h1>{text}</h1>
      {amount && <h1>Absolute Total £{amount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h1>}
    </div>
  );
}
