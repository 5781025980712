import { OptionType } from "../Components/FormControls/Types";

export const UndefinedString = 'undefined'

export const ActionColumnPath = 'action'

export const EnvironmentAPIURL = {
    DEV: 'https://dev-api.movex.co.uk/api',
    TEST: 'https://test-api.movex.co.uk/api',
    STAGE: 'https://stage-api.movex.co.uk/api',
    PROD: 'https://api.movex.co.uk/api'
}

export const EnvironmentImageDownloadURL = {
    DEV: 'https://dev.movex.co.uk',
    TEST: 'https://test.movex.co.uk',
    STAGE: 'https://stage.movex.co.uk',
    PROD: 'https://movex.co.uk'
}

export const ApiHost = "https://dev-api.movex.co.uk/api";

export const documentDownloadURL = "https://dev-api.movex.co.uk/api";

export const Page = { CurrentPage: 1, PageSize: 50 }

export const RowsPerPageOptions: OptionType[] = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '1000', value: 1000 }
]

export const GoogleAPIHost = "https://maps.googleapis.com/";
export const FetchifyAPIHost = "https://pcls1.craftyclicks.co.uk/";

//https://developers.google.com/maps/documentation/geocoding/overview
export const GoogleAPIAddressType =
{
    ROUTE: "route",
    POSTAL_CODE: "postal_code",
    POSTAL_TOWN: "postal_town",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    COUNTRY: "country",
    POLITICAL: "political",
    LOCALITY: "locality"
};

export const GoogleAPIStatus =
{
    OK: "OK",
    ZERO_RESULTS: "ZERO_RESULTS",
    OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
    REQUEST_DENIED: "REQUEST_DENIED",
    INVALID_REQUEST: "INVALID_REQUEST",
    UNKNOWN_ERROR: "UNKNOWN_ERROR"
};

export const MAX_VALUE = 2147483647;

export const ConfirmDialogContent = {
    DeleteMemberBlocked: { Title: 'Remove block from selected members by current member.', Body: 'Are you sure?' },
    DeleteMemberBlocking: { Title: 'Remove block on current member by selected members.', Body: 'Are you sure?' },
    DeleteMemberFavorites: { Title: 'Delete Selected Favourites', Content: 'Are you sure?' },
    DeleteFeedback: { Title: 'Delete Feedback', Content: 'Are you sure?' },
    DeletePartner: { Title: 'Delete Partner', Content: 'Are you sure?' },
    DeletePrice: {Title: 'Delete Price', Content: 'Are you sure?'},
    DeletePriceGroup: {Title: 'Delete PriceGroup', Content: 'Are you sure?'},
    AddMemberFavorite: {Title: 'Add Favourite Member'},
    RemoveMemberFavorite: {Title: 'Remove Favourite Member'},
    EditGroupSavedAddress: { Title: 'Edit Address Details', Content: 'Are you sure?' },
    DeleteGroupSavedAddress: { Title: 'Delete Address Details', Content: 'Are you sure?' },
    EditMarginDetail: { Title: 'Edit Margin', Content: 'Are you sure?' },
    DeleteMarginDetail: { Title: 'Delete requesting member fee fixed details', Content: 'Are you sure?' },
    UpdateDrivenMinimumPrice: { Title: 'Update Driven Minimum Price', Content: 'Are you sure?' },
    UpdateDrivenMaximumPrice: { Title: 'Update Driven Maximum Price', Content: 'Are you sure?' },
    UpdateDrivenGuaranteedPrice: { Title: 'Update Driven Guaranteed Price', Content: 'Are you sure?' },
    UpdateTransportedMinimumPrice: { Title: 'Update Transported Minimum Price', Content: 'Are you sure?' },
    UpdateTransportedMaximumPrice: { Title: 'Update Transported Maximum Price', Content: 'Are you sure?' },
    UpdateTransportedGuaranteedPrice: { Title: 'Update Transported Guaranteed Price', Content: 'Are you sure?' },
    UpdateCodaRefsFile: { Title: "Update Coda Refs' File", Content: "This will update the CODA references for the given members. Are you absolutely sure?" },
    SendSmartDebitFile: { Title: "Send 'Smart Debit' File", Content: "This will send the charge file to Smart Debit.Are you absolutely sure?" },
    UploadMarkInvicesFile: { Title: "Upload 'Mark Invoices' File", Content: "This will attempt to mark the payments & bills for given invoices as paid. Are you absolutely sure?" },
    DeleteMarkQuote: { Title: 'Mark Quote Deleted', Content: 'Are you sure?' },
    ArchivePOC: {Title: 'Archived POC', Content: 'Archived Successfully.'},
    ArchivePOD: {Title: 'Archived POD', Content: 'Archived Successfully.'},
    DeleteSavedSearch: { Title: 'Delete Saved Search', Content: 'Are you sure?' },
}

export const TimeAmPmDropdown: OptionType[] = [
    { label: 'Anytime', value: 0 },
    { label: 'Am', value: 1 },
    { label: 'Pm', value: 2 },
]

export const TimeUnit: OptionType[] = [
    { label: 'Minutes', value: 1 },
    { label: 'Hours', value: 2 },
    { label: 'Days', value: 3 },
]

/**Fee Structure - Version 3 */
export const FeeStructureVersion3 = {
    FeesV3_TransporterFeePercentage: 4,
}

export const TimeToStringCOnvertion = {
    secondsPerYear: 31536000,
    secondsPerDay: 86400,
    secondsPerHour: 3600,
    secondsPerMinute: 60
}