import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';

export const useGetAdminRolesForDropdown = (
  options?: any
): UseQueryResult<any, any> => {

  const getAdminRoles = () => {
    return agent.admin.getAdminRolesForDropdown();
  };

  return useQuery(['GetAdminRolesForDropdown'], getAdminRoles, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
