import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeletePrice = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number> => {

  const deletePrice = (priceID: number) => {
    return agent.prices.deletePrice(priceID);
  };

  return useMutation(deletePrice);
};
