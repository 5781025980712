import { SqlStringMatchType } from "../../../Utils/Enums";
import { VehicleType } from "../../../Utils/Enums/VehicleType";

export type VehiclesFilter = {
    Freetext?: string;
    Type?: VehicleType,
    SqlStringMatchType?: SqlStringMatchType
}

export const VehiclesFilterKeys = {
    Freetext: "Freetext",
    Type: "Type"
}