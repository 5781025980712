import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AcceptQuoteModel } from '../../../../../Models/Jobs/EditJob';
import { AcceptedQuoteResponse } from '../../../../../Models/Jobs/EditJob/AcceptedQuoteResponse';
import { MovexErrorResponse } from '../../../../../Types';

export const usePutAcceptQuote = (
  options?: any
): UseMutationResult<AcceptedQuoteResponse, MovexErrorResponse, AcceptQuoteModel> => {

  const putAcceptQuote = (acceptQuoteModel: AcceptQuoteModel) => {
    return agent.jobs.postAcceptQuote(acceptQuoteModel);
  };

  return useMutation(putAcceptQuote);
};
