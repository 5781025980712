import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useAppDispatch } from '../../../../../Store/hooks';
import { membersActionCreators } from '../../../../../Store/Members/ViewMembers/Action';
import { ApplicationState } from '../../../../../Store/Types';
import CustomConfig from './CustomConfig';
import DealerConfig from './DealerConfig';
import GeneralForm from './General';
import { useGetMember } from './General/Hooks';
import DealerMargin from './Margin';
import MembersBlocks from './MemberBlocks';
import { MemberIDParam } from './MemberBlocks/Types';
import Partners from './Partners';
import { Alert, useAlert } from '../../../../../Components';

const Tabstrip = () => {
     const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch();

     const { memberID: paramMemberID } = useParams<MemberIDParam>();
     const memberID = paramMemberID ? parseInt(paramMemberID) : 0;
     const isEditMode = memberID > 0;

     const toggleGeneralSectionScrollbar = (eventKey: string) => {
          if (eventKey.toLowerCase() === 'general') {
               dispatch(membersActionCreators.showScrollbar(true));
          } else {
               dispatch(membersActionCreators.showScrollbar(false));
          }
     };

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hook */
     const {
          isLoading: isGetMemberLoading,
          data: getMemberResponse,
          error: getMemberError,
          refetch: getMember,
     } = useGetMember({ enabled: isEditMode });

     return (
          <div>
               <Alert {...alert} />
               <Tabs
                    defaultActiveKey='general'
                    id='uncontrolled-tab-example'
                    className='mb-3'
                    onSelect={(e: any) => toggleGeneralSectionScrollbar(e)}
               >
                    <Tab eventKey='general' title='General'>
                         <GeneralForm
                              isGetMemberLoading={isGetMemberLoading}
                              getMemberResponse={getMemberResponse}
                              getMemberError={getMemberError}
                              refetchMember={getMember}
                         />
                    </Tab>
                    <Tab eventKey='customConfig' title='Custom Config'>
                         <CustomConfig />
                    </Tab>
                    <Tab eventKey='dealerConfig' title='Dealer Config'>
                         <DealerConfig />
                    </Tab>
                    <Tab eventKey='membersBlocks' title='Members Blocks'>
                         <MembersBlocks />
                    </Tab>
                    <Tab eventKey='partners' title='Partners'>
                         <Partners />
                    </Tab>
                    <Tab eventKey='margin' title='Margin'>
                         <DealerMargin getMemberResponse={getMemberResponse} />
                    </Tab>
               </Tabs>
          </div>
     );
};

export default Tabstrip;
