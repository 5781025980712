import React from 'react';

export const AboutUs = () => {
  return (
    <div className="about-us-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>ABOUT US</h1>
            <h2>
              HELPLINE{'  '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>About Us</h1>
            <p>
              MOVEX founders have had over 30 years experience in the car
              industry, and have now set up one of the first real-time website
              systems dedicated solely to the collection and delivery of
              vehicles.
            </p>
            <p>
              The MOVEX system has been designed to make a smooth transaction
              between transport providers and customers that need a vehicle
              moved, with a state of art backload section dedicated to filling
              any empty slots on car transporters or return journeys for trade
              plate drivers, automatically giving the customer faster turnaround
              time and a better price as well as given the carrier a return job.
            </p>
            <p>
              MOVEX provides a high quality and highly transparent vehicle
              delivery service by matching vehicles movements to transport
              providers as well as single vehicle movements to individual
              drivers
            </p>
            <p>
              With all businesses focusing on slashing their transport
              emissions, MOVEX backload system is not only contributing to
              improving operating efficiencies across the whole vehicle
              logistics industry, the technology is reducing the environmental
              impact of transporters by eliminating unnecessary miles
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
