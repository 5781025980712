import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetPriceGroupPricesResponse } from '../../../../../Models/PriceGroup';

export const useGetPriceGroupPrices = (
     priceGroupID: number,
     options?: any
): UseQueryResult<GetPriceGroupPricesResponse, any> => {
     const getPriceGroupPrices = () => {
          return agent.priceGroup.getPriceGroupPrices(priceGroupID)
     };

     return useQuery(['GetPriceGroupPrices', priceGroupID], getPriceGroupPrices, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
