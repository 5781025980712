import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddOrUpdatePriceGroupRequest, PriceGroupCreatedOrUpdatedViewModelResponse } from '../../../../../Models/PriceGroup';

export const usePostAddPriceGroup = (
     options?: any
): UseMutationResult<PriceGroupCreatedOrUpdatedViewModelResponse, any, AddOrUpdatePriceGroupRequest> => {
     const updatePriceGroup = (payload: AddOrUpdatePriceGroupRequest) => {
          return agent.priceGroup.postAddPriceGroup(payload);
     };

     return useMutation(updatePriceGroup);
};
