import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert, Loading, ShowPleaseWait, useAlert } from '../../../../Components';
import { FormControlKeys, OptionType } from '../../../../Components/FormControls/Types';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
     DivContainer,
     FieldSetContainer,
     FormContainer,
     Margin20BottomContainer,
} from '../../../../Components/Formik/StyledComponents';
import { AddSavedSearchModel } from '../../../../Models/Searches';
import { RouteConstants } from '../../../../Routes';
import { MemberType, ServiceType } from '../../../../Utils/Enums';
import withAddForm from '../../Common/AddForm/withAddForm';
import { SearchRadiusOptions } from '../../Members/Constants';
import { useGetMembersForDropdown } from '../../Members/EditMember/Tabstrip/MemberBlocks/Hooks';
import { SavedSearchIDParam, SearchesAddEditFormKeys } from '../ViewSearches/Types';
import { useGetSavedSearch, usePostAddSavedSearch, usePutUpdateSavedSearch } from './Hooks';

const AddSearch: React.FC = (): JSX.Element => {
     const { searchID: paramSavedSearchID } = useParams<SavedSearchIDParam>();
     const savedSearchID = paramSavedSearchID ? parseInt(paramSavedSearchID) : 0;
     const isEditMode = savedSearchID > 0;

     const history = useHistory();

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     const validationSchema = Yup.object({
          MemberID: Yup
               .number().required('Member is required')
               .moreThan(0, 'Member is required'),
          SearchName: Yup.string().required('Search name is required'),
          WithinMiles: Yup.string().required('Search radius is required'),
          PostCode: Yup.string().required('Post code is required'),
          MinVehicles: Yup.number()
               .required('Min vehicles is required')
               .moreThan(0, 'Min vehicles must be more than 0'),
          MaxVehicles: Yup.number()
               .required('Max vehicles is required')
               .moreThan(0, 'Max vehicles must be more than 0')
               .min(Yup.ref('MinVehicles'), 'Max vehicles must be greater than or equal to min vehicles'),
    });

     /** useStates */
     const [formikInitialValues, setFormikInitialValues] = useState<any>({
          SearchName: '',
          SelectedMemberID: 0,
          WithinMiles: '',
     });
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);

     /** Functions */
     const submitForm = (payload: AddSavedSearchModel) => {
          if (isFormValid(payload)) {
               //submitForm will trigger validation:
               formik.submitForm();
               return;
          }

          const request: AddSavedSearchModel = { ...payload };
          request.MemberID = formik.values?.MemberID;
          request.ID = request.ID == 0 ? null : request.ID;

          if (isEditMode) {
               putUpdateSavedSearch(request);
          } else {
               postAddSavedSearch(request);
          }
     };

     /** Query Hooks */
     const { data: suppliersForDropdown = [] } = useGetMembersForDropdown(MemberType.TransportCompany);

     const {
          mutate: putUpdateSavedSearch,
          isLoading: isPutUpdateSavedSearchLoading,
          data: updateSavedSearchResponse,
          error: updateSavedSearchError,
     } = usePutUpdateSavedSearch();

     const {
          mutate: postAddSavedSearch,
          isLoading: isPostAddSavedSearchLoading,
          data: addSavedSearchResponse,
          error: addSavedSearchError,
     } = usePostAddSavedSearch();

     const {
          isLoading: isGetSavedSearchLoading,
          data: getSavedSearchResponse,
          error: getSavedSearchError,
     } = useGetSavedSearch(savedSearchID);

     /** useEffects */
     useEffect(() => {
          setIsLoading(isGetSavedSearchLoading);
     }, [isGetSavedSearchLoading]);

     useEffect(() => {
          setShowPleaseWait(isPostAddSavedSearchLoading || isPutUpdateSavedSearchLoading);
     }, [isPostAddSavedSearchLoading, isPutUpdateSavedSearchLoading]);

     useEffect(() => {
          if(savedSearchID === 0){
               setFormikInitialValues(emptyValues);
          }
          else if (getSavedSearchResponse) {
               const serviceType = getSavedSearchResponse.SavedSearchResult.ServiceType;

               if (serviceType == ServiceType.Both) {
                    getSavedSearchResponse.SavedSearchResult.Transported = true;
                    getSavedSearchResponse.SavedSearchResult.Driven = true;
               } else if (serviceType == ServiceType.Driven) {
                    getSavedSearchResponse.SavedSearchResult.Driven = true;
               } else if (serviceType == ServiceType.Transported) {
                    getSavedSearchResponse.SavedSearchResult.Transported = true;
               }

               const retrivedValues = {
                    ...getSavedSearchResponse.SavedSearchResult,
                    MemberID: getSavedSearchResponse.SavedSearchResult.MemberId,
               };

               setFormikInitialValues(retrivedValues);
          }
     }, [getSavedSearchResponse]);

     useEffect(() => {
          if (updateSavedSearchResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'SavedSearch',
                    body: 'Saved search updated successfully',
                    closeCallback: redirectToSavedSearchesGrid(history),
               });
          }
          if (addSavedSearchResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'SavedSearch',
                    body: 'Saved search saved successfully',
                    closeCallback: redirectToSavedSearchesGrid(history),
               });
          }
     }, [updateSavedSearchResponse, addSavedSearchResponse]);

     useEffect(() => {
          if (updateSavedSearchError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: updateSavedSearchError?.Subject,
                    body: updateSavedSearchError?.Description,
               });
          } else if (addSavedSearchError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addSavedSearchError?.Subject,
                    body: addSavedSearchError?.Description,
               });
          } else if (getSavedSearchError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getSavedSearchError?.Subject,
                    body: getSavedSearchError?.Description,
               });
          }
     }, [updateSavedSearchError, addSavedSearchError, getSavedSearchError]);

     const formik = useFormik({
          initialValues: formikInitialValues,
          validationSchema: validationSchema,
          onSubmit: () => {},
          enableReinitialize: true,
     });

     const getTransportCompanyMemberOptions = () => {
          const options: OptionType[] = [];
          suppliersForDropdown.forEach((member) => {
               options.push({
                    value: member.Id,
                    label: member.UserName,
               });
          });
          return options;
     };

     return withAddForm(
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <Loading loading={isLoading} />
               <ShowPleaseWait show={showPleaseWait} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         formik.handleSubmit();
                    }}
               >
                    <FieldSetContainer>
                         <DivContainer>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             control={FormControlKeys.Select}
                                             options={getTransportCompanyMemberOptions()}
                                             label='For Member'
                                             name={SearchesAddEditFormKeys.MemberID}
                                             placeholder='Select'
                                             type='number'
                                        />
                                   </Col>
                                   <Col>
                                        <div className='row'>
                                             <label className='fw-600'>Vehicle Range</label>
                                        </div>
                                        <div className='row'>
                                             <div className='col-md-3'>
                                                  <FormikControl name={SearchesAddEditFormKeys.MinVehicles} type='number' />
                                             </div>{' '}
                                             to{' '}
                                             <div className='col-md-3'>
                                                  <FormikControl name={SearchesAddEditFormKeys.MaxVehicles} type='number' />
                                             </div>
                                        </div>
                                   </Col>
                              </Row>
                         </DivContainer>
                         <DivContainer>
                              <Row>
                                   <Col>
                                        <FormikControl name={SearchesAddEditFormKeys.SearchName} label='Search Name' />
                                   </Col>
                                   <Col>
                                        <div className='mb-3 form-group'>
                                             <div>
                                                  <br />
                                                  <label className='fw-600'>Service Type</label>
                                             </div>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={SearchesAddEditFormKeys.Driven}
                                                  label='Driven'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={SearchesAddEditFormKeys.Transported}
                                                  label='Transported'
                                             />
                                        </div>
                                   </Col>
                              </Row>
                         </DivContainer>
                         <DivContainer>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             control={FormControlKeys.Select}
                                             name={SearchesAddEditFormKeys.WithinMiles}
                                             label='Search Radius'
                                             placeholder='Select'
                                             options={SearchRadiusOptions}
                                        />
                                   </Col>
                                   <Col>
                                        <div className='row'>
                                             <div className='col-md-5'>
                                                  <FormikControl
                                                       name={SearchesAddEditFormKeys.PostLatitude}
                                                       label='Latitude'
                                                       type='number'
                                                       readOnly
                                                  />
                                             </div>
                                             <div className='col-md-5'>
                                                  <FormikControl
                                                       name={SearchesAddEditFormKeys.PostLongitude}
                                                       label='Longitude'
                                                       type='number'
                                                       readOnly
                                                  />
                                             </div>
                                        </div>
                                   </Col>
                              </Row>
                         </DivContainer>
                         <DivContainer>
                              <Row>
                                   <Col>
                                        <FormikControl name={SearchesAddEditFormKeys.PostCode} label='Radius From PostCode' />
                                   </Col>
                                   <Col>
                                        <div className='mb-3 form-group'>
                                             <div>
                                                  <br />
                                                  <label className='fw-600'>Active Status</label>
                                             </div>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={SearchesAddEditFormKeys.SearchActive}
                                                  label='Is Search Active'
                                             />
                                        </div>
                                   </Col>
                              </Row>
                              <Row>
                                   <Col>
                                        <Margin20BottomContainer>
                                             <FormikControl
                                                  control={FormControlKeys.Button}
                                                  name='Submit'
                                                  className='btn btn-primary f-r'
                                                  label={isEditMode ? 'Update Search' : 'Add  aSearch'}
                                                  onClick={() => {
                                                       submitForm(formik.values);
                                                  }}
                                             />
                                        </Margin20BottomContainer>
                                   </Col>
                              </Row>
                         </DivContainer>
                    </FieldSetContainer>
               </FormContainer>
          </FormikProvider>,
          isEditMode ? 'Edit Saved Search' : 'Add Saved Search'
     );
};

export default AddSearch;

const emptyValues: AddSavedSearchModel = {
     ID: 0,
     MemberID: 0,
     SearchName: '',
     SelectedMemberID: 0,
     WithinMiles: undefined,
     PostCode: '',
     PostLatitude: 0,
     PostLongitude: 0,
     MinVehicles: 1,
     MaxVehicles: 1,
     Driven: false,
     Transported: false,
     SearchActive: false,
     ServiceType: ServiceType.Unknown,
};

function isFormValid(payload: AddSavedSearchModel) {
     return (
          payload.MemberID == null ||
          payload.SearchName == null ||
          payload.WithinMiles == null ||
          payload.PostCode == null ||
          payload.MinVehicles < 1 ||
          payload.MaxVehicles < 1
     );
}

function redirectToSavedSearchesGrid(history: any): () => void | Function {
     return () => {
          history.push(RouteConstants.ViewSearches);
     };
}
