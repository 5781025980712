import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { FeedbackFilter } from '../../../../../Models/Feedback';
import { FilterKeys } from '../../../../../Types';
import { FeedbackFreeTextSearchBy } from '../../../Members/Constants';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';
import { object, string } from 'yup';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch, filters } = props;
  
  const initialValues: FeedbackFilter = {
    FreeTextSearchBy: filters?.FreeTextSearchBy ?? FreeTextSearchByOptions.JobID,
    DateAddedFrom: filters?.DateAddedFrom ?? '',
    DateAddedTo: filters?.DateAddedTo ?? '',
    FreeText: filters?.FreeText ?? '',
  };

  const onSubmit = (values: FeedbackFilter) => {
    formik.validateForm();
    if (formik.isValid) onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: () => createValidationSchema(formik.values.FreeTextSearchBy)
  });

  const { 
    handleSubmit, 
    resetForm,
  } = formik;


  useEffect(() => {
    if(filters != null)
    formik.setValues(filters);
  }, [filters]);

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={FilterKeys.Freetext}
                    label="Freetext"
                    title="Searches by ID, Job ID, From Member UserName or To Member UserName"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.FreeTextSearchBy}
                    label="Freetext Type"
                    control={FormControlKeys.Select}
                    options={FeedbackFreeTextSearchBy}
                  />
              </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.DateAddedFrom}
                    label="Date Added From"
                    title="Date Added From"
                    control={FormControlKeys.DateInputNotClearable}
                    type="date"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.DateAddedTo}
                    label="Date Added To"
                    title="Date Added To"
                    control={FormControlKeys.DateInputNotClearable}
                    type="date"
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export const createValidationSchema = (currentFreeTextSearchBy: string) => {
  return object({
    [FilterKeys.Freetext]: currentFreeTextSearchBy === 'JobID' 
      ? string().matches(/^[0-9]*$/, "Job ID must be a number").nullable() 
      : string().required(),
  });
};

export default FilterBox;
export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
  filters: FeedbackFilter;
}
