import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { AddVehicleModel } from '../../../../../../Models/System/Vehicles';
import { UpdateVehicleModel } from '../../../../../../Models/System/Vehicles/UpdateVehicleModel';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';

export const usePutUpdateVehicle = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  UpdateVehicleModel
> => {

  const updateVehicle = (payload: UpdateVehicleModel) => {
    return agent.vehicles.putUpdateVehicle(payload);
  };

  return useMutation(updateVehicle);
};