import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { RelistJobRequest } from '../../../../../Models/Jobs/EditJob/RelistJobRequest';

export const usePutRelistJob = (
  options?: any
): UseMutationResult<any, any, RelistJobRequest> => {

  const putRelistJob = (relistJobRequest: RelistJobRequest) => {
    return agent.jobs.putRelistJob(relistJobRequest);
  };

  return useMutation(putRelistJob);
};
