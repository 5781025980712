import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { ReleaseNotesResponse } from '../../../../../Models/Jobs/EditJob/ReleaseNotesResponse';

export const useGetReleaseNotes = (
    payload: number,
    options?: any
): UseQueryResult<ReleaseNotesResponse, any> => {

    const getReleaseNotes = () => {
        if(payload === 0) return [];
        return agent.jobs.getReleaseNotes(payload)
    }

    return useQuery(['GetReleaseNotes', payload], getReleaseNotes, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}
