import { Reducer } from 'redux';
import { ApiUrlActionTypes, ApiUrlState, GET_API_URL, SET_API_URL } from './Types';
const initialState: ApiUrlState = {
     apiUrl: '',
};
export const reducer: Reducer<ApiUrlState, ApiUrlActionTypes> = (state = initialState, action): ApiUrlState => {
     switch (action.type) {
          case SET_API_URL:
          case GET_API_URL:
               return { ...state, apiUrl: action.payload };
          default:
               return state;
     }
};
