import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import React from 'react';
import { ControlledPagination } from '../../../../../Components/Pagination/Pagination';
import { GridContainer } from '../../../../../Components/UI';
import { PricesResponseModel } from '../../../../../Models/Prices/ViewPrices/Response';
import { GridProps } from '../../../../../Types';
import { getPriceGridColumns } from './Column';

const GridView: React.FC<PriceGridViewProps> = (props) => {
     const { data: priceList, onPageChange, onPageSizeChange, onDelete, onSort } = props;

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };
     if (priceList) {
          const { Page, TableSorter } = priceList;
          const { CurrentPage, Items, TotalItems, ItemsPerPage, TotalPages } = Page;
          const CustomGridToolbar: React.FC = (): JSX.Element => {
               return (
                    <ControlledPagination
                         itemsCount={TotalItems}
                         totalPages={TotalPages}
                         currentPage={CurrentPage}
                         pageSize={ItemsPerPage}
                         onPageChange={handlePageChange}
                         onRowsPerPageChange={handleRowsPerPageChange}
                    ></ControlledPagination>
               );
          };

          return (
               <div>
                    {Page && (
                         <GridContainer id='id_grid_prices'>
                              <DataGrid
                                   sortingOrder={['desc', 'asc']}
                                   getRowId={(row: any) => row.Id}
                                   rows={Items}
                                   autoHeight
                                   disableColumnMenu={true}
                                   columns={getPriceGridColumns({ onDelete })}
                                   components={{
                                        Toolbar: CustomGridToolbar,
                                        Footer: CustomGridToolbar,
                                   }}
                                   disableExtendRowFullWidth={true}
                                   onSortModelChange={handleSort}
                              />
                         </GridContainer>
                    )}
               </div>
          );
     }
     return null;
};

export default GridView;

interface PriceGridViewProps extends GridProps<PricesResponseModel> {
     onDelete: (partnerID: number) => void;
}
