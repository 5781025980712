import { DataGrid, GridColDef, GridOverlay, GridRenderCellParams } from '@mui/x-data-grid';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MemberIDParamType } from '../..';
import MovexResponseError from '../../../../../../Axios/MovexResponseError';
import {
  Alert,
  ShowPleaseWait,
  useAlert,
} from '../../../../../../Components';
import { Button } from '../../../../../../Components/FormControls';
import {
  FormControlKeys,
  OptionType,
} from '../../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import {
  DivContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormFieldSetContainer,
  Margin20RightSpan,
} from '../../../../../../Components/Formik/StyledComponents';
import {
  AddPartnerMemberModel,
  AddPartnerMemberResponseViewModel,
  DeleteMemberPartnersRequestModel,
} from '../../../../../../Models/Members/EditMember';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { isMovexErrorResponse } from '../../../../../../Utils';
import { useGetMember } from '../General/Hooks';
import {
  useDeleteSelectedPartnersFromMember,
  useGetPartnersForDropdown,
  usePostAddSelectedMemberToPartner,
} from './Hooks';
import { ExistingPartnerKeys, PartnerKeys } from './Types';
import { CellRenderer } from '../../../../../../Components/Formik';

const { renderPartnerNameCell } = CellRenderer;

const columnsForMemberPartners: GridColDef[] = [
  {
    field: ExistingPartnerKeys.PartnerName,
    headerName: 'Partner Name',
    renderCell: (params: GridRenderCellParams) =>
        renderPartnerNameCell(params),
    flex: 1,
  },
  {
    field: ExistingPartnerKeys.PartnerCID,
    headerName: 'Partner Customer ID',
    flex: 1,
  },
];

const MemberPartners: React.FC = () => {

  const { memberID } = useParams<MemberIDParamType>();

  /** Custom Hook */
  const { alert, handleAlertClose, setAlert } = useAlert();

  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<AddPartnerMemberModel>({});
  const [partnersForDropdown, setPartnersForDropdown] = useState<OptionType[]>(
    []
  );
  const [partnersForGridView, setPartnersForGridView] = useState<
    AddPartnerMemberResponseViewModel[]
  >([]);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [selectedPartnerIds, setSelectedPartnerIds] = React.useState<number[]>(
    []
  );

  const CustomFooterForPartners = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedPartnerIds.length}
        style={{ margin: 10 }}
        onClick={removePartner}
      ></Button>
    );
  };

  /** Query Hooks */
  const { data: getMemberResponse, refetch } = useGetMember();

  const {
    data: getPartnersForDropdownResponse,
    isLoading: isGetPartnersForDropdownLoading,
    error: getPartnersForDropdownError
  } = useGetPartnersForDropdown();

  const {
    mutate: deleteMemberPartners,
    isLoading: isDeleteMemberPartnersLoading,
    data: deleteMemberPartnersResponse,
    error: deleteMemberPartnersError,
  } = useDeleteSelectedPartnersFromMember();

  const {
    mutate: postAddPartner,
    isLoading: isPostAddParnerLoading,
    data: postAddPartnerResponse,
    error: postAddPartnerError,
  } = usePostAddSelectedMemberToPartner();

  const removePartner = (e: React.MouseEvent<HTMLButtonElement>) => {
    const payload: DeleteMemberPartnersRequestModel = {
      PartnersMembersIds: selectedPartnerIds,
      MemberId: parseInt(memberID),
    };
    deleteMemberPartners(payload);
  };

  /** useEffects */
  useEffect(() => {
    setShowPleaseWait(
      isPostAddParnerLoading ||
        isDeleteMemberPartnersLoading ||
        isGetPartnersForDropdownLoading
    );
  }, [
    isPostAddParnerLoading,
    isDeleteMemberPartnersLoading,
    isGetPartnersForDropdownLoading,
  ]);

   useEffect(() => {
     if (getPartnersForDropdownResponse) {
       const partnerList = getPartnersForDropdownResponse.PartnersDetails;
       if (partnerList) {
         const partners: OptionType[] = [];
         partnerList.forEach((x) =>
           partners.push({ label: x.Name, value: x.Id })
         );
         setPartnersForDropdown(partners);
       }
     }
   }, [getPartnersForDropdownResponse]);

  useEffect(() => {
    if (postAddPartnerResponse) {
      const { PartnerCID, PartnerName, UserName } = postAddPartnerResponse;
      const headerText = (
        <div>
          <span className="fw-600">{UserName}</span> successfully added as a
          partner member for <span className="fw-600">{PartnerName} </span>
          with CID <span className="fw-600">{PartnerCID}</span>
        </div>
      );
      setAlert({
        ...alert,
        show: true,
        header: headerText,
        closeCallback: onCloseAlert,
      });
    }
  }, [postAddPartnerResponse]);

  useEffect(() => {
    if (postAddPartnerError) {
      if (isMovexErrorResponse(postAddPartnerError)) {
        setAlert({
          ...alert,
          show: true,
          header: MovexResponseError.getMovexErrorContent(postAddPartnerError).header,
          body: MovexResponseError.getMovexErrorContent(postAddPartnerError).body,
        });
      }
    }
  }, [postAddPartnerError]);

  useEffect(() => {
    if (getMemberResponse) {
      const { Partners } = getMemberResponse;
      setPartnersForGridView(Partners);
    }
  }, [getMemberResponse]);

  useEffect(() => {
    if (deleteMemberPartnersResponse) {
      const { DeletePartnersMembers: deletedPartnerMembers = [] } =
        deleteMemberPartnersResponse;
      const deleted = deletedPartnerMembers.every((x) => x.IsDeleted);
      if (deleted) {
        setAlert({
          ...alert,
          show: true,
          header: 'Successfully deleted!',
          closeCallback: onCloseAlert
        });
        return;
      } else {
        deletedPartnerMembers.forEach((x) => {
          if (!x.IsDeleted) {
            console.log(x.ExceptionDetail);
          }
        });
      }
    }
  }, [deleteMemberPartnersResponse]);

  useEffect(() => {
    if (getPartnersForDropdownError) {
      setAlert({
        ...alert,
        show: true,
        header: getPartnersForDropdownError?.Subject,
        body: getPartnersForDropdownError?.Description,
        closeCallback: onCloseAlert,
      });
    } else if (deleteMemberPartnersError) {
      setAlert({
        ...alert,
        show: true,
        header: deleteMemberPartnersError?.Subject,
        body: deleteMemberPartnersError?.Description,
        closeCallback: onCloseAlert,
      });
    }
  }, [getPartnersForDropdownError, deleteMemberPartnersError]);
  

  /** Methods */
  const onSubmit = (payload: AddPartnerMemberModel) => {
    payload.MemberID = parseInt(memberID);
    postAddPartner(payload);
  };

  const onCloseAlertAndRedirect = () => {
    handleAlertClose();
    const url = `${RouteConstants.Members}`;
    window.location.href = url;
  };

  const onCloseAlert = () => {
    handleAlertClose();
    refetch();
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { values } = formik;
  if (getMemberResponse) {
    return (
      <FormikProvider value={formik}>
        <Form>
          <Alert {...alert} />
          <ShowPleaseWait show={showPleaseWait} />
          <FormFieldSetContainer>
            <FieldSetContainer>
              <FieldSetHeader>Partners</FieldSetHeader>
              <DivContainer>
                <FlexContainer>
                  <FormikControl
                    name={PartnerKeys.PartnerID}
                    control={FormControlKeys.Select}
                    label="Add a new partner"
                    placeholder="Select Partner"
                    options={partnersForDropdown}
                  />
                  <Margin20RightSpan />
                  <FormikControl
                    name={PartnerKeys.PartnerCID}
                    placeholder="Partner CID"
                    label="Partner CID"
                  />
                  <Margin20RightSpan />
                  <FieldAddButtonContainer>
                    <Button
                      label="Add Partner"
                      type="submit"
                      disabled={!values?.PartnerID}
                    />
                  </FieldAddButtonContainer>
                </FlexContainer>
              </DivContainer>
              <DivContainer>
                <h6 style={{ fontWeight: 'bold' }}>Manage Existing Partners</h6>
                <div id='id_grid_member_partners' style={{ width: '100%' }}>
                  <DataGrid
                    sortingOrder={['desc', 'asc']}
                    getRowId={(row: any) => row.Id}
                    autoHeight
                    disableColumnMenu={true}
                    rows={partnersForGridView}
                    columns={columnsForMemberPartners}
                    hideFooterPagination={true}
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={selectedPartnerIds}
                    onSelectionModelChange={(selected) => {
                      setSelectedPartnerIds(selected as number[]);
                    }}
                    components={{
                      Footer: partnersForGridView.length
                        ? CustomFooterForPartners
                        : undefined,
                      NoRowsOverlay: () => {
                        return (
                          <GridOverlay>
                            <div>No partners for this member!</div>
                          </GridOverlay>
                        );
                      },
                    }}
                  />
                </div>
              </DivContainer>
            </FieldSetContainer>
          </FormFieldSetContainer>
        </Form>
      </FormikProvider>
    );
  }
  return null;
};

export default MemberPartners;

export const FieldAddButtonContainer = styled.div`
  margin: 30px 10px;
`;
