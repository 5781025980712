import { InvoiceType } from "../../../Utils/Enums";

export interface InvoicesFilter {
    InvoiceID?: number;
    InvoiceNo?: string;
    Type?: InvoiceType;
    MemberCodaCustomerReference?: string;
    MemberCodaSupplierReference?: string;
    MemberCompany?: string;
    InvoiceDateFrom?: string;
    InvoiceDateTo?: string;
    JobID?: number;
}

export const InvoicesFilterKeys = {
    InvoiceID: 'InvoiceID',
    InvoiceNo: 'InvoiceNo',
    Type: 'InvoiceType',
    MemberCodaCustomerReferenceID: 'MemberCodaCustomerReference',
    MemberCodaSupplierReferenceID: 'MemberCodaSupplierReference',
    MemberCompany: 'MemberCompany',
    InvoiceDateFrom: 'InvoiceDateFrom',
    InvoiceDateTo: 'InvoiceDateTo',
    JobID: 'JobID',
}