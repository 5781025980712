import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FeedbackItemModel } from '../../../../../Models/Feedback';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddFeedback = (
  options?: any
): UseMutationResult<
EntityCreatedOrUpdatedViewModel,
  any,
  FeedbackItemModel
> => {

  const addFeedback = (payload: FeedbackItemModel) => {
    return agent.feedback.postAddFeedback(payload);
  };

  return useMutation(addFeedback);
};
