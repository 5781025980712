import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddQuoteModel, AddQuoteResponseModel } from '../../../../../Models/Jobs/EditJob';

export const usePostAddQuote = (
  options?: any
): UseMutationResult<
  AddQuoteResponseModel,
  any,
  AddQuoteModel
> => {
  const addQuote = (payload: AddQuoteModel) => {
    return agent.jobs.postAddQuote(payload);
  };

  return useMutation(addQuote);
};
