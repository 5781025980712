import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import {
DataGrid,GridRenderCellParams,
GridSortModel
} from '@mui/x-data-grid';
import React from 'react';
import { ControlledPagination as Pagination } from '../../../../../Components';
import { CellRenderer } from '../../../../../Components/Formik';
import { HoverableLink } from '../../../../../Components/Formik/StyledComponents';
import { SavedSearchesResponseModel } from '../../../../../Models/Searches';
import { RouteConstants } from '../../../../../Routes/Constants';
import { GridProps,Sorter } from '../../../../../Types';
import { getSortModelFromSorter, lookupEnumByVal } from '../../../../../Utils';
import { ActionColumnPath } from '../../../../../Utils/Constants';
import { SearchesGridKeys } from '../Types';
import { SearchRadiusOptions } from '../../../Members/Constants';

const { renderCellWithTooltip, renderServiceTypeCell } =
  CellRenderer;

interface Column {
  field: string;
  headerName: string;
  description: string;
  renderCell?: (params: GridRenderCellParams) => JSX.Element | undefined
  type?: string;
  flex: number;
  sortable?: boolean;
}

const getColumns = ( onDelete : (Id: number) => void): Column[] => [
  {
    field: SearchesGridKeys.Id,
    headerName: 'ID',
    description: 'ID',
    renderCell: (params: GridRenderCellParams) => (
      <HoverableLink to={`${RouteConstants.EditSearch}/${params.id}`} style={{ paddingLeft: '10px' }}>
        {params.value}
      </HoverableLink>
    ),
    flex: 2,
  },
  {
    field: SearchesGridKeys.UserName,
    headerName: 'Username',
    description: 'Username',
    flex: 6,
    renderCell: (params: GridRenderCellParams) => renderUserNameCell(params),
  },
  {
    field: SearchesGridKeys.Email,
    description: 'Email',
    headerName: 'Email',
    renderCell: (params: GridRenderCellParams) => renderEmailCell(params),
    flex: 4,
  },
  {
    field: SearchesGridKeys.SearchName,
    headerName: 'Search Name',
    description: 'Search Name',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: SearchesGridKeys.ServiceType,
    description: 'Service Type',
    headerName: 'Service Type',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderServiceTypeCell(params),
    sortable: false,
  },
  {
    field: SearchesGridKeys.MinVehicles,
    description: 'Min Vehicles',
    headerName: 'Min Vehicles',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: SearchesGridKeys.MaxVehicles,
    description: 'Max Vehicles',
    headerName: 'Max Vehicles',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: SearchesGridKeys.WithinMiles,
    description: 'Within Miles',
    headerName: 'Within Miles',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => (<>{lookupEnumByVal(SearchRadiusOptions, params.value) || params.value}</>),
    sortable: false,
  },
  {
    field: SearchesGridKeys.SearchActive,
    description: 'Status',
    headerName: 'Status',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => (<>{params.value ? 'Active' : 'Inactive'}</>),
    sortable: false,
  },
  {
    field: ActionColumnPath,
    description: 'Action',
    headerName: 'Action',
    type: 'actions',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderActionCell(params, onDelete),
    sortable: false,
  },
];

const renderUserNameCell = (params: GridRenderCellParams) => {
  const memberID = params.row.MemberId;
  return (
    <HoverableLink to={`${RouteConstants.EditMemberByMemberId}${memberID}`}>
      <span>{params.value}</span>
    </HoverableLink>
  );
};

const renderActionCell = (params: GridRenderCellParams, onDelete: (Id: number) => void) => {
     return (
          <React.Fragment>
               <Tooltip title='Edit Saved Search'>
                    <HoverableLink
                         to={`${RouteConstants.EditSearch}/${params.id}`}
                         style={{ paddingLeft: '10px', alignContent: 'left', boxSizing: 'border-box' }}
                    >
                         <FontAwesomeIcon icon={faEdit} />
                    </HoverableLink>
               </Tooltip>
               <Tooltip title='Delete Saved Search'>
                    <a
                         href='#'
                         style={{ paddingLeft: '10px', width: '100%', alignContent: 'left' }}
                         onClick={() => {
                              onDelete(params?.row?.Id);
                         }}
                    >
                         <FontAwesomeIcon icon={faTrash} />
                    </a>
               </Tooltip>
          </React.Fragment>
     );
};

const renderEmailCell = (params: GridRenderCellParams) => {
  return (
    <div style = {{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
      {params.value}
    </div>
  );
};

const ViewSearchesGridView: React.FC<ViewSearchesGridProps> = (props) => {
  const { data, onPageChange, onPageSizeChange, onSort, onDelete } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };
  const { Page: page,TableSorter } = data;
  if(page){
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = page;
    
  
    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            currentPage={CurrentPage}
            pageSize={ItemsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
  
    return (
      <div>
        {page && (
          <div className="view-searches-container">
            <div id='id_grid_view_searches' style={{ display: 'flex', height: '100%', width: '100%' }}>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                autoHeight
                getRowId={(row: any) => row.Id}
                rows={Items}
                rowHeight={41}
                disableColumnMenu={true}
                columns={getColumns(onDelete)}
                columnBuffer={getColumns(onDelete).length + 1}
                components={{
                  Toolbar: CustomGridFooter,
                  Footer: CustomGridFooter,
                }}
                disableExtendRowFullWidth={true}
                sortingMode="server"
                sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                onSortModelChange={handleSort}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ViewSearchesGridView;

interface ViewSearchesGridProps extends GridProps<SavedSearchesResponseModel> {
  onDelete: (Id: number) => void;
}
