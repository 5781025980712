import axios from 'axios';
import { AddGroupBlockModel, AddGroupFavouriteModel, AddGroupModel, GroupSavedAddressDetails, GroupsDropdownViewModelKeys, GroupsRequestParam } from '../../../Models/Groups';
import { IDeletePriceFromPriceGroup } from '../../../Models/PriceGroup';
import { camelize, mapResultsToOptionTreeTypeList } from '../../../Utils';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetGroups_URL,
    GetGroupsForDropdown_URL,
    GetGroup_URL,
    PutAddGroupFavourite_URL,
    DeleteGroupFavourite_URL,
    PutAddGroupBlock_URL,
    DeleteGroupBlock_URL,
    PostAddGroup_URL,
    PutUpdateGroup_URL,
    AddOrUpdateGroupSavedDetails_URL,
    DeleteGroupSavedDetails_URL,
    DeletePriceFromPriceGroup_URL,
} = ApiURLConstants;


export const groups = {
    getGroups: (params: GroupsRequestParam): Promise<any> => {
        return axios
            .post(`${GetGroups_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    getGroupsForDropdown: (): Promise<any> => {
        return axios
            .get(`${GetGroupsForDropdown_URL}`)
            .then((response) => {
                return new Promise((resolve, reject) => {
                    const res = camelize(response);
                    if (res && res.Data) {

                        const results = mapResultsToOptionTreeTypeList(res.Data?.Results, {
                            labelKeyString: GroupsDropdownViewModelKeys.Username,
                            valueKeyString: GroupsDropdownViewModelKeys.Id,
                        });
                        resolve(results);
                    } else {
                        reject();
                    }
                })
            })
    },

    getGroup: (groupID: number): Promise<any> => {
        return axios
            .get(`${GetGroup_URL}?groupID=${groupID}`, jsonConfig)
            .then(responseBody);
    },

    postAddGroup: (params: AddGroupModel): Promise<any> => {
        return axios.post(`${PostAddGroup_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    putUpdateGroup: (params: AddGroupModel): Promise<any> => {
        return axios.put(`${PutUpdateGroup_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    putGroupFavourite: (params: AddGroupFavouriteModel): Promise<any> => {
        return axios.put(`${PutAddGroupFavourite_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    deleteGroupFavourite: (params: AddGroupFavouriteModel): Promise<any> => {
        return axios.delete(`${DeleteGroupFavourite_URL}`, { data: params })
            .then(responseBody)
    },

    putGroupBlock: (params: AddGroupBlockModel): Promise<any> => {
        return axios.put(`${PutAddGroupBlock_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    deleteGroupBlock: (params: AddGroupBlockModel): Promise<any> => {
        return axios.delete(`${DeleteGroupBlock_URL}`, { data: params })
            .then(responseBody)
    },

    addOrUpdateGroupSavedDetails: (params: GroupSavedAddressDetails): Promise<any> => {
        return axios.post(`${AddOrUpdateGroupSavedDetails_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    deleteGroupSavedDetails: (groupID: number): Promise<any> => {
        return axios.delete(`${DeleteGroupSavedDetails_URL}?groupID=${groupID}`, jsonConfig)
            .then(responseBody => {
                return camelize(responseBody)
            })
    },
    
    deletePriceFromPriceGroup: (params: IDeletePriceFromPriceGroup): Promise<any> => {
        return axios.delete(`${DeletePriceFromPriceGroup_URL}?priceGroupId=${params.groupId}&priceId=${params.priceId}`, jsonConfig)
            .then(responseBody => {
                return camelize(responseBody)
            })
    },
};