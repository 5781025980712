import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import React, { useCallback, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { SavedSearch, SearchData } from '../../../../../Models/Jobs/ViewJobs';

interface FilterProps extends CommonProps {
     savedSearches: SavedSearch[];
     performClear: () => void;
     performQuickSearch: (searchText: string) => void;
     showFilter: () => void;
     showSave: () => void;
};

interface CommonProps {
     selectedQuickSearch: string;
     runSavedSearch: (searchData: SearchData, selectedQuickSearch?: string) => void;
}

export default function Filter({
     savedSearches,
     selectedQuickSearch,
     performClear,
     performQuickSearch,
     runSavedSearch,
     showFilter,
     showSave,
}: FilterProps) {
     const [quickSearch, setQuickSearch] = useState('');

     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setQuickSearch(event.target.value);
     };

     const handleQuickSearch = useCallback(() => {
          performQuickSearch(quickSearch);
     }, [quickSearch, performQuickSearch]);

     return (
          <>
               <div className='pull-right' style={{ display: 'flex' }}>
                    <div className='input-append btn--quick-search' style={{ display: 'flex', cursor: 'pointer' }}>
                         <InputGroup>
                              <input
                                   id='QuickSearch'
                                   name='QuickSearch'
                                   className='input-mini form-control'
                                   placeholder='Quick search by ID...'
                                   type='number'
                                   onChange={handleChange}
                                   value={quickSearch}
                                   onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                             handleQuickSearch();
                                        }
                                   }}
                              />
                              <InputGroup.Text>
                                   <SearchIcon onClick={handleQuickSearch} />
                              </InputGroup.Text>
                         </InputGroup>
                    </div>
                    <div>
                         <Button
                              size='sm'
                              className='btn btn-mini btn--filter quick-search-btn'
                              href='#filter'
                              title='Show Search'
                              variant='light'
                              onClick={showFilter}
                         >
                              <FilterAltIcon />
                         </Button>
                         <Button
                              size='sm'
                              className='btn btn-mini btn--save quick-search-btn'
                              href='#save'
                              variant='light'
                              title='Save Search'
                              onClick={showSave}
                              data-bind="click: ShowSave, css: { 'disabled': !SaveButtonEnabled() }"
                         >
                              <SaveIcon />
                         </Button>
                         <Button
                              size='sm'
                              variant='light'
                              className='btn btn-mini btn--clear quick-search-btn'
                              href='#filter'
                              title='Clear Search'
                              onClick={performClear}
                              data-bind="click: PerformClear, css: { 'disabled': !ClearButtonEnabled() }"
                         >
                              <ClearIcon />
                         </Button>
                    </div>
               </div>

               <div className='pull-right quick-search-badges-div'>
                    {Array.isArray(savedSearches) &&
                         savedSearches.map((x: SavedSearch, index: number) =>
                              <QuickSearchBadge
                                   key={index}
                                   savedSearch={x}
                                   selectedQuickSearch={selectedQuickSearch}
                                   runSavedSearch={runSavedSearch}
                              />
                         )
                    }
               </div>
          </>
     );
};

interface QuickSearchBadgeProps extends CommonProps {
     savedSearch: SavedSearch,
}

function QuickSearchBadge({ savedSearch, selectedQuickSearch, runSavedSearch }: QuickSearchBadgeProps) {
     const { IsQuickSearch, SearchData, SearchName } = savedSearch;

     if (SearchName === "" || !IsQuickSearch)
          return null;

     return (
          <a
               id={SearchName}
               key={SearchName}
               className='quick-search-badge '
               onClick={(event) => {
                    event.preventDefault();

                    runSavedSearch(SearchData, SearchName);
               }}
               title={`Run quick search: ${SearchName}`}
          >
               <span
                    className={
                         selectedQuickSearch === SearchName
                              ? 'blue-quick-search-badge'
                              : 'quick-search-label'
                    }
               >
                    {SearchName}
               </span>
          </a>
     );
}
