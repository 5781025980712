import { BillingModel } from "../../../../Models/Billing/AddBilling/BillingModel";
import { Charge } from "../../../../Models/Billing/AddBilling/ChargeModel";
import { PaymentModel } from "../../../../Models/Billing/AddBilling/PaymentModel";
import { BillPaymentStatus } from "../../../../Utils/Enums/BillPaymentStatus";

const VATRate = 0.2;

// Version 1
export const V1_FeeTotal = (charges: Charge[]) => {
  var total = 0;
  charges.forEach((charge) => {
    total += charge.QuoteFee ?? 0;
  });
  return Number(total).toFixed(2);
};

export const V1_HandlingFeeTotal = (charges: Charge[]) => {
  var total = 0;

  charges.forEach((charge) => {
    total += charge.QuoteHandlingFee || 0;
  });

  return Number(total).toFixed(2);
};

export const V1_FeeVAT = (charges: Charge[]) => {
  var feeTotal = Number(V1_FeeTotal(charges));
  var handlingFeeTotal = Number(V1_HandlingFeeTotal(charges));
  return Number((feeTotal + handlingFeeTotal) * VATRate).toFixed(2);
};

export const V1_GrandTotal = (charges: Charge[]) => {
  var feeTotal = Number(V1_FeeTotal(charges));
  var handlingFeeTotal = Number(V1_HandlingFeeTotal(charges));
  var vat = Number(V1_FeeVAT(charges));
  var grandTotal = Number(feeTotal + handlingFeeTotal + vat);
  return grandTotal.toFixed(2);
};

export const ShowMarkAsPaid = (
  bill: BillingModel,
  payments: PaymentModel[]
) => {
  var showMarkAsPaid = false;

  if (
    bill &&
    bill.Version === 3 &&
    (bill.PaymentStatus === BillPaymentStatus.Unpaid ||
      bill.PaymentStatus === BillPaymentStatus.Paid)
  ) {
    if (bill.RequestingMemberID && bill.RequestingMemberID > 0) {
      payments?.forEach((payment) => {
        if (!payment.MovexPaidDate) showMarkAsPaid = true;
      });

      if (!bill.RequestingMemberMonthlyFeePaidDate) showMarkAsPaid = true;
    } else if (bill.SupplyingMemberID && bill.SupplyingMemberID > 0) {
      if (!bill.SupplyingMemberMonthlyFeePaidDate) showMarkAsPaid = true;
    }
  }

  return showMarkAsPaid;
};

// Version 2
export const V2_AmountTotal = (charges: Charge[]) => {
  var total = 0;
  charges.forEach((charge) => {
    total += charge.QuoteAmount!;
  });
  return Number(total).toFixed(2);
};

export const V2_MemberSurchargeTotal = (charges: Charge[]) => {
  var total = 0;
  charges.forEach((charge) => {
    total += charge.QuoteMemberSurcharge!;
  });
  return Number(total).toFixed(2);
};

export const V2_MonthlyFee = (bill: BillingModel) => {
  var monthlyFee = bill && bill.MonthlyFee ? bill.MonthlyFee : 0;
  return monthlyFee.toFixed(2);
};

export const V2_SubTotal = (charges: Charge[], bill: BillingModel) => {
  var memberSurchargeTotal = Number(V2_MemberSurchargeTotal(charges));
  var monthlyFee = Number(V2_MonthlyFee(bill));
  var subTotal = Number(memberSurchargeTotal + monthlyFee);
  return subTotal.toFixed(2);
};

export const V2_VAT = (charges: Charge[], bill: BillingModel) => {
  var subTotal = Number(V2_SubTotal(charges, bill));
  var vat = Number(subTotal * VATRate);
  return vat.toFixed(2);
};
export const V2_GrandTotal = (charges: Charge[], bill: BillingModel) => {
  var subTotal = Number(V2_SubTotal(charges, bill));
  var vat = Number(V2_VAT(charges, bill));
  var grandTotal = Number(subTotal + vat);
  return grandTotal.toFixed(2);
};

// Version 3 (Users)
export const V3a_AmountTotal = (payments: PaymentModel[]) => {
  var total = 0;
  payments.forEach((payment) => {
    if (payment.QuoteAmount) {
      total += payment.QuoteAmount;
    }
  });

  return Number(total).toFixed(2);
};

export const V3a_RequestingMemberFeeTotal = (payments: PaymentModel[]) => {
  var total = 0;
  payments.forEach((payment) => {
    if (payment.RequestingMemberFee) {
      total += payment.RequestingMemberFee;
    }
  });
  return Number(total).toFixed(2);
};

export const V3a_RequestingMemberPayAsYouGoFeeTotal = (
  payments: PaymentModel[]
) => {
  var total = 0;

  payments.forEach((payment) => {
    if (payment.RequestingMemberPayAsYouGoFee) {
      total += payment.RequestingMemberPayAsYouGoFee;
    }
  });

  return Number(total).toFixed(2);
};

export const V3a_RequestingMemberMonthlyFee = (
  payments: PaymentModel[],
  bill: BillingModel
) => {
  var monthlyFee =
    bill && bill.RequestingMemberMonthlyFee
      ? bill.RequestingMemberMonthlyFee
      : 0;
  return monthlyFee.toFixed(2);
};

export const V3a_RequestingMemberAmountTotal = (payments: PaymentModel[]) => {
  var total = 0;

  payments.forEach((payment) => {
    total += Number(payment.RequestingMemberAmount);
    total += Number(payment.FuelCostLessVat);
  });

  return total.toFixed(2);
};
export const V3a_FuelCostLessVatTotal = (payments: PaymentModel[]) => {
  var total = 0;

  payments.forEach((payment) => {
    if (payment.FuelCostLessVat) {
      total += Number(payment.FuelCostLessVat);
    }
  });

  return total.toFixed(2);
};
export const V3a_FuelVatTotal = (payments: PaymentModel[]) => {
  var total = 0;

  payments.forEach((payment) => {
    if (payment.FuelVat) {
      total += Number(payment.FuelVat);
    }
  });
  return total.toFixed(2);
};
export const V3a_FuelCostTotal = (payments: PaymentModel[]) => {
  var total = 0;

  payments.forEach((payment) => {
    if (payment.FuelCost) {
      total += Number(payment.FuelCost);
    }
  });

  return total.toFixed(2);
};
export const ShowFuel = (payments: PaymentModel[]) => {
  return V3a_FuelCostTotal(payments).length > 0;
};

export const V3a_SubTotal = (
  payments: PaymentModel[],
  bill: BillingModel
) => {
  var amountTotal = Number(V3a_AmountTotal(payments));
  var fuelTotal = Number(V3a_FuelCostLessVatTotal(payments));
  var requestingMemberFeeTotal = Number(V3a_RequestingMemberFeeTotal(payments));
  var requestingMemberPayAsYouGoFeeTotal = Number(
    V3a_RequestingMemberPayAsYouGoFeeTotal(payments)
  );
  var requestingMemberMonthlyFee = Number(
    V3a_RequestingMemberMonthlyFee(payments, bill)
  );
  var subTotal =
    amountTotal +
    fuelTotal +
    requestingMemberFeeTotal +
    requestingMemberPayAsYouGoFeeTotal +
    requestingMemberMonthlyFee;
  return subTotal.toFixed(2);
};
export const V3a_VAT = (payments: PaymentModel[], bill: BillingModel) => {
  var vatRate = VATRate;
  var amountVat = Number(V3a_AmountTotal(payments)) * vatRate;
  var fuelVat = Number(V3a_FuelVatTotal(payments));
  var requestingMemberFeeVat =
    Number(V3a_RequestingMemberFeeTotal(payments)) * vatRate;
  var requestingMemberPayAsYouGoFeeVat =
    Number(V3a_RequestingMemberPayAsYouGoFeeTotal(payments)) * vatRate;
  var requestingMemberMonthlyFeeVat =
    Number(V3a_RequestingMemberMonthlyFee(payments, bill)) * vatRate;
  var vat =
    amountVat +
    fuelVat +
    requestingMemberFeeVat +
    requestingMemberPayAsYouGoFeeVat +
    requestingMemberMonthlyFeeVat;
  return vat.toFixed(2);
};
export const V3a_GrandTotal = (
  payments: PaymentModel[],
  bill: BillingModel
) => {
  var subTotal = Number(V3a_SubTotal(payments, bill));
  var vat = Number(V3a_VAT(payments, bill));
  var grandTotal = Number(subTotal + vat);
  return grandTotal.toFixed(2);
};

// Version 3 (Suppliers)
export const V3b_AmountTotal = (payments: PaymentModel[]) => {
  var total = 0;
  payments.forEach((payment) => {
    if (payment.QuoteAmount) {
      total += payment.QuoteAmount;
    }
  });

  return Number(total).toFixed(2);
};

export const V3a_SupplyingMemberMonthlyFee_IsSelected = false;

export const V3b_SupplyingMemberMonthlyFee = (bill: BillingModel) => {
  var monthlyFee =
    bill && bill.SupplyingMemberMonthlyFee ? bill.SupplyingMemberMonthlyFee : 0;
  return monthlyFee.toFixed(2);
};

export const V3b_SupplyingMemberFeeTotal = (
  bill: BillingModel,
  payments: PaymentModel[]
) => {
  var total = 0;
  payments?.forEach((payment) => {
    if (payment.SupplyingMemberFee) {
      total += payment.SupplyingMemberFee;
    }
  });
  return Number(total).toFixed(2);
};

export const V3b_SubTotal = (
  bill: BillingModel,
  payments: PaymentModel[]
) => {
  var supplyingMemberFeeTotal = Number(
    V3b_SupplyingMemberFeeTotal(bill, payments)
  );
  var supplyingMemberMonthlyFee = Number(V3b_SupplyingMemberMonthlyFee(bill));
  var subTotal = Number(supplyingMemberFeeTotal + supplyingMemberMonthlyFee);
  return subTotal.toFixed(2);
};

export const V3b_VAT = (bill: BillingModel, payments: PaymentModel[]) => {
  var subTotal = Number(V3b_SubTotal(bill, payments));
  var vat = Number(subTotal * VATRate);
  return vat.toFixed(2);
};

export const V3b_GrandTotal = (
  bill: BillingModel,
  payments: PaymentModel[]
) => {
  var subTotal = Number(V3b_SubTotal(bill, payments));
  var vat = Number(V3b_VAT(bill, payments));
  var grandTotal = Number(subTotal + vat);
  return grandTotal.toFixed(2);
};
