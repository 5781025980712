import axios from 'axios';
import { PricesRequestParam } from '../../../Models/Prices/ViewPrices/Request';
import { jsonConfig,responseBody } from '../../Agent';

import { ApiURLConstants } from '../../ApiUrlConstants';

const { DeletePrice_URL,GetPriceList_URL } = ApiURLConstants;

export const prices = {
     getPrices: (params: PricesRequestParam): Promise<any> => {
          return axios.post(`${GetPriceList_URL}`, params, jsonConfig)
              .then(responseBody);
      },
     deletePrice: (priceID: number): Promise<any> => {
          return axios.delete(`${DeletePrice_URL}?priceID=${priceID}`).then(responseBody);
     },
};
