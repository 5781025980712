import React from 'react';
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import TextError from '../Formik/TextError';

export function DatePickerInput({ ...props }) {
  const { label, name, showRequired, checkDate, dateFrom, dateTo, ...rest } = props;
  const dateF = new Date(dateFrom);
  const dateT = new Date(dateTo);
  return (
    <div className="mb-3 form-group">
      <label className="form-label fw-600" htmlFor={name}>
        {label}
      </label>
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <DatePicker 
              //showMonthDropdown
              //showYearDropdown
              placeholderText="Please select a date"
              selected={value ? new Date(value) : null}
              dateFormat="d MMMM yyyy"
              className="form-control"
              onChange={(date: any) => {
                setFieldValue(name, date);
              }}
              dayClassName={(date) => {
                if(date.getTime() >= dateF.getTime() && date.getTime() <= dateT.getTime()){
                  return "validday";
                }else return null;
              }}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export function SimpleDatePickerInput({ ...props }) {
  const { label, name, showRequired, ...rest } = props;

  return (
    <div className="mb-3 form-group">
      <label className="form-label fw-600" htmlFor={name}>
        {label}
      </label>
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <DatePicker 
              //showMonthDropdown
              //showYearDropdown
              placeholderText="Please select a date"
              selected={value ? new Date(value) : null}
              dateFormat="d MMMM yyyy"
              className="form-control"
              onChange={(date: any) => {
                setFieldValue(name, date);
              }}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default DatePickerInput;
