import { OptionType } from "../../../Components/FormControls";
import { InvoiceLineItemType } from "../../../Utils/Enums";

export const InvoiceLineItemTypeOptions: OptionType[] = [
    { value: InvoiceLineItemType.AdHocLineItem, label: 'Ad Hoc / Other' },
    { value: InvoiceLineItemType.DeferredFeesLineItem, label: 'Deferred Fees' },
    { value: InvoiceLineItemType.PartnerLineItem, label: 'Partner Revenue' },
    { value: InvoiceLineItemType.rm_JobLineItem, label: 'Customer Job Costs' },
    { value: InvoiceLineItemType.rm_MembershipFeeLineItem, label: 'Customer Membership Costs' },
    { value: InvoiceLineItemType.sm_JobLineItem, label: 'Supplier Job Costs' },
    { value: InvoiceLineItemType.sm_MembershipFeeLineItem, label: 'Supplier Membership Costs' },
]

export const getInvoiceLineItemTypes = (isNew: boolean) => {
    let options = [...InvoiceLineItemTypeOptions]
    options = options.filter(x => x.value !== InvoiceLineItemType.DeferredFeesLineItem)
    if (isNew) {
        options = options.filter(x => x.value !== InvoiceLineItemType.sm_JobLineItem && x.value !== InvoiceLineItemType.sm_MembershipFeeLineItem)
    }
    return options
}