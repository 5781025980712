import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetSavedSearches = (payload: any,
  options?: any
): UseQueryResult<any, any> => {

  const getSavedSearches = ({ queryKey }: any) => {
    
    return agent.savedSearches.getSavedSearches(payload)
   
  };
  return useQuery(['GetSavedSearches'], getSavedSearches, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
