import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FailIndicatorIcon, SuccessIndicatorIcon } from '../../../../Assets/Images';
import { Alert, Loading, useAlert } from '../../../../Components';
import { AdminUserDataModel } from '../../../../Models/Admin';
import { SavedSearch } from '../../../../Models/Jobs';
import { useGetSavedSearches, usePutUpdateAdminUserData } from '../ViewJobs/Hooks';

const GetSavedSearchesTable: React.FC<Props> = (props) => {
     const { RunSavedSearch, DeleteSavedSearch } = props;
     const [savedSearchesState, setSavedSearchState] = useState<SavedSearch[]>({} as any);
     const [deleting, setDeleting] = useState<boolean>(false);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /* Custom Hooks */
     const {
          mutate: putUpdateAdminUserDataMutate,
          isLoading: isPutUpdateAdminUserDataLoading,
          data: putUpdateAdminUserDataResponse,
          error: putUpdateAdminUserDataError,
     } = usePutUpdateAdminUserData();

     const { 
          data: savedSearchesResult, 
          refetch: refetchGetSavedSearches,
          error: getSavedSearchesError
     } = useGetSavedSearches();
     
     /** Effects */
     useEffect(() => {
          refetchGetSavedSearches();
     }, []);

     useEffect(() => {
          refetchGetSavedSearches();
     }, [putUpdateAdminUserDataResponse]);

     useEffect(() => {
          if (savedSearchesResult) {
               setSavedSearchState(
                    savedSearchesResult?.UserData != 'undefined' ? JSON.parse(savedSearchesResult?.UserData)?.SavedSearches : {}
               );
          }
     }, [savedSearchesResult]);

     useEffect(() => {
          if (putUpdateAdminUserDataError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: putUpdateAdminUserDataError?.Subject,
                    body: putUpdateAdminUserDataError?.Description,
               });
          }
     }, [putUpdateAdminUserDataError]);

     useEffect(() => {
          if (getSavedSearchesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getSavedSearchesError?.Subject + ': GetAdminAccountInformation',
                    body: getSavedSearchesError?.Description,
               });
          }
     }, [getSavedSearchesError]);



     const handleDelete = async (SearchData: any, SearchName: string) => {
          setDeleting(true);
          await DeleteSavedSearch(SearchData, SearchName);
          setDeleting(false);
     };

     const handleToogleQuickSearch = async (SearchData: any, SearchName: string) => {
          let parsedData = savedSearchesState.map((obj: SavedSearch) => {
               if (obj.SearchName == SearchName) {
                    return { ...obj, IsQuickSearch: !obj.IsQuickSearch };
               }
               return obj;
          });
          setSavedSearchState(parsedData);

          const payload = {
               ID: savedSearchesResult.Id,
               UserData: JSON.stringify({ DefaultPage: '', SavedSearches: parsedData }),
          } as AdminUserDataModel;
          putUpdateAdminUserDataMutate(payload);
     };

     const handleToogleDefaultView = async (SearchData: any, SearchName: string) => {};

     return (
          <table id='id_table_saved_searches_detail' className='table table-striped'>
               <Alert {...alert} />
               <thead>
                    <tr>
                         <th className='search-name'>Saved Search</th>
                         <th className='quick-search'>Quick?</th>
                         <th className='default-view'>Default?</th>
                         <th className='actions'>action</th>
                    </tr>
               </thead>
               {savedSearchesState && savedSearchesState?.length > 0 && (
                    <tbody>
                         <Loading loading={deleting} />
                         {savedSearchesState?.map((savedSearch: SavedSearch, index) => {
                              const { SearchName, IsQuickSearch, IsDefaultView, SearchData } = savedSearch;
                              if(SearchName === "") return null;
                              return (
                                   <tr id={SearchName + '_' + index}>
                                        <td className='search-name'>
                                             <a
                                                  href='#search'
                                                  onClick={() => {
                                                       RunSavedSearch(SearchData);
                                                  }}
                                             >
                                                  <span>{SearchName}</span>
                                             </a>
                                        </td>
                                        <td className='quick-search'>
                                             <a
                                                  href='#toggle-quick-search'
                                                  onClick={() => handleToogleQuickSearch(SearchData, SearchName)}
                                             >
                                                  {IsQuickSearch && <img src={SuccessIndicatorIcon} width='20' />}
                                                  {!IsQuickSearch && <img src={FailIndicatorIcon} width='20' />}
                                             </a>
                                        </td>
                                        <td className='default-view'>
                                             <a
                                                  href='#toggle-default-view'
                                                  onClick={() => handleToogleDefaultView(SearchData, SearchName)}
                                             >
                                                  {IsDefaultView && <img src={SuccessIndicatorIcon} width='20' />}
                                                  {!IsDefaultView && <img src={FailIndicatorIcon} width='20' />}
                                             </a>
                                        </td>
                                        <td className='actions'>
                                             <a href='#remove' onClick={() => handleDelete(SearchData, SearchName)}>
                                                  <a style={{ paddingLeft: '10px' }} title='Remove'>
                                                       <FontAwesomeIcon icon={faTrash} />
                                                  </a>

                                                  <i className='icon-trash'></i>
                                             </a>
                                        </td>
                                   </tr>
                              );
                         })}
                    </tbody>
               )}
               {savedSearchesState && savedSearchesState.length === 0 && (
                    <tbody>
                         <tr>
                              <td colSpan={4}>You haven't got any saved searches!</td>
                         </tr>
                    </tbody>
               )}
          </table>
     );
};

interface Props {
     SavedSearches: SavedSearch[];
     RunSavedSearch: Function;
     DeleteSavedSearch: Function;
}

export default GetSavedSearchesTable;
