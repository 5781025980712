export interface NewOrUpdatedMemberItemModel {
    AccountName: string;
    AccountNo: string;
    Address1: string;
    Address2: string;
    Address3: string;
    BankName: string;
    CodaCustomerReference: string;
    CodaSupplierReference: string;
    Company: string;
    Company_RegistrationNumber: string;
    Company_TradingStatus: number;
    Country: string;
    County: string;
    Email: string;
    Email_InvoiceForJobSent: string;
    Id: number;
    Landline: string;
    MemberType: number;
    Mobile: string;
    PayStartDate: Date;
    PaymentMethod: number;
    PaymentReference: string;
    PostCode: string;
    RegisteredAddress1: string;
    RegisteredAddress2: string;
    RegisteredAddress3: string;
    RegisteredCountry: string;
    RegisteredCounty: string;
    RegisteredPostCode: string;
    RegisteredTown: string;
    SortCode: string;
    TotalRecords: number;
    Town: string;
    UserName: string;
    VATNo: string;
}

export const NewOrUpdatedMemberItemModelKeys = {
    AccountName: 'AccountName',
    AccountNo: 'AccountNo',
    Address1: 'Address1',
    Address2: 'Address2',
    Address3: 'Address3',
    BankName: 'BankName',
    CodaCustomerReference: 'CodaCustomerReference',
    CodaSupplierReference: 'CodaSupplierReference',
    Company: 'Company',
    Company_RegistrationNumber: 'Company_RegistrationNumber',
    Company_TradingStatus: 'Company_TradingStatus',
    Country: 'Company_RegistrationNumber',
    County: 'County',
    Email: 'Email',
    Email_InvoiceForJobSent: 'Email_InvoiceForJobSent',
    Id: 'MemberID',
    Landline: 'Landline',
    MemberType: 'MemberType',
    Mobile: 'Mobile',
    PayStartDate: 'PayStartDate',
    PaymentMethod: 'PaymentMethod',
    PaymentReference: 'PaymentReference',
    PostCode: 'PostCode',
    RegisteredAddress1: 'RegisteredAddress1',
    RegisteredAddress2: 'RegisteredAddress2',
    RegisteredAddress3: 'RegisteredAddress3',
    RegisteredCountry: 'RegisteredCountry',
    RegisteredCounty: 'RegisteredCounty',
    RegisteredPostCode: 'RegisteredPostCode',
    RegisteredTown: 'RegisteredTown',
    SortCode: 'SortCode',
    TotalRecords: 'TotalRecords',
    Town: 'Town',
    UserName: 'UserName',
    VATNo: 'VatNo',
}