import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, useAlert } from '../../../../../Components';
import { FormControlKeys } from '../../../../../Components/FormControls';
import { FieldSetHeader } from '../../../../../Components/Formik';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
     JobsRequestHistoryModel,
     JobViewModel,
     MemberSearchFilter,
     UpdateJobRequestMoreQuoteModel,
} from '../../../../../Models/Jobs/EditJob';
import { getFormattedDateWithYearAndTime } from '../../../../../Utils';
import { JobStatus } from '../../../../../Utils/Enums';
import { usePutUpdateJobRequestMoreQuotes } from '../Hooks';

const Section6RequestHistory: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { jobID, job, requestHistory } = props;

     const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
     const [filter, setFilter] = useState<MemberSearchFilter>({} as MemberSearchFilter);
     const [jobCompleted, setJobCompleted] = useState<boolean>(false);
     const [jobBooked, setJobBooked] = useState<boolean>(false);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hook */
     const {
          mutate: requestMoreQuotes,
          data: requestMoreQuotesResponse,
          error: updateJobRequestMoreQuotesError,
     } = usePutUpdateJobRequestMoreQuotes();

     /** useEffect */
     useEffect(() => {
          if (requestMoreQuotesResponse) {
               setAlert({ ...alert, show: true, header: 'Request More Quotes', body: 'Success! Suppliers have been emailed.' });
          }
     }, [requestMoreQuotesResponse]);

     useEffect(() => {
          if (updateJobRequestMoreQuotesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: updateJobRequestMoreQuotesError.Subject,
                    body: updateJobRequestMoreQuotesError.Description,
               });
          }
     }, [updateJobRequestMoreQuotesError]);

     useEffect(() => {
          if (job.JobStatus === JobStatus.CompletedJob) setJobCompleted(true);
          if (job.JobStatus === JobStatus.BookedJob) setJobBooked(true);
     }, [job]);

     /** Methods */
     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {
               let payload: UpdateJobRequestMoreQuoteModel = { jobID: jobID };
               requestMoreQuotes(payload);
          },
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     const fetchMembers = () => {
          setIsWaitingData(true);
     };

     const getLabel = () => {
          if (isWaitingData)
               return (
                    <span>
                         <i className='fa fa-spinner fa-spin'></i> Waiting...
                    </span>
               );
          return 'Request More Quotes';
     };

     return (
          <>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>Request History</FieldSetHeader>
                              <Alert {...alert} />
                              <table id='id_table_request_history' className='table table-bordered table-condensed table-striped'>
                                   <thead>
                                        <tr>
                                             <td>Request Date</td>
                                             <td>Emails Sent</td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {isWaitingData && <tr>Loading...</tr>}
                                        {!isWaitingData &&
                                             requestHistory?.map((data: JobsRequestHistoryModel, index: number) => {
                                                  const { EmailsSent, RequestDate } = data;
                                                  return (
                                                       <tr id={index.toString()} key={index}>
                                                            <td>{getFormattedDateWithYearAndTime(RequestDate)}</td>
                                                            <td>{EmailsSent}</td>
                                                       </tr>
                                                  );
                                             })}
                                   </tbody>
                              </table>
                              {!jobCompleted && !jobBooked && (
                                   <FormikControl
                                        control={FormControlKeys.Button}
                                        label={getLabel()}
                                        type='submit'
                                        disabled={isWaitingData}
                                   />
                              )}
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     jobID: number;
     job: JobViewModel;
     requestHistory: JobsRequestHistoryModel[];
     showAlert: Function;
};

export default Section6RequestHistory;
