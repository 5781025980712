import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import agent from '../../../../Axios/Agent';
import { FormControlKeys, OptionType } from '../../../../Components/FormControls';
import { InlineBlockColMd5Section, InlineBlockColMd7Section } from '../../../../Components/Formik';
import FormikControl from '../../../../Components/Formik/FormikControl';
import { JobsFilterFormModel, SavedSearch, SearchData } from '../../../../Models/Jobs';
import { getDropdownSalesPersonOptions } from '../../../../Utils';
import { useGetPartnersForDropdown } from '../../Members/EditMember/Tabstrip/Partners/Hooks';
import { useGetAdminUsersForDropdown } from '../EditJob/Hooks';
import GetSavedSearchesTable from './SavedSearchTab';
import { JobFilterDialogKeys } from './Types';
import { Alert, useAlert } from '../../../../Components';
import { JobsFreeTextSearchBy } from '../../Members/Constants';

interface Props {
     initialValues: JobsFilterFormModel;
     savedSearches: SavedSearch[];
     searchFormValues: JobsFilterFormModel;
     clearFilters?: () => void;
     deleteSavedSearch: (searchData: SearchData, SearchName: string) => void;
     handleModalClose: () => void;
     handleSetFilter: (filter: JobsFilterFormModel) => void
     refetchGetJobs: Function;
     runSavedSearch: (searchData: SearchData) => void
}

export default function GetJobsFilterPopupBody({
     initialValues,
     savedSearches,
     searchFormValues,
     clearFilters,
     deleteSavedSearch,
     handleModalClose,
     handleSetFilter,
     refetchGetJobs,
     runSavedSearch,
}: Props) {
     // Fetch data and configure the dropdowns.
     const [partnersForDropdown, setPartnersForDropdown] = useState<OptionType[]>([]);
     const [groupsForDropdown, setGroupsForDropdown] = useState<OptionType[]>([]);
     
     /** Hooks */
     const { alert, setAlert } = useAlert();
     
     /** Query Hooks */
     const { 
          data: adminUsersList 
     } = useGetAdminUsersForDropdown();

     const { 
          data: getPartnersForDropdownResponse,
          error: getPartnersForDropdownError,
     } = useGetPartnersForDropdown();

     const getGroupsForDropdown = async () => {
          const results = await agent.groups.getGroupsForDropdown();
          results.unshift({ value: '', label: 'All Jobs' });
          results.splice(1, 0, { label: 'Group Jobs', value: '0' });
          setGroupsForDropdown(results);
     };
     useEffect(() => {
          if (getPartnersForDropdownResponse?.PartnersDetails) {
               const partners: OptionType[] = getPartnersForDropdownResponse.PartnersDetails.map((x) => ({
                    label: x.Name,
                    value: x.Id,
               }));
               partners.unshift({ label: 'All Jobs', value: '' });
               partners.splice(1, 0, { label: 'Partner Jobs', value: '1' });
               setPartnersForDropdown(partners);
          }
          getGroupsForDropdown();
     }, [getPartnersForDropdownResponse]);

     useEffect(() => {
          if (getPartnersForDropdownError) {
            setAlert({
              ...alert,
              show: true,
              header: getPartnersForDropdownError?.Subject,
              body: getPartnersForDropdownError?.Description,
            });
          }
        }, [getPartnersForDropdownError]);
        

     // Form Handlers
     const handleSubmit = (values: JobsFilterFormModel, { setSubmitting }: FormikHelpers<JobsFilterFormModel>) => {
          // Set the filter with the new values.
          handleSetFilter(values);
          refetchGetJobs();
          setSubmitting(false);
          handleModalClose();
     };

     const formik = useFormik({
          initialValues: searchFormValues,
          onSubmit: handleSubmit,
     });

     const handleReset = useCallback(() => {
          formik.resetForm({ values: { ...initialValues } });

          if(clearFilters) {
               clearFilters();
          }
     }, [initialValues, formik, clearFilters]);

     return (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <Form>
                    <InlineBlockColMd7Section>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>FreeText</label>
                              </Col>
                              <Col sm='3'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.FreeText}
                                        placeholder='Type and Select'
                                   />
                              </Col>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'> Search by</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.FreeTextSearchBy}
                                        control={FormControlKeys.Select}
                                        options={JobsFreeTextSearchBy}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>VRM / VIN</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.VRMorVIN} placeholder='Search by VRM or VIN' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Partner</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.PartnerID}
                                        control={FormControlKeys.Select}
                                        options={partnersForDropdown}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Group</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.GroupID}
                                        control={FormControlKeys.Select}
                                        options={groupsForDropdown}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Sales Person</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.AdminUserID}
                                        control={FormControlKeys.Select}
                                        options={getDropdownSalesPersonOptions(adminUsersList?.Results || [])}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Request Date</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.RequestDateFrom} type='date' placeholder='From' />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.RequestDateTo} type='date' placeholder='To' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Kind of Job</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Standard}
                                        label='Standard'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Return}
                                        label='Return'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Onward}
                                        label='Onward'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MultipleDropOff}
                                        label='Multiple Drop Off'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row xs='auto'>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Movement Type</label>
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypeInternalTransfer}
                                        label='Internal Transfer'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypeDealerTransfer}
                                        label='Dealer Transfer'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypeFleetDelivery}
                                        label='Fleet Delivery'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypeRetailDelivery}
                                        label='Retail Delivery'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'></label>
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypePremiumFleet}
                                        label='Premium Fleet'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.MovementTypePremiumRetail}
                                        label='Premium Retail'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Service Type</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Driven}
                                        label='Driven'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Transported}
                                        label='Transported'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Both}
                                        label='Both'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Vehicles</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.VehiclesMin} placeholder='Min' type='number' />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.VehiclesMax} placeholder='Max' type='number' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>From PostCode</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.FromPostCode} />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>To PostCode</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.ToPostCode} />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Miles</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.DistanceMilesMin} placeholder='Min' type='number' />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.DistanceMilesMax} placeholder='Max' type='number' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Collection Date</label>
                              </Col>
                              <Col sm='3'>
                                   <FormikControl name={JobFilterDialogKeys.CollectionDateFrom} placeholder='From' type='date' />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.CollectionDateTo} placeholder='To' type='date' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Delivery Date</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.DeliveryDateFrom} placeholder='From' type='date' />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl name={JobFilterDialogKeys.DeliveryDateTo} placeholder='To' type='date' />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Type of Job</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.JobTypeQuotes}
                                        label='Quotes'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.JobTypeFixedPrice}
                                        label='Fixed Price'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.JobTypeBoth}
                                        label='Both'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.JobTypeGuaranteedPrice}
                                        label='Guaranteed Price'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Offered To</label>
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.OfferedToWholeNetwork}
                                        label='Whole Network'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.FiveStarCompanies}
                                        label='Five Star Companies'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.SelectedSuppliers}
                                        label='Selected Suppliers'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Status</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Draft}
                                        label='Draft'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.PendingQuotes}
                                        label='Pending'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.AcceptedQuote}
                                        label='Accepted'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.BookedJob}
                                        label='Booked'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'></label>
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.ActiveJob}
                                        label='Active'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.CompletedJob}
                                        label='Completed'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Expired}
                                        label='Expired'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Cancelled}
                                        label='Cancelled'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'></label>
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Aborted}
                                        label='Aborted'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm='2'>
                                   <FormikControl
                                        name={JobFilterDialogKeys.AdminRemoved_OrFailed}
                                        label='Admin Removed / Failed'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                         <Row>
                              <Col sm='3'>
                                   <label className='text-right-fw-600'>Payment Method</label>
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Pending}
                                        label='Pending'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.SmartDebit}
                                        label='Smart Debit'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.Invoice}
                                        label='Invoice'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                              <Col sm={true}>
                                   <FormikControl
                                        name={JobFilterDialogKeys.PrePay}
                                        label='PrePay'
                                        control={FormControlKeys.Checkbox}
                                   />
                              </Col>
                         </Row>
                    </InlineBlockColMd7Section>
                    <InlineBlockColMd5Section>
                         <GetSavedSearchesTable
                              DeleteSavedSearch={deleteSavedSearch}
                              RunSavedSearch={runSavedSearch}
                              SavedSearches={savedSearches}
                         />
                    </InlineBlockColMd5Section>
                    <Row>
                         <Col sm={true}>
                              <FormikControl label='Clear' control={FormControlKeys.Button} onClick={handleReset} />
                         </Col>
                         <Col sm={true}>
                              <FormikControl label='Search' control={FormControlKeys.Button} type='submit' />
                         </Col>
                         <Col sm={true}>
                              <FormikControl label='Close' control={FormControlKeys.Button} onClick={() => handleModalClose()} />
                         </Col>
                    </Row>
               </Form>
          </FormikProvider>
     );
};
