import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const usePutMarkPaymentAsNotPayable = (options?: any): UseMutationResult<any, any, number> => {
     const putMarkPaymentAsNotPayable = (paymentId: number) => {
          return agent.payments.putMarkPaymentAsNotPayable(paymentId);
     };

     return useMutation(putMarkPaymentAsNotPayable);
};
