export type MemberItemModel = {
    TotalRecords: number,
    Id: number,
    MemberType: number,
    Username: string,
    Email: string,
    Notes: string,
    SignupDate: string,
    LastLoginDate: string,
    Disabled: boolean,
    Active: boolean,
    IsKeyAccount?: boolean,
    CodaCustomerReference: string,
    CodaSupplierReference: string,
    Contact_Name1: string,
    Company: string,
    TypeOfBusiness?: number,
    TypeOfBusiness_Other: string,
    Address: string,
    Town: string,
    PostCode: string,
    Country: string,
    Landline: string,
    Mobile: string,
    DrivenInsuranceCoverValue: string,
    DrivenInsuranceRenewalDate?: string,
    TransportedSingleVehicleInsuranceCoverValue?: number,
    TransportedSingleVehicleInsuranceRenewalDate?: string,
    TransportedLoadLimitInsuranceCoverValue?: number,
    TransportedLoadLimitInsuranceRenewalDate?: string,
    ServiceType?: number,
    NoOfDrivers?: number,
    NoOfTransporters?: number,
    PaySuccSelected: boolean,
    PaymentMethod?: number,
    JobsAdded: number,
    JobsAddedAndDone: number,
    JobsQuotedOn: number,
    JobsWorkedOnAndDone: number,
    NoOfBlocks: number,
    PositiveFeedback: number,
    IsFiveStar: boolean,
}

export const MemberModelKeys = {
    TotalRecords: "TotalRecords",
    Id: "Id",
    MemberType: "MemberType",
    Username: "Username",
    Email: "Email",
    Notes: "Notes",
    SignupDate: "SignupDate",
    LastLoginDate: "LastLoginDate",
    Disabled: "Disabled",
    Active: "Active",
    IsKeyAccount: "IsKeyAccount",
    CodaCustomerReference: "CodaCustomerReference",
    CodaSupplierReference: "CodaSupplierReference",
    Contact_Name1: 'Contact_Name1',
    Company: "Company",
    TypeOfBusiness: "TypeOfBusiness",
    TypeOfBusiness_Other: "TypeOfBusiness_Other",
    Address: "Address",
    Town: "Town",
    PostCode: "PostCode",
    Country: "Country",
    Landline: "Landline",
    Mobile: "Mobile",
    DrivenInsuranceCoverValue: "DrivenInsuranceCoverValue",
    DrivenInsuranceRenewalDate: "DrivenInsuranceRenewalDate",
    TransportedSingleVehicleInsuranceCoverValue: "TransportedSingleVehicleInsuranceCoverValue",
    TransportedSingleVehicleInsuranceRenewalDate: "TransportedSingleVehicleInsuranceRenewalDate",
    TransportedLoadLimitInsuranceCoverValue: "TransportedLoadLimitInsuranceCoverValue",
    TransportedLoadLimitInsuranceRenewalDate: "TransportedLoadLimitInsuranceRenewalDate",
    ServiceType: "ServiceType",
    NoOfDrivers: "NoOfDrivers",
    NoOfTransporters: "NoOfTransporters",
    PaySuccSelected: "PaySuccSelected",
    PaymentMethod: "PaymentMethod",
    JobsAdded: "JobsAdded",
    JobsAddedAndDone: "JobsAddedAndDone",
    JobsQuotedOn: "JobsQuotedOn",
    JobsWorkedOnAndDone: "JobsWorkedOnAndDone",
    NoOfBlocks: "NoOfBlocks",
    PositiveFeedback: "PositiveFeedback",
    IsFiveStar: "IsFiveStar",
}