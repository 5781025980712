import * as React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

const rows: GridRowsProp = [
  { id: 1, col1: "CANEISVWMOX01D:3764", col2: "10", col3: "DEFAULT", col4: "4 hours ago", col5: "19 mins ago" },
];

const columns: GridColDef[] = [
  { field: "id", hide: true },
  { field: "col1", headerName: "Name", width: 400 },
  { field: "col2", headerName: "Workers", width: 150 },
  { field: "col3", headerName: "Queues", width: 200 },
  { field: "col4", headerName: "Started", width: 150 },
  { field: "col5", headerName: "Heartbeat", width: 150 },
];

const Servers: React.FC = (): JSX.Element => {
  return (
    <div id='id_grid_scheduling_services' style={{ width: "100%",height: 170, paddingInline: "10%", marginTop: "5%" }}>
      <DataGrid 
        sortingOrder={['desc', 'asc']}
        rows={rows} 
        columns={columns} 
        autoPageSize 
        hideFooterPagination  />
    </div>
  );
};
export default Servers;
