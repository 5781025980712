/** Import Inbuilt libraries */
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object, ref, string } from 'yup';
/** Custom components */
import agent from '../../../../Axios/Agent';
import MovexResponseError from '../../../../Axios/MovexResponseError';

import {
  Alert,
  AlertProps,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  ShowPleaseWait,
  useAlert,
} from '../../../../Components';
import {
  FormControlKeys,
  OptionType,
} from '../../../../Components/FormControls/Types';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
  ColMd6SectionInner,
  DivContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormContainer,
  FormFieldSetContainer,
  HoverableLink,
  InlineBlockColMd6Section,
  QuickLinkItem,
} from '../../../../Components/Formik/StyledComponents';
import {
  AddGroupBlockModel,
  AddGroupFavouriteModel,
  AddGroupModel,
} from '../../../../Models/Groups';
import { RouteConstants } from '../../../../Routes/Constants';
import {
  convertNullsToEmptyStrings,
  isMovexErrorResponse,
  isSuccessEntityCreatedOrUpdatedResponse,
} from '../../../../Utils';
import { MemberType, RequestingMemberFeeType } from '../../../../Utils/Enums';
import withAddForm from '../../Common/AddForm/withAddForm';
import MarginMaintenance from '../../Common/MarginMaintenance';
import { useGetMembersForDropdown } from '../../Members/EditMember/Tabstrip/MemberBlocks/Hooks';
import { GroupActiveStatusOptions } from '../Constants';
import GroupSavedAddress from './GroupSavedAddress';
import {
  useDeleteGroupBlock,
  useDeleteGroupFavourite,
  useGetGroup,
  usePostAddGroup,
  usePutGroupBlock,
  usePutGroupFavourite,
  usePutUpdateGroup,
} from './Hooks';
import './index.css';
import { AddGroupKeys, GroupIDParamType } from './Types';

const validationSchema = object({
  [AddGroupKeys.UserName]: string().required(),
  [AddGroupKeys.Email]: string().required(),
  [AddGroupKeys.Password]: string(),
  [AddGroupKeys.ConfirmPassword]: string().oneOf(
    [ref('Password')],
    'Password and confirmation password do not match!'
  ),
});

const AddGroup: React.FC = () => {
  const { groupID: paramGroupID } = useParams<GroupIDParamType>();
  const groupID = paramGroupID ? parseInt(paramGroupID) : 0;
  const isEditMode = groupID > 0;
  const history = useHistory();
  const membersLink = groupID
    ? `${RouteConstants.MembersByGroup}=${groupID}`
    : `${RouteConstants.Members}`;
  const jobsLink = groupID
    ? `${RouteConstants.JobsByGroup}=${groupID}`
    : `${RouteConstants.Jobs}`;

  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };
  const addGroupFormInitialValues = {
    Active: false,
    ConfirmPassword: '',
    Email: '',
    Id: 0,
    Notes: '',
    ParentId: 0,
    Password: '',
    UserName: '',
  };

  /** useStates */
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });

  const [formikInitialValues, setFormikInitialValues] =
    useState<AddGroupFormModel>(addGroupFormInitialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [groupsForDropdown, setGroupsForDropdown] = useState<OptionType[]>([]);

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Methods */
  const getGroupsForDropdown = () => {
    agent.groups.getGroupsForDropdown().then((results) => {
      setGroupsForDropdown(results);
    });
  };

  const showAlert = (header: string, body: string): void => {
    setAlert({
      ...alert,
      show: true,
      header: header,
      body: body,
    });
  };

  const onSubmit = async (payload: AddGroupModel) => {
    if (isEditMode) {
      updateGroup(payload);
    } else {
      payload.Id = null;
      addGroup(payload);
    }
  };

  const getTransportCompanyMemberOptions = () => {
    const options: OptionType[] = [];
    transportCompanies.forEach((member) => {
      let className = '';
      if (!member.HasAcceptedCurrentTsAndCs) className += 'tsandcs-invalid ';
      if (!member.PaySuccSelected) className += ' payment-not-setup';
      if (!member.Active) className += ' inactive';
      if (member.Disabled) className += ' disabled';
      options.push({
        value: member.Id,
        label: member.UserName,
        className: className,
      });
    });
    return options;
  };

  const addMemberFavorite = (favouriteMemberID?: number) => {
    const payload: AddGroupFavouriteModel = {
      FavouriteMemberID: favouriteMemberID,
      GroupID: groupID,
    };
    putGroupFavourite(payload);
    formik.setFieldValue(AddGroupKeys.SelectedFavouriteMember, -1);
  };

  const removeMemberFavorite = (favouriteMemberID?: string) => {
    const payload: AddGroupFavouriteModel = {
      FavouriteMemberID: favouriteMemberID ? parseInt(favouriteMemberID) : 0,
      GroupID: groupID,
    };
    deleteGroupFavourite(payload);
    formik.setFieldValue(AddGroupKeys.SelectedFavouriteMember, -1);
  };

  const addMemberBlock = (blockedMemberID?: string) => {
    const payload: AddGroupBlockModel = {
      BlockedMemberID: blockedMemberID ? parseInt(blockedMemberID) : 0,
      GroupID: groupID,
    };
    putGroupBlock(payload);
    formik.setFieldValue(AddGroupKeys.SelectedFavouriteBlock, -1);
  };

  const removeMemberBlock = (blockedMemberID?: string) => {
    const payload: AddGroupBlockModel = {
      BlockedMemberID: blockedMemberID ? parseInt(blockedMemberID) : 0,
      GroupID: groupID,
    };
    deleteGroupBlock(payload);
    formik.setFieldValue(AddGroupKeys.SelectedFavouriteBlock, -1);
  };

  const onGroupSaveSuccess = () => {
    history.push(RouteConstants.ViewGroups);
  };

  /** Query Hooks */
  const {
    isLoading: isGetGroupLoading,
    data: getGroupData,
    refetch: refetchGetGroup,
    error: getGroupError,
  } = useGetGroup(groupID);

  const {
    mutate: putGroupFavourite,
    isLoading: isPutGroupFavouriteLoading,
    data: putGroupFavouriteResponse,
    error: putGroupFavouriteError,
  } = usePutGroupFavourite();

  const {
    mutate: putGroupBlock,
    isLoading: isPutGroupBlockLoading,
    data: putGroupBlockResponse,
    error: putGroupBlockError,
  } = usePutGroupBlock();

  const {
    mutate: deleteGroupFavourite,
    isLoading: isDeleteGroupFavouriteLoading,
    data: deleteGroupFavouriteResponse,
    error: deleteGroupFavouriteError,
  } = useDeleteGroupFavourite();

  const {
    mutate: deleteGroupBlock,
    isLoading: isDeleteGroupBlockLoading,
    data: deleteGroupBlockResponse,
    error: deleteGroupBlockError,
  } = useDeleteGroupBlock();

  const { data: transportCompanies = [] } = useGetMembersForDropdown(
    MemberType.TransportCompany
  );

  const {
    mutate: updateGroup,
    isLoading: isUpdateGroupLoading,
    data: updateGroupResponse,
    error: updateGroupError,
  } = usePutUpdateGroup();

  const {
    mutate: addGroup,
    isLoading: isAddGroupLoading,
    data: addGroupResponse,
    error: addGroupError,
  } = usePostAddGroup();

  /** useEffects */
  useEffect(() => {
    if (getGroupData) {
      const { Group } = getGroupData;
      convertNullsToEmptyStrings(Group);
      setFormikInitialValues({
        ...formikInitialValues,
        ...Group,
        Password: '',
        ConfirmPassword: '',
      });
    }
  }, [getGroupData]);

  useEffect(() => {
    getGroupsForDropdown();
  }, []);

  useEffect(() => {
    setShowPleaseWait(
      isPutGroupFavouriteLoading ||
        isPutGroupBlockLoading ||
        isDeleteGroupFavouriteLoading ||
        isDeleteGroupBlockLoading
    );
  }, [
    isPutGroupFavouriteLoading,
    isPutGroupBlockLoading,
    isDeleteGroupFavouriteLoading,
    isDeleteGroupBlockLoading,
  ]);

  useEffect(() => {
    const isErrorPresent = [
      getGroupError,
      putGroupFavouriteError,
      putGroupBlockError,
      deleteGroupFavouriteError,
      deleteGroupBlockError,
      updateGroupError,
      addGroupError,
    ].some(error => error !== null);
  
    if (isErrorPresent) {
      setAlert({
        ...alert,
        show: true,
        header: getGroupError?.Subject
          || putGroupFavouriteError?.Subject
          || putGroupBlockError?.Subject
          || deleteGroupFavouriteError?.Subject
          || deleteGroupBlockError?.Subject
          || updateGroupError?.Subject
          || addGroupError?.Subject,
        body: getGroupError?.Description
          || putGroupFavouriteError?.Description
          || putGroupBlockError?.Description
          || deleteGroupFavouriteError?.Description
          || deleteGroupBlockError?.Description
          || updateGroupError?.Description
          || addGroupError?.Description,
      });
    }
  }, [
    getGroupError,
    putGroupFavouriteError,
    putGroupBlockError,
    deleteGroupFavouriteError,
    deleteGroupBlockError,
    updateGroupError,
    addGroupError,
  ]);

  useEffect(() => {
    if (groupID) {
      refetchGetGroup();
    } else {
      setFormikInitialValues(addGroupFormInitialValues);
    }
  }, [groupID]);

  useEffect(() => {
    setIsLoading(isGetGroupLoading);
  }, [isGetGroupLoading]);

  useEffect(() => {
    setShowPleaseWait(isAddGroupLoading || isUpdateGroupLoading);
  }, [isAddGroupLoading, isUpdateGroupLoading]);

  useEffect(() => {
    if (putGroupFavouriteResponse) {
      showAlert(
        'Add Favourite Member',
        putGroupFavouriteResponse.ResponseMessage
      );
    }
  }, [putGroupFavouriteResponse]);

  useEffect(() => {
    if (deleteGroupFavouriteResponse) {
      showAlert(
        'Remove Favourite Member',
        deleteGroupFavouriteResponse.ResponseMessage
      );
    }
  }, [deleteGroupFavouriteResponse]);

  useEffect(() => {
    if (putGroupBlockResponse) {
      showAlert('Add Blocked Member', putGroupBlockResponse.ResponseMessage);
    }
  }, [putGroupBlockResponse]);

  useEffect(() => {
    if (deleteGroupBlockResponse) {
      showAlert(
        'Remove Blocked Member',
        deleteGroupBlockResponse.ResponseMessage
      );
    }
  }, [deleteGroupBlockResponse]);

  useEffect(() => {
    if (addGroupError) {
      if (isMovexErrorResponse(addGroupError)) {
        setAlert({
          ...alert,
          show: true,
          header: MovexResponseError.getMovexErrorContent(addGroupError).header,
        });
      }
    }
  }, [addGroupError]);

  useEffect(() => {
    if (isSuccessEntityCreatedOrUpdatedResponse(addGroupResponse)) {
      setAlert({
        ...alert,
        show: true,
        body: 'Save Successful!',
        closeCallback: onGroupSaveSuccess,
      });
    }
  }, [addGroupResponse]);

  useEffect(() => {
    if (updateGroupError) {
      if (isMovexErrorResponse(updateGroupError)) {
        setAlert({
          ...alert,
          show: true,
          header: MovexResponseError.getMovexErrorContent(updateGroupError).header,
        });
      }
    }
  }, [updateGroupError]);

  useEffect(() => {
    if (isSuccessEntityCreatedOrUpdatedResponse(updateGroupResponse)) {
      setAlert({
        ...alert,
        show: true,
        body: 'Save Successful!',
        closeCallback: onGroupSaveSuccess,
      });
    }
  }, [updateGroupResponse]);

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, values } = formik;
  const disableFavoriteMember =
  values?.FavouriteMemberID === undefined || values?.FavouriteMemberID === null || values?.FavouriteMemberID < 0;
  const disableBlockMember =
    values?.BlockedMemberID === null || values?.BlockedMemberID === undefined || values?.BlockedMemberID < 0;

  const component = (
    <FormikProvider value={formik}>
      <Alert {...alert} />
      <ShowPleaseWait show={showPleaseWait} />
      <Loading loading={isLoading} />
      <ConfirmDialog {...confirmDialogContent} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <FormFieldSetContainer>
          <FieldSetContainer>
            <FieldSetHeader>Quick Links</FieldSetHeader>
            <QuickLinkItem>
              <HoverableLink to={membersLink} target="_blank">
                View Members
              </HoverableLink>
            </QuickLinkItem>
            <QuickLinkItem>
              <HoverableLink to={jobsLink}>View Jobs</HoverableLink>
            </QuickLinkItem>
          </FieldSetContainer>
          <FieldSetContainer>
            <FlexContainer>
              <InlineBlockColMd6Section>
                <ColMd6SectionInner>
                  <FieldSetHeader>
                    Login & Membership Information
                  </FieldSetHeader>
                  <FormikControl
                    name={AddGroupKeys.UserName}
                    label="UserName"
                    disabled={isEditMode}
                  />
                  <FormikControl name={AddGroupKeys.Email} label="Email" />
                  <FormikControl
                    control={FormControlKeys.ClickToUpdateField}
                    name={AddGroupKeys.Password}
                    label="Password"
                    type="password"
                  />
                  <FormikControl
                    control={FormControlKeys.ClickToUpdateField}
                    name={AddGroupKeys.ConfirmPassword}
                    label="Confirm Password"
                    type="password"
                  />
                  <FormikControl
                    name={AddGroupKeys.Active}
                    control={FormControlKeys.RadioGroup}
                    options={GroupActiveStatusOptions}
                    label="Active Status"
                  />
                </ColMd6SectionInner>
              </InlineBlockColMd6Section>
              <InlineBlockColMd6Section>
                <ColMd6SectionInner>
                  <DivContainer>
                    {' '}
                    <FieldSetHeader>
                      Group Level Member Functions
                    </FieldSetHeader>
                    <DivContainer>
                      <FormikControl
                        control={FormControlKeys.Select}
                        name={AddGroupKeys.SelectedFavouriteMember}
                        label="Add / Remove Favourite Members"
                        placeholder="Select"
                        options={getTransportCompanyMemberOptions()}
                      />
                      <FormikControl
                        control={FormControlKeys.Button}
                        name={AddGroupKeys.AddFavouriteMember}
                        label="Add Favourite"
                        className="btn btn-sm btn-primary"
                        disabled={disableFavoriteMember}
                        onClick={() =>
                          addMemberFavorite(values.FavouriteMemberID)
                        }
                      />
                      <FormikControl
                        control={FormControlKeys.Button}
                        placeholder="Select"
                        name={AddGroupKeys.RemoveFavouriteMember}
                        label="Remove Favourite"
                        className="btn btn-sm btn-danger btn-movex-cancel"
                        disabled={disableFavoriteMember}
                        onClick={() =>
                          removeMemberFavorite(
                            values.FavouriteMemberID?.toString()
                          )
                        }
                      />
                    </DivContainer>
                    <FormikControl
                      control={FormControlKeys.Select}
                      name={AddGroupKeys.SelectedFavouriteBlock}
                      options={getTransportCompanyMemberOptions()}
                      placeholder="Select"
                      label="Add / Remove  Blocked Members"
                    />
                    <FormikControl
                      control={FormControlKeys.Button}
                      name={AddGroupKeys.AddFavouriteBlock}
                      label="Add Block"
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        addMemberBlock(values.BlockedMemberID?.toString())
                      }
                      disabled={disableBlockMember}
                    />
                    <FormikControl
                      control={FormControlKeys.Button}
                      placeholder="Select"
                      name={AddGroupKeys.RemoveFavouriteBlock}
                      label="Remove Block"
                      className="btn btn-sm btn-danger btn-movex-cancel"
                      disabled={disableBlockMember}
                      onClick={() =>
                        removeMemberBlock(values.BlockedMemberID?.toString())
                      }
                    />
                  </DivContainer>
                </ColMd6SectionInner>
              </InlineBlockColMd6Section>
            </FlexContainer>
          </FieldSetContainer>
          <FieldSetContainer>
            <FlexContainer>
              <InlineBlockColMd6Section>
                <ColMd6SectionInner>
                  <FieldSetHeader>Group Hierarchy</FieldSetHeader>
                  <FormikControl
                    control={FormControlKeys.Select}
                    name={AddGroupKeys.ParentId}
                    label="Parent Group"
                    placeholder="No Parent Group"
                    options={groupsForDropdown}
                  />
                  <FieldSetHeader>Notes for admin use</FieldSetHeader>
                  <FormikControl
                    control={FormControlKeys.Textarea}
                    name={AddGroupKeys.Notes}
                    label="Points to Note"
                  />
                </ColMd6SectionInner>
              </InlineBlockColMd6Section>
              <InlineBlockColMd6Section>
                <ColMd6SectionInner>
                  <FieldSetHeader>Group Margin</FieldSetHeader>
                  <DivContainer>
                    <MarginMaintenance
                      requestingMemberFeeType={RequestingMemberFeeType.Group}
                      memberID={groupID}
                    />
                  </DivContainer>
                </ColMd6SectionInner>
              </InlineBlockColMd6Section>
            </FlexContainer>
          </FieldSetContainer>
          <GroupSavedAddress groupID={groupID} groupData={getGroupData} />
          <FieldSetContainer>
            <FormikControl
              name="AddGroupButton"
              control={FormControlKeys.Button}
              type="submit"
              label={isEditMode ? 'Update Group' : 'Add Group'}
              style={{ margin: '10px 0' }}
            />
          </FieldSetContainer>
        </FormFieldSetContainer>
      </FormContainer>
    </FormikProvider>
  );

  return withAddForm(component, isEditMode ? 'Edit Group' : 'Add Group');
};

export default AddGroup;

type AddGroupFormModel = AddGroupModel &
  AddGroupFavouriteModel &
  AddGroupBlockModel;
