import { GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Alert, Loading, renderHeader, useAlert } from "../../../../../Components";
import { EmailServiceModelKeys } from "../../../../../Models/System/EmailService";
import {
  CommonRequestParam,
  FreeTextFilter,
  Pager,
} from "../../../../../Types";
import { getSorterFromSortModel, Page } from "../../../../../Utils";
import { SqlStringMatchType } from "../../../../../Utils/Enums";
import EmailServiceGridView from "./Grid";
import { useGetEmailServiceList } from "./Hooks";

const ViewEmailService: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FreeTextFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: EmailServiceModelKeys.Id,
      sort: "asc",
    },
  ]);

  const getParams = () => {
    const params: CommonRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: FreeTextFilter) => {
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setFilter({});
  };
  const pageChangeHandler = (currentPage: number) => {};
  const pageSizeChangeHandler = (itemsPerPage: number) => {};
  const onSort = (sortModel: GridSortModel) => {};

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    data: emailServiceListResponse,
    isLoading: isEmailServiceListLoading,
    refetch: refetchEmailServiceList,
    error: getEmailServiceListError,
  } = useGetEmailServiceList(getParams());

  const isDataLoaded = emailServiceListResponse !== null;

  /** useEffects */
  useEffect(() => {
    refetchEmailServiceList();
  }, [filter, pager, sortModel]);

  useEffect(() => {
    setIsLoading(isEmailServiceListLoading);
  }, [isEmailServiceListLoading]);

  useEffect(() => {
    if (getEmailServiceListError) {
        setAlert({
            ...alert,
            show: true,
            header: getEmailServiceListError?.Subject ? getEmailServiceListError?.Subject : "Error",
            body: getEmailServiceListError?.Description ? getEmailServiceListError?.Description : "Error while fetching the Email Service list."
        });
    }
}, [getEmailServiceListError]);


  return (
    <div>
      {renderHeader("Current Email Queue")}
      <div className="container-fluid">
        <Loading loading={isLoading} />
        <Alert {...alert} />
        {isDataLoaded && (
          <EmailServiceGridView
            data={emailServiceListResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewEmailService;
