import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { AddAdminUserModel } from '../../../../../../Models/System';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../Types';

export const usePostAddAdministrator = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddAdminUserModel
> => {

  const addAdministrator = (payload: AddAdminUserModel) => {
    return agent.admin.postAddAdministrator(payload);
  };

  return useMutation(addAdministrator);
};
