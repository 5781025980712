import { useQuery, UseQueryResult } from "react-query";
import agent from "../../../../../Axios/Agent";

export const useGetStorageServiceSasToken = (
  options?: any
): UseQueryResult<string, any> => {
  const getStorageServiceSasToken = () => {
    return agent.admin.getStorageServiceSasToken();
  };

  return useQuery(["GetStorageSasToken"], getStorageServiceSasToken, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
    ...options,
  });
};
