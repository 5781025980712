import { GridSortModel } from '@mui/x-data-grid';
import React,{ useEffect,useState } from 'react';
import { Alert,ConfirmDialog,Loading,renderHeader,useAlert } from '../../../../../Components';
import { useConfirmDialog } from '../../../../../Components/ConfirmDialog/useConfirmDialog';
import { AdministratorModelKeys } from '../../../../../Models/System/Administrators';
import { CommonRequestParam,FreeTextFilter,Pager } from '../../../../../Types';
import { getSorterFromSortModel,Page } from '../../../../../Utils';
import { SqlStringMatchType } from '../../../../../Utils/Enums';
import FilterBox from '../../../Groups/ViewGroups/FilterBox';
import AdministratorGridView from './Grid';
import { useDeleteAdministrator,useGetAdministratorList } from './Hooks';

const ViewAdministrators: React.FC = (): JSX.Element => {
     /** States Hooks*/
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [filter, setFilter] = useState<FreeTextFilter>({SqlStringMatchType: SqlStringMatchType.Contains});
     const [pager, setPager] = useState<Pager>({
          CurrentPage: Page.CurrentPage,
          ItemsPerPage: Page.PageSize,
     });
     const [sortModel, setSortModel] = useState<GridSortModel>([
          {
               field: AdministratorModelKeys.Id,
               sort: 'asc',
          },
     ]);

     /** Functions */
     const getParams = () => {
          const params: CommonRequestParam = {
               Pager: pager,
               Filter: filter,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };
     const handleSearch = (filter: FreeTextFilter) => {
          setFilter(filter);
     };
     const handleClearSearch = () => {
          setFilter({});
     };
     const handleDelete = (id: number) => {
          openConfirmDialog(
               'Delete Administrator',
               'Are you sure you want to delete this administrator?',
               () => {
                    deleteAdministrator(id);
                    closeConfirmDialog();
               }
          );
     };
     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
     };
     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
     };
     const onSort = (sortModel: GridSortModel) => {
          setPager({ ...pager, CurrentPage: 1 });
          setSortModel(sortModel);
     };

     /**Custom Hooks */
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();
     const { alert, setAlert } = useAlert();
     
     /**Query Hooks */
     const {
          data: administratorListResponse,
          isLoading: isAdministratorListLoading,
          refetch: refetchAdministratorList,
          error: getAdministratorListError,
     } = useGetAdministratorList(getParams());
     const {
          mutate: deleteAdministrator,
          error: deleteAdministratorError,
     } = useDeleteAdministrator();

     const isDataLoaded = administratorListResponse !== null;

     /** useEffects Hooks */
     useEffect(() => {
          refetchAdministratorList();
     }, [filter, pager, sortModel]);

     useEffect(() => {
          setIsLoading(isAdministratorListLoading);
     }, [isAdministratorListLoading]);

     useEffect(() => {
          if (getAdministratorListError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getAdministratorListError?.Subject ?? 'Error',
                    body: getAdministratorListError?.Description ?? 'Error while fetching the list of administrators.',
               });
          }
     }, [getAdministratorListError]);

     useEffect(() => {
          if (deleteAdministratorError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: deleteAdministratorError?.Subject ?? 'Error',
                    body: deleteAdministratorError?.Description ?? 'Error while trying to delete administrator.',
               });
          }
     }, [deleteAdministratorError]);
        

     return (
          <div>
               {renderHeader('Administrators')}
               <div className='container-fluid'>
                    <ConfirmDialog {...confirmDialogProps} />
                    <Alert {...alert} />
                    <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
                    <Loading loading={isLoading} />

                    {isDataLoaded && (
                         <AdministratorGridView
                              data={administratorListResponse}
                              onSort={onSort}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onDelete={handleDelete}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewAdministrators;
