/** Import inbuilt libraries */
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import { JobStatisticsResponseModel } from '../../../../../Models/Reports/Statistics';
import { GridProps } from '../../../../../Types';
import { ViewStatisticsGridKeys } from '../Types';

const JobStatisticsGridView: React.FC<JobStatisticsGridViewProps> = (props) => {
  const { data, onPageChange, onPageSizeChange, onSort } = props;

  const columns: GridColDef[] = [
    {
      field: ViewStatisticsGridKeys.Label,
      headerName: 'Label',
      description: 'Label',
      renderCell: (params: GridRenderCellParams) => renderCell(params),
      flex: 1,
    },
    {
      field: ViewStatisticsGridKeys.Value,
      headerName: 'Value',
      description: 'Value',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => renderCell(params),
    },
  ];

  const renderCell = (params: GridRenderCellParams) => {
    return (
      <Tooltip title={params.value || ''}>
        <span>{params.value}</span>
      </Tooltip>
    );
  };

  if (data?.JobStatistics) {
    return (
      <div>
        {data?.JobStatistics && (
          <div className="job-statistics-grid-container">
            <div id='id_grid_statistics'>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                autoHeight
                getRowId={(row: any) => row.Label}
                rows={data?.JobStatistics}
                rowHeight={41}
                disableColumnMenu={true}
                columns={columns}
                columnBuffer={columns.length + 1}
                disableExtendRowFullWidth={true}
                sortingMode="server"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default JobStatisticsGridView;

interface JobStatisticsGridViewProps
  extends GridProps<JobStatisticsResponseModel> {}
