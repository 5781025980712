import React from 'react';
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ControlledPagination as Pagination } from '../../../../../../Components';
import {
  AdministratorModelKeys,
  AdministratorResponseModel,
} from '../../../../../../Models/System';
import { GridProps, Sorter } from '../../../../../../Types';
import {
  ActionColumnPath,
  getSortModelFromSorter,
} from '../../../../../../Utils';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { AdmonistratorGridColumns } from './columns';

const AdministratorGridView: React.FC<AdministratorsGridProps> = (props) => {
  const {
    data: administrators,
    onPageChange,
    onPageSizeChange,
    onSort,
    onDelete,
  } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (administrators?.Page) {
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = administrators?.Page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      );
    };

    return (
      <div>
        {administrators?.Page && (
          <div id='id_grid_administrators' className="view-administrators-container">
            <DataGrid
              sortingOrder={['desc', 'asc']}
              autoHeight
              getRowId={(row: any) => row.Id}
              disableColumnMenu={true}
              rows={Items}
              rowHeight={30}
              columns={AdmonistratorGridColumns({onDelete})}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
              sortModel={getSortModelFromSorter(administrators?.TableSorter as Sorter)}
              onSortModelChange={handleSort}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

interface AdministratorsGridProps
  extends GridProps<AdministratorResponseModel> {
    onDelete: ((id : number) => void)
  }

export default AdministratorGridView;
