import { GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, ConfirmDialog, ConfirmDialogProps, Loading, ModalDialog, ModalDialogProps, renderHeader, useAlert } from '../../../../Components';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';
import { SessionStorageKeys, useSessionStorage } from '../../../../Hooks';
import { AdminUserDataModel, UserData } from '../../../../Models/Admin';
import {
     JobGridKeys,
     JobsFilterFormModel,
     JobsFilterFormValueModel,
     JobsRequestParam,
     JobsResponseModel,
     SavedSearch,
     SearchData,
} from '../../../../Models/Jobs/ViewJobs';
import { Pager, Sorter } from '../../../../Types';
import { getSorterFromSortModel, Page } from '../../../../Utils';
import { FreeTextSearchByOptions } from '../../../../Utils/Enums/FreeTextSearchBy';
import ViewJobsGridView from './Grid';
import { getJobFiltersFromSavedSearchModel } from './helper';
import { useGetJobs, useGetSavedSearches, usePutUpdateAdminUserData } from './Hooks';
import './index.css';
import GetJobsFilterPopupBody from './JobsFilterPopupBody';

interface SessionStorageJobSearchFormValues {
     filters: JobsFilterFormModel;
     selectedQuickSearch: string;
     sortModel: GridSortModel;
}

const ViewJobs: React.FC = (): JSX.Element => {

     const location = useLocation();
     const queryParams = new URLSearchParams(location.search);
     const memberID = queryParams.get('memberID');
     const groupID = queryParams.get('groupID');

     /** Confirm dialog */
     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };
     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });

     /** Initial Form values */
     let formValues = {
          Freetext: '',
          FreeTextSearchBy: null as FreeTextSearchByOptions | null,
          PendingQuotes: true,
          AcceptedQuote: true,
          BookedJob: true,
          ActiveJob: true,
     };

     /** States */
     const [filterModuleVisible, setFilterModuleVisible] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [pager, setPager] = useState<Pager>({ CurrentPage: Page.CurrentPage, ItemsPerPage: Page.PageSize });
     const [refreshModule, setRefreshModule] = useState<boolean>(false);
     const [refreshSearchesState, setRefreshSearchesState] = useState<boolean>(false);
     const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([] as SavedSearch[]);
     const [searchFormValues, setSearchFormValues] = useState<JobsFilterFormModel>(formValues);
     const [selectedQuickSearch, setSelectedQuickSearch] = useState('');
     const [sortModel, setSortModel] = useState<GridSortModel>([{ field: JobGridKeys.RequestDate, sort: 'desc', },]);
     const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

     /** GetParam for sending the request */
     const getParams = (): JobsRequestParam => {
          const jobFilterImpl = new JobsFilterFormValueModel();
          Object.assign(jobFilterImpl, searchFormValues);

          const params: JobsRequestParam = {
               Pager: pager,
               Filter: jobFilterImpl.toRequestBody(), // Default Filter
               Sorter: getSorterFromSortModel(sortModel),
          };

          // Modify params.Filter based on conditions
          if (memberID) {
               params.Filter = { MemberID: parseInt(memberID) };
          } else if (groupID) {
               params.Filter = { GroupID: parseInt(groupID) };
          }

          return params;
     };

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     const [
          getSessionStorageSearchFormValues,
          setSessionStorageSearchFormValues,
          deleteSessionStorageSearchFormValues,
     ] = useSessionStorage<SessionStorageJobSearchFormValues>(SessionStorageKeys.ViewJobsPageSearchFilter);

     /** Query Hooks */
     const {
          mutate: updateAdminUserData,
          data: adminUserResponse,
          error: adminUserError,
     } = usePutUpdateAdminUserData();

     const rqOption = { enabled: false };

     const {
          data: getJobsResponse,
          isLoading: isGetJobsLoading,
          refetch: refetchGetJobs,
          error: getJobsError
     } = useGetJobs(getParams(), rqOption);

     const isDataLoaded = getJobsResponse != null;

     const {
          data: savedSearchesResult,
          refetch: refetchGetSavedSearches,
          error: getSavedSearchesError
     } = useGetSavedSearches();

     /** Functions */
     const handleModalClose = () => {
          setModalContent({ ...modalContent, show: false });
          setFilterModuleVisible(false);
     };

     const [modalContent, setModalContent] = useState<ModalDialogProps>({
          header: '',
          body: '',
          show: false,
          closeCallback: handleModalClose,
     });

     const clearSearch = () => {
          formValues = {
               Freetext: '',
               PendingQuotes: true,
               AcceptedQuote: true,
               BookedJob: true,
               ActiveJob: true,
               FreeTextSearchBy: null,
          };
          setSearchFormValues(formValues);
          setTriggerSearch(true);
     };

     const handleSetFilter = useCallback((filters: JobsFilterFormModel, selectedQuickSearch?: string) => {
          setSessionStorageSearchFormValues({
               filters,
               selectedQuickSearch: selectedQuickSearch ?? '',
               sortModel: [{ field: JobGridKeys.RequestDate, sort: 'desc' }]
          });

          setSelectedQuickSearch(selectedQuickSearch || '');
          setSearchFormValues(filters);
          setTriggerSearch(true);
     }, [setSessionStorageSearchFormValues]);

     const handleSetPager = useCallback((pager: Pager) => {
          setPager(pager);
          setTriggerSearch(true);
     }, []);

     const handleSetSorter = useCallback((sorter: Sorter) => {
          const { Column, Ascending } = sorter;
          const sortModel: GridSortModel = [{ field: Column, sort: Ascending ? 'asc' : 'desc' }];

          setSessionStorageSearchFormValues({ filters: searchFormValues, selectedQuickSearch, sortModel });
          setSortModel(sortModel)
          setTriggerSearch(true);
     }, [searchFormValues, selectedQuickSearch, setSessionStorageSearchFormValues]);

     const pageChangeHandler = useCallback((currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
          setTriggerSearch(true);
     }, [pager]);

     const pageSizeChangeHandler = useCallback((itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
          setTriggerSearch(true);
     }, [pager]);

     const onSort = useCallback((sortModel: GridSortModel) => {
          setSessionStorageSearchFormValues({ filters: searchFormValues, selectedQuickSearch, sortModel });
          setSortModel(sortModel);
          setPager({ ...pager, CurrentPage: 1 });
          setTriggerSearch(true);
     }, [searchFormValues, selectedQuickSearch, setSessionStorageSearchFormValues]);

     const performQuickSearch = useCallback((searchTerm: string) => {
          formValues = {
               Freetext: searchTerm,
               PendingQuotes: false,
               AcceptedQuote: false,
               BookedJob: false,
               ActiveJob: false,
               FreeTextSearchBy: FreeTextSearchByOptions.ID,
          };

          setSessionStorageSearchFormValues({ 
               filters: formValues, 
               selectedQuickSearch: '', 
               sortModel: [{ field: JobGridKeys.RequestDate, sort: 'desc' }] 
          });
          setSearchFormValues(formValues);
          setTriggerSearch(true);
     }, [setSessionStorageSearchFormValues]);

     const runSavedSearch = useCallback((searchData: SearchData, selectedQuickSearch?: string) => {
          let newValues = getJobFiltersFromSavedSearchModel(searchData.filter);
          const { Column, Ascending } = searchData.sorter;

          setSortModel([{ field: Column, sort: Ascending ? 'asc' : 'desc' }])
          setSelectedQuickSearch(selectedQuickSearch ?? '');
          setPager(searchData.pager);
          setSearchFormValues(newValues);
          setTriggerSearch(true);
          handleModalClose();
     }, [handleModalClose]);

     const deleteSavedSearch = (SearchData: SearchData, SearchName: string) => {
          if (savedSearches && savedSearches?.length > 0) {
               let tempSavedSearch = savedSearches;
               const indexOfObject = tempSavedSearch.findIndex((object) => {
                    return object.SearchName === SearchName;
               });
               tempSavedSearch?.splice(indexOfObject, 1);

               const adminData = {
                    DefaultPage: '',
                    SavedSearches: tempSavedSearch,
               } as UserData;

               let adminUserData = {
                    ID: savedSearchesResult?.Id,
                    UserData: JSON.stringify(adminData),
               } as AdminUserDataModel;

               updateAdminUserData(adminUserData);
               setTriggerSearch(true);
          }
     };

     /** useEffects */
     useEffect(() => {
          const searchFormValues = getSessionStorageSearchFormValues();

          if (searchFormValues) {
               const { filters, selectedQuickSearch, sortModel } = searchFormValues;

               setSelectedQuickSearch(selectedQuickSearch);
               setSortModel(sortModel);
               setSearchFormValues(filters);
               setTriggerSearch(true);
          }
     }, []);

     useEffect(() => {
          if (triggerSearch || memberID || groupID) {
               refetchGetJobs();
          }
     }, [triggerSearch, pager, searchFormValues, sortModel, groupID]);

     useEffect(() => {
          setIsLoading(isGetJobsLoading);
     }, [isGetJobsLoading]);

     useEffect(() => {
          refetchGetSavedSearches();
          setRefreshSearchesState(true);
     }, [adminUserResponse]);

     useEffect(() => {
          if (savedSearchesResult) {
               setSavedSearches(savedSearchesResult?.UserData && savedSearchesResult?.UserData !== 'undefined'
                    ? JSON.parse(savedSearchesResult.UserData).SavedSearches
                    : {});
               setRefreshSearchesState(false);
               setRefreshModule(true);
          }
     }, [savedSearchesResult, refreshSearchesState]);

     useEffect(() => {
          if (savedSearches && refreshModule && filterModuleVisible)
               showFilter();
          setRefreshModule(false);
     }, [savedSearches, refreshModule, filterModuleVisible]);

     useEffect(() => {
          if (adminUserError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: adminUserError?.Subject,
                    body: adminUserError?.Description,
               });
          }
     }, [adminUserError]);

     useEffect(() => {
          if (getJobsError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getJobsError?.Subject ?? "Error when getting jobs",
                    body: getJobsError?.Description,
               });
          }
     }, [getJobsError]);

     useEffect(() => {
          if (getSavedSearchesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getSavedSearchesError?.Subject,
                    body: getSavedSearchesError?.Description,
               });
          }
     }, [getSavedSearchesError]);


     const showFilter = () => {
          setFilterModuleVisible(true);
          setModalContent({
               ...modalContent,
               show: true,
               header: 'Search for Jobs...',
               body: (
                    <GetJobsFilterPopupBody
                         initialValues={formValues}
                         savedSearches={savedSearches}
                         searchFormValues={searchFormValues}
                         clearFilters={deleteSessionStorageSearchFormValues}
                         deleteSavedSearch={deleteSavedSearch}
                         handleModalClose={handleModalClose}
                         handleSetFilter={handleSetFilter}
                         refetchGetJobs={refetchGetJobs}
                         runSavedSearch={runSavedSearch}
                    />
               ),
               size: 'xl',
          });
     };

     return (
          <div className='jobs-container'>
               <Alert {...alert} />
               <ModalDialog {...modalContent}></ModalDialog>
               <ConfirmDialog {...confirmDialogContent} />
               {renderHeader('Jobs')}
               <div className='container-fluid'>
                    <Loading loading={isLoading} />
                    <ViewJobsGridView
                         data={getJobsResponse ?? {} as JobsResponseModel}
                         savedSearches={savedSearches}
                         selectedQuickSearch={selectedQuickSearch}
                         clearSearch={clearSearch}
                         handleSetFilter={handleSetFilter}
                         handleSetPager={handleSetPager}
                         handleSetSorter={handleSetSorter}
                         onPageChange={pageChangeHandler}
                         onPageSizeChange={pageSizeChangeHandler}
                         onSort={onSort}
                         performQuickSearch={performQuickSearch}
                         showFilter={showFilter}
                    />
                    {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
               </div>
          </div>
     );
};

export default ViewJobs;
