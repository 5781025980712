import { RequestingMemberFeeType } from '../../../Utils/Enums';
import withAddForm from '../Common/AddForm/withAddForm';
import MarginMaintenance from '../Common/MarginMaintenance';

const CustomerSetMargin = () => {
     const component = (
          <>
               <MarginMaintenance requestingMemberFeeType={RequestingMemberFeeType.CustomerSet} />
          </>
     );
     return withAddForm(component, 'Customer Set Margin');
};

export default CustomerSetMargin;
