import { Formik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../../Components/Formik/StyledComponents';

const AddNewPage: React.FC<AddNewPageProps> = (props) => {
  const { onAdd } = props;
  const initialValues = { NewPageName: '' };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onAdd(values.NewPageName);
      }}
    >
      {({ handleSubmit, resetForm, values }) => {
        return (
          <FormContainer
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="well">
              <Container>
                <Row>
                  <Col>
                    <FormikControl
                      name="NewPageName"
                      label="New Page"
                      placeholder="Enter name for new page"
                      style={{ width: '%' }}
                    />
                    <FormikControl
                      name="NewPageSubmit"
                      control={FormControlKeys.Button}
                      type="submit"
                      label="Add"
                    />
                  </Col>
                </Row>
                <Row>
                  <br />
                </Row>
              </Container>
            </div>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default AddNewPage;
export interface AddNewPageProps {
  onAdd: Function;
}
