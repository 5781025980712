export interface TasksFormModel {
  SendEmailToInactiveMembers?: number;
  DailyPaymentRun?: string;
  PerformDailyPaymentRun?: string;
  PerformFortnightlyInvoiceRun?: string;
  GeneratePrePaymentFile?: string;
  GenerateDailyStatsReport?: string;
  PerformInvoiceRunForDeferredFees?: string;
  PerformMonthEndInvoiceRun?: string;
  PerformWeeklyInvoiceRun?: string;
  MemberCodaReferences?: string;
  SmartDebitFile?: string;
  MarkRequestingMemberInvoicesAsPaid?: string;
}

export const TasksFormKeys = {
  SendEmailToInactiveMembers: "SendEmailToInactiveMembers",
  DailyPaymentRun: "DailyPaymentRun",
  PerformDailyPaymentRun: "PerformDailyPaymentRun",
  PerformFortnightlyInvoiceRun: "PerformFortnightlyInvoiceRun",
  GeneratePrePaymentFile: "GeneratePrePaymentFile",
  GenerateDailyStatsReport: "GenerateDailyStatsReport",
  PerformInvoiceRunForDeferredFees: "PerformInvoiceRunForDeferredFees",
  PerformMonthEndInvoiceRun: "PerformMonthEndInvoiceRun",
  PerformWeeklyInvoiceRun: "PerformWeeklyInvoiceRun",
  MemberCodaReferences: "MemberCodaReferences",
  SmartDebitFile: "SmartDebitFile",
  MarkRequestingMemberInvoicesAsPaid: "MarkRequestingMemberInvoicesAsPaid"
};

export const TasksDocumentTypes = {
  MemberCodaReferences: "MemberCodaReferences",
  SmartDebitFile: "SmartDebitFile",
  MarkRequestingMemberInvoicesAsPaid: "MarkRequestingMemberInvoicesAsPaid",
};

export interface TaskRunModel
{
    DateTime?: Date;
}