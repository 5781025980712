import { SpanItem } from "../../../../../../Components/UI";
import { ModifiedQuoteModel } from "../../../../../../Models/Jobs/EditJob";
import { expiresInMilliseconds, isExpired, ReFormatUkDateTimeExpiry } from "../../../../../../Utils";
import { QuoteStatus } from "../../../../../../Utils/Enums";
import { QuoteExpiryTab } from "../QuoteExpiryTab";

interface Props {
  expirationDate: string;
  quote: ModifiedQuoteModel;
  quoteExpiry: QuoteExpiryTab[];
}

export default function QuoteExpirationCell({ expirationDate, quote, quoteExpiry}: Props) {
    const quoteExpiryInfo = quoteExpiry.find((element) => element.quoteId === quote.Id);
    const oneHourInMilliseconds = 3600000;
    return (
      <SpanItem>
        {quote.QuoteStatus === QuoteStatus.Placed && isExpired(expirationDate) ? (
          <>
            {expiresInMilliseconds(expirationDate) <= 0 && (
              <label style={{ color: 'red' }}>
                Expired
                <br />
                {ReFormatUkDateTimeExpiry(expirationDate)}
              </label>
            )}
          </>
        ) : (
          <span style={{ color: expiresInMilliseconds(expirationDate) < oneHourInMilliseconds ? "red" : "" }}>
              {quoteExpiryInfo ? quoteExpiryInfo.expiryInfo : "N/A"}
          </span>
        )}
        {quote.QuoteStatus !== QuoteStatus.Placed && !isExpired(expirationDate) && (
          <span>N/A</span>
        )}
      </SpanItem>
    );    
  };