import { GridSortModel } from "@mui/x-data-grid"
import { SqlStringMatchType } from "./Utils/Enums/SqlStringMatchType"

export type Pager = {
    CurrentPage: number;
    ItemsPerPage: number;
    TotalItems?: number;
    TotalPages?: number;
}

export type Sorter = {
    Column: string,
    Ascending: boolean;
}

export type DataGridSorter = {
    Fields: string,
    Sort: boolean;
}

export type CommonRequestParam = {
    Pager: Pager,
    Sorter?: Sorter,
    Filter?: any,
    Paginated?: boolean
}

export interface ResponsePage<T> extends Pager {
    Context: any,
    Items: T[];
}
export interface ResultsViewModel<T> {
    Totals: any,
    TableSorter?: Sorter,
    ErrorResponse?: MovexErrorResponse,
    Page: ResponsePage<T>
}

export interface ListResponse<T> {
    Results: T[],
    ErrorResponse?: MovexErrorResponse,
}
export interface MovexErrorResponse {
    ErrorCode: number,
    DefaultHttpResponse: number
    Subject?: string,
    Description?: string,
    AdditionalInformation?: string,
}
export interface ApiErrorResponse {
    Errors: any,
    Status: number,
    Title: string,
    TraceId: string,
    Type: string,
}

export type FreeTextFilter = {
    FreeText?: string;
    FreeTextSearchBy?: string;
    SqlStringMatchType?: SqlStringMatchType;
}

export const FilterKeys = {
    Freetext: "FreeText",
    FreeTextSearchBy: "FreeTextSearchBy",
    JobID: "JobID",
    GroupID: "GroupID",
    MemberStatus: "MemberStatus",
    ServiceType: "ServiceType",
    MemberType: "MemberType",
    SignupFromDate: "SignupFromDate",
    SignupToDate: "SignupToDate",
    IsKeyAccount: "IsKeyAccount",
    SavedSearchStatus: "SavedSearchStatus",
    DateAddedFrom: "DateAddedFrom",
    DateAddedTo: "DateAddedTo",
}

export type DocumentUpload = {
    Name: string,
    Size: number,
    Type: string,
}
export interface GridProps<T> {
    data: T;
    onPageChange: (currentPage: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onSort: (sortModel: GridSortModel) => void;
    onDelete?: (Id: number ) => void;
}
export interface EntityCreatedOrUpdatedViewModel {
    Id: number,
    Error?: MovexErrorResponse
}

export type CommonResponse = {
    ErrorResponse?: MovexErrorResponse,
    IsSuccess?: boolean,
    Message?: string,
}

export type TypeaheadOption = {
    Label: string,
    Id: string
}