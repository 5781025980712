import { BrowserRouter, Route, Switch } from "react-router-dom";

import { NavBar } from "../../../../../Components/SubNavBar/SubNavBar";
import HangfireDashboard from "./Pages/HangfireDashboard";
import Jobs from "./Pages/Jobs";
import Retries from "./Pages/Retries";
import RecurringJobs from "./Pages/RecurringJobs";
import Servers from "./Pages/Servers";

export function SchedulingService() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route path="/_schedulingservice" exact>
            <HangfireDashboard />
          </Route>
          <Route path="/_schedulingservice/jobs">
            <Jobs />
          </Route>
          <Route path="/_schedulingservice/retries">
            <Retries />
          </Route>
          <Route path="/_schedulingservice/recurringJobs">
            <RecurringJobs />
          </Route>
          <Route path="/_schedulingservice/servers">
            <Servers />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default SchedulingService;
