/** Import inbuilt libraries*/
import React from "react";
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
/** Import custom components*/
import { ControlledPagination as Pagination } from "../../../../../../Components";
import {
  AuthServicesModelKeys,
  AuthServicesResponseModel,
} from "../../../../../../Models/System";
import { GridProps, Sorter } from "../../../../../../Types";
import {
  ActionColumnPath,
  getSortModelFromSorter,
} from "../../../../../../Utils";
import Checkbox from "@mui/material/Checkbox";

const AuthServicesGridView: React.FC<AuthServicesGridProps> = (props) => {
  const {
    data: administrators,
    onPageChange,
    onPageSizeChange,
    onSort,
  } = props;

  const authServicesColumns = [
    {
      field: AuthServicesModelKeys.Id,
      headerName: "#",
      description: "ID",
      flex: 0.2,
    },
    {
      field: AuthServicesModelKeys.Created,
      headerName: "Created",
      description: "Created",
      flex: 2,
    },
    {
      field: AuthServicesModelKeys.AuthToken,
      headerName: "Auth Token",
      flex: 4,
      description: "Auth Token",
    },
    {
      field: AuthServicesModelKeys.ClientVersion,
      headerName: "Client Version",
      flex: 3,
      description: "Client Version",
    },
    {
      field: AuthServicesModelKeys.ClientIP,
      headerName: "Client IP",
      flex: 1,
      description: "Client IP",
    },
    {
      field: AuthServicesModelKeys.UserID,
      headerName: "UserID",
      flex: 1,
      description: "UserID",
    },
    {
      field: AuthServicesModelKeys.UserName,
      headerName: "UserName",
      description: "UserName",
      flex: 1,
    },
    {
      field: ActionColumnPath,
      headerName: "",
      flex: 0.2,
      description: "Action",
      type: "actions",
      renderCell: (params: GridRenderCellParams) => renderActionCell(params),
    },
  ];

  const renderActionCell = (params: GridRenderCellParams) => {
    return (
      <React.Fragment>
       <Checkbox />
      </React.Fragment>
    );
  };

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  if (administrators?.Page) {
    const { CurrentPage, TotalPages, ItemsPerPage, TotalItems, Items } = administrators?.Page;
    const CustomGridToolbar = () => {
      return (
        <Pagination
          itemsCount={TotalItems}
          currentPage={CurrentPage}
          totalPages={TotalPages}
          pageSize={ItemsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      );
    };

    return (
      <div>
        {administrators?.Page && (
          <div id='id_grid_auth_services' className="view-administrators-container">
            <DataGrid
              sortingOrder={['desc', 'asc']}
              autoHeight
              getRowId={(row: any) => row.Id}
              disableColumnMenu={true}
              rows={Items}
              rowHeight={30}
              columns={authServicesColumns}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridToolbar,
              }}
              sortingMode="server"
              sortModel={getSortModelFromSorter(administrators?.TableSorter as Sorter)}
              onSortModelChange={handleSort}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

interface AuthServicesGridProps
  extends GridProps<AuthServicesResponseModel> {}

export default AuthServicesGridView;
