import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  renderHeader,
  useAlert,
} from '../../../../Components';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';
import {
  JobStatisticsFilter,
} from '../../../../Models/Reports/Statistics';
import { Pager } from '../../../../Types';
import { Page } from '../../../../Utils';
import FilterBox from './FilterBox';
import JobStatisticsGridView from './Grid';
import { useGetStatistics } from './Hooks';

const Statistics: React.FC = (): JSX.Element => {
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  /** useStates */
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });
  const [triggerDataFetch, setTriggerDataFetch] = useState<boolean>(false);
  //default filter
  const [filter, setFilter] = useState<JobStatisticsFilter>({
    StatisticsType: 1,
    PartnerID: 0
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  /** Methods */
  const getParams = () => {
    const params = filter;
    return params;
  };

  const handleSearch = (filter: JobStatisticsFilter) => {
    setFilter(filter);
    setTriggerDataFetch(true);
  };

  const handleClearSearch = () => {
    setFilter({
      StatisticsType: 1,
      PartnerID: 0,
    });
    setSortModel([]);
    setPager({
      CurrentPage: Page.CurrentPage,
      ItemsPerPage: Page.PageSize,
    });
    setTriggerDataFetch(true);
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
    setTriggerDataFetch(true);
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
    setTriggerDataFetch(true);
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
    setTriggerDataFetch(true);
  };

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** Query Hook */
  const reqOption = { enabled: false };
  const {
    data: getJobStatisticsReponse,
    isLoading: isGetJobStatisticsLoading,
    refetch: refetchGetJobStatistics,
    error: getJobStatisticsError,
  } = useGetStatistics(getParams(), reqOption);

  const isDataLoaded = getJobStatisticsReponse != null;
  const isResultEmpty = getJobStatisticsReponse?.JobStatistics == null;

  /** useEffects */
  useEffect(() => {
    if (triggerDataFetch) {
      refetchGetJobStatistics();
    }
  }, [triggerDataFetch, pager, filter, sortModel]);

  useEffect(() => {
    if (getJobStatisticsError) {
      setAlert({
        ...alert,
        show: true,
        header: getJobStatisticsError?.Subject,
        body: getJobStatisticsError?.Description,
      });
    }
  }, [getJobStatisticsError]);

  return (
    <div>
      <Alert {...alert} />
      <ConfirmDialog {...confirmDialogContent} />
      {renderHeader('Job Statistics')}
      <div className="container-fluid">
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
        <Loading loading={isGetJobStatisticsLoading} />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        {(isDataLoaded && getJobStatisticsReponse) && (
          <JobStatisticsGridView
            data={getJobStatisticsReponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Statistics;