import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { containerName } from "./StorageConfig";

export const uploadFileToBlob = async (
  file: File | null, url : string
): Promise<string[]> => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(url);

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName);

  // upload file
  await createBlobInContainer(containerClient, file);

  // get list of blobs in container
  return [];
};

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);
  
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  const response = await blobClient.uploadData(file, options);
  if(response._response.status === 201){
  }

};

export function getExtensionFromMimeType(mimeType: string, fileName: string): string {
  switch (mimeType) {
      case 'image/jpeg': return 'jpg';
      case 'image/png': return 'png';
      case 'application/pdf': return 'pdf';
      default: 
          const fileExtension = fileName.split('.').pop()?.toLowerCase() || 'png';
          return fileExtension;
  }
}

export const downloadProofFile = (Name: string, fileString: string, mimeType: string) => {
  // Create a data URL from the file string and mime type
  const dataURL = `data:${mimeType};base64,${fileString}`;
  
  // Get the file extension from the mime type
  const fileExtension = getExtensionFromMimeType(mimeType, Name);
  // Check if Name already has an extension
  const hasExtension = /\.[0-9a-z]+$/i;
  const fileName = hasExtension.test(Name) ? Name : `${Name}.${fileExtension}`;
  // Create a temporary link and click it to trigger the download
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = dataURL;
  a.download = fileName || `file.${fileExtension}`;
  a.click();
  document.body.removeChild(a);
};