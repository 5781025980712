import PropTypes from 'prop-types';
import './index.css';

export const Button = ({ ...props }): JSX.Element => {
  const { variant, label, size, style, ...rest } = props;
  return (
    <button
      style={{ ...style, marginRight: 5 }}
      type="button"
      className={`btn btn-${variant} btn-${size}`}
      {...rest}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.any,
};

Button.defaultProps = {
  variant: 'primary',
  size: 'default',
  onClick: undefined,
};

export default Button;
