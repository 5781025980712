import styled, { css } from 'styled-components';

export const Root = styled.div`
     position: relative;
     width: 100%;
`;

export const baseButtonMixin = css`
     background: none;
     border: none;
     padding: 0;
`;

export const ValueWrapper = styled.input`
     width: 100%;
     padding-left: 8px;
     padding-right: 32px;
     height: 32px;
     box-sizing: border-box;
     border-radius: 1px;
     border: 1px solid #b6c1ce;
     line-height: 32px;
`;

/* AutoCOmplete */
export const AutoCompleteContainerConditionalRender = styled.div<{ isVisible: boolean }>`
     display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

export const HideAutoComplete = styled.div<{ isComponentVisible: boolean }>`
     display: ${(props) => (props.isComponentVisible ? 'block' : 'none')};
     background-color: transparent;
     position: fixed;
     z-index: 0;
     top: 0;
     left: 0;
`;

export const AutoCompleteContainer = styled.ul`
     top: 100%;
     left: 0;
     float: left;
     min-width: 160px;
     padding: 5px 0;
     margin: 2px 0 0;
     background-color: #fff;
     border: 1px solid #ccc;
     border: 1px solid rgba(0, 0, 0, 0.2);
     *border-right-width: 2px;
     *border-bottom-width: 2px;
     -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
     border-radius: 6px;
     -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
     box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
     -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
     background-clip: padding-box;
     position: absolute;
     list-style-type: none;
     height: 300px;
     width: 40%;
     overflow: hidden;
     overflow-y: scroll;
`;

export const AutoCompleteItem = styled.li`
     padding: 0 24px;
     width: 100%;
     box-sizing: border-box;
     &:hover {
          background-color: #ebf4ff;
     }
`;

export const AutoCompleteItemButton = styled.button`
     ${baseButtonMixin} width: 100%;
     line-height: 32px;
     text-align: left;
     &:active {
          outline: none;
          color: #0076f5;
     }
`;

/** item list */

export const PriceGroupList = styled.div`
     border: 1px solid #d9edf7;
     border-bottom-width: 20px;
     height: 200px;
     overflow-y: scroll;
     padding: 10px;
     width: 100%;
`;

export const PriceGroupItem = styled.div`
     background-color: #fff;
     border: 1px solid #b2b2b2;
     border-radius: 4px;
     box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
     color: #43484a;
     display: inline-block;
     max-width: 100%;
     margin-bottom: 3px;
     padding: 5px;
     vertical-align: middle;
     width: -webkit-fill-available;
`;

export const Label = styled.label`
     display: inline-block;
     padding: 2px 4px;
     font-size: 11.844px;
     font-weight: bold;
     line-height: 14px;
     color: #fff;
     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
     white-space: nowrap;
     vertical-align: baseline;
     background-color: #333;
     border-radius: 3px;
`;

export const LabelPartner = styled.label`
     display: inline-block;
     padding: 2px 4px;
     font-size: 11.844px;
     font-weight: bold;
     line-height: 14px;
     color: #fff;
     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
     white-space: nowrap;
     vertical-align: baseline;
     border-radius: 3px;
     background-color: #333333;
`;

export const LabelGroup = styled.label`
     display: inline-block;
     padding: 2px 4px;
     font-size: 11.844px;
     font-weight: bold;
     line-height: 14px;
     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
     white-space: nowrap;
     vertical-align: baseline;
     background-color: #f89406;
     border-radius: 3px;
     color: white;
`;

export const LabelMember = styled.label`
     display: inline-block;
     padding: 2px 4px;
     font-size: 11.844px;
     font-weight: bold;
     line-height: 14px;
     color: #fff;
     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
     white-space: nowrap;
     vertical-align: baseline;
     background-color: #3a87ad;
     border-radius: 3px;
`;

export const Close = styled.label`
     float: right;
     font-size: 20px;
     font-weight: bold;
     line-height: 20px;
     color: #000;
     text-shadow: 0 1px 0 #fff;
     opacity: 0.2;
     text-decoration: none;
`;

export const Search = styled.div`
     background-color: #d9edf7;
     border: 1px solid #d9edf7;
     padding: 10px;
`;

/* Editable Prices */
export const Action = styled.td`
  min-width: 60px;
`;
