import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../Components/Formik/StyledComponents';

export const ForgottenUsername = (model: any) => {
  const { EmailAddress, SendReminder } = model;

  const formikInitialValues = {};

  const onSubmit = (values: any) => {
    SendReminder(values);
  };
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });

  const userDetailForm = (
    <FormikProvider value={formik}>
      <FormContainer>
        <div className="row ">
          <div className="col-xs-12 col-lg-12 text-center">
            Please fill in the form below and we'll send you an email containing
            your username
          </div>
        </div>
        <div className="row">
          <div className="col-xs-9 col-xs-offset-1 col-lg-8 col-lg-offset-2">
            <FormikControl placeholder="Email Address" name={'EmailAddress'} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-offset-3 col-lg-offset-4">
            <FormikControl type="submit" label="Send Reminder" />
          </div>
        </div>
      </FormContainer>
    </FormikProvider>
  );

  return (
    <div className="forgotten-details-page container-fluid">
      <div className="row">
        <div className="container">
          <div className="row hero-row">
            <div className="col-lg-12">
              <h1>FORGOTTEN USERNAME</h1>
              <h2>
                HELPLINE{' '}
                <span className="helpline-phone-number">0208 551 1060</span>
              </h2>
            </div>
          </div>
          <div className="row reset-form-row">
            <div className="col-xs-12 col-lg-12">{userDetailForm}</div>
          </div>
        </div>
      </div>
    </div>
  );
};