import React from 'react';

export const Compliance = () => {
  // @(Config.MovexLandline)
  const movexLandline = '';

  return (
    <div className="terms-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>COMPLIANCE</h1>

            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">{movexLandline}</span>
            </h2>
          </div>
        </div>
      </div>

      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>COMPLIANCE</h1>

            <p>
              <strong>
                PLEASE READ THESE COMPLIANCE REGULATIONS CAREFULLY BEFORE USING
                THIS SITE
              </strong>
            </p>

            <p>
              <strong>
                Cox Automotive UK - Creating the future of automotive today
              </strong>
            </p>
            <p>
              As the world’s largest automotive services organisation, we are
              committed to helping our manufacturer, fleet and dealer customers
              meet the big, bold challenges of the digital transformation era.
              Cox Automotive has the broadest, deepest, and most complete view
              of the used vehicle ecosystem. We connect the wholesale market at
              every stage of a vehicle’s life with physical and digital
              solutions, insights, and expertise.
            </p>
            <p>
              Our vision is to transform the way the world buys, sells, owns,
              and uses vehicles
            </p>

            <p>
              <strong>Purpose and Introduction</strong>
            </p>
            <p>
              The purpose of this document is to provide our employees and
              interested third parties with an overview of the minimum standards
              which Cox Automotive UK (CAUK) expects from its employees in their
              internal and external dealings with team members, clients,
              stakeholders and third parties. CAUK additionally requires its
              suppliers of products and services, contractors, agency, and
              temporary workers to understand our expectations and to comply
              with their own minimum standards of conduct and behaviour.
            </p>

            <p>
              The signatory, representing the company listed above, confirms the
              following;
            </p>
            <p>Complies with all UK legal Health & Safety requirements.</p>
            <p>Manages personal data within current GDPR legislation.</p>
            <p>
              Is an organisation providing equal opportunities for all team
              members, and will not discriminate based on age, disability,
              gender, gender reassignment, marital or civil partnership,
              pregnancy and maternity, race, colour, nationality, ethnic origin,
              religion or belief, and sexual orientation. (This is not an
              exhaustive list.)
            </p>

            <p>
              Employs all team members in line with UK employment legislation,
              including conducting right to work checks.
            </p>
            <p>
              Treats all employees fairly and honestly. All employees will have
              agreed terms and conditions in accordance with legislation and
              will be given appropriate skills training.
            </p>

            <p>
              Working hours shall not be excessive and shall comply with
              industry guidelines and national standards where they exist.
            </p>

            <p>
              Will not employ illegal child labour, forced or bonded labour,
              forced overtime or condone illegal child labour and have a zero
              tolerance to modern slavery; operates within the Modern Slavery
              Act 2015.
            </p>

            <hr className="dash" />
          </div>
        </div>
      </div>
    </div>
  );
};
