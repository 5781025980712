import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { admin } from '../Axios/Screens';
import { AdminPermission } from '../Models/Admin/GetAdminUIPermissionsResponse';
import { ApplicationState } from '../Store/Types';
import { RouteConstants } from './Constants';

const TWENTY_MINUTES_IN_MILLISECONDS: number = 20 * 60 * 1000;

function setWithExpiry(key: string, value: AdminPermission[], ttl: number) {
     const now = new Date();
     const item = {
          value: value,
          expiry: now.getTime() + ttl,
     };
     localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
     const itemStr = localStorage.getItem(key);
     if (!itemStr) {
          return null;
     }
     const item = JSON.parse(itemStr);
     const now = new Date();
     if (now.getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
     }
     return item.value;
}

const getAndCacheAdminUIPermissions = async (): Promise<any> => {
     try {
          const cachedData = getWithExpiry('adminPermissions');
          if (!cachedData || cachedData.length === 0) {
               const result = await admin.getAdminUIPermissions();
               if (result?.AdminPermissions) {
                    setWithExpiry('adminPermissions', result.AdminPermissions, TWENTY_MINUTES_IN_MILLISECONDS);
               }
               return result.AdminPermissions;
          }
          return cachedData;
     } catch (error) {
          console.error('Failed to fetch admin UI permissions', error);
     }
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
     const { path, component: Component, permission, ...rest } = props;
     const sessionState = useSelector((state: ApplicationState) => state.sessionState!);

     const [adminPermissions, setAdminPermissions] = useState<AdminPermission[]>();
     const [isLoading, setIsLoading] = useState(true);

     useEffect(() => {
          getAndCacheAdminUIPermissions()
               .then((permissions) => {
                    setAdminPermissions(permissions);
                    setIsLoading(false);
               })
               .catch((error) => console.error(error));
     }, []);

     if (adminPermissions === null) {
          return null;
     }

     return (
          <Route
               {...rest}
               render={(props) => {
                    if (!sessionState.session || (!isLoading && (!adminPermissions ||  adminPermissions?.length === 0))) {
                         return (
                              <Redirect
                                   to={{
                                        pathname: RouteConstants.Login,
                                        state: { from: props.location },
                                   }}
                              />
                         );
                    }

                    if (!isLoading && !adminPermissions?.some((permObj: any) => permObj.Permission === permission)) {
                         return (
                              <Redirect
                                   to={{
                                        pathname: '/permission-denied',
                                        state: { from: props.location },
                                   }}
                              />
                         );
                    }

                    return <Component {...props} />;
               }}
          />
     );
};

export default ProtectedRoute;

export type ProtectedRouteProps = {
     path: string;
     component: React.FC<RouteComponentProps>;
     permission: string;
};
