import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateDeliveryContact, UpdateJobDeliveryResponseModel } from "../../../../../Models/Jobs/EditJob";

export const usePutUpdateJobDeliveryContact = (
  options?: any
): UseMutationResult<
  UpdateJobDeliveryResponseModel,
  any,
  UpdateDeliveryContact
> => {
  const putUpdateJobDeliveryContact = (payload: UpdateDeliveryContact) => {
    return agent.jobs.putUpdateJobDeliveryContact(payload);
  };

  return useMutation(putUpdateJobDeliveryContact);
};
