import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { PermissionModel } from '../../../../../../Models/Admin/PermissionsModel';

interface result {
     AdminPermissions: PermissionModel[];
}

export const useGetAdminPermissions = (options?: any): UseQueryResult<result, any> => {
     const getAdminPermissions = () => {
          return agent.adminRole.getAdminPermissions();
     };

     return useQuery(['GetAdminPermissions'], getAdminPermissions, {
          ...options,
     });
};
