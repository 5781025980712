import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GroupsRequestParam, GroupsResponseModel } from '../../../../../Models/Groups';

export const useGetGroups = (payload: GroupsRequestParam,
  options?: any
): UseQueryResult<GroupsResponseModel, any> => {

  const getGroups = () => {
    return agent.groups.getGroups(payload)
  };

  return useQuery(['GetGroups'], getGroups, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
