import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Loading } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { usePutPausePayment } from '../Hooks';
import { ColWrapper, ErrorContainer, RowWrapper } from './style/PaymentModuleStyledComponents';

export const PausePaymentModalFooter: React.FC<any> = ({ onClose, paymentId, onRefresh }): JSX.Element => {
     /** States */
     const [paymentPaused, setPaymentPaused] = React.useState(false);

     /** Query Hooks */
     const { mutate: pausePayment, isLoading, data, error, isSuccess } = usePutPausePayment();

     useEffect(() => {
          if (data) {
               setPaymentPaused(true);
               onRefresh();
          }
     }, [error, data]);

     const formik = useFormik({
          initialValues: { pausePayment: false },
          onSubmit: () => {
               pausePayment({ paymentId });
          },
          enableReinitialize: true,
     });

     return (
          <FormikProvider value={formik}>
               {error && <ErrorContainer>{error.Subject}: {error.Description}</ErrorContainer>}
               <p>Are you sure?</p>
               <Loading loading={isLoading} />
               {paymentPaused && isSuccess && <div>Payment paused!</div>}
               {paymentPaused && !isSuccess && <div>Error: {error}</div>}

               <Form onSubmit={formik.handleSubmit}>
                    <RowWrapper className='justify-content-end'>
                         {!paymentPaused && (
                              <ColWrapper xs={2}>
                                   <FormikControl label='Submit' control={FormControlKeys.Button} type='submit' />
                              </ColWrapper>
                         )}
                         <ColWrapper>
                              <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                         </ColWrapper>
                    </RowWrapper>
               </Form>
          </FormikProvider>
     );
};
