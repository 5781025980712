import './index.css';

const PermissionDenied = () => {
     return (
          <div className='permission-denied-container'>
               <div className='permission-denied-content'>
                    <h1>Access Restricted</h1>
                    <p>Sorry, you don't have permission to access this area.</p>
                    <p>This section is specifically for authorized administrators.</p>
                    <button onClick={() => window.history.back()}>Go Back</button>
               </div>
          </div>
     );
};

export default PermissionDenied;
