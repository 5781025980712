import axios from 'axios';
import { AddOrUpdatePriceGroupRequest } from '../../../Models/PriceGroup/AddOrUpdatePriceGroupRequest';
import { IDeleteTargetFormPriceGroup, IDeleteSourceFormPriceGroup, IPriceGroupTypeAndName } from '../../../Models/PriceGroup';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
     DeletePriceGroup_URL,
     GetPriceGroupList_URL,
     GetPriceGroupSources_URL,
     GetPriceGroupTargets_URL,
     GetPriceGroupPrices_URL,
     GetPriceGroup_URL,
     PostAddPriceGroup_URL,
     PostAddSourceToPriceGroup_URL,
     DeleteSourceFromPriceGroup_URL,
     DeleteTargetFromPriceGroup_URL,
     GetPricesByTypeAndName_URL,
     GetPricePriceGroups_URL,
     PostAddPrice_URL,
     PostAddPriceToPriceGroup_URL,
     PutUpdatePrice_URL,
     DeletePriceFromPriceGroup_URL,
     GetPriceGroupSourcesAndTargetsByName_URL,
     PostAddTargetToPriceGroup_URL,
     PutUpdatePriceGroup_URL,
} = ApiURLConstants;

export const priceGroup = {
     deletePriceGroup: (priceGroupId: number): Promise<any> => {
          return axios
               .delete(`${DeletePriceGroup_URL}?priceGroupId=${priceGroupId}`)
               .then(responseBody);
     },

     getPriceGroupList: (params: any): Promise<any> => {
          return axios.post(`${GetPriceGroupList_URL}`, params, jsonConfig).then(responseBody);
     },

     getPriceGroupSources: (priceGroupId: any): Promise<any> => {
          return axios.get(`${GetPriceGroupSources_URL}?priceGroupId=${priceGroupId}`).then(responseBody);
     },

     getPriceGroupTargets: (priceGroupId: any): Promise<any> => {
          return axios.get(`${GetPriceGroupTargets_URL}?priceGroupId=${priceGroupId}`).then(responseBody);
     },

     getPriceGroupPrices: (priceGroupId: any): Promise<any> => {
          return axios.get(`${GetPriceGroupPrices_URL}?priceGroupId=${priceGroupId}`).then(responseBody);
     },
     
     getPriceGroup: (priceGroupId: any): Promise<any> => {
          return axios.get(`${GetPriceGroup_URL}?priceGroupId=${priceGroupId}`).then(responseBody);
     },

     PostAddPriceGroup_URL: (params: any): Promise<any> => {
          return axios.put(`${PostAddPriceGroup_URL}`, params, jsonConfig).then(responseBody);
     },

     PostAddSourceToPriceGroup: (params: any): Promise<any> => {
          return axios
               .post(`${PostAddSourceToPriceGroup_URL}`, params, jsonConfig)
               .then(responseBody);
     },

     deleteSourceFromPriceGroup: (params: IDeleteSourceFormPriceGroup): Promise<any> => {
          return axios
               .delete(`${DeleteSourceFromPriceGroup_URL}?priceGroupId=${params.priceGroupId}&sourceId=${params.sourceId}`).then(responseBody);
     },

     deleteTargetFromPriceGroup: (params: IDeleteTargetFormPriceGroup): Promise<any> => {
          return axios
               .delete(`${DeleteTargetFromPriceGroup_URL}?priceGroupId=${params.priceGroupId}&targetId=${params.targetId}`).then(responseBody);
     },

     getPricesByTypeAndName: (params: IPriceGroupTypeAndName): Promise<any> => {
          return axios
               .get(`${GetPricesByTypeAndName_URL}?type=${params.Type}&name=${params.Name}`)
               .then(responseBody);
     },

     postAddPriceToPriceGroup: (params: any): Promise<any> => {
          return axios
               .post(`${PostAddPriceToPriceGroup_URL}`, params, jsonConfig)
               .then(responseBody);
     },

     deletePriceFromPriceGroup: (params: any): Promise<any> => {
          return axios 
               .delete(`${DeletePriceFromPriceGroup_URL}`, { data: params })
               .then(responseBody);
     },

     putUpdatePrice: (params: any): Promise<any> => {
          return axios.put(`${PutUpdatePrice_URL}`, params, jsonConfig).then(responseBody);
     },

     getPricePriceGroups: (priceId: number): Promise<any> => {
          return axios
               .get(`${GetPricePriceGroups_URL}?priceId=${priceId}`)
               .then(responseBody);
     },

     postAddPrice: (params: any): Promise<any> => {
          return axios.post(`${PostAddPrice_URL}`, params, jsonConfig).then(responseBody);
     },

     getPriceGroupSourcesAndTargetsByName: (name: any): Promise<any> => {
          return axios
               .get(`${GetPriceGroupSourcesAndTargetsByName_URL}?name=${name}`)
               .then(responseBody);
     },

     postAddTargetToPriceGroup: (params: any): Promise<any> => {
          return axios
               .post(`${PostAddTargetToPriceGroup_URL}`, params, jsonConfig)
               .then(responseBody);
     },

     putUpdatePriceGroup: (params: AddOrUpdatePriceGroupRequest): Promise<any> => {
          return axios.put(PutUpdatePriceGroup_URL, params, jsonConfig).then(responseBody);
     },

     postAddPriceGroup: (params: AddOrUpdatePriceGroupRequest): Promise<any> => {
          return axios
               .post(`${PostAddPriceGroup_URL}`, params, jsonConfig)
               .then(responseBody);
     },
};
