import { Form,FormikProvider,useFormik } from 'formik';
import React,{ useEffect } from 'react';
import { Col,Row } from 'react-bootstrap';
import { Loading } from '../../../../../Components';
import { Button,FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { PaymentItemModel } from '../../../../../Models/Jobs';
import { ServiceType } from '../../../../../Utils/Enums';
import { usePutMakePaymentNow } from '../Hooks/usePutMakePaymentNow';
import { ColWrapper,ErrorContainer,RowWrapper } from './style/PaymentModuleStyledComponents';
import { SuccessIndicatorIcon } from '../../../../../Assets/Images';
import { cellImageStyle } from '../../../Billing/AddBill';

interface PayNowModalProps {
     onClose: () => void;
     paymentId: number;
     onRefresh: Function;
     paymentData: PaymentItemModel;
}

export const PayNowModal: React.FC<PayNowModalProps> = ({ onClose, paymentId, onRefresh, paymentData }): JSX.Element => {
     let feeRate = calculateFeeRate(paymentData);
     const fuelApplicable = paymentData.ActualServiceType === ServiceType.Driven;
     const vatApplicable = paymentData.SupplyingMemberVatAdditional === true;
     const vatRate = Number(paymentData.VatRate);

     /** States*/
     const [paymentPaid, setPaymentPaid] = React.useState(false);

     /** Query Hook */
     const { mutate: makePaymentNow, isLoading, data, error, isSuccess } = usePutMakePaymentNow();

     useEffect(() => {
          if (data) {
               setPaymentPaid(true);
               onRefresh();
          }
     }, [error, data]);

     const formik = useFormik({
          initialValues: {
               pausePayment: false,
               FuelCost: (paymentData.FuelPaid ?? 0).toFixed(2),
               QuoteAmount: (paymentData.QuoteAmount ?? 0).toFixed(2),
               RequestingMemberFee: (paymentData.RequestingMemberFee ?? 0).toFixed(2),
               RequestingMemberPayAsYouGoFee: (paymentData.RequestingMemberPayAsYouGoFee ?? 0).toFixed(2),
               RequestingMemberAmount: (paymentData.RequestingMemberAmount ?? 0).toFixed(2),
               RequestingMemberDiscount: (paymentData.RequestingMemberDiscount ?? 0).toFixed(2),
               RequestingMemberNet: (paymentData.RequestingMemberAmount ?? 0).toFixed(2),
               RequestingCustomerVat: (paymentData.RequestingMemberVat ?? 0).toFixed(2),
               RequestingMemberTotal: (paymentData.RequestingMemberTotal ?? 0).toFixed(2),
               SupplyingMemberFees: (paymentData.SupplyingMemberFees ?? 0).toFixed(2),
               SupplyingMemberFeesVat: (paymentData.SupplyingMemberFeesVat ?? 0).toFixed(2),
               Total: (paymentData.SupplyingMemberTotal ?? 0).toFixed(2),
               NewQuoteAmount: (paymentData.QuoteAmount ?? 0).toFixed(2),
               NewRequestingMemberFee: (paymentData.RequestingMemberFee ?? 0).toFixed(2),
               NewRequestingMemberPayAsYouGoFee: (paymentData.RequestingMemberPayAsYouGoFee ?? 0).toFixed(2),
               NewRequestingMemberAmount: (paymentData.RequestingMemberAmount ?? 0).toFixed(2),
               NewRequestingMemberDiscount: (paymentData.RequestingMemberDiscount ?? 0).toFixed(2),
               NewRequestingMemberNet: ((paymentData.RequestingMemberAmount - paymentData.RequestingMemberDiscount) ?? 0).toFixed(2),
               NewRequestingCustomerVat: (paymentData.RequestingMemberVat ?? 0).toFixed(2),
               NewRequestingMemberTotal: (paymentData.RequestingMemberTotal ?? 0).toFixed(2),
               NewSupplyingMemberFees: (paymentData.SupplyingMemberFees ?? 0).toFixed(2),
               NewSupplyingMemberFeesVat: (paymentData.SupplyingMemberFeesVat ?? 0).toFixed(2),
               NewTotal: (paymentData.SupplyingMemberTotal ?? 0).toFixed(2),
               NewFuelCost: (paymentData.FuelPaid ?? 0).toFixed(2),
               NewLockQuoteAmountWithNewRequestingMemberFee: false,
          },
          onSubmit: (values) => {
               makePaymentNow({
                    paymentId: Number(paymentData.PaymentID),
                    QuoteAmount: Number(values.QuoteAmount),
                    FuelCost: 0,
                    RequestingMemberFee: Number(values.NewRequestingMemberFee),
               });
          },
          enableReinitialize: true,
     });

     useEffect(() => {
          //Customer Surcharge (= NewRequestingMemberFee)
          const newRequestingMemberFee = updateFeeWithDifference(Number(formik.values.QuoteAmount), Number(formik.values.NewQuoteAmount), Number(formik.values.RequestingMemberFee), formik.values.NewLockQuoteAmountWithNewRequestingMemberFee);
          const newRequestingMemberAmount = updateRequestingMemberAmount(Number(formik.values.NewQuoteAmount), newRequestingMemberFee, Number(formik.values.NewRequestingMemberPayAsYouGoFee));
          const newRequestingMemberNet = calculateRequestingMemberNet( newRequestingMemberAmount, Number(formik.values.NewRequestingMemberDiscount));
          const newRequestingMemberVat = calculateRequestingMemberVat(newRequestingMemberNet, vatRate);
          const newRequestingMemberTotal = calculateRequestingMemberTotal(newRequestingMemberNet, newRequestingMemberVat);
          const newSupplyingMemberFees = calculateSupplyingMemberFees(Number(formik.values.NewQuoteAmount), feeRate, Number(paymentData.SupplyingMemberMonthlyFee));
          const newSupplyingMemberFeesVat = calculateSupplyingMemberFeesVat(newSupplyingMemberFees, vatRate);
          const newQuoteAmountVat = calculateQuoteAmountVat(Number(formik.values.NewQuoteAmount), vatRate);
          const newTotal = calculateTotal(Number(formik.values.NewQuoteAmount), Number(formik.values.NewFuelCost), vatApplicable, newQuoteAmountVat, newSupplyingMemberFees, newSupplyingMemberFeesVat, fuelApplicable);

          formik.setValues({
               ...formik.values,
               NewRequestingMemberFee: newRequestingMemberFee.toFixed(2),
               NewRequestingMemberAmount: newRequestingMemberAmount.toFixed(2),
               NewRequestingMemberNet: newRequestingMemberNet.toFixed(2),
               NewRequestingCustomerVat: newRequestingMemberVat.toFixed(2),
               NewRequestingMemberTotal: newRequestingMemberTotal.toFixed(2),
               NewSupplyingMemberFees: newSupplyingMemberFees.toFixed(2),
               NewSupplyingMemberFeesVat: newSupplyingMemberFeesVat.toFixed(2),
               NewTotal: newTotal.toFixed(2),
          });
     }, [formik.values.NewQuoteAmount, formik.values.NewLockQuoteAmountWithNewRequestingMemberFee]);

     return (
          <FormikProvider value={formik}>
               <Loading loading={isLoading} />
               {paymentPaid && isSuccess && <div><b><img alt='Payment Paid' style={cellImageStyle} src={SuccessIndicatorIcon} /> Payment paid!</b></div>}
               {error && <ErrorContainer>{error.Subject}: {error.Description}</ErrorContainer>}
               <Form onSubmit={formik.handleSubmit}>
               {(!paymentPaid && !error) && <>
                    <Row>
                         <Col></Col>
                         <Col>
                              <b>ORIGINAL AMOUNTS</b>
                         </Col>
                         <Col>
                              <b>NEW AMOUNTS</b>
                         </Col>
                    </Row>
                    {(paymentData.ActualServiceType === ServiceType.Driven ||
                         paymentData.ActualServiceType === ServiceType.Both) && (
                         <Row>
                              <Col>Fuel</Col>
                              <Col>
                                   <FormikControl
                                        name={'FuelCost'}
                                        min={0}
                                        control={FormControlKeys.InputGroup}
                                        text='£'
                                        disabled={true}
                                   />
                              </Col>
                              <Col>
                                   <FormikControl name={'NewFuelCost'} min={0} control={FormControlKeys.InputGroup} text='£' />
                              </Col>
                         </Row>
                    )}
                    <Row>
                         <Col>Job Cost</Col>
                         <Col>
                              <FormikControl
                                   name={'QuoteAmount'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl name={'NewQuoteAmount'} min={0} control={FormControlKeys.InputGroup} text='£' />
                              <FormikControl
                                   label={'Adjust Surcharge'}
                                   name={'NewLockQuoteAmountWithNewRequestingMemberFee'}
                                   control={FormControlKeys.Checkbox}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Customer Surcharge</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberFee'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberFee'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                              />
                         </Col>
                    </Row>

                    <Row>
                         <Col>Customer PAYG</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberPayAsYouGoFee'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberPayAsYouGoFee'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Customer Amount</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberAmount'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberAmount'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Customer Discount</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberDiscount'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberDiscount'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Customer Net</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberNet'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberNet'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Customer VAT</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingCustomerVat'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingCustomerVat'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>

                    <Row>
                         <Col>Customer Total</Col>
                         <Col>
                              <FormikControl
                                   name={'RequestingMemberTotal'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewRequestingMemberTotal'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Supplier Fee</Col>
                         <Col>
                              <FormikControl
                                   name={'SupplyingMemberFees'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewSupplyingMemberFees'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>Supplier VAT</Col>
                         <Col>
                              <FormikControl
                                   name={'SupplyingMemberFeesVat'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewSupplyingMemberFeesVat'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>

                    <Row>
                         <Col>Supplier Total Payable</Col>
                         <Col>
                              <FormikControl
                                   name={'Total'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                         <Col>
                              <FormikControl
                                   name={'NewTotal'}
                                   min={0}
                                   control={FormControlKeys.InputGroup}
                                   text='£'
                                   disabled={true}
                              />
                         </Col>
                    </Row>
                    </>}
                    <RowWrapper>
                         {(!paymentPaid && !error) && (
                              <ColWrapper>
                                   <FormikControl name='Submit' label='Submit' type='submit' control={FormControlKeys.Button} />
                              </ColWrapper>
                         )}
                         <ColWrapper>
                              <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                         </ColWrapper>
                    </RowWrapper>
               </Form>
               
          </FormikProvider>
     );
};

/**Inputs Re-Calculation */

function calculateFeeRate(paymentData: PaymentItemModel) {
     if (paymentData.QuoteAmount > 0) {
          return paymentData.SupplyingMemberFee / paymentData.QuoteAmount;
     }
     // TODO The value '4 / 100' is a placeholder. Replace it with `paymentData.FeesV3_TransporterFeePercentage`
     return 4 / 100;
}

function updateFeeWithDifference(
     quoteAmount: number,
     newQuoteAmount: number,
     requestingMemberFee: number,
     newLockQuoteAmountWithNewRequestingMemberFee: boolean
) {
     if (!newLockQuoteAmountWithNewRequestingMemberFee) return requestingMemberFee;
     const diff = quoteAmount - newQuoteAmount;
     const newFee = Math.max(0, requestingMemberFee + diff);
     return newFee;
}

function updateRequestingMemberAmount(
     newQuoteAmount: number,
     newRequestingMemberFee: number,
     newRequestingMemberPayAsYouGoFee: number
) {
     return Number(newQuoteAmount) + Number(newRequestingMemberFee) + Number(newRequestingMemberPayAsYouGoFee);
}

function calculateRequestingMemberNet(newRequestingMemberAmount: number, newRequestingMemberDiscount: number): number {
     return newRequestingMemberAmount - newRequestingMemberDiscount;
}

function calculateRequestingMemberVat(newRequestingMemberNet: number, vatRate: number): number {
     return newRequestingMemberNet * vatRate;
}

function calculateRequestingMemberTotal(newRequestingMemberNet: number, newRequestingMemberVat: number): number {
     return newRequestingMemberNet + newRequestingMemberVat;
}

function calculateSupplyingMemberFees(newQuoteAmount: number, feeRate: number, supplyingMemberMonthlyFee: number): number {
     return newQuoteAmount * feeRate + Number(supplyingMemberMonthlyFee);
}

function calculateSupplyingMemberFeesVat(newSupplyingMemberFees: number, vatRate: number): number {
     return newSupplyingMemberFees * vatRate;
}

function calculateQuoteAmountVat(newQuoteAmount: number, vatRate: number): number {
     return newQuoteAmount * vatRate;
}

function calculateTotal(
     newQuoteAmount: number,
     newFuelCost: number | undefined,
     vatApplicable: boolean,
     newQuoteAmountVat: number,
     newSupplyingMemberFees: number,
     newSupplyingMemberFeesVat: number,
     fuelApplicable: boolean
): number {
     let newTotal = fuelApplicable && newFuelCost ? newFuelCost : 0;
     newTotal += newQuoteAmount;
     if (vatApplicable) {
          newTotal += newQuoteAmountVat;
     }
     newTotal -= newSupplyingMemberFees;
     newTotal -= newSupplyingMemberFeesVat;
     return newTotal;
}
