import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddGroupFavouriteModel, GroupFavouriteResponseModel } from '../../../../../Models/Groups';

export const usePutGroupFavourite = (
  options?: any
): UseMutationResult<
  GroupFavouriteResponseModel,
  any,
  AddGroupFavouriteModel
> => {

  const putGroupFavourite = (payload: AddGroupFavouriteModel) => {
    return agent.groups.putGroupFavourite(payload)
  };

  return useMutation(putGroupFavourite);
};