import axios from 'axios';
import CSS from 'csstype';
import React,{ useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import agent from './Axios/Agent';
import Navbar from './Components/Navbar';
import { config } from './Config/AdminConfig';
import { loadConfig } from './Config/EnvConfigLoader';
import { ErrorBoundary } from './ErrorBoundary';
import RouteList from './Routes/RouteList';
import { apiUrlActionCreators } from './Store/ApiUrl/Action';
import { useAppDispatch } from './Store/hooks';
import { ApplicationState } from './Store/Types';
import { getAPIHostURL } from './Utils';
import { EnvironmentEnum } from './Utils/Enums';
import * as Storage from './Utils/Storage';

type RootNavigatorProps = {
  isLoggedIn: boolean;
  setLoggedIn: (isLoggedIn: boolean) => void;
};

const RootNavigator: React.FC<RootNavigatorProps> = () => {
  const [isLoggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const [configLoaded, setConfigLoaded] = useState(false);

  const sessionState = useSelector((state: ApplicationState) => state.sessionState!);

  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch();
  // Configure the base URL dynamically based on the environment
  useEffect(() => {
       const setAxiosBaseUrl = async () => {
            const config = await loadConfig();
            const apiUrl = config.REACT_APP_API_URL_CONFIG_VALUE ?? getAPIHostURL(EnvironmentEnum.DEV.toString());
            axios.defaults.baseURL = apiUrl;
            dispatch(apiUrlActionCreators.setApiUrl(apiUrl));
            setConfigLoaded(true);
       };
       setAxiosBaseUrl();
  }, []);

  useEffect(() => {
    if (sessionState === undefined) {
    } else if (sessionState.error) {
      setLoggedIn(false);
    } else if (sessionState.session === undefined) {
      setLoggedIn(false);
    } else {
      getGoogleMapKey().catch(error => {
          setLoggedIn(false);
      });
      setLoggedIn(true);
    }
  }, [sessionState]);

  return (
    <React.Fragment>
      <ErrorBoundary>
        {isLoggedIn && <Navbar />}
        <div className="loader"></div>
        <main style={isLoggedIn ? mainStyle : {}}>
        {configLoaded && <RouteList />}
        </main>
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default RootNavigator;

const mainStyle: CSS.Properties = {
  position: 'absolute',
  top: '40px',
  width: '100%',
  overflowX: 'hidden',
};

const getGoogleMapKey = async (): Promise<string> => {
  return new Promise(async function (resolve, reject) {
    await Storage.getGoogleInfo(config.googleAPIKey)
      .then((response) => response as string)
      .then(async (data: any) => {
        if (data === undefined) {
          await agent.session.getConfig().then((configdata) => {
            if (configdata && configdata.googleAPIKey) {
              Storage.setGoogleInfo('googleAPIKey', configdata.googleAPIKey);
              data = configdata.googleAPIKey;
            }
          });
        }
        resolve(data);
      });
  });
};

export const getFuelEstimatedCostPerMile = async (): Promise<number> => {
  return new Promise(async function (resolve, reject) {
    Storage.getGoogleInfo('fuelEstimateCostPerMile')
          .then((response) => {
            if(response !== undefined) return parseFloat(response)
          })          
          .then((response) => response)
          .then(async (data: any) => {
              if (data === undefined) {
                  await agent.session.getConfig().then((configdata) => {
                      if (configdata && configdata.FuelEstimateCostPerMile) {
                          Storage.setGoogleInfo('fuelEstimateCostPerMile', configdata.FuelEstimateCostPerMile);
                          data = configdata.FuelEstimateCostPerMile;
                      }
                  });
              }
              resolve(Number(data));
          })
          .catch((error) => {
              reject(error);
          });
  });
};
