import axios from 'axios';
import { FeedbackItemModel, GetFeedbackListRequest } from '../../../Models/Feedback';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetFeedbackList_URL,
    GetFeedback_URL,
    PostAddFeedback_URL,
    PutUpdateFeedback_URL,
    DeleteFeedback_URL,
} = ApiURLConstants;


export const feedback = {
    getFeedbackList: (params: GetFeedbackListRequest): Promise<any> => {
        return axios
            .post(`${GetFeedbackList_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    getFeedback: (feedbackID: number): Promise<any> => {
        return axios
            .get(`${GetFeedback_URL}?feedbackID=${feedbackID}`, jsonConfig)
            .then(responseBody);
    },

    postAddFeedback: (params: FeedbackItemModel): Promise<any> => {
        return axios
            .post(`${PostAddFeedback_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    putFeedback: (params: FeedbackItemModel): Promise<any> => {
        return axios
            .put(`${PutUpdateFeedback_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    deleteFeedback: (feedbackID: number): Promise<any> => {
        return axios.delete(`${DeleteFeedback_URL}?feedbackID=${feedbackID}`)
            .then(responseBody)
    },
}