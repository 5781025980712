import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';
import TextError from '../Formik/TextError';
import { OptionType } from './Types';

export const Select = ({ ...props }): JSX.Element => {
  const {
    name,
    label,
    options,
    value,
    placeholder,
    showRequired,
    selected,
    ...rest
  } = props;

  const renderOption = (o: OptionType) => {
    return (
      <option
        key={o.value}
        value={o.value}
        className={o.className}
        disabled={o.disabled ?? false}
      >
        {o.label}
      </option>
    );
  };

  /** 
   * 
   * I added `const [field] = useField(name);` and `value={field.value}` to the Field component.
   * 
   * This change has been done to avoid the **warning** of *uncontrolled to controlled component*.
   * 
   * */

  const [field] = useField(name);

  return (
    <div className="mb-3 form-group">
      {label && (
        <label htmlFor={name} className="form-label fw-600">
          {label}
        </label>
      )}
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <Field
        name={name}
        as="select"
        id={name}
        className="form-select"
        aria-label={placeholder}
        value={field.value}
        {...rest}
      >
        {placeholder && <option value=""> {placeholder}</option>}
        {options &&
          options.map((o: OptionType) => {
            if (o.children?.length) {
              return (
                <optgroup key={o.value} label={o.label}>
                  {o.children.map((o: OptionType) => {
                    return renderOption(o);
                  })}
                </optgroup>
              );
            } else {
              return renderOption(o);
            }
          })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Select;
