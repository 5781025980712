import { TaskDataFieldValue } from "./TaskDataFieldValues"

export interface TaskItemModel {
    Active:boolean,
    TaskId: number,
    Type: string,
    Name: string,
    AssignedTo: string,
    Created?: Date,
    Due: Date,
    Status: String,
    StatusChanged: Date,
    OpenReason: string,
    LastActivityBy: string,
    LastActivityUpdated: Date,
    LastActivityComments: string,
    InProgressActivity: boolean,
    TaskDataFieldValues: TaskDataFieldValue[]
}

export const TaskGridKeys = {
    Active: "Active",
    ID: "TaskId",
    Type: "TaskType",
    AssignedTo: "AssignedTo",
    Created: "CreatedOn",
    Due: "DueDate",
    Status: "TaskStatusId",
    StatusChanged: "LastStatusChangeOn",
    OpenReason: "OpenReasonId",
    LastActivityBy: "LatestActivityAdminUser",
    LastActivityUpdated: "LatestActivityCompletedOn",
    LastActivityComments: "LatestActivityComments",
    InProgressActivity: "InProgressActivity",
    TaskDataFieldValues: "TaskDataFieldValues"
}