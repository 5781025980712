export const EditTablePricesKeys = {
     Name: 'Name',
     Type: 'ServiceType',
     IncludesFuel: 'IncludesFuel',
     MinWeight: 'MinWeight',
     MinVehicles: 'MinVehicles',
     MaxVehicles: 'MaxVehicles',
     MaxWeight: 'MaxWeight',
     MinLeadTime: 'MinLeadTime',
     MaxLeadTime: 'MaxLeadTime',
     InitialDistance: 'InitialDistance',
     InitialPrice: 'InitialPrice',
     MinDistance: 'MinDistance',
     MaxDistance: 'MaxDistance',
     MinPriceRate: 'MinPriceRate',
     MaxPriceRate: 'MaxPriceRate',
     PricePerVehiclePerMile: 'PricePerVehiclePerMile',
};

export type PriceGroupIDParamType = {
     priceGroupID: string;
};
