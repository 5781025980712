export type OptionType = {
    value: any;
    label: string;
    children?: OptionType[];
    className?: string;
    disabled?: boolean;
}

export const FormControlKeys = {
    PlainInput: "PlainInput",
    LabelledInput: "LabelledInput",
    InputGroup: "InputGroup",
    Checkbox: "Checkbox",
    CheckboxReversed: "CheckboxReversed",
    Select: "Select",
    Radio: "Radio",
    RadioGroup: "RadioGroup",
    CheckboxGroup: "CheckboxGroup",
    Button: "Button",
    ButtonGroup: "ButtonGroup",
    TypeaheadSingle: "TypeaheadSingle",
    TypeaheadMulti: "TypeaheadMulti",
    Textarea: "Textarea",
    DatePicker: "DatePicker",
    SimpleDatePickerInput: "SimpleDatePickerInput",
    ClickToUpdateField: "ClickToUpdateField",
    Rating: "Rating",
    DateInputNotClearable: "DateInputNotClearable",
    DateInput: "DateInput",
};