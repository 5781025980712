import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export enum FilterTypes {
    UserName = 0,
    CodaCustomerReference = 1,
    Company = 2,
    Contact = 3,
}

export interface GetMembersByRequestFilter {
    filterType: FilterTypes;
    filterValue: string;
}

export const useGetMembersBy = (getMembersByRequestFilter : GetMembersByRequestFilter,
  options?: any
): UseQueryResult<any, any> => {

  const getMembersBy = async () => {
    if (getMembersByRequestFilter.filterValue && getMembersByRequestFilter.filterValue.trim() !== "") {
    let result = await agent.members.getMembersBy(getMembersByRequestFilter)
    return result;
    }
  };

  return useQuery(['useGetMembersBy', getMembersByRequestFilter], getMembersBy, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
});
};
