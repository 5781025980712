import axios from 'axios';
import {
     MakePaymentNowModel,
     MarkPaymentAsNotPayableModel,
     PausePaymentModel,
     RefundPrePaymentModel,
     UpdatePaymentAdminNotesModel,
     UpdatePaymentDueDateModel,
     UpdateProofStatusModel,
     ViewPaymentsRequestParam,
} from '../../../Models/Jobs';
import { responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
     GetPayments_URL,
     PutUpdatePaymentAdminNotes_URL,
     UpdateProofStatus_URL,
     PutPausePayment_URL,
     PutMakePaymentNow_URL,
     PutMarkPaymentAsNotPayable_URL,
     PutUpdatePaymentDueDate_URL,
     PutCheckPayment_URL,
     PutUncheckPayment_URL,
     PutRefundPrePayment_URL,
     PutMakePayment_URL,
} = ApiURLConstants;

export const payments = {
     getPayments: (params: ViewPaymentsRequestParam): Promise<any> => {
          return axios.post(`${GetPayments_URL}`, params).then(responseBody);
     },

     putPayNow: (params: MakePaymentNowModel): Promise<any> => {
          return axios.put(PutMakePayment_URL, params).then(responseBody);
     },

     putMarkPaymentAsNotPayable: (paymentId: number): Promise<any> => {
          const url = `${PutMarkPaymentAsNotPayable_URL}?paymentId=${paymentId}`;
          return axios.put(url).then(response => response.data);
     },

     putUpdatePaymentAdminNotes: (params: UpdatePaymentAdminNotesModel): Promise<any> => {
          return axios.put(PutUpdatePaymentAdminNotes_URL, params).then(responseBody);
     },

     updateProofStatus: (params: UpdateProofStatusModel): Promise<any> => {
          return axios.put(UpdateProofStatus_URL, params).then(responseBody);
     },

     putPausePayment: (params: PausePaymentModel): Promise<any> => {
          return axios
               .put(`${PutPausePayment_URL}?paymentId=${params.paymentId}`)
               .then(responseBody);
     },

     putUpdatePaymentDueDate: (params: UpdatePaymentDueDateModel): Promise<any> => {
          return axios.put(PutUpdatePaymentDueDate_URL, params).then(responseBody);
     },

     putRefundPrePayment: (params: RefundPrePaymentModel): Promise<any> => {
          return axios.put(PutRefundPrePayment_URL, params).then(responseBody);
     },

     putCheckPayment: (paymentID: number): Promise<any> => {
          return axios.put(`${PutCheckPayment_URL}?paymentId=${paymentID}`).then(responseBody);
     },

     putUncheckPayment: (paymentID: number): Promise<any> => {
          return axios.put(`${PutUncheckPayment_URL}?paymentId=${paymentID}`).then(responseBody);
     },
};
