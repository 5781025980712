/** Import inbuilt libraries */
import { DataGrid, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback } from 'react';
/** Import custom components*/
import { ControlledPagination as Pagination } from '../../../../../Components';
import {
  MembersResponseModel,
} from '../../../../../Models/Members';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter } from '../../../../../Utils';
import { columns } from './Columns';

const MembersDataGridView: React.FC<MembersGridProps> = (props) => {
  const { data: members, onPageChange, onPageSizeChange, onSort } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
    onSort(sortModel);
  };

  const getRowClassName = useCallback((params: GridRowParams) => {
    let className = params.row.Disabled
      ? 'disabled'
      : 'enabled';

    if (params.row.Active) {
      className += ' active';
    }
    else {
      if (!params.row.Disabled) {
        className += ' inactive';
      }
    }
    return className;
  }, []);

  const { Page, TableSorter } = members;
  if (Page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = Page;

    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            pageSize={ItemsPerPage}
            currentPage={CurrentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    return (
      <div>
        {Page && (
          <div className="members-container">
            <div id='id_grid_view_members' style={{ display: 'flex', height: '100%', width: '100%' }}>
              <DataGrid
                sortingOrder={['asc', 'desc']}
                getRowId={(row: any) => row.Id}
                autoHeight
                rows={Items}
                rowHeight={41}
                disableColumnMenu={true}
                columns={columns}
                columnBuffer={columns.length + 1}
                components={{
                  Toolbar: CustomGridFooter,
                  Footer: CustomGridFooter,
                }}
                disableExtendRowFullWidth={true}
                getRowClassName={(params) => getRowClassName(params)}
                sortingMode="server"
                sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                onSortModelChange={handleSort}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default MembersDataGridView;

interface MembersGridProps extends GridProps<MembersResponseModel> {}
