import {
     AboutUs,
     Compliance,
     ContactUs,
     Disclaimer,
     Expectations,
     ForgottenPassword,
     ForgottenUsername,
     Info24HoursDealer,
     Info24HoursDealerPrePay,
     Info24HoursTransporters,
     InfoHowToGetPaid,
     InstantPrices,
     JobDetails,
     Privacy,
     Register,
     Terms,
     TermsInstantPrices,
     TermsStandard,
     TermsStandardUnstyled,
     VerifyEmailAddress,
} from '../Screens/Admin/System/Pages/Layout';

export const RouteConstants = {
     Login: '/login',
     TemporaryImageFile: '/files/temporary-image?file',

     /** Tasks */
     ViewTasks:'/view-tasks',
     ViewTask: '/add-task',
     EditTaskRoute: '/edit-task',
     /** Members */
     NewMembers: '/new-members',
     Members: '/members',
     EditMember: '/edit-member',
     MembersByGroup: '/members?groupID',
     EditMemberByMemberId: '/edit-member/',
     /** Feedback */
     FeebackBy: '/view-feedback?fromMemberID',
     FeebackTo: '/view-feedback?toMemberID',
     EditFeedback: '/edit-feedback',
     ViewFeedbacks: '/view-feedback',
     /** Searches */
     SearchesByMember: '/searches?memberID',
     AddSearch: '/add-search?searchID',
     EditSearch: '/edit-search',
     ViewSearches: '/view-searches',
     /** Jobs */
     JobActivityHistoryByMemberID: '/jobs-activity-history?memberID',
     JobActivityHistoryByAdminID: '/jobs-activity-history?adminUserID',
     JobActivityHistoryByJobID: '/jobs-activity-history?jobID',
     JobsPlacedByMember: '/jobs?memberID',
     JobsByGroup: '/jobs?groupID',
     Jobs: '/jobs',
     ViewJobs: '/view-jobs',
     ViewJobById: '/view-job',
     /** Groups */
     ViewGroups: '/view-groups',
     EditGroup: '/edit-group',
     /** Billing */
     ViewBills: '/view-bills',
     BillingByMember: '/billing?memberID',
     ViewBillById: '/view-bill',
     ViewInvoiceById: '/view-invoice',
     InvoiceDocument: '/files/jobs-invoice?invoiceFileName',
     CodaDocument: '/files/admin-csv?csvFileName',
     /** Partners */
     EditPartner: '/edit-partner',
     ViewPartners: '/view-partners',
     /**Administrators */
     ViewAdministrators: '/administrators',
     EditAdministrator: '/edit-administrator',
     EditAdminRole: '/edit-adminrole',
     EditPageByName: 'edit-page/?name',
     DeletePageByName: 'delete-page/?name',
     /** AdminRoles */
     ViewAdminRoles: '/adminroles',
     /** Price Group */
     EditPriceGroupById: '/edit-price-group',
     ViewPriceGroups: '/view-price-groups',
     /** Vehicle */
     EditVehicle: '/edit-vehicle',
     AddVehicle: '/add-vehicle',
     ViewVehicles: '/view-vehicles',
     /** Tasks Page */
     TasksPage: '/tasks',
     ViewTaskById: '/view-task',
     /** Billing */
     // EditBilling: '/view-bill?billID',
};

export const PageRoutes = [
     { path: 'About-Us', component: AboutUs },
     { path: 'Compliance', component: Compliance },
     { path: 'Contact-Us', component: ContactUs },
     { path: 'Disclaimer', component: Disclaimer },
     { path: 'Expectations', component: Expectations },
     { path: 'Forgotten-Password', component: ForgottenPassword },
     { path: 'Forgotten-Username', component: ForgottenUsername },
     { path: 'Info-24-Hours-Transporters', component: Info24HoursTransporters },
     { path: 'Info-24-Hours-Dealer', component: Info24HoursDealer },
     { path: 'Info-24-Hours-Dealer-PrePay', component: Info24HoursDealerPrePay },
     { path: 'Info-How-To-Get-Paid', component: InfoHowToGetPaid },
     { path: 'Instant-Prices', component: InstantPrices },
     { path: 'Job-Details', component: JobDetails },
     { path: 'Privacy', component: Privacy },
     { path: 'Register', component: Register },
     { path: 'Terms', component: Terms },
     { path: 'Terms-Instant-Prices', component: TermsInstantPrices },
     { path: 'Terms-Standard', component: TermsStandard },
     { path: 'Terms-Standard-Unstyled', component: TermsStandardUnstyled },
     { path: 'Verify-Email-Address', component: VerifyEmailAddress },
];
