import React from "react";
import "chartjs-adapter-luxon";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import StreamingPlugin from "chartjs-plugin-streaming";
Chart.register(...registerables);
Chart.register(StreamingPlugin);
const HangFireDashboard: React.FC = (): JSX.Element => {
  return (
    <div className="col-md-12">
      <div style={{ display: "block" , width: "100%", paddingInline: "18%", paddingBlock: 15 }}>
      <h1 className="page-header">Overview</h1>
        <hr
          style={{
            color: "grey",
            height: 1,
          }}
        />
        <h3>Realtime Graph</h3>
        <Line
          data={{
            datasets: [
              {
                label: "Failed",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderColor: "rgb(255, 99, 132)",
                cubicInterpolationMode: "monotone",
                fill: false,
                data: [],
              },
              {
                label: "Succeeded",
                backgroundColor: "lightgreen",
                borderColor: "green",
                cubicInterpolationMode: "monotone",
                fill: false,
                data: [],
              },
            ],
          }}
          options={{
            scales: {
              x: {
                type: "realtime",
                realtime: {
                  delay: 2000,
                  onRefresh: (chart) => {
                    chart.data.datasets.forEach((dataset) => {
                      dataset.data.push({
                        x: Date.now(),
                        y: Math.random(),
                      });
                    });
                  },
                },
              },
            },
          }}
        />
      </div>
      <div style={{ display: "block" , width: "100%", paddingInline: "18%", paddingBlock: 15 }}>
        <h3>History Graph</h3>
        <Line
          data={{
            datasets: [
              {
                label: "Failed",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderColor: "rgb(255, 99, 132)",
                cubicInterpolationMode: "monotone",
                fill: false,
                data: [],
              },
              {
                label: "Succeeded",
                backgroundColor: "lightgreen",
                borderColor: "green",
                cubicInterpolationMode: "monotone",
                fill: false,
                data: [],
              },
            ],
          }}
          options={{
            scales: {
              x: {
                type: "realtime",
                realtime: {
                  delay: 2000,
                  onRefresh: (chart) => {
                    chart.data.datasets.forEach((dataset) => {
                      dataset.data.push({
                        x: Date.now(),
                        y: Math.random(),
                      });
                    });
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
export default HangFireDashboard;
