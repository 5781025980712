import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { InvoiceItemModelKeys } from '../../../../../Models/Billing';
import { ActionColumnPath } from '../../../../../Utils';

const {
  renderInvoiceIDCell,
  renderInvoiceTypeCell,
  renderDateCell,
  renderCellWithTooltip,
  renderCurrencyCell,
  renderInvoicesActionCell,
} = CellRenderer;

export const columnsComponents = (onEmailInvoicePdf : Function, showAlert: Function, handleAlertClose: Function ) => {

  return [
    {
      field: InvoiceItemModelKeys.Id,
      headerName: 'ID',
      description: 'ID',
      renderCell: (params: GridRenderCellParams) => renderInvoiceIDCell(params),
      flex: 2,
    },
    {
      field: InvoiceItemModelKeys.InvoiceNo,
      headerName: 'Invoice No',
      description: 'Invoice No',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    },
    {
      field: InvoiceItemModelKeys.Type,
      description: 'Type',
      headerName: 'Type',
      renderCell: (params: GridRenderCellParams) => renderInvoiceTypeCell(params),
      flex: 6,
      sortable: false,
    },
    {
      field: InvoiceItemModelKeys.MemberCodaCustomerReference,
      headerName: 'C-Ref',
      description: 'C-Ref',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
      sortable: false,
    },
    {
      field: InvoiceItemModelKeys.MemberCodaSupplierReference,
      headerName: 'S-Ref',
      description: 'S-Ref',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
      sortable: false,
    },
    {
      field: InvoiceItemModelKeys.MemberCompany,
      description: 'Company',
      headerName: 'Company',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    },
    {
      field: InvoiceItemModelKeys.InvoiceDate,
      description: 'Invoice Date',
      headerName: 'Invoice Date',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderDateCell(params),
    },
    {
      field: InvoiceItemModelKeys.DueDate,
      description: 'Due Date',
      headerName: 'Due Date',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderDateCell(params),
    },
    {
      field: InvoiceItemModelKeys.DeductionDate,
      description: 'Deduction Date',
      headerName: 'Deduction Date',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderDateCell(params),
    },
    {
      field: InvoiceItemModelKeys.TotalNetAmount,
      description: 'Net',
      headerName: 'Net',
      renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
      flex: 4,
      sortable: false,
    },
    {
      field: InvoiceItemModelKeys.TotalVatAmount,
      description: 'VAT',
      headerName: 'VAT',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
      sortable: false,
    },
    {
      field: InvoiceItemModelKeys.TotalGrossAmount,
      description: 'Gross',
      headerName: 'Gross',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    },
    {
      field: ActionColumnPath,
      description: 'Action',
      headerName: 'Action',
      type: 'actions',
      flex: 6,
      getActions: (params: GridRowParams) => renderInvoicesActionCell(params, onEmailInvoicePdf, showAlert, handleAlertClose),
      sortable: false,
    },
  ];
};