import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loading } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { RefundPrePaymentModel } from '../../../../../Models/Jobs';
import { usePutRefundPrePayment } from '../Hooks';
import { ColWrapper, RowWrapper } from './style/PaymentModuleStyledComponents';

export const RefundPrePaymentModal: React.FC<GeneralFormProps> = ({ paymentId, onClose, onRefresh }): JSX.Element => {
     /** States */
     const [prePaymentRefunded, setPrePaymentRefunded] = React.useState(false);
     const [showAreYouSure, setShowAreYouSure] = React.useState(false);

     /** Query Hooks */
     const { mutate: putRefundPrePayment, data, isLoading, isSuccess, error } = usePutRefundPrePayment();

     useEffect(() => {
          if (data) {
               setPrePaymentRefunded(true);
               onRefresh();
          }
          onRefresh();
     }, [error, data]);

     /** Formik */
     const formik = useFormik({
          initialValues: {
               RefundDate: '',
               RefundAmount: 0,
               RefundEPDQ: false,
               SendCreditNote: false,
               RecipientsByCommas: '',
          },
          onSubmit: () => {
               setShowAreYouSure(true);
                 const payload = {
                     PaymentId : paymentId,
                     RefundDate : values.RefundDate,
                     RefundAmount : values.RefundAmount,
                     RefundEPDQ : values.RefundEPDQ,
                     SendCreditNote : values.SendCreditNote,
                     Recipients : values.RecipientsByCommas
                 } as RefundPrePaymentModel;

                 putRefundPrePayment(payload);
          },
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     return (
          <>
               {' '}
               <Loading loading={isLoading} />
               {prePaymentRefunded && isSuccess && <div>Payment Note updated!</div>}
               {prePaymentRefunded && !isSuccess && <div>Error: {error.Subject + ': PutRefoundPrePayment'}, {error.Description}</div>}
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         {!prePaymentRefunded && !showAreYouSure && (
                              <>
                                   <p>Refund Pre Payment?</p>
                                   <Row>
                                        <Col>
                                             <FormikControl
                                                  control={FormControlKeys.DatePicker}
                                                  label='Enter refund date'
                                                  name={'RefundDate'}
                                             />
                                        </Col>
                                        <Col>
                                             <FormikControl
                                                  control={FormControlKeys.InputGroup}
                                                  label='Enter refund amount'
                                                  name={'RefundAmount'}
                                             />
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  label='Refund EPDQ?'
                                                  name={'RefundEPDQ'}
                                             />
                                        </Col>
                                        <Col>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  label='Send Credit Note?'
                                                  name={'SendCreditNote'}
                                             />
                                        </Col>
                                   </Row>
                                   <Row>
                                        <FormikControl
                                             control={FormControlKeys.Textarea}
                                             label='Enter recipients separated by commas'
                                             name={'RecipientsByCommas'}
                                        />
                                   </Row>
                              </>
                         )}
                         {error && <b style={{color: 'red'}}>Error: {error.AdditionalInformation} </b>}

                         {(showAreYouSure && !error) && (
                              <Row>
                                   <Col>
                                   Are you sure? {' '}
                                   <FormikControl
                                        name='Yes'
                                        onClick={() => {
                                             const payload = {
                                                  PaymentId: paymentId,
                                                  RefundDate: values.RefundDate,
                                                  RefundAmount: values.RefundAmount,
                                                  RefundEPDQ: values.RefundEPDQ,
                                                  SendCreditNote: values.SendCreditNote,
                                                  Recipients: values.RecipientsByCommas,
                                             } as RefundPrePaymentModel;

                                             putRefundPrePayment(payload);
                                        }}
                                        label='Yes'
                                        control={FormControlKeys.Button}
                                   />
                                   </Col>
                              </Row>
                         )}

                         <RowWrapper>
                              {!prePaymentRefunded && !showAreYouSure && (
                                   <ColWrapper>
                                        <FormikControl
                                             name='Submit'
                                             label='Submit'
                                             type='submit'
                                             control={FormControlKeys.Button}
                                        />
                                   </ColWrapper>
                              )}
                              <ColWrapper>
                                   <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                              </ColWrapper>
                         </RowWrapper>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     paymentId: number;
     onClose: Function;
     onRefresh: Function;
};
