import { Tooltip } from '@mui/material';
import {
DataGrid,
GridColDef,
GridColumnHeaderParams,
GridRenderCellParams,
GridSortModel
} from "@mui/x-data-grid";
import CSS from "csstype";
import React from "react";
import {
GeneralPublicIcon,
SupplierIcon,
UserIcon
} from "../../../../../Assets/Images";
import { ControlledPagination as Pagination } from "../../../../../Components";
import { HoverableLink } from "../../../../../Components/Formik/StyledComponents";
import {
NewOrUpdatedMemberItemModelKeys,
ReportsNewOrUpdatedMembersResponse
} from "../../../../../Models/NewOrUpdatedMembers";
import { GridProps,Sorter } from "../../../../../Types";
import { getSortModelFromSorter } from "../../../../../Utils";
import { MemberType,PaymentMethod,TradingStatus } from "../../../../../Utils/Enums";

const columns: GridColDef[] = [
  {
    field: NewOrUpdatedMemberItemModelKeys.Id,
    headerName: "ID",
    description: "Member ID",
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ""}>
        <HoverableLink to={`edit-member/${params.id}`}>
          {params.value}
        </HoverableLink>
      </Tooltip>
    ),
    valueGetter: (params) => {
      return params.row.Id;
    },
    maxWidth: 55,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.UserName,
    headerName: "UserName",
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Email,
    headerName: "Email",
    renderCell: (params: GridRenderCellParams) => renderEmailCell(params),
    minWidth: 200,
  },

  {
    field: NewOrUpdatedMemberItemModelKeys.Email_InvoiceForJobSent,
    headerName: "Invoice Email",
    
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => renderEmailCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.MemberType,
    description: "MemberType",
    renderCell: (params: GridRenderCellParams) =>
      getUserTypeCellContent(params),
    maxWidth: 55,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Member Type
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Company,    
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Company
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Address1,
    headerName: "Address 1",
    
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    minWidth: 80,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Address2,
    headerName: "Address 2",
    
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    minWidth: 80,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Address3,
    headerName: "Address 3",
    
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    minWidth: 80,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Town,
    headerName: "Town",
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    minWidth: 80,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.County,
    headerName: "County",
    
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.PostCode,
    headerName: "PostCode",
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    minWidth: 80,
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredAddress1,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered Address 1
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredAddress2,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered Address 2
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredAddress3,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderTooLongCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered Address 3
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredCounty,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered County
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredPostCode,
    
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered PostCode
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.RegisteredCountry,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Registered Country
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Company_TradingStatus,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderTradingStatusCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Company Trading Status
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Company_RegistrationNumber,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Company Registration Number
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.VATNo,
    headerName: "VAT No.",
    
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.PaymentMethod,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderPaymentCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Payment Method
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.PaymentReference,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Payment Reference
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.BankName,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Bank Name
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.AccountName,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Account Name
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.AccountNo,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Account No
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.SortCode,
    headerName: "Sort Code",
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Landline,
    headerName: "Landline",
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.Mobile,
    headerName: "Mobile",
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.CodaCustomerReference,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Coda Customer Reference
      </div>
    )
  },
  {
    field: NewOrUpdatedMemberItemModelKeys.CodaSupplierReference,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => (
      <div className="grid-custom-header">
        Coda Supplier Reference
      </div>
    )
  },
];

const renderCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value || ""}>
      <span>{params.value}</span>
    </Tooltip>
  );
};

const renderPaymentCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={PaymentMethod[params.value] || ""}>
      <span>{PaymentMethod[params.value]}</span>
    </Tooltip>
  );
};

const renderTradingStatusCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={TradingStatus[params.value] || ""}>
      <span>{TradingStatus[params.value]}</span>
    </Tooltip>
  );
};

const renderTooLongCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value || ""}>
      <span style={{
        display: 'inline-block',
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
        {params.value}
      </span>
    </Tooltip>
  );
};

const renderEmailCell = (params: GridRenderCellParams) => {
  return (
    <div style = {{
      wordWrap: 'break-word', 
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      overflow: 'hidden',
      paddingRight: '5px'
    }}>
      {params.value}
    </div>
  );
};


const getUserTypeCellContent = (params: GridRenderCellParams) => {
  switch (params.value) {
    case MemberType.MotorDealer:
      return (
        <Tooltip title="User">
          <img alt="User" style={imageStyle} src={UserIcon} />
        </Tooltip>
      );
    case MemberType.TransportCompany:
      return (
        <Tooltip title="Supplier">
          <img alt="Supplier" style={imageStyle} src={SupplierIcon} />
        </Tooltip>
      );
    case MemberType.GeneralPublic:
      return (
        <Tooltip title="General Public">
          <img
            alt="General Public"
            style={imageStyle}
            src={GeneralPublicIcon}
          />
        </Tooltip>
      );
  }
};

const NewOrUpdatedUsersGridView: React.FC<NewOrUpdatedUsersGridViewProps> = (
  props
) => {
  const { data, onPageChange, onPageSizeChange, onSort } = props;

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentPage: number
  ) => {
    onPageChange(currentPage);
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
       onPageSizeChange(parseInt(e.target.value));
  };

  const handleSort = (sortModel: GridSortModel) => {
       if (sortModel.length === 0) {
            sortModel = [
                 {
                      field: 'Id',
                      sort: 'asc',
                 },
            ];
       }
       onSort(sortModel);
  };

  const { Page: page, TableSorter } = data;

  if (page) {
    const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = page;
    
    const CustomGridFooter: React.FC = (): JSX.Element => {
      return (
        <div>
          <Pagination
            itemsCount={TotalItems}
            totalPages={TotalPages}
            pageSize={ItemsPerPage}
            currentPage={CurrentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      );
    };
    return (
      <div>
        {page && (
         
         <div className="new-or-updated-users-grid-container">
         <div style={{ display: "flex", height: "100%", width: "100%" }}>
           <div id='id_grid_new_or_updated_users' style={{ flexGrow: 1 }}>
                <DataGrid
                  sortingOrder={['desc', 'asc']}
                  getRowId={(row: any) => row.Id}
                  autoHeight
                  rows={Items}
                  rowHeight={41}
                  disableColumnMenu={true}
                  columns={columns}
                  components={{
                    Toolbar: CustomGridFooter,
                    Footer: CustomGridFooter,
                  }}
                  disableExtendRowFullWidth={true}
                 
                  sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                  onSortModelChange={handleSort}
                />
              
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default NewOrUpdatedUsersGridView;

interface NewOrUpdatedUsersGridViewProps
  extends GridProps<ReportsNewOrUpdatedMembersResponse> {}

const imageStyle: CSS.Properties = { height: "25px", width: "25px" };
