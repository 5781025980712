export class AddPageModel {
    public IsNew: boolean = false;
    public Name: string = '';
    public Layout: string = '';
    public Title: string = '';
    public MetaKeywords: string = '';
    public MetaDescription: string = '';
    public Styles: string = '';
    public Scripts: string = '';
}

export const AddPageModelKeys = {
    IsNew: "IsNew",
    Name: 'Name',
    Layout: 'Layout',
    Title: 'Title',
    MetaKeywords: 'MetaKeywords',
    MetaDescription: 'MetaDescription',
    Styles: 'Styles',
    Scripts: 'Scripts',
}