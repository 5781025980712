import React from 'react';

export const Expectations = () => {
  return (
    <div className="terms-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>EXPECTATIONS</h1>

            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">
                @(Config.MovexLandline)
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>EXPECTATIONS</h1>

            <p>
              <strong>
                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
                THIS SITE
              </strong>
            </p>

            <ul>
              <li>
                Additional notes should be read and accepted before quoting or
                accepting a fixed priced job. You will be held accountable for
                completing the job in line with the additional notes.
                Noncompliance will be registered as a failure and could affect
                payment of the job.
              </li>
              <li>
                Collections and deliveries must be completed within the dates
                provided when quoting. Movex should be made aware immediately of
                any changes made to these dates. High expectations are placed on
                all jobs, but retail deliveries are of utmost importance.
              </li>
              <li>
                Signatures for Collection/Delivery must be provided or the name
                of the person who released/accepted the vehicle noted on your
                POC/POD.
              </li>
              <li>
                Movex must be informed whilst you are onsite of any additional
                charge for waiting time and be provided with your hourly rate.
                Once the vehicle has been collected you must inform us of the
                total charge. We expect you to allow 30 mins waiting time from
                the time you call Movex, as matter of courtesy.
              </li>
              <li>
                Aborts must be notified to Movex immediately and you must remain
                onsite. Authority to leave site must be gained from Movex.
              </li>
              <li>
                For all issues, please contact Movex and not the customer in the
                first instance.
              </li>
              <li>
                Drivers should be smartly dressed and act in a professional
                manner.
              </li>
              <li>
                Job costs or additional costs should not be discussed directly
                with the customer or dealership.
              </li>
              <li>Subcontracting of Movex jobs is prohibited.</li>
              <li>
                A senior contact within the transport company should be
                available by phone or email throughout business hours. A maximum
                of 60 minutes delay in response is deemed acceptable.
              </li>
              <li>
                The Movex App should be used for all collections and deliveries
                in the first instance. Use of alternative mobile Apps is not
                advised but will be accepted.
              </li>
              <li>
                Requests for lifts from customers is prohibited, and should not
                be requested under any circumstances.
              </li>
              <li>
                Late cancellations will have penalties attached see terms and
                conditions for details.
              </li>
              <li>
                Communication with Movex or customers should be concise and
                correct. Names of contacts and the date and time of the calls
                must be recorded and provided to support payment of aborts and
                additional costs.
              </li>
              <li>
                Collection points should be contacted by midday the day prior to
                collection unless stated differently in the additional notes.
                Failure to gain a response should be reported to Movex
                immediately. Movex will not support abort costs for suppliers
                arriving at collection without calling ahead.
              </li>
              <li>
                Breakdowns should be notified to Movex immediately if within
                business hours. Outside of business hours vehicles should be
                recovered to the driver’s home address or a secure compound and
                reported to Movex and the customer via email. Details providing
                the location of the vehicle and a brief description of the
                reason for the breakdown should be included. Movex should be
                called at the earliest opportunity the following business day.
              </li>
              <li>
                Continued supplier issues of 10% and over will be monitored on a
                rolling 3-month basis and failure to reduce to an acceptable
                level could lead to account suspension.
              </li>
              <li>
                Damages should be reported to Movex immediately. Suppliers are
                expected to cooperate with Movex and their own insurance company
                to ensure a swift resolution. Suppliers will be liable for any
                damage noticed on delivery that has not been noted on the proof
                of collection report. Payment for this movement will be paused
                until the damage issue has been resolved.
              </li>
              <li>
                Government guidelines around covid-19 precautions should be
                followed at all times, however please read additional notes if
                they exist in case the customer requests additional measures.
              </li>
            </ul>

            <hr className="dash" />
          </div>
        </div>
      </div>
    </div>
  );
};