import React, { Component, FunctionComponent } from 'react';
import {
  MainContentOuterContainer,
  MainContentInnerContainer,
  RowFluidContainer,
  Span8Offset2Container,
} from '../../../../Components/Formik/StyledComponents';
import { renderHeader } from '../../../../Components/PageHeader';

const withAddForm = (WrappedComponent: JSX.Element, headerName: string) => {
  return (
    <MainContentOuterContainer>
      {renderHeader(headerName)}
      <MainContentInnerContainer>
        <RowFluidContainer>
          <Span8Offset2Container>{WrappedComponent}</Span8Offset2Container>
        </RowFluidContainer>
      </MainContentInnerContainer>
    </MainContentOuterContainer>
  );
};

export default withAddForm;
