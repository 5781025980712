import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, useAlert } from '../../../../Components';
import { PaymentItemModelKeys, PaymentsGridFilter, ViewPaymentsRequestParam } from '../../../../Models/Jobs/Payments';
import { Pager } from '../../../../Types';
import {
  getSorterFromSortModel, Page
} from '../../../../Utils';
import FilterBox from './FilterBox';
import GridView from './Grid';
import { useGetPayments } from './Hooks';
import { AdminJobStatus, PaymentStatus } from '../../../../Utils/Enums';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';

const ViewPayments: React.FC = (): JSX.Element => {

  /** useStates */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerDataFetch, setTriggerDataFetch] = useState<boolean>(false);
  
  const today = new Date().toISOString().slice(0, 10);
  const [filter, setFilter] = useState<PaymentsGridFilter>({
    JobStatus: AdminJobStatus.CompletedJob,
    DueDateTo: today,
    PaymentStatus: PaymentStatus.Approved,
  });

  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: PaymentItemModelKeys.DueDate,
      sort: 'desc',
    },
  ]);

  /** Methods */
  const getParams = () => {
    const params: ViewPaymentsRequestParam = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Query Hook */
  const reqOption = { enabled: false};
  const {
    isLoading: isGetPaymentListLoading,
    data: paymentList,
    refetch: fetchPayments,
    error: getPaymentListError,
  } = useGetPayments(getParams(), reqOption);
  const isDataLoaded = paymentList != null;
  const isResultEmpty = paymentList?.Page == null;

  const handleSearch = (filter: PaymentsGridFilter) => {
    setTriggerDataFetch(true);
    if (filter.PaymentStatus === PaymentStatus.All) filter.PaymentStatus = null;
    if (filter.JobStatus === AdminJobStatus.All) filter.JobStatus = null;
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setTriggerDataFetch(true);
    setFilter({});
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
  };

  /** useEffects */
  useEffect(() => {
    if (triggerDataFetch) {
      fetchPayments();
    }
  }, [triggerDataFetch, pager, filter, sortModel]);

  useEffect(() => {
    setIsLoading(isGetPaymentListLoading);
  }, [isGetPaymentListLoading]);

  useEffect(() => {
    if (getPaymentListError) {
      setAlert({
        ...alert,
        show: true,
        header: getPaymentListError?.Subject,
        body: getPaymentListError?.Description,
      });
    }
  }, [getPaymentListError]);
  
  return (
    <div>
      <Alert {...alert} />
      {renderHeader('View Payments')}
      <div className="container-fluid">
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} filter={filter}/>
        <Loading loading={isLoading} />
        {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
        {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
        {isDataLoaded && paymentList && (
          <GridView
            onFetchData={fetchPayments}
            data={paymentList}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPayments;