import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert, ConfirmDialog, Loading, renderHeader, useAlert } from '../../../../Components';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import useHandleGridFilterCache, { GridFilterKeys } from '../../../../Components/savedFilterHook/useHandleGridFilterCache';
import SearchResultPlaceholder from '../../../../Components/SharedComponents/girdComponents/SearchResultMessage';
import { FeedbackFilter, GetFeedbackListRequest } from '../../../../Models/Feedback';
import { ApplicationState } from '../../../../Store/Types';
import { Pager } from '../../../../Types';
import { ConfirmDialogContent, getDateFrom, getSorterFromSortModel, Page } from '../../../../Utils';
import { DateFrom } from '../../../../Utils/Enums/DateFrom';
import { FreeTextSearchByOptions } from '../../../../Utils/Enums/FreeTextSearchBy';
import FilterBox from './FilterBox';
import ViewFeedbackGridView from './Grid';
import { useDeleteFeedback, useGetFeedbackList } from './Hooks';
import { FeedbackGridKeys } from './Types';
import { parseQueryParamAsInt } from '../../../../Utils/parsingHelper';

const ViewFeedback: React.FC = (): JSX.Element => {
     /** get Params */
     const { search } = useLocation();
     const filterParams = new URLSearchParams(search);
     const fromMemberID = parseQueryParamAsInt(filterParams, 'fromMemberID');
     const forMemberID = parseQueryParamAsInt(filterParams, 'toMemberID');
     const dateRange = (fromMemberID || forMemberID) ? DateFrom.ThreeMonths : DateFrom.Week;
     const dateAddedFrom = getDateFrom(dateRange);

     /** useStates */
     const { saveFilterInCache } = useHandleGridFilterCache({
          key: GridFilterKeys.FeedbackFilterKey,
          defaultFilter: {
               FreeTextSearchBy: FreeTextSearchByOptions.JobID,
               FromMemberID: fromMemberID,
               ToMemberID: forMemberID,
               DateAddedFrom: dateAddedFrom,
               DateAddedTo: new Date().toISOString(),
          },
     });
     const [pager, setPager] = useState<Pager>({
          CurrentPage: Page.CurrentPage,
          ItemsPerPage: Page.PageSize,
     });
     const [sortModel, setSortModel] = useState<GridSortModel>([
          {
               field: FeedbackGridKeys.ID,
               sort: 'desc',
          },
     ]);
     const [shouldTriggerFetch, triggerFeedbacksFetch] = useState<boolean>(false);

     /** get the 'last updated' value from Redux */
     const lastUpdatedGridState = useSelector((state: ApplicationState) => state.gridsFilterState.savedFilters!);

     /** Functions */
     const getParams = () => {
          const params: GetFeedbackListRequest = {
               Pager: pager,
               Filter: {
                    ...lastUpdatedGridState.filterData,
                    FromMemberID: fromMemberID,
                    ToMemberID: forMemberID,
               } as FeedbackFilter,
               Sorter: getSorterFromSortModel(sortModel),
          };
          return params;
     };

     const handleSearch = (filter: FeedbackFilter) => {
          saveFilterInCache({
               key: GridFilterKeys.FeedbackFilterKey,
               filterData: {
                    FreeTextSearchBy: filter?.FreeTextSearchBy,
                    FreeText: filter?.FreeText,
                    FromMemberID: fromMemberID,
                    ToMemberID: forMemberID,
                    DateAddedFrom: filter?.DateAddedFrom,
                    DateAddedTo: filter?.DateAddedTo,
               },
          });
          triggerFeedbacksFetch(true);
     };

     const handleClearSearch = (triggerFetch?: boolean) => {
          saveFilterInCache({
               key: GridFilterKeys.FeedbackFilterKey,
               filterData: {
                    FreeTextSearchBy: FreeTextSearchByOptions.JobID,
                    DateAddedFrom: dateAddedFrom,
                    DateAddedTo: new Date().toISOString(),
                    FreeText: '',
               },
          });
          triggerFeedbacksFetch(triggerFetch == undefined ? true : triggerFetch);
     };

     const pageChangeHandler = (currentPage: number) => {
          setPager({ ...pager, CurrentPage: currentPage });
          triggerFeedbacksFetch(true);
     };

     const pageSizeChangeHandler = (itemsPerPage: number) => {
          setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
          triggerFeedbacksFetch(true);
     };

     const onSort = (sortModel: GridSortModel) => {
          setSortModel(sortModel);
          setPager({ ...pager, CurrentPage: 1 });
          triggerFeedbacksFetch(true);
     };

     const onDelete = (feedbackID: number) => {
          openConfirmDialog(ConfirmDialogContent.DeleteFeedback.Title, ConfirmDialogContent.DeleteFeedback.Content, () => {
               deleteFeedback(feedbackID);
               closeConfirmDialog();
          });
          triggerFeedbacksFetch(true);
     };

     /** Hooks */
     const { closeConfirmDialog, confirmDialogProps, openConfirmDialog } = useConfirmDialog();
     const { alert, setAlert } = useAlert();
     const {
          data: getFeedbackListResponse,
          isLoading: isGetFeedbackListLoading,
          refetch: refetchGetFeedbackList,
          error: getFeedbackListError,
     } = useGetFeedbackList(getParams(), { enabled: false });
     const isDataLoaded = getFeedbackListResponse != null;
     const isResultEmpty = getFeedbackListResponse?.Page == null;
     
     const { mutate: deleteFeedback, isSuccess: deleteFeedbackIsSuccess, error: deleteFeedbackError } = useDeleteFeedback();

     /** useEffects */
     useEffect(() => {
          if (lastUpdatedGridState.key !== GridFilterKeys.FeedbackFilterKey) {
               const triggerFetching = false;
               handleClearSearch(triggerFetching);
          }
     }, []);
     
     useEffect(() => {
          if (shouldTriggerFetch) {
               refetchGetFeedbackList();
               triggerFeedbacksFetch(false);
          }
     }, [shouldTriggerFetch, deleteFeedbackIsSuccess]);
     
     useEffect(() => {
          if (getFeedbackListError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getFeedbackListError.Subject ?? 'Error when getting feedback',
                    body: getFeedbackListError?.Description,
               });
          } else if (deleteFeedbackError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: deleteFeedbackError?.Subject ?? 'Error when deleting feedback',
                    body: deleteFeedbackError?.Description,
               });
          }
     }, [getFeedbackListError, deleteFeedbackError]);

     /** Render */
     return (
          <div>
               <Alert {...alert} />
               <ConfirmDialog {...confirmDialogProps} />
               {renderHeader('Feedback')}
               <div className='container-fluid'>
                    <FilterBox
                         filters={lastUpdatedGridState.filterData as FeedbackFilter}
                         onSearch={handleSearch}
                         onClearSearch={handleClearSearch}
                    />
                    <Loading loading={isGetFeedbackListLoading} />
                    {isDataLoaded && isResultEmpty && <SearchResultPlaceholder message='No matching results found.' />}
                    {!isDataLoaded && <SearchResultPlaceholder message='Please perform a search.' />}
                    {isDataLoaded && getFeedbackListResponse && (
                         <ViewFeedbackGridView
                              data={getFeedbackListResponse}
                              onSort={onSort}
                              onPageChange={pageChangeHandler}
                              onPageSizeChange={pageSizeChangeHandler}
                              onDelete={onDelete}
                         />
                    )}
               </div>
          </div>
     );
};

export default ViewFeedback;
