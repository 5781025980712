import { useQuery, UseQueryResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { IGetJobSummaryDetailsResponseModel } from '../../../../../Models/Tasks/GetJobSummaryDetailsResponseModel';

export const useGetSummaryDetails = (
     jobId: number,
     options?: any
): UseQueryResult<IGetJobSummaryDetailsResponseModel, any> => {
     const getSummaryDetail = () => {
          if (jobId) {
               return adminTasks.getSummaryDetails(jobId);
          }
     };

     return useQuery(['GetSummaryDetails', jobId], getSummaryDetail, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
