import * as React from "react";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const succeededJobsColumns: GridColDef[] = [
  { field: "id", headerName: "Id", width: 100 },
  {
    field: "job",
    headerName: "Job",
    width: 550,
  },
  {
    field: "totalDuration",
    headerName: "Total Duration",
    width: 150,
  },
  {
    field: "succeeded",
    headerName: "Succeeded",
    width: 100,
  },
];

const succeededJobsRows = [
  {
    id: "#131192	",
    job: "CommonRepository.ReOfferJobTo",
    totalDuration: "5m 2.273s",
    succeeded: "a day ago",
  },
];

const failedJobsColumns: GridColDef[] = [
  { field: "id", headerName: "Id", width: 100 },
  {
    field: "failed",
    headerName: "Failed",
    width: 250,
  },
  {
    field: "job",
    headerName: "Job",
    width: 700,
  },
];

const failedJobsRows = [
  {
    id: "#32159",
    failed: "a year ago",
    job: "CommonRepository.ReOfferJobTo",
  },
  {
    id: "#31963",
    failed: "a year ago",
    job: "CommonRepository.ReOfferJobTo",
  },
  {
    id: "#31948",
    failed: "a year ago",
    job: "CommonRepository.ReOfferJobTo",
  },
  {
    id: "#31941",
    failed: "a year ago",
    job: "CommonRepository.ReOfferJobTo",
  },
];

const Jobs = () => {
  const [show, setShow] = useState<string>("queues");
  return (
    <div style={{ display: "flex" }}>
      <div className="col-md-2 scroll-to-general-tab-menus">
        <Nav
          id="addMemberLeftNav"
          className="flex-column"
          style={{ position: "fixed", marginTop: 30 }}
        >
          <Link spy={true} activeClass="active" to="queues">
            <div
              onClick={() => {
                setShow("queues");
              }}
            >
              Enqueued
            </div>
          </Link>
          <Link spy={true} to="scheduledJobs">
            <div
              onClick={() => {
                setShow("scheduledJobs");
              }}
            >
              Scheduled
            </div>
          </Link>

          <Link spy={true} to="processingJobs">
            <div
              onClick={() => {
                setShow("processingJobs");
              }}
            >
              Processing
            </div>
          </Link>

          <Link spy={true} to="succeededJobs">
            <div
              onClick={() => {
                setShow("succeededJobs");
              }}
            >
              Succeedeed
            </div>
          </Link>

          <Link spy={true} to="failedJobs">
            <div
              onClick={() => {
                setShow("failedJobs");
              }}
            >
              Failed
            </div>
          </Link>

          <Link spy={true} to="deletedJobs">
            <div
              onClick={() => {
                setShow("deletedJobs");
              }}
            >
              Deleted
            </div>
          </Link>

          <Link spy={true} to="awaitingJobs">
            <div
              onClick={() => {
                setShow("awaitingJobs");
              }}
            >
              Awaiting
            </div>
          </Link>
        </Nav>
      </div>

      <div className="col-md-8" style={{ marginBlock: "1%" }}>
        {show === "queues" ? (
          <div id="queues">
            <h1 className="page-header">Queues</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div className="alert alert-warning">
              No queued jobs found. Try to enqueue a job.
            </div>
          </div>
        ) : show === "scheduledJobs" ? (
          <div id="scheduledJobs">
            <h1 className="page-header">Scheduled Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div className="alert alert-info">There are no scheduled jobs.</div>
          </div>
        ) : show === "processingJobs" ? (
          <div id="processingJobs">
            <h1 className="page-header">Processing Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div className="alert alert-info">
              No jobs are being processed right now.
            </div>
          </div>
        ) : show === "succeededJobs" ? (
          <div id="succeededJobs">
            <h1 className="page-header">Succeeded Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div style={{ height: 200, marginBottom: "10%" }}>
              <Stack spacing={2} direction="row" paddingY={2}>
                <Button variant="contained" onClick={() => {}}>
                  Requeue jobs
                </Button>
              </Stack>

              <div id='id_grid_scheduling_service'>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                rows={succeededJobsRows}
                columns={succeededJobsColumns}
                rowsPerPageOptions={[]}
                hideFooterPagination
                checkboxSelection
                disableSelectionOnClick
              />
              </div>
            </div>
          </div>
        ) : show === "failedJobs" ? (
          <div id="failedJobs">
            <h1 className="page-header">Failed Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />

            <div style={{ height: 400, marginBottom: "10%" }}>
              <Stack spacing={2} direction="row" paddingY={2}>
                <Button variant="contained" onClick={() => {}}>
                  Requeue jobs
                </Button>
                <Button variant="outlined" onClick={() => {}}>
                  Delete
                </Button>
              </Stack>

              <div id='id_grid_failed_jobs'>
              <DataGrid
                sortingOrder={['desc', 'asc']}
                rows={failedJobsRows}
                columns={failedJobsColumns}
                rowsPerPageOptions={[]}
                hideFooterPagination
                checkboxSelection
                disableSelectionOnClick
              />
              </div>
            </div>
          </div>
        ) : show === "deletedJobs" ? (
          <div id="deletedJobs">
            <h1 className="page-header">Deleted Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div className="alert alert-info">No deleted jobs found.</div>
          </div>
        ) : show === "awaitingJobs" ? (
          <div id="awaitingJobs">
            <h1 className="page-header">Awaiting Jobs</h1>
            <hr
              style={{
                color: "grey",
                height: 1,
              }}
            />
            <div className="alert alert-info">No deleted jobs found.</div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
