import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';

export const useGetPriceGroupSourcesAndTargetsByName = (
     name: string,
     options?: any
): UseQueryResult<any, any> => {
     const getPriceGroupSourcesAndTargetsByName = () => {
          if(name === "" || name === undefined) return [];
          return agent.priceGroup.getPriceGroupSourcesAndTargetsByName(name);
     };

     return useQuery(
          ['GetPriceGroupSourcesAndTargetsByName', name],
          getPriceGroupSourcesAndTargetsByName,
          {
               refetchOnMount: false,
               refetchOnWindowFocus: false,
               ...options,
          }
     );
};
