import { InvoiceType } from "../../../Utils/Enums"

export interface InvoiceItemModel {
    AlreadyPaid: boolean
    CodaFileGeneratedDate: Date
    CodaFileName: string
    DeductionDate: Date
    DueDate: Date
    Id: number
    InvoiceDate: Date
    InvoiceFileGeneratedDate: Date
    InvoiceFileName: string
    InvoiceNo: string
    MemberCodaCustomerReference: string
    MemberCodaSupplierReference: null
    MemberCompany: string
    MemberID: number
    PaymentReference: string
    Title: string
    TotalGrossAmount: number
    TotalNetAmount: number
    TotalRecords: number
    TotalVatAmount: number
    Type: InvoiceType
}

export const InvoiceItemModelKeys = {
    AlreadyPaid: "AlreadyPaid",
    CodaFileGeneratedDate: 'CodaFileGeneratedDate',
    CodaFileName: 'CodaFileName',
    DeductionDate: 'DeductionDate',
    DueDate: 'DueDate',
    Id: 'Id',
    InvoiceDate: 'InvoiceDate',
    InvoiceFileGeneratedDate: 'InvoiceFileGeneratedDate',
    InvoiceFileName: 'InvoiceFileName',
    InvoiceNo: 'InvoiceNo',
    MemberCodaCustomerReference: 'MemberCodaCustomerReference',
    MemberCodaSupplierReference: 'MemberCodaSupplierReference',
    MemberCompany: 'MemberCompany',
    MemberID: 'MemberID',
    PaymentReference: 'PaymentReference',
    Title: 'Title',
    TotalGrossAmount: 'TotalGrossAmount',
    TotalNetAmount: 'TotalNetAmount',
    TotalRecords: 'TotalRecords',
    TotalVatAmount: 'TotalVatAmount',
    Type: 'Type',
}