import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
     Alert,
     AlertProps,
     ConfirmDialog,
     ConfirmDialogProps,
     Loading,
     ShowPleaseWait,
     useAlert,
} from '../../../../Components';
import { FormControlKeys, OptionType } from '../../../../Components/FormControls';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
     DivContainer,
     FieldSetContainer,
     FieldSetHeader,
     FlexContainer,
     Width50DivContainer,
} from '../../../../Components/Formik/StyledComponents';
import { RequestingMemberFeeModel } from '../../../../Models/Prices/DealerMargin';
import { ConfirmDialogContent, isValidNumberAndNotZero } from '../../../../Utils';
import { FeeType, MemberType, RequestingMemberFeeType } from '../../../../Utils/Enums';
import { getCustomerSetForDropdown } from '../../Members/EditMember/Tabstrip/General/fetchOptions';
import {
     useDeleteDealerMarginFeeQuotesPrice,
     useDeleteDealerMarginFixedPrice,
     useGetDealerMarginFixedPrices,
     useGetDealerMarginQuotedPrices,
     usePostAddOrUpdateFeeFixedPrice,
     usePostAddOrUpdateFeeQuotesPrice,
} from './Hooks';
import { MarginGrid } from './MarginGrid';
import { DealerMarginGridType } from './Types';

const MarginMaintenance: React.FC<MarginMaintenanceProps> = (props) => {
     const { requestingMemberFeeType, memberID, memberType } = props;
     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     /** useStates */
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [options, setOptions] = useState<OptionType[]>([]);
     const [fixedPriceData, setFixedPriceData] = useState<RequestingMemberFeeModel[]>([
          {
               AmountTo: 0,
               ParentKeyID: 0,
               MemberFee: 0,
               RequestingMemberFeeQuoteRangeID: 0,
               RequestingMemberFeeType: 0,
               PriceFrom: 1,
          },
     ]);
     const [quotedData, setQuotedData] = useState<RequestingMemberFeeModel[]>([
          {
               AmountTo: 0,
               ParentKeyID: 0,
               MemberFee: 0,
               RequestingMemberFeeQuoteRangeID: 0,
               RequestingMemberFeeType: 0,
               PriceFrom: 1,
          },
     ]);
     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });
     const [editRowIdWithTypeMap, setEditRowIdWithPriceTypeMap] = useState<Map<number, number>>(
          new Map()
     );

     const [amoumtTo, setAmoumtTo] = useState<number>(0);
     const [margin, setMargin] = useState<number>(0);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Functions */
     const handleSaveNewPrice = (data: RequestingMemberFeeModel, isFixedPrice : number) => {
          if (data?.ParentKeyID != null && data?.ParentKeyID > 0) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Please select a Customer Set!',
                    body: 'Please select a Customer Set.',
               });
          } else {
               setEditRowIdWithPriceTypeMap(
                    editRowIdWithTypeMap.set(data.RequestingMemberFeeType, -1)
               );
               data.RequestingMemberFeeType = requestingMemberFeeType;

               if (isFixedPrice === FeeType.FeeFixedPrice) {
                    addOrUpdateFeeFixedPrice(data);
               }
               else if (isFixedPrice === FeeType.FeeQuotesPrice) {
                    addOrUpdateFeeQuotesPrice(data);
               }
          }
     };

     const handleShowNewLine = (type: number) => {
          if (type === DealerMarginGridType.FixedPrice) {
               setFixedPriceData([
                    ...fixedPriceData,
                    {
                         AmountTo: 0,
                         ParentKeyID: 0,
                         MemberFee: 0,
                         RequestingMemberFeeQuoteRangeID: 0,
                         RequestingMemberFeeType: 0,
                         PriceFrom: 1,
                    },
               ]);
          } else {
               setQuotedData([
                    ...quotedData,
                    {
                         AmountTo: 0,
                         ParentKeyID: 0,
                         MemberFee: 0,
                         RequestingMemberFeeQuoteRangeID: 0,
                         RequestingMemberFeeType: 0,
                         PriceFrom: 1,
                    },
               ]);
          }
          const map = new Map(editRowIdWithTypeMap);
          setEditRowIdWithPriceTypeMap(map.set(type, 0));
     };

     const handleCancelEditPrice = (type: number, rowId: number) => {
          if (rowId === 0) {
               const data = [...getGridDataByType(type)];
               data.pop();
               setGridDataByType(type, [...data]);
          } else {
               const map = new Map(editRowIdWithTypeMap);
               setEditRowIdWithPriceTypeMap(map.set(type, -1));
          }
     };

     const handleEditPrice = (type: number, rowId: number, amoumtTo: number, margin: number) => {
          setConfirmDialogContent({
               ...confirmDialogContent,
               header: ConfirmDialogContent.EditMarginDetail.Title,
               body: ConfirmDialogContent.EditMarginDetail.Content,
               show: true,
               confirmCallback: () => {
                    setAmoumtTo(amoumtTo);
                    setMargin(margin);
                    setEditRowIdWithPriceTypeMap(editRowIdWithTypeMap.set(type, rowId));
                    onConfirmDialogClose();
               },
          });
     };

     const handleDeletePrice = (type: number, rowId: number) => {
          setConfirmDialogContent({
               ...confirmDialogContent,
               header: ConfirmDialogContent.DeleteMarginDetail.Title,
               body: ConfirmDialogContent.DeleteMarginDetail.Content,
               show: true,
               confirmCallback: () => {
                    if (type === DealerMarginGridType.Quoted) deleteDealerMarginFeeQuotes(rowId);
                    if (type === DealerMarginGridType.FixedPrice)
                         deleteDealerMarginFeeFixedPrice(rowId);
                    onConfirmDialogClose();
               },
          });
     };

     const getGridDataByType = (type: number) => {
          if (type === DealerMarginGridType.FixedPrice) return fixedPriceData;
          if (type === DealerMarginGridType.Quoted) return quotedData;
          return [];
     };

     const setGridDataByType = (type: number, data: RequestingMemberFeeModel[]) => {
          if (type === DealerMarginGridType.FixedPrice) {
               setFixedPriceData([...data]);
          } else if (type === DealerMarginGridType.Quoted) {
               setQuotedData([...data]);
          }
     };

     const [fromMember, setForMember] = useState(memberID ?? 0);

     /** React Queries*/
     const {
          isLoading: isFixedPriceDataLoading,
          data: fixedPriceResponse,
          error: getFixedPriceDataError,
          refetch: fetchDealerMarginFixedPrices,
     } = useGetDealerMarginFixedPrices(requestingMemberFeeType, fromMember, {});

     const {
          isLoading: isQuotedPriceDataLoading,
          data: quotePriceResponse,
          error: getQuotedPriceDataError,
          refetch: fetchDealerMarginQuotedPrices,
     } = useGetDealerMarginQuotedPrices(requestingMemberFeeType, fromMember, {});

     const {
          mutate: addOrUpdateFeeFixedPrice,
          isLoading: isPostAddOrUpdateFeeFixedPriceLoading,
          data: addOrUpdateFeeFixedPriceResponse,
          error: addOrUpdateFeeFixedPriceError,
     } = usePostAddOrUpdateFeeFixedPrice();

     const {
          mutate: addOrUpdateFeeQuotesPrice,
          isLoading: isPostAddOrUpdateFeeQuotesPriceLoading,
          data: addOrUpdateFeeQuotesPriceResponse,
          error: addOrUpdateFeeQuotesPriceError,
     } = usePostAddOrUpdateFeeQuotesPrice();

     const {
          mutate: deleteDealerMarginFeeQuotes,
          isLoading: isDeleteDealerMarginFeeQuotesLoading,
          data: deleteDealerMarginFeeQuotesResponse,
          error: deleteealerMarginFeeQuotesError,
     } = useDeleteDealerMarginFeeQuotesPrice();

     const {
          mutate: deleteDealerMarginFeeFixedPrice,
          isLoading: isDeleteDealerMarginFeeFixedPriceLoading,
          data: deleteDealerMarginFeeFixedPriceResponse,
          error: deleteealerMarginFeeFixedPriceError,
     } = useDeleteDealerMarginFixedPrice();

     /** useEffects */
     useEffect(() => {
          setShowPleaseWait(
               isQuotedPriceDataLoading ||
                    isPostAddOrUpdateFeeFixedPriceLoading ||
                    isPostAddOrUpdateFeeFixedPriceLoading ||
                    isPostAddOrUpdateFeeQuotesPriceLoading ||
                    isDeleteDealerMarginFeeQuotesLoading ||
                    isDeleteDealerMarginFeeFixedPriceLoading
          );
     }, [
          isFixedPriceDataLoading,
          addOrUpdateFeeFixedPrice,
          isQuotedPriceDataLoading,
          isPostAddOrUpdateFeeFixedPriceLoading,
          isPostAddOrUpdateFeeFixedPriceLoading,
          isPostAddOrUpdateFeeQuotesPriceLoading,
          isDeleteDealerMarginFeeQuotesLoading,
          isDeleteDealerMarginFeeFixedPriceLoading,
     ]);

     useEffect(() => {
          if (requestingMemberFeeType !== RequestingMemberFeeType.CustomerSet) {
               fetchDealerMarginFixedPrices();
               fetchDealerMarginQuotedPrices();
          }
     }, [requestingMemberFeeType]);

     useEffect(() => {
          if (addOrUpdateFeeFixedPriceResponse || addOrUpdateFeeQuotesPriceResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Fixed Price added!',
                    body: 'Fixed Price Added',
               });
          }
          if (isValidNumberAndNotZero(fromMember)) {
               fetchDealerMarginFixedPrices();
               fetchDealerMarginQuotedPrices();
          }
     }, [addOrUpdateFeeFixedPriceResponse, addOrUpdateFeeQuotesPriceResponse]);

     useEffect(() => {
          if (addOrUpdateFeeFixedPriceError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addOrUpdateFeeFixedPriceError?.Subject  ?? 'Error when adding Fee!',
                    body: addOrUpdateFeeFixedPriceError?.Description ?? 'Error when adding Fee',
               });
          }
     }, [addOrUpdateFeeFixedPriceError]);

     useEffect(() => {
          if (addOrUpdateFeeQuotesPriceError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addOrUpdateFeeQuotesPriceError?.Subject  ?? 'Error when adding Fee!',
                    body: addOrUpdateFeeQuotesPriceError?.Description ?? 'Error when adding Fee',
               });
          }
     }, [addOrUpdateFeeQuotesPriceError]);

     useEffect(() => {
          if (deleteDealerMarginFeeQuotesResponse || deleteDealerMarginFeeFixedPriceResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Fee Deleted!',
                    body: 'Fee Deleted',
               });
          }
          if (isValidNumberAndNotZero(fromMember)) {
               fetchDealerMarginFixedPrices();
               fetchDealerMarginQuotedPrices();
          }
     }, [deleteDealerMarginFeeQuotesResponse, deleteDealerMarginFeeFixedPriceResponse]);

     useEffect(() => {
          if (deleteealerMarginFeeQuotesError || deleteealerMarginFeeFixedPriceError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Error when deleting Price!',
                    body: deleteealerMarginFeeQuotesError?.Description 
                    ?? deleteealerMarginFeeFixedPriceError?.Description 
                    ?? 'Error when deleting Price',
               });
          }
     }, [deleteealerMarginFeeQuotesError, deleteealerMarginFeeFixedPriceError]);

     useEffect(() => {
          if (getFixedPriceDataError || getQuotedPriceDataError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: getFixedPriceDataError?.Subject 
                    ?? getQuotedPriceDataError?.Subject,
                    body: getFixedPriceDataError?.Description 
                    ?? getQuotedPriceDataError?.Description,
               });
          }
     }, [getFixedPriceDataError, getQuotedPriceDataError]);

     useEffect(() => {
          setEditRowIdWithPriceTypeMap(editRowIdWithTypeMap.set(2, -1));
          setEditRowIdWithPriceTypeMap(editRowIdWithTypeMap.set(1, -1));
          setFixedPriceData(fixedPriceResponse);
          setQuotedData(quotePriceResponse);
     }, [fixedPriceResponse, quotePriceResponse]);

     useEffect(() => {
          if (isValidNumberAndNotZero(fromMember)) {
               fetchDealerMarginFixedPrices();
               fetchDealerMarginQuotedPrices();
          } else {
               setFixedPriceData([{}] as RequestingMemberFeeModel[]);
               setQuotedData([{}] as RequestingMemberFeeModel[]);
          }
     }, [fromMember]);

     useEffect(() => {
          setIsLoading(isFixedPriceDataLoading || isQuotedPriceDataLoading);
     }, [isFixedPriceDataLoading, isQuotedPriceDataLoading]);

     useEffect(() => {
          const fetchOptions = async () => {
               const newOptions = await getCusotmerSetListOptions();
               setOptions(newOptions);
          };
          fetchOptions();
     }, []);

     const formik = useFormik({
          initialValues: { MemberId: 0 },
          enableReinitialize: true,
          onSubmit: () => {},
     });

     return (
          <DivContainer>
               <Loading loading={isLoading} />
               <ShowPleaseWait show={showPleaseWait} />
               <ConfirmDialog {...confirmDialogContent}></ConfirmDialog>
               <Alert {...alert} />
               {requestingMemberFeeType === RequestingMemberFeeType.CustomerSet && (
                    <FormikProvider value={formik}>
                         <FormikControl
                              control={FormControlKeys.Select}
                              name={MarginMaintenanceGridKeys.MemberDropdownKey}
                              label='Customer Set:'
                              placeholder='Select'
                              options={options}
                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                   formik.setFieldValue(
                                        MarginMaintenanceGridKeys.MemberDropdownKey,
                                        e.target.value
                                   );
                                   setForMember(parseInt(e.target.value));
                              }}
                         />
                    </FormikProvider> 
               )}
               <FlexContainer id='divMarginContainer'>
                    {memberType != MemberType.TransportCompany && (
                                             <Width50DivContainer>
                                             <FieldSetContainer>
                                                  <FieldSetHeader>
                                                       Fixed Price
                                                       <span title='Add New'>
                                                            <AddCircleOutlineIcon
                                                                 className='add-new-icon'
                                                                 onClick={() => {
                                                                      handleShowNewLine(
                                                                           DealerMarginGridType.FixedPrice
                                                                      );
                                                                 }}
                                                            />
                                                       </span>
                                                  </FieldSetHeader>
                                                  <MarginGrid
                                                       isCustomerSet={requestingMemberFeeType === RequestingMemberFeeType.CustomerSet}
                                                       data={fixedPriceData}
                                                       marginGridType={DealerMarginGridType.FixedPrice}
                                                       editRowIdWithTypeMap={editRowIdWithTypeMap}
                                                       onCancelEdit={handleCancelEditPrice}
                                                       onDeleteSavedData={handleDeletePrice}
                                                       onEditSavedData={handleEditPrice}
                                                       onSubmit={handleSaveNewPrice}
                                                       parentKeyId={fromMember}
                                                       editingItem={{AmountTo: amoumtTo, MemberFee: margin}}
                                                  />
                                             </FieldSetContainer>
                                        </Width50DivContainer>
                    )}
                    <Width50DivContainer>
                         <FieldSetContainer>
                              <FieldSetHeader>
                                   Quoted
                                   <AddCircleOutlineIcon
                                        className='add-new-icon'
                                        onClick={() => {
                                             handleShowNewLine(DealerMarginGridType.Quoted);
                                        }}
                                   />
                              </FieldSetHeader>
                              <MarginGrid
                                   isCustomerSet={requestingMemberFeeType === RequestingMemberFeeType.CustomerSet}
                                   data={quotedData}
                                   marginGridType={DealerMarginGridType.Quoted}
                                   editRowIdWithTypeMap={editRowIdWithTypeMap}
                                   onCancelEdit={handleCancelEditPrice}
                                   onDeleteSavedData={handleDeletePrice}
                                   onEditSavedData={handleEditPrice}
                                   onSubmit={handleSaveNewPrice}
                                   parentKeyId={fromMember}
                                   editingItem={{AmountTo: amoumtTo, MemberFee: margin}}
                              />
                         </FieldSetContainer>
                    </Width50DivContainer>
               </FlexContainer>
          </DivContainer>
     );
};

export default MarginMaintenance;

type MarginMaintenanceProps = {
     requestingMemberFeeType: RequestingMemberFeeType;
     memberID?: number;
     memberType?: MemberType;
};

const MarginMaintenanceGridKeys = {
     MemberDropdownKey: 'MemberId',
};

const getCusotmerSetListOptions = async (): Promise<OptionType[]> => {
     const options: OptionType[] = [];
     try {
          const customerSetList = (await getCustomerSetForDropdown()) ?? [];
          customerSetList.forEach((customerSet) => {
               options.push({
                    value: customerSet.value,
                    label: customerSet.label,
               });
          });
          return options;
     } catch (error) {
          return options;
     }
};
