import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import {
     GetReportsNewOrUpdatedMembersRequest,
     ReportsNewOrUpdatedMembersResponse,
} from '../../../../../Models/NewOrUpdatedMembers';

export const useGetReportsNewOrUpdatedMembers = (
     payload: GetReportsNewOrUpdatedMembersRequest,
     options?: any
): UseQueryResult<ReportsNewOrUpdatedMembersResponse, any> => {

     // Create a new payload with the date fields converted to ISO strings
     const requestPayload = {
          ...payload,
          Filter: {
               ...payload.Filter,
               FromDate: payload.Filter.FromDate ? new Date(payload.Filter.FromDate).toISOString() : null,
               ToDate: payload.Filter.ToDate ? new Date(payload.Filter.ToDate).toISOString() : null,
          },
     };

     const getMembers = ({ queryKey }: any) => {
          return agent.reports.GetReportsNewOrUpdatedMembers(requestPayload);
     };

     return useQuery(['GetReportsNewOrUpdatedMembers', payload], getMembers, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
