import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddInvoiceDetailsModel } from '../../../../../Models/Billing';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddInvoice = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, AddInvoiceDetailsModel> => {
     const addInvoice = (payload: AddInvoiceDetailsModel) => {
          return agent.invoices.postAddInvoice(payload);
     };

     return useMutation(addInvoice);
};
