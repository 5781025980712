import axios from 'axios';
import { AddAdminUserRoleModel, AdminRoleModel, DeleteAdminUserRolesModel } from '../../../Models/System';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';
import { UpdateAdminRolePermissionModel } from '../../../Models/Admin/UpdateAdminRolePermission';

const {
    GetAdminRoles_URL,
    GetAdminRole_URL,
    PostAddAdminRole_URL,
    PutUpdateAdminRole_URL,
    DeleteAdminRole_URL,
    PostAddSelectedAdminRoleToAdminUser_URL,
    DeleteSelectedAdminRolesFromAdminUser_URL,
    PostAddAdminRolePermission_URL,
    PutUpdateAdminRolePermission_URL,
    DeleteAdminRolePermission_URL,
    GetAdminPermissions_URL,
} = ApiURLConstants;


export const adminRole = {
    getAdminRoles: (params: any): Promise<any> => {
        return axios.post(GetAdminRoles_URL, params)
            .then(responseBody)
    },

    getAdminRole: (adminRoleID: number): Promise<any> => {
        return axios.get(`${GetAdminRole_URL}?adminRoleID=${adminRoleID}`).then(responseBody);
    },

    postAddAdminRole: (params: AdminRoleModel): Promise<any> => {
        return axios
            .post(PostAddAdminRole_URL, params, jsonConfig)
            .then(responseBody);
    },

    putUpdateAdminRole: (params: AdminRoleModel): Promise<any> => {
        return axios
            .put(PutUpdateAdminRole_URL, params, jsonConfig)
            .then(responseBody);
    },

    putUpdateAdminRolePermission: (params: UpdateAdminRolePermissionModel): Promise<any> => {
        return axios
            .put(PutUpdateAdminRolePermission_URL, params, jsonConfig)
            .then(responseBody);
    },

    postAddAdminRolePermission: (params: UpdateAdminRolePermissionModel): Promise<any> => {
        return axios
            .post(PostAddAdminRolePermission_URL, params, jsonConfig)
            .then(responseBody);
    },

    deleteAdminRole: (adminRoleID: number): Promise<any> => {
        return axios.delete(`${DeleteAdminRole_URL}?adminRoleId=${adminRoleID}`)
            .then(responseBody)
    },

    postAddSelectedAdminRoleToAdminUser: (params: AddAdminUserRoleModel): Promise<any> => {
        return axios
            .post(PostAddSelectedAdminRoleToAdminUser_URL, params, jsonConfig)
            .then(responseBody);
    },

    deleteSelectedAdminRolesFromAdminUser: (params: DeleteAdminUserRolesModel): Promise<any> => {
        return axios.delete(DeleteSelectedAdminRolesFromAdminUser_URL, { data: params })
            .then(responseBody)
    },
    
    getAdminPermissions: (): Promise<any> => {
        return axios.get(GetAdminPermissions_URL).then(responseBody)
    },
}