import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { SavedSearchResponseModel } from '../../../../../Models/Searches';

export const useGetSavedSearch = (
     savedSearchID: number,
     options?: any
): UseQueryResult<SavedSearchResponseModel, any> => {
     const getSavedSearch = () => {
          if(savedSearchID === 0) return {}
          return agent.savedSearches.getSavedSearch(savedSearchID);
     };

     return useQuery(['GetSavedSearch', savedSearchID], getSavedSearch, {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          ...options,
     });
};
