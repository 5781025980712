import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { MovexErrorResponse } from '../../../../../../../Types';
import { UpdateTransporterPlateRequest } from '../../../../../../../Models/Members/AddMember/UpdateTradePlateRequest';

export const usePutUpdateTransporterPlates = (
     options?: any
): UseMutationResult<MovexErrorResponse, any, UpdateTransporterPlateRequest> => {
     const updateTradePlates = (payload: UpdateTransporterPlateRequest) => {
          return agent.members.putUpdateTransporterPlates(payload);
     };

     return useMutation(updateTradePlates);
};
