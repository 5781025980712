import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { CellRenderer } from '../../../../../Components/Formik';
import { MemberModelKeys } from '../../../../../Models/Members';
import { ActionColumnPath } from '../../../../../Utils';

const {
  renderMemberNotesCell,
  renderCellWithTooltip,
  renderDateCell,
  renderMemberActionCell,
  renderMemberDisabledCell,
  renderMemberKeyAccountCell,
  renderMemberPayCell,
  renderMemberStatusCell,
  renderMemberUserNameCell,
  renderMemberTypeCell,
} = CellRenderer;

export const columns: GridColDef[] = [
  {
    field: MemberModelKeys.MemberType,
    headerName: 'Type',
    description: 'Type',
    renderCell: (params: GridRenderCellParams) =>
      renderMemberTypeCell(params.value),
    flex: 2,
    sortable: false,
  },
  {
    field: MemberModelKeys.Username,
    headerName: 'UserName',
    flex: 6,
    renderCell: (params: GridRenderCellParams) =>
      renderMemberUserNameCell(params),
  },
  {
    field: MemberModelKeys.CodaCustomerReference,
    headerName: 'Cref',
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    flex: 4,
    sortable: false,
  },
  {
    field: MemberModelKeys.CodaSupplierReference,
    headerName: 'Sref',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    sortable: false,
  },
  {
    field: MemberModelKeys.PositiveFeedback,
    headerName: '%',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.NoOfBlocks,
    headerName: 'Blocks',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.NoOfDrivers,
    headerName: 'No.D',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.NoOfTransporters,
    headerName: 'No.T',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.JobsAddedAndDone,
    headerName: 'U.Done',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.JobsWorkedOnAndDone,
    headerName: 'S.Done',
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
    flex: 4,
  },
  {
    field: MemberModelKeys.JobsQuotedOn,
    headerName: 'Quoted',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.JobsAdded,
    headerName: 'Placed',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
  },
  {
    field: MemberModelKeys.Email,
    headerName: 'Email',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ''}>
        <span className="table-cell-trucate"> {params.value} </span>
      </Tooltip>
    ),
    flex: 4,
  },
  {
    field: MemberModelKeys.Company,
    headerName: 'Company',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value || ''}>
        <span className="table-cell-trucate"> {params.value} </span>
      </Tooltip>
    ),
    flex: 4,
  },
  {
    field: MemberModelKeys.Notes,
    headerName: 'Notes',
    type: 'actions',
    renderCell: (params: GridRenderCellParams) =>
      renderMemberNotesCell(params.value, parseInt(params.id.toString())),
    flex: 4,
  },
  {
    field: MemberModelKeys.SignupDate,
    headerName: 'Signup',
    renderCell: (params: GridRenderCellParams) => renderDateCell(params),
    flex: 4,
  },
  {
    field: MemberModelKeys.PaymentMethod,
    headerName: 'Pay',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => renderMemberPayCell(params),
  },
  {
    field: MemberModelKeys.LastLoginDate,
    headerName: 'Last Login',
    renderCell: (params: GridRenderCellParams) => renderDateCell(params),
    flex: 4,
  },
  {
    field: MemberModelKeys.Disabled,
    headerName: 'Account',
    renderCell: (params: GridRenderCellParams) =>
      renderMemberDisabledCell(params),
    flex: 4,
    sortable: false,
  },
  {
    field: MemberModelKeys.Active,
    headerName: 'Status',
    flex: 4,
    renderCell: (params: GridRenderCellParams) =>
      renderMemberStatusCell(params),
    sortable: false,
  },
  {
    field: MemberModelKeys.IsKeyAccount,
    headerName: 'Key',
    flex: 4,
    renderCell: (params: GridRenderCellParams) =>
      renderMemberKeyAccountCell(params),
    sortable: false,
  },
  {
    field: ActionColumnPath,
    headerName: 'Action',
    type: 'actions',
    flex: 6,
    renderCell: (params: GridRenderCellParams) =>
      renderMemberActionCell(params.id as number),
  },
];
