import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { RequestingMemberFeeModel } from '../../../../../Models/Prices/DealerMargin';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddOrUpdateFeeQuotesPrice = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, RequestingMemberFeeModel> => {
     const aAddOrUpdateFeeQuotesPrice = (payload: RequestingMemberFeeModel) => {
          return agent.dealerMargin.postDealerMarginQuotesPrices(payload);
     };

     return useMutation(aAddOrUpdateFeeQuotesPrice);
};
