import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../FormControls/Button';
import './index.css';

export const Alert: React.FC<AlertProps> = (props): JSX.Element => {
  const { header, body, show = false, closeCallback } = props;

  return (
    <Modal
      className="alert-modal"
      animation={false}
      style={{ opacity: 1 }}
      show={show}
      onHide={closeCallback}
    >
      {header && (
        <Modal.Header closeButton>
          <Modal.Title style={{ whiteSpace: 'pre-wrap' }}>{header}</Modal.Title>
        </Modal.Header>
      )}
      {body && (
        <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>{body}</Modal.Body>
      )}
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={closeCallback}
          label="Close"
        ></Button>
      </Modal.Footer>
    </Modal>
  );
};

export type AlertProps = {
  header?: any;
  body?: any;
  show: boolean;
  isConfirmDialog?: boolean;
  closeCallback?: () => void;
};
