import {
     AdminJobStatus,
     JobKind,
     JobMovementType,
     JobOfferedTo,
     JobType,
     PaymentMethod,
     ServiceType,
} from '../../../Utils/Enums';
import { JobsFilterRequestBody } from './JobsFilterRequestBody';

/**
 * @interface JobsFilterFormModel
 * @summary The model for the jobs filter form
 */
export interface JobsFilterFormModel {
     AdminUserID?: number;
     CollectionDateFrom?: Date;
     CollectionDateTo?: Date;
     DeliveryDateFrom?: Date;
     DeliveryDateTo?: Date;
     DistanceMilesMax?: number;
     DistanceMilesMin?: number;
     Freetext?: string;
     FreeTextSearchBy?: string | null;
     FromPostCode?: string;
     GroupID?: number;
     MemberID?: number;
     PartnerID?: number;
     RequestDateFrom?: Date;
     RequestDateTo?: Date;
     ToPostCode?: string;
     VehiclesMax?: number;
     VehiclesMin?: number;
     VRMorVIN?: string;
     Standard?: boolean;
     Return?: boolean;
     Onward?: boolean;
     MultipleDropOff?: boolean;
     InternalTransfer?: boolean;
     DealerTranser?: boolean;
     FleetDelivery?: boolean;
     Aborted?: boolean;
     AcceptedQuote?: boolean;
     ActiveJob?: boolean;
     AdminRemoved_OrFailed?: boolean;
     BookedJob?: boolean;
     Cancelled?: boolean;
     CompletedJob?: boolean;
     Draft?: boolean;
     Expired?: boolean;
     PendingQuotes?: boolean;
     Unknown?: boolean;
     Quotes?: boolean;
     MovementTypeFleetDelivery?: boolean;
     MovementTypeDealerTransfer?: boolean;
     MovementTypeInternalTransfer?: boolean;
     MovementTypePremiumFleet?: boolean;
     MovementTypePremiumRetail?: boolean;
     MovementTypeRetailDelivery?: boolean;
     MovementTypeUnknown?: boolean;
     Driven?: boolean;
     Transported?: boolean;
     Both?: boolean;
     JobTypeQuotes?: boolean;
     JobTypeFixedPrice?: boolean;
     JobTypeBoth?: boolean;
     JobTypeGuaranteedPrice?: boolean;
     OfferedToWholeNetwork?: boolean;
     FiveStarCompanies?: boolean;
     SelectedSuppliers?: boolean;
     Pending?: boolean;
     SmartDebit?: boolean;
     Invoice?: boolean;
     PrePay?: boolean;
}

/**
 * @interface JobsFilterFormValueModel
 * @summary Jobs Filter Form Model with the `toRequestBody` function to convert to Request Body
 */
export class JobsFilterFormValueModel implements JobsFilterFormModel {
     AdminUserID?: number;
     CollectionDateFrom?: Date;
     CollectionDateTo?: Date;
     DeliveryDateFrom?: Date;
     DeliveryDateTo?: Date;
     DistanceMilesMax?: number;
     DistanceMilesMin?: number;
     Freetext?: string;
     FreeTextSearchBy?: string;
     FromPostCode?: string;
     GroupID?: number;
     MemberID?: number;
     PartnerID?: number;
     RequestDateFrom?: Date;
     RequestDateTo?: Date;
     ToPostCode?: string;
     VehiclesMax?: number;
     VehiclesMin?: number;
     VRMorVIN?: string;
     Standard?: boolean;
     Return?: boolean;
     Onward?: boolean;
     MultipleDropOff?: boolean;
     InternalTransfer?: boolean;
     DealerTranser?: boolean;
     FleetDelivery?: boolean;
     // JobStatus
     Aborted?: boolean;
     AcceptedQuote?: boolean;
     ActiveJob?: boolean;
     AdminRemoved_OrFailed?: boolean;
     BookedJob?: boolean;
     Cancelled?: boolean;
     CompletedJob?: boolean;
     Draft?: boolean;
     Expired?: boolean;
     PendingQuotes?: boolean;
     Unknown?: boolean;
     Quotes?: boolean;
     // MovementType
     MovementTypeFleetDelivery?: boolean;
     MovementTypeDealerTransfer?: boolean;
     MovementTypeInternalTransfer?: boolean;
     MovementTypePremiumFleet?: boolean;
     MovementTypePremiumRetail?: boolean;
     MovementTypeRetailDelivery?: boolean;
     MovementTypeUnknown?: boolean;
     // ServiceType
     Driven?: boolean;
     Transported?: boolean;
     Both?: boolean;
     // JobType
     JobTypeQuotes?: boolean;
     JobTypeFixedPrice?: boolean;
     JobTypeBoth?: boolean;
     JobTypeGuaranteedPrice?: boolean;
     // OfferedTo
     OfferedToWholeNetwork?: boolean;
     FiveStarCompanies?: boolean;
     SelectedSuppliers?: boolean;
     // PaymentMethod
     Pending?: boolean;
     SmartDebit?: boolean;
     Invoice?: boolean;
     PrePay?: boolean;
     //convert to request body
     toRequestBody(): JobsFilterRequestBody {
          return {
               AdminUserID: this.AdminUserID,
               CollectionDateFrom: this.CollectionDateFrom,
               CollectionDateTo: this.CollectionDateTo,
               DeliveryDateFrom: this.DeliveryDateFrom,
               DeliveryDateTo: this.DeliveryDateTo,
               DistanceMilesMax: this.DistanceMilesMax,
               DistanceMilesMin: this.DistanceMilesMin,
               Freetext: this.Freetext,
               FreeTextSearchBy: this.FreeTextSearchBy,
               FromPostCode: this.FromPostCode,
               GroupID: this.GroupID,
               MemberID: this.MemberID,
               PartnerID: this.PartnerID,
               RequestDateFrom: this.RequestDateFrom,
               RequestDateTo: this.RequestDateTo,
               ToPostCode: this.ToPostCode,
               VehiclesMax: this.VehiclesMax,
               VehiclesMin: this.VehiclesMin,
               VRMorVIN: this.VRMorVIN,

               JobKind: [
                    ...(this.MultipleDropOff ? [JobKind.MultipleDropOff] : []),
                    ...(this.Onward ? [JobKind.Onward] : []),
                    ...(this.Return ? [JobKind.Return] : []),
                    ...(this.Standard ? [JobKind.Standard] : []),
               ],
               JobStatus: [
                    ...(this.Draft ? [AdminJobStatus.Draft] : []),
                    ...(this.Aborted ? [AdminJobStatus.Aborted] : []),
                    ...(this.AcceptedQuote ? [AdminJobStatus.AcceptedQuote] : []),
                    ...(this.ActiveJob ? [AdminJobStatus.ActiveJob] : []),
                    ...(this.AdminRemoved_OrFailed ? [AdminJobStatus.AdminRemoved_Or_Failed] : []),
                    ...(this.BookedJob ? [AdminJobStatus.BookedJob] : []),
                    ...(this.Cancelled ? [AdminJobStatus.Cancelled] : []),
                    ...(this.CompletedJob ? [AdminJobStatus.CompletedJob] : []),
                    ...(this.PendingQuotes ? [AdminJobStatus.PendingQuotes] : []),
                    ...(this.Expired ? [AdminJobStatus.Expired] : []),
                    // ...(this.Quotes ? [AdminJobStatus.Quotes] : []),
               ],
               MovementType: [
                    ...(this.MovementTypeFleetDelivery ? [JobMovementType.FleetDelivery] : []),
                    ...(this.MovementTypeDealerTransfer ? [JobMovementType.DealerTransfer] : []),
                    ...(this.MovementTypeInternalTransfer ? [JobMovementType.InternalTransfer] : []),
                    ...(this.MovementTypePremiumFleet ? [JobMovementType.PremiumFleet] : []),
                    ...(this.MovementTypePremiumRetail ? [JobMovementType.PremiumRetail] : []),
                    ...(this.MovementTypeRetailDelivery ? [JobMovementType.RetailDelivery] : []),
               ],
               ServiceType: [
                    ...(this.Driven ? [ServiceType.Driven] : []),
                    ...(this.Transported ? [ServiceType.Transported] : []),
                    ...(this.Both ? [ServiceType.Both] : []),
               ],
               JobType: [
                    ...(this.JobTypeQuotes ? [JobType.RequestQuote] : []),
                    ...(this.JobTypeFixedPrice ? [JobType.FixedPrice] : []),
                    ...(this.JobTypeBoth ? [JobType.Both] : []),
                    ...(this.JobTypeGuaranteedPrice ? [JobType.GuaranteedPrice] : []),
               ],
               OfferedTo: [
                    ...(this.OfferedToWholeNetwork ? [JobOfferedTo.WholeNetwork] : []),
                    ...(this.FiveStarCompanies ? [JobOfferedTo.FiveStarCompanies] : []),
                    ...(this.SelectedSuppliers ? [JobOfferedTo.SelectedSuppliers] : []),
               ],
               PaymentMethod: [
                    ...(this.Pending ? [PaymentMethod.Pending] : []),
                    ...(this.SmartDebit ? [PaymentMethod.SmartDebit] : []),
                    ...(this.Invoice ? [PaymentMethod.Invoice] : []),
                    ...(this.PrePay ? [PaymentMethod.PrePay] : []),
               ],
          };
     }
}
