import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { DeletePartnerMembersRequestModel, DeletePartnerMembersResponseModel } from '../../../../../Models/Partners';

export const useDeleteSelectedMembersFromPartner = (
  options?: any
): UseMutationResult<DeletePartnerMembersResponseModel, any, DeletePartnerMembersRequestModel> => {

  const deleteMembersFromPartner = (payload: DeletePartnerMembersRequestModel) => {
    return agent.partners.deleteSelectedMembersFromPartner(payload);
  };

  return useMutation(deleteMembersFromPartner);
};
