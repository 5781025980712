import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ConfirmDialog,
  ConfirmDialogProps,
  Loading,
  renderHeader,
  useAlert,
} from '../../../../Components';
import { GetPartnersRequest } from '../../../../Models/Partners';
import { Pager } from '../../../../Types';
import {
  getSorterFromSortModel,
  ConfirmDialogContent,
  Page,
} from '../../../../Utils';
import FilterBox from './FilterBox';
import ViewPartnerGridView from './Grid';
import { useDeletePartner, useGetPartners } from './Hooks';
import { PartnerGridKeys } from './Types';
import './index.css';
import { AdminGetPartnersFilter } from '../../../../Models/Partners/AdminGetPartnersFilter';

const ViewPartners: React.FC = (): JSX.Element => {
  const onConfirmDialogClose = () => {
    setConfirmDialogContent({ ...confirmDialogContent, show: false });
  };

  /** useStates */
  const [confirmDialogContent, setConfirmDialogContent] =
    useState<ConfirmDialogProps>({
      header: '',
      body: '',
      cancelCallback: onConfirmDialogClose,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<AdminGetPartnersFilter>({} as AdminGetPartnersFilter);
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: PartnerGridKeys.ID,
      sort: 'asc',
    },
  ]);

  /** Functions */
  const getParams = () => {
    const params: GetPartnersRequest = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: AdminGetPartnersFilter) => {
    if(filter?.FreeText){
      if(/^\d+$/.test(filter?.FreeText)){
        const adminGetPartnersFilter : AdminGetPartnersFilter = {
          PartnerId: parseInt(filter.FreeText),
          FreeText: ""
        }
        setFilter(adminGetPartnersFilter);
      }else{
        setFilter(filter);
      }
    }
  };

  const handleClearSearch = () => {
    setFilter({} as AdminGetPartnersFilter);
  };

  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };

  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };

  const onSort = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPager({ ...pager, CurrentPage: 1 });
  };

  const onDelete = (partnerID: number) => {
    setConfirmDialogContent({
      ...confirmDialogContent,
      header: ConfirmDialogContent.DeletePartner.Title,
      body: ConfirmDialogContent.DeletePartner.Content,
      show: true,
      confirmCallback: () => {
        deletePartner(partnerID);
        onConfirmDialogClose();
      },
    });
  };

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** React Query Hooks */
  const {
    data: getPartnersResponse,
    isLoading: isGetPartnersLoading,
    refetch: refetchGetPartners,
    error: getPartnersError,
  } = useGetPartners(getParams());

  const isDataLoaded = getPartnersResponse !== null;

  const { 
    mutate: deletePartner, 
    error: deletePartnerError,
    isSuccess: isDeletePartnerSuccess
   } = useDeletePartner();

  /** useEffects */
  useEffect(() => {
    refetchGetPartners();
  }, [pager, filter, sortModel]);

  useEffect(() => {
    setIsLoading(isGetPartnersLoading);
  }, [isGetPartnersLoading]);

  useEffect(() => {
    if (getPartnersError) {
      setAlert({
        ...alert,
        show: true,
        header: getPartnersError?.Subject,
        body: getPartnersError?.Description,
      });
    } else if (deletePartnerError) {
      setAlert({
        ...alert,
        show: true,
        header: deletePartnerError?.Subject,
        body: deletePartnerError?.Description,
      });
    }
  }, [getPartnersError, deletePartnerError]);

  useEffect(() => {
    if (isDeletePartnerSuccess) {
      setAlert({
        ...alert,
        show: true,
        header: 'Success',
        body: 'Partner deleted successfully',
      });
      refetchGetPartners();
    }
  }, [isDeletePartnerSuccess]);

  return (
    <div>
      <Alert {...alert} />
      <ConfirmDialog {...confirmDialogContent} />
      {renderHeader('Partners')}
      <div className="container-fluid">
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
        <Loading loading={isLoading} />
        {isDataLoaded && (
          <ViewPartnerGridView
            data={getPartnersResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onDelete={onDelete}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPartners;
