export type JobIDParamType = {
    jobID: string;
}

export const PlacedQuotes = {
    DealerPays: 'DealerPays',
    MarkRead: 'MarkRead',
}

export const PlaceQuoteOrAcceptFixedPrices = {
    QuoteDrivenSupplier: 'QuoteDrivenSupplier',
    QuoteDrivenAmount: 'QuoteDrivenAmount',
    DrivenCollectionDate: 'DrivenCollectionDate',
    DrivenDeliveryDate: 'DrivenDeliveryDate',
    DrivenExpirationValue: 'DrivenExpirationValue',
    DrivenExpirationPeriod: 'DrivenExpirationPeriod',
    DrivenTermsAndConditionsAccepted: 'DrivenTermsAndConditionsAccepted',
    DrivenDealerNotesAccepted: "DrivenDealerNotesAccepted",
    DrivenAdditionalNotes: 'DrivenAdditionalNotes',
    AcceptDrivenFixedPrices: 'AcceptDrivenFixedPrices',
    QuoteTransportedSupplier: 'QuoteTransportedSupplier',
    QuoteTransportedAmount: 'QuoteTransportedAmount',
    TransportedCollectionDate: 'TransportedCollectionDate',
    TransportedDeliveryDate: 'TransportedDeliveryDate',
    TransportedExpirationValue: 'TransportedExpirationValue',
    TransportedExpirationPeriod: 'TransportedExpirationPeriod',
    TransportedTermsAndConditionsAccepted: 'TransportedTermsAndConditionsAccepted',
    TransportedFuelIncluded: 'TransportedFuelIncluded',
    AcceptTransportedFixedPrices: 'AcceptTransportedFixedPrices'
}
export const EditJobKeys = {
    AdditionalNotes: 'AdditionalNotes',
    AdminNotes: 'AdminNotes',
    AdminUserID: 'AdminUserID',
    BillID: 'BillID',
    CollectionAddress: 'CollectionAddress',
    CollectionCompany: 'CollectionCompany',
    CollectionContact: 'CollectionContact',
    CollectionDate: 'CollectionDate',
    CollectionLatitude: 'CollectionLatitude',
    CollectionLongitude: 'CollectionLongitude',
    CollectionPhone: 'CollectionPhone',
    CollectionPostCode: 'CollectionPostCode',
    CollectionTime: '',
    CollectionTown: '',
    DEstimatedFuelCost: '',
    DFixedPriceMax: 'DFixedPriceMax',
    DFixedPriceMax_Original: 'DFixedPriceMax_Original',
    DFixedPriceMin: 'DFixedPriceMin',
    DFixedPriceMin_Original: 'DFixedPriceMin_Original',
    DFuelCost: '',
    DGuaranteedPrice: 'DGuaranteedPrice',
    DVAT: '',
    DeliveryAddress: '',
    DeliveryCompany: '',
    DeliveryContact: '',
    DeliveryDate: '',
    DeliveryLatitude: '',
    DeliveryLongitude: '',
    DeliveryPhone: '',
    DeliveryPostCode: '',
    DeliveryTime: '',
    DeliveryTown: '',
    DistanceMiles: '',
    Driven_FuelRequired: '',
    Driven_ValidMOT: '',
    DurationSeconds: '',
    Expired: '',
    FeesDeferred: '',
    ID: 'ID',
    IgnoreFavourites: '',
    JobKind: 'JobKind',
    JobMovementType: '',
    JobOfferedTo: '',
    JobStatus: 'JobStatus',
    JobType: '',
    LockedDate: '',
    LockedReason: '',
    NoOfReturnVehicles: '',
    NoOfVehicles: '',
    PartnerCID: '',
    PartnerID: '',
    PartnerJID: '',
    PaymentMethod: '',
    PromoCode: '',
    Reference: '',
    RequestDate: '',
    RequestingMemberContact: '',
    RequestingMemberHasSeen: '',
    RequestingMemberHasTerms: '',
    RequestingMemberID: '',
    RequestingMemberLandline: '',
    RequestingMemberMobile: '',
    RequestingMemberTerms: '',
    RequestingMemberUserName: '',
    RoutePolyline: '',
     ServiceType: '',
    SupplyingMemberHasSeen: '',
    SupplyingMemberID: '',
    SupplyingMemberUserName: '',
    TFixedPriceMax: 'TFixedPriceMax',
    TFixedPriceMax_Original: 'TFixedPriceMax_Original',
    TFixedPriceMin: 'TFixedPriceMin',
    TFixedPriceMin_Original: 'TFixedPriceMin_Original',
    TGuaranteedPrice: 'TGuaranteedPrice',
    TVAT: '',
    Timestamp: '',
    Transported_DriveOnto: '',
    Transported_Enclosed: '',
    UseExactCollectionDateAndTime: '',
    UseExactDeliveryDateAndTime: '',
    ProofOfCollectionFile: 'ProofOfCollectionFile',
    ProofOfDeliveryFile: 'ProofOfDeliveryFile',
    NotesForAdmin: "NotesForAdmin"
}

export const StatusInformationJobKeys = {
    AdminUserID: 'AdminUserID'
}

export const JobDetailsJobKeys = {
    AdditionalNotes: 'AdditionalNotes',
    Reference: "Reference",
    DeliveryContact: "DeliveryContact",
    DeliveryPhone: "DeliveryPhone",
    ReleaseNote: "ReleaseNote"
}

export const PreviousSupplierOfRoute = {
    DistanceFromCollection: "DistanceFromCollection",
    DistanceFromDelivery: "DistanceFromDelivery"
}

export const SupplyingmembersNearby = {
    WithinMiles : "WithinMiles"
}

export const ProofFormKey = {
    ProofOfCollectionFile: "ProofOfCollectionFile"
}

export const PlaceQuoteFormKey = {
    DrivenCollectionDate: "DrivenCollectionDate",
    DrivenDeliveryDate: "DrivenDeliveryDate",
    DrivenExpirationValue: "DrivenExpirationValue",
    DrivenExpirationPeriod: "DrivenExpirationPeriod",
    DrivenFuelIncluded: "DrivenFuelIncluded",
    DrivenTermsAndConditionsAccepted: "DrivenTermsAndConditionsAccepted",
    QuoteTransportedSupplier: "QuoteTransportedSupplier",
    TransportedCollectionDate: "TransportedCollectionDate",
    TransportedDeliveryDate: "TransportedDeliveryDate",
    TransportedExpirationValue: "TransportedExpirationValue",
    TransportedExpirationPeriod: "TransportedExpirationPeriod",
    TransportedAdditionalNotes: "TransportedAdditionalNotes",
    TransportedDealerNotesAccepted: "TransportedDealerNotesAccepted",
    TransportedTermsAndConditionsAccepted: "TransportedTermsAndConditionsAccepted",
    TransportedShowTermsAndConditions: "TransportedShowTermsAndConditions",
    AcceptTransportedFixedPrices: "AcceptTransportedFixedPrices",
    QuoteDrivenSupplier: "QuoteDrivenSupplier"

}