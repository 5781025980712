import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
  FormContainer,
  RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { FreeTextFilter, FilterKeys } from '../../../../../Types';
import { AdminSavedSearchCombinedStatusOptions, SavedSearchFreeTextSearchBy } from '../../../Members/Constants';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';
import { SavedSearchesFilter } from '../../../../../Models/Searches';
import { AdminSavedSearchCombinedStatus } from '../../../../../Utils/Enums';

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch } = props;
  const initialValues: SavedSearchesFilter = {
    FreeText: '', 
    FreeTextSearchBy: FreeTextSearchByOptions.Username,
    SavedSearchStatus: AdminSavedSearchCombinedStatus.All,
  };

  const onSubmit = (values: FreeTextFilter) => {
    onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const { handleSubmit, resetForm } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={FilterKeys.Freetext}
                    label="Freetext"
                    title="Searches by ID, Member UserName or Email"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.FreeTextSearchBy}
                    label="Freetext Type"
                    control={FormControlKeys.Select}
                    options={SavedSearchFreeTextSearchBy}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.SavedSearchStatus}
                    control={FormControlKeys.Select}
                    label="Status"
                    placeholder="All Saved Searches"
                    options={AdminSavedSearchCombinedStatusOptions}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;
export interface FilterBoxProps {
  onSearch: Function;
  onClearSearch: Function;
}
