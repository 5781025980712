import axios from 'axios';
import { MarkPaymentsAsPaidModel, UpdateBillPaymentInformationModel } from '../../../Models/Billing/AddBilling';
import { BillsRequestParam } from '../../../Models/Billing/Request';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {GetBill_URL, GetBills_URL, GetInvoiceDatesForDropdown_URL, PutUpdateBill_URL, PutMarkPaymentsAndMembershipAsPaid_URL } =
  ApiURLConstants;

export const bills = {
  getBills: (params: BillsRequestParam): Promise<any> => {
    return axios.post(`${GetBills_URL}`, params, jsonConfig).then(responseBody);
  },
  getBill:(BillID: any): Promise<any> => {
    return axios.get(`${GetBill_URL}?billId=${BillID}`).then(responseBody);
},
  getInvoiceDatesForDropdown: (): Promise<any> => {
    return axios.get(`${GetInvoiceDatesForDropdown_URL}`).then(responseBody);
  },
  putUpdateBill: (params: UpdateBillPaymentInformationModel): Promise<any> => {
    return axios.put(`${PutUpdateBill_URL}`, params, jsonConfig).then(responseBody);
  },
  putMarkPaymentsAndMembershipAsPaidModel: (params: MarkPaymentsAsPaidModel): Promise<any> => {
    return axios.put(`${PutMarkPaymentsAndMembershipAsPaid_URL}`, params, jsonConfig).then(responseBody);
  },
};