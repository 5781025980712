import { ServiceType } from "../../../Utils/Enums"

export type PaymentItemModel = {
    ActualServiceType: ServiceType,
    AdminCheckedDate: Date,
    AdminNotes: string,
    AdminUserID: number,
    AdminUserName: string,
    ApprovedDate: Date,
    CreatedDate: Date,
    DistanceMiles: number,
    DueDate: Date,
    DueDays: number,
    Expired: number,
    FuelPaid: number,
    ID: number,
    JobKind: number,
    JobOfferedTo: number,
    JobStatus: number,
    MileageDiscrepancies: number,
    MovexPaidDate: Date,
    MovexRefundDate: Date,
    NoOfAbortedVehicles: number,
    NoOfHGVs: number,
    NoOfVehicles: number,
    POC_CreatedDate: Date,
    POC_ID: number,
    POC_IsGenerated: boolean,
    POC_Name: string,
    POC_WaitingTime: Date,
    POD_CreatedDate: Date,
    POD_ID: number,
    POD_IsGenerated: boolean,
    POD_Name: string,
    POD_WaitingTime: Date,
    PaymentID: number,
    PaymentMethod: number,
    PaymentStatus: ''
    QuoteAmount: number,
    QuoteAmountOriginal: number,
    QuoteAmountVat: number,
    QuoteAmountVatOriginal: number,
    RequestDate: Date,
    RequestingMemberAmount: number,
    RequestingMemberDiscount: number,
    RequestingMemberFee: number,
    RequestingMemberID: number,
    RequestingMemberInvoiceFileName: string,
    RequestingMemberInvoiceSentDate: Date,
    RequestingMemberPayAsYouGoFee: number,
    RequestingMemberTotal: number,
    RequestingMemberUserName: string,
    RequestingMemberVat: number,
    ServiceType: number,
    SupplierPaid: string,
    SupplierPaidDate: Date,
    SupplyingMemberAmount: number,
    SupplyingMemberAmountOriginal: number,
    SupplyingMemberFee: number,
    SupplyingMemberFeeOriginal: number,
    SupplyingMemberFeeVat: number,
    SupplyingMemberFeeVatOriginal: number,
    SupplyingMemberFees: number,
    SupplyingMemberFeesOriginal: number,
    SupplyingMemberFeesVat: number,
    SupplyingMemberFeesVatOriginal: number,
    SupplyingMemberID: number,
    SupplyingMemberInvoiceFileName: string,
    SupplyingMemberInvoiceSentDate: string,
    SupplyingMemberMonthlyFee: string,
    SupplyingMemberMonthlyFeeVat: string,
    SupplyingMemberTotal: number,
    SupplyingMemberTotalOriginal: number,
    SupplyingMemberUserName: string,
    SupplyingMemberVat: number,
    SupplyingMemberVatAdditional: boolean,
    SupplyingMemberVatOriginal: number,
    TotalRecords: number,
    VatRate: number,
}

export const PaymentItemModelKeys = {
    ActualServiceType: 'ServiceType',
    AdminCheckedDate: 'AdminCheckedDate',
    AdminNotes: 'AdminNotes',
    AdminUserID: 'AdminUserID',
    AdminUserName: 'AdminUserName',
    ApprovedDate: "ApprovedDate",
    CreatedDate: "CreatedDate",
    DistanceMiles: 'DistanceMiles',
    DueDate: "DueDate",
    DueDays: 'DueDays',
    Expired: 'Exxpired',
    FuelPaid: 'FuelPaid',
    ID: 'Id',
    JobKind: 'JobKind',
    JobOfferedTo: 'JobOfferedTo',
    JobStatus: 'JobStatus',
    MileageDiscrepancies: 'MileageDiscrepancies',
    MovexPaidDate: 'MovexPaidDate',
    MovexRefundDate: 'MovexRefundDate',
    NoOfAbortedVehicles: 'NoOfAbortedVehicles',
    NoOfHGVs: 'NoOfHGVs',
    NoOfVehicles: 'NoOfVehicles',
    POC_CreatedDate: 'POC_CreatedDate',
    POC_ID: 'POC_ID',
    POC_IsGenerated: 'POC_IsGenerated',
    POC_Name: 'POC_Name',
    POC_WaitingTime: 'POC_WaitingTime',
    POD_CreatedDate: 'POD_CreatedDate',
    POD_ID: 'POD_ID',
    POD_IsGenerated: 'POD_IsGenerated',
    POD_Name: 'POD_Name',
    POD_WaitingTime: 'POD_WaitingTime',
    PaymentID: 'PaymentID',
    PaymentMethod: 'PaymentMethod',
    PaymentStatus: 'PaymentStatus',
    QuoteAmount: 'QuoteAmount',
    QuoteAmountOriginal: 'QuoteAmountOriginal',
    QuoteAmountVat: 'QuoteAmountVat',
    QuoteAmountVatOriginal: 'QuoteAmountVatOriginal',
    RequestDate: "RequestDate",
    RequestingMemberAmount: 'RequestingMemberAmount',
    RequestingMemberDiscount: 'RequestingMemberDiscount',
    RequestingMemberFee: 'RequestingMemberFee',
    RequestingMemberID: 'RequestingMemberID',
    RequestingMemberInvoiceFileName: 'RequestingMemberInvoiceFileName',
    RequestingMemberInvoiceSentDate: 'RequestingMemberInvoiceSentDate',
    RequestingMemberPayAsYouGoFee: 'RequestingMemberPayAsYouGoFee',
    RequestingMemberTotal: 'RequestingMemberTotal',
    RequestingMemberUserName: "RequestingMemberUserName",
    RequestingMemberVat: 'RequestingMemberVat',
    ServiceType: 'ServiceType',
    SupplierPaid: 'SupplierPaid',
    SupplierPaidDate: 'SupplierPaidDate',
    SupplyingMemberAmount: 'SupplyingMemberAmount',
    SupplyingMemberAmountOriginal: 'SupplyingMemberAmountOriginal',
    SupplyingMemberFee: 'SupplyingMemberFee',
    SupplyingMemberFeeOriginal: 'SupplyingMemberFeeOriginal',
    SupplyingMemberFeeVat: 'SupplyingMemberFeeVat',
    SupplyingMemberFeeVatOriginal: 'SupplyingMemberFeeVatOriginal',
    SupplyingMemberFees: 'SupplyingMemberFees',
    SupplyingMemberFeesOriginal: 'SupplyingMemberFeesOriginal',
    SupplyingMemberFeesVat: 'SupplyingMemberFeesVat',
    SupplyingMemberFeesVatOriginal: 'SupplyingMemberFeesVatOriginal',
    SupplyingMemberID: 'SupplyingMemberID',
    SupplyingMemberInvoiceFileName: 'SupplyingMemberInvoiceFileName',
    SupplyingMemberInvoiceSentDate: 'SupplyingMemberInvoiceSentDate',
    SupplyingMemberMonthlyFee: 'SupplyingMemberMonthlyFee',
    SupplyingMemberMonthlyFeeVat: 'SupplyingMemberMonthlyFeeVat',
    SupplyingMemberTotal: 'SupplyingMemberTotal',
    SupplyingMemberTotalOriginal: 'SupplyingMemberTotalOriginal',
    SupplyingMemberUserName: "SupplyingMemberUserName",
    SupplyingMemberVat: 'SupplyingMemberVat',
    SupplyingMemberVatAdditional: 'SupplyingMemberVatAdditional',
    SupplyingMemberVatOriginal: 'SupplyingMemberVatOriginal',
    TotalRecords: 'TotalRecords',
    VatRate: 'QuoteAmountVat',
}