import axios from 'axios';
import { AddInvoiceDetailsModel, EmailInvoiceModel, GetInvoicesRequest } from '../../../Models/Billing';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetInvoice_URL,
    GetInvoices_URL,
    PostAddInvoice_URL,
    PutUpdateInvoice_URL,
    PutEmailInvoice_URL,
    DeleteInvoice_URL,
    PutRegenerateInvoice_URL,
    GetInvoiceFile_URL,
    GetCodaFile_URL
} = ApiURLConstants;

export const invoices =
{
    getInvoice: (invoiceID: number): Promise<any> => {
        return axios.get(`${GetInvoice_URL}?invoiceId=${invoiceID}`)
            .then(responseBody)
    },

    getInvoices: (params: GetInvoicesRequest): Promise<any> => {
        return axios.post(GetInvoices_URL, params, jsonConfig)
            .then(responseBody)
    },

    postAddInvoice: (params: AddInvoiceDetailsModel): Promise<any> => {
        return axios
            .post(PostAddInvoice_URL, params, jsonConfig)
            .then(responseBody);
    },

    putUpdateInvoice: (params: AddInvoiceDetailsModel): Promise<any> => {
        return axios
            .post(PutUpdateInvoice_URL, params, jsonConfig)
            .then(responseBody);
    },

    putEmailInvoice: (params: EmailInvoiceModel): Promise<any> => {
        return axios
            .put(PutEmailInvoice_URL, params, jsonConfig)
            .then(responseBody);
    },

    putRegenerateInvoice: (invoiceID: number): Promise<any> => {
        return axios
            .put(PutRegenerateInvoice_URL, invoiceID, jsonConfig)
            .then(responseBody);
    },

    deleteInvoice: (id: number): Promise<any> => {
        return axios.delete(`${DeleteInvoice_URL}?id=${id}`).then(responseBody);
    },

    getInvoiceFile: async (invoiceID: number): Promise<Blob> => {
        return axios.get(`${GetInvoiceFile_URL}?invoiceID=${invoiceID}`, {
            responseType: 'blob'
        })
        .then(response => response.data);
    },

    getCodaFile : async (invoiceID: number): Promise<Blob> => {
        return axios.get(`${GetCodaFile_URL}?invoiceID=${invoiceID}`, {
            responseType: 'blob'
        })
        .then(response => response.data);
    }
    
}
