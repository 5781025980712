import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { FormContainer } from '../../../../../Components/Formik/StyledComponents';

export const Register = (model: any) => {
  const {
    Username,
    EmailAddress,
    NewPassword,
    ConfirmPassword,
    ChangePassword,
  } = model;

  const formikInitialValues = {};

  const type = 0;
  const onSubmit = (values: any) => {
    ChangePassword(values);
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: onSubmit,
  });
  return (
    <div className="register-page container-fluid">
      <div className="row">
        <div className="container">
          <div className="row hero-row">
            <div className="col-lg-12">
              <h1>JOIN US</h1>
              <h2>
                HELPLINE{' '}
                <span className="helpline-phone-number">0208 551 1060</span>
              </h2>
            </div>
          </div>
          <div className="row register-form-row">
            <div className="col-xs-12 col-lg-12">
              <FormikProvider value={formik}>
                <FormContainer>
                  <FormikControl placeholder="Type" name={'Type'} />
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl placeholder="Type" name={'Type'} />
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl placeholder="Username" name={'Username'} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="EmailAddress"
                        name={'EmailAddress'}
                      />
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="ConfirmEmailAddress"
                        name={'ConfirmEmailAddress'}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl placeholder="Password" name={'Password'} />
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="ConfirmPassword"
                        name={'ConfirmPassword'}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="PhoneNumber"
                        name={'PhoneNumber'}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="HearAboutMovex"
                        name={'HearAboutMovex'}
                      />
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="HearAboutIsLocked"
                        name={'HearAboutIsLocked'}
                      />
                    </div>
                  </div>
                  @if (isTransporter)
                  {
                    <div className="row">
                      <div className="col-xs-12 col-lg-6">
                        <FormikControl
                          placeholder="NoOfTradePlates"
                          name={'NoOfTradePlates'}
                        />
                      </div>
                      <div className="col-xs-12 col-lg-6">
                        <FormikControl
                          placeholder="NoOfTransporters"
                          name={'NoOfTransporters'}
                        />
                      </div>
                    </div>
                  }
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <FormikControl
                        placeholder="LanguageId"
                        name={'LanguageId'}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-12">
                      <div>
                        <FormikControl
                          placeholder="AcceptTerms"
                          name={'AcceptTerms'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-last">
                    <div className="col-xs-12 col-lg-12">
                      <button
                        type="submit"
                        className="btn movex-btn-primary"
                        data-bind="click: $root.SubmitUserRegistration"
                      >
                        Register<div className="movex-btn-login-arrow">❯</div>
                      </button>

                      {type && (
                        <div>
                          <br />
                          Please note: This is a motor trade only website, if
                          you are a member of the public please do not register.
                        </div>
                      )}
                    </div>
                  </div>
                </FormContainer>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
