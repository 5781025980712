import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { MemberIDParamType } from '../../..';
import agent from '../../../../../../../Axios/Agent';
import _ from 'lodash';
import {
  DealerFlowAddJobConfigModel,
  DealerUIAddJobConfigModel,
} from '../../../../../../../Models/Members/EditMember/ConfigObjects';

const onGetConfigObjectError = (error: any) => {
  console.log('Peform side effect after encountering error', error);
};

const getConfigObject = ({ queryKey }: any) => {
  const [_, memberID, configType] = queryKey;
  return agent.members.getConfigObject(memberID, configType);
};

export const useGetConfigObject = (
  configType: number,
  options?: any
): UseQueryResult<
  DealerUIAddJobConfigModel & DealerFlowAddJobConfigModel,
  any
> => {
  const { memberID } = useParams<MemberIDParamType>();

  return useQuery(['GetConfigObject', memberID, configType], getConfigObject, {
    onError: onGetConfigObjectError,
    ...options,
    refetchOnWindowFocus: false,
  });
};
