import { ActivityHistory, JobProofOfCollection, JobsRequestHistoryModel, JobsVehicle, JobViewModel, QuoteModel } from "../../../../../Models/Jobs/EditJob";
import { SelectedSupplierModel } from "../../../../../Models/Jobs/EditJob/GeneralJobRequest/SelectedSuppliersModel";

export const emptyValues : JobViewModel = {
  RoutePolyline: "",
  AdditionalNotes: "",
  AdminNotes: null,
  AdminUserID: 0,
  BillID: null,
  CollectionAddress: "",
  CollectionCompany: "",
  CollectionContact: "",
  CollectionDate: "",
  CollectionLatitude: 0,
  CollectionLongitude: 0,
  CollectionPhone: "",
  CollectionPostCode: "",
  CollectionTime: 0,
  CollectionTown: "",
  DEstimatedFuelCost: null,
  DFixedPriceMax: 0,
  DFixedPriceMax_Original: 0,
  DFixedPriceMin: 0,
  DFixedPriceMin_Original: 0,
  DFuelCost: null,
  DGuaranteedPrice: null,
  DVAT: true,
  DeliveryAddress: "",
  DeliveryCompany: "",
  DeliveryContact: "",
  DeliveryDate: "",
  DeliveryLatitude: 0,
  DeliveryLongitude: 0,
  DeliveryPhone: "",
  DeliveryPostCode: "",
  DeliveryTime: 0,
  DeliveryTown: "",
  DistanceMiles: 0,
  Driven_FuelRequired: false,
  Driven_ValidMOT: false,
  DurationSeconds: 0,
  Expired: false,
  FeesDeferred: null,
  Id: 0,
  IgnoreFavourites: false,
  JobKind: 0,
  JobMovementType: 0,
  JobOfferedTo: 0,
  JobStatus: 0,
  JobType: 0,
  LockedDate: null,
  LockedReason: null,
  NoOfReturnVehicles: null,
  NoOfVehicles: 0,
  PartnerCID: "",
  PartnerID: 0,
  PartnerJID: "",
  PaymentMethod: 0,
  PromoCode: "",
  Reference: "",
  RequestDate: "",
  RequestingMemberContact: "",
  RequestingMemberHasSeen: false,
  RequestingMemberHasTerms: false,
  RequestingMemberID: 0,
  RequestingMemberLandline: "",
  RequestingMemberMobile: "",
  RequestingMemberTerms: "",
  RequestingMemberUserName: "",
  ServiceType: 0,
  SupplyingMemberHasSeen: false,
  SupplyingMemberID: 0,
  SupplyingMemberUserName: "",
  TFixedPriceMax: 0,
  TFixedPriceMax_Original: 0,
  TFixedPriceMin: 0,
  TFixedPriceMin_Original: 0,
  TGuaranteedPrice: null,
  TVAT: true,
  Timestamp: "",
  Transported_DriveOnto: true,
  Transported_Enclosed: false,
  UseExactCollectionDateAndTime: false,
  UseExactDeliveryDateAndTime: false,
  JobHasReleaseNote: false,
  IsKeyAccountJob: false,
  };