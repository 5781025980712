import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Alert, useAlert } from '../../../../../../Components';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import { FieldSetHeader } from '../../../../../../Components/Formik';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { DivUploadProof } from '../../../../../../Components/UI';
import { FileModel } from '../../../../../../Models/Common';
import { UpdateProofStatusModel } from '../../../../../../Models/Jobs';
import { JobProofOfCollection, JobViewModel, uploadNewProof } from '../../../../../../Models/Jobs/EditJob';
import { AdminMembersDocumentViewModel } from '../../../../../../Models/Members';
import { getFormattedDateWithYear } from '../../../../../../Utils';
import { uploadFileToBlob } from '../../../../../../Utils/BlobStorageServiceUpload';
import { DocumentType, JobStatus, ProofStatus, ProofType, QuoteStatus } from '../../../../../../Utils/Enums';
import { useUpdateProofStatus } from '../../../ViewPayments/Hooks';
import { useGetStorageServiceSasToken } from '../../Hooks';
import { EditJobKeys } from '../../Types';
import { NoteForUploadButton } from './NotesForUploadButton';
import { inspectionReportDownloadArchivedPOC, inspectionReportDownloadPOC } from './downoladPOCPODHelper';

const SectionUploadProof: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { job, uploadNewProof, pocList, proofType, showLoading, refreshProofList } = props;


     const [selectedFile, setSelectedFile] = useState<FileToUpload>();
     const [showFileUploader, setShowFileUploader] = useState<boolean>(false);
     const [jobCompleted, setJobCompleted] = useState<boolean>(false);
     const [jobBooked, setJobBooked] = useState<boolean>(false);

     /* Custom Hooks */
     const { alert, setAlert } = useAlert();

     /* Query Hooks */
     const {
          mutate: updateProofStatus,
          isLoading: updateProofStatusLoading,
          data: updateProofStatusData,
          error: updateProofStatusError,
     } = useUpdateProofStatus();
     const {
          data: sasToken,
          error: sasTokenError,
          refetch: getSasToken,
          isSuccess: sasTokenSuccess,
     } = useGetStorageServiceSasToken();

     /* useEffect */
     useEffect(() => {
          if (job.JobStatus === JobStatus.CompletedJob) setJobCompleted(true);
          if (job.JobStatus === JobStatus.BookedJob) setJobBooked(true);
     }, [job]);

     useEffect(() => {
          if (updateProofStatusData) {
               showLoading(false);
               refreshProofList();
          }
     }, [updateProofStatusData]);

     useEffect(() => {
          if (updateProofStatusData) {
               showLoading(false);
               refreshProofList();
          }
     }, [updateProofStatusError]);

     useEffect(() => {
          if (showFileUploader) getSasToken();
     }, [showFileUploader]);

     useEffect(() => {
          if (updateProofStatusError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: updateProofStatusError?.Subject,
                    body: updateProofStatusError?.Description,
               });
          }
     }, [updateProofStatusError]);

     useEffect(() => {
          if (sasTokenError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: sasTokenError?.Subject,
                    body: sasTokenError?.Description,
               });
          }
     }, [sasTokenError]);

     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {},
          enableReinitialize: true,
     });

     /* Functions */
     const clearFile = () => {
          setSelectedFile({
               documentInformation: {} as AdminMembersDocumentViewModel,
               file: null,
          });
     };

     const uploadProof = async (): Promise<void> => {
          if (selectedFile?.file && sasToken) {
               showLoading(true);
               await uploadFileToBlob(selectedFile?.file, sasToken);
               clearFile();
               let payload = {
                    JobId: job.Id,
                    File: {
                         ID: selectedFile?.documentInformation?.Id,
                         Name: selectedFile?.documentInformation?.Name,
                         TemporaryFile: selectedFile?.documentInformation?.Name,
                         IsUploaded: selectedFile?.documentInformation?.UploadedDate ? true : false,
                         JobId: job.Id,
                    } as FileModel,
                    Type: proofType,
               } as uploadNewProof;
               uploadNewProof(payload);
          }
     };

     const onFileChange = (event: Event, type: number) => {
          const target = event.target as HTMLInputElement;
          const files = target.files as FileList;
          const newDocument: AdminMembersDocumentViewModel = {
               Name: files[0]?.name,
               Size: files[0]?.size,
               Type: type.toString(),
               IsUploaded: true,
          };
          const formData = new FormData();
          formData.append('files', files[0]);
          setSelectedFile({
               ...selectedFile,
               documentInformation: newDocument,
               file: files[0],
          });
     };

     const FileUploader = (fileInputName: string): JSX.Element => {
          if (selectedFile?.documentInformation?.Size) {
               return (
                    <div className='file-uploader'>
                         <div className='files'>
                              {'  '}
                              <FormikControl
                                   control={FormControlKeys.Button}
                                   label='Remove'
                                   variant='danger'
                                   onClick={() => {
                                        formik.setFieldValue(fileInputName, '');
                                        clearFile();
                                   }}
                              ></FormikControl>
                              {'  '}
                              <FormikControl
                                   control={FormControlKeys.Button}
                                   label='Upload'
                                   onClick={() => {
                                        uploadProof();
                                   }}
                              ></FormikControl>
                              <br />
                         </div>
                    </div>
               );
          } else {
               return <></>;
          }
     };

     const handleArchive = (proofId: number) => {
          showLoading(true);
          const payload: UpdateProofStatusModel = {
               Id: proofId,
               Status: ProofStatus.Live,
               ProofType: proofType,
               JobStatus: JobStatus.ActiveJob,
               QuoteStatus: QuoteStatus.Active,
          };
          updateProofStatus(payload);
     };

     const handleActivate = (proofId: number) => {
          showLoading(true);
          const payload: UpdateProofStatusModel = {
               Id: proofId,
               Status: ProofStatus.Archive,
               ProofType: proofType,
          };
          updateProofStatus(payload);
     };

     const handleShowUploader = () => {
          setShowFileUploader(true);
     };

     return (
          <>
               <FormikProvider value={formik}>
                    <Alert {...alert} />
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>
                                   {proofType === ProofType.POD ? 'Proof of Delivery' : 'Proof of Collection'}
                              </FieldSetHeader>
                              <Col>
                                   <Row>
                                        {!jobBooked && !jobCompleted && !showFileUploader && (
                                             <DivUploadProof>
                                                  <FormikControl
                                                       control={FormControlKeys.Button}
                                                       label={
                                                            proofType === ProofType.POD
                                                                 ? 'Select a POD to Upload'
                                                                 : 'Select a POC to Upload'
                                                       }
                                                       onClick={() => {
                                                            handleShowUploader();
                                                       }}
                                                  ></FormikControl>
                                                  <NoteForUploadButton proofType={proofType} />
                                             </DivUploadProof>
                                        )}

                                        {showFileUploader && (
                                             <div>
                                                  <label>
                                                       <FormikControl
                                                            name={EditJobKeys.ProofOfCollectionFile}
                                                            label={
                                                                 proofType === ProofType.POD
                                                                      ? 'Proof of Delivery'
                                                                      : 'Proof of Collection'
                                                            }
                                                            type='file'
                                                            onChange={(e: any) => {
                                                                 onFileChange(e, DocumentType.Any);
                                                                 if (proofType === ProofType.POD)
                                                                      formik.setFieldValue(
                                                                           EditJobKeys.ProofOfDeliveryFile,
                                                                           e.target.value
                                                                      );
                                                                 else
                                                                      formik.setFieldValue(
                                                                           EditJobKeys.ProofOfCollectionFile,
                                                                           e.target.value
                                                                      );
                                                            }}
                                                            disabled={false}
                                                            title={'documentTitle'}
                                                       />
                                                  </label>
                                                  <NoteForUploadButton proofType={proofType} />
                                                  {proofType === ProofType.POD
                                                       ? FileUploader(EditJobKeys.ProofOfCollectionFile)
                                                       : FileUploader(EditJobKeys.ProofOfDeliveryFile)}
                                             </div>
                                        )}
                                   </Row>
                                   <Row>
                                        <table id='id_table_section_upload_proof' className='table table-bordered table-condensed table-striped'>
                                             <thead>
                                                  <tr>
                                                       <td>Active</td>
                                                       <td>Job ID</td>
                                                       <td>Date / Time</td>
                                                       <td>Name</td>
                                                       {job.JobStatus != JobStatus.CompletedJob &&
                                                            job.JobStatus != JobStatus.BookedJob && <td>Action</td>}
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {pocList &&
                                                       pocList.map((data: JobProofOfCollection, index: number) => {
                                                            const { JobId, CreatedDate, Name, Id, Status } = data;
                                                            return (
                                                                 <tr>
                                                                      <td>{Status}</td>
                                                                      <td>{JobId}</td>
                                                                      <td>{getFormattedDateWithYear(CreatedDate)}</td>
                                                                      <td>
                                                                           <a
                                                                                href='#'
                                                                                onClick={(e) => {
                                                                                     e.preventDefault();
                                                                                     Status === 'Archived' ?
                                                                                     inspectionReportDownloadArchivedPOC(Id, Name) :
                                                                                     inspectionReportDownloadPOC(Id, Name);
                                                                                }}
                                                                                target='_blank'
                                                                                rel='noopener noreferrer'
                                                                           >
                                                                                {Name}
                                                                           </a>
                                                                      </td>
                                                                      {job.JobStatus != JobStatus.CompletedJob &&
                                                                           job.JobStatus != JobStatus.BookedJob && (
                                                                                <td>
                                                                                {Status == 'Active' && (
                                                                                     <FormikControl
                                                                                     control={FormControlKeys.Button}
                                                                                     label='Archive'
                                                                                     variant='danger'
                                                                                     onClick={() => {
                                                                                          handleArchive(Id);
                                                                                     }}
                                                                                     size='sm'
                                                                                     />
                                                                                )}
                                                                                {Status == 'Archived' && (
                                                                                     <FormikControl
                                                                                     control={FormControlKeys.Button}
                                                                                     label='Activate'
                                                                                     onClick={() => {
                                                                                          handleActivate(Id);
                                                                                     }}
                                                                                     size='sm'
                                                                                     />
                                                                                )}
                                                                                </td>
                                                                           )}
                                                                 </tr>
                                                            );
                                                       })}
                                             </tbody>
                                        </table>
                                   </Row>
                              </Col>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

interface FileToUpload {
     documentInformation: AdminMembersDocumentViewModel;
     file: File | null;
}

type GeneralFormProps = {
     job: JobViewModel;
     pocList?: JobProofOfCollection[];
     uploadNewProof: Function;
     proofType: ProofType;
     showLoading: Function;
     refreshProofList: Function;
};

export default SectionUploadProof;
