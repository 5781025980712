import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export const ButtonGroup = ({ ...props }): JSX.Element => {
  const { variant, label, icon, ...rest } = props;
  return (
    <button
      style={{ marginRight: 5 }}
      type="button"
      className={`btn btn-${variant}`}
      {...rest}
    >
      {icon}
      <span style={{ marginRight: 10 }} />
      {label}
    </button>
  );
};

ButtonGroup.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

ButtonGroup.defaultProps = {
  variant: 'primary',
  onClick: undefined,
};

export default ButtonGroup;
