import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { SavedSearch } from '../../../../../Models/Jobs/ViewJobs';

export const useGetSavedSearches = (options?: any): UseQueryResult<any, any> => {
     const getSavedSearches = () => {
          let response = agent.jobs.getAdminSavedSearch();
          return response;
     };

     return useQuery(['GetSavedSearches'], getSavedSearches, {
          ...options,
     });
};
