
export type GroupViewModel = {
    Active: boolean;
    AvgDistance: number;
    Email: string;
    Id: number;
    JobsAdded: number;
    JobsDone: number;
    LastLoginDate: string;
    NumMembers: number;
    ParentID: number;
    TotalDistance: number;
    TotalRecords: number;
    UserName: string;
}

export const GroupModelKeys = {
    Active: "Active",
    AvgDistance: "AvgDistance",
    Email: "Email",
    Id: "Id",
    JobsAdded: "JobsAdded",
    JobsDone: "JobsDone",
    LastLoginDate: "LastLoginDate",
    NumMembers: "NumMembers",
    ParentID: "ParentID",
    TotalDistance: "TotalDistance",
    TotalRecords: "TotalRecords",
    UserName: "UserName",
}