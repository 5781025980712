import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import agent from '../../../../../../Axios/Agent';

export const useGetPage = (administratorID: number,
  options?: any
): UseQueryResult<
  any,
  any
> => {
  const getAdministrator = ({ queryKey }: any) => {
    return agent.admin.getAdministrator(administratorID)
  };

  return useQuery(['GetAdministrator', administratorID], getAdministrator, {
    ...options,
    refetchOnWindowFocus: false,
  });
};
