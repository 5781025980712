import React from 'react';

export const VerifyEmailAddress = () => {
  return (
    <div className="verify-email-address-page standard-page container-fluid">
      <div className="row hero-row">
        <div className="container">
          <div className="col-lg-12">
            <h1>EMAIL VERIFICATION</h1>
            <h2>
              HELPLINE{' '}
              <span className="helpline-phone-number">0208 551 1060</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-lg-12">
          <div className="container">
            <h1>Email Verification</h1>
            <p data-bind="text: VerificationStatus"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailAddress;
