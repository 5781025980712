import { Redirect, Route, Switch } from 'react-router-dom';
import AddInvoice from '../Screens/Admin/Billing/AddInvoice';
import ViewBills from '../Screens/Admin/Billing/ViewBills';
import ViewInvoices from '../Screens/Admin/Billing/ViewInvoices';
import ViewTasks from '../Screens/Admin/Tasks/ViewTasks';
import AddFeedback from '../Screens/Admin/Feedback/AddFeedback';
import ViewFeedback from '../Screens/Admin/Feedback/ViewFeedback';
import AddGroup from '../Screens/Admin/Groups/AddGroup';
import ViewGroups from '../Screens/Admin/Groups/ViewGroups';
import JobActivityHistory from '../Screens/Admin/Jobs/JobActivityHistory';
import ViewJobs from '../Screens/Admin/Jobs/ViewJobs';
import ViewPayments from '../Screens/Admin/Jobs/ViewPayments';
import Login from '../Screens/Admin/Login/index';
import Logout from '../Screens/Admin/Logout/index';
import EditMember from '../Screens/Admin/Members/EditMember';
import NewMembers from '../Screens/Admin/Members/NewMembers';
import ViewMembers from '../Screens/Admin/Members/ViewMembers';
import AddPartner from '../Screens/Admin/Partners/AddPartner';
import ViewPartners from '../Screens/Admin/Partners/ViewPartners';
import ViewPrices from '../Screens/Admin/Prices/ViewPrices';
import JobsByUser from '../Screens/Admin/Reporting/JobsByUser';
import NewUpdatedUsers from '../Screens/Admin/Reporting/NewUpdatedUsers';
import Statistics from '../Screens/Admin/Reporting/Statistics';
import AddSearch from '../Screens/Admin/Searches/AddSearch';
import NotFound from '../Screens/Admin/Common/NotFound';
import ProtectedRoute from './ProtectedRoute';
import AddMember from '../Screens/Admin/Members/AddMember';
import ViewJob from '../Screens/Admin/Jobs/EditJob';
import ViewAdministrators from '../Screens/Admin/System/Administrators/ViewAdministrators';
import ViewAdminRoles from '../Screens/Admin/System/Administrators/ViewAdminRoles';
import AddAdministrator from '../Screens/Admin/System/Administrators/AddAdministrator';
import AddAdminRole from '../Screens/Admin/System/Administrators/AddAdminRoles';
import DefaultMargin from '../Screens/Admin/Prices/DefaultMargin';
import ViewPriceGroup from '../Screens/Admin/Prices/ViewPriceGroup';
import CustomerSetMargin from '../Screens/Admin/CustomerSet';
import ViewPages from '../Screens/Admin/System/Pages/ViewPages';
import EditPage from '../Screens/Admin/System/Pages/EditPage';
import { PageRoutes } from './Constants';
import TasksPage from '../Screens/Admin/System/Tasks';
import AuthServices from '../Screens/Admin/System/Services/AuthServices';
import CacheService from '../Screens/Admin/System/Services/CacheService';
import EmailService from '../Screens/Admin/System/Services/EmailService';
import SchedulingService from '../Screens/Admin/System/Services/SchedulingService';
import ViewBill from '../Screens/Admin/Billing/AddBill';
import EditPriceGroup from '../Screens/Admin/Prices/AddEditPriceGroup';
import ViewVehicles from '../Screens/Admin/System/Vehicles/ViewVehicles';
import AddVehicle from '../Screens/Admin/System/Vehicles/AddVehicle';
import ViewSaveSearches from '../Screens/Admin/Searches/ViewSearches';
import AddTask from '../Screens/Admin/Tasks/AddEditTask';
import PermissionDenied from '../Screens/Admin/NotAuthorized/index';

const RouteList = () => {
  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      
      {/* /*Tasks */ }
      <ProtectedRoute path="/view-tasks" component={ViewTasks} permission="AdminUsers|AdminUserTasks"/>
      <ProtectedRoute path="/add-task" component={AddTask} permission="AdminUsers|CreateAdminTask"/>
      <ProtectedRoute path="/edit-task/:taskID" component={AddTask} permission="AdminUsers|TaskActivity"/>
      
      {/* Members */}
      <ProtectedRoute path="/add-member" component={AddMember} permission="AdminUsers|AddMember"/>
      <ProtectedRoute path="/members/" component={ViewMembers} permission="AdminUsers|Members"/>
      <ProtectedRoute path="/new-members" component={NewMembers} permission="AdminUsers|Members"/>
      <ProtectedRoute path="/edit-member/:memberID" component={EditMember} permission="AdminUsers|EditMember"/>
      
      {/* Groups */}
      <ProtectedRoute path="/add-group" component={AddGroup} permission="AdminUsers|AddGroup"/>
      <ProtectedRoute path="/edit-group/:groupID" component={AddGroup} permission="AdminUsers|EditGroup"/>
      <ProtectedRoute path="/view-groups" component={ViewGroups} permission="AdminUsers|Groups"/>
      
      {/* Customer Set */}
      <ProtectedRoute
        path="/customer-set-margin"
        component={CustomerSetMargin}
        permission="AdminUsers|CustomerSetMargins"
      />
      
      {/* Jobs */}
      <ProtectedRoute path="/view-jobs" component={ViewJobs} permission="AdminUsers|Jobs" />
      <ProtectedRoute path="/view-job/:jobID" component={ViewJob} permission="AdminUsers|ViewJob" />
      <ProtectedRoute path="/jobs" component={ViewJobs} permission="AdminUsers|ViewJob" />
      <ProtectedRoute path="/view-payments" component={ViewPayments} permission="AdminUsers|Payments" />
      <ProtectedRoute
        path="/jobs-activity-history"
        component={JobActivityHistory}
        permission="AdminUsers|JobsActivityHistory"
      />
      
      {/* Feedback */}
      <ProtectedRoute path="/view-feedback" component={ViewFeedback} permission="AdminUsers|ViewFeedback"/>
      <ProtectedRoute path="/add-feedback" component={AddFeedback} permission="AdminUsers|AddFeedback"/>
      <ProtectedRoute
        path="/edit-feedback/:feedbackID"
        component={AddFeedback}
        permission="AdminUsers|AddFeedback"
      />
      
      {/* Searches */}
      <ProtectedRoute path="/view-searches" component={ViewSaveSearches} permission="AdminUsers|Searches"/>
      <ProtectedRoute path="/add-search" component={AddSearch} permission="AdminUsers|AddSearch"/>
      <ProtectedRoute path="/edit-search/:searchID" component={AddSearch} permission="AdminUsers|EditSearch"/>
      
      {/* Partners */}
      <ProtectedRoute path="/view-partners" component={ViewPartners} permission="AdminUsers|Partners"/>
      <ProtectedRoute path="/add-partner" component={AddPartner} permission="AdminUsers|AddPartner"/>
      <ProtectedRoute path="/edit-partner/:partnerID" component={AddPartner} permission="AdminUsers|EditPartner"/>
      
      {/* Prices */}
      <ProtectedRoute path="/view-prices" component={ViewPrices} permission="AdminUsers|Prices"/>
      <ProtectedRoute path="/default-margin" component={DefaultMargin} permission="AdminUsers|DefaultMargins"/>
      <ProtectedRoute path="/view-price-groups" component={ViewPriceGroup} permission="AdminUsers|PriceGroups"/>
      <ProtectedRoute path="/add-price-groups" component={EditPriceGroup} permission="AdminUsers|AddPriceGroup"/>
      <ProtectedRoute path="/edit-price-group/:priceGroupID" component={EditPriceGroup} permission="AdminUsers|EditPriceGroup"/>
      
      {/* Billing */}
      <ProtectedRoute path="/view-bills/:MemberId?" component={ViewBills} permission="AdminUsers|Billing"/>
      <ProtectedRoute path="/view-invoices" component={ViewInvoices} permission="AdminUsers|Invoices"/>
      <ProtectedRoute path="/add-invoice" component={AddInvoice} permission="AdminUsers|AddInvoice"/>
      <ProtectedRoute path="/view-invoice/:invoiceID" component={AddInvoice} permission="AdminUsers|ViewInvoice"/>
      <ProtectedRoute path="/add-credit-note" component={AddInvoice} permission="AdminUsers|AddInvoice"/>
      <ProtectedRoute path="/view-bill/:billID" component={ViewBill} permission="AdminUsers|ViewBill"/>
      
      {/* Reporting */}
      <ProtectedRoute path="/jobs-by-user" component={JobsByUser} permission="AdminUsers|ReportsJobsByUser"/>
      <ProtectedRoute path="/new-updated-users" component={NewUpdatedUsers} permission="AdminUsers|ReportsNewOrUpdatedUsers"/>
      <ProtectedRoute path="/statistics" component={Statistics} permission="AdminUsers|Statistics"/>

      {/* System */}
      <ProtectedRoute path="/_authservice" component={AuthServices} permission="AdminUsers|_AuthService"/>
      <ProtectedRoute path="/_cacheservice" component={CacheService} permission="AdminUsers|_CacheService"/>
      <ProtectedRoute path="/_emaildeliveryservice" component={EmailService} permission="AdminUsers|_EmailService"/>
      <ProtectedRoute path="/_schedulingservice" component={SchedulingService} permission="AdminUsers|_SchedulingServices"/>
      
      {/* Administrator */}
      <ProtectedRoute path="/adminroles" component={ViewAdminRoles} permission="AdminUsers|AdminRoles"/>
      <ProtectedRoute path="/administrators" component={ViewAdministrators} permission="AdminUsers|Administrators"/>
      <ProtectedRoute path="/add-adminrole" component={AddAdminRole} permission="AdminUsers|AddAdminRole"/>
      <ProtectedRoute path="/edit-adminrole/:administratorID" component={AddAdminRole} permission="AdminUsers|EditAdminRole"/>
      <ProtectedRoute path="/add-administrator" component={AddAdministrator} permission="AdminUsers|AddAdministrator"/>
      <ProtectedRoute path="/edit-administrator/:administratorID" component={AddAdministrator} permission="AdminUsers|EditAdministrator"/>

      {/* Pages */}
      <ProtectedRoute path="/pages" component={ViewPages} permission="AdminUsers|Pages"/>
      <ProtectedRoute path="/edit-page" component={EditPage} permission="AdminUsers|EditPage"/>
      
      {/* Tasks Page*/}
      <ProtectedRoute path="/tasks" component={TasksPage} permission="AdminUsers|Tasks"/>
      <ProtectedRoute path="/view-task/:taskID" component={AddTask} permission="AdminUsers|Tasks"/>
      
      {/* Vehicles*/}
      <ProtectedRoute path="/view-vehicles" component={ViewVehicles} permission="AdminUsers|Vehicles"/>
      <ProtectedRoute path="/edit-vehicle/:vehicleID" component={AddVehicle} permission="AdminUsers|EditVehicle"/>
      <ProtectedRoute path="/add-vehicle" component={AddVehicle} permission="AdminUsers|AddVehicle"/>

      {/* Other Pages */}
      {PageRoutes.map((pageRoute) => {
        const { path, component } = pageRoute;
        return <ProtectedRoute key={path} path={`/${path}`} component={component} permission=""/>;
      })}

      {/*PermissionDenied*/}
      <Route path="/permission-denied" component={PermissionDenied}/>

      {/* Others */}
      <Route path="/login" component={Login} />
      <Redirect from="/" exact to="/login" />
      <Route path="/not-found" component={NotFound} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default RouteList;
