import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { OptionType } from '../../../../../Components/FormControls';
import { GroupsDropdownViewModelKeys } from '../../../../../Models/Groups';
import { mapResultsToOptionTypeList } from '../../../../../Utils';

export const useGetGroupsForDropdown = (
  options?: any
): UseQueryResult<OptionType[], any> => {

  const getGroupsForDropdown = () => {
    return agent.groups.getGroupsForDropdown();
  }

  return useQuery(['GetGroupsForDropdown'], getGroupsForDropdown, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
