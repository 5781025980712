import {
     GridCellCheckboxRenderer,
     GridColDef,
     GridColumnHeaderParams,
     GridHeaderCheckbox,
     GridRenderCellParams,
     GridValueGetterParams,
     selectedIdsLookupSelector,
} from '@mui/x-data-grid';
import { CellRenderer, HoverableLink } from '../../../../../Components/Formik';
import { JobGridKeys } from '../../../../../Models/Jobs/ViewJobs';
import { RouteConstants } from '../../../../../Routes/Constants';
import { JobOfferedTo } from '../../../../../Utils/Enums';
import NextExpiringQuote from './NextExpiringQuote';

const {
     renderAdminJobStatusCell,
     renderCellWithTooltip,
     renderDateCell,
     renderDate,
     renderJobKindCell,
     renderJobMaxMarginCell,
     renderJobMovementTypeCell,
     renderJobNoOfVehiclesCell,
     renderJobOfferedToCell,
     renderJobSalesCell,
     renderJobTypeCell,
     renderJobUserOrSupplierCell,
     renderPaymentCell,
     renderServiceTypeCell,
     renderJobsActionCell,
} = CellRenderer;

export const getColumns = () => {

     const columns: GridColDef[] = [
          {
               field: '__check__',
               resizable: false,
               sortable: false,
               filterable: false,
               disableColumnMenu: true,
               disableReorder: true,
               disableExport: true,
               flex: 1,
               valueGetter: (params) => {
                    const selectionLookup = selectedIdsLookupSelector(
                         (params as GridValueGetterParams).api.state
                    ) as any;
                    return selectionLookup[params.id] !== undefined;
               },
     
               renderHeader: (params) => (
                    <GridHeaderCheckbox {...params} />
               ),
               renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
          },
          {
               field: JobGridKeys.ID,
               headerName: 'ID',
               description: 'ID',
               renderCell: (params: GridRenderCellParams) => {
                    const isFiveStarCompany = params.row.JobOfferedTo == JobOfferedTo.FiveStarCompanies;
                    if (isFiveStarCompany) {
                         return (
                              <HoverableLink to={`${RouteConstants.ViewJobById}/${params.value}`}>
                                   <span className='five-stars' title='Five Star Companies'>
                                        {params.value}<i className='fa fa-star'></i>
                                   </span>
                              </HoverableLink>
                         );
                    } else {
                         return (
                              <HoverableLink to={`${RouteConstants.ViewJobById}/${params.value}`}>
                                   <span>{params.value}</span>
                              </HoverableLink>
                         );
                    }
               },
               flex: 5,
          },       
          {
               field: JobGridKeys.SalesPerson,
               headerName: 'Sales',
               description: 'Sales',
               flex: 8,
               renderCell: (params: GridRenderCellParams) => renderJobSalesCell(params),
          },
          {
               field: JobGridKeys.RequestDate,
               description: 'Request',
               headerName: 'Request',
               renderCell: (params: GridRenderCellParams) => renderDateCell(params),
               flex: 4,
          },
          {
               field: JobGridKeys.RequestingMemberUserName,
               headerName: 'User',
               description: 'User',
               flex: 12,
               renderCell: (params: GridRenderCellParams) => renderJobUserOrSupplierCell(params, true),
          },
          {
               field: JobGridKeys.SupplyingMemberUserName,
               description: 'Supplier',
               headerName: 'Supplier',
               flex: 12,
               renderCell: (params: GridRenderCellParams) => renderJobUserOrSupplierCell(params, false),
          },
          {
               field: JobGridKeys.JobKind,
               description: 'Kind',
               headerName: 'Kind',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderJobKindCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.JobMovementType,
               description: 'Move',
               headerName: 'Move',
               flex: 5,
               renderCell: (params: GridRenderCellParams) => renderJobMovementTypeCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.ServiceType,
               description: 'Service',
               headerName: 'Service',
               flex: 6,
               renderCell: (params: GridRenderCellParams) => renderServiceTypeCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.NoOfVehicles,
               description: 'Vehicles',
               headerName: 'Vehicles',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderJobNoOfVehiclesCell(params),
          },
          {
               field: JobGridKeys.CollectionPostCode,
               description: 'From',
               headerName: 'From',
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
               flex: 6,
               sortable: false,
          },
          {
               field: JobGridKeys.DeliveryPostCode,
               description: 'To',
               headerName: 'To',
               flex: 6,
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
               sortable: false,
          },
          {
               field: JobGridKeys.DistanceMiles,
               description: 'Miles',
               headerName: 'Miles',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => {
                    params.value = Math.round(params.value);
                    return renderCellWithTooltip(params);
               },
          },
          {
               field: JobGridKeys.CollectionDate,
               description: 'Collection',
               headerName: 'Collection',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderDate(params),
          },
          {
               field: JobGridKeys.DeliveryDate,
               description: 'Delivery',
               headerName: 'Delivery',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderDate(params),
          },
          {
               field: JobGridKeys.JobType,
               description: 'Type',
               headerName: 'Type',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderJobTypeCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.JobOfferedTo,
               description: 'Offered',
               headerName: 'Offered',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderJobOfferedToCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.NumQuotes,
               description: 'Quotes',
               headerName: 'Quotes',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderCellWithTooltip(params),
          },
          {
               field: JobGridKeys.NextExpiringQuote,
               description: 'Next Quote',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => <NextExpiringQuote {...params} />,
               renderHeader: (_: GridColumnHeaderParams) => (
                    <div className="grid-custom-header">
                         Next Quote
                    </div>
               )
          },
          {
               field: JobGridKeys.MaximumMargin,
               description: 'Max Margin',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderJobMaxMarginCell(params),
               renderHeader: (params: GridColumnHeaderParams) => (
                    <div className="grid-custom-header">
                         Max Margin
                    </div>
               )
          },
          {
               field: JobGridKeys.JobStatus,
               description: 'Status',
               headerName: 'Status',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderAdminJobStatusCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.PaymentMethod,
               description: 'Payment',
               headerName: 'Payment',
               flex: 4,
               renderCell: (params: GridRenderCellParams) => renderPaymentCell(params),
               sortable: false,
          },
          {
               field: JobGridKeys.AdminNotes,
               description: 'Action',
               headerName: 'Action',
               type: 'actions',
               flex: 6,
               renderCell: (params: GridRenderCellParams) =>
                    renderJobsActionCell(params.value, params.id as number),
          },
     ];

     return columns;
};
