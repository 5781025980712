export enum DocumentType {
    Any = 0,
    InsuranceDocument = 1,
    LicenseDocument = 2,
    PhotoIdentification = 3,
    ProofOfBusinessAddress = 4,
    ProofOfPartnerAddress = 5,
    CreditReport = 6,
}

