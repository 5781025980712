
export const CustomConfigKeys = {
    CustomConfigOptions: "CustomConfigOptions",

    /** Dealer UI Main Menu keys*/
    ShowQuotesRequested: "ShowQuotesRequested",
    ShowAddJob: "ShowAddJob",
    ShowActiveJobs: "ShowActiveJobs",
    ShowCompletedJobs: "ShowCompletedJobs",
    ShowTradeInsurance: "ShowTradeInsurance",

    /** Dealer UI Add Job keys*/
    ChooseDealerUIAddJobSection: "ChooseDealerUIAddJobSection",

    /** Dealer Flow Add Job keys*/
    AutoQuotesForDrivenFixedPrice: "AutoQuotesForDrivenFixedPrice",
    AutoQuotesForTransportedFixedPrice: "AutoQuotesForTransportedFixedPrice",
}

export const DealerUIAddJobkeys = {
    ShowJobKindSection: "ShowJobKindSection",
    AllowNonStandardJobs: "AllowNonStandardJobs",
    DefaultJobKind: "DefaultJobKind",

    ShowJobMovementTypeSection: "ShowJobMovementTypeSection",
    HideInternalTransfer: "HideInternalTransfer",
    HideDealerTransfer: "HideDealerTransfer",
    HideFleetDelivery: "HideFleetDelivery",
    HideRetailDelivery: 'HideRetailDelivery',
    HidePremiumFleet: "HidePremiumFleet",
    HidePremiumRetail: "HidePremiumRetail",

    DefaultJobMovementType: 'DefaultJobMovementType',
    JobTypeForInternalTransfer: "JobTypeForInternalTransfer",
    JobTypeForDealerTransfer: "JobTypeForDealerTransfer",
    JobTypeForFleetDelivery: "JobTypeForFleetDelivery",
    JobTypeForRetailDelivery: "JobTypeForRetailDelivery",
    JobTypeForPremiumFleet: "JobTypeForPremiumFleet",
    JobTypeForPremiumRetail: "JobTypeForPremiumRetail",
    IgnoreFavouritesForInternalTransfer: "IgnoreFavouritesForInternalTransfer",
    IgnoreFavouritesForDealerTransfer: "IgnoreFavouritesForDealerTransfer",
    IgnoreFavouritesForFleetDelivery: "IgnoreFavouritesForFleetDelivery",
    IgnoreFavouritesForRetailDelivery: "IgnoreFavouritesForRetailDelivery",
    IgnoreFavouritesForPremiumFleet: "IgnoreFavouritesForPremiumFleet",
    IgnoreFavouritesForPremiumRetail: "IgnoreFavouritesForPremiumRetail",

    ShowServiceTypeSection: "ShowServiceTypeSection",
    AllowServiceTypeDriven: "AllowServiceTypeDriven",
    AllowServiceTypeTransported: "AllowServiceTypeTransported",
    AllowServiceTypeBoth: 'AllowServiceTypeBoth',
    DefaultServiceType: "DefaultServiceType",
    Show_Transported_Enclosed: "Show_Transported_Enclosed",
    Default_Driven_ValidMOT: "Default_Driven_ValidMOT",
    Default_Driven_FuelRequired: "Default_Driven_FuelRequired",
    Default_Transported_DriveOnto: "Default_Transported_DriveOnto",
    Transported_EnclosedSuppliers: "Transported_EnclosedSuppliers",

    ShowVehiclesSection: "ShowVehiclesSection",
    AllowMultiVehicleJobs: "AllowMultiVehicleJobs",
    RequireVrmsOnJobsVehicles: "RequireVrmsOnJobsVehicles",
    RequireConditionOnJobsVehicles: "RequireConditionOnJobsVehicles",
    RequireValueOnJobsVehicles: "RequireValueOnJobsVehicles",
    SeeVRMBeforeJobAccepted: "SeeVRMBeforeJobAccepted",
    DefaultNoOfVehicles: "DefaultNoOfVehicles",

    ShowCollectionSection: "ShowCollectionSection",

    ShowDeliverySection: "ShowDeliverySection",

    ShowDatesSection: "ShowDatesSection",
    AllowExactDatesAndTimes: "AllowExactDatesAndTimes",
    DefaultCollectionDateOffsetFromToday: "DefaultCollectionDateOffsetFromToday",
    DefaultDeliveryDateOffsetFromCollectionDateBeforeMidday: "DefaultDeliveryDateOffsetFromCollectionDateBeforeMidday",
    DefaultDeliveryDateOffsetFromCollectionDateAfterMidday: "DefaultDeliveryDateOffsetFromCollectionDateAfterMidday",

    ShowJobTypeSection: "ShowJobTypeSection",
    AllowQuoteRequests: "AllowQuoteRequests",
    AllowJobTypeFixedPrice: "AllowJobTypeFixedPrice",
    AllowJobTypeBoth: "AllowJobTypeBoth",
    AllowJobTypeGuaranteedPrice: "AllowJobTypeGuaranteedPrice",
    FixedPricesSetAutomatically: "FixedPricesSetAutomatically",
    FixedPricesEditableForWholeNetwork: "FixedPricesEditableForWholeNetwork",
    FixedPricesEditableFiveStarCompanies: "FixedPricesEditableFiveStarCompanies",
    FixedPricesEditableSelectableSuppliers: "FixedPricesEditableSelectableSuppliers",
    DefaultJobType: "DefaultJobType",

    ShowJobOfferedToSection: "ShowJobOfferedToSection",
    DefaultJobOfferedTo: "DefaultJobOfferedTo",
    ShowSelectableSuppliers: "ShowSelectableSuppliers",
    SelectableSuppliers: "SelectableSuppliers",
    SelectableSuppliersChoosableByUser: 'SelectableSuppliersChoosableByUser',
    SelectedSupplierJobAutoReOfferedTo: 'SelectedSupplierJobAutoReOfferedTo',
    SelectedSupplierJobAutoReOfferedAfterValue: "SelectedSupplierJobAutoReOfferedAfterValue",
    SelectedSupplierJobAutoReOfferedAfterPeriod: "SelectedSupplierJobAutoReOfferedAfterPeriod",
    SelectedSupplierJobAutoServiceType: "SelectedSupplierJobAutoServiceType",

    ShowAttachPhotosSection: "ShowAttachPhotosSection",
    ShowShippingNotesSection: "ShowShippingNotesSection",
    ShowAdditionalNotesSection: "ShowAdditionalNotesSection",
    DefaultNoteForJobs: 'DefaultNoteForJobs',

    DefaultNoteForInternalTransferJobs: "DefaultNoteForInternalTransferJobs",
    DefaultNoteForDealerTransferJobs: "DefaultNoteForDealerTransferJobs",
    DefaultNoteForFleetDeliveryJobs: "DefaultNoteForFleetDeliveryJobs",
    DefaultNoteForRetailDeliveryJobs: "DefaultNoteForRetailDeliveryJobs",
    DefaultNoteForPremiumFleetJobs: "DefaultNoteForPremiumFleetJobs",
    DefaultNoteForPremiumRetailJobs: "DefaultNoteForPremiumRetailJobs",

    ShowYourReferenceSection: "ShowYourReferenceSection",
    ShowPromoCodeSection: 'ShowPromoCodeSection',

    AllowModelJobs: 'AllowModelJobs',
    ModelVmAccountId: 'ModelVmAccountId',
}

