export const FilterKeys = {
    FreeText: "FreeText",
    InvoiceStatus: "InvoiceStatus",
    PaymentType: "PaymentType",
    InvoiceDate: "InvoiceDate",
    MemberId: "MemberId",
    FreeTextSearchBy: "FreeTextSearchBy"
}


export const BillingFormKeys = {
    PaymentStatus: "PaymentStatus",
    PaymentDate: "PaymentDate",
    UpdatePaymentInformation: "UpdatePaymentInformation",
}