export enum TypeOfBusiness {
    FranchiseDealership = 5,
    IndependentUserCarDealership = 6,
    CarTrader = 7,
    Auction = 8,
    FinanceCompany = 9,
    RentalCompany = 10,
    RemarketingCompany = 11,
    ShippingCompany = 12,
    SalvageCompany = 13,
    Other = 255
}