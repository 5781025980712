import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Loading } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { UpdatePaymentDueDateModel } from '../../../../../Models/Jobs';
import { usePutUpdatePaymentDueDate } from '../Hooks';
import { ColWrapper, ErrorContainer, RowWrapper } from './style/PaymentModuleStyledComponents';

export const ChangeDueDateModalBody: React.FC<GeneralFormProps> = ({ paymentId, onClose, onRefresh }): JSX.Element => {
     /** States */
     const [paymentDueDateUpdated, setPaymentDueDateUpdated] = React.useState(false);

     /** Query Hooks */
     const { mutate: updatePaymentDueDate, isLoading, data, error, isSuccess } = usePutUpdatePaymentDueDate();

     useEffect(() => {
          if (data) {
               setPaymentDueDateUpdated(true);
               onRefresh();
          }
          onRefresh();
     }, [error, data]);

     /** Formik */
     const formik = useFormik({
          initialValues: { DueDate: new Date() },
          onSubmit: () => {
               const paymentDueDateModel: UpdatePaymentDueDateModel = {
                    PaymentID: paymentId,
                    DueDate: values.DueDate,
               };
               updatePaymentDueDate(paymentDueDateModel);
          },
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     return (
          <>
               <Loading loading={isLoading} />
               {error && <ErrorContainer>{error?.Subject}: {error?.Description}</ErrorContainer>}
               {paymentDueDateUpdated && isSuccess && <div>Payment Date updated!</div>}
               {paymentDueDateUpdated && !isSuccess && <div>Error: {error}</div>}
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         {!paymentDueDateUpdated && (
                              <Row>
                                   <FormikControl type='date' name={'ChangeDueDatePayment'} label='Date' />
                              </Row>
                         )}
                         <RowWrapper>
                              {!paymentDueDateUpdated && (
                                   <ColWrapper>
                                        <FormikControl
                                             name='Submit'
                                             label='Submit'
                                             type='submit'
                                             control={FormControlKeys.Button}
                                        />
                                   </ColWrapper>
                              )}
                              <ColWrapper>
                                   <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                              </ColWrapper>
                         </RowWrapper>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     paymentId: number;
     onClose: Function;
     onRefresh: Function;
};
