import React from 'react';
import { FieldError } from './StyledComponents';

function TextError(props: any) {
  if (props.children && props.children.length > 0) {
    return <FieldError>{props.children} </FieldError>;
  }
  return null;
}

export default TextError;
