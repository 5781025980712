import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import MovexResponseError from '../../../../../Axios/MovexResponseError';
import { Alert, ConfirmDialog, ConfirmDialogProps, Loading, ShowPleaseWait, useAlert } from '../../../../../Components';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
     DivContainer,
     FieldSetContainer,
     FormContainer,
     FormFieldSetContainer,
     RowFluidContainer,
} from '../../../../../Components/Formik/StyledComponents';
import { AddVehicleModel } from '../../../../../Models/System/Vehicles';
import { UpdateVehicleModel } from '../../../../../Models/System/Vehicles/UpdateVehicleModel';
import { RouteConstants } from '../../../../../Routes';
import { isMovexErrorResponse } from '../../../../../Utils';
import withAddForm from '../../../Common/AddForm/withAddForm';
import { AddVehicleTypeOptions } from '../Constants';
import { useGetVehicle, usePostAddVehicle, usePutUpdateVehicle } from './Hooks';
import { VehicleIDParamsType } from './Types';

const validationSchema = object({});

const AddVehicle = () => {
     const { vehicleID: paramVehicleID } = useParams<VehicleIDParamsType>();
     const vehicleID = paramVehicleID ? parseInt(paramVehicleID) : 0;

     const isEditMode = vehicleID > 0;
     const history = useHistory();

     const onConfirmDialogClose = () => {
          setConfirmDialogContent({ ...confirmDialogContent, show: false });
     };

     /** useStates */
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [confirmDialogContent, setConfirmDialogContent] = useState<ConfirmDialogProps>({
          header: '',
          body: '',
          cancelCallback: onConfirmDialogClose,
     });

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Methods */
     const onSubmit = (payload: any): void => {
          const request: AddVehicleModel = { ...payload };

          if (isEditMode) {
               const vehicleTypeNumber = Number(payload.VehicleType);
               const VehicleResponse: UpdateVehicleModel = {
                    Id: payload.Id,
                    Make: payload.Make,
                    MaxWeight: payload.MaxWeight,
                    Model: payload.Model,
                    VehicleType: vehicleTypeNumber,
               };
               updateVehicle(VehicleResponse);
          } else {
               addVehicle(request);
          }
     };

     const onVehicleSaveSuccess = () => {
          history.push(RouteConstants.ViewVehicles);
     };

     const onVehicleUpdateSuccess = () => {
          history.push(RouteConstants.ViewVehicles);
     };

     const formik = useFormik({
          initialValues: {
               Id: undefined,
               Make: '',
               MaxWeight: undefined,
               Model: '',
               VehicleType: undefined,
          },
          validationSchema: validationSchema,
          onSubmit: onSubmit,
     });

     /** Query Hooks */
     const { data: getVehicleResponse, isLoading: isGetVehicleLoading, error: getVehiclesError } = useGetVehicle(vehicleID);

     const {
          mutate: updateVehicle,
          isLoading: isUpdateVehicleLoading,
          data: putUpdateVehicleResponse,
          error: updateVehicleError,
     } = usePutUpdateVehicle();

     const {
          mutate: addVehicle,
          isLoading: isAddVehicleLoading,
          data: postAddVehicleResponse,
          error: addVehicleError,
     } = usePostAddVehicle();

     const { handleSubmit, values, errors, validateForm, isValid, setFieldValue, resetForm } = formik;

     /** useEffects */
     useEffect(() => {
          if (isEditMode) {
               if (getVehicleResponse) {
                    formik.resetForm({ values: getVehicleResponse.VehicleModel });
               }
          } else {
               formik.resetForm({
                    values: {
                         Id: undefined,
                         Make: '',
                         MaxWeight: undefined,
                         Model: '',
                         VehicleType: undefined,
                    },
               });
          }
     }, [isEditMode, getVehicleResponse]);

     useEffect(() => {
          if (postAddVehicleResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    body: 'Save Successful!',
                    closeCallback: onVehicleSaveSuccess,
               });
          }
     }, [postAddVehicleResponse]);

     useEffect(() => {
          if (putUpdateVehicleResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    body: 'Update Successful!',
                    closeCallback: onVehicleUpdateSuccess,
               });
          }
     }, [putUpdateVehicleResponse]);

     useEffect(() => {
          setIsLoading(isGetVehicleLoading);
     }, [isGetVehicleLoading]);

     useEffect(() => {
          setShowPleaseWait(isUpdateVehicleLoading || isAddVehicleLoading);
     }, [isUpdateVehicleLoading, isAddVehicleLoading]);

     useEffect(() => {
          if (addVehicleError) {
               if (isMovexErrorResponse(addVehicleError)) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: MovexResponseError.getMovexErrorContent(addVehicleError).header,
                         body: MovexResponseError.getMovexErrorContent(addVehicleError).body,
                    });
               }
          }
     }, [addVehicleError]);

     useEffect(() => {
          if (getVehiclesError) {
               if (isMovexErrorResponse(getVehiclesError)) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: MovexResponseError.getMovexErrorContent(getVehiclesError).header,
                         body: MovexResponseError.getMovexErrorContent(getVehiclesError).body,
                    });
               }
          }
     }, [getVehiclesError]);

     useEffect(() => {
          if (updateVehicleError) {
               if (isMovexErrorResponse(updateVehicleError)) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: MovexResponseError.getMovexErrorContent(updateVehicleError).header,
                         body: MovexResponseError.getMovexErrorContent(updateVehicleError).body,
                    });
               }
          }
     }, [updateVehicleError]);

     const component = (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <ShowPleaseWait show={showPleaseWait} />
               <Loading loading={isLoading} />
               <ConfirmDialog {...confirmDialogContent} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         handleSubmit(e);
                    }}
               >
                    <FormFieldSetContainer>
                         <FieldSetContainer>
                              <RowFluidContainer>
                                   <FormikControl
                                        name='VehicleType'
                                        label='Type'
                                        control={FormControlKeys.Select}
                                        options={AddVehicleTypeOptions}
                                        placeholder='Select'
                                   />
                                   <FormikControl name='Make' label='Make' />
                                   <FormikControl name='Model' label='Model' />
                                   <FormikControl name='MaxWeight' label='Max Weight' />
                                   <DivContainer>
                                        <FormikControl
                                             name='AddUpdateVehicleButton'
                                             control={FormControlKeys.Button}
                                             type='submit'
                                             label={isEditMode ? 'Update Vehicle' : 'Add Vehicle'}
                                             style={{ margin: '10px 0' }}
                                        ></FormikControl>
                                   </DivContainer>
                              </RowFluidContainer>
                         </FieldSetContainer>
                    </FormFieldSetContainer>
               </FormContainer>
          </FormikProvider>
     );

     return withAddForm(component, isEditMode ? 'Edit Vehicle' : 'Add Vehicle');
};

export default AddVehicle;
