import { ResultType } from "../../Utils"

export type GroupsDropdownViewModel = {
    id: number,
    parentId?: number,
    username: string,
}

export const GroupsDropdownViewModelKeys: { [key in keyof ResultType]: key } = {
    Id: 'Id',
    ParentId: 'ParentId',
    Username: 'Username',
}