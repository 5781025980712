import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const useDeletePriceGroup = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, number> => {
     const deletePriceGroup = (priceGroupId: number) => {
          return agent.priceGroup.deletePriceGroup(priceGroupId);
     };

     return useMutation(deletePriceGroup);
};
