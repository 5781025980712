import React, { Fragment, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export const TypeaheadSingleInput = ({ ...props }) => {
  const { name, label, options = [], onTypeaheadInput, ...rest } = props;
  const [selected, setSelected] = useState<any>([]);
  return (
    <div className="mb-3 form-group">
      <label htmlFor={name} className="form-label fw-600">
        {label}
      </label>
      <Typeahead
        id="basic-example"
        options={options}
        selected={selected}
        multiple
        onChange={(value) => {
          setSelected(value);
        }}
        {...rest}
      />
    </div>
  );
};

export default TypeaheadSingleInput;
