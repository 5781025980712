import { GridRenderCellParams } from '@mui/x-data-grid';
import { CellRenderer } from '../../../../../Components/Formik';
import { BillModelKeys } from '../../../../../Models/Billing/BillViewModel';
import { getFormattedDateWithYear } from '../../../../../Utils';

const {
  renderEditBillingByBillingIdCell,
  renderBillingMemberUserNameCell,
  renderMemberTypeCell,
  renderCurrencyCell,
  renderPaymentStatusCell,
  renderBillingActionCell,
  renderPaymentMethodCell,
  renderBillInvoiceCell
} = CellRenderer;

export const columns = [
  {
    field: BillModelKeys.Id,
    headerName: 'ID',
    description: 'ID',
    renderCell: (params: GridRenderCellParams) => renderEditBillingByBillingIdCell(params),
  },
  {
    field: BillModelKeys.BillType,
    headerName: 'Bill Type',
    description: 'Bill Type',
    flex: 4,
  },
  {
    field: BillModelKeys.MemberUserName,
    headerName: 'Username',
    description: 'Username',
    renderCell: (params: GridRenderCellParams) => {
      let memberId = (params.row.BilledMemberID as number) || 0;
      return renderBillingMemberUserNameCell(params, memberId);
    },
    flex: 2,
  },
  {
    field: BillModelKeys.MemberType,
    headerName: 'Member Type',
    flex: 2,
    description: 'MemberType',
    renderCell: (params: GridRenderCellParams) => renderMemberTypeCell(params.value),
    sortable: false,
  },
  {
    field: BillModelKeys.MemberPaymentMethod,
    headerName: 'Payment Method',
    flex: 2,
    description: 'PaymentMethod',
    renderCell: (params: GridRenderCellParams) => renderPaymentMethodCell(params.value),
    sortable: false,
  },
  {
    field: BillModelKeys.MemberPaymentReference,
    headerName: 'Payment Reference',
    flex: 2,
    description: 'Payment Reference',
    sortable: false,
  },
  {
    field: BillModelKeys.CorrectedTotal,
    headerName: 'Total Fee',
    description: 'TotalFee',
    renderCell: (params: GridRenderCellParams) => renderCurrencyCell(params),
    flex: 2,
  },
  {
    field: BillModelKeys.InvoiceDate,
    headerName: 'Invoice Date',
    description: 'Invoice Date',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => getFormattedDateWithYear(params.value),
  },
  {
    field: BillModelKeys.InvoiceFileName,
    headerName: 'Invoice',
    flex: 2,
    description: 'Invoice',
    renderCell: (params: GridRenderCellParams) => renderBillInvoiceCell(params),
  },
  {
    field: BillModelKeys.PaymentStatus,
    headerName: 'Payment Status',
    flex: 2,
    description: 'Payment Status',
    renderCell: (params: GridRenderCellParams) => renderPaymentStatusCell(params),
    sortable: false,
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 2,
    description: 'Action',
    type: 'actions',
    renderCell: (params: GridRenderCellParams) => renderBillingActionCell(params.id as number),
  },
];
