export enum TaskStatus {
    Creating = 0,
    New = 1,
    InProgress = 2,
    AwaitingDealer = 3,
    AwaitingSupplier = 4,
    AwaitingOther = 5,
    PaymentApproved = 6,
    PaymentDisputed = 7,
    Disputed = 8,
    Confirmed = 9,
    Closed = 254,
    Completed = 255,
  }
  