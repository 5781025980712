import axios from 'axios';
import { AddPartnerMemberModel } from '../../../Models/Members';
import { AddPartnerModel, DeletePartnerMembersRequestModel, UpdatePartnerRequestModel } from '../../../Models/Partners';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    GetPartner_URL,
    GetPartners_URL,
    PostAddPartner_URL,
    PutUpdatePartner_URL,
    DeletePartner_URL,
    DeleteSelectedMembersFromPartner_URL,
    PostSelectedMembersToPartner_URL,
} = ApiURLConstants;

export const partners = {
    getPartner: (partnerID: number): Promise<any> => {
        return axios.get(`${GetPartner_URL}?partnerId=${partnerID}`)
            .then(responseBody);
    },

    getPartners: (params: any): Promise<any> => {
        return axios.post(`${GetPartners_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    postPartner: (params: AddPartnerModel): Promise<any> => {
        return axios
            .post(`${PostAddPartner_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    putPartner: (params: UpdatePartnerRequestModel): Promise<any> => {
        return axios
            .put(`${PutUpdatePartner_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    deletePartner: (partnerID: number): Promise<any> => {
        return axios.delete(`${DeletePartner_URL}?partnerId=${partnerID}`)
            .then(responseBody)
    },

    postSelectedMembersToPartner: (params: AddPartnerMemberModel): Promise<any> => {
        return axios
            .post(PostSelectedMembersToPartner_URL, params, jsonConfig)
            .then(responseBody);
    },

    deleteSelectedMembersFromPartner: (params: DeletePartnerMembersRequestModel): Promise<any> => {
        return axios.delete(`${DeleteSelectedMembersFromPartner_URL}`, { data: params })
            .then(responseBody)
    }
}