import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import {
     GetMarginForQuoteModel,
     GetMarginForQuoteReponseModel,
} from '../../../../../Models/Jobs/EditJob';

export const usePutGetMarginForQuote = (
     options?: any
): UseMutationResult<number, any, GetMarginForQuoteModel> => {
     const usePutGetMarginForQuote = async (payload: GetMarginForQuoteModel) => {
          if(Number(payload.quoteAmount) !== 0){
               const result = await agent.jobs.putGetMarginForQuote(payload);
               return result;
          }
     };

     return useMutation(usePutGetMarginForQuote);
};
