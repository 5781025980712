import { Tooltip } from '@mui/material';
import { DataGrid,GridColDef,GridColumnHeaderParams,GridRenderCellParams,GridSortModel } from '@mui/x-data-grid';
import CSS from 'csstype';
import React from 'react';
import { FailIndicatorIcon,SuccessIndicatorIcon } from '../../../../../Assets/Images';
import { CellRenderer,HoverableLink } from '../../../../../Components/Formik';
import { ControlledPagination } from '../../../../../Components/Pagination/Pagination';
import { GridContainer } from '../../../../../Components/UI';
import { TaskResponseModel } from '../../../../../Models/Tasks/Response';
import { TaskGridKeys,TaskItemModel } from '../../../../../Models/Tasks/TasksViewModel';
import { RouteConstants } from '../../../../../Routes/Constants';
import { GridProps,Sorter } from '../../../../../Types';
import { getSortModelFromSorter, getTaskDataFieldValue } from '../../../../../Utils';
import { TaskTypeOptions } from '../Constants';
import { TaskType } from '../../../../../Utils/Enums/TaskType';

const GridView: React.FC<TaskGridViewProps> = (props) => {
     const { data, onPageChange, onPageSizeChange, onDelete, onSort } = props;

     const {} = CellRenderer;

     const columns: GridColDef[] = [
          {
               field: TaskGridKeys.ID,
               headerName: 'ID',
               renderHeader: () => <div className='grid-custom-header'>Task ID</div>,
               renderCell: (params: GridRenderCellParams) => {
                    return (
                         <HoverableLink to={`${RouteConstants.EditTaskRoute}/${params.value}`}>
                              <span>{params.value}</span>
                         </HoverableLink>
                    );
               },
               flex: 3,
          },
          {
               field: TaskGridKeys.Type,
               description: 'Task Type',
               headerName: 'Task Type',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.TaskDataFieldValues,
               description: 'Additional Cost Amount',
               headerName: 'Task Data',
               renderCell: (params: GridRenderCellParams) => {
                    const isAdditionalCostsJob = params.row[TaskGridKeys.Type] === (TaskTypeOptions.find(o => o.value === TaskType.JobAdditionalCosts))?.label;
                    if (isAdditionalCostsJob && params.value) {
                      const costs = getTaskDataFieldValue(TaskType.JobAdditionalCosts, params.value);
                      return (
                        <Tooltip title="Additional Cost Amount">
                          <div>{costs}</div>
                        </Tooltip>
                      );
                    }
               },
               flex: 6,
          },             
          {
               field: TaskGridKeys.AssignedTo,
               description: 'Assigned To',
               headerName: 'Assigned To',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.Created,
               description: 'Created',
               headerName: 'Created',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.Due,
               description: 'Due',
               headerName: 'Due',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.Status,
               valueGetter: (params) => {
                    return params.row.TaskStatus;
               },
               description: 'Status',
               headerName: 'Status',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.StatusChanged,
               description: 'Status Changed',
               headerName: 'Status Changed',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.OpenReason,
               valueGetter: (params) => {
                    return params.row.OpenReason;
               },
               description: 'Open Reason',
               headerName: 'Open Reason',
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.LastActivityBy,
               description: 'Last Activity By',
               renderHeader: () => <div className='grid-custom-header'>Last Activity By</div>,
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.LastActivityUpdated,
               description: 'Last Activity Updated',
               renderHeader: (params: GridColumnHeaderParams) => <div className='grid-custom-header'>Last Activity Updated</div>,
               renderCell: (params: GridRenderCellParams) => {
                    return <div>{params.value}</div>;
               },
               flex: 6,
          },
          {
               field: TaskGridKeys.LastActivityComments,
               description: 'Last Activity Comments',
               renderHeader: (params: GridColumnHeaderParams) => <div className='grid-custom-header'>Last Activity Comments</div>,
               renderCell: (params: GridRenderCellParams) => {
                    return   <div style={cellWrapStyle}>{params.value}</div>
               },
               flex: 10,
          },
          {
               field: TaskGridKeys.InProgressActivity,
               description: 'In Progress',
               renderHeader: (params: GridColumnHeaderParams) => <div className='grid-custom-header'>In Progress</div>,
               renderCell: (params: GridRenderCellParams) => {
                    return (
                         <div>
                              {params.value ? (
                                   <Tooltip title='In progress'>
                                        <img alt='Yes' style={cellImageStyle} src={SuccessIndicatorIcon}></img>
                                   </Tooltip>
                              ) : (
                                   <Tooltip title='Not in progress'>
                                        <img alt='No' style={cellImageStyle} src={FailIndicatorIcon}></img>
                                   </Tooltip>
                              )}
                         </div>
                    );
               },
               flex: 3,
          },
     ];

     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     if (data) {
          const { Page, TableSorter } = data ?? {};
          const { CurrentPage, Items, TotalItems, ItemsPerPage, TotalPages } = Page ?? {};

          const CustomGridToolbar: React.FC = (): JSX.Element => {
               return (
                    <ControlledPagination
                         itemsCount={TotalItems}
                         totalPages={TotalPages}
                         currentPage={CurrentPage}
                         pageSize={ItemsPerPage}
                         onPageChange={handlePageChange}
                         onRowsPerPageChange={handleRowsPerPageChange}
                    ></ControlledPagination>
               );
          };

          return (
               <div>
                    {Page && (
                         <GridContainer id='id_grid_view_tasks'>
                              <DataGrid
                                   sortingOrder={['desc', 'asc']}
                                   getRowId={(row: TaskItemModel) => row.TaskId}
                                   rows={Items}
                                   autoHeight
                                   disableColumnMenu={true}
                                   columns={columns}
                                   components={{
                                        Toolbar: CustomGridToolbar,
                                        Footer: CustomGridToolbar,
                                   }}
                                   disableExtendRowFullWidth={true}
                                   sortingMode='server'
                                   sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                                   onSortModelChange={handleSort}
                              />
                         </GridContainer>
                    )}
               </div>
          );
     }
     return null;
};

export default GridView;

interface TaskGridViewProps extends GridProps<TaskResponseModel> {}

const cellImageStyle: CSS.Properties = { height: '25px', width: '25px' };

const cellWrapStyle: CSS.Properties = { whiteSpace: 'normal', wordWrap: 'break-word'}
