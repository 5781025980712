import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { IDeletePriceFromPriceGroup } from '../../../../../Models/PriceGroup';

export const useDeletePriceFromPriceGroup = (
     options?: any
): UseMutationResult<any, any, IDeletePriceFromPriceGroup> => {
     const deletePriceFromPriceGroup = (params: IDeletePriceFromPriceGroup) => {
          return agent.groups.deletePriceFromPriceGroup(params);
     };

     return useMutation(deletePriceFromPriceGroup);
};
