import agent from '../../../../../../Axios/Agent';
import {
     CustomerSetDropdownResponseModel,
     CustomerSetKeys,
     LanguageKeys,
     LanguagesDropdownResponseModel,
} from '../../../../../../Models/Members/AddMember';
import { isSuccessListResponse, mapResultsToOptionTypeList } from '../../../../../../Utils/Helper';

export const getCustomerSetForDropdown = () => {
     return agent.options
          .getCustomerSetForDropdown()
          .then((response: CustomerSetDropdownResponseModel) => {
               if (isSuccessListResponse(response)) {
                    const results = mapResultsToOptionTypeList(response?.Results, {
                         labelKeyString: CustomerSetKeys.Name,
                         valueKeyString: CustomerSetKeys.CustomerSetID,
                    });
                    results.splice(0, 0, { value: '', label: 'No Customer Set' });
                    return results;
               }
          });
};

export const getGroupsForDropdown = () => {
     return agent.groups.getGroupsForDropdown().then((results) => {
          results.splice(0, 0, { value: '', label: 'No Group Membership' });
          return results;
     });
};

export const getLanguagesForDropdown = () => {
     return agent.options
          .getLanguagesForDropdown()
          .then((response: LanguagesDropdownResponseModel) => {
               if (isSuccessListResponse(response)) {
                    const results = mapResultsToOptionTypeList(response?.Results, {
                         labelKeyString: LanguageKeys.Name,
                         valueKeyString: LanguageKeys.Id,
                    });
                    return results;
               }
          });
};
