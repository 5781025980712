import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Alert, Loading, renderHeader, useAlert } from '../../../../../Components';
import { GetVehiclesRequest, VehicleItemModelKeys, VehiclesFilter } from '../../../../../Models/System/Vehicles';
import { CommonRequestParam, Pager } from '../../../../../Types';
import { getSorterFromSortModel, Page } from '../../../../../Utils';
import { SqlStringMatchType } from '../../../../../Utils/Enums';
import FilterBox from './FilterBox';
import VehiclesGridView from './Grid';
import { useGetVehicles, useDeleteVehicle } from './Hooks';
import { get } from 'lodash';
import MovexResponseError from '../../../../../Axios/MovexResponseError';

const ViewVehicles: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<VehiclesFilter>({
    SqlStringMatchType: SqlStringMatchType.Contains,
  });
  const [pager, setPager] = useState<Pager>({
    CurrentPage: Page.CurrentPage,
    ItemsPerPage: Page.PageSize,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: VehicleItemModelKeys.Id,
      sort: 'asc',
    },
  ]);

  const getParams = () => {
    const params: GetVehiclesRequest = {
      Pager: pager,
      Filter: filter,
      Sorter: getSorterFromSortModel(sortModel),
    };
    return params;
  };

  const handleSearch = (filter: VehiclesFilter) => {
    setFilter(filter);
  };

  const handleClearSearch = () => {
    setFilter({});
  };
  const pageChangeHandler = (currentPage: number) => {
    setPager({ ...pager, CurrentPage: currentPage });
  };
  const pageSizeChangeHandler = (itemsPerPage: number) => {
    setPager({ ...pager, ItemsPerPage: itemsPerPage, CurrentPage: 1 });
  };
  const onSort = (sortModel: GridSortModel) => {
    setPager({ ...pager, CurrentPage: 1 });
    setSortModel(sortModel);
  };

  /** Custom Hook */
  const { alert, setAlert } = useAlert();

  /** Query Hooks */
  const {
    data: vehiclesResponse,
    isLoading: isGetVehiclesLoading,
    refetch : refetchVehicles,
    error: getVehiclesError,
  } = useGetVehicles(getParams());

  const isDataLoaded = vehiclesResponse !== null;

  /** useEffects */
  useEffect(() => {
    refetchVehicles();
  }, [filter, pager, sortModel]);

  useEffect(() => {
    setIsLoading(isGetVehiclesLoading);
  }, [isGetVehiclesLoading]);

  useEffect(() => {
    if (getVehiclesError) {
      setAlert({
        ...alert,
        show: true,
        header: getVehiclesError?.AdditionalInformation,
        body: getVehiclesError?.Description,
      });
    }
  }, [getVehiclesError]);

  return (
    <div>
      {renderHeader('Vehicles')}
      <div className="container-fluid">
        <Alert {...alert} />
        <FilterBox onSearch={handleSearch} onClearSearch={handleClearSearch} />
        <Loading loading={isLoading} />
        {(isDataLoaded && vehiclesResponse) && (
          <VehiclesGridView
            data={vehiclesResponse}
            onSort={onSort}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ViewVehicles;
