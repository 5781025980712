import { useMutation, UseMutationResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { CreateAdditionalCostsTaskRequest } from '../../../../../Models/Tasks/CreateAdditionalCostTaskRequest';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../Types';

export const usePostAddAdditionalCostsTask = (
     options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, CreateAdditionalCostsTaskRequest> => {
     const addTask = (payload: CreateAdditionalCostsTaskRequest) => {
          return adminTasks.postAddAdditionalCostsTask(payload);
     };

     return useMutation(addTask);
};
