import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { AddMemberRequestModel } from '../../../../../../../Models/Members/AddMember';
import { EntityCreatedOrUpdatedViewModel } from '../../../../../../../Types';

export const usePostAddMember = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  any,
  AddMemberRequestModel
> => {
  const addMember = (payload: AddMemberRequestModel) => {
    return agent.members.addMember(payload);
  };

  return useMutation(addMember);
};
