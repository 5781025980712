export type Language = {
    Id: number,
    Name: string,
    Code: string,
}

export const LanguageKeys = {
    Id: 'Id',
    Name: 'Name',
    Code: 'Code'
}