import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { FileModelRequestForSystemTasksCSV, FileModelRequest } from '../../../../../Models/Tasks';

export const usePostUploadMemberCodaReferencesFile = (
    options?: any
): UseMutationResult<FileModelRequestForSystemTasksCSV, any, FileModelRequest> => {

    const uploadMemberCodaReferenceFile = (payload: FileModelRequest) => {
        return agent.tasks.uploadUpdateMemberCodaReferenceFile(payload)
    }

    return useMutation(uploadMemberCodaReferenceFile);
}
