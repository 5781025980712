import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { PausePaymentModel } from '../../../../../Models/Jobs';

export const usePutPausePayment = (
     options?: any
): UseMutationResult<any, any, PausePaymentModel> => {
     const putPausePayment = (params: PausePaymentModel) => {
          return agent.payments.putPausePayment(params);
     };

     return useMutation(putPausePayment);
};
