import { Action, Reducer } from "redux";
import { MemberModelKeys } from "../../../Models/Members";
import { NewMembersActionTypes, NewMembersState } from "./Types";

const unloadedState: NewMembersState = {
    newMembers: {
        Totals: 0,
        Page: {
            Context: null,
            Items: [],
            CurrentPage: 1,
            ItemsPerPage: 0,
            TotalItems: 0,
            TotalPages: 0
        },
        TableSorter: {
            Column: MemberModelKeys.SignupDate,
            Ascending: true
        }
    },
    isApiLoading: true,
};

export const reducer: Reducer<NewMembersState> = (
    state: NewMembersState | undefined,
    incomingAction: Action
): NewMembersState => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as NewMembersActionTypes;
    switch (action.type) {
        case "REQUEST_NEW_MEMBERS":
            return {
                ...state, isApiLoading: true
            }
        case "RECEIVE_NEW_MEMBERS":
            return {
                ...state,
                newMembers: action.payload,
                isApiLoading: false
            }

        default:
            return state;
    }
};
