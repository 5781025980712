import { Action, Reducer } from "redux";
import { UserSessionActionTypes, UserSessionState } from "./Types";

const unloadedState: UserSessionState = {
    isApiLoading: false,
    session: undefined,
    lastRefreshedAt: new Date(),
    error: undefined,
};

export const reducer: Reducer<UserSessionState> = (
    state: UserSessionState | undefined,
    incomingAction: Action
): UserSessionState => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as UserSessionActionTypes;
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...state,
                isApiLoading: true,
                error: undefined,
            }
        case "RECEIVE_LOGIN":
            return {
                ...state,
                session: action.payload,
                isApiLoading: false,
                error: undefined,
            }
        case "RECEIVE_LOGIN_ERROR":
            return {
                ...state,
                isApiLoading: false,
                error: action.payload,
                session: undefined,
            };
        case "REQUEST_REFRESH_SESSION":
            return {
                ...state,
                isApiLoading: true,
            };
        case "RECEIVE_REFRESH_SESSION":
            let session = state.session;
            if (session) {
                session.Access_token = action.payload.Access_token;
                session.Refresh_token = action.payload.Refresh_token;
                session.Expires_in = action.payload.Expires_in;
            }
            return {
                ...state,
                session: session,
                isApiLoading: false,
            };
        case "RECEIVE_REFRESH_SESSION_ERROR":
            return {
                ...state,
                isApiLoading: false,
                session: undefined,
                error: action.payload,
            };
        case "REQUEST_LOGOUT_SESSION":
            return {
                ...state,
                isApiLoading: true,
            };
        case "RECEIVE_LOGOUT_SESSION":
            return {
                ...state,
                isApiLoading: false,
                session: undefined,
                error: undefined,
            };
        case "RECEIVE_LOGOUT_ERROR": {
            return {
                ...state,
                isApiLoading: false,
                session: undefined,
                error: action.payload
            };
        }

        default:
            return state;
    }
};
