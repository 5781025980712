import { Field } from 'formik';
import React from 'react';

export const Radio = ({ ...props }): JSX.Element => {
  let { radioId, name, label, isInline, ...rest } = props;
  const isInlineClass = isInline ? 'form-check-inline' : '';
  let className = `form-check ${isInlineClass}`;

  const radioElement = (
    <React.Fragment>
      <Field name={name} {...rest}>
        {({
          field: { name },
          meta: { touched, error },
        }: {
          field: { name: string };
          meta: { touched: boolean; error: any };
        }) => {
          return (
            <React.Fragment>
              <input
                className="form-check-input"
                type="radio"
                id={radioId}
                name={name}
                {...rest}
              />
              <label className="form-check-label" htmlFor={radioId}>
                {label}
              </label>
            </React.Fragment>
          );
        }}
      </Field>
    </React.Fragment>
  );

  return <div className={className}>{radioElement}</div>;
};

export default Radio;
