import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Logo } from '../../../Assets/Images';
import { FormControlKeys } from '../../../Components/FormControls/Types';
import FormikControl from '../../../Components/Formik/FormikControl';
import {
  BoxContainer,
  FieldError,
  LineBreak,
} from '../../../Components/Formik/StyledComponents';
import { Loading } from '../../../Components';
import { authActionCreators } from '../../../Store/Session/Action';
import { LoginApiParam } from '../../../Store/Session/Types';
import { ApplicationState } from '../../../Store/Types';
import { LoginFormKeys } from './Types';
import { RouteConstants } from '../../../Routes';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useAppDispatch } from '../../../Store/hooks';

const validationSchema = Yup.object({
  [LoginFormKeys.Username]: Yup.string().required(`Username is required.`),
  [LoginFormKeys.Password]: Yup.string().required(`Password is required.`),
  [LoginFormKeys.RememberMe]: Yup.bool(),
});

const Login: React.FC<any> = (props): JSX.Element => {
  const initialValues: LoginApiParam = {
    Username: '',
    Password: '',
    RememberMe: false,
  };
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sessionState = useSelector(
    (state: ApplicationState) => state.sessionState!
  );
  const isLoggedIn = sessionState.session !== undefined;
  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch()
  const { isApiLoading } = sessionState;

  const onSubmit = async (payload: LoginApiParam) => {
    const params: LoginApiParam = {
      Password: payload.Password,
      RememberMe: payload.RememberMe ?? false,
      Username: payload.Username,
    };
    dispatch(authActionCreators.login(params));
  };

  useEffect(() => {
    if (sessionState.error) {
      setError(sessionState.error.description);
    }
  }, [sessionState]);

  useEffect(() => {
    setIsLoading(isApiLoading);
  }, [isApiLoading]);

  if (isLoggedIn) {
    const { state } = props.location;
    const nextUrl = state
      ? state.from.pathname
      : `${RouteConstants.NewMembers}`;
    return <Redirect to={nextUrl} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid }) => {
        return (
          <BoxContainer>
            <Loading loading={isLoading} />
            <LineBreak />
            <LoginFormContainer
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <img alt="Movex Logo" src={Logo} />
              <LineBreak />
              <LineBreak />
              <LoginFormHeader className="form-signin-heading">
                Please sign in
              </LoginFormHeader>
              <FieldError>{error ? error : ''}</FieldError>
              <FormikControl
                name={LoginFormKeys.Username}
                control={FormControlKeys.PlainInput}
                label="Username"
              />
              <FormikControl
                name={LoginFormKeys.Password}
                control={FormControlKeys.PlainInput}
                type="password"
                label="Password"
                autoomplete="off"
              />
              <FormikControl
                name={LoginFormKeys.RememberMe}
                control={FormControlKeys.Checkbox}
                label="Remember Me"
              />
              <LineBreak />
              <LineBreak />
              <FormikControl
                name="login-button"
                type="submit"
                control={FormControlKeys.Button}
                label="Sign in"
                disabled={!isValid}
              />
            </LoginFormContainer>
          </BoxContainer>
        );
      }}
    </Formik>
  );
};

export default Login;

export const LoginFormContainer = styled.form`
  max-width: 350px;
  padding: 19px 29px 29px;
  margin: 0 auto 20px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

export const LoginFormHeader = styled.h6`
  margin-bottom: 10px;
`;
