import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export const TypeaheadMultiInput = ({ ...props }) => {
  const {
    name,
    label,
    options,
    onTypeaheadInput,
    selected = [],
    onSelect,
    showRequired,
    ...rest
  } = props;

  return (
    <div className="mb-3 form-group">
      <label htmlFor={name} className="form-label fw-600">
        {label}
      </label>
      {showRequired && (
        <span className="asterisk" title="Required">
          *
        </span>
      )}
      <Typeahead
        labelKey={'Label' as any}
        id="basic-example"
        selected={selected}
        multiple
        onInputChange={(value) => {
          onTypeaheadInput(value);
        }}
        onChange={(value) => {
          onSelect(value);
        }}
        options={
          options && options.length && options !== undefined && options !== null
            ? options
            : []
        }
        {...rest}
      />
    </div>
  );
};

export default TypeaheadMultiInput;
