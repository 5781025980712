import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { MemberIDParamType } from '../../..';
import agent from '../../../../../../../Axios/Agent';
import { MembersFavouriteResponseModel } from '../../../../../../../Models/Members/EditMember';

export const useGetMemberFavourites = (
  options?: any
): UseQueryResult<
  MembersFavouriteResponseModel,
  any
> => {
  const { memberID } = useParams<MemberIDParamType>();

  const getMemberFavorites = ({ queryKey }: any) => {
    const [_, memberID] = queryKey;
    return agent.members.getMemberFavourites(memberID)
  };

  return useQuery(['GetMemberFavourites', memberID], getMemberFavorites, {
    ...options,
    refetchOnWindowFocus: false,
  });
};
