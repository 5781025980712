import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../../Axios/Agent';
import { DropdownMember } from '../../../../../../../Models/Members/EditMember/MemberBlocks';
import { MemberType } from '../../../../../../../Utils/Enums';

export const useGetMembersForDropdown = (memberType?: MemberType,
  options?: any
): UseQueryResult<DropdownMember[], any> => {

  const getMembersForDropdown = ({ queryKey }: any) => {
    if(memberType){
      return agent.members.getMembersForDropdown(memberType).then(response => response.MemberDetails)
    }
    return agent.members.getMembersForDropdown().then(response => response.MemberDetails)
  };

  return useQuery(['GetMembersForDropdown', memberType], getMembersForDropdown, {
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
    ...options,
  });
};
