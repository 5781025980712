import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddPartnerMemberModel, AddPartnerMemberResponseViewModel } from '../../../../../Models/Members/EditMember';
import { camelize } from '../../../../../Utils';

export const usePostAddSelectedMemberToPartner = (
  options?: any
): UseMutationResult<
  AddPartnerMemberResponseViewModel, 
  any, 
  AddPartnerMemberModel
> => {
  
  const postAddMemberToPartner = (payload: AddPartnerMemberModel) => {
    return agent.partners.postSelectedMembersToPartner(payload).then((response => camelize(response)));
  };

  return useMutation(postAddMemberToPartner);
};
