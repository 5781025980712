import { Tooltip } from '@mui/material';
import CSS from 'csstype';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { FailIndicatorIcon, SuccessIndicatorIcon } from '../../../../../Assets/Images';
import { Button } from '../../../../../Components/FormControls';
import { CheckBox } from '../../../../../Components/FormControls/SimpleCheckbox';
import { FieldSetHeader, HoverableLink } from '../../../../../Components/Formik';
import { BillingModel, Charge, PaymentModel } from '../../../../../Models/Billing/AddBilling';
import { getFormattedDateWithYear } from '../../../../../Utils';
import { StyledTd } from '../../BillsStyledComponents';
import {
     ShowMarkAsPaid,
     V3a_AmountTotal,
     V3a_GrandTotal,
     V3a_RequestingMemberAmountTotal,
     V3a_RequestingMemberFeeTotal,
     V3a_RequestingMemberMonthlyFee,
     V3a_RequestingMemberPayAsYouGoFeeTotal,
     V3a_VAT,
} from '../billingFunctions';
import { RouteConstants } from '../../../../../Routes';

const cellImageStyle: CSS.Properties = { height: '25px', width: '25px' };

const BillVersion3a: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { bill, payments, onShowModule } = props;

     /** useState */
     const [isWaitingData, setIsWaitingData] = useState<boolean>(false);
     const [paymentList, setPaymentList] = useState<PaymentModel[]>(payments);
     const [monthlyFeeChecked, setMonthlyFeeChecked] = useState<boolean>(false);

     /** Methods */
     const formik = useFormik({
          initialValues: {},
          onSubmit: () => {},
          enableReinitialize: true,
     });
     const { values, setFieldValue } = formik;

     const handleAllChecked = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          let paymentListToUpdate = paymentList;
          paymentListToUpdate.forEach((payment) => {
               if (!payment.MovexPaidDate) {
                    payment.isChecked = event.currentTarget.checked;
               }
          });
          setMonthlyFeeChecked(event.currentTarget.checked);
          setPaymentList([...paymentListToUpdate]);
     };

     const handleCheckChildElement = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          let paymentListToUpdate = paymentList;

          paymentListToUpdate.forEach((payment) => {
               if (payment.Id === Number(event.currentTarget.value)) {
                    payment.isChecked = event.currentTarget.checked;
               }
          });
          setPaymentList([...paymentListToUpdate]);
     };

     const handleCheckMonthlyFee = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          setMonthlyFeeChecked((prevCheckedValue) => !prevCheckedValue);
     };

     const handleShowModule = () => {
          const jobIDs = paymentList
               .filter((item) => {
                    if (item.isChecked) {
                         return true;
                    } else {
                         return false;
                    }
               })
               .map((payment) => {
                    return payment.JobId;
               });

          onShowModule(bill.Id, jobIDs, monthlyFeeChecked, false);
     };

     return (
          <>
               <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>Payments</FieldSetHeader>
                              <table
                                   id='id_table_bill_for_payments'
                                   className='table table-bordered table-condensed table-striped'
                              >
                                   <thead>
                                        <tr>
                                             <td></td>
                                             <td>Supplier</td>
                                             <td>Job ID / Invoiced</td>
                                             <td>Created Date</td>
                                             <td>Job Cost</td>
                                             <td>Surcharge</td>
                                             <td>PAYG</td>
                                             <td>Total</td>
                                             <td>
                                                  Movex Paid?
                                                  <br />
                                                  {ShowMarkAsPaid(bill, payments) ? (
                                                       <input type='checkbox' onClick={handleAllChecked} value='checkedAll' />
                                                  ) : (
                                                       'false'
                                                  )}
                                             </td>
                                        </tr>
                                   </thead>
                                   {isWaitingData && <tr>Loading...</tr>}
                                   {!isWaitingData &&
                                        payments?.map((data: PaymentModel, index: number) => {
                                             const {
                                                  SupplyingMemberID,
                                                  SupplyingMemberUserName,
                                                  JobId,
                                                  CreatedDate,
                                                  QuoteAmount,
                                                  RequestingMemberInvoiceFileName,
                                                  RequestingMemberFee,
                                                  RequestingMemberPayAsYouGoFee,
                                                  RequestingMemberAmount,
                                                  MovexPaidDate,
                                             } = data;
                                             return (
                                                  <tbody>
                                                       <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                 <HoverableLink to={`${RouteConstants.EditMember}/${SupplyingMemberID}`}>
                                                                      {SupplyingMemberUserName}
                                                                 </HoverableLink>
                                                            </td>
                                                            <td>
                                                                 <HoverableLink to={`${RouteConstants.ViewJobById}/${JobId}`}>
                                                                      {JobId}
                                                                 </HoverableLink>
                                                                 {RequestingMemberInvoiceFileName && ' - Invoiced'}
                                                            </td>
                                                            <td>{CreatedDate && getFormattedDateWithYear(CreatedDate)}</td>
                                                            <td>£{QuoteAmount && QuoteAmount.toFixed(2)}</td>
                                                            <td>
                                                                 {RequestingMemberFee && RequestingMemberFee > 0
                                                                      ? `£` + RequestingMemberFee.toFixed(2)
                                                                      : ' - '}
                                                            </td>
                                                            <td>
                                                                 {!RequestingMemberPayAsYouGoFee
                                                                      ? ' - '
                                                                      : `£` + RequestingMemberPayAsYouGoFee.toFixed(2)}
                                                            </td>
                                                            <td>{RequestingMemberAmount && RequestingMemberAmount.toFixed(2)}</td>
                                                            <td>
                                                                 {MovexPaidDate && (
                                                                      <>
                                                                           <Tooltip title='Supplier Paid Date'>
                                                                                <img
                                                                                     alt='SupplierPaidDate'
                                                                                     style={cellImageStyle}
                                                                                     src={SuccessIndicatorIcon}
                                                                                ></img>
                                                                           </Tooltip>
                                                                           {getFormattedDateWithYear(MovexPaidDate)}
                                                                      </>
                                                                 )}
                                                                 {!MovexPaidDate && (
                                                                      <CheckBox
                                                                           handleCheckChieldElement={handleCheckChildElement}
                                                                           {...data}
                                                                      />
                                                                 )}
                                                            </td>
                                                       </tr>
                                                  </tbody>
                                             );
                                        })}

                                   {payments?.length === 0 && (
                                        <tbody>
                                             <tr>
                                                  <td colSpan={8} style={{ textAlign: 'center' }}>
                                                       No charges found!
                                                  </td>
                                             </tr>
                                        </tbody>
                                   )}

                                   {payments?.length > 0 && (
                                        <tfoot>
                                             <tr>
                                                  <StyledTd colSpan={4}>Total</StyledTd>
                                                  <td>£ {V3a_AmountTotal(payments)}</td>
                                                  <td>£ {V3a_RequestingMemberFeeTotal(payments)}</td>
                                                  <td>£ {V3a_RequestingMemberPayAsYouGoFeeTotal(payments)}</td>
                                                  <td>£ {V3a_RequestingMemberAmountTotal(payments)}</td>
                                                  <td></td>
                                             </tr>
                                             <tr>
                                                  <StyledTd colSpan={7}>Monthly Fee</StyledTd>
                                                  <td>£ {V3a_RequestingMemberMonthlyFee(payments, bill)}</td>
                                                  <td>
                                                       {bill.RequestingMemberMonthlyFeePaidDate && (
                                                            <>
                                                                 <Tooltip title='Supplier Paid Date'>
                                                                      <img
                                                                           alt='SupplyingMemberMonthlyFeePaidDate'
                                                                           style={cellImageStyle}
                                                                           src={SuccessIndicatorIcon}
                                                                      ></img>
                                                                 </Tooltip>
                                                                 {getFormattedDateWithYear(
                                                                      bill.RequestingMemberMonthlyFeePaidDate
                                                                 )}
                                                            </>
                                                       )}
                                                       {!bill.RequestingMemberMonthlyFeePaidDate && (
                                                            <>
                                                                 {ShowMarkAsPaid(bill, payments) ? (
                                                                      <input
                                                                           key={'monthlyFeeCheckbox'}
                                                                           onClick={handleCheckMonthlyFee}
                                                                           type='checkbox'
                                                                           checked={monthlyFeeChecked}
                                                                      />
                                                                 ) : (
                                                                      <Tooltip title='Supplier Paid Date'>
                                                                           <img
                                                                                alt='SupplierPaidDate'
                                                                                style={cellImageStyle}
                                                                                src={FailIndicatorIcon}
                                                                           ></img>
                                                                      </Tooltip>
                                                                 )}
                                                            </>
                                                       )}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <StyledTd colSpan={7}>VAT</StyledTd>
                                                  <td>{V3a_VAT(payments, bill)}</td>
                                                  <td></td>
                                             </tr>
                                             <tr>
                                                  <StyledTd colSpan={7}>Grand Total</StyledTd>
                                                  <td>£ {V3a_GrandTotal(payments, bill)}</td>
                                                  {payments?.length !== 0 && (
                                                       <td>
                                                            <Button
                                                                 label='MarkAsPaid'
                                                                 style={{ margin: 10 }}
                                                                 onClick={handleShowModule}
                                                            ></Button>
                                                       </td>
                                                  )}
                                             </tr>
                                        </tfoot>
                                   )}
                              </table>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

type GeneralFormProps = {
     payments: PaymentModel[];
     bill: BillingModel;
     charges: Charge[];
     onShowModule: Function;
};

export default BillVersion3a;
