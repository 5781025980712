
export interface PageModel {
    Id: number;
    FirstLine: string;
    IsReserved: boolean;
    Path: string;
    Name: string;
}

export const PageGridKeys = {
    Name: "Name",
    IsReserved: "IsReserved"
}
