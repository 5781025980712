import axios from 'axios';
import { Configuration } from '../../../Config/Config';
import { RefreshApiResponse } from '../../../Store/Session/Types';
import { jsonConfig, responseBody, _URLencodedConfig } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';

const {
    PostUserLogin_URL,
    GetUserLogout_URL,
    Refresh_URL,
    GetServerTime_URL,
    GetConfig_URL,
} = ApiURLConstants;

export const session = {
    login: (params: any): Promise<any> => {
        return axios
            .post(`${PostUserLogin_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    logout: (): Promise<any> => {
        return axios.get(`${GetUserLogout_URL}`);
    },

    refreshSession: (params: any): Promise<RefreshApiResponse> => {
        return axios
            .post(
                `${Refresh_URL}`,
                new URLSearchParams(params).toString(),
                _URLencodedConfig
            )
            .then(responseBody);
    },

    getServerTime: (): Promise<any> => {
        return axios.get(`${GetServerTime_URL}`).then(responseBody);
    },

    getConfig: (): Promise<Configuration> => {
        return axios.get(`${GetConfig_URL}`).then(responseBody);
    },
};