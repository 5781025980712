import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const RowWrapper = styled(Row)`
     border-top: 1px solid #dee2e6;
     justify-content: flex-end;
     padding-top: 1.1rem;
     padding-bottom: 0.5rem;
     padding-right: 0.8rem;
     margin-left: -14px;
     margin-right: -14px;
`;

export const ColWrapper = styled(Col)`
     max-width: 60px;
     margin-right: 16px;
`;

export const ErrorContainer = styled.div`
  color: red;
`;
