import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { adminTasks } from '../../../../../Axios/Screens/Task';
import { AssignTaskToUserResponse } from '../../../../../Models/Tasks/AssignTaskToUserResponse';

export interface UpdateTaskDetails {
     TaskId: number,
     NewAssignedUserId?: number 
}

export const usePutAssignTask = (
     options?: any
): UseMutationResult<AssignTaskToUserResponse, any, UpdateTaskDetails> => {
     const assignTaskDetails = (payload: UpdateTaskDetails) => {
          return adminTasks.putAssignTaskDetails(payload);
     };

     return useMutation(assignTaskDetails);
};
