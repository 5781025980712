import { AdminJobStatus, PaymentStatus } from "../../../Utils/Enums";

export interface PaymentsGridFilter {
    DueDateFrom?: string,
    DueDateTo?: string,
    JobID?: number,
    JobStatus?: AdminJobStatus | null,
    PaymentID?: number,
    PaymentStatus?: PaymentStatus | null,
    RequestDateFrom?: Date,
    RequestDateTo?: Date,
    Supplier?: string,
    User?: string,
}

export const PaymentsGridFilterKeys = {
    DueDateFrom: "DueDateFrom",
    DueDateTo: "DueDateTo",
    JobID: "JobID",
    JobStatus: "JobStatus",
    PaymentID: "PaymentID",
    PaymentStatus: "PaymentStatus",
    RequestDateFrom: "RequestDateFrom",
    RequestDateTo: "RequestDateTo",
    Supplier: "Supplier",
    User: "User",
}