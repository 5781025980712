import { useQuery, UseQueryResult } from "react-query";
import agent from '../../../../../Axios/Agent';
import { PricesRequestParam } from "../../../../../Models/Prices/ViewPrices/Request";

export const useGetPrices = (
  payload: PricesRequestParam,
  options?: any
): UseQueryResult<any, any> => {
  const getPrices = () => {
    return agent.prices.getPrices(payload);
  };

  return useQuery(["GetPrices", payload], getPrices, {
    ...options,
  });
};
