/** Inbuilt libraries */
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { FormControlKeys } from "../../../../../Components/FormControls";
import CheckBox from "../../../../../Components/FormControls/SimpleCheckbox";
/** Custom Components */
import { FieldSetHeader, HoverableLink } from "../../../../../Components/Formik";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import {
  BillingModel,
  ChargeV2,
  PaymentModel,
} from "../../../../../Models/Billing/AddBilling";
import { getFormattedDateWithYear } from "../../../../../Utils";
import { StyledTd, StyledTdNoCharge, Styledtfoot } from "../../BillsStyledComponents";
import {
  V2_AmountTotal,
  V2_GrandTotal,
  V2_MemberSurchargeTotal,
  V2_MonthlyFee,
  V2_VAT,
} from "../billingFunctions";
import { RouteConstants } from "../../../../../Routes";

const BillVersion2: React.FC<GeneralFormProps> = (props): JSX.Element => {
  const { bill, payments, charges } = props;
  

  /** useState */
  const [chargesCheckable, setChargesCheckable] =
    useState<PaymentModel[]>(payments);
  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

  /** Methods */
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const handleCheckChieldElement = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    let chargesCheckableToUpdate = chargesCheckable;
    chargesCheckableToUpdate.forEach((charge) => {
      if (charge.Id === Number(event.currentTarget.value)) {
        charge.isChecked = event.currentTarget.checked;
      }
    });
    setChargesCheckable([...chargesCheckableToUpdate]);
  };

  const handleShowWarningModule = () => {
    //Warning!
    alert("The ability to delete charges is not currently available.");
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <FieldSetHeader>Charges</FieldSetHeader>
            <table id='id_table_bill_charges' className="table table-bordered table-condensed table-striped">
              <thead>
                <tr>
                  <td></td>
                  <td>Client</td>
                  <td>Job Cost</td>
                  <td>Job ID / Invoiced</td>
                  <td>Charge Date</td>
                  <td>Member Surcharge</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {isWaitingData && <tr>Loading...</tr>}
                {(!isWaitingData && charges) &&
                  charges?.map((data: ChargeV2, index: number) => {
                    const {
                      ID,
                      JobId,
                      ChargeDate,
                      QuoteAmount,
                      InvoiceSent,
                      QuoteMemberSurcharge,
                      RequestingMemberId,
                      RequestingMemberUserName,
                    } = data;
                    return (
                      <tr key={ID}>
                        <td>{index + 1}</td>
                        <td>                                                                 
                          <HoverableLink to={`${RouteConstants.EditMember}/${RequestingMemberId}`}>
                            {RequestingMemberUserName}
                          </HoverableLink>
                        </td>
                        <td>{"£" + QuoteAmount?.toFixed(2)}</td>
                        <td>
                          <HoverableLink to={`${RouteConstants.ViewJobById}/${JobId}`}>
                            {JobId}
                          </HoverableLink>
                          <span>{InvoiceSent && " - Invoiced"}</span>
                        </td>
                        <td>{getFormattedDateWithYear(ChargeDate!)}</td>
                        <td>
                          {QuoteMemberSurcharge === 0
                            ? "-"
                            : "£" + QuoteMemberSurcharge?.toFixed(2)}
                        </td>
                        <td>
                          <CheckBox
                            handleCheckChieldElement={handleCheckChieldElement}
                            {...data}
                          />
                        </td>
                      </tr>
                    );
                  }
                  )}
              </tbody>
              {charges?.length === 0 && (
                <tbody>
                  <tr>
                    <StyledTdNoCharge colSpan={8} >
                      No charges found!
                    </StyledTdNoCharge>
                  </tr>
                </tbody>
              )}
              {charges?.length > 0 && (
                <Styledtfoot
                >
                  <tr>
                    <StyledTd colSpan={2}>
                      Total
                    </StyledTd>
                    <td>£{V2_AmountTotal(charges)}</td>

                    <StyledTd colSpan={2} >
                      Total
                    </StyledTd>
                    <td>£{V2_MemberSurchargeTotal(charges)}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <StyledTd colSpan={5} >
                      Monthly Fee
                    </StyledTd>
                    <td>£{V2_MonthlyFee(bill)}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <StyledTd colSpan={5}>
                      VAT
                    </StyledTd>
                    <td>£{V2_VAT(charges, bill)}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <StyledTd colSpan={5}>
                      Grand Total
                    </StyledTd>
                    <td>£{V2_GrandTotal(charges, bill)}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <StyledTd colSpan={6}></StyledTd>
                    <td>
                      <FormikControl
                        name="Delete"
                        label="Delete"
                        type="submit"
                        onClick={handleShowWarningModule}
                        control={FormControlKeys.Button}
                        size="sm"
                        variant="danger"
                      />
                    </td>
                  </tr>
                </Styledtfoot>
              )}
            </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  payments: PaymentModel[];
  bill: BillingModel;
  charges: ChargeV2[];
};

export default BillVersion2;
