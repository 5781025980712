import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { FormikProvider, useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { number, object } from 'yup';
import { FailIndicatorIcon, SuccessIndicatorIcon } from '../../../../Assets/Images';
import { Alert, ConfirmDialog, ShowPleaseWait, useAlert } from '../../../../Components';
import { useConfirmDialog } from '../../../../Components/ConfirmDialog/useConfirmDialog';
import { FormControlKeys } from '../../../../Components/FormControls/Types';
import { renderServiceTypeCell } from '../../../../Components/Formik';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
     FieldSetContainer,
     FieldSetHeader,
     FlexContainerWithMargin,
     FormContainer,
} from '../../../../Components/Formik/StyledComponents';
import { Action } from '../../../../Components/UI';
import { EditPriceDetails, IDeletePriceFromPriceGroup } from '../../../../Models/PriceGroup';
import { MovexErrorResponse } from '../../../../Types';
import { ConfirmDialogContent } from '../../../../Utils';
import { cellImageStyle } from '../../Billing/AddBill';
import { useAddOrUpdateGroupSavedDetails } from '../../Groups/AddGroup/Hooks';
import { PriceGroupServiceTypeOption } from '../../Members/Constants';
import { useDeletePriceFromPriceGroup, usePostAddPrice, usePostAddPriceToPriceGroup, usePutUpdatePrice } from './Hooks';
import { useGetPricesByTypeAndName } from './Hooks/useGetPricesByTypeAndName';
import { AddPriceToPriceGroupRequest } from './Hooks/usePostAddPriceToPriceGroup';
import { EditTablePricesKeys } from './Types';
import { useGetPricePriceGroups } from './Hooks/useGetPricePriceGroups';

const PricesEditor: React.FC<PricesEditorProps> = ({ prices, refetchPricesList, priceGroupId, priceGroupType }) => {
     const initialPriceSavedDataRow = { Id: 0 };

     /** States */
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [groupPriceSavedDataRow, setGroupPriceSavedDataRow] = useState<EditPriceDetails>(initialPriceSavedDataRow);
     const [groupPriceSavedData, setGroupPriceSavedData] = useState<EditPriceDetails[]>([]);
     const [editSavedDataID, setEditSavedDataID] = useState<number>(0);
     const [editSavedDetails, setEditSavedDetails] = useState<boolean>(false);

     const [showPricesDropdown, setShowPricesDropdown] = useState(false);
     const [fetchedPrices, setFetchedPrices] = useState([]);
     const [currentEditPriceName, setCurrentEditPriceName] = useState('');
     const [debouncedEditPriceName, setDebouncedEditPriceName] = useState('');

     const [getPricePriceGroups, setGetPricePriceGroups] = useState<number>(0);

     const updateDebouncedEditPriceName = useCallback(
          debounce((value: string) => {
               setDebouncedEditPriceName(value);
          }, 1000),
          []
     );

     /* Hooks */
     const { alert, 
          setAlert 
     } = useAlert();

     const { confirmDialogProps, 
          openConfirmDialog, 
          closeConfirmDialog 
     } = useConfirmDialog();

     const {
          isLoading: isAddSavedAddressLoading,
          data: addSavedPricesResponse,
          error: addSavedPricesError,
     } = useAddOrUpdateGroupSavedDetails();

     const {
          refetch: refetchPricePriceGroups,
          data: getPricePriceGroupsResponse,
          isLoading: isGetPricePriceGroupsLoading,
          error: getPricePriceGroupsError,
     } = useGetPricePriceGroups(getPricePriceGroups);

     const { data: getPricesByTypeAndNameResponse 
     } = useGetPricesByTypeAndName(priceGroupType, debouncedEditPriceName);

     const {
          mutate: deletePriceFromPriceGroup,
          isLoading: isdeletePriceFromPriceGroupLoading,
          data: deletePriceFromPriceGroupResponse,
          error: deletePriceFromPriceGroupError,
     } = useDeletePriceFromPriceGroup();

     const { mutate: putUpdatePrice, 
          data: putUpdatePriceResponse, 
          error: putUpdatePriceError 
     } = usePutUpdatePrice();

     const { mutate: postAddPrice, 
          data: postAddPriceResponse, 
          error: postAddPriceError 
     } = usePostAddPrice();

     const {
          mutate: postAddPriceToPriceGroup,
          data: postAddPriceToPriceGroupResponse,
          error: postAddPriceToPriceGroupError,
     } = usePostAddPriceToPriceGroup();

     /** Handlers */
     const handlePriceNameChange = (e: any) => {
          formik.setFieldValue(EditTablePricesKeys.Name, e.target.value);
          setShowPricesDropdown(false);
          setCurrentEditPriceName(e.target.value);
     };

     const handleSelectPrice = async (price: EditPriceDetails) => {
          setGetPricePriceGroups(price.Id);
          setGroupPriceSavedDataRow(price);
          setShowPricesDropdown(false);
     };

     useEffect(() => {
          if(getPricePriceGroupsResponse?.[0]){
               const price = groupPriceSavedDataRow;
               price.Id = 0;
               price.Name = price.Name + " - COPY";
               formik.setFieldValue(EditTablePricesKeys.Name, price.Name);
               setGroupPriceSavedDataRow(price);
          }
          else{
               let payload = {
               PriceGroupID: priceGroupId,
               PriceID: groupPriceSavedDataRow.Id,
          } as AddPriceToPriceGroupRequest;
          postAddPriceToPriceGroup(payload);
     }
     }, [getPricePriceGroupsResponse]);

     const handleAddNewPrice = () => {
          setEditSavedDetails(false);
          setGroupPriceSavedDataRow(initialPriceSavedDataRow);
          formik.resetForm();
          setGroupPriceSavedData([
               ...groupPriceSavedData,
               {
                    Id: 0,
               },
          ]);
     };

     const handleEditPrice = (data: EditPriceDetails) => {
          setGroupPriceSavedDataRow(data);
          setEditSavedDetails(true);
          setEditSavedDataID(data.Id);
     };

     const handleCancelEditPrice = (id: number) => {
          if (id === 0) {
               const data = [...groupPriceSavedData];
               data.pop();
               setGroupPriceSavedData([...data]);
          } else {
               setEditSavedDataID(-1);
          }
     };

     const handleDeletePrice = (id: number) => {
          openConfirmDialog(ConfirmDialogContent.DeletePrice.Title, ConfirmDialogContent.DeletePrice.Content, () => {
               var payload = {
                    groupId: priceGroupId,
                    priceId: id,
               } as IDeletePriceFromPriceGroup;
               deletePriceFromPriceGroup(payload);
               closeConfirmDialog();
          });
     };

     const handleSavePriceSubmit = () => {
          if (
               formik.values.Name &&
               formik.values.InitialDistance &&
               formik.values.InitialPrice &&
               formik.values.PricePerVehiclePerMile
          ) {
               formik.values.Id = formik.values.Id;
               if (!formik.values.Id) postAddPrice(formik.values);
               else putUpdatePrice(formik.values);
               setEditSavedDataID(-1);
               setGroupPriceSavedData((prevData) => {
                    return prevData.map((row) => {
                         if (row.Id === formik.values.Id) {
                              return { ...formik.values };
                         }
                         return row;
                    });
               });
          }
     };

     const dropdownContainerRef = useRef<HTMLDivElement | null>(null);

     const handleClickOutside = (event: MouseEvent) => {
          if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target as Node)) {
               setShowPricesDropdown(false);
          }
     };

     /** Validation Schema */
     const validationSchema = object({
          [EditTablePricesKeys.InitialDistance]: number().required(),
          [EditTablePricesKeys.InitialPrice]: number().required(),
          [EditTablePricesKeys.PricePerVehiclePerMile]: number().required(),
          [EditTablePricesKeys.Type]: number().required(),
     });

     /** Formik Configuration */
     const formik = useFormik({
          initialValues: groupPriceSavedDataRow,
          validationSchema: validationSchema,
          enableReinitialize: true,
          validateOnBlur: false,
          validateOnChange: false,
          onSubmit: (values) => {
               console.log(values);
          },
     });

     /** useEffects */
     useEffect(() => {
          updateDebouncedEditPriceName(currentEditPriceName);
      }, [currentEditPriceName]);
      
     useEffect(() => {
          if (priceGroupId && prices) {
               setGroupPriceSavedData(prices);
          }
     }, [prices]);

     useEffect(() => {
          if (priceGroupId && postAddPriceResponse) {
               let payload = {
                    PriceGroupID: priceGroupId,
                    PriceID: postAddPriceResponse?.Id,
               } as AddPriceToPriceGroupRequest;
               postAddPriceToPriceGroup(payload);
          }
     }, [postAddPriceResponse]);

     useEffect(() => {
          if (priceGroupId) {
               refetchPricesList();
          }
     }, [putUpdatePriceResponse, postAddPriceToPriceGroupResponse, deletePriceFromPriceGroupResponse]);

     useEffect(() => {
          if (addSavedPricesResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    body: addSavedPricesResponse,
               });
          }
     }, [addSavedPricesResponse]);

     useEffect(() => {
          setGroupPriceSavedDataRow({} as EditPriceDetails);
     }, [postAddPriceToPriceGroupResponse]);

     /** useEffect - Error handling */
     useEffect(() => {
          const setAlertBasedOnError = (error: MovexErrorResponse, titlePrefix = '') => {
               if (!error) return;

               const header = error.Subject;
               const body = error.Description + (error.AdditionalInformation ?? '');

               setAlert({
                    ...alert,
                    show: true,
                    header: titlePrefix ? `${titlePrefix} - ${header}` : header,
                    body,
               });
          };

          if (priceGroupId) {
               setAlertBasedOnError(deletePriceFromPriceGroupError);
               setAlertBasedOnError(putUpdatePriceError);
               setAlertBasedOnError(postAddPriceToPriceGroupError, 'PostAddPriceToPriceGroup');
               setAlertBasedOnError(postAddPriceError, 'PostAddPrice');
               setAlertBasedOnError(addSavedPricesError);
               setAlertBasedOnError(getPricePriceGroupsError);
          }
     }, [
          deletePriceFromPriceGroupError,
          putUpdatePriceError,
          postAddPriceToPriceGroupError,
          postAddPriceError,
          addSavedPricesError,
          getPricePriceGroupsError
     ]);

     // useEffect - Show Please Wait Hook
     useEffect(() => {
          setShowPleaseWait(isAddSavedAddressLoading || isdeletePriceFromPriceGroupLoading);
     }, [isAddSavedAddressLoading, isdeletePriceFromPriceGroupLoading]);

     // useEffect - Click Outside Logic Hook
     useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
               document.removeEventListener('mousedown', handleClickOutside);
          };
     }, []);

     // useEffect - Dropdown Logic Hook
     useEffect(() => {
          const hasValidName = debouncedEditPriceName?.length > 2;
          const pricesAvailable = getPricesByTypeAndNameResponse?.Prices ?? [];

          setShowPricesDropdown(hasValidName);
          setFetchedPrices(hasValidName ? pricesAvailable : []);
     }, [debouncedEditPriceName, getPricesByTypeAndNameResponse]);

     return (
          <FormikProvider value={formik}>
               <FlexContainerWithMargin>
                    <FieldSetContainer>
                         <FieldSetHeader>
                              Prices
                              <span title='Add New'>
                                   <AddCircleOutlineIcon className='add-new-icon' onClick={handleAddNewPrice} />
                              </span>
                         </FieldSetHeader>
                         <FormContainer>
                              <Alert {...alert} />
                              <ShowPleaseWait show={showPleaseWait} />
                              <ConfirmDialog {...confirmDialogProps} />
                              <table
                                   id='id_table_prices_editor'
                                   className='group-saved-details table table-condensed table-striped'
                              >
                                   <thead>
                                        <tr>
                                             <td>#</td>
                                             <td>Name</td>
                                             <td>Service Type</td>
                                             <td>Incl. Fuel</td>
                                             <td>Min Veh.</td>
                                             <td>Max Veh.</td>
                                             <td>Min Wei.</td>
                                             <td>Max Wei.</td>
                                             <td>Min Lead</td>
                                             <td>Max Lead</td>
                                             <td>Min Dist.</td>
                                             <td>Max Dist.</td>
                                             <td>Init. Dist.</td>
                                             <td>Init. Price</td>
                                             <td>Price</td>
                                             <td>Min Rate</td>
                                             <td>Max Rate</td>
                                             <Action>Action</Action>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {groupPriceSavedData.map((data: EditPriceDetails, index: number) => {
                                             const {
                                                  Id,
                                                  Name,
                                                  ServiceType,
                                                  IncludesFuel,
                                                  MinVehicles,
                                                  MaxVehicles,
                                                  MinWeight,
                                                  MaxWeight,
                                                  MinLeadTime,
                                                  MaxLeadTime,
                                                  MinDistance,
                                                  MaxDistance,
                                                  InitialDistance,
                                                  InitialPrice,
                                                  MinPriceRate,
                                                  MaxPriceRate,
                                                  PricePerVehiclePerMile,
                                             } = data;
                                             const isEdit = Id === 0 || (editSavedDetails && editSavedDataID === Id);
                                             const rowId = index + 1;
                                             return (
                                                  <tr key={Id} className={isEdit ? 'edit-row' : 'display-row'}>
                                                       <td>{rowId}</td>
                                                       <td>
                                                            {isEdit && (
                                                                 <>
                                                                      <FormikControl
                                                                           name={EditTablePricesKeys.Name}
                                                                           defaultValue={Name || ''}
                                                                           showLabel={false}
                                                                           control={FormControlKeys.Textarea}
                                                                           onChange={handlePriceNameChange}
                                                                      />
                                                                      {showPricesDropdown && (
                                                                           <div
                                                                                ref={dropdownContainerRef}
                                                                                className='member-dropdown-container'
                                                                           >
                                                                                <ul className='member-dropdown'>
                                                                                     {fetchedPrices.map(
                                                                                          (price: EditPriceDetails, index) => (
                                                                                               <li
                                                                                                    key={price.Id + index}
                                                                                                    onClick={() =>
                                                                                                         handleSelectPrice(price)
                                                                                                    }
                                                                                               >
                                                                                                    {price.Name}
                                                                                               </li>
                                                                                          )
                                                                                     )}
                                                                                </ul>
                                                                           </div>
                                                                      )}
                                                                 </>
                                                            )}
                                                            {!isEdit && <span>{Name}</span>}
                                                       </td>
                                                       <td>
                                                            {isEdit && (
                                                                 <FormikControl
                                                                      name={EditTablePricesKeys.Type}
                                                                      defaultValue={Name || ''}
                                                                      onChange={(e: any) => {
                                                                           formik.setFieldValue(
                                                                                EditTablePricesKeys.Type,
                                                                                e.target.value
                                                                           );
                                                                      }}
                                                                      control={FormControlKeys.Select}
                                                                      placeholder='Select'
                                                                      options={PriceGroupServiceTypeOption}
                                                                 />
                                                            )}
                                                            {!isEdit && <span>{renderServiceTypeCell(ServiceType ?? 0)}</span>}
                                                       </td>
                                                       <td>
                                                            <FormikControl
                                                                 name={EditTablePricesKeys.IncludesFuel}
                                                                 defaultValue={IncludesFuel || ''}
                                                                 control={FormControlKeys.Checkbox}
                                                            />
                                                            {!isEdit &&
                                                                 (IncludesFuel ? (
                                                                      <img
                                                                           alt='Includes Fuel'
                                                                           style={cellImageStyle}
                                                                           src={SuccessIndicatorIcon}
                                                                      />
                                                                 ) : (
                                                                      <img
                                                                           alt='Doesnt include Fuel'
                                                                           style={cellImageStyle}
                                                                           src={FailIndicatorIcon}
                                                                      />
                                                                 ))}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MinVehicles,
                                                                 MinVehicles,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MaxVehicles,
                                                                 MaxVehicles,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(EditTablePricesKeys.MinWeight, MinWeight, isEdit)}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(EditTablePricesKeys.MaxWeight, MaxWeight, isEdit)}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MinLeadTime,
                                                                 MinLeadTime,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MaxLeadTime,
                                                                 MaxLeadTime,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MinDistance,
                                                                 MinDistance,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MaxDistance,
                                                                 MaxDistance,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.InitialDistance,
                                                                 InitialDistance,
                                                                 isEdit
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.InitialPrice,
                                                                 InitialPrice,
                                                                 isEdit,
                                                                 true
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.PricePerVehiclePerMile,
                                                                 PricePerVehiclePerMile,
                                                                 isEdit,
                                                                 true
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MinPriceRate,
                                                                 MinPriceRate,
                                                                 isEdit,
                                                                 true
                                                            )}
                                                       </td>

                                                       <td>
                                                            {renderNumericInput(
                                                                 EditTablePricesKeys.MaxPriceRate,
                                                                 MaxPriceRate,
                                                                 isEdit,
                                                                 true
                                                            )}
                                                       </td>
                                                       {isEdit && (
                                                            <td>
                                                                 <span title='Save'>
                                                                      <DoneIcon
                                                                           className='cursor-pointer'
                                                                           type='submit'
                                                                           onClick={() => {
                                                                                //we need to make all the fields "touched" here in order to make the validation errors visible
                                                                                Object.keys(formik.errors).forEach((field) => {
                                                                                     formik.setFieldTouched(field, true, false);
                                                                                });
                                                                                handleSavePriceSubmit();
                                                                           }}
                                                                      />
                                                                 </span>
                                                                 |
                                                                 <span title='Cancel'>
                                                                      <CloseIcon
                                                                           className='cursor-pointer'
                                                                           onClick={(e: any) => {
                                                                                e.preventDefault();

                                                                                handleCancelEditPrice(data.Id);
                                                                           }}
                                                                      ></CloseIcon>
                                                                 </span>
                                                            </td>
                                                       )}
                                                       {!isEdit && (
                                                            <td>
                                                                 <span title='Edit Price'>
                                                                      <EditIcon
                                                                           className='cursor-pointer'
                                                                           onClick={() => {
                                                                                handleEditPrice(data);
                                                                                if (!formik.errors) {
                                                                                     handleEditPrice(data);
                                                                                }
                                                                           }}
                                                                      />
                                                                 </span>
                                                                 |
                                                                 <span title='Delete Price'>
                                                                      <DeleteIcon
                                                                           className='cursor-pointer'
                                                                           onClick={() => {
                                                                                handleDeletePrice(data.Id);
                                                                           }}
                                                                      />
                                                                 </span>
                                                            </td>
                                                       )}
                                                  </tr>
                                             );
                                        })}
                                   </tbody>
                                   {groupPriceSavedData.length === 0 && (
                                        <tbody>
                                             <tr>
                                                  <td colSpan={12}>No saved details!</td>
                                             </tr>
                                        </tbody>
                                   )}
                              </table>
                         </FormContainer>
                    </FieldSetContainer>
               </FlexContainerWithMargin>
          </FormikProvider>
     );
};

export default PricesEditor;

type PricesEditorProps = {
     prices?: EditPriceDetails[];
     refetchPricesList: Function;
     priceGroupId?: number;
     priceGroupType: number;
};

export const renderNumericInput = (name: string, value: any, isEdit: boolean, isCurrency = false) => {
     if (!isEdit) {
          return <span>{isCurrency && value ? '£' + value : value}</span>;
     }

     return <FormikControl name={name} type={isCurrency ? 'currency' : ''} defaultValue={value ?? ''} />;
};
