import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { JobActivityHistoryRequestParam, JobActivityHistoryResponseModel } from '../../../../../Models/Jobs/JobActivityHistory';

export const useGetJobsActivityHistoryList = (payload: JobActivityHistoryRequestParam,
    options?: any
): UseQueryResult<JobActivityHistoryResponseModel, any> => {

    const getJobsActivityHistoryList = ({ queryKey }: any) => {
        return agent.jobActivityHistory.getJobsActivityHistoryList(payload)
    }

    return useQuery(['GetJobsActivityHistoryList', payload], getJobsActivityHistoryList, {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        ...options,
    });
}
