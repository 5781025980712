import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { AddGroupBlockModel, GroupBlockResponseModel } from '../../../../../Models/Groups';

export const usePutGroupBlock = (
  options?: any
): UseMutationResult<
  GroupBlockResponseModel,
  any,
  AddGroupBlockModel
> => {

  const putGroupBlock = (payload: AddGroupBlockModel) => {
    return agent.groups.putGroupBlock(payload);
  };

  return useMutation(putGroupBlock);
};