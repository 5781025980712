import React from 'react';

export const TermsStandardUnstyled = () => {
  return (
    <>
      <h1>TERMS</h1>

      <p>
        <strong>MOVEX STANDARD TERMS AND CONDITIONS OF BUSINESS</strong>
      </p>

      <p>
        Movex provides an online platform via which businesses can engage
        transport suppliers to move vehicles on an ad hoc basis.
      </p>

      <p>
        These standard terms and conditions (<strong>Conditions</strong>) set
        out the contract terms between Transport Supplier and User and explain
        each party&rsquo;s rights and obligations. These Conditions also set out
        Movex&rsquo;s rights and its duties to Transport Suppliers and Users.
        Movex shall, at its discretion, be entitled to amend and/or vary these
        Conditions from time to time.
      </p>

      <p>
        This is an important document which should be read carefully. All
        transactions arranged via the Platform shall be governed by these
        Conditions. In consideration for the mutual promises and undertakings
        set out herein, Movex, Transport Supplier and User agree to be bound by
        these Conditions.
      </p>

      <p>
        <strong>
          <u>Definitions</u>
        </strong>
      </p>

      <p>
        In these Conditions, the following definitions and rules of
        interpretation apply.
      </p>

      <p>
        <strong>Acceptance</strong> means the date on which the Job becomes an
        Accepted Job.
      </p>

      <p>
        <strong>Accepted Job</strong> means a Job that is deemed to have been
        satisfactorily completed and accepted by a User in accordance with
        clause 12.
      </p>

      <p>
        <strong>App</strong> means the mobile software application operated by
        Movex and available via Google Android and Apple iOS platforms.
      </p>

      <p>
        <strong>Business Day</strong> means a day other than a Saturday or
        Sunday on which banks are generally open in England for the transaction
        of normal business.
      </p>

      <p>
        <strong>Cancellation Fees</strong> means the fees payable to Movex by a
        party to a Contract of Supply which cancels a Job, as specified on the
        Website and subject to amendment from time to time.
      </p>

      <p>
        <strong>Contract of Supply</strong> means a contract between User and
        Transport Supplier for the provision of the Job which comprises these
        Conditions and is formed in accordance with clause 3.
      </p>

      <p>
        <strong>Credit Limited</strong> means the amount of credit which Movex
        gives to a User;
      </p>

      <p>
        <strong>Credit User</strong> means a User that has been given credit by
        Movex, up to a Credit Limit;
      </p>

      <p>
        <strong>Delivery Date</strong> means the date on which a Vehicle is
        delivered by the Transport Supplier to the destination of the Job.
      </p>

      <p>
        <strong>Disputes Policy</strong> means the policy by which the User may
        raise a dispute against the Transport Supplier, which is displayed on
        the Website and subject to amendment from time to time.
      </p>

      <p>
        <strong>Fixed Price Request</strong> means a request for a Job, which
        includes a fixed price that a User is willing to pay for that Job and is
        published by that User on the Platform, inclusive of costs and expenses
        and with Platform Fees added by Movex.
      </p>

      <p>
        <strong>Job</strong> means the transportation of a Vehicle provided by a
        Transport Supplier to a User, which can either be a Plate Job or a
        Transporter Job.
      </p>

      <p>
        <strong>Job Price</strong> means the amount to be paid by a User for a
        Job as agreed between the parties to the Contract of Supply, which may
        be the fixed price of a Fixed Price Request as offered by the User or
        the amount of a Quote offered by the Transport Supplier.
      </p>

      <p>
        <strong>Movex </strong>means Movex Logistics Limited (a company
        registered in England under registration number 8113821) whose
        registered office is at Central House, Leeds Road, Rothwell, Leeds, LS26
        0JE.
      </p>

      <p>
        <strong>Non-Credit User</strong> means a User that has not been given
        credit by Movex.
      </p>

      <p>
        <strong>Platform</strong> means the online platform operated by Movex
        via the Website and App which enables Transport Suppliers and Users to
        enter into Contracts of Supply.
      </p>

      <p>
        <strong>Platform Fees</strong> means the amount payable to Movex for
        facilitating the Contract of Supply, including a percentage of the Job
        Price, as specified on the Website and subject to amendment from time to
        time.
      </p>

      <p>
        <strong>Plate Job</strong> means a Job where the Vehicle is driven to
        its destination using its own fuel and consumable parts, as opposed to a
        Transporter Job.
      </p>

      <p>
        <strong>Quote</strong> means the price a Transport Supplier is willing
        to complete a Job for in response to a Quote Request, as communicated to
        a User via the Platform, inclusive of costs and expenses and with
        Platform Fees added by Movex.
      </p>

      <p>
        <strong>Quote Request</strong> means a request for a Job, which invites
        Quotes and is published by a User on the Platform.
      </p>

      <p>
        <strong>Request</strong> means a request for a Job by a User, being
        either a Fixed Price Request or a Quote Request.
      </p>

      <p>
        <strong>Roadworthy </strong>means the Vehicle complies with all
        applicable laws and regulations introduced to ensure that vehicles do
        not cause a danger, including regulation as to a vehicle&rsquo;s
        consumable parts.
      </p>

      <p>
        <strong>Subscription Fees</strong> means the monthly subscription fees
        payable by Transport Supplier and User for the Services as specified on
        the Website and subject to amendment from time to time.
      </p>

      <p>
        <strong>Services</strong> means the services offered by Movex via the
        Platform.
      </p>

      <p>
        <strong>Transport Supplier </strong>means a person in the business of
        transporting vehicles and registered as a transport supplier with Movex.
      </p>

      <p>
        <strong>Transporter Job</strong> means a Job where the Vehicle is
        transported to its destination on another vehicle, as opposed to a Plate
        Job.
      </p>

      <p>
        <strong>User </strong>means a business registered as a user with Movex
        in order to use the Services, which can either be a Credit User or a
        Non-Credit User.
      </p>

      <p>
        <strong>Vehicle</strong> means the vehicle(s) that is to be transported
        as part of a Job.
      </p>

      <p>
        <strong>Vehicle Value</strong> means the market value of the Vehicle as
        determined by reference to the &ldquo;CAP Average&rdquo; price.
      </p>

      <p>
        <strong>Website </strong>means the website located at movex.co.uk.
      </p>

      <p>Words in the singular shall include the plural and vice versa.</p>

      <p>
        Any words following the terms <strong>including</strong>,{' '}
        <strong>include</strong>, <strong>in particular</strong>,{' '}
        <strong>for example</strong> or any similar expression shall be
        construed as illustrative and shall not limit the sense of the words,
        description, definition, phrase or term preceding those terms.
      </p>

      <p>
        <strong>
          <u>Transport Supplier duties</u>
        </strong>
      </p>

      <ol>
        <li>
          The Transport Supplier warrants and undertakes to both Movex and the
          User that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              it enters into these Conditions in the course of its business, not
              as a private individual, with full capacity to meet and carry out
              its obligations under these Conditions;
            </li>
            <li>
              all information and documents provided as part of its registration
              as a Transport Supplier with Movex are complete and accurate and
              it will inform Movex of any changes to such information in writing
              immediately;
            </li>
            <li>
              all information it provides in connection with the Job, including
              within any Quote, is complete and accurate;
            </li>
            <li>
              it shall perform the Job in accordance with the Request and any
              additional reasonable requirements communicated to it in writing
              by the User and/or Movex;
            </li>
            <li>it shall not subcontract the Job in whole or part;</li>
            <li>
              it shall carry out the Job in accordance with all applicable laws
              and regulations, including health and safety legislation and
              compliance with HSE (Health and Safety Executive) and DVSA (Driver
              &amp; Vehicle Standards Agency) guidance;
            </li>
            <li>
              it shall employ personnel who are suitably qualified and
              experienced to perform the tasks assigned to them and in
              sufficient number to ensure that its obligations under these
              Conditions are fulfilled;
            </li>
            <li>
              it shall use the App to record the condition of the Vehicle upon
              collection and delivery and to submit signatures on behalf of
              Transport Supplier and User as confirmation of delivery;
            </li>
            <li>
              it has in place all necessary licences for the operation of its
              business and provision of the Job and shall provide evidence of
              such licences to the User and/or Movex immediately upon request;
            </li>
            <li>
              it has in place and shall maintain such insurances with reputable
              insurers against those risks and to the extent that is usual for a
              transport supplier of its size and sufficient to cover the
              liabilities accepted under these Conditions. The Transport
              Supplier shall provide evidence of such insurances to Movex prior
              to accessing the Platform and ensure that these are updated upon
              each renewal and change to the insurance policies so that at all
              times Movex holds an up to date copy of such insurances;
            </li>
            <li>
              it shall perform the Job with reasonable skill and care in
              accordance with the commercial practices and to the standard
              expected of a competent and experienced provider of such services;
            </li>
            <li>
              where applicable, it shall comply with the terms of the Disputes
              Policy, Acceptable Use Policy and Platform Terms of Use; and
            </li>
            <li>
              it shall use its best endeavours to support and assist Movex in
              any claim it brings against the User in connection with the Job,
              including providing all requested information and documents and
              making available its personnel.
            </li>
          </ol>
        </li>
      </ol>

      <p>
        <strong>
          <u>User&rsquo;s duties</u>
        </strong>
      </p>

      <ol>
        <li value="2">
          The User warrants and undertakes to both Movex and the Transport
          Supplier that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              it enters into these Conditions in the course of its business, not
              as a private individual, with full capacity to meet and carry out
              its obligations under these Conditions;
            </li>
            <li>
              all information and documents provided as part of its registration
              as a User with Movex are complete and accurate and it will inform
              Movex of any changes to such information in writing immediately;
            </li>
            <li>
              all information it provides in connection with the Job, including
              within any Request, is complete and accurate;
            </li>
            <li>
              it is not aware of any issues which may affect the ability of a
              Transport Supplier to transport the Vehicle in the manner
              requested;
            </li>
            <li>where it Requests a Plate Job, the Vehicle is Roadworthy;</li>
            <li>
              it shall sign a proof of delivery form provided by the Transport
              Supplier upon delivery of the Vehicle;
            </li>
            <li>
              within 3 (three) Business Days of Delivery, it shall report any
              issues with the Job in accordance with clause 11;
            </li>
            <li>
              where applicable, it shall comply with the terms of the Disputes
              Policy, Acceptable Use Policy and Platform Terms of Use; and
            </li>
            <li>
              it shall use its best endeavours to support and assist Movex in
              any claim it brings against the Transport Supplier in connection
              with the Job, including providing all requested information and
              documents and making available its personnel.
            </li>
          </ol>
        </li>
      </ol>

      <p>
        <strong>
          <u>Contract of Supply</u>
        </strong>
      </p>

      <p>
        <strong>Formation</strong>
      </p>

      <ol>
        <li value="3">
          A Contract of Supply is a legally binding agreement formed between
          Transport Supplier and User when:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              a Fixed Price Request is accepted by a Transport Supplier; and/or
            </li>
            <li>a Quote is accepted by a User.</li>
          </ol>
        </li>
      </ol>

      <p style={{ marginLeft: '49.65pt' }}>&nbsp;</p>

      <ol>
        <li value="4">
          Movex is not a party to the Contract of Supply and has no liability or
          responsibility in respect of the Contract of Supply.
        </li>
      </ol>

      <ol>
        <li value="5">
          The Contract of Supply contains the warranties and representations
          made by the Transport Supplier at clause 1. The Transport Supplier
          makes no other warranties or representations.
        </li>
      </ol>

      <ol>
        <li value="6">
          Other than as set out in these Conditions, the Transport Supplier
          expressly excludes all other representations and warranties including
          any which may otherwise be implied into the Contract of Supply by
          legislation or by common law (whether this be terms implied by custom
          and practice or otherwise).
        </li>
      </ol>

      <ol>
        <li value="7">
          The Transport Supplier shall have no liability to the User for any
          loss of profit, loss of goodwill, loss of business, increased
          management costs, loss of anticipated savings, lost opportunity or any
          special, consequential or indirect losses suffered by the User in
          relation to the Contract of Supply.
        </li>
      </ol>

      <ol>
        <li value="8">
          At all times the Transport Supplier&rsquo;s liability to the User
          shall not exceed the Vehicle Value.
        </li>
      </ol>

      <ol>
        <li value="9">
          Nothing in the Contract of Supply shall exclude or limit the Transport
          Supplier&rsquo;s liability for death or personal injury caused by the
          Transport Supplier&rsquo;s negligence, fraud or fraudulent
          misrepresentation or any other matter for which it would be unlawful
          for it to exclude liability.
        </li>
      </ol>

      <p>
        <strong>Cancellation</strong>
      </p>

      <ol>
        <li value="10">
          If either the User or the Transport Supplier cancels the Job following
          formation of the Contract of Supply, that party shall pay the
          Cancellation Fees to Movex, which, depending on the notice period
          provided, may be 100% (one hundred per cent) of the Job Price.
        </li>
      </ol>

      <p>
        <strong>Acceptance</strong>
      </p>

      <ol>
        <li value="11">
          Within 3 (three) Business Days of the Delivery Date, the User shall
          raise any dispute in regard to the Job by providing full details for
          consideration by Movex in accordance with the Disputes Policy.
        </li>
      </ol>

      <ol>
        <li value="12">
          If the User does not dispute acceptance of the Job in accordance with
          clause 11, the User shall be deemed to have accepted the Job 3 (three)
          Business Days after Delivery (<strong>Accepted Job</strong>) and must
          pay the Job Price.
        </li>
      </ol>

      <p>
        <strong>Disputes</strong>
      </p>

      <ol>
        <li value="13">
          If the User disputes the Job in accordance with clause 11, Movex shall
          review and determine the dispute in accordance with its Disputes
          Policy.
        </li>
      </ol>

      <ol>
        <li value="14">
          If Movex determines that the Transport Supplier carried out the Job in
          accordance with these Conditions, the Job will be deemed to be an
          Accepted Job and the Job Price will be payable by the User.
        </li>
      </ol>

      <ol>
        <li value="15">
          The User and Transport Supplier acknowledge and agree that provision
          of the disputes resolution procedure is an essential element of the
          Service and Movex&rsquo;s determination of the dispute is final and
          binding, other than in the event of fraud or manifest error.
        </li>
      </ol>

      <p>
        <strong>
          <u>Charges</u>
        </strong>
      </p>

      <p>
        <strong>Users</strong>
      </p>

      <ol>
        <li value="16">
          Movex shall invoice Users once per month for:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              Cancellation Fees for Jobs cancelled by the User in the previous
              month; and
            </li>
            <li>Subscription Fees.</li>
          </ol>
        </li>
      </ol>

      <ol>
        <li value="17">
          Non-Credit Users shall pay the Job Price before they enter into the
          Contract of Supply.
        </li>
      </ol>

      <ol>
        <li value="18">
          Movex shall invoice Credit Users for the Job Price for each Accepted
          Job where Acceptance was in the previous month.
        </li>
      </ol>

      <ol>
        <li value="19">
          If the Job Price exceeds the Credit Limit of the Credit User, the
          Credit User must pay the amount of the Job Price that exceeds the
          Credit Limit before it enters into the Contract of Supply.
        </li>
      </ol>

      <ol>
        <li value="20">
          Users shall pay the full amount of each invoice within 30 (thirty)
          calendar days of the date of the invoice.
        </li>
      </ol>

      <p>
        <strong>Transport Suppliers</strong>
      </p>

      <ol>
        <li value="21">
          In normal circumstances Movex shall pay the Job Price, minus Platform
          Fees, to the bank account nominated by the Transport Supplier 3
          (three) Business Days after Delivery
        </li>
        <li value="22">
          In the event that Movex determines the Job Price is not payable
          following a dispute being raised by the User in accordance with clause
          11, the Transport Supplier shall refund any Job Price that has been
          paid to it by Movex within 5 (five) Business Days of a written request
          by Movex. Following investigation this could be used to compensate a
          user. In the event that Movex determines there is an issue with a job,
          they reserve the right to hold back monies owed.
        </li>
        <li value="23">
          Movex shall invoice Transport Suppliers once per month for:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              Cancellation Fees for Jobs cancelled by the Transport Supplier in
              the previous month; and
            </li>
            <li>Subscription Fees.</li>
          </ol>
        </li>
      </ol>

      <p style={{ marginLeft: '21.3pt' }}>
        The Transport Supplier shall pay each invoice within 30 (thirty)
        calendar days of the date of the invoice.
      </p>

      <ol>
        <li value="24">
          VAT (at the rate prevailing by law from time to time) shall be payable
          on all amounts due under these Conditions.
        </li>
      </ol>

      <ol>
        <li value="25">
          If the Transport Supplier or the User fails to pay any amount payable
          by it under these Conditions, interest (compounded monthly) shall
          accrue on the overdue amount from the due date up to the date of
          actual payment (both before and after judgment) at the rate of 4%
          (four per cent) per annum above the base rate of National Westminster
          Bank (or any other bank base rate chosen by Movex) from time to time.
        </li>
      </ol>

      <p>
        <strong>
          <u>Movex&rsquo;s Rights</u>
        </strong>
      </p>

      <ol>
        <li value="26">
          Movex has the right to refuse any person or business access to the
          Platform or any part thereof or other facilities and/or to suspend any
          such rights at its sole discretion.
        </li>
      </ol>

      <ol>
        <li value="27">
          Without affecting any other right or remedy available to it, Movex may
          terminate these Conditions with immediate effect by giving written
          notice to the Transport Supplier and/or User (as applicable) if it:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              fails to pay any amount due under these Conditions on the due date
              for payment and remains in default 7 (seven) days after being
              notified in writing to make such payment;
            </li>
            <li>
              commits a material breach of any term of these Conditions which
              breach is irremediable or (if such breach is remediable) fails to
              remedy that breach within a period of 7 (seven) days after being
              notified in writing to do so;
            </li>
            <li>
              repeatedly breaches any of the terms of these Conditions in such a
              manner as to reasonably justify the opinion that its conduct is
              consistent with it having the intention or ability to give effect
              to these Conditions;
            </li>
            <li>
              has become insolvent or has had a receiver, administrator or
              administrative receiver appointed or applied for or has called a
              meeting of creditors or resolved to go into liquidation (except
              for bona fide amalgamation or reconstruction while solvent) or an
              application is made to appoint a provisional liquidator of the
              Transport Supplier and/or User (as applicable) or for an
              administration order or notice of intention to appoint an
              administrator is given or a proposal is made for a voluntary
              arrangement or any other composition, scheme or arrangement with
              or assignment for the benefit of any of its creditors, or any
              event analogous to any of the foregoing occurs in any jurisdiction
              other than England and Wales in respect of the Transport Supplier
              and/or User (as applicable); or
            </li>
            <li>ceases or threatens to cease to carry on business.</li>
          </ol>
        </li>
      </ol>

      <ol>
        <li value="28">
          Without prejudice to any other right or remedy, Movex shall be
          entitled but not obliged at any time to set off any liability of the
          Transport Supplier or User owed to Movex against any liability of
          Movex to the Transport Supplier or User (as applicable),
          &nbsp;howsoever arising and whether any such liability is present or
          future, liquidated or unliquidated.
        </li>
      </ol>

      <p>
        <strong>
          <u>Liability</u>
        </strong>
      </p>

      <ol>
        <li value="29">
          Nothing in these Conditions shall limit Movex&rsquo;s liability for
          death or personal injury arising from its negligence, fraud or
          fraudulent misrepresentation or any other matter for which it would be
          unlawful for it exclude liability.
        </li>
      </ol>

      <ol>
        <li value="30">
          Movex shall have no liability to either a Transport Supplier or User
          for any loss of profit, loss of goodwill, loss of business, increased
          management costs, loss of anticipated savings, lost opportunity or any
          special or consequential losses suffered by the Transport Supplier or
          User in relation to these Conditions.
        </li>
      </ol>

      <ol>
        <li value="31">
          Movex shall have no responsibility to check the accuracy of any
          information provided or made available on the Platform, including
          information provided as part of Requests and Offers. Transport
          Suppliers and Users rely on all such information at their own risk.
        </li>
      </ol>

      <ol>
        <li value="32">
          At no time shall Movex&rsquo;s liability exceed the Vehicle Value of
          the Job that the claim or loss relates to.
        </li>
      </ol>

      <ol>
        <li value="33">
          Movex shall have no liability to a Transport Supplier or User for any
          delay in performance if and to the extent that the delay is due to
          circumstances beyond its reasonable control.
        </li>
      </ol>

      <ol>
        <li value="34">
          Each of the Transport Supplier and the User agree to fully and
          effectively indemnify Movex and hold Movex harmless on demand against
          any losses, costs, damages, expenses or liabilities (including legal
          fees on a full indemnity basis) suffered by Movex in connection with
          it being in breach of these Conditions including under the Contract of
          Sale.
        </li>
      </ol>

      <p>
        <strong>
          <u>General</u>
        </strong>
      </p>

      <ol>
        <li value="35">
          In order to provide the Services Movex shall require certain
          information from the Transport Supplier and User, which may include
          personal data (as defined in accordance with the Data Protection Act
          1998). Any and all personal data collected by Movex for such purposes
          shall be processed by Movex in accordance with Movex&rsquo;s Privacy
          Policy.
        </li>
      </ol>

      <ol>
        <li value="36">
          Use of the Platform shall be subject to Movex&rsquo;s Platform Terms
          of Use and Acceptable Use Policy.
        </li>
      </ol>

      <ol>
        <li value="37">
          Movex makes no guarantees as to the availability of the Platform and
          does not warrant that Transport Suppliers and Users shall have
          uninterrupted, secure or error free access to such systems and
          communication facilities.
        </li>
      </ol>

      <ol>
        <li value="38">
          Transport Suppliers and Users agree and understand that when using the
          Website and App, being electronic means of communication, to submit
          Requests and Quotes, such submissions may not be received or
          transmitted. Transport Suppliers and Users use the Website and App at
          their own risk that communications will be transmitted efficiently.
        </li>
      </ol>

      <ol>
        <li value="39">
          These Conditions set out the entire agreement between (1) Movex and
          Transport Supplier; (2) Movex and User; and (3) Transport Supplier and
          User.
        </li>
      </ol>

      <ol>
        <li value="40">
          No other person except for the parties to these Conditions shall have
          any right under the Contracts (Rights of Third Parties) Act 1999 to
          rely upon or enforce any term of these Conditions. Nothing in these
          Conditions shall affect any right or remedy of a third party which
          exists or is available other than as a result of the aforementioned
          Act.
        </li>
      </ol>

      <ol>
        <li value="41">
          These Conditions contain all the terms which are agreed in relation to
          the Services and the Contract of Supply and supersede any prior
          written or oral agreements, representations or understandings between
          the parties relating to the Services and/or the Contract of Supply (as
          applicable).
        </li>
      </ol>

      <ol>
        <li value="42">
          If any clause, sub-clause, or any part of a clause or sub-clause, of
          these Conditions is found by any court, tribunal, administrative body
          or authority of competent jurisdiction to be illegal, invalid or
          unenforceable than that provision will, to the extent required, be
          severed from these Conditions and will be ineffective. All other
          provisions of these Conditions will remain in full force and effect
          with such modifications as may be necessary to give effect to the
          remaining Conditions.
        </li>
      </ol>

      <ol>
        <li value="43">
          Any notices shall be made by either email, facsimile, telephone,
          regular mail or displayed on the Platform at Movex&rsquo;s election.
        </li>
      </ol>

      <ol>
        <li value="44">
          Both Transport Supplier and User agree not to assign their rights and
          obligations under these Conditions.
        </li>
      </ol>

      <ol>
        <li value="45">
          No partnership, joint venture, contractor, employee-employer,
          franchisor-franchisee relationship arises between Movex and either
          Transport Supplier or User by reason of these Conditions.
        </li>
      </ol>

      <ol>
        <li value="46">
          These Conditions shall be governed by English Law and the parties
          agree to submit to the non-exclusive jurisdiction of the English
          courts.
        </li>
      </ol>
    </>
  );
};
