import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const ShowPleaseWait: React.FC<ShowPleaseWaitProps> = (props) => {
  const { show } = props;

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <Dialog open={show}>
        <DialogContent style={{ height: '100%', width: '100%' }}>
          <h5>Please wait...</h5>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
};

type ShowPleaseWaitProps = {
  show: boolean;
};

const Modal = styled.div;
