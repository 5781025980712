import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, ConfirmDialog, ControlledPagination as Pagination, useAlert } from '../../../../../Components';
import { useConfirmDialog } from '../../../../../Components/ConfirmDialog/useConfirmDialog';
import { GetInvoicesResponseModel } from '../../../../../Models/Billing';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter, validateEmail } from '../../../../../Utils';
import { EmailForm } from '../../AddInvoice/EmailInvoicePdfModal';
import { usePutEmailInvoice } from '../Hooks';
import { columnsComponents } from './Columns';

const ViewInvoicesGridView: React.FC<InvoiceGridViewProps> = (props) => {
     const { data: invoiceList, onPageChange, onPageSizeChange, onSort } = props;

     /** Custom Hooks */
     const { confirmDialogProps, openConfirmDialog, closeConfirmDialog } = useConfirmDialog();
     const [emails, setEmails] = useState('');
     const emailRef = useRef(emails);
     const { alert, setAlert, handleAlertClose } = useAlert();

     /* Mutations Hooks */
     const { mutate: putEmailInvoice, error: putEmailInvoiceError, data: putEmailInvoiceData } = usePutEmailInvoice();

     /* Functions */
     const onEmailInvoicePdf = (invoiceId: number) => {
          openConfirmDialog('Send By Email - Please Enter Recipient', <EmailForm udapteEmails={setEmails} />, () => {
               const emails = emailRef.current.split(',');
               const allEmailsValid = emails.every(validateEmail);
               if (!allEmailsValid) {
                    return;
               } else {
                    putEmailInvoice({ InvoiceID: invoiceId, Recipients: emails.toString() });
                    setEmails('');
                    closeConfirmDialog();
               }
          });
     };

     const handlePageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentPage: number) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     /** Side Effects */
     useEffect(() => {
          emailRef.current = emails;
     }, [emails]);

     useEffect(() => {
          if (putEmailInvoiceError) {
               setAlert({
                    ...alert,
                    header: putEmailInvoiceError.Subject ?? 'Error Sending Emails',
                    body: putEmailInvoiceError.Description ?? `Error when sending emails.: ${putEmailInvoiceError}`,
                    show: true,
               });
          }
     }, [putEmailInvoiceError]);

     useEffect(() => {
          if (putEmailInvoiceData) {
               setAlert({
                    ...alert,
                    header: 'Success',
                    body: 'Email sent Successfully',
                    show: true,
               });
          }
     }, [putEmailInvoiceData]);

     if (invoiceList) {
          const { Page: page, TableSorter } = invoiceList;

          const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = page;

          const CustomGridFooter: React.FC = (): JSX.Element => {
               return (
                    <div>
                         <Pagination
                              itemsCount={TotalItems}
                              totalPages={TotalPages}
                              currentPage={CurrentPage}
                              pageSize={ItemsPerPage}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                         />
                    </div>
               );
          };
          return (
               <div>
                    {page && (
                         <div className='view-invoices-grid-container'>
                              <ConfirmDialog {...confirmDialogProps} />
                              <Alert {...alert} />
                              <div id='id_grid_invoices' style={{ display: 'flex', height: '100%', width: '100%' }}>
                                   <DataGrid
                                        sortingOrder={['desc', 'asc']}
                                        getRowId={(row: any) => row.Id}
                                        autoHeight
                                        rows={Items ?? {}}
                                        rowHeight={41}
                                        disableColumnMenu={true}
                                        columns={columnsComponents(onEmailInvoicePdf, setAlert, handleAlertClose)}
                                        components={{
                                             Toolbar: CustomGridFooter,
                                             Footer: CustomGridFooter,
                                        }}
                                        disableExtendRowFullWidth={true}
                                        sortingMode='server'
                                        sortModel={getSortModelFromSorter(TableSorter as Sorter)}
                                        onSortModelChange={handleSort}
                                   />
                              </div>
                         </div>
                    )}
               </div>
          );
     }
     return null;
};

export default ViewInvoicesGridView;

interface InvoiceGridViewProps extends GridProps<GetInvoicesResponseModel> {}
