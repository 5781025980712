import { useMutation, UseMutationResult } from 'react-query';
import { adminTasks } from '../../../../../Axios/Screens/Task';

export const useDeleteTaskAttachment = (
  options?: any
): UseMutationResult<any, any, number> => {

  const deleteTaskAttachment = (jobId: number) => {
    return adminTasks.deleteTaskAttachment(jobId);
  };

  return useMutation(deleteTaskAttachment);
};
