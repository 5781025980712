import axios, { AxiosResponse } from 'axios';
import { config } from '../Config/AdminConfig';
import { AddressDetail, Location } from '../Models/GoogleAPI/AddressDetail';
import {
  DistanceMatrix,
  StreetAddressResponse
} from '../Models/GoogleAPI/DistanceMatrix';
import { camelize } from '../Utils';
import { GoogleAPIHost, FetchifyAPIHost } from '../Utils/Constants';
import * as Storage from '../Utils/Storage';
import agent from './Agent';

const responseBody = (response: AxiosResponse) => camelize(response.data);

var googleAgent = axios.create({
  baseURL: GoogleAPIHost,
});

var fetchifyAgent = axios.create({
  baseURL: FetchifyAPIHost,
});

fetchifyAgent.interceptors.response.use(async (response) =>
  (response)
)

googleAgent.interceptors.request.use(
  async (config) => {
    const token = await Storage.getGoogleInfo('googleAPIKey');
    if (token) {
      config.url = config.url + `&key=${token}`;
    } else {
      agent.session.getConfig().then((data) => {
        if (data && data.googleAPIKey) {
          Storage.setGoogleInfo('googleAPIKey', data.googleAPIKey);
          config.url = config.url + `&key=${data.googleAPIKey}`;
        }
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

googleAgent.interceptors.response.use(async (response) => (response));

const geoCoding = {
  getFullAddressByPostCode: (postcode: string): Promise<AddressDetail> => {
    return googleAgent
      .get(`maps/api/geocode/json?address=${postcode}`)
      .then(responseBody);
  },
};

const distanceMatrix = {
  get: (
    units: string,
    origins: string,
    destinations: string
  ): Promise<DistanceMatrix> => {
    return googleAgent
      .get(
        `maps/api/distancematrix/json?units=${units}&origins=${origins}&destinations=${destinations}`
      )
      .then(responseBody);
  },
};

const streetAddress = {
  getFullAddressByPostCode: (postCode: string): Promise<StreetAddressResponse> => {
    return fetchifyAgent
      .get(
        `json/rapidaddress?key=${config.fetchifyTokenKey}&postcode=${postCode}&response=application/json`
      )
      .then(responseBody);
  },

  getStreetAddressByPostCode: (postCode: string): Promise<StreetAddressResponse> => {
    return fetchifyAgent
      .get(
        `json/basicaddress?key=${config.fetchifyTokenKey}&postcode=${postCode}&response=application/json`
      )
      .then(responseBody);
  },
};

const googleApi = {
  geoCoding,
  distanceMatrix,
  streetAddress,
};

export const getLatLngForPostCode = (postCode: string): Promise<Location> => {
  return googleApi.geoCoding.getFullAddressByPostCode(postCode).then((response) => {
    return new Promise((resolve, reject) => {
      if (response?.Results?.length) {
        const lat = response.Results[0].Geometry.Location.Lat;
        const lng = response.Results[0].Geometry.Location.Lng;
        const result: Location = { Lat: lat, Lng: lng };
        return resolve(result);
      } else {
        reject()
      }
    })
  })
};

export default googleApi;
